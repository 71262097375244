import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-failed-to-reapply-for-benefits',
  templateUrl: './failed-to-reapply-for-benefits.component.html',
  styleUrls: ['./failed-to-reapply-for-benefits.component.css']
})
export class FailedToReapplyForBenefitsComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
