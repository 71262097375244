import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { ApiManager } from 'src/app/common/api-manager.service';
import { AppUtilService } from 'src/app/common/app.util.service';
import { ConstantsService } from 'src/app/common/constants.service';
import { CustomValidatorsService } from 'src/app/common/custom-validators.service';
import { NavigationService } from 'src/app/common/navigation.service';
import { PrintService } from 'src/app/common/print.service';
import { DATE_FORMAT } from '../month-format.directive';
import * as jspdf from 'jspdf';  

@Component({
  selector: 'app-qc10',
  templateUrl: './qc10.component.html',
  styleUrls: ['./qc10.component.css']
})
export class Qc10Component implements OnInit {
  allDOs: any;
  constructor(
    public constantsService: ConstantsService,
    private utilService: AppUtilService,
    private apiManager: ApiManager,
    private router: Router,
    private route: ActivatedRoute,
    @Optional() public dialog: MatDialog,
    public navigationService: NavigationService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private printService: PrintService,
    private customValidatorsService: CustomValidatorsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (this.data) {
      this.popupMode = true;
      // this.displayedColumns = this.displayedBPEColumns;
    }
  }

  ngOnInit() {
    this.allDOs = this.constantsService.getDistinctDOsWithOutAppType();
   this.getQc10();
  }
  displayedColumns: string[] = ['ldssCd', 'date'
    , 'actions'];
  qcdataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  popupMode: boolean = false;
  //  checkValue(event) {
  //     let phnumber = event.currentTarget.value;
  //     const ctrl = this.group.get('phoneNumber').get('phoneNumberType');
  //     if (this.group.get('phoneNumber').get('phoneNumber').hasError('invalidPhone') || phnumber.length == 0) {
  //       ctrl.disable();
  //       ctrl.reset();
  //     } else {
  //       ctrl.enable();
  //     }
  //   }

  print() {
    window.print();
  }
  review(idx) {
    this.openDialog(idx)

  }
  refreshNav() {
    this.navigationService.getPrograms(this.navigationService.appCd).then(v => {
      this.afterNavChange();
    });
  }

  afterNavChange() {
    // Do Nothing
    // TODO : if any change after nav change while adding new program
    this.navigationService.navigatePage(this.router.url, 'next');
  }


  openDialog(index) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    if (index !== undefined && index > -1) {
      dialogConfig.data = {
        // samplingdata: this.qcdataSource.data,
        index: index,
        qc10Data: this.qcdataSource.data
      };
    }
    else {
      dialogConfig.data = {
        // samplingdata: this.qcdataSource.data,
        index: -1,
        qc10Data: this.qcdataSource.data
      };
    }

    const dialogRef = this.dialog.open(qc10Dialog, dialogConfig);
    dialogRef.updateSize('80%', '85%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log('afterClose', val);
        // this.dsQCSamplingSchedule = new MatTableDataSource(val);
        // this.dialog.closeAll();
        this.ngOnInit();

      }

    });
  }
  getQc10() {

    const queryParams: any[] = [];

    // queryParams.push(this.constantsService.getSecurityObject().user.uniqueId); // TODO: set the analyst id from user id

    queryParams.push('QC');
    const objBody: any = this.constantsService.getSecurityObject().user.uniqueId ;

    this.apiManager.fetchData('getQc10', undefined, ApiManager.GET,
        objBody, undefined, undefined, queryParams)
        .subscribe(res => {
          if (res) {
            console.log(res);

            this.qcdataSource = new MatTableDataSource(res);
            this.qcdataSource.paginator = this.paginator;
            this.qcdataSource.sort = this.sort;
          }
        });
  }

  getDONamebyCd(doCd){
    let doName = '';
    this.allDOs.filter(item => {
      if (item.ldssDoCd === doCd) {
      doName = item.ldssDoName;
      }
    });
    return doName;
  }
 


}
@Component({
  selector: 'qc-10-dialog',
  templateUrl: 'qc10dialog.component.html',
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],

})
export class qc10Dialog implements OnInit {

  public qcForm: FormGroup;

  allDOs:any;
  constructor(
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    public appUtilService: AppUtilService,
    private fb: FormBuilder,
    private customValidatorsService: CustomValidatorsService,
  ) {
    this.buildForm();
  }

  ngOnInit() {
    this.allDOs = this.constantsService.getDistinctDOsWithOutAppType();

    if (this.data.index > -1) {
      const selectedQC10 = this.data.qc10Data[this.data.index];
      this.qcForm.patchValue(selectedQC10);
    }
    else {

    }
  }

  buildForm() {
    this.qcForm = this.fb.group({
      ldssCd: ['', [Validators.required]],
      date: ['', [Validators.required]],
      to: ['', [Validators.required]],
      attn: ['', [Validators.required]],
      cc: ['', [Validators.required]],
      from: ['', [Validators.required]],
      phoneNo: ['', [Validators.required]],
      faxNo: ['', [Validators.required]],
      schedDt: ['', [Validators.required]],
      schedLoc: ['', [Validators.required]],
      dirtyStatus : ['INSERT']
    });

  }

  save(model) {


    const queryParams: any[] = [];
    const samplingData = model.value;


    this.apiManager.fetchData('saveQc10', undefined, ApiManager.POST,
      samplingData, null, null, queryParams)
      .subscribe(res => {
     this.convertPDF()
        this.dialogRef.close(res);
      });
  }

 

  convertPDF() {

    let data = document.getElementById('appPagePDFTwo');
    html2canvas(data).then(async canvas => {
      const imgWidth = 180;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jspdf('p', 'mm', 'a4'); 
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      let rvwMonthDate = new Date().toString() ;
      // pdf.save(rvwMonthDate + '.pdf');
            window.open(pdf.output('bloburl', { filename: rvwMonthDate }), '_blank');
    });
  }

  
  
  

  
 


}

