import { Component, OnInit, SimpleChange, OnChanges, AfterContentChecked, ViewChild, ElementRef, EventEmitter, Output, Input, ChangeDetectorRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FieldConfig, Validator, PageConfig } from "../components/field.interface";
import { ConstantsService } from '../common/constants.service';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { AppUtilService } from '../common/app.util.service';
import { NavigationService } from '../common/navigation.service';
import { DataShareService } from '../common/data-share.service';
import { ApiManager } from '../common/api-manager.service';
import { CustomValidatorsService } from '../common/custom-validators.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SendEmailComponent } from './qc-forms/send-email/send-email.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClarifyComponent } from './clarify/clarify.component';
import { QuestionaryComponent } from "./questionary/questionary.component";




@Component({
  selector: "app-page",
  template: ``,
  styles: []
})
export class BaseComponent implements OnInit, OnChanges, AfterContentChecked {
  @Input() public openDialog: any;
  @Output() callGet: EventEmitter<any> = new EventEmitter();
  @Output() callSave: EventEmitter<any> = new EventEmitter();
  @Output() callGenerateSpanishPDF: EventEmitter<any> = new EventEmitter();
  
  field: FieldConfig[];
  group: FormGroup;
  errorCount = 0;
  errorField;
  screenType: string;
  isNotEditable = false;
  customIsEditable: boolean = false;
  public maxDate = new Date();
  public mask = ['(', /[1-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  constructor(
    public router: Router,
    private activeRoute: ActivatedRoute,
    public constantsService: ConstantsService,
    public utilService: AppUtilService,
    public navigationService: NavigationService,
    public shareService: DataShareService,
    public apiManager: ApiManager,
    public customValidator: CustomValidatorsService,
    public dialog: MatDialog,
    public _snackBar: MatSnackBar

  ) {
    this.customIsEditable = this.constantsService.selectedIndividual.headOfHsehldInd === 'Y' ? false : true;
    this.activeRoute.queryParams.subscribe(queryParams => {
      if (queryParams['type']) {
        this.screenType = queryParams['type'];
      }
    });
  }

  ngOnInit() {
    this.determineAccess();
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }) {
    console.log('onchange');
  }

  ngAfterContentChecked() {

    Object.keys(this.group.value).map(ctrl => {

      if (this.constantsService && this.constantsService.ReviewInfo && this.group.controls[ctrl]) {
        if (this.constantsService.ReviewInfo.rvwSts === 'CP' && this.constantsService.isAnalystUser()) {
          this.group.controls[ctrl].disable();
        } else if (this.constantsService.ReviewInfo.rvwSts === 'ST'  && this.constantsService.isAnalystUser() && ConstantsService.isConfirmChecked ==='Y') {
            this.group.controls[ctrl].disable();
        }

        // if (ctrl === '777' && this.navigationService.getSelectedNavHead() !== 'parentCaseReview') {
        //   this.group.controls[ctrl].disable();
        // }

      }
    });

    this.errorCount = 0;
    const ctrl = this.group.value;
    Object.keys(this.group.value).map(val => {
      if (ctrl[val] === 'No' || ctrl[val] === 'NO' || ctrl[val] === 'N') {
        this.errorCount = this.errorCount + 1;
        this.group.controls[val].markAsDirty();
        this.group.controls[val].updateValueAndValidity();
      }
      if (val === '68a.12' || val === '68.1.9' || val === '68-B.12') {
        this.constantsService.in_68_Code = ctrl[val];
      }
    });
    if (this.errorField) {
      this.group.controls[this.errorField].setValue(this.errorCount);
      this.group.controls[this.errorField].disable();
    }
    // let controlName: any = 500010.4;
    // if (this.group.controls[controlName].value) {
    //   let numValue = this.group.controls[controlName].value as string;
    //   if (numValue.length == 10) {
    //     numValue = numValue.slice(4, 10);
    //     this.group.controls[controlName].patchValue(numValue);
    //   }
    // }
    this.group.updateValueAndValidity();
  }


  getLookupNameByQCd(qCd) {
    const tempField = this.field.find(fld => fld.name === qCd);
    return ((tempField !== undefined && tempField.lookupName !== undefined)
      ? this.field.find(fld => fld.name === qCd).lookupName
      : []);
  }

  getfieldInfo(qCd, controlType = null, isErrCnt = false) {
    let currentField: FieldConfig;
    if (this.field && this.field.find(fld => fld.name === qCd)) {
      currentField = this.field.find(fld => fld.name === qCd);
      if (controlType) {
        currentField.qtype = controlType;
      }

      if (isErrCnt) {
        // this.group.get(qCd).disable
        this.errorField = qCd;

      }
    }

    const lkpOptions = this.constantsService.getLookUpByName(this.getLookupNameByQCd(qCd));
    if (currentField && lkpOptions) {
      currentField.options = lkpOptions;
      // this.group.controls[qCd].valueChanges.subscribe( val => {
      //   if (val ==='No' || val ==='NO' || val ==='N') {
      //     this.errorCount = this.errorCount + 1
      //   }
      //   this.group.controls[this.errorField].setValue(this.errorCount)
      // }
      // )
    }

    return currentField;
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();
    if (ref.user.roles.find((x: any) => x == 'EE_QC_ANALYST' || x == 'EE_QC_INQRY')) {
      if ((ref.accessMap[this.router.url] != null && ref.accessMap[this.router.url].accessMode != "rw") || (ref.user.roles.find((x: any) => x == 'EE_QC_INQRY'))) {
        this.isNotEditable = true;
      }
      this.isNotEditable = false;
    }
  }

  setControlValue(qtn, val) {

    if (this.group.controls[qtn]) {
      this.group.controls[qtn].patchValue(val);
    }
  }

  getControlValue(qtn) {
    return this.group.controls[qtn] ? this.group.controls[qtn].value : null;
  }

  convertPDF() {
    let data = document.getElementById('convertPDF');
    html2canvas(data).then(canvas => {
      // const contentDataURL = canvas.toDataURL('image/png');
      // let pdf = new jspdf('l', 'cm', 'a4'); // Generates PDF in landscape mode
      // // let pdf = new jspdf('p', 'cm', 'a4'); // Generates PDF in portrait mode
      // pdf.addImage(contentDataURL, 'PNG', 0, 0, 29.7, 21.0);

      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jspdf('p', 'mm', 'a4');
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      let rvwMonthDate: Date = new Date(this.constantsService.ReviewInfo.rvwMo);
      let rvwMonth = rvwMonthDate.getMonth() + '/' + rvwMonthDate.getFullYear();
      pdf.save(rvwMonth + '-' + this.constantsService.ReviewInfo['ldssDoName'] + '.pdf');
    });

    // let doc = new jspdf();
    // doc.addHTML(this.content.nativeElement, function() {
    //    doc.save("Filename.pdf");
    // });
  }
  setAutofill(type) {
    this.setControlValue(type + '.2', '2');
    this.setControlValue(type + '.3', '6');
    this.setControlValue(type + '.4', '10');
  }

  handleAddress(selectedMember, addr1Cntrl, addr2Contrl, dobControl, ssnControl) {

    let householdMembers = this.constantsService.householdMembers;

    householdMembers.map(indivId => {
      if (indivId.indvdlId == selectedMember) {
        if (addr1Cntrl == null) {
          this.setControlValue(addr2Contrl, indivId.address1 + ',' + indivId.address2);
        } else
          if (addr2Contrl) {
            this.setControlValue(addr2Contrl, indivId.address2);
            this.setControlValue(addr1Cntrl, indivId.address1);
          } else {
            this.setControlValue(addr1Cntrl, indivId.address1 + ',' + indivId.address2);
          }
        if (dobControl) {
          this.setControlValue(dobControl, this.utilService.changeDateFormatYYYYMMDD(indivId.dob));
        }

        if (ssnControl) {
          this.setControlValue(ssnControl, indivId.ssn);
        }
      }
    });
  }

  navigateToPage(typeCd) {
    this.callSave.emit(undefined);
    this.router.navigateByUrl('/qualityControl/questionary-' + typeCd + '?type=' + typeCd);
  }

  calculateSDAmount(value) {

    let result;

    let hhs_allot =
      [
        { hh_Size: 1, standard_deduction: 167 },
        { hh_Size: 2, standard_deduction: 167 },
        { hh_Size: 3, standard_deduction: 167 },
        { hh_Size: 4, standard_deduction: 181 },
        { hh_Size: 5, standard_deduction: 212 },
        { hh_Size: 6, standard_deduction: 243 },
        { hh_Size: 7, standard_deduction: 243 },
        { hh_Size: 8, standard_deduction: 243 },
        { hh_Size: 9, standard_deduction: 243 },
        { hh_Size: 10, standard_deduction: 243 },
        { hh_Size: 11, standard_deduction: 243 },
        { hh_Size: 12, standard_deduction: 243 },
        { hh_Size: 13, standard_deduction: 243 },
        { hh_Size: 14, standard_deduction: 243 },
        { hh_Size: 15, standard_deduction: 243 },
        { hh_Size: 16, standard_deduction: 243 },
        { hh_Size: 17, standard_deduction: 243 },
        { hh_Size: 18, standard_deduction: 243 },
        { hh_Size: 19, standard_deduction: 243 },
        { hh_Size: 20, standard_deduction: 243 },
        { hh_Size: 21, standard_deduction: 243 },
        { hh_Size: 22, standard_deduction: 243 },
        { hh_Size: 23, standard_deduction: 243 },
        { hh_Size: 24, standard_deduction: 0 },
        { hh_Size: 25, standard_deduction: 0 },
        { hh_Size: 26, standard_deduction: 0 },
        { hh_Size: 27, standard_deduction: 0 },
        { hh_Size: 28, standard_deduction: 0 },
        { hh_Size: 29, standard_deduction: 0 },
        { hh_Size: 30, standard_deduction: 0 },
        { hh_Size: 31, standard_deduction: 0 }
      ];

    hhs_allot.map(item => {
      if (item.hh_Size.toString() === value.toString()) {
        result = item.standard_deduction;
      }
    });
    this.setControlValue('361.2.2', result);
  }



  getHH_MaxAllot(hhsize) {
    let result;
    // this.constantsService.getLookUpByName(this.getLookupNameByQCd(qtn)).map(item => {

    // })

    let hhs_allot =
      [
        { hh_Size: 1, max_gros_inc: 1174, max_net_inc: 903, inc_elderly: 1490, max_allot: 259 },
        { hh_Size: 2, max_gros_inc: 1579, max_net_inc: 1215, inc_elderly: 2004, max_allot: 453 },
        { hh_Size: 3, max_gros_inc: 1984, max_net_inc: 1526, inc_elderly: 2518, max_allot: 574 },
        { hh_Size: 4, max_gros_inc: 2389, max_net_inc: 1838, inc_elderly: 3032, max_allot: 695 },
        { hh_Size: 5, max_gros_inc: 2794, max_net_inc: 2150, inc_elderly: 3547, max_allot: 805 },
        { hh_Size: 6, max_gros_inc: 3200, max_net_inc: 2461, inc_elderly: 4061, max_allot: 885 },
        { hh_Size: 7, max_gros_inc: 3605, max_net_inc: 2773, inc_elderly: 4575, max_allot: 995 },
        { hh_Size: 8, max_gros_inc: 4010, max_net_inc: 3085, inc_elderly: 5089, max_allot: 1095 },
        { hh_Size: 9, max_gros_inc: 4416, max_net_inc: 3397, inc_elderly: 5604, max_allot: 1182 },
        { hh_Size: 10, max_gros_inc: 4822, max_net_inc: 3709, inc_elderly: 6119, max_allot: 1278 },
        { hh_Size: 11, max_gros_inc: 5228, max_net_inc: 4021, inc_elderly: 6634, max_allot: 1394 },
        { hh_Size: 12, max_gros_inc: 5634, max_net_inc: 4333, inc_elderly: 7149, max_allot: 1459 },
        { hh_Size: 13, max_gros_inc: 6040, max_net_inc: 4645, inc_elderly: 7664, max_allot: 1549 },
        { hh_Size: 14, max_gros_inc: 6446, max_net_inc: 4957, inc_elderly: 8179, max_allot: 1639 },
        { hh_Size: 15, max_gros_inc: 6852, max_net_inc: 5269, inc_elderly: 8694, max_allot: 1733 },
        { hh_Size: 16, max_gros_inc: 7258, max_net_inc: 5581, inc_elderly: 9209, max_allot: 1846 },
        { hh_Size: 17, max_gros_inc: 7664, max_net_inc: 5893, inc_elderly: 9724, max_allot: 1955 },
        { hh_Size: 18, max_gros_inc: 8070, max_net_inc: 6205, inc_elderly: 10239, max_allot: 2064 },
        { hh_Size: 19, max_gros_inc: 8476, max_net_inc: 6517, inc_elderly: 10754, max_allot: 2173 },
        { hh_Size: 20, max_gros_inc: 8882, max_net_inc: 6829, inc_elderly: 11269, max_allot: 2282 }
      ];

    hhs_allot.map(item => {
      if (item.hh_Size.toString() === hhsize.toString()) {
        result = item.max_allot;
      }
    });
    this.setControlValue('347.5b', result);

    return result;

  }

  openEmail() {
    let value = this.field[0].ccId;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      ccid: value,
      screenId: this.screenType
    };
    const dialogRef = this.dialog.open(SendEmailComponent, dialogConfig);
    dialogRef.updateSize('75%', '85%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log('afterClose', val);
      }

    });
  }

  get currentIndividual() {
    return this.shareService.getDataFromMap('currentIndividual');
  }

  set currentIndividual(id) {
    this.shareService.addDataToMap('currentIndividual', id);
  }
  
  openClarifyDialog(value:any){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      individualId: this.currentIndividual
    };


    const dialogRef = this.dialog.open(ClarifyComponent, dialogConfig);
    dialogRef.updateSize('75%', '85%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        
          
          this.field.filter(fld => fld['qdesc'] === 'Clarification' && fld.qmode =='FN' && fld.qtype === 'checkbox')
            .map(ctrl => {
              if(val.value.clarifyList.filter(item => item.clrfy && item.clrfy.trim().length > 0).length > 0) {
                   this.group.controls[ctrl.name].patchValue(true) ;
                  } else {
                    this.group.controls[ctrl.name].patchValue(false);
                  }
                }
                )
          
        
        console.log('afterClose', val);
      }

    });
  }
 
}
