import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-contribution-charity',
  templateUrl: './contribution-charity.component.html',
  styleUrls: ['./contribution-charity.component.css']
})
export class ContributionCharityComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;
    ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

}
