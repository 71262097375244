import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-dependent-care-deduction',
  templateUrl: './dependent-care-deduction.component.html',
  styleUrls: ['./dependent-care-deduction.component.css']
})
export class DependentCareDeductionComponent  extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  get Is323_1_1():boolean{
    return this.group.controls['323.1.1'].value === 'N';
      }
      changeQf323_1_3(val) {
        if ( val === 'Y') {
          this.group.controls['323.1.3'].disable();
        }else {
          this.group.controls['323.1.3'].enable();
      }

}
}
