import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-tca-tanf',
  templateUrl: './tca-tanf.component.html',
  styleUrls: ['./tca-tanf.component.css']
})
export class TcaTanfComponent extends BaseComponent implements OnInit {


isNotEditable: boolean =false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

  change347_1Q(val){
  if(val === 'N'){
    this.group.controls['347.2'].disable();
    this.group.controls['347.3'].disable();
    this.group.controls['347.4'].disable();
    this.group.controls['347.5a'].disable();
    this.group.controls['347.5b'].disable();
    this.group.controls['347.6'].disable();


  }else if(val === 'Y'){
    this.group.controls['347.2'].enable();
    this.group.controls['347.3'].enable();
    this.group.controls['347.4'].enable();
    this.group.controls['347.5a'].enable();
    this.group.controls['347.5b'].enable();
    this.group.controls['347.6'].enable();
  }
  }
  calculateAllowablePayment(val){
    this.getHH_MaxAllot(val);
  }

}
