import { Component, OnInit, Inject } from '@angular/core';
import { ConstantsService } from '../../common/constants.service';
import { ApiManager } from '../../common/api-manager.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DataShareService } from '../../common/data-share.service';
import { NavigationService } from '../../common/navigation.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppUtilService } from 'src/app/common/app.util.service';

@Component({
  selector: 'app-clarify',
  templateUrl: './clarify.component.html',
  styleUrls: ['./clarify.component.css']
})
export class ClarifyComponent implements OnInit {
  screenType: string ;
  householdMembers: any;
  clarifyForm: FormGroup;
  clarifyData: any;

  constructor(
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    private activeRoute: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private router: Router,
    public constantsService: ConstantsService,
    private apiManager: ApiManager,
    private shareService: DataShareService,
    public appUtilService:AppUtilService,
    public navigationService: NavigationService,
    private fb: FormBuilder,
   ) {
    if (this.activeRoute.snapshot.data.pageData && this.activeRoute.snapshot.data.pageData.memberList) {
      this.householdMembers = this.activeRoute.snapshot.data.pageData.memberList;
      }
      this.setDefaultIndividual();
      if(this.data.screenId){
        this.screenType = this.data.screenId;
      }else{
      this.activeRoute.queryParams.subscribe(queryParams => {
        if (queryParams['type']) {
          this.screenType =  queryParams['type'];
        }
    });
  }
  }

  ngOnInit() {
    this.buildForm();
    this.getPageData();
  }


  get currentIndividual() {
    return this.shareService.getDataFromMap('currentIndividual');
  }

  set currentIndividual(id) {
    this.shareService.addDataToMap('currentIndividual', id);
  }

  setDefaultIndividual() {
    if (this.householdMembers) {
      this.householdMembers.map(hhm => {
        if (this.currentIndividual === undefined  && hhm.headOfHsehldInd === 'Y') {
          this.currentIndividual = hhm.indvdlId;
        }
      });
    }
  }

  buildForm() {
    this.clarifyForm = this.fb.group({
      clarifyList: this.fb.array([
      ])
    });
  }
  
  getPageData() {
    const caseId: any = '';
    const invId: any = this.currentIndividual; // this.memberForm.get('individualId').value;
    const queryParams: any[] = [];
    
    if (invId) {
      queryParams.push(invId);
    }
    queryParams.push(this.screenType); //screen type

    let appCd: string = '';
    appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    let rvwNum = this.constantsService.ReviewInfo.rvwNum;
    queryParams.push(appCd);
    queryParams.push(rvwNum);



    this.apiManager.fetchData('getClarify', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
    .subscribe(
      res => {
        this.clarifyData = res;
        let clrfy; 
        if(this.data.caseRecInfo && this.data.discoveryVerification) {
          clrfy = this.data.caseRecInfo + '\n\n' + this.data.discoveryVerification;
        }else if(this.data.caseRecInfo){
          clrfy = this.data.caseRecInfo;
        }else if(this.data.discoveryVerification){
          clrfy = this.data.discoveryVerification;
        }
        if ( this.clarifyData && this.clarifyData.length === 0  ) {
          this.clarifyData.push( {
            clarifyList: [
              {
                dirtyStatus: ['INSERT'],
                qaClrfyId: '',
                clrfy: '',
              }
            ]
          });
        }
        // this.clarifyForm.patchValue({clarifyList:this.clarifyData});
        this.clarifyData.map(item => {
          (this.clarifyForm.get('clarifyList') as FormArray).push(
            this.fb.group({
              dirtyStatus: item.dirtyStatus,
              qaClrfyId: item.qaClrfyId,
              //EE-68734
              clrfy: item.clrfy? item.clrfy : clrfy
             // clrfy: clrfy
            })
          )
        });
      }
    );


  }


  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }

  save(model) {

    const caseId: any = '';
    const invId: any = this.currentIndividual; // this.memberForm.get('individualId').value;
    let appCd: string = '';
    let drtySts: string = '';
    const queryParams: any[] = [];
    
    
    appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    

    let clarifyObj: any = [];
    model.value.clarifyList.map(clfy =>{
      clarifyObj.push( {
        dirtyStatus : clfy.qaClrfyId  ? 'UPDATE' : 'INSERT' ,
        qaClrfyId : clfy.qaClrfyId,
        clrfy : clfy.clrfy,
        indvdlId: invId,
        scrnId: this.screenType,
        rvwNum: this.constantsService.ReviewInfo.rvwNum ,
        appType: appCd
  
      });
    } );
   


    this.apiManager.fetchData('saveClarify', undefined, ApiManager.POST, clarifyObj, undefined, undefined, queryParams)
    .subscribe(
      res => {
        this.dialogRef.close(clarifyObj);
      }
    );

  }

  closeDialog(frm){
    this.dialogRef.close(frm);
  }

}
