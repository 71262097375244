import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-calcsheet-expensesDeductions',
  templateUrl: './calcsheet-expensesDeductions.component.html',
  styleUrls: ['./calcsheet-expensesDeductions.component.css']
})
export class CalcsheetexpensesDeductionsComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;


  ngOnInit() {
    super.ngOnInit
    this.determineAccess();
    //if 884.5a has a value disable 884.17a
    const controlMapping = {
      '884.5a': '884.17a',
      '884.5b': '884.17b',
      '884.5c': '884.17c',
    };

    for (const [sourceControl, targetControl] of Object.entries(controlMapping)) {
      if (this.getControlValue(sourceControl)) {
        this.setControlValue(targetControl, '');
        this.group.controls[targetControl].disable();
      } else {
        this.group.controls[targetControl].enable();
      }
    }
  }

}





