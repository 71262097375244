import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';


@Component({
  selector: 'app-closing-screen',
  templateUrl: './closing-screen.component.html',
  styleUrls: ['./closing-screen.component.css']
})
export class ClosingScreenComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

  getClosingCode(type) {
    if (type === '361' || type === '365') {
      return type + '.0';
    } 
    if( 
    type === '150' ||
    type === '151' ||
    type === '201' ||
    type === '203' ||
    type === '205' ||
    type === '208' ||
    type === '210' ||
    type === '217' ||
    type === '220' ||
    type === '225' ||
    type === '226' ||
    type === '251' ||
    type === '261' ||
    type === '270' ||
    type === '271' ||
    type === '272' ||
    type === '273' ||
    type === '274' ||
    type === '275' ||
    type === '276' ||
    type === '277' ||
    type === '278' ||
    type === '279' ||
    type === '345' ||
    type === '348' ||
    type === '369' ||
    type === '371' ||
    type === '374' ||
    type === '418' ||
    type === '584' ||
    type === '602' ||
    type === '604' ||
    type === '623' ||
    type === '626' ||
    type === '635' ||
    type === '711' ||
    type === '740' ||
    type === '743' ||
    type === '749' ||
    type === '750' ||
    type === '756' ||
    type === '785' ||
    type === '786' ||
    type === '788' ||
    type === '1052'
    ) {
      return '000';
    }
    if (type === '202'){
      return '120';
    }
    if (type === '231'){
      return '552';
    }
    if (type === '675'){
      return '365'+ '.0';
    }
    if (type === '784'){
      return '301';
    }
    else {
      return type;
    }

    

  }
  OnChangeQ1_1(val) {
    if (val === 'N') {
      this.group.controls['1.2'].disable();
      this.group.controls['1.3'].disable();
      this.group.controls['1.4'].disable();
      this.group.controls['1.5'].disable();
    } else if (val === 'Y') {
      this.group.controls['1.2'].enable();
      this.group.controls['1.3'].enable();
      this.group.controls['1.4'].enable();
      this.group.controls['1.5'].enable();
    }
  }
  
  get Is1_1(): boolean {
    return this.group.controls['1.1'].value === 'N';
  }
  get Is1_1_Y(): boolean {
    return this.group.controls['1.1'].value === 'Y';
  }
  get Is1_2(): boolean {
    return this.group.controls['1.2'].value === 'N';
  }
  get Is1_2_Y(): boolean {
    return this.group.controls['1.2'].value === 'Y';
  }
  get Is2_1(): boolean {
    return this.group.controls['2.1'].value === 'N';
  }
  get Is2_1_Y(): boolean {
    return this.group.controls['2.1'].value === 'Y';
  }
  get Is3_1(): boolean {
    return this.group.controls['3.1'].value === 'N';
  }
  get Is3_1_Y(): boolean {
    return this.group.controls['3.1'].value === 'Y';
  }

  get Is3_2(): boolean {
    return this.group.controls['3.2'].value === 'Y';
  }

  changeQf1_2(){
    if ( this.Is1_1_Y && this.Is1_2 ) {
      this.setControlValue('1.3','2');
      this.setControlValue('1.4','36');
      this.setControlValue('1.5','47');
    }
    else {
      this.setControlValue('1.3','');
      this.setControlValue('1.4','');
      this.setControlValue('1.5','');
    }
  }
  changeQf2_1(){
    if ( this.Is2_1 ) {
      this.setControlValue('2.2','2');
    }
    else {
      this.setControlValue('2.2','');
    }
  }

  changeQf3_3(){
    if ( this.Is3_1_Y && this.Is3_2!==true ) {
      this.setControlValue('3.3','2');
    }
    else {
      this.setControlValue('3.3','');
    }
  }

  
}
