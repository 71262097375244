import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DATE_FORMAT } from 'src/app/shared-module/month-format.directive';

@Component({
  selector: 'app-primary-error',
  templateUrl: './primary-error.component.html',
  styleUrls: ['./primary-error.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ]
})

export class PrimaryErrorComponent  extends BaseComponent implements OnInit {
  subElements: any = [];
  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    if( this.getControlValue('888.1')){
    this.changeSubElementByElement('888.2', this.getControlValue('888.1'));
    }
    this.determineAccess();
  }

  changeSubElementByElement(lkpNameCd, eCd) {
    this.subElements = this.constantsService.getLookUpByName(this.getLookupNameByQCd(lkpNameCd));
    if (this.subElements.length > 0 && eCd) {
      this.subElements = this.subElements.filter(item => item.parentRef === eCd);
    }
  }
}