import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-no-code',
  templateUrl: './no-code.component.html',
  styleUrls: ['./no-code.component.css']
})
export class NoCodeComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
