import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-contributions',
  templateUrl: './contributions.component.html',
  styleUrls: ['./contributions.component.css']
})
export class ContributionsComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;
    ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

}
