import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-identifying-info-work-book',
  templateUrl: './identifying-info-work-book.component.html',
  styleUrls: ['./identifying-info-work-book.component.css']
})
export class IdentifyingInfoWorkBookComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean =false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.setControlValue("700100.5","N/A");
    let intrvDate = localStorage.getItem('qcIntrvwDt'); 
    if(intrvDate != " " && intrvDate){
    if(intrvDate.split('T').length > 0) {
      this.setControlValue("700100.18",intrvDate.split('T')[0]);
    }
   }
  }
}
