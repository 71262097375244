import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-unemployment-benefits',
  templateUrl: './unemployment-benefits.component.html',
  styleUrls: ['./unemployment-benefits.component.css']
})

export class UnemploymentBenefitsComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

  changeQshandling(event: any){
    this.change334_1();
    this.change334_2();
    this.change334_4();
    this.change334_5();
    this.change334_7();
    this.change334_15();
    this.change334_11();
    this.change334_13();
    this.change334_17();
    this.change334_19();
  }
  change334_1(){
  if (this.group.controls['334.1'].value === 'Y') {
    this.group.controls['334.2'].disable();
    this.group.controls['334.3'].disable();
    this.group.controls['334.4'].disable();
    this.group.controls['334.5'].disable();
    this.group.controls['334.6'].disable();
    this.group.controls['334.7'].disable();
    this.group.controls['334.8'].enable();
    this.group.controls['334.9'].enable();
    this.group.controls['334.10'].enable();
    this.group.controls['334.11'].enable();
    this.group.controls['334.12'].enable();
    this.group.controls['334.13'].enable();
    this.group.controls['334.14'].enable();
    this.group.controls['334.14a'].enable();
    this.group.controls['334.14b'].enable();
    this.group.controls['334.14c'].enable();
    this.group.controls['334.14d'].enable();
    this.group.controls['334.14e'].enable();
    this.group.controls['334.15'].disable();
    this.group.controls['334.16'].disable();
    this.group.controls['334.17'].disable();
    this.group.controls['334.18'].disable();
    this.group.controls['334.19'].disable();
    this.group.controls['334.20'].disable();
  }else if(this.group.controls['334.1'].value === 'N'){
    this.group.controls['334.2'].enable();
    this.group.controls['334.3'].enable();
    this.group.controls['334.4'].enable();
    this.group.controls['334.5'].enable();
    this.group.controls['334.6'].enable();
    this.group.controls['334.7'].enable();
    this.group.controls['334.8'].enable();
    this.group.controls['334.9'].enable();
    this.group.controls['334.10'].enable();
    this.group.controls['334.11'].enable();
    this.group.controls['334.12'].enable();
    this.group.controls['334.13'].enable();
    this.group.controls['334.14'].enable();
    this.group.controls['334.14a'].enable();
    this.group.controls['334.14b'].enable();
    this.group.controls['334.14c'].enable();
    this.group.controls['334.14d'].enable();
    this.group.controls['334.14e'].enable();
    this.group.controls['334.15'].enable();
    this.group.controls['334.16'].enable();
    this.group.controls['334.17'].enable();
    this.group.controls['334.18'].enable();
    this.group.controls['334.19'].enable();
    this.group.controls['334.20'].enable();
  }
}

change334_2(){
  if (this.group.controls['334.2'].value === 'Y') {
    this.group.controls['334.3'].enable();
    this.group.controls['334.4'].enable();
    this.group.controls['334.5'].enable();
    this.group.controls['334.6'].enable();
    this.group.controls['334.7'].enable();
    this.group.controls['334.8'].enable();
    this.group.controls['334.9'].enable();
    this.group.controls['334.10'].enable();
    this.group.controls['334.11'].enable();
    this.group.controls['334.12'].enable();
    this.group.controls['334.13'].enable();
    this.group.controls['334.14'].enable();
    this.group.controls['334.14a'].enable();
    this.group.controls['334.14b'].enable();
    this.group.controls['334.14c'].enable();
    this.group.controls['334.14d'].enable();
    this.group.controls['334.14e'].enable();
    this.group.controls['334.15'].enable();
    this.group.controls['334.16'].enable();
    this.group.controls['334.17'].enable();
    this.group.controls['334.18'].enable();
    this.group.controls['334.19'].enable();
    this.group.controls['334.20'].enable();
  }else if(this.group.controls['334.2'].value === 'N'){
    this.group.controls['334.3'].disable();
    this.group.controls['334.4'].disable();
    this.group.controls['334.5'].disable();
    this.group.controls['334.6'].disable();
    this.group.controls['334.7'].disable();
    this.group.controls['334.8'].disable();
    this.group.controls['334.9'].disable();
    this.group.controls['334.10'].disable();
    this.group.controls['334.11'].disable();
    this.group.controls['334.12'].disable();
    this.group.controls['334.13'].disable();
    this.group.controls['334.14'].disable();
    this.group.controls['334.14a'].disable();
    this.group.controls['334.14b'].disable();
    this.group.controls['334.14c'].disable();
    this.group.controls['334.14d'].disable();
    this.group.controls['334.14e'].disable();
    this.group.controls['334.15'].disable();
    this.group.controls['334.16'].disable();
    this.group.controls['334.17'].disable();
    this.group.controls['334.18'].disable();
    this.group.controls['334.19'].disable();
    this.group.controls['334.20'].disable();
  }
}

change334_4(){
  if(this.group.controls['334.4'].value === 'Y'){
    this.group.controls['334.5'].enable();
    this.group.controls['334.6'].enable();
    this.group.controls['334.7'].enable();
    this.group.controls['334.8'].enable();
    this.group.controls['334.9'].enable();
    this.group.controls['334.10'].enable();
    this.group.controls['334.11'].enable();
    this.group.controls['334.12'].enable();
    this.group.controls['334.13'].enable();
    this.group.controls['334.14'].enable();
    this.group.controls['334.14a'].enable();
    this.group.controls['334.14b'].enable();
    this.group.controls['334.14c'].enable();
    this.group.controls['334.14d'].enable();
    this.group.controls['334.14e'].enable();
    this.group.controls['334.15'].enable();
    this.group.controls['334.16'].enable();
    this.group.controls['334.17'].enable();
    this.group.controls['334.18'].enable();
    this.group.controls['334.19'].enable();
    this.group.controls['334.20'].enable();
  }else 
  if(this.group.controls['334.4'].value === 'N'){
      this.group.controls['334.5'].disable();
      this.group.controls['334.6'].disable();
      this.group.controls['334.7'].disable();
      this.group.controls['334.8'].disable();
      this.group.controls['334.9'].disable();
      this.group.controls['334.10'].disable();
      this.group.controls['334.11'].disable();
      this.group.controls['334.12'].disable();
      this.group.controls['334.13'].disable();
      this.group.controls['334.14'].disable();
      this.group.controls['334.14a'].disable();
      this.group.controls['334.14b'].disable();
      this.group.controls['334.14c'].disable();
      this.group.controls['334.14d'].disable();
      this.group.controls['334.14e'].disable();
      this.group.controls['334.15'].disable();
      this.group.controls['334.16'].disable();
      this.group.controls['334.17'].disable();
      this.group.controls['334.18'].disable();
      this.group.controls['334.19'].disable();
      this.group.controls['334.20'].disable();
  }
}

change334_5(){
  if(this.group.controls['334.5'].value === 'Y'){
    this.group.controls['334.6'].disable();
    this.group.controls['334.7'].enable();
    this.group.controls['334.8'].enable();
    this.group.controls['334.9'].enable();
    this.group.controls['334.10'].enable();
    this.group.controls['334.11'].enable();
    this.group.controls['334.12'].enable();
    this.group.controls['334.13'].enable();
    this.group.controls['334.14'].enable();
    this.group.controls['334.14a'].enable();
    this.group.controls['334.14b'].enable();
    this.group.controls['334.14c'].enable();
    this.group.controls['334.14d'].enable();
    this.group.controls['334.14e'].enable();
    this.group.controls['334.15'].enable();
    this.group.controls['334.16'].enable();
    this.group.controls['334.17'].enable();
    this.group.controls['334.18'].enable();
    this.group.controls['334.19'].enable();
    this.group.controls['334.20'].enable();
  }else if(this.group.controls['334.5'].value === 'N'){
    this.group.controls['334.6'].enable();
    this.group.controls['334.7'].disable();
    this.group.controls['334.8'].disable();
    this.group.controls['334.9'].disable();
    this.group.controls['334.10'].disable();
    this.group.controls['334.11'].disable();
    this.group.controls['334.12'].disable();
    this.group.controls['334.13'].disable();
    this.group.controls['334.14'].disable();
    this.group.controls['334.14a'].disable();
    this.group.controls['334.14b'].disable();
    this.group.controls['334.14c'].disable();
    this.group.controls['334.14d'].disable();
    this.group.controls['334.14e'].disable();
    this.group.controls['334.15'].disable();
    this.group.controls['334.16'].disable();
    this.group.controls['334.17'].disable();
    this.group.controls['334.18'].disable();
    this.group.controls['334.19'].disable();
    this.group.controls['334.20'].disable();
  }
}

change334_7(){
  if(this.group.controls['334.7'].value === 'Y'){
    this.group.controls['334.8'].enable();
    this.group.controls['334.9'].enable();
    this.group.controls['334.10'].enable();
    this.group.controls['334.11'].enable();
    this.group.controls['334.12'].enable();
    this.group.controls['334.13'].enable();
    this.group.controls['334.14'].enable();
    this.group.controls['334.14a'].enable();
    this.group.controls['334.14b'].enable();
    this.group.controls['334.14c'].enable();
    this.group.controls['334.14d'].enable();
    this.group.controls['334.14e'].enable();
    this.group.controls['334.15'].disable();
    this.group.controls['334.16'].disable();
    this.group.controls['334.17'].disable();
    this.group.controls['334.18'].disable();
    this.group.controls['334.19'].disable();
    this.group.controls['334.20'].disable();
  }else if(this.group.controls['334.7'].value === 'N'){
    this.group.controls['334.8'].disable();
    this.group.controls['334.9'].disable();
    this.group.controls['334.10'].disable();
    this.group.controls['334.11'].disable();
    this.group.controls['334.12'].disable();
    this.group.controls['334.13'].disable();
    this.group.controls['334.14'].disable();
    this.group.controls['334.14a'].disable();
    this.group.controls['334.14b'].disable();
    this.group.controls['334.14c'].disable();
    this.group.controls['334.14d'].disable();
    this.group.controls['334.14e'].disable();
    this.group.controls['334.15'].enable();
    this.group.controls['334.16'].enable();
    this.group.controls['334.17'].enable();
    this.group.controls['334.18'].enable();
    this.group.controls['334.19'].enable();
    this.group.controls['334.20'].enable();
  }
}
change334_15(){
  if(this.group.controls['334.15'].value === 'Y'){
    this.group.controls['334.16'].enable();
    this.group.controls['334.17'].enable();
    this.group.controls['334.18'].enable();
    this.group.controls['334.19'].disable();
    this.group.controls['334.20'].disable();
  }else if(this.group.controls['334.15'].value === 'N'){
    this.group.controls['334.16'].disable();
    this.group.controls['334.17'].disable();
    this.group.controls['334.18'].disable();
    this.group.controls['334.19'].enable();
    this.group.controls['334.20'].enable();
  }
}
change334_19(){
  if(this.group.controls['334.19'].value === 'Y'){
    this.group.controls['334.20'].enable();
  }else if(this.group.controls['334.19'].value === 'N'){
    this.group.controls['334.20'].disable();
  }
}
change334_17(){
  if(this.group.controls['334.17'].value === 'Y'){
    this.group.controls['334.18'].enable();
  }else if(this.group.controls['334.17'].value === 'N'){
    this.group.controls['334.18'].disable();
  }
}
change334_11(){
  if(this.group.controls['334.11'].value === 'Y'){
    this.group.controls['334.12'].enable();
  }else if(this.group.controls['334.11'].value === 'N'){
    this.group.controls['334.12'].disable();
  }
}
change334_13(){
  if(this.group.controls['334.13'].value === 'Y'){
    this.group.controls['334.14'].enable();
    this.group.controls['334.14a'].enable();
    this.group.controls['334.14b'].enable();
    this.group.controls['334.14c'].enable();
    this.group.controls['334.14d'].enable();
    this.group.controls['334.14e'].enable();
  }else if(this.group.controls['334.13'].value === 'N'){
    this.group.controls['334.14'].disable();
    this.group.controls['334.14a'].disable();
    this.group.controls['334.14b'].disable();
    this.group.controls['334.14c'].disable();
    this.group.controls['334.14d'].disable();
    this.group.controls['334.14e'].disable();
  }
}
}