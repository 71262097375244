import { Injectable } from '@angular/core';
import { forkJoin as observableForkJoin,  Observable, of, EMPTY } from "rxjs";
import {tap, map} from 'rxjs/operators';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from "@angular/router";
import { ApiManager } from "../../common/api-manager.service";
import { ConstantsService } from '../../common/constants.service';
import { NavigationService } from '../../common/navigation.service';
import { AppUtilService } from 'src/app/common/app.util.service';

@Injectable()
export class QuestionaryResolver implements Resolve<Observable<any[]>>{

  constructor(private router: Router,
              public constantsService: ConstantsService,
              private apiManager: ApiManager,
              private utilService: AppUtilService,
              public navigationService: NavigationService) {
                if (!this.constantsService.ReviewInfo) {
                  this.router.navigate([this.constantsService.getNavigateToRvwList()]);
                }
               }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    if ( route.queryParams['type']) {
    return observableForkJoin(this.fetchMemberList(), this.fetchPageData(route.queryParams['type'])).pipe(
      map(([memberList, pageFieldConfig]) => ({ memberList, pageFieldConfig })));
    } else {
      return observableForkJoin(this.fetchMemberList()).pipe(
        map(([memberList]) => ({ memberList })));
      }
  }

  fetchMemberList(): Observable<any> {
    const queryParams: any[] = [];
    if (this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    } else {
      queryParams.push(115);
    }

    queryParams.push(this.constantsService.getAppCd(this.navigationService.appCd));
    if (this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwType) {
        
          queryParams.push(this.constantsService.ReviewInfo.rvwType);
        

    } else {
      queryParams.push('TCA');
    }

    return this.apiManager.fetchData('getMemberList', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
    .pipe(
      map((res: Response) => {
        this.constantsService.householdMembers = res;
        return res;
      }
    ));
  }

  fetchPageData(pgType): Observable<any> {
    

    const caseId: any = '';
    let invId: any ;
    if ( !this.constantsService.householdMembers ) {
      invId = 0;
    } else {
      this.constantsService.householdMembers.map(hhm => {
        if ( hhm.headOfHsehldInd === 'Y' ) {
          invId = hhm.indvdlId;
        }
      });
    }
    const queryParams: any[] = [];
    queryParams.push(pgType); //screen type
    if (invId && !this.constantsService.ReviewInfo.bpeLdssEvltnId) {
      queryParams.push(invId);
    } else {
      queryParams.push(0); // for evualtion flow
    }


    if (this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }

    if (this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwType) {
        if (!pgType.startsWith('5001') ) {
          queryParams.push(this.constantsService.ReviewInfo.rvwType);
        } else {
          queryParams.push('QCForm');
        }
      
    } else {
      queryParams.push('Evaluation');
    }
    ConstantsService.loaderFlag = true;
    return this.apiManager.fetchData('getReviewQ', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
    .pipe(
      map((res: Response) => {
        this.utilService.hideLoader();
        return res;
      }
    ));
  }
}

