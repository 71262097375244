import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-earned-income-list-of-employer',
  templateUrl: './earned-income-list-of-employer.component.html',
  styleUrls: ['./earned-income-list-of-employer.component.css']
})
export class EarnedIncomeListOfEmployerComponent extends BaseComponent implements OnInit {

  currentPage: number = 0;
  isNotEditable: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  clearInputMethod() { this.group.reset();  }

  

}
