import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-blind-phone-call',
  templateUrl: './blind-phone-call.component.html',
  styleUrls: ['./blind-phone-call.component.css']
})
export class BlindPhoneCallComponent extends BaseComponent implements OnInit {
  currentPage: number = 0;
  ngOnInit() {
    super.ngOnInit();
  }

}
