import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnInit,
  ViewContainerRef,
  EventEmitter,
  Output
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig, PageConfig } from "../../components/field.interface";
import { InputComponent } from "../input/input.component";
import { ButtonComponent } from "../button/button.component";
import { SelectComponent } from "../select/select.component";
import { DateComponent } from "../date/date.component";
import { RadiobuttonComponent } from "../radiobutton/radiobutton.component";
import { CheckboxComponent } from "../checkbox/checkbox.component";
import { DobComponent } from '../../components/dob/dob.component';
import { CitizenComponent } from '../../components/citizen/citizen.component';
import { SsnComponent } from '../../components/ssn/ssn.component';
import { ResidencyComponent } from '../../components/residency/residency.component';
import { StudentStatusComponent } from '../../components/student-status/student-status.component';
import { RecipientDisqualificationComponent } from '../../components/recipient-disqualification/recipient-disqualification.component';
import { EmploymentTrainingComponent } from '../../components/employment-training/employment-training.component';
import { HouseholdComponent } from '../../components/household/household.component';
import { HouseholdBoarderComponent } from '../../components/household-boarder/household-boarder.component';
import { HouseholdGroupHomeComponent } from '../../components/household-group-home/household-group-home.component';
import { HouseholdTDAAComponent } from '../../components/household-tdaa/household-tdaa.component';
import { WorkRegistrationRequirementsComponent } from '../../components/work-registration-requirements/work-registration-requirements.component';
import { VoluntaryQuitComponent } from '../../components/voluntary-quit/voluntary-quit.component';
import { NonRecurringLumpSumPaymentComponent } from '../../components/non-recurring-lump-sum-payment/non-recurring-lump-sum-payment.component';
import { LimitedTimeParticipationComponent } from '../../components/limited-time-participation/limited-time-participation.component';
import { AcceptanceOfEmploymentComponent } from '../../components/acceptance-of-employment/acceptance-of-employment.component';
import { BankAccountCashOnHandComponent } from '../../components/bank-account-cash-on-hand/bank-account-cash-on-hand.component';
import { EmploymentStatusJobAvailabilityComponent } from '../../components/employment-status-job-availability/employment-status-job-availability.component';
import { HhcompDisabledLivewithOthersComponent } from '../../components/hhcomp-disabled-livewith-others/hhcomp-disabled-livewith-others.component';
import { HhCompHomelessShelterComponent } from '../../components/hh-comp-homeless-shelter/hh-comp-homeless-shelter.component';
import { LiquidAssetsComponent } from '../../components/liquid-assets/liquid-assets.component';
import { WorkfareComponent } from '../../components/workfare/workfare.component';
import { HhCompPeopleInHHComponent } from '../../components/hh-comp-people-in-hh/hh-comp-people-in-hh.component';
import { HhCompBenfitEstimateComponent } from '../../components/hh-comp-benfit-estimate/hh-comp-benfit-estimate.component';
import { MotorVehiclesComponent } from '../../components/motor-vehicles/motor-vehicles.component';
import { NonLiquidAssetsComponent } from '../../components/non-liquid-assets/non-liquid-assets.component';
import { CombinedResourcesComponent } from '../../components/combined-resources/combined-resources.component';
import { EarnedIncomeEmploymentQuestionsComponent } from '../../components/earned-income-employment-questions/earned-income-employment-questions.component';
import { SelfEmploymentComponent } from '../../components/self-employment/self-employment.component';
import { EmployStatusSeEiDeductionComponent } from 'src/app/components/employ-status-se-ei-deduction/employ-status-se-ei-deduction.component';
import { CaseRecordAnalysisComponent } from '../../components/case-record-analysis/case-record-analysis.component';
import { SelfEmpBusinessNamesComponent } from '../../components/self-emp-business-names/self-emp-business-names.component';
import { OtherEarnedIncomeComponent } from '../../components/other-earned-income/other-earned-income.component';
import { EarnedIncomeDeductionsComponent } from '../../components/earned-income-deductions/earned-income-deductions.component';
import { DependentCareDeductionComponent } from '../../components/dependent-care-deduction/dependent-care-deduction.component';
import { RsdiBenefitsComponent } from '../../components/rsdi-benefits/rsdi-benefits.component';
import { VaBenefitsComponent } from '../../components/va-benefits/va-benefits.component';
import { SsiBenefitsComponent } from '../../components/ssi-benefits/ssi-benefits.component';
import { UnemploymentBenefitsComponent } from '../../components/unemployment-benefits/unemployment-benefits.component';
import { WorkersCompensationComponent } from '../../components/workers-compensation/workers-compensation.component';
import { OtherGovernmentBenefitsComponent } from 'src/app/components/other-government-benefits/other-government-benefits.component';
import { ContributionsComponent } from '../../components/contributions/contributions.component';
import { DeemedIncomeComponent } from '../../components/deemed-income/deemed-income.component';
import { GeneralAssistanceComponent } from '../../components/general-assistance/general-assistance.component';
import { ChildSupportComponent } from '../../components/child-support/child-support.component';
import { AbsentParentNotpayingChildsupportComponent } from '../../components/absent-parent-notpaying-childsupport/absent-parent-notpaying-childsupport.component';
import { EducationalGrantsComponent } from '../../components/educational-grants/educational-grants.component';
import { StandardDeductionComponent } from '../../components/standard-deduction/standard-deduction.component';
import { DemonstrationProjectsComponent } from '../../components/demonstration-projects/demonstration-projects.component';
import { TransitionalBenefitsComponent } from '../../components/transitional-benefits/transitional-benefits.component';
import { MedicalDeductionsComponent } from '../../components/medical-deductions/medical-deductions.component';
import { UtilityAllowanceComponent } from '../../components/utility-allowance/utility-allowance.component';
import { ShelterCostExpensesComponent } from '../../components/shelter-cost-expenses/shelter-cost-expenses.component';
import { EarnedIncomeEmploymentQuestiosPart2Component } from '../../components/earned-income-employment-questios-part2/earned-income-employment-questios-part2.component';
import { EarnedIncomeListOfEmployerComponent } from '../../components/earned-income-list-of-employer/earned-income-list-of-employer.component';
import { NonliquidAssetsBuildingComponent } from '../../components/nonliquid-assets-building/nonliquid-assets-building.component';
import { NonliquidAssetsFactoryComponent } from '../../components/nonliquid-assets-factory/nonliquid-assets-factory.component';
import { NonliquidAssetsHouseComponent } from '../../components/nonliquid-assets-house/nonliquid-assets-house.component';
import { NonliquidAssetsLandComponent } from '../../components/nonliquid-assets-land/nonliquid-assets-land.component';
import { NonliquidAssetsOfficeComponent } from '../../components/nonliquid-assets-office/nonliquid-assets-office.component';
import { NonliquidAssetsBoatTrailerComponent } from '../../components/nonliquid-assets-boat-trailer/nonliquid-assets-boat-trailer.component';
import { NonliquidAssetsOthersSpecifyComponent } from '../../components/nonliquid-assets-others-specify/nonliquid-assets-others-specify.component';
import { ClosingScreenComponent } from 'src/app/quality-control/fs-negative/closing-screen/closing-screen.component';
import { NoEligibleMemberComponent } from 'src/app/quality-control/fs-negative/no-eligible-member/no-eligible-member.component';
import { NoCodeComponent } from 'src/app/quality-control/fs-negative/no-code/no-code.component';
import { ImmigrantEligibilityComponent } from 'src/app/quality-control/fs-negative/immigrant-eligibility/immigrant-eligibility.component';
import { NoReqVerificationInfoComponent } from 'src/app/quality-control/fs-negative/no-req-verification-info/no-req-verification-info.component';
import { UnderPriorPenaltyComponent } from 'src/app/quality-control/fs-negative/under-prior-penalty/under-prior-penalty.component';
import { HohDeceasedComponent } from 'src/app/quality-control/fs-negative/hoh-deceased/hoh-deceased.component';
import { HohLivingArrangementsComponent } from 'src/app/quality-control/fs-negative/hoh-living-arrangements/hoh-living-arrangements.component';
import { HohFailedResidencyComponent } from 'src/app/quality-control/fs-negative/hoh-failed-residency/hoh-failed-residency.component';
import { TotalIncomeOverLimitComponent } from 'src/app/quality-control/fs-negative/total-income-over-limit/total-income-over-limit.component';
import { GrossIncomeOverlimitComponent } from 'src/app/quality-control/fs-negative/gross-income-overlimit/gross-income-overlimit.component';
import { FailedToProvideVerificationComponent } from 'src/app/quality-control/fs-negative/failed-to-provide-verification/failed-to-provide-verification.component';
import { PropertyOverAssetLimitComponent } from 'src/app/quality-control/fs-negative/property-over-asset-limit/property-over-asset-limit.component';
import { BecameEmployedComponent } from 'src/app/quality-control/fs-negative/became-employed/became-employed.component';
import { FailedToComplyJobPrg1Component } from 'src/app/quality-control/fs-negative/failed-to-comply-job-prg1/failed-to-comply-job-prg1.component';
import { FailedToComplyJobPrg2Component } from 'src/app/quality-control/fs-negative/failed-to-comply-job-prg2/failed-to-comply-job-prg2.component';
import { FailedToComplyJobPrg3Component } from 'src/app/quality-control/fs-negative/failed-to-comply-job-prg3/failed-to-comply-job-prg3.component';
import { FailedToComplyUpfrontJobSearchComponent } from 'src/app/quality-control/fs-negative/failed-to-comply-upfront-job-search/failed-to-comply-upfront-job-search.component';
import { FailedToCooperatewithChildSupportComponent } from 'src/app/quality-control/fs-negative/failed-to-cooperatewith-child-support/failed-to-cooperatewith-child-support.component';
import { ClosedToAvoidDualParticipationComponent } from 'src/app/quality-control/fs-negative/closed-to-avoid-dual-participation/closed-to-avoid-dual-participation.component';
import { MinorParentFailedLivingArrangementsComponent } from 'src/app/quality-control/fs-negative/minor-parent-failed-living-arrangements/minor-parent-failed-living-arrangements.component';
import { MaxBenefitsRecievedAnthrStateComponent } from 'src/app/quality-control/fs-negative/max-benefits-recieved-anthr-state/max-benefits-recieved-anthr-state.component';
import { DenialCodeForInterviewComponent } from 'src/app/quality-control/fs-negative/denial-code-for-interview/denial-code-for-interview.component';
import { MaxBenefitsRecievedMdComponent } from 'src/app/quality-control/fs-negative/max-benefits-recieved-md/max-benefits-recieved-md.component';
import { MaxBenefitsRecievedMdpriorStofRsdnceComponent } from 'src/app/quality-control/fs-negative/max-benefits-recieved-mdprior-stof-rsdnce/max-benefits-recieved-mdprior-stof-rsdnce.component';
import { PreviouslyReceivedWAagComponent } from 'src/app/quality-control/fs-negative/previously-received-waag/previously-received-waag.component';
import { AfInternationalprogramViolation3Component } from 'src/app/quality-control/fs-negative/af-internationalprogram-violation3/af-internationalprogram-violation3.component';
import { AfInternationalprogramViolation2Component } from 'src/app/quality-control/fs-negative/af-internationalprogram-violation2/af-internationalprogram-violation2.component';
import { AfInternationalprogramViolation1Component } from 'src/app/quality-control/fs-negative/af-internationalprogram-violation1/af-internationalprogram-violation1.component';
// import { HouseholdUnboundComponent } from 'src/app/quality-control/tca/household-unbound/household-unbound.component';
// import { EarnedEmployerComponent } from 'src/app/quality-control/tca/earned-employer/earned-employer.component';
// import { EarnedIncomeComponent } from 'src/app/quality-control/tca/earned-income/earned-income.component';
// import { UnearnedSourceComponent } from 'src/app/quality-control/tca/unearned-source/unearned-source.component';
// import { UnearnedContributionComponent } from 'src/app/quality-control/tca/unearned-contribution/unearned-contribution.component';
// import { SponsorIncomeComponent } from 'src/app/quality-control/tca/sponsor-income/sponsor-income.component';
// import { SponsorDeductionComponent } from 'src/app/quality-control/tca/sponsor-deduction/sponsor-deduction.component';
// import { StepParentIncomeComponent } from 'src/app/quality-control/tca/step-parent-income/step-parent-income.component';
// import { StepParentDeductionComponent } from 'src/app/quality-control/tca/step-parent-deduction/step-parent-deduction.component';
// import { DependentCarePocComponent } from 'src/app/quality-control/tca/dependent-care-poc/dependent-care-poc.component';
// import { DependentCareKididComponent } from 'src/app/quality-control/tca/dependent-care-kidid/dependent-care-kidid.component';
// import { DependentCareIncomeComponent } from 'src/app/quality-control/tca/dependent-care-income/dependent-care-income.component';
// import { ChildSupportPaymentComponent } from 'src/app/quality-control/tca/child-support-payment/child-support-payment.component';
// import { ChildSupportApidComponent } from 'src/app/quality-control/tca/child-support-apid/child-support-apid.component';
// import { ChildSupportApadComponent } from 'src/app/quality-control/tca/child-support-apad/child-support-apad.component';
// import { ChildSupportCas1Component } from 'src/app/quality-control/tca/child-support-cas1/child-support-cas1.component';
// import { ChildSupportCas2Component } from 'src/app/quality-control/tca/child-support-cas2/child-support-cas2.component';
// import { ChildSupportPay1Component } from 'src/app/quality-control/tca/child-support-pay1/child-support-pay1.component';
// import { ChildSupportPay2Component } from 'src/app/quality-control/tca/child-support-pay2/child-support-pay2.component';
// import { HousingExpensesComponent } from 'src/app/quality-control/tca/housing-expenses/housing-expenses.component';
// import { HousingMiscComponent } from 'src/app/quality-control/tca/housing-misc/housing-misc.component';
// import { PpiDisallowComponent } from 'src/app/quality-control/tca/ppi-disallow/ppi-disallow.component';
// import { WorkStatusComponent } from 'src/app/quality-control/tca/work-status/work-status.component';
// import { WorkEligibilityComponent } from 'src/app/quality-control/tca/work-eligibility/work-eligibility.component';
// import { WorkAdditionalComponent } from 'src/app/quality-control/tca/work-additional/work-additional.component';
// import { WorkConcilliationComponent } from 'src/app/quality-control/tca/work-concilliation/work-concilliation.component';
// import { WorkAttendanceComponent } from 'src/app/quality-control/tca/work-attendance/work-attendance.component';
import { ChildAcceptedIntoFacilityComponent } from 'src/app/quality-control/fs-negative/child-accepted-into-facility/child-accepted-into-facility.component';
import { BenefitsPreviouslyRecievedComponent } from 'src/app/quality-control/fs-negative/benefits-previously-recieved/benefits-previously-recieved.component';
import { ClientIdErrorComponent } from 'src/app/quality-control/fs-negative/client-id-error/client-id-error.component';
import { WorkerVoidedApplicationComponent } from 'src/app/quality-control/fs-negative/worker-voided-application/worker-voided-application.component';
import { FailedPhotoRequirementsComponent } from 'src/app/quality-control/fs-negative/failed-photo-requirements/failed-photo-requirements.component';
import { NotParticipatingRehabilitativeProgramComponent } from 'src/app/quality-control/fs-negative/not-participating-rehabilitative-program/not-participating-rehabilitative-program.component';
import { DidNotTrytoObtainGovBftsComponent } from 'src/app/quality-control/fs-negative/did-not-tryto-obtain-gov-bfts/did-not-tryto-obtain-gov-bfts.component';
import { DisabilityOfLessThan3nthsComponent } from 'src/app/quality-control/fs-negative/disability-of-less-than3nths/disability-of-less-than3nths.component';
import { CstmDeclinedTFSComponent } from 'src/app/quality-control/fs-negative/cstm-declined-tfs/cstm-declined-tfs.component';
import { NtElgToRecMSNAPComponent } from 'src/app/quality-control/fs-negative/nt-elg-to-rec-msnap/nt-elg-to-rec-msnap.component';
import { NonCooperationWthFedQCReqComponent } from 'src/app/quality-control/fs-negative/non-cooperation-wth-fed-qcreq/non-cooperation-wth-fed-qcreq.component';
import { RefusedToSignLienComponent } from 'src/app/quality-control/fs-negative/refused-to-sign-lien/refused-to-sign-lien.component';
import { NonCooperationWithEligProcComponent } from 'src/app/quality-control/fs-negative/non-cooperation-with-elig-proc/non-cooperation-with-elig-proc.component';
import { AlcoholDrugFacilityLostCertComponent } from 'src/app/quality-control/fs-negative/alcohol-drug-facility-lost-cert/alcohol-drug-facility-lost-cert.component';
import { AssistanceUnitMarriageComponent } from 'src/app/quality-control/fs-negative/assistance-unit-marriage/assistance-unit-marriage.component';
import { VoluntaryWithdrawlApplicationComponent } from 'src/app/quality-control/fs-negative/voluntary-withdrawl-application/voluntary-withdrawl-application.component';
import { WhereaboutsUnknownsComponent } from 'src/app/quality-control/fs-negative/whereabouts-unknowns/whereabouts-unknowns.component';
import { FailureToGiveInfoComponent } from 'src/app/quality-control/fs-negative/failure-to-give-info/failure-to-give-info.component';
import { NonCooperationWithEArequirementsComponent } from 'src/app/quality-control/fs-negative/non-cooperation-with-earequirements/non-cooperation-with-earequirements.component';
import { ClientMovedOutofStateComponent } from 'src/app/quality-control/fs-negative/client-moved-outof-state/client-moved-outof-state.component';
import { ApplicationOpenInErrorComponent } from 'src/app/quality-control/fs-negative/application-open-in-error/application-open-in-error.component';
import { NoncooperationWithQcRequirementsComponent } from 'src/app/quality-control/fs-negative/noncooperation-with-qc-requirements/noncooperation-with-qc-requirements.component';
import { IndividualIDRequestedClosureComponent } from 'src/app/quality-control/fs-negative/individual-id-requested-closure/individual-id-requested-closure.component';
import { FailureToApplyForPotentialIncomeComponent } from 'src/app/quality-control/fs-negative/failure-to-apply-for-potential-income/failure-to-apply-for-potential-income.component';
import { ClientDiscrepancyNameErrorComponent } from 'src/app/quality-control/fs-negative/client-discrepancy-name-error/client-discrepancy-name-error.component';
import { PresumptiveEligibilityFailedAtFormalApplicationComponent } from 'src/app/quality-control/fs-negative/presumptive-eligibility-failed-at-formal-application/presumptive-eligibility-failed-at-formal-application.component';
import { MisrepRestoRecMulBenComponent } from 'src/app/quality-control/fs-negative/misrep-resto-rec-mul-ben/misrep-resto-rec-mul-ben.component';
import { ConDrgFelonComponent } from 'src/app/quality-control/fs-negative/con-drg-felon/con-drg-felon.component';
import { ABAWDReqComponent } from 'src/app/quality-control/fs-negative/abawdreq/abawdreq.component';
import { CtzReqComponent } from 'src/app/quality-control/fs-negative/ctz-req/ctz-req.component';
import { DidNotTrytoObtainLegalCompenComponent } from 'src/app/quality-control/fs-negative/did-not-tryto-obtain-legal-compen/did-not-tryto-obtain-legal-compen.component';
import { FleeingFelonComponent } from 'src/app/quality-control/fs-negative/fleeing-felon/fleeing-felon.component';
import { FspPreReviewCasesComponent } from 'src/app/bpe-app/fsme/fsme/fsp-pre-review-cases/fsp-pre-review-cases.component';
import { FsAppsFiledComponent } from 'src/app/bpe-app/fsme/fsme/fs-apps-filed/fs-apps-filed.component';
import { MyDhrOnlineAppsComponent } from 'src/app/bpe-app/fsme/fsme/my-dhr-online-apps/my-dhr-online-apps.component';
import { RedetsComponent } from 'src/app/bpe-app/fsme/fsme/redets/redets.component';
import { DenialsComponent } from 'src/app/bpe-app/fsme/fsme/denials/denials.component';
import { ClosingsComponent } from 'src/app/bpe-app/fsme/fsme/closings/closings.component';
import { AbawdActivesComponent } from 'src/app/bpe-app/fsme/fsme/abawd-actives/abawd-actives.component';
import { AbawdClosuresComponent } from 'src/app/bpe-app/fsme/fsme/abawd-closures/abawd-closures.component';
import { ReceipientIntegrityComponent } from 'src/app/bpe-app/fsme/fsme/receipient-integrity/receipient-integrity.component';
import { SvesPrisonMatchComponent } from 'src/app/bpe-app/fsme/fsme/sves-prison-match/sves-prison-match.component';
import { DeathMatchComponent } from 'src/app/bpe-app/fsme/fsme/death-match/death-match.component';
import { NewHireAlertsComponent } from 'src/app/bpe-app/fsme/fsme/new-hire-alerts/new-hire-alerts.component';
import { RecipientClaimsComponent } from 'src/app/bpe-app/fsme/fsme/recipient-claims/recipient-claims.component';
import { EbtMultipleIssuancesComponent } from 'src/app/bpe-app/fsme/fsme/ebt-multiple-issuances/ebt-multiple-issuances.component';
import { CsCustomerPgmAccessComponent } from 'src/app/bpe-app/fsme/interview/cs-customer-pgm-access/cs-customer-pgm-access.component';
import { CsStaffPgmAccessComponent } from 'src/app/bpe-app/fsme/interview/cs-staff-pgm-access/cs-staff-pgm-access.component';
import { EbtModuleComponent } from 'src/app/bpe-app/fsme/interview/ebt-module/ebt-module.component';
import { ReviewerObservationsComponent } from 'src/app/bpe-app/fsme/interview/reviewer-observations/reviewer-observations.component';
import { VoterRegistrationReviewerComponent } from 'src/app/bpe-app/fsme/interview/voter-registration-reviewer/voter-registration-reviewer.component';
import { CaInitiativesImplementedComponent } from 'src/app/bpe-app/fsme/interview/ca-initiatives-implemented/ca-initiatives-implemented.component';
import { BlindPhoneCallComponent } from 'src/app/bpe-app/fsme/interview/blind-phone-call/blind-phone-call.component';
import { CboInterviewComponent } from 'src/app/bpe-app/fsme/interview/cbo-interview/cbo-interview.component';
import { CaBestPracticesComponent } from 'src/app/bpe-app/fsme/interview/ca-best-practices/ca-best-practices.component';
import { PaCaseReviewComponent } from 'src/app/bpe-app/payment-accuracy/payment-accuracy/pa-case-review/pa-case-review.component';
import { WprCaseReviewComponent } from 'src/app/bpe-app/wpr/wpr/wpr-case-review/wpr-case-review.component';
import { NationalNewHireComponent } from 'src/app/bpe-app/monitoring/monitoring-alerts/national-new-hire/national-new-hire.component';
import { ParisAlertComponent } from 'src/app/bpe-app/monitoring/monitoring-alerts/paris-alert/paris-alert.component';
import { StateHireComponent } from 'src/app/bpe-app/monitoring/monitoring-alerts/state-hire/state-hire.component';
import { PreReivewDirectComponent } from 'src/app/bpe-app/monitoring/monitoring-alerts/pre-reivew-direct/pre-reivew-direct.component';
import { PrisonMatchComponent } from 'src/app/bpe-app/monitoring/monitoring/prison-match/prison-match.component';
import { ChildSupportMonitorComponent } from 'src/app/bpe-app/monitoring/monitoring/child-support-monitor/child-support-monitor.component';
import { ReactivationsComponent } from 'src/app/bpe-app/monitoring/monitoring/reactivations/reactivations.component';
import { OverpaymentBegsComponent } from 'src/app/bpe-app/monitoring/monitoring/overpayment-begs/overpayment-begs.component';
import { MonitoringMemosComponent } from 'src/app/bpe-app/monitoring/monitoring-memos/monitoring-memos/monitoring-memos.component';
import { PrimaryErrorComponent } from 'src/app/components/primary-error/primary-error.component';
import { SecondaryErrorComponent } from 'src/app/components/secondary-error/secondary-error.component';
import { Qc16FrontPageComponent } from 'src/app/components/qc16-front-page/qc16-front-page.component';
import { Qc16ErrorDescriptionComponent } from 'src/app/components/qc16-error-description/qc16-error-description.component';
import { Qc16AdditionalInformationComponent } from 'src/app/components/qc16-additional-information/qc16-additional-information.component';
import { Qc16NarrationComponent } from 'src/app/components/qc16-narration/qc16-narration.component';
import { Qc16NarrationTwoComponent } from 'src/app/components/qc16-narration-two/qc16-narration-two.component';
import { Qc16ConcludingPageComponent } from 'src/app/components/qc16-concluding-page/qc16-concluding-page.component';
import { Qc16ConvertTopdfComponent } from 'src/app/components/qc16-convert-topdf/qc16-convert-topdf.component';
import { Qc16FsnegFrontpageComponent } from 'src/app/quality-control/fs-negative/qc16-fsneg-frontpage/qc16-fsneg-frontpage.component';
import { Qc16FsnegFindingsComponent } from 'src/app/quality-control/fs-negative/qc16-fsneg-findings/qc16-fsneg-findings.component';
import { Qc16FsnegactionReviewComponent } from 'src/app/quality-control/fs-negative/qc16-fsnegaction-review/qc16-fsnegaction-review.component';
import { LdssReviewListComponent } from 'src/app/bpe-app/fsme/interview/ldss-review-list/ldss-review-list.component';
import { FsmeLdssReportComponent } from 'src/app/bpe-app/fsme/fsme-reports/fsme-ldss-report/fsme-ldss-report.component';
import { Qc13FormComponent } from '../qc-forms/qc13-form/qc13-form.component';
import { QcAppointmentLetterComponent } from '../qc-forms/qc-appointment-letter/qc-appointment-letter.component';
import { Qc13ALivingArrangmentsComponent } from '../qc-forms/qc13-a-living-arrangments/qc13-a-living-arrangments.component';
import { QC14BankVerificationComponent } from '../qc-forms/qc14-bank-verification/qc14-bank-verification.component';
import { QC19ChildSupportAbsenceComponent } from '../qc-forms/qc19-child-support-absence/qc19-child-support-absence.component';
import { QC32CustomerInformationRequestComponent } from '../qc-forms/qc32-customer-information-request/qc32-customer-information-request.component';
import { QC33CaseClosureRequestComponent } from '../qc-forms/qc33-case-closure-request/qc33-case-closure-request.component';
import { QC35ARescindCaseClosureRequestComponent } from '../qc-forms/qc35-a-rescind-case-closure-request/qc35-a-rescind-case-closure-request.component';
import { QC35BQualityControlQC35BProcessComponent } from '../qc-forms/qc35-b-quality-control-qc35-bprocess/qc35-b-quality-control-qc35-bprocess.component';
import { QC37EmploymentVerificationLetterComponent } from '../qc-forms/qc37-employment-verification-letter/qc37-employment-verification-letter.component';
import { QC38ContributionVerificationLetterComponent } from '../qc-forms/qc38-contribution-verification-letter/qc38-contribution-verification-letter.component';
import { QC39ChildCareVerificationformComponent } from '../qc-forms/qc39-child-care-verificationform/qc39-child-care-verificationform.component';
import { QC53HealthVerificationFormComponent } from '../qc-forms/qc53-health-verification-form/qc53-health-verification-form.component';
import { QC54SchoolVerificationFormComponent } from '../qc-forms/qc54-school-verification-form/qc54-school-verification-form.component';
import { QC56PostalVerificationComponent } from '../qc-forms/qc56-postal-verification/qc56-postal-verification.component';
import { QC56ReleaseofInformationFormComponent } from '../qc-forms/qc56-releaseof-information-form/qc56-releaseof-information-form.component';
import { QC199NonCooperationFormComponent } from '../qc-forms/qc199-non-cooperation-form/qc199-non-cooperation-form.component';
import { HiEdHigherEducationFormComponent } from '../qc-forms/hi-ed-higher-education-form/hi-ed-higher-education-form.component';
import { FAXCoverSheetComponent } from '../qc-forms/faxcover-sheet/faxcover-sheet.component';
import { NstrComponent } from 'src/app/components/nstr/nstr.component';
import { NstrExplainComponent } from 'src/app/components/nstr-explain/nstr-explain.component';
import { SimplifiedReportingComponent } from 'src/app/bpe-app/fsme/fsme/simplified-reporting/simplified-reporting.component';
import { EarnedIncomefromCARESComponent } from 'src/app/components/earned-incomefrom-cares/earned-incomefrom-cares.component';
import { EarnedIncomeNewHireComponent } from 'src/app/components/earned-income-new-hire/earned-income-new-hire.component';
import { EarnedIncomeQCMABSWageComponent } from 'src/app/components/earned-income-qcmabswage/earned-income-qcmabswage.component';
import { EarnedIncomeEmploymentInfoComponent } from 'src/app/components/earned-income-employment-info/earned-income-employment-info.component';
import { EarnedIncomeWorksheetComponent } from 'src/app/components/earned-income-worksheet/earned-income-worksheet.component';
import { EarnedIncomeSimplifiedReportingComponent } from 'src/app/components/earned-income-simplified-reporting/earned-income-simplified-reporting.component';
import { EarnedIncomeComparisonofIncomeComponent } from 'src/app/components/earned-income-comparisonof-income/earned-income-comparisonof-income.component';
import { RealPropertNonLiquidResourcesComponent } from 'src/app/components/real-propert-non-liquid-resources/real-propert-non-liquid-resources.component';
import { OtherUnearnedIncomeComponent } from 'src/app/components/other-unearned-income/other-unearned-income.component';
import { DependentCareDeductiontwoComponent } from 'src/app/components/dependent-care-deductiontwo/dependent-care-deductiontwo.component';
import { DependentCareDeductionthreeComponent } from 'src/app/components/dependent-care-deductionthree/dependent-care-deductionthree.component';
import { CalcsheetSRMonthComponent } from 'src/app/components/calcsheet-srmonth/calcsheet-srmonth.component';
import { ReceiptofExpeditedServiceComponent } from 'src/app/components/receiptof-expedited-service/receiptof-expedited-service.component';
import { Field68AAfterComponent } from 'src/app/components/field68-a-after/field68-a-after.component';
import { FieldSRMonthComponent } from 'src/app/components/field-srmonth/field-srmonth.component';
import { Field68BComponent } from 'src/app/components/field68-b/field68-b.component';
import { RecertificationComponent } from 'src/app/components/recertification/recertification.component';
import { IncomeonSelfEmployedHouseholdMembersComponent } from 'src/app/components/incomeon-self-employed-household-members/incomeon-self-employed-household-members.component';
import { DependentCareCostforEachHouseholdMembersComponent } from 'src/app/components/dependent-care-costfor-each-household-members/dependent-care-costfor-each-household-members.component';
import { Schedule380Component } from 'src/app/components/schedule380/schedule380.component';
import { RanktheErrorComponent } from 'src/app/components/rankthe-error/rankthe-error.component';
import { RecertificationCodesComponent } from 'src/app/components/recertification-codes/recertification-codes.component';
import { Earnedincomeqcmabswage2Component } from 'src/app/components/earnedincomeqcmabswage2/earnedincomeqcmabswage2.component';
import { SelfemployedIncomefromCares2Component } from 'src/app/components/selfemployed-incomefrom-cares2/selfemployed-incomefrom-cares2.component';
import { SelfemployedIncomeWorksheet2Component } from 'src/app/components/selfemployed-income-worksheet2/selfemployed-income-worksheet2.component';
import { Selfemployedquestions2Component } from 'src/app/components/selfemployedquestions2/selfemployedquestions2.component';
import { SelfemployedSrWorksheet2Component } from 'src/app/components/selfemployed-sr-worksheet2/selfemployed-sr-worksheet2.component';
import { ComparisonofIncome2Component } from 'src/app/components/comparisonof-income2/comparisonof-income2.component';
import { CostToProduceExpensesComponent } from 'src/app/components/cost-to-produce-expenses/cost-to-produce-expenses.component';
import { MedicaldeductionpartTwoComponent } from 'src/app/components/medicaldeductionpart-two/medicaldeductionpart-two.component';
import { IdentifyingInfoWorkBookComponent } from 'src/app/components/identifying-info-work-book/identifying-info-work-book.component';
import { PeronsLivinginHomeWorkBookComponent } from 'src/app/components/perons-livingin-home-work-book/perons-livingin-home-work-book.component';
import { SingnificantPersonsNotLivingWorkBookComponent } from 'src/app/components/singnificant-persons-not-living-work-book/singnificant-persons-not-living-work-book.component';
import { ReviewFindingsWorkBookComponent } from 'src/app/components/review-findings-work-book/review-findings-work-book.component';
import { ViewWorkbookWorkBookComponent } from 'src/app/components/view-workbook-work-book/view-workbook-work-book.component';
import { DisabledAndElderlyComponent } from 'src/app/components/disabled-and-elderly/disabled-and-elderly.component';
import { MiscQuestionComponent } from 'src/app/components/misc-question/misc-question.component';
import { QC35BRequestforContactComponent } from '../qc-forms/qc35-b-requestfor-contact/qc35-b-requestfor-contact.component';
import { InformationRequestComponent } from '../qc-forms/information-request/information-request.component';
import { MiscInfoRequiredFor380WBComponent } from 'src/app/quality-control/misc-info-required-for380-wb/misc-info-required-for380-wb.component';
import { Fns245ScheduleComponent } from 'src/app/quality-control/fs-negative/fns245-schedule/fns245-schedule.component';
import { ChildsupportTwoComponent } from 'src/app/components/childsupport-two/childsupport-two.component';
import { ChildSupportThreeComponent } from 'src/app/components/child-support-three/child-support-three.component';
import { InformationOfBankAccountComponent } from 'src/app/quality-control/information-of-bank-account/information-of-bank-account.component';
import { ArithmaticComputationComponent } from 'src/app/quality-control/arithmatic-computation/arithmatic-computation.component';
import { NotepadComponent } from 'src/app/quality-control/notepad/notepad.component';
import { TcaTanfComponent } from 'src/app/components/tca-tanf/tca-tanf.component';
import { ABAWDComponent } from 'src/app/components/abawd/abawd.component';
import { ChildSupportDeductionsComponent } from 'src/app/components/child-support-deductions/child-support-deductions.component';
import { WorkbookSummaryComponent } from 'src/app/quality-control/workbook-summary/workbook-summary.component';
import { ShelScreenComponent } from 'src/app/components/shel-screen/shel-screen.component';
import { CrAnalysisTwoComponent } from 'src/app/cr-analysis-two/cr-analysis-two.component';
import { FailedToReapplyForBenefitsComponent } from 'src/app/quality-control/fs-negative/failed-to-reapply-for-benefits/failed-to-reapply-for-benefits.component';
import { ShelterCostRentComponent } from 'src/app/components/shelter-cost-rent/shelter-cost-rent.component';
import { ShelterCostPropertyTaxesComponent } from 'src/app/components/shelter-cost-property-taxes/shelter-cost-property-taxes.component';
import { ShelterCostHomeInsuraceComponent } from 'src/app/components/shelter-cost-home-insurace/shelter-cost-home-insurace.component';
import { ShelterCostHoaCondoFeeComponent } from 'src/app/components/shelter-cost-hoa-condo-fee/shelter-cost-hoa-condo-fee.component';
import { ShelterCostElectricComponent } from 'src/app/components/shelter-cost-electric/shelter-cost-electric.component';
import { ShelterCostAirConditioningComponent } from 'src/app/components/shelter-cost-air-conditioning/shelter-cost-air-conditioning.component';
import { ShelterCostWaterComponent } from 'src/app/components/shelter-cost-water/shelter-cost-water.component';
import { ShelterCostGarbageTrashComponent } from 'src/app/components/shelter-cost-garbage-trash/shelter-cost-garbage-trash.component';
import { ShelterCostPhoneComponent } from 'src/app/components/shelter-cost-phone/shelter-cost-phone.component';
import { ShelterCostHeatSpecifyComponent } from 'src/app/components/shelter-cost-heat-specify/shelter-cost-heat-specify.component';
import { ShelterCostOtherSpecifyComponent } from 'src/app/components/shelter-cost-other-specify/shelter-cost-other-specify.component';
import { ContributionPrescriptionComponent } from 'src/app/components/contribution-prescription/contribution-prescription.component';
import { ContributionRentComponent } from 'src/app/components/contribution-rent/contribution-rent.component';
import { ContributionCleaningSuppliesComponent } from 'src/app/components/contribution-cleaning-supplies/contribution-cleaning-supplies.component';
import { ContributionBabyProductsComponent } from 'src/app/components/contribution-baby-products/contribution-baby-products.component';
import { ContributionChildCareComponent } from 'src/app/components/contribution-child-care/contribution-child-care.component';
import { ContributionAlimonyComponent } from 'src/app/components/contribution-alimony/contribution-alimony.component';
import { ContributionCharityComponent } from 'src/app/components/contribution-charity/contribution-charity.component';
import { ContributionOthersSpecifyComponent } from 'src/app/components/contribution-others-specify/contribution-others-specify.component';
import { Q16FSNegVerificationRequestComponent } from 'src/app/quality-control/fs-negative/q16-fsneg-verification-request/q16-fsneg-verification-request.component';
import { Q16FSNegIncomeAndShelterExpensesComponent } from 'src/app/quality-control/fs-negative/q16-fsneg-income-and-shelter-expenses/q16-fsneg-income-and-shelter-expenses.component';
import { DeemedIncomePartTwoComponent } from 'src/app/components/deemed-income-part-two/deemed-income-part-two.component';
import { BudgetHouseholdExpensesComponent } from 'src/app/components/budget-household-expenses/budget-household-expenses.component';
import { TotalGrossAndNetIncomeComponent } from 'src/app/quality-control/app/components/total-gross-and-net-income/total-gross-and-net-income.component';
import {Dialog68BComponent} from "../../components/dialog68-b/dialog68-b.component";
import { Gen380WorkbookComponent } from 'src/app/gen380-workbook/gen380-workbook.component';
import { EeVaBenefitsComponent  } from 'src/app/quality-control/ee-va-benefits/ee-va-benefits.component';
import { VarianceComponent } from "src/app/components/variance/variance.component";
import { RepairToDamageComponent } from "src/app/components/repair-to-damage/repair-to-damage.component";
import { AbawdClkComponent } from "src/app/components/abawd-clk/abawd-clk.component";
import { DepeComponent } from "src/app/components/depe/depe.component";
import { QcInterviewGuide } from "../qc-forms/qc-interview-guide/qc-interview-guide.component";
import { ReportingSystemsComponent } from "src/app/components/reporting-systems/reporting-systems.component";
import { CalcsheetdataQuestionsComponent } from "src/app/components/calcsheet-dataQuestions/calcsheet-dataQuestions.component";
import { CalcsheetcomparisonComponent } from "src/app/components/calcsheet-comparison/calcsheet-comparison.component";
import { CalcsheetrunCalcComponent } from "src/app/components/calcsheet-runCalc/calcsheet-runCalc.component";
import { CalcsheetexpensesDeductionsComponent } from "src/app/components/calcsheet-expensesDeductions/calcsheet-expensesDeductions.component";
import { CalcSheetfnsComponent } from "src/app/components/calcsheet-calcSheetfns/calcsheet-calcSheetfns.component";




const componentMapper = {
  input: InputComponent,
  button: ButtonComponent,
  select: SelectComponent,
  date: DateComponent,
  radiobutton: RadiobuttonComponent,
  checkbox: CheckboxComponent,
  110: DobComponent,
  130: CitizenComponent,
  170: SsnComponent,
  140: ResidencyComponent,
  111: StudentStatusComponent,
  151: RecipientDisqualificationComponent,
  160: EmploymentTrainingComponent,
  150.1: HouseholdComponent,
  150.2: HouseholdBoarderComponent,
  150.3: HouseholdGroupHomeComponent,
  150.4: HouseholdTDAAComponent,
  150.5: HhcompDisabledLivewithOthersComponent,
  150.6: HhCompHomelessShelterComponent,
  150.7: HhCompBenfitEstimateComponent,
  150.8: HhCompPeopleInHHComponent,
  161: LimitedTimeParticipationComponent,
  '161.1.1':ABAWDComponent,
  'abawdclk' :AbawdClkComponent,
  162: WorkRegistrationRequirementsComponent,
  163: VoluntaryQuitComponent,
  164: WorkfareComponent,
  165: EmploymentStatusJobAvailabilityComponent,
  166: AcceptanceOfEmploymentComponent,
  211: BankAccountCashOnHandComponent,
  211.2:InformationOfBankAccountComponent,
  212: NonRecurringLumpSumPaymentComponent,
  213: LiquidAssetsComponent,
  221: RealPropertNonLiquidResourcesComponent,
  222: MotorVehiclesComponent,
  224: NonLiquidAssetsComponent,
  224.1: NonliquidAssetsBuildingComponent,
  224.2: NonliquidAssetsFactoryComponent,
  224.3: NonliquidAssetsHouseComponent,
  224.4: NonliquidAssetsLandComponent,
  224.5: NonliquidAssetsOfficeComponent,
  224.6: NonliquidAssetsBoatTrailerComponent,
  224.7: NonliquidAssetsOthersSpecifyComponent,
  225: CombinedResourcesComponent,
  // '311.0': CaseRecordAnalysisComponent,
  311.1: EarnedIncomeEmploymentQuestionsComponent,
  311.2: EarnedIncomeEmploymentQuestiosPart2Component,
  311.3: EarnedIncomeListOfEmployerComponent,
  311.4: EarnedIncomefromCARESComponent,
  311.5: EarnedIncomeNewHireComponent,
  311.6: EarnedIncomeQCMABSWageComponent,
  311.7: EarnedIncomeEmploymentInfoComponent,
  311.8: EarnedIncomeWorksheetComponent,
  311.9: EarnedIncomeSimplifiedReportingComponent,
  // '311.9a': CalcsheetSRMonthComponent,
  '311.10': EarnedIncomeComparisonofIncomeComponent,
  // '312.0':CrAnalysisTwoComponent,
  312.1: SelfEmploymentComponent,
  312.2: SelfEmpBusinessNamesComponent,
  312.3:Earnedincomeqcmabswage2Component,
  // 312.4:SelfemployedIncomefromCares2Component,
  // 312.5:Selfemployedquestions2Component,
  312.6:SelfemployedIncomeWorksheet2Component,
  312.7:SelfemployedSrWorksheet2Component,
  // 312.8:ComparisonofIncome2Component,
  312.9: CostToProduceExpensesComponent,
  311.312: EmployStatusSeEiDeductionComponent,
  314: OtherEarnedIncomeComponent,
  321: EarnedIncomeDeductionsComponent,
  323.1: DependentCareDeductionComponent,
  323.2: DependentCareDeductiontwoComponent,
  323.3: DependentCareDeductionthreeComponent,
  331: RsdiBenefitsComponent,
  332: VaBenefitsComponent,
  333: SsiBenefitsComponent,
  334: UnemploymentBenefitsComponent,
  335: WorkersCompensationComponent,
  336: OtherGovernmentBenefitsComponent,
  342: ContributionsComponent,
  '342.1.2': ContributionPrescriptionComponent,
  '342.1.3':ContributionRentComponent,
  '342.1.4':ContributionCleaningSuppliesComponent,
  '342.1.5':ContributionBabyProductsComponent,
  '342.1.6':ContributionChildCareComponent,
  '342.1.7': ContributionAlimonyComponent,
  '342.1.8': ContributionCharityComponent,
  '342.1.9': ContributionOthersSpecifyComponent,
  343: DeemedIncomeComponent,
  343.2:DeemedIncomePartTwoComponent,
  344: GeneralAssistanceComponent,
  345: EducationalGrantsComponent,
  346: OtherUnearnedIncomeComponent,
  347: TcaTanfComponent,
  350.1: ChildSupportComponent,
  350.2:ChildsupportTwoComponent,
  350.3:ChildSupportThreeComponent,
  999: AbsentParentNotpayingChildsupportComponent,
  361: StandardDeductionComponent,
  363: ShelterCostExpensesComponent,
  '363.1.2' : ShelterCostRentComponent,
  '363.1.3': ShelterCostPropertyTaxesComponent,
  '363.1.4':ShelterCostHomeInsuraceComponent,
  '363.1.5':ShelterCostHoaCondoFeeComponent,
  '363.1.6':ShelterCostElectricComponent,
  '363.1.7':ShelterCostAirConditioningComponent,
  '363.1.8':ShelterCostWaterComponent,
  '363.1.9':ShelterCostGarbageTrashComponent,
  '363.1.10': ShelterCostPhoneComponent,
  '363.1.11':ShelterCostHeatSpecifyComponent,
 '363.1.12': ShelterCostOtherSpecifyComponent,
  '363.0': ShelScreenComponent,
  364: UtilityAllowanceComponent,
  364.4 :RepairToDamageComponent,
  365: MedicalDeductionsComponent,
  365.2:MedicaldeductionpartTwoComponent,
  366: ChildSupportDeductionsComponent,
  520:ArithmaticComputationComponent,
  530: TransitionalBenefitsComponent,
  820: DemonstrationProjectsComponent,
  888: PrimaryErrorComponent,
  887: SecondaryErrorComponent,
  'DA':DisabledAndElderlyComponent,
  'Misc':MiscQuestionComponent,
  'Var':VarianceComponent,
  'repSys':ReportingSystemsComponent,
  880: NstrComponent,
  // 881 NSTR Comp used for additional info for all the pages
  881: NstrExplainComponent,
  //380-1 WorkBook
  882:NotepadComponent,
  883:CalcsheetSRMonthComponent,
  // 884:CalcsheetexpenseDeductionComponent,
  884:CalcsheetexpensesDeductionsComponent,
  885:CalcsheetdataQuestionsComponent,
//New FNSCalcSheet
  889:CalcSheetfnsComponent,
  890:CalcsheetcomparisonComponent,
  //Old Calcsheet
  891:CalcsheetrunCalcComponent,
  
  //'Bgt': BudgetHouseholdExpensesComponent,
  '371': TotalGrossAndNetIncomeComponent,

 700100:IdentifyingInfoWorkBookComponent,
//  700010:MiscInfoRequiredFor380WBComponent,
  700110:PeronsLivinginHomeWorkBookComponent,
  700120:SingnificantPersonsNotLivingWorkBookComponent,
  700130:ReviewFindingsWorkBookComponent,
  700135:WorkbookSummaryComponent,
  700140:Gen380WorkbookComponent,
  700141:ViewWorkbookWorkBookComponent,
  

  //QC-16
  500010: Qc16FrontPageComponent,
  500011: Qc16ErrorDescriptionComponent,
  500012: Qc16AdditionalInformationComponent,
  500013: Qc16NarrationComponent,
  500014: Qc16NarrationTwoComponent,
  500015: Qc16ConcludingPageComponent,
  500016: Qc16ConvertTopdfComponent,
  //Schedule
  'f26': ReceiptofExpeditedServiceComponent,
  '68a': Field68AAfterComponent,
  //1st month
  68.1: FieldSRMonthComponent,
  '68-B': Field68BComponent,
  '68b': Dialog68BComponent,
  70: RecertificationComponent,
  '70a': RecertificationCodesComponent,
  '380-1.1': RanktheErrorComponent,
  '380-1.2': IncomeonSelfEmployedHouseholdMembersComponent,
  '380-1.3': DependentCareCostforEachHouseholdMembersComponent,
  '380-1.4': Schedule380Component,

  //FSPNegative components
  1: ClosingScreenComponent,
  '000': NoCodeComponent,
  120: ImmigrantEligibilityComponent,
  230: NoReqVerificationInfoComponent,
  '230.3':Q16FSNegIncomeAndShelterExpensesComponent,
  '230.6':Q16FSNegVerificationRequestComponent,
  235:FailedToReapplyForBenefitsComponent,
  245: NoEligibleMemberComponent,
  246: UnderPriorPenaltyComponent,
  248: HohDeceasedComponent,
  281: HohLivingArrangementsComponent,
  282: HohFailedResidencyComponent,
  301: TotalIncomeOverLimitComponent,
  320: GrossIncomeOverlimitComponent,
  //Duplicate for FSP Positive
  '361.0': FailedToProvideVerificationComponent,
  '365.0': DenialCodeForInterviewComponent,
  401: PropertyOverAssetLimitComponent,
  500: BecameEmployedComponent,
  501: FailedToComplyJobPrg1Component,
  502: FailedToComplyJobPrg2Component,
  503: FailedToComplyJobPrg3Component,
  504: FailedToComplyUpfrontJobSearchComponent,
  505: FailedToCooperatewithChildSupportComponent,
  507: ClosedToAvoidDualParticipationComponent,
  508: MinorParentFailedLivingArrangementsComponent,
  509: MaxBenefitsRecievedAnthrStateComponent,
  510: MaxBenefitsRecievedMdComponent,
  511: MaxBenefitsRecievedMdpriorStofRsdnceComponent,
  515: PreviouslyReceivedWAagComponent,
  516: AfInternationalprogramViolation1Component,
  517: AfInternationalprogramViolation2Component,
  519: AfInternationalprogramViolation3Component,
  523: MisrepRestoRecMulBenComponent,
  524: FleeingFelonComponent,
  525: ConDrgFelonComponent,
  526: ABAWDReqComponent,
  527: CtzReqComponent,
  529: AssistanceUnitMarriageComponent,
  550: VoluntaryWithdrawlApplicationComponent,
  551: WhereaboutsUnknownsComponent,
  552: FailureToGiveInfoComponent,
  553: NonCooperationWithEArequirementsComponent,
  554: ClientMovedOutofStateComponent,
  555: ApplicationOpenInErrorComponent,
  556: NoncooperationWithQcRequirementsComponent,
  557: IndividualIDRequestedClosureComponent,
  558: FailureToApplyForPotentialIncomeComponent,
  559: ClientDiscrepancyNameErrorComponent,
  560: PresumptiveEligibilityFailedAtFormalApplicationComponent,
  561: RefusedToSignLienComponent,
  566: NonCooperationWithEligProcComponent,
  567: AlcoholDrugFacilityLostCertComponent,
  569: ChildAcceptedIntoFacilityComponent,
  570: BenefitsPreviouslyRecievedComponent,
  571: ClientIdErrorComponent,
  572: WorkerVoidedApplicationComponent,
  573: FailedPhotoRequirementsComponent,
  577: NotParticipatingRehabilitativeProgramComponent,
  578: DidNotTrytoObtainLegalCompenComponent,
  579: DidNotTrytoObtainGovBftsComponent,
  580: DisabilityOfLessThan3nthsComponent,
  581: CstmDeclinedTFSComponent,
  582: NtElgToRecMSNAPComponent,
  583: NonCooperationWthFedQCReqComponent,
  //qc
  //  500101:
  500101: QcAppointmentLetterComponent,
  500102: Qc13FormComponent,
  500103: Qc13ALivingArrangmentsComponent,
  500104: QC14BankVerificationComponent,
  500105: QC19ChildSupportAbsenceComponent,
  500106: QC32CustomerInformationRequestComponent,
  500107: QC33CaseClosureRequestComponent,
  500108: QC35ARescindCaseClosureRequestComponent,
  500109: QC35BRequestforContactComponent,
  500110: QC35BQualityControlQC35BProcessComponent,
  500111: QC37EmploymentVerificationLetterComponent,
  500112: QC38ContributionVerificationLetterComponent,
  500113: QC39ChildCareVerificationformComponent,
  // 500114: QC53HealthVerificationFormComponent,
  500115: QC54SchoolVerificationFormComponent,
  500116: QC56ReleaseofInformationFormComponent,
  500117: QC199NonCooperationFormComponent,
  500118: HiEdHigherEducationFormComponent,
  500119: FAXCoverSheetComponent,
  500120: InformationRequestComponent,
  500121: QC56PostalVerificationComponent,
  'INTRV': QcInterviewGuide,
  500020: Qc16FsnegFrontpageComponent,
  500021: Qc16FsnegFindingsComponent,
  500022: Qc16FsnegactionReviewComponent,
  600210:Fns245ScheduleComponent,
  'UINC':EeVaBenefitsComponent,
  'DEPE':DepeComponent,





  // //TCA
  // 20: HouseholdUnboundComponent,
  // 21: EarnedEmployerComponent,
  // 22: EarnedIncomeComponent,
  // 23: UnearnedSourceComponent,
  // 24: UnearnedContributionComponent,
  // 25: SponsorIncomeComponent,
  // 26: SponsorDeductionComponent,
  // 27: StepParentIncomeComponent,
  // 28: StepParentDeductionComponent,
  // 29: DependentCarePocComponent,
  // 30: DependentCareKididComponent,
  // 31: DependentCareIncomeComponent,
  // 32: ChildSupportPaymentComponent,
  // 33: ChildSupportApidComponent,
  // 34: ChildSupportApadComponent,
  // 35: ChildSupportCas1Component,
  // 36: ChildSupportCas2Component,
  // 37: ChildSupportPay1Component,
  // 38: ChildSupportPay2Component,
  // 39: HousingExpensesComponent,
  // 40: HousingMiscComponent,
  // 41: PpiDisallowComponent,
  // 42: WorkStatusComponent,
  // 43: WorkEligibilityComponent,
  // 44: WorkConcilliationComponent,
  // 45: WorkAdditionalComponent,
  // 46: WorkAttendanceComponent,
  // 886: ErrorPageComponent,

  //BPE FSME

  100006: FspPreReviewCasesComponent,
  100007: FsAppsFiledComponent,
  100008: MyDhrOnlineAppsComponent,
  100009: RedetsComponent,
  100010: DenialsComponent,
  100011: ClosingsComponent,
  100012: AbawdActivesComponent,
  100013: AbawdClosuresComponent,
  100014: ReceipientIntegrityComponent,
  100015: SvesPrisonMatchComponent,
  100016: DeathMatchComponent,
  100017: NewHireAlertsComponent,
  100018: RecipientClaimsComponent,
  100019: EbtMultipleIssuancesComponent,
  100020: SimplifiedReportingComponent,
  100022: CsCustomerPgmAccessComponent,
  100023: CsStaffPgmAccessComponent,
  100024: EbtModuleComponent,
  100025: ReviewerObservationsComponent,
  100026: VoterRegistrationReviewerComponent,
  100027: CaInitiativesImplementedComponent,
  100028: BlindPhoneCallComponent,
  100029: CboInterviewComponent,
  100030: CaBestPracticesComponent,
  100031: FsmeLdssReportComponent,
  //PA
  200006: PaCaseReviewComponent,
  //WPR
  300006: WprCaseReviewComponent,

  //Monitoring
  400007: ParisAlertComponent,
  400008: NationalNewHireComponent,
  400009: StateHireComponent,
  400010: PreReivewDirectComponent,
  400012: PrisonMatchComponent,
  400013: ChildSupportMonitorComponent,
  400014: ReactivationsComponent,
  400015: OverpaymentBegsComponent,
  400017: MonitoringMemosComponent,



};
@Directive({
  selector: "[dynamicField]"
})
export class DynamicFieldDirective implements OnInit {
  @Input() callGet: EventEmitter<any> = new EventEmitter();
  @Input() callSave: EventEmitter<any> = new EventEmitter();
  @Input() callGenerateSpanishPDF: EventEmitter<any> = new EventEmitter();
  @Input() page: PageConfig;
  @Input() field: FieldConfig;
  @Input() group: FormGroup;
  @Input() openDialog: any;
  @Input() showQCd: boolean = true;
  @Input() showQDesc: boolean = true;

  componentRef: any;
  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef
  ) { }
  ngOnInit() {
    if (this.page || this.field) {
      const factory = this.resolver.resolveComponentFactory(
        componentMapper[this.page && this.page.pageType ? this.page.pageType : this.field.qtype]
      );
      this.componentRef = this.container.createComponent(factory);
      this.componentRef.instance.field = this.page && this.page.pageType
        ? this.page.fieldConfig : this.field;
      this.componentRef.instance.group = this.group;
      this.componentRef.instance.openDialog = this.openDialog;
      this.componentRef.instance.showQCd = this.showQCd;
      this.componentRef.instance.showQDesc = this.showQDesc;
      this.componentRef.instance.callSave = this.callSave;
      this.componentRef.instance.callGet = this.callGet;
      this.componentRef.instance.callGenerateSpanishPDF = this.callGenerateSpanishPDF;
    }
  }
}
