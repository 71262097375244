import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-minor-parent-failed-living-arrangements',
  templateUrl: './minor-parent-failed-living-arrangements.component.html',
  styleUrls: ['./minor-parent-failed-living-arrangements.component.css']
})
export class MinorParentFailedLivingArrangementsComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }


}
