import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-worker-voided-application',
  templateUrl: './worker-voided-application.component.html',
  styleUrls: ['./worker-voided-application.component.css']
})
export class WorkerVoidedApplicationComponent extends BaseComponent implements OnInit {
  ngOnInit() {
     super.ngOnInit();
   }

}
