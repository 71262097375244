import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-my-dhr-online-apps',
  templateUrl: './my-dhr-online-apps.component.html',
  styleUrls: ['./my-dhr-online-apps.component.css']
})

export class MyDhrOnlineAppsComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }
}