import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-earned-income-new-hire',
  templateUrl: './earned-income-new-hire.component.html',
  styleUrls: ['./earned-income-new-hire.component.css']
})
export class EarnedIncomeNewHireComponent extends BaseComponent implements OnInit {

  currentPage: number = 0;
  isNotEditable: boolean= false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    if(this.group.get('FormArray')['controls']){
      this.group.get('FormArray')['controls'].map(s=>{
      this.changeQ311_2_1(s);
        this.changeQ311_5_4(s);
    });
  }
  }
  changeQ311_2_1(val) {

    if (val.controls["311.5.1"].value === 'N') {
      val.controls['311.5.2'].disable();
      val.controls['311.5.3'].disable();
    } else {
      val.controls['311.5.2'].enable();
      val.controls['311.5.3'].enable();
    }
  }

  changeQ311_5_4(val){
    if (val.controls["311.5.4"].value === 'N') {
      val.controls['311.5.5'].disable();  
    } else {
      val.controls['311.5.5'].enable();
    }

  }
  

  

}


