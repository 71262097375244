import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-alert-details-dialog',
  templateUrl: './alert-details-dialog.component.html',
  styleUrls: ['./alert-details-dialog.component.css']
})
export class AlertDetailsDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<any>) { }

  ngOnInit() {
  }

  close(val){
    this.data = val;
    this.dialogRef.close(this.data);
  }
}
