import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-earned-income-deductions',
  templateUrl: './earned-income-deductions.component.html',
  styleUrls: ['./earned-income-deductions.component.css']
})
export class EarnedIncomeDeductionsComponent extends BaseComponent implements OnInit {
isNotEditable: boolean= false;


  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
   this.calcTotal();
   
    
  }

  calcTotal(){
    let all_sum_value =  this.getControlValue('321.1');
    let all_sum_value_array = all_sum_value.split('~');
    this.setControlValue('321.2a',all_sum_value_array[0]);
    this.setControlValue('321.2b',all_sum_value_array[1]);
    this.setControlValue('321.2c',all_sum_value_array[2]);
    this.setControlValue('321.2d',all_sum_value_array[3]);
    this.setControlValue('321.2e',all_sum_value_array[4]);
    this.setControlValue('321.2f',all_sum_value_array[5]);
    this.setControlValue('321.3a',all_sum_value_array[6]);
    this.setControlValue('321.3b',all_sum_value_array[7]);
    this.setControlValue('321.3c',all_sum_value_array[8]);
    this.setControlValue('321.3d',all_sum_value_array[9]);
    this.setControlValue('321.3e',all_sum_value_array[10]);
    this.setControlValue('321.3f',all_sum_value_array[11]);
  }



}
