import { Component, OnInit, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';

import { BodyComponent } from './body/body.component';
import { AuthService } from '../common/auth.service';

declare var $: any;

@Component({
  selector: 'app-root-frame',
  templateUrl: './root-frame.component.html',
  styleUrls: ['./root-frame.component.scss'],
})

export class RootFrameComponent implements OnInit {

  public elementRef;
  private isHomeScreen = false;
  appHeaderTwo: boolean = false;
  resizeNav: boolean = false;
  authService: AuthService;
  constructor(
    private activatedRoute: ActivatedRoute,

    private router: Router,

    // private http: Http,
    private fb: FormBuilder,
    private cdRef: ChangeDetectorRef,
    myElement: ElementRef
  ) {
      
  }

  ngOnInit(): void {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  ngDoCheck(){
    let url = this.router.url;
    if(url.includes("demographicDetails")){
      this.appHeaderTwo = true;
    } else {
      this.appHeaderTwo = false;
    }
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  logout(): void {
    this.authService.setLoginStatus(false);
    this.router.navigate(['/Login']);
  }
  
  resizeNavBar(val: boolean) {
    this.resizeNav  = val;
  }

}
