import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { DataSource, SelectionModel } from "@angular/cdk/collections";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { ApiManager } from "src/app/common/api-manager.service";
import { NavigationService } from "../../common/navigation.service";
import { ConstantsService } from "../../common/constants.service";
import {
  Region,
  DistrictOffice,
  Analyst,
} from "../../model/api/region-district-analyst.class";
import { dateFormat } from "highcharts";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CaseViewComponent } from "src/app/home-page/case-view/case-view.component";
import moment from "moment";

@Component({
  selector: "app-assignment-summary",
  templateUrl: "./assignment-summary.component.html",
  styleUrls: ["./assignment-summary.component.css"],
})
export class AssignmentSummaryComponent implements OnInit {
  isNotEditable: boolean = false;
  assignmentSummaryForm: FormGroup;
  displayedColumns = [
    "districtOff",
    "reviewMonth",
    "analystName",
    "fspPstvCnt",
    "fspNgtvCnt",
  ];
  dataSource: any = new MatTableDataSource();
  casedataSource: any = new MatTableDataSource();
  districtOfficesList: DistrictOffice[] = [];
  regions: Region[];
  Analysts: Analyst[] = [];
  regnName: any;
  distOffCd: any;
  anlystId: any;
  rvwMos: any[] = [];
  today: any;
  labelmap: Map<string, string> = new Map<string, string>();
  map: Map<any, any> = new Map();
  pquestion;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  keys: Set<string> = new Set();
  data: any;
  constructor(
    private readonly router: Router,
    private apiManager: ApiManager,
    private fb: FormBuilder,
    public navigationService: NavigationService,
    public constantsService: ConstantsService,
    public dialog: MatDialog
  ) {
    this.regions = this.constantsService.getDistinctRegions();
    this.Analysts = this.constantsService.getDistinctReviewer();
  }

  ngOnInit() {
    this.buildForm();
    let date: Date = new Date();

    let currentMoVal;
    let currentMoViewVal;

    for (let i = 0; i >= -7; i--) {
      let rvwDate = new Date();
      rvwDate.setMonth(rvwDate.getMonth() - i);
      currentMoVal = moment().add(i, "months").format("YYYYMM");
      currentMoViewVal = moment().add(i, "months").format("MM/YYYY");
      this.rvwMos.push({ value: currentMoVal, viewValue: currentMoViewVal });
    }

    // this.monthYear= date.getFullYear()+""+(date.getMonth()+1<10?"0"+(date.getMonth()+1):date.getMonth()+1);
    let rvwMonth =
      date.getFullYear() +
      "" +
      (date.getMonth() < 10
        ? 0 + "" + (date.getMonth() + 1)
        : date.getMonth() + 1);
    this.searchAssignment(rvwMonth);
    this.determineAccess();
  }

  buildForm() {
    this.assignmentSummaryForm = this.fb.group({
      region: [""],
      districtOff: [""],
      analystName: [""],
      rvwMo: [""],
    });
  }
  reset() {
    this.assignmentSummaryForm.reset();
    this.ngOnInit();
  }
  rvwInfodataSource: any = new MatTableDataSource();

  changeRvwMonth(rvwMonth) {
    this.searchAssignment(rvwMonth);
  }

  changeDistrictByRegionCd(regnName) {
    this.districtOfficesList =
      this.constantsService.getDoByRegionCdWithOutAppCd(regnName);
    this.regnName = regnName;
    this.Analysts = [];
  }

  changeAnalystByDoCd(doCd) {
    this.Analysts = this.constantsService.getDistinctReviewer();
    this.distOffCd = doCd;
  }

  changeAnalyst(analyst) {
    if (this.regnName && this.distOffCd && analyst) {
      this.rvwInfodataSource = this.dataSource.data.filter(
        (val) => val.analystId == analyst && val.doCd == this.distOffCd
      );
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.rvwInfodataSource.filter = filterValue.trim().toLowerCase();
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if (
      ref.accessMap["/qualityControl/assignmentSummary"] != null &&
      ref.accessMap["/qualityControl/assignmentSummary"].accessMode != "rw"
    ) {
      this.isNotEditable = true;
    }
  }

  searchAssignment(rvwMonth) {
    const queryParams: any[] = [];
    queryParams.push(rvwMonth);

    this.apiManager
      .fetchData(
        "rvwInfoByRgnDoAnalyst",
        undefined,
        ApiManager.GET,
        undefined,
        undefined,
        undefined,
        queryParams
      )
      .subscribe((res) => {
        console.log("----Assigned-----");
        res.map((item) => {
          if (item && item.reviewMonth) {
            item["dispRvwMo"] =
              item.reviewMonth.substring(4) +
              "/" +
              item.reviewMonth.substring(0, 4);
          }
        });
        this.rvwInfodataSource = new MatTableDataSource();
        this.dataSource = new MatTableDataSource(res);
        this.rvwInfodataSource.data = res;
      });
  }

  openDialog(action: any, element: any, source) {
    const dialogRef = this.dialog.open(CaseViewComponent, {
      height: "94%",
      width: "94%",
      data: {
        action: action,
        source: source,
        analystName: element.analystName,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
