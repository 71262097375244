import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-medicaldeductionpart-two',
  templateUrl: './medicaldeductionpart-two.component.html',
  styleUrls: ['./medicaldeductionpart-two.component.css']
})
export class MedicaldeductionpartTwoComponent extends BaseComponent implements OnInit {
isNotEditable: boolean =false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  sumMedCare(){
    const amt1  =   this.getControlValue('365.2.1.1');
    const amt2 = this.getControlValue('365.2.2.1');
    const amt3 = this.getControlValue('365.2.3.1');
    const amt4 = this.getControlValue('365.2.4.1');
    const amt5 = this.getControlValue('365.2.5.1');
    const amt6  =   this.getControlValue('365.2.6.1');
    const amt7 = this.getControlValue('365.2.7.1');
    const amt8 = this.getControlValue('365.2.8.1');
    const amt9 = this.getControlValue('365.2.9.1');
    const amt10 = this.getControlValue('365.2.10.1');
    const amt11  =   this.getControlValue('365.2.11.1');
    const amt12 = this.getControlValue('365.2.12.1');
    const amt13 = this.getControlValue('365.2.13.1');
    const amt14 = this.getControlValue('365.2.14.1');
    const amt15 = this.getControlValue('365.2.15.1');
    const amt16  =   this.getControlValue('365.2.16.1');
    const amt17 = this.getControlValue('365.2.17.2'); 
    const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5) + (+amt6) + (+amt7) + (+amt8) +(+amt9)+ (+amt10) + (+amt11) + (+amt12)+ (+amt13) + (+amt14) + (+amt15) + (+amt16) + (+amt17);
    this.setControlValue('365.2.18.1', res);
  }

  sumLdss(){
    const amt1  =   this.getControlValue('365.2.1.2');
    const amt2 = this.getControlValue('365.2.2.2');
    const amt3 = this.getControlValue('365.2.3.2');
    const amt4 = this.getControlValue('365.2.4.2');
    const amt5 = this.getControlValue('365.2.5.2');
    const amt6  =   this.getControlValue('365.2.6.2');
    const amt7 = this.getControlValue('365.2.7.2');
    const amt8 = this.getControlValue('365.2.8.2');
    const amt9 = this.getControlValue('365.2.9.2');
    const amt10 = this.getControlValue('365.2.10.2');
    const amt11  =   this.getControlValue('365.2.11.2');
    const amt12 = this.getControlValue('365.2.12.2');
    const amt13 = this.getControlValue('365.2.13.2');
    const amt14 = this.getControlValue('365.2.14.2');
    const amt15 = this.getControlValue('365.2.15.2');
    const amt16  =   this.getControlValue('365.2.16.2');
    const amt17 = this.getControlValue('365.2.17.3'); 
    const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5) + (+amt6) + (+amt7) + (+amt8) +(+amt9)+ (+amt10) + (+amt11) + (+amt12)+ (+amt13) + (+amt14) + (+amt15) + (+amt16) + (+amt17);
    this.setControlValue('365.2.18.2', res);
  }

  sumSampleMonth(){
    const amt1  =   this.getControlValue('365.2.1.3');
    const amt2 = this.getControlValue('365.2.2.3');
    const amt3 = this.getControlValue('365.2.3.3');
    const amt4 = this.getControlValue('365.2.4.3');
    const amt5 = this.getControlValue('365.2.5.3');
    const amt6  =   this.getControlValue('365.2.6.3');
    const amt7 = this.getControlValue('365.2.7.3');
    const amt8 = this.getControlValue('365.2.8.3');
    const amt9 = this.getControlValue('365.2.9.3');
    const amt10 = this.getControlValue('365.2.10.3');
    const amt11  =   this.getControlValue('365.2.11.3');
    const amt12 = this.getControlValue('365.2.12.3');
    const amt13 = this.getControlValue('365.2.13.3');
    const amt14 = this.getControlValue('365.2.14.3');
    const amt15 = this.getControlValue('365.2.15.3');
    const amt16  =   this.getControlValue('365.2.16.3');
    const amt17 = this.getControlValue('365.2.17.4'); 
    const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5) + (+amt6) + (+amt7) + (+amt8) +(+amt9)+ (+amt10) + (+amt11) + (+amt12)+ (+amt13) + (+amt14) + (+amt15) + (+amt16) + (+amt17);
    this.setControlValue('365.2.18.3', res);
  }

  sumCorrectedLdss(){
    const amt1  =   this.getControlValue('365.2.1.4');
    const amt2 = this.getControlValue('365.2.2.4');
    const amt3 = this.getControlValue('365.2.3.4');
    const amt4 = this.getControlValue('365.2.4.4');
    const amt5 = this.getControlValue('365.2.5.4');
    const amt6  =   this.getControlValue('365.2.6.4');
    const amt7 = this.getControlValue('365.2.7.4');
    const amt8 = this.getControlValue('365.2.8.4');
    const amt9 = this.getControlValue('365.2.9.4');
    const amt10 = this.getControlValue('365.2.10.4');
    const amt11  =   this.getControlValue('365.2.11.4');
    const amt12 = this.getControlValue('365.2.12.4');
    const amt13 = this.getControlValue('365.2.13.4');
    const amt14 = this.getControlValue('365.2.14.4');
    const amt15 = this.getControlValue('365.2.15.4');
    const amt16  =   this.getControlValue('365.2.16.4');
    const amt17 = this.getControlValue('365.2.17.5'); 
    const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5) + (+amt6) + (+amt7) + (+amt8) +(+amt9)+ (+amt10) + (+amt11) + (+amt12)+ (+amt13) + (+amt14) + (+amt15) + (+amt16) + (+amt17);
    this.setControlValue('365.2.18.4', res);
  }


 
}