import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-ca-best-practices',
  templateUrl: './ca-best-practices.component.html',
  styleUrls: ['./ca-best-practices.component.css']
})
export class CaBestPracticesComponent extends BaseComponent implements OnInit {
  currentPage: number = 0;
  ngOnInit() {
    super.ngOnInit();
  }

}
