import { Component, OnInit, Optional, Inject, ViewChild } from '@angular/core';
import { ConstantsService } from '../../common/constants.service';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ApiManager } from 'src/app/common/api-manager.service';
import { AppUtilService } from 'src/app/common/app.util.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidatorsService } from 'src/app/common/custom-validators.service';
import moment from 'moment';
import {CdkDragDrop, moveItemInArray,CdkDragHandle,transferArrayItem} from '@angular/cdk/drag-drop';
import { NavigationService } from 'src/app/common/navigation.service';
import { DATE_FORMAT } from 'src/app/shared-module/month-format.directive';
import { MatTableModule } from '@angular/material/table';
import {MatTable} from '@angular/material/table';
import {MatIconRegistry} from '@angular/material/icon';
import clonedeep from 'lodash.clonedeep';
import { SelectionModel } from '@angular/cdk/collections';
import { ConditionalExpr } from '@angular/compiler';
import { saveAs } from 'file-saver/FileSaver';
import { DomSanitizer } from '@angular/platform-browser';
import { DataShareService } from 'src/app/common/data-share.service';
import { DeleteDialogComponent } from 'src/app/shared-module/delete-dialog/delete-dialog.component';
import { EditViewWorkbookComponent } from 'src/app/quality-control/edit-view-workbook/edit-view-workbook.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventEmitterService } from 'src/app/common/event.emitter.service';

export interface informationElement {
  order:number,
  element_num: string,
  bookmarkname:string,
  tmplt_name: string,
  cmmts: string,
  notes: string,
  create_dt: Date,
  fnssmryind: string,
}

export interface informationElementTwo {
  order:number,
  element_num: string,
  bookmarkname:string,
  tmplt_name: string,
  cmmts: string,
  notes: string,
  create_dt: Date,
  fnssmryind: string,
}

export interface informationElementThree {
  order:number,
  element_num: string,
  bookmarkname:string,
  tmplt_name: string,
  cmmts: string,
  notes: string,
  create_dt: Date,
  fnssmryind: string,
}

@Component({
  selector: 'app-view-workbook-work-book',
  templateUrl: './view-workbook-work-book.component.html',
  styleUrls: ['./view-workbook-work-book.component.css']
})
export class ViewWorkbookWorkBookComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;  
  qcDocRepoForm: FormGroup;
 // fileUrl;
  isNotEditable: boolean = false;
  screenType: string ;
  filesToUpload : informationElement[];
  elementfiles: informationElementThree[];
  newfilesToUpload: informationElementTwo[];
  //dsQCDocRepo = new MatTableDataSource();
  pgm_type: string;

  
 
  displayedColumns: string[] = [
    "select",
    'order',
    'element_num',
    'bookmarkname',
    'tmplt_name',
    'cmmts',
    'create_dt',
    'actions'
    ];
     
  displayedColumnsElementReview: string[] = [
    "select",
    'order',
    'element_num',
    'bookmarkname',
    'tmplt_name',
    'cmmts',
    'create_dt',
    'actions'
    ];
    displayedColumnsThree: string[] = [
    "select",
    'order',
    'element_num',
    'bookmarkname',
    'tmplt_name',
    'cmmts',
    'create_dt',
    'actions'
    ];
  dataSource: MatTableDataSource<informationElement>;
  newdataSource: MatTableDataSource<informationElementTwo>;
  newfiledataSource: MatTableDataSource<informationElementThree>;

  selection = new SelectionModel(true, []);
  selectedItems: informationElement[];
  newselection = new SelectionModel(true, []);
  newselectedItems: informationElementTwo[];

  newfileselection =  new SelectionModel(true, []);
  newfileselectedItems : informationElementThree[];
  
  docMetadata = [];

  constructor(
    private sanitizer: DomSanitizer,
    public constantsService: ConstantsService,
    private apiManager: ApiManager,
    public utilService: AppUtilService,
    private fb: FormBuilder,
    private shareService: DataShareService,
    @Optional() public dialog: MatDialog,
    public _snackBar: MatSnackBar,
    public emitter: EventEmitterService,
   @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    this.emitter.subscribe('refreshFileUpload', (data) => {
      this.ngOnInit();
    });

    if ( this.data ) {
      this.buildForm();
      this.dataSource = new MatTableDataSource();
      this.newdataSource = new MatTableDataSource();

    }
   }

  ngOnInit() {
    this.getQCDocRepo();
    this.getQCDocRepo1();
    this.selection.clear();
    this.newselection.clear();
    this.newfileselection.clear();

    this.selectedItems = new Array<informationElement>();
    this.newselectedItems = new Array<informationElementTwo>();
    this.newfileselectedItems = new Array<informationElementTwo>();

  }
  buildForm() {
    this.qcDocRepoForm = this.fb.group({
      order:[''],
      rvw_num: [''],
      element_num: [''],
      bookmarkname:[' '],
      tmplt_name: [''],
      cmmts: [''],
      notes: [''],
      create_dt:[''],
      qc_doc_repo_id:['']
   });
  }

getQCDocRepo() {
  const queryParams: any[] = [];
  this.pgm_type = this.constantsService.ReviewInfo.rvwType;
  if (this.constantsService.ReviewInfo &&
    this.constantsService.ReviewInfo.rvwNum) {
    queryParams.push(this.constantsService.ReviewInfo.rvwNum);
  }
    this.apiManager.fetchData('getQCDocRepo', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
    .subscribe(res => {
      if (res) {
        // res.forEach(files => files.element_num = this.getFNSName(files));
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.filesToUpload = this.dataSource.data;
        this.filesToUpload.forEach((files,idx)=>
        {
            files.order = idx + 1; 
        })
      }
    }
  );

  
}



getQCDocRepo1() {
  const queryParams: any[] = [];
 
  if (this.constantsService.ReviewInfo &&
    this.constantsService.ReviewInfo.rvwNum) {
    queryParams.push(this.constantsService.ReviewInfo.rvwNum);
  }
  //   this.apiManager.fetchData('getQCDocRepo1', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
  //   .subscribe(res => {
  //     if (res) {
  //       this.newdataSource = new MatTableDataSource(res);
  //       this.newfilesToUpload = this.newdataSource.data;
  //       this.newdataSource.paginator = this.paginator;
  //       this.newdataSource.sort = this.sort;
  //       this.newfilesToUpload.forEach((files,idx)=>
  //       {
  //           files.order = idx + 1; 
  //       })
  //     }
  //   }
  // );
  this.apiManager.fetchData('getQCDocRepo1', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
    .subscribe(res => {
      if (res) {
        // res.forEach(files => files.element_num = this.getFNSName(files));
        this.newdataSource = new MatTableDataSource(res);
        this.newfilesToUpload = this.newdataSource.data.filter(rec => rec.fnssmryind !=='E') ;
        this.newdataSource.paginator = this.paginator;
        this.newdataSource.sort = this.sort;
        let repo1Sort = new MatSort();
        repo1Sort.active = 'element_num';
        if(res[0].q880_9a === 'Incomplete' || res[0].q880_9a === 'NSTR'){
          repo1Sort.direction = 'asc';
        }else{
          repo1Sort.direction = 'desc';
        }
        this.sortDataNew(repo1Sort);
        this.newfilesToUpload.forEach((files,idx)=>
        {
            files.order = idx + 1; 
        })

        this.elementfiles = this.newdataSource.data.filter(rec => rec.fnssmryind ==='E') ;
        this.elementfiles.forEach((files,idx)=>
        {
            files.order = idx + 1; 
        })
      }
    }
  );
}

dropTable(event: CdkDragDrop<informationElement[]>) {
  moveItemInArray(this.filesToUpload, event.previousIndex, event.currentIndex);
  this.selection.clear();
  this.filesToUpload.forEach((files,idx)=>
  {
     files.order = idx + 1; 
  })
  this.filesToUpload = clonedeep(this.filesToUpload);
}
dropTable1(event: CdkDragDrop<informationElementTwo[]>) {
  moveItemInArray(this.newfilesToUpload, event.previousIndex, event.currentIndex);
  this.newselection.clear();
  this.newfilesToUpload.forEach((files,idx)=>
  {
     files.order = idx + 1; 
  })
  this.newfilesToUpload = clonedeep(this.newfilesToUpload);
}


dropTable2(event: CdkDragDrop<informationElementThree[]>) {
  moveItemInArray(this.elementfiles, event.previousIndex, event.currentIndex);
  this.newfileselection.clear();
  this.elementfiles.forEach((files,idx)=>
  {
     files.order = idx + 1; 
  })
  this.elementfiles = clonedeep(this.elementfiles);
}

 masterToggle(_ref: any) {
   if (_ref.checked) {
       this.checkPageOnly();
   }
   else {
       this.selection.clear();
   }
   
 }
 masterToggle1(_ref1: any) {
  if (_ref1.checked) {
      this.checkPageOnly1();
  }
  else {
      this.newselection.clear();
  }
  
}
masterToggle2(_ref2: any) {
  if (_ref2.checked) {
      this.checkPageOnly2();
  }
  else {
      this.newfileselection.clear();
  }
  
}

 get currentIndividual() {
  return this.shareService.getDataFromMap('currentIndividual');
}

set currentIndividual(id) {
  this.shareService.addDataToMap('currentIndividual', id);
}

/** The label for the checkbox on the passed row */
checkboxLabel(row?: informationElement): string {
  if (!row) {
    return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
  }
  return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
}

isAllSelected() {
  const numSelected = this.selection.selected.length;
  const numRows = this.filesToUpload.length;
  return numSelected === numRows;
}

checkboxLabel1(row?: informationElementTwo): string {
  if (!row) {
    return `${this.isAllNewSelected() ? 'select' : 'deselect'} all`;
  }
  return `${this.newselection.isSelected(row) ? 'deselect' : 'select'}`;
}

isAllNewSelected() {
  const numSelected = this.newselection.selected.length;
  const numRows = this.newfilesToUpload.length;
  return numSelected === numRows;
}


checkboxLabel2(row?: informationElementTwo): string {
  if (!row) {
    return `${this.isAllElemFilesSelected() ? 'select' : 'deselect'} all`;
  }
  return `${this.newfileselection.isSelected(row) ? 'deselect' : 'select'}`;
}

isAllElemFilesSelected() {
  const numSelected = this.newfileselection.selected.length;
  const numRows = this.elementfiles.length;
  return numSelected === numRows;
}

 @ViewChild('ref', { static: false }) ref: any;
 checkPageOnly() {
   for (let i = 0; i < this.filesToUpload.length; i++) {
     this.selection.select(this.filesToUpload[i]);
   }
  console.log('selected...',this.selection.selected)
 }
 @ViewChild('ref1', { static: false }) ref1: any;
 checkPageOnly1() {
   for (let i = 0; i < this.newfilesToUpload.length; i++) {
     this.newselection.select(this.newfilesToUpload[i]);
   }
  console.log('selected...',this.newselection.selected)
 }

 @ViewChild('ref2', { static: false }) ref2: any;
 checkPageOnly2() {
   for (let i = 0; i < this.elementfiles.length; i++) {
     this.newfileselection.select(this.elementfiles[i]);
   }
  console.log('selected...',this.newfileselection.selected)
 }
 
 moveToFNSRvwSmry(){
  const queryParams: any[] = [];
  queryParams.push("Y");

  if(this.selection.selected.length>0){
    let filename: any;
    this.selection.selected.forEach(row => {
      console.log("row....", row);
    });
      const objBody: any = this.selection.selected ;
      this.apiManager.fetchData('moveToFNSRvwSmry', undefined, ApiManager.POST, objBody, undefined, undefined, queryParams)
        .subscribe(
          (res: any) => {
            if(res == true) {
              alert('Empty file found associated with the selected row!');
            } else {
              this._snackBar.open('Move to FNS Review Summary Successful.', undefined, {
                duration: 2000,
              });
              this.ngOnInit();
            }
          },
            error => {
              alert('Found Error while Move to FNS Review Summary!');
            }
          );
    }else{
      alert('Please select a row before Move to FNS Review Summary!');
    }

 }

 moveToReviewFiles(){
  const queryParams: any[] = [];
  queryParams.push("RF");

  if(this.newfileselection.selected.length>0){
    let filename: any;
    this.newfileselection.selected.forEach(row => {
      console.log("row....", row);
    });
      const objBody: any = this.newfileselection.selected ;
      this.apiManager.fetchData('moveToFNSRvwSmry', undefined, ApiManager.POST, objBody, undefined, undefined, queryParams)
        .subscribe(
          (res: any) => {
            if(res == true) {
              alert('Empty file found associated with the selected row!');
            } else {
              this._snackBar.open('Move to Review Files Successful.', undefined, {
                duration: 2000,
              });
              this.ngOnInit();
            }
              
            },
            error => {
              alert('Found Error while Move to Review Files!');
            }
          );
    }else{
      alert('Please select a row before Move to Review Files!');
    }

 }

 moveToElementReview(){
  const queryParams: any[] = [];
  queryParams.push("E");

  if(this.selection.selected.length>0){
    let filename: any;
    this.selection.selected.forEach(row => {
      console.log("row....", row);
    });
      const objBody: any = this.selection.selected ;
      this.apiManager.fetchData('moveToFNSRvwSmry', undefined, ApiManager.POST, objBody, undefined, undefined, queryParams)
        .subscribe(
          (res: any) => {
            if(res == true) {
              alert('Empty file found associated with the selected row!');
            } else {
              this._snackBar.open('Move to Element Review Successful.', undefined, {
                duration: 2000,
              });
              this.ngOnInit();
            }
          },
            error => {
              alert('Found Error while Move to Element Review!');
            }
          );
    }else{
      alert('Please select a row before Move to Element Review!');
    }

 }

merge(buttonId:any) {

  if(this.newselection.selected.length>0){
  let filename: any;


  this.newselection.selected.forEach(row => {
    console.log("row....", row);
  });
   const queryParams: any[] = [];
    if (this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }
    const invId: any = this.currentIndividual;
    queryParams.push(invId);
    this.screenType = "700140";
    queryParams.push(this.screenType);
    queryParams.push(this.constantsService.ReviewInfo.rvwType);
    queryParams.push(buttonId);

    filename = this.constantsService.getDisplayReviewNum(this.constantsService.ReviewInfo.rvwNum)+"_"+ this.utilService.getMemberNameByIndvId(invId)+"_FNS";

    const objBody: any = this.newselection.selected ;
    this.apiManager.fetchData('mergeAllSelectedDoc', undefined, ApiManager.POST, objBody, undefined, undefined, queryParams)
      .subscribe(
        (res: any) => {
            // let blob;
            // blob = new Blob([res], { type: 'application/pdf' });
            // saveAs(blob, filename);
            this._snackBar.open('FNS PDF Generated Successfully.', undefined, {
              duration: 2000,
            });
            this.newselection.clear();
            this.ngOnInit();
          },
          error => {
            console.log('Error in downloading the file after API call: Error');
            alert('Found Error while Generating FNS Review Summary!');
          }
        );
  }
  else{
    alert('Please select a row before Generate FNS Review Summary!');
  }
}

 download(row){
   console.log("download method"+row.qc_doc_repo_id);
   const queryParams: any[] = [];
   let filename: any;
   filename = row.tmplt_name;
   queryParams.push(row.qc_doc_repo_id);
     this.apiManager.fetchData('getDocumentForDownload', undefined, ApiManager.FILEDOWNLOAD, undefined, undefined, undefined, queryParams)
     .subscribe(res => {
       if (res) {
        let blob;
        blob = new Blob([res], { type: 'application/pdf' });
        saveAs(blob, filename);
       }
     }
   );
  }

  view(row){
    console.log("download method"+row.qc_doc_repo_id);
    const queryParams: any[] = [];
    let filename: any;
    filename = row.tmplt_name;
    queryParams.push(row.qc_doc_repo_id);
      this.apiManager.fetchData('getDocumentForDownload', undefined, ApiManager.FILEDOWNLOAD, undefined, undefined, undefined, queryParams)
      .subscribe(res => {
        if (res) {
       let blob = new Blob([res], { type: res.type });
         let fileURL = URL.createObjectURL(blob);
         var newWin = window.open(fileURL);
        }
      }
    );
   }
   
  edit(row,indicator){  
  if (this.isNotEditable) {
    return;
  }
  
    // Check if 'row.cmmts' exists and is not null before splitting
    if (row.cmmts && typeof row.cmmts === 'string') {
      const cmmtsArray = row.cmmts.split('_').filter(Boolean);
      row.cmmts = cmmtsArray;
    } else {
      // Set 'row.cmmts' to an empty string if it's null or not a string
    row.cmmts = '';
    }
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data = {
  index: this.dataSource.data.indexOf(row),
  ind : indicator,
  data: row,
  };
  const dialogRef = this.dialog.open(EditViewWorkbookComponent, dialogConfig);
  dialogRef.updateSize('75%', '85%');
  dialogRef.afterClosed().subscribe(val => {
    if (val) {
      console.log('afterClose', val);
    }
    this.ngOnInit();
  });
}


edit1(row,indicator){  
  if (this.isNotEditable) {
    return;
  }
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data = {
  index: this.newdataSource.data.indexOf(row),
  ind : indicator,
  data: row
  };
  const dialogRef = this.dialog.open(EditViewWorkbookComponent, dialogConfig);
  dialogRef.updateSize('75%', '85%');
  dialogRef.afterClosed().subscribe(val => {
    if (val) {
      console.log('afterClose', val);
    }
    this.ngOnInit();
  });
}

  delete(row){
    let selectedList = [];
    if(this.selection.selected.length){
      selectedList = this.selection.selected;
    }else{
      selectedList.push(row);
    }
    const queryParams: any[] = [];

   if (this.isNotEditable) {
    return;
  }
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.width = '900px';
  dialogConfig.data = selectedList;

  const dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
  dialogRef.afterClosed().subscribe(val => {
    if (val == 'yes') {
      const queryParams: any[] = [];
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    let list = [];
    selectedList.forEach(element => {
      list.push(element.qc_doc_repo_id);
    });
      this.apiManager.fetchData('updateQcDocStatusByIdList', undefined, ApiManager.POST, list, undefined, undefined, queryParams)
          .subscribe(res => {
            if (res) {
              this.dataSource = res;
              this.ngOnInit();
            }
            this.dialog.closeAll();
          });
    }
  });
  }
  delete1(row){
    let selectedList = [];
    if(this.selection.selected.length){
      selectedList = this.selection.selected;
    }else{
      selectedList.push(row);
    }
    const queryParams: any[] = [];

   if (this.isNotEditable) {
    return;
  }
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.width = '900px';
  dialogConfig.data = selectedList;

  const dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
  dialogRef.afterClosed().subscribe(val => {
    if (val == 'yes') {
      const queryParams: any[] = [];
      queryParams.push(row.qc_doc_repo_id);
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);

      this.apiManager.fetchData('updateQcDocStatus', undefined, ApiManager.POST, undefined, undefined, undefined, queryParams)
          .subscribe(res => {
            if (res) {
              this.newdataSource = res;
              this.ngOnInit();
            }
            this.dialog.closeAll();
          });
    }
  });
  }


  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }

  // fNSNameMap = {
  //   'Member Details': 110,
  //   'Education Details': 111,
  //   'Citizenship Details': 130,
  //   'Citizenship Sponsorship': 130,
  //   'Contact Information': 140,
  //   'Individual Details': 140,
  //   'Authorized Representative': 140,
  //   'Roomer and Boarder': 140,
  //   'Roomer & Boarder': 140,
  //   'Sanctions and Disqualifications': 151,
  //   'Sanctions/Disqualifications': 151,
  //   'ABAWD': 161,
  //   'Program Time Limits': 161,
  //   'Work Details': 162,
  //   'Works Details': 162,
  //   'Works Exemptions': 162,
  //   'Works Exemption': 162,
  //   'Issuance Summary': 'RCTM',
  //   'Summary': 'P&M',
  //   'Program Request': 'P&M',
  //   'Verification Status': 'P&M',
  //   'Audit Trail': 'P&M'
  // };

  // getFNSName(element) :string{
  //   if(element.element_num) {
  //     return element.element_num;
  //   } else if(this.fNSNameMap[element.tmplt_name]) {
  //     return  this.fNSNameMap[element.tmplt_name];
  //   } else {
  //     return 'FNS Name';
  //   }
  // }
  
  posBookmarkDetailPDF(){
    let newWindow = window.open("https://drive.google.com/file/d/13S9V-Lim3VRVgeqzoAa6eEOdRWUTbnPf/view");
  }

  negBookmarkDetailPDF(){
    let newWindow = window.open("https://drive.google.com/file/d/1c3Y_UD10wnTiLkQTIlFUQWe-Y9OLyKJ3/view");
  }
 
  combineFiles(){
    
  if(this.newfileselection.selected.length>0){
    let filename: any;
  
  
    this.newfileselection.selected.forEach(row => {
      console.log("row....", row);
    });
     const queryParams: any[] = [];
      if (this.constantsService.ReviewInfo &&
        this.constantsService.ReviewInfo.rvwNum) {
        queryParams.push(this.constantsService.ReviewInfo.rvwNum);
      }
      const invId: any = this.currentIndividual;
      queryParams.push(invId);
      this.screenType = "700140";
      queryParams.push(this.screenType);
      queryParams.push(this.constantsService.ReviewInfo.rvwType);
      queryParams.push("1");

      filename = this.constantsService.getDisplayReviewNum(this.constantsService.ReviewInfo.rvwNum)+"_"+ this.utilService.getMemberNameByIndvId(invId)+"_Combine";
  
      const objBody: any = this.newfileselection.selected ;
     
      this.apiManager.fetchData('mergeAllSelectedDoc', undefined, ApiManager.POST, objBody, undefined, undefined, queryParams)
        .subscribe(
          (res: any) => {
              // let blob;
              // blob = new Blob([res], { type: 'application/pdf' });
              // saveAs(blob, filename);
              this._snackBar.open('Combine PDF Generated Successfully.', undefined, {
                duration: 2000,
              });
              this.newfileselection.clear();
              this.ngOnInit();
            },
            error => {
              console.log('Error in downloading the file after Combine Files API call: Error');
              alert('Found Error while Combine Files! Please make sure to combine PDF Files only!');
            }
          );
    }
    
    else{
      alert('Please select a row before Combine Files!');
    }
  }

  sortData(sort: Sort) {
    const data = this.filesToUpload;
    if (!sort.active || sort.direction === '') {
      this.filesToUpload = data;
      return;
    }
    this.filesToUpload = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'order': return compare(a.order, b.order, isAsc);
        case 'element_num':
          if(this.pgm_type === 'FSPPositive' || this.pgm_type === 'ESAPPositive') {
            if(isAsc)
            return compareElemAsc(a.element_num,b.element_num);
          else
            return compareElemDesc(a.element_num,b.element_num);
          }
          else if(this.pgm_type === 'FSPNegative' || this.pgm_type === 'ESAPNegative') {
            if(isAsc)
            return compareElemAscNeg(a.element_num,b.element_num);
          else
            return compareElemDescNeg(a.element_num,b.element_num);
          }
          
        case 'tmplt_name': return compare(a.tmplt_name, b.tmplt_name, isAsc);
        case 'create_dt' : return compare(a.create_dt, b.create_dt, isAsc);
        default: return 0;
      }
    });

    this.filesToUpload = [... this.filesToUpload];
  }

  sortDataNewElem(sort: Sort) {
    const data = this.elementfiles;
    if (!sort.active || sort.direction === '') {
      this.elementfiles = data;
      return;
    }

    this.elementfiles = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'order': return compare(a.order, b.order, isAsc);
        case 'tmplt_name': return compare(a.tmplt_name, b.tmplt_name, isAsc);
        case 'element_num':
          if(this.pgm_type === 'FSPPositive' || this.pgm_type === 'ESAPPositive') {
            if(isAsc)
            return compareElemAsc(a.element_num,b.element_num);
          else
            return compareElemDesc(a.element_num,b.element_num);
          }
          else if(this.pgm_type === 'FSPNegative' || this.pgm_type === 'ESAPNegative') {
            if(isAsc)
            return compareElemAscNeg(a.element_num,b.element_num);
          else
            return compareElemDescNeg(a.element_num,b.element_num);
          }
        case 'create_dt' : return compare(a.create_dt, b.create_dt, isAsc);
        default: return 0;
      }
    });

    this.elementfiles = [... this.elementfiles];
  }

  sortDataNew(sort: Sort) {
    const data = this.newfilesToUpload;
    if (!sort.active || sort.direction === '') {
      this.newfilesToUpload = data;
      return;
    }

    this.newfilesToUpload = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'order': return compare(a.order, b.order, isAsc);
        case 'element_num':
          if(this.pgm_type === 'FSPPositive' || this.pgm_type === 'ESAPPositive') {
            if(isAsc)
            return compareElemAsc(a.element_num,b.element_num);
          else
            return compareElemDesc(a.element_num,b.element_num);
          }
          else if(this.pgm_type === 'FSPNegative' || this.pgm_type === 'ESAPNegative') {
            if(isAsc)
            return compareElemAscNeg(a.element_num,b.element_num);
          else
            return compareElemDescNeg(a.element_num,b.element_num);
          }
        case 'tmplt_name': return compare(a.tmplt_name, b.tmplt_name, isAsc);
        case 'create_dt' : return compare(a.create_dt, b.create_dt, isAsc);
        default: return 0;
      }
    });

    this.newfilesToUpload = [... this.newfilesToUpload];
  }
}

function compare(a: number | string | Date , b: number | string | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

function compareElemAsc(a: any, b: any) {
  const elem = {'FNSPos':'-100','FNSPos2':'-110','QC16':'-120','380S':'-130','380WB':'-140','CALC':'-150',
  '110':'-160','111':'-170','130':'-180','140':'-190','150':'-200','151':'-210','160':'-220','161':'-230',
  '162':'-240','163':'-250','164':'-260','165':'-270','166':'-280','170':'-290','211':'-300','212':'-310',
  '213':'-320','221':'-330','222':'-340','224':'-350','225':'-360','311':'-370','312':'-380','314':'-390',
  '321':'-400','323':'-410','331':'-420','332':'-430','333':'-440','334':'-450','335':'-460','336':'-470',
  '342':'-480','343':'-490','344':'-500','345':'-510','346':'-520','350':'-530','361':'-540','363':'-550',
  '364':'-560','365':'-570','366':'-580','530':'-590','820':'-600','SNAP':'-610','DOC':'-620','ELIGH':'-630',
  'BENH':'-640','RCTM':'-650','P&M':'-660','':'-930',' ':'-940','null':'-960','FNS Name':'-970'};
  if (elem[a] && elem[b]) 
    return  (elem[a] < elem[b] ? -1 : 1);
  else 
    return compare(a,b,true);
}

function compareElemDesc(a: any, b: any) {
  const elem = {'FNSPos':'-100','FNSPos2':'-110','QC16':'-120','380S':'-130','380WB':'-140','CALC':'-150','SNAP':'-160','DOC':'-170',
	'ELIGH':'-180','BENH':'-190','110':'-200','111':'-210','130':'-220',
	'140':'-230','150':'-240','151':'-250','160':'-260','161':'-270','162':'-280','163':'-290','164':'-300',
	'165':'-310','166':'-320','170':'-330','211':'-340','212':'-350','213':'-360','221':'-370','222':'-380',
	'224':'-390','225':'-400','311':'-410','312':'-420','314':'-430','321':'-440','323':'-450','331':'-460',
	'332':'-470','333':'-480','334':'-490','335':'-500','336':'-510','342':'-520','343':'-530','344':'-540',
  '345':'-550','346':'-560','350':'-570','361':'-580','363':'-590','364':'-600','365':'-610','366':'-620',
  '530':'-630','820':'-640','RCTM':'-650','P&M':'-660', '':'-930',' ':'-940','null':'-960','FNS Name':'-970'};
  if ( elem[a] && elem[b]) 
    return  (elem[a] < elem[b] ? -1 : 1);
  else 
    return compare(a,b,true);
}

function compareElemAscNeg(a: any, b: any) {
 
  const elem = {'FNSNeg':'-100','QC16N':'-110','245S':'-120','DONAT':'-130','SNAP':'-140','Doc':'-150','CalcSht':'-160', 'CALC':'-170',
  'EligHs':'-180','BenHs':'-190','':'-930',' ':'-940','null':'-960','FNS Name':'-970'};
  if (elem[a] && elem[b]) 
    return  (elem[a] < elem[b] ? -1 : 1);
  else 
    return compare(a,b,true);
}

function compareElemDescNeg(a: any, b: any) {

  const elem = {'FNSNeg':'-100','QC16N':'-110','245S':'-120','DONAT':'-130','SNAP':'-140','Doc':'-150','CalcSht':'-160', 'CALC':'-170',
  'EligHs':'-180','BenHs':'-190','':'-930',' ':'-940','null':'-960','FNS Name':'-970'};
  if (elem[a] && elem[b]) 
    return  (elem[a] < elem[b] ? -1 : 1);
  else 
    return compare(a,b,true);
}