import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { QuestionaryComponent } from './../../shared-module/questionary/questionary.component';
import { QuestionaryResolver } from './../../shared-module/questionary/questionary.resolver';
import { CaseAssignmentComponent } from 'src/app/shared-module/case-assignment/case-assignment.component';
import { ReviewCaseListComponent } from 'src/app/shared-module/review-case-list/review-case-list.component';
import { MonitoringDashboardComponent } from './monitoring-admin/monitoring-dashboard/monitoring-dashboard.component';
import { MonitoringSampleComponent } from './monitoring-admin/monitoring-sample/monitoring-sample.component';
import { PreReivewDirectComponent } from './monitoring-alerts/pre-reivew-direct/pre-reivew-direct.component';
import { MonitoringMemosComponent } from './monitoring-memos/monitoring-memos/monitoring-memos.component';




const routes: Routes = [
  // {
  //   path: '',
  //   component: PaDashboardComponent
  // },
  
  {
    path: 'monitoring-dashboard',
    component: MonitoringDashboardComponent
  },
  {
    path: 'monitoring-sample',
    component: MonitoringSampleComponent
  },
  {
      path: 'monitoring-case-assignment',
      component: CaseAssignmentComponent
  },
  {
    path: 'monitoring-case-review-list',
    component: ReviewCaseListComponent
  },
  {
    path: 'questionary-400006',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-400007',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-400008',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-400009',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-400010',
    component: PreReivewDirectComponent,
  
  },
  {
    path: 'questionary-400012',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-400013',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-400014',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-400015',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-400017',
    component: MonitoringMemosComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MonitoringRoutingModule { }
