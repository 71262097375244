import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-employment-status-job-availability',
  templateUrl: './employment-status-job-availability.component.html',
  styleUrls: ['./employment-status-job-availability.component.css']
})
export class EmploymentStatusJobAvailabilityComponent extends BaseComponent implements OnInit {

isNotEditable : boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  get Is165_1():boolean{
    return this.group.controls['165.1'].value === 'N';
      }

  get Is165_3():boolean{
    return this.group.controls['165.3'].value === 'N';
      }


handleQ165_9(val){
        if(val === '9'){
          this.group.controls['165.9a'].enable();
        }else{
          this.group.controls['165.9a'].disable();
        }
    }
}