import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-medical-deductions',
  templateUrl: './medical-deductions.component.html',
  styleUrls: ['./medical-deductions.component.css']
})

export class MedicalDeductionsComponent extends BaseComponent implements OnInit {
  isNotEditable: boolean = false; 
  currentPage: number = 0;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.handle_365_1();
    this.handle_365_2();
  }


  get Is365_1(): boolean {
    return this.group.controls['365.1'].value === 'N';
  }

  get Is365_2(): boolean {
    return this.group.controls['365.2'].value === 'N';
  }

 
  handle_365_1(){
    let q1 = this.group.controls['365.1'].value;
    if(q1 === 'N') {
      this.group.controls['365.4'].disable();
    }
    else {
      this.group.controls['365.4'].enable();
    }
  }
  handle_365_2(){
    let q1 = this.group.controls['365.2'].value;
    if(q1 === 'N') {
      this.group.controls['365.4'].disable();
    }
    else {
      this.group.controls['365.4'].enable();
    }
  }
 
}
