import { Component, OnInit } from '@angular/core';
import { GoogleAuthService } from 'ng-gapi';
import { ConstantsService } from '../../common/constants.service';
import { ApiManager } from '../../common/api-manager.service';
import { UserService } from '../../common/UserService';
import { GoogleApiService } from 'ng-gapi';
import { SheetResource } from '../../common/SheetResource';
import {ActivatedRoute, Router} from '@angular/router';
import { environment } from 'src/environments/environment';
import { NavigationService } from 'src/app/common/navigation.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialog } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ValidateCalcSheetDialogComponent } from '../validate-calc-sheet-dialog/validate-calc-sheet-dialog.component';

@Component({
  selector: 'app-google-sheet',
  templateUrl: './google-sheet.component.html',
  styleUrls: ['./google-sheet.component.css']
})
export class GoogleSheetComponent implements OnInit {

  // enableSave: boolean = false;
  // isNotEditable:boolean = false;
  // ccId : any = 0;
  // calcSheetTemplateList = [];
  constructor(
      // public constantsService: ConstantsService,
      // private apiManager: ApiManager,
      // private userService: UserService,
      // private sheetResource: SheetResource,
      // private route: ActivatedRoute,
      // private authService: GoogleAuthService,
      // private gapiService: GoogleApiService,
      // private router: Router,
      // public navigationService: NavigationService,
      // public _snackBar: MatSnackBar,
      // public dialog: MatDialog,

  ) {
    // First make sure gapi is loaded can be in AppInitilizer
    // this.gapiService.onLoad().subscribe();
   }

  ngOnInit() {
  //   this.getCalcSheetTemplate();
  //   this.route.fragment.subscribe((fragment) => {
  //     console.log(fragment);
  // }
  // );
    
  // this.sheetResource.findById('1B_uEoOwHI_zA5BmY3rM27Mw1AnIKEX0IjiqW35PDDbE', 
  //   this.userService.getToken()).subscribe(res => {
  //     console.log(res);
  //   });

  
  
    
  }

  // public getCalcSheetTemplate() {

  //   const queryParams: any[] = [];
  //   this.apiManager.fetchData('getCalcSheetTemplate', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
  //   .subscribe(
  //     res => {
  //       this.calcSheetTemplateList = res;

  //     });
    
  // }

  // public validateCalcSheet(calcSheetTemplateNm : any) {
  //   const queryParams: any[] = [];
  //   if (this.constantsService.ReviewInfo &&
  //     this.constantsService.ReviewInfo.rvwNum) {
  //     queryParams.push(this.constantsService.ReviewInfo.rvwNum);
  //   }
  //   queryParams.push(calcSheetTemplateNm);
  //   this.apiManager.fetchData('validateCalcSheet', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
  //   .subscribe(
  //     res => {
  //       const dialogConfig = new MatDialogConfig();
  //       dialogConfig.disableClose = true;
  //       dialogConfig.autoFocus = true;
  //       dialogConfig.width = '700px';
  //       dialogConfig.height = 'auto';
  //       dialogConfig.data = res;
    
  //       const dialogRef = this.dialog.open(ValidateCalcSheetDialogComponent, dialogConfig);
        
  //       dialogRef.afterClosed().subscribe(result => {
  //         if(result) {

  //         }
  //         }
  //       );
  //     });
  // }

  // public runCalcSheet(calcSheetTemplateNm : any) {
  //   this.sheetResource.copySpreadSheet('1B_uEoOwHI_zA5BmY3rM27Mw1AnIKEX0IjiqW35PDDbE', this.userService.getToken()).subscribe(res => {
  //     // console.log(res);
  //     window.open(res.spreadsheetUrl);
  //   });
  //   window.open(environment.baseUrlDev + 'oauth2/authorization/google');

  //   const queryParams: any[] = [];
  //   if (this.constantsService.ReviewInfo &&
  //     this.constantsService.ReviewInfo.rvwNum) {
  //     queryParams.push(this.constantsService.ReviewInfo.rvwNum);
  //   }
  //   queryParams.push(calcSheetTemplateNm);
  //   this.apiManager.fetchData('getCalcSheet', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
  //   .subscribe(
  //     res => {
  //       let newWindow = window.open(res.spreadSheetUrl, 'calcSheetPopupWindow',
  //       'location=no,width=800,height=700,scrollbars=yes,modal=yes');
  //       this.enableSave = true;
  //       const dialogRef = this.dialog.open(ConfirmationDialog, {
  //         disableClose: false
  //       });
  //       dialogRef.componentInstance.confirmMessage = "Are you sure you want to save the Calc Sheet?"
        
  //       dialogRef.afterClosed().subscribe(result => {
  //         if(result) {
  //           this.save();
  //         }

  //       // if(newWindow.closed){
  //       //   console.log('calc sheet closed.........');
  //       // }
        
  //       // newWindow.onbeforeunload = () => null;
  //       // newWindow.onbeforeunload = function(){
  //       //   alert("You are now leaving, are you sure?")
  //       // }
  //       // newWindow.addEventListener('onunload', (evt) => {
  //       //   alert("You are now leaving, are you sure?")
  //       //     console.log('calc sheet closed.........');
  //       //     console.log(evt);
  //         }
  //       );
  //     });
  // }

  // viewCalcSheet(calcSheetTemplateNm : any) {
  //   const queryParams: any[] = [];
  //   if (this.constantsService.ReviewInfo &&
  //     this.constantsService.ReviewInfo.rvwNum) {
  //     queryParams.push(this.constantsService.ReviewInfo.rvwNum);
  //   }
  //   queryParams.push(calcSheetTemplateNm);

  //     this.apiManager.fetchData('getCalcSheet', undefined, ApiManager.FILEDOWNLOAD, undefined, undefined, undefined, queryParams)
  //     .subscribe(res => {
  //       if(res.size===0){
  //         this._snackBar.open('Please Run Calc Sheet and Save to View Calc Sheet.', undefined, {
  //           duration: 2000,
  //         });
  //       }else if (res) {
  //        let blob = new Blob([res], { type: res.type });
  //        let fileURL = URL.createObjectURL(blob);
  //        var newWin = window.open(fileURL);
  //       }
  //     }
  //   );
  //   // this.sheetResource.copySpreadSheet('1B_uEoOwHI_zA5BmY3rM27Mw1AnIKEX0IjiqW35PDDbE', this.userService.getToken()).subscribe(res => {
  //   //   // console.log(res);
  //   //   window.open(res.spreadsheetUrl);
  //   // });
  //   // window.open(environment.baseUrlDev + 'oauth2/authorization/google');

  //   // const queryParams: any[] = [];
  //   // if (this.constantsService.ReviewInfo &&
  //   //   this.constantsService.ReviewInfo.rvwNum) {
  //   //   queryParams.push(this.constantsService.ReviewInfo.rvwNum);
  //   // }
  //   // this.apiManager.fetchData('getCalcSheet', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
  //   // .subscribe(
  //   //   res => {
  //   //     let viewSheet = res.spreadSheetUrl;
  //   //     viewSheet = viewSheet.split('/edit?')[0]+ '/export?format=pdf&attachment=false&portrait=false&scale=1&fitw=true';
  //   //     let newWindow = window.open(viewSheet, 'calcSheetPopupWindow',
  //   //     'location=no,width=800,height=700,scrollbars=yes,modal=yes');
  //   //     this.enableSave = true;
  //   //     // newWindow.onbeforeunload = () => null;
  //   //     // newWindow.addEventListener('onunload', (evt) => {
  //   //     //     console.log('calc sheet closed.........');
  //   //     //     console.log(evt);
  //   //     //   }
  //   //     // );
  //   //   });
  // }

  // save() {
  //   if ( this.enableSave ) {
  //     const queryParams: any[] = [];
  //     if (this.constantsService.ReviewInfo &&
  //       this.constantsService.ReviewInfo.rvwNum) {
  //       queryParams.push(this.constantsService.ReviewInfo.rvwNum);
  //     }
  //     this.apiManager.fetchData('saveCalcSheet', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
  //     .subscribe(
  //       res => {
  //        this.ccId = res;
  //       });
  //   }

  // }



  
  // public isLoggedIn(): boolean {
  //   return this.userService.isUserSignedIn();
  // }

  // public signIn() {
  //   this.userService.signIn();
  // }

  // back() {
  //   this.navigationService.navigatePage(this.router.url, 'back');
  // }

  // next() {
  //   let varianceInd = false;
  //   this.navigationService.getNavItems().map(v => {
  //     if (v.nvgtn_cd == 'nav-CalcSheetVar' &&  this.constantsService.ReviewInfo.rvwType === 'FSPPositive') {
  //       if(v.disabled == 'Y'){
  //         varianceInd = true;
  //         this.navigationService.navigatePage("/qualityControl/questionary-Var?type=Var",'next');
  //         return;
  //       }
  // }
  // });
  // if(!varianceInd){
  //   this.navigationService.navigatePage(this.router.url, 'next');
  // }
  // }
}
