import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-receiptof-expedited-service',
  templateUrl: './receiptof-expedited-service.component.html',
  styleUrls: ['./receiptof-expedited-service.component.css']
})
export class ReceiptofExpeditedServiceComponent extends BaseComponent implements OnInit {
isNotEditable: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.setControlValue('f26.4','$0');
    this.changef26_5(this.getControlValue('f26.5'));
  }

  changef26_5(val) {
    if ( val === 'N') {
      this.setValuef26_9('3');
      this.disableforF26_5(true);
    } else {
      //this.setValuef26_9('');
      this.disableforF26_5(false);
    }
  }

  changef26_6(val) {
    if ( val === 'Y') {
      this.setValuef26_9('3');
      this.disableforF26_6(true);
    } else {
      this.setValuef26_9('');
      this.disableforF26_6(false);
      
    }
  }

  changef26_7(val) {
    if ( val === '5') {
      this.disableforF26_7(true);
      this.setValuef26_9('3');
    }else {
      this.setValuef26_9('');
      this.disableforF26_7(false);
    }
  }

  changef26_8(val) {
    if ( val === 'Y') {
      this.setValuef26_9('1');
    } else if ( val === 'N') {
      this.setValuef26_9('2');
    }
  }

  setValuef26_9(val) {
    this.group.controls['f26.9'].patchValue(val);
  }

  disableforF26_5(val) {
    if (val) {
      this.group.controls['f26.6'].disable();
      this.group.controls['f26.7'].disable();
      this.group.controls['f26.8'].disable();
    } else {
      this.group.controls['f26.6'].enable();
      this.group.controls['f26.7'].enable();
      this.group.controls['f26.8'].enable();
    }
    
  }

  disableforF26_6(val) {
    if (val) {
      this.group.controls['f26.7'].disable();
      this.group.controls['f26.8'].disable();
    } else {
      this.group.controls['f26.7'].enable();
      this.group.controls['f26.8'].enable();
    }
    
  }
  disableforF26_7(val) {
    if (val) {
     
      this.group.controls['f26.8'].disable();
    } else {
      
      this.group.controls['f26.8'].enable();
    }
    
  }

}
