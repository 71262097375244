import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-voluntary-withdrawl-application',
  templateUrl: './voluntary-withdrawl-application.component.html',
  styleUrls: ['./voluntary-withdrawl-application.component.css']
})
export class VoluntaryWithdrawlApplicationComponent extends BaseComponent implements OnInit {
 ngOnInit() {
    super.ngOnInit();
  }

}


