import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { AgMaterialModule } from "../angular-material/ag-matrial.module";
import { CommonModule } from '@angular/common';
import { CustomValidatorsService } from "../common/custom-validators.service";
import {
  QuestionaryComponent,
  QuestionaryDialog
} from './questionary/questionary.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { DynamicFieldDirective } from './dynamic-field/dynamic-field.directive';
import { InputComponent } from './input/input.component';
import { ButtonComponent } from './button/button.component';
import { SelectComponent } from './select/select.component';
import { DateComponent } from './date/date.component';
import { RadiobuttonComponent } from './radiobutton/radiobutton.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { BaseComponent } from './baseComponent.component';
import { CaseDocumentComponent } from './case-document/case-document.component';
import { CaseAssignmentComponent } from './case-assignment/case-assignment.component';
import { ReviewCaseListComponent } from './review-case-list/review-case-list.component';
import { WorkItemsComponent } from './work-items/work-items.component';
import { ClarifyComponent } from './clarify/clarify.component';
import { ReviewSummaryComponent } from './review-summary/review-summary.component';
import { FindRespComponent, FindResponseDialog } from './find-resp/find-resp.component';
import { CaseCountViewComponent } from './case-count-view/case-count-view.component';
import {DeleteDialogComponent} from './delete-dialog/delete-dialog.component';
import { Qc13FormComponent } from './qc-forms/qc13-form/qc13-form.component';
import { PrintService } from '../common/print.service';
import { QcAppointmentLetterComponent } from './qc-forms/qc-appointment-letter/qc-appointment-letter.component';
import { Qc13ALivingArrangmentsComponent } from './qc-forms/qc13-a-living-arrangments/qc13-a-living-arrangments.component';
import { QC14BankVerificationComponent } from './qc-forms/qc14-bank-verification/qc14-bank-verification.component';
import { QC19ChildSupportAbsenceComponent } from './qc-forms/qc19-child-support-absence/qc19-child-support-absence.component';
import { QC32CustomerInformationRequestComponent } from './qc-forms/qc32-customer-information-request/qc32-customer-information-request.component';
import { QC33CaseClosureRequestComponent } from './qc-forms/qc33-case-closure-request/qc33-case-closure-request.component';
import { QC35ARescindCaseClosureRequestComponent } from './qc-forms/qc35-a-rescind-case-closure-request/qc35-a-rescind-case-closure-request.component';
import { QC35BRequestforContactComponent } from './qc-forms/qc35-b-requestfor-contact/qc35-b-requestfor-contact.component';
import { QC35BQualityControlQC35BProcessComponent } from './qc-forms/qc35-b-quality-control-qc35-bprocess/qc35-b-quality-control-qc35-bprocess.component';
import { QC37EmploymentVerificationLetterComponent } from './qc-forms/qc37-employment-verification-letter/qc37-employment-verification-letter.component';
import { QC38ContributionVerificationLetterComponent } from './qc-forms/qc38-contribution-verification-letter/qc38-contribution-verification-letter.component';
import { QC39ChildCareVerificationformComponent } from './qc-forms/qc39-child-care-verificationform/qc39-child-care-verificationform.component';
import { QC53HealthVerificationFormComponent } from './qc-forms/qc53-health-verification-form/qc53-health-verification-form.component';
import { QC54SchoolVerificationFormComponent } from './qc-forms/qc54-school-verification-form/qc54-school-verification-form.component';
import { QC56ReleaseofInformationFormComponent } from './qc-forms/qc56-releaseof-information-form/qc56-releaseof-information-form.component';
import { QC199NonCooperationFormComponent } from './qc-forms/qc199-non-cooperation-form/qc199-non-cooperation-form.component';
import { HiEdHigherEducationFormComponent } from './qc-forms/hi-ed-higher-education-form/hi-ed-higher-education-form.component';
import { FAXCoverSheetComponent } from './qc-forms/faxcover-sheet/faxcover-sheet.component';
import { InformationRequestComponent } from './qc-forms/information-request/information-request.component';
import { QC56PostalVerificationComponent } from './qc-forms/qc56-postal-verification/qc56-postal-verification.component';
import { PrintSectionDirective } from './print-section.directive';
import { RunSamplingComponent, RunSamplingDialog,RunSamplingReportComponent } from './run-sampling/run-sampling.component';
import { MonthFormatDirective } from './month-format.directive';
import { FsmeScheduleComponent, FSMEScheduleDialog, FSMEViewDialog } from './fsme-schedule/fsme-schedule.component';
import { ViewReviewHistoryComponent } from './view-review-history/view-review-history.component';
import { SnapSampleComponent } from './snap-sample/snap-sample.component';
// import { GoogleSheetComponent } from './google-sheet/google-sheet.component';
import { FsmeSupervisoryReviewComponent } from './fsme-supervisory-review/fsme-supervisory-review.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { LdssInformationComponent, LdssInformationDialog } from './ldss-information/ldss-information.component';
import { fnsVerifyComponent } from '../quality-control/fns-transmission-summary/fns-transmission-summary.component';
import { ReportIssueComponent } from './report-issue/report-issue.component';
import { TextMaskModule } from 'angular2-text-mask';
import { SendEmailComponent } from './qc-forms/send-email/send-email.component';
import { AssignmentSummaryComponent } from './assignment-summary/assignment-summary.component';
import { ReviewDocumentsComponent } from './review-documents/review-documents.component';
import { FspReviewLogComponent } from './fsp-review-log/fsp-review-log.component';
import { FsnegReviewLogComponent } from './fsneg-review-log/fsneg-review-log.component';
import { SsnMaskDirective } from './ssn-mask.directive';
// import { FileScanComponent } from './file-scan/file-scan.component';
import { Qc10Component, qc10Dialog } from './qc10/qc10.component';
import { RegionAssignmentComponent } from './region-assignment/region-assignment.component';
import { FileScanComponent } from './file-scan/file-scan.component';
import { LookupManagerService } from '../common/lookup-manager.service';
import { DialogModule } from '../dialog/dialog.module';
import { FileScan2Component } from './file-scan/file-scants2';
import { DisableOnClickDirective } from './disable-on-click.directive';
import { UpdateItemsComponent } from './update-items/update-items.component';
import { ValidateCalcSheetDialogComponent } from './validate-calc-sheet-dialog/validate-calc-sheet-dialog.component';
import { AlertDetailsDialogComponent } from './alert-details-dialog/alert-details-dialog.component';
import { CreateAlertDialogComponent } from './create-alert-dialog/create-alert-dialog.component';
import { QcInterviewGuide } from './qc-forms/qc-interview-guide/qc-interview-guide.component';





@NgModule({
  declarations: [
    DynamicFieldDirective,
    DynamicFormComponent,
    BaseComponent,
    InputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    QuestionaryComponent,
    QuestionaryDialog,
    CaseDocumentComponent,
    CaseAssignmentComponent,
    ReviewCaseListComponent,
    WorkItemsComponent,
    UpdateItemsComponent,
    ClarifyComponent,
    ReviewSummaryComponent,
    FindRespComponent,
    FindResponseDialog,
    CaseCountViewComponent,
    DeleteDialogComponent,
    AlertDetailsDialogComponent,
    CreateAlertDialogComponent,
    ValidateCalcSheetDialogComponent,
    Qc13FormComponent,
    QcAppointmentLetterComponent,
    Qc13ALivingArrangmentsComponent,
    QC14BankVerificationComponent,
    QC19ChildSupportAbsenceComponent,
    QC32CustomerInformationRequestComponent,
    QC33CaseClosureRequestComponent,
    QC35ARescindCaseClosureRequestComponent,
    QC35BRequestforContactComponent,
    QC35BQualityControlQC35BProcessComponent,
    QC37EmploymentVerificationLetterComponent,
    QC38ContributionVerificationLetterComponent,
    QC39ChildCareVerificationformComponent,
    QC53HealthVerificationFormComponent,
    QC54SchoolVerificationFormComponent,
    QC56ReleaseofInformationFormComponent,
    QC199NonCooperationFormComponent,
    HiEdHigherEducationFormComponent,
    FAXCoverSheetComponent,
    InformationRequestComponent,
    QC56PostalVerificationComponent,
    PrintSectionDirective,
    DisableOnClickDirective,
    RunSamplingComponent,
    RunSamplingDialog,
    RunSamplingReportComponent,
    MonthFormatDirective,
    FsmeScheduleComponent,
    FSMEScheduleDialog,
    FSMEViewDialog,
    ViewReviewHistoryComponent,
    SnapSampleComponent,
    // GoogleSheetComponent,
    FsmeSupervisoryReviewComponent,
    FileUploadComponent,
    LdssInformationComponent,
    // FileScanComponent,
    LdssInformationDialog,
    fnsVerifyComponent,
    ReportIssueComponent,
    SendEmailComponent,
    AssignmentSummaryComponent,
    ReviewDocumentsComponent,
    FspReviewLogComponent,
    FsnegReviewLogComponent,
    SsnMaskDirective,
    Qc10Component,
    qc10Dialog,
    RegionAssignmentComponent,
    FileScanComponent,
    FileScan2Component,
    QcInterviewGuide
  
    // FileScanComponent,
    // FileScan2Component
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    AgMaterialModule,
    CommonModule,
    TextMaskModule,
    DialogModule
   
  ],
  exports: [
    FormsModule,
    MonthFormatDirective,
    SsnMaskDirective,
    DynamicFieldDirective,
    PrintSectionDirective,
    DisableOnClickDirective,
    DynamicFormComponent,
    QuestionaryComponent,
    QuestionaryDialog,
    WorkItemsComponent,
    UpdateItemsComponent,
    ReviewSummaryComponent,
    FindRespComponent,
    FindResponseDialog,
    CaseCountViewComponent,
    RunSamplingComponent,
    RunSamplingDialog,
    RunSamplingReportComponent,
    FsmeScheduleComponent,
    FSMEScheduleDialog,
    FSMEViewDialog,
    ViewReviewHistoryComponent,
    SnapSampleComponent,
    // GoogleSheetComponent,
    FsmeSupervisoryReviewComponent,
    FileUploadComponent,
    LdssInformationComponent,
    // FileScanComponent,
    LdssInformationDialog,
    fnsVerifyComponent,
    ReportIssueComponent,
    ReviewDocumentsComponent,
    Qc10Component,
    DialogModule
  ],
  entryComponents: [
    BaseComponent,
    InputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    CaseDocumentComponent,
    ClarifyComponent,
    ReviewSummaryComponent,
    ReviewDocumentsComponent,
    FindRespComponent,
    FindResponseDialog,
    CaseCountViewComponent,
    DeleteDialogComponent,
    CreateAlertDialogComponent,
    AlertDetailsDialogComponent,
    ValidateCalcSheetDialogComponent,
    Qc13FormComponent,
    QcAppointmentLetterComponent,
    Qc13ALivingArrangmentsComponent,
    QC14BankVerificationComponent,
    QC19ChildSupportAbsenceComponent,
    QC32CustomerInformationRequestComponent,
    QC33CaseClosureRequestComponent,
    QC35ARescindCaseClosureRequestComponent,
    QC35BRequestforContactComponent,
    QC35BQualityControlQC35BProcessComponent,
    QC37EmploymentVerificationLetterComponent,
    QC38ContributionVerificationLetterComponent,
    QC39ChildCareVerificationformComponent,
    QC53HealthVerificationFormComponent,
    QC54SchoolVerificationFormComponent,
    QC56ReleaseofInformationFormComponent,
    QC199NonCooperationFormComponent,
    HiEdHigherEducationFormComponent,
    FAXCoverSheetComponent,
    SendEmailComponent,
    InformationRequestComponent,
    QC56PostalVerificationComponent,
    RunSamplingComponent,
    RunSamplingDialog,
    RunSamplingReportComponent,
    FsmeScheduleComponent,
    FSMEScheduleDialog,
    FSMEViewDialog,
    ViewReviewHistoryComponent,
    SnapSampleComponent,
    // GoogleSheetComponent,
    FsmeSupervisoryReviewComponent,
    FileUploadComponent,
    LdssInformationComponent,
    // FileScanComponent,
    LdssInformationDialog,
    fnsVerifyComponent,
    ReportIssueComponent,
    FspReviewLogComponent,
    FsnegReviewLogComponent,
    Qc10Component,
    qc10Dialog,
    QcInterviewGuide
    
  ],
  providers: [
    CustomValidatorsService,
    PrintService,
    LookupManagerService
  ]
})
export class SharedModuleModule { }
