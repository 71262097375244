import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-calcsheet-dataQuestions',
  templateUrl: './calcsheet-dataQuestions.component.html',
  styleUrls: ['./calcsheet-dataQuestions.component.css']
})
export class CalcsheetdataQuestionsComponent  extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess(); 
    const reviewNumber = this.constantsService.ReviewInfo.rvwNum;

    // Convert the number to a string
    const reviewNumberString = reviewNumber.toString();

    // Check if the sixth character is '2'
    if (reviewNumberString.charAt(5) !== '2' && this.constantsService.ReviewInfo.pgmTypeDesc != 'OTO') { 
      this.group.controls['885.12a'].disable();
      this.setControlValue('885.12a','');
      this.group.controls['885.12b'].disable();
      this.setControlValue('885.12b','');
      this.group.controls['885.12c'].disable();
      this.setControlValue('885.12c','');
      console.log("Not an OTO Case");
    } else {
      // It's  OTO case
      console.log("OTO Case");
    }

}
}