import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-work-registration-requirements',
  templateUrl: './work-registration-requirements.component.html',
  styleUrls: ['./work-registration-requirements.component.css']
})

export class WorkRegistrationRequirementsComponent extends BaseComponent implements OnInit {
  isNotEditable: boolean = false
  ngOnInit() {

    // Setting this Default EE-75104
    if (this.getControlValue('162.03') === null) {
      this.setControlValue('162.03', '2');
    }
  
    this.changeof162_1(this.getControlValue('162.1'));
    this.changeof162_3();
    this.changeof162_6();
    super.ngOnInit();
    this.determineAccess();
    
  }
  changeof162_1(val) {
    if (val === 'N') {
      this.group.controls['162.2'].disable();
    } else {
      this.group.controls['162.2'].enable();
    }
  }
  // get Is162_1(): boolean {
  //   return this.group.controls['162.1'].value === 'N';
  // }
  get Is162_3(): boolean {
    return this.group.controls['162.3'].value === 'Y';
  }
  changeof162_3() {
    if (this.group.controls['162.3'].value === 'Y') {

      this.group.controls['162.4'].enable();
      this.group.controls['162.5'].disable();
      this.group.controls['162.6'].disable();
      this.group.controls['162.7'].disable();
      this.group.controls['162.9'].disable();
    } else  if
     (this.group.controls['162.3'].value === 'N') {
      this.group.controls['162.4'].disable();
      this.group.controls['162.5'].enable();
      this.group.controls['162.6'].enable();
      this.group.controls['162.7'].enable();
      this.group.controls['162.9'].enable();



    }
  }
  get Is162_6(): boolean {
    return this.group.controls['162.6'].value === 'Y';
  }
  get Is162_6_N(): boolean {
    return this.group.controls['162.6'].value === 'N';
  }
  get Is162_7(): boolean {
    return this.group.controls['162.7'].value === 'Y';
  }
  get Is162_7_N(): boolean {
    return this.group.controls['162.7'].value === 'N';
  }
  get Is162_9_N(): boolean {
    return this.group.controls['162.9'].value === 'N';
  }
  changeof162_6() {
    if (this.group.controls['162.6'].value === 'Y') {

      this.group.controls['162.7'].disable();

      this.group.controls['162.8'].disable();

      this.group.controls['162.9'].disable();

    } else  if (this.group.controls['162.6'].value === 'N'){


      this.group.controls['162.7'].enable();

      this.group.controls['162.8'].enable();

      this.group.controls['162.9'].enable();

    }
  }

}
