import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-calcsheet-runCalc',
  templateUrl: './calcsheet-runCalc.component.html',
  styleUrls: ['./calcsheet-runCalc.component.css']
})
export class CalcsheetrunCalcComponent  extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();

  }




  
}