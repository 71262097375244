import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../shared-module/baseComponent.component';

@Component({
  selector: 'app-state-hire',
  templateUrl: './state-hire.component.html',
  styleUrls: ['./state-hire.component.css']
})

export class StateHireComponent extends BaseComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
  }
}