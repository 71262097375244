import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-reporting-systems',
  templateUrl: './reporting-systems.component.html',
  styleUrls: ['./reporting-systems.component.css']
})
export class ReportingSystemsComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

  get IsrepSys_1_N(): boolean {
    return this.group.controls['repSys.1'].value === 'N';
  }

}
