import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-child-support-three',
  templateUrl: './child-support-three.component.html',
  styleUrls: ['./child-support-three.component.css']
})
export class ChildSupportThreeComponent extends BaseComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
    this.sum();
  }

sum(){
  let counter = 0;
  const amt1 = this.group.controls["350.3.3a"].value;
  const amt2 = this.group.controls["350.3.3b"].value;
  const amt3 = this.group.controls["350.3.3c"].value;
  const amt4 = this.group.controls["350.3.3d"].value;
  const amt5 = this.group.controls["350.3.3e"].value;
 const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5);
 this.group.controls["350.3.5"].patchValue(res);

 amt1 != null ? counter++: counter;
 amt2 != null ? counter++: counter;
 amt3 != null ? counter++: counter;
 amt4 != null ? counter++: counter;
 amt5 != null ? counter++: counter;
const average = res/counter;
this.group.controls["350.3.6"].patchValue(average);
const amtX = this.group.controls["350.3.7"].value;
const equalTo =  (amtX)*average;
this.group.controls["350.3.8"].patchValue(equalTo);
const amtHh = this.group.controls["350.3.9"].value;
const equalToTwo =  (equalTo)/amtHh;
this.group.controls["350.3.10"].patchValue(equalToTwo);
const amtEligHh = this.group.controls["350.3.11"].value;
const equalToThree =  equalToTwo*amtEligHh;
this.group.controls["350.3.12"].patchValue(equalToThree);
}
}
