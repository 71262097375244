import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-con-drg-felon',
  templateUrl: './con-drg-felon.component.html',
  styleUrls: ['./con-drg-felon.component.css']
})
export class ConDrgFelonComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }
}
