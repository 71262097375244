import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-limited-time-participation',
  templateUrl: './limited-time-participation.component.html',
  styleUrls: ['./limited-time-participation.component.css']
})
export class LimitedTimeParticipationComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.changeof161_7(this.getControlValue('161.7'));
    this.changeof161_2(this.getControlValue('161.2'));
    this.changeof161_3(this.getControlValue('161.3'));
    this.changeof161_4(this.getControlValue('161.4'));
    this.changeof161_5(this.getControlValue('161.5'));
    this.changeof161_8(this.getControlValue('161.8'));
  }

  changeof161_7(val) {
    if (this.group.controls['161.7'].value === 'Y') {
      this.group.controls['161.7a'].enable();

    } else {
      this.group.controls['161.7a'].disable();

    }
  }

  changeof161_2(val) {
    if ( this.group.controls['161.2'].value === 'Y') {
      this.group.controls['161.2a'].disable();
      this.group.controls['161.3'].enable();
      this.group.controls['161.4'].enable();
      this.group.controls['161.4a'].enable();
      this.group.controls['161.5'].enable();
      this.group.controls['161.5a'].enable();
      this.group.controls['161.6'].enable();
      this.group.controls['161.6a'].enable();
      this.group.controls['161.7'].enable();
      this.group.controls['161.7a'].enable();
      this.group.controls['161.8'].enable();
      this.group.controls['161.8a'].enable();
      this.group.controls['161.8b'].enable();
      this.group.controls['161.9'].enable();
      this.group.controls['161.10'].enable();
      this.group.controls['161.11'].enable();

    } else {
      this.group.controls['161.2a'].enable();
      this.group.controls['161.3'].disable();
      this.group.controls['161.4'].disable();
      this.group.controls['161.4a'].disable();
      this.group.controls['161.5'].disable();
      this.group.controls['161.5a'].disable();
      this.group.controls['161.6'].disable();
      this.group.controls['161.6a'].disable();
      this.group.controls['161.7'].disable();
      this.group.controls['161.7a'].disable();
      this.group.controls['161.8'].disable();
      this.group.controls['161.8a'].disable();
      this.group.controls['161.8b'].disable();
      this.group.controls['161.9'].disable();
      this.group.controls['161.10'].disable();
      this.group.controls['161.11'].disable();

    }
  }
  changeof161_3(val) {
    if (this.group.controls['161.3'].value === 'N') {
      this.group.controls['161.4'].disable();
      this.group.controls['161.4a'].disable();
      this.group.controls['161.5'].disable();
      this.group.controls['161.5a'].disable();
      this.group.controls['161.6'].disable();
      this.group.controls['161.6a'].disable();
      this.group.controls['161.7'].disable();
      this.group.controls['161.7a'].disable();
      this.group.controls['161.8'].disable();
      this.group.controls['161.8a'].disable();
      this.group.controls['161.8b'].disable();
      this.group.controls['161.9'].disable();
      this.group.controls['161.10'].disable();
      this.group.controls['161.11'].disable();

    } else if (this.group.controls['161.3'].value === 'Y') {
      this.group.controls['161.4'].enable();
      this.group.controls['161.4a'].enable();
      this.group.controls['161.5'].enable();
      this.group.controls['161.5a'].enable();
      this.group.controls['161.6'].enable();
      this.group.controls['161.6a'].enable();
      this.group.controls['161.7'].enable();
      this.group.controls['161.7a'].enable();
      this.group.controls['161.8'].enable();
      this.group.controls['161.8a'].enable();
      this.group.controls['161.8b'].enable();
      this.group.controls['161.9'].enable();
      this.group.controls['161.10'].enable();
      this.group.controls['161.11'].enable();
    }
  }
  changeof161_4(val) {
    if (this.group.controls['161.4'].value === 'N') {
      this.group.controls['161.4a'].enable();
      this.group.controls['161.5'].disable();
      this.group.controls['161.5a'].disable();
      this.group.controls['161.6'].disable();
      this.group.controls['161.6a'].disable();
      this.group.controls['161.7'].disable();
      this.group.controls['161.7a'].disable();
      this.group.controls['161.8'].disable();
      this.group.controls['161.8a'].disable();
      this.group.controls['161.8b'].disable();
      this.group.controls['161.9'].disable();
      this.group.controls['161.10'].disable();
      this.group.controls['161.11'].disable();
    } else if (this.group.controls['161.4'].value === 'Y') {
      this.group.controls['161.4a'].disable();
      this.group.controls['161.5'].enable();
      this.group.controls['161.5a'].enable();
      this.group.controls['161.6'].enable();
      this.group.controls['161.6a'].enable();
      this.group.controls['161.7'].enable();
      this.group.controls['161.7a'].enable();
      this.group.controls['161.8'].enable();
      this.group.controls['161.8a'].enable();
      this.group.controls['161.8b'].enable();
      this.group.controls['161.9'].enable();
      this.group.controls['161.10'].enable();
      this.group.controls['161.11'].enable();
    }
  }
  changeof161_5(val) {
    if (this.group.controls['161.5'].value === 'N') {
      this.group.controls['161.5a'].disable();
      this.group.controls['161.6'].disable();
      this.group.controls['161.6a'].disable();
    } else if (this.group.controls['161.5'].value === 'Y') {
      this.group.controls['161.5a'].enable();
      this.group.controls['161.6'].enable();
      this.group.controls['161.6a'].enable();
    }
  }
  get Is161_6_N(): boolean {
    return this.group.controls['161.6'].value === 'N';
  }
  get Is161_8(): boolean {
    return this.group.controls['161.8'].value === 'Y';
  }
  get Is161_8_N(): boolean {
    return this.group.controls['161.8'].value === 'N';
  }

  changeof161_8(val) {
    if (this.group.controls['161.8'].value === 'Y') {
      this.group.controls['161.8b'].disable();
      this.group.controls['161.9'].enable();    
    } else if (this.group.controls['161.8'].value === 'N') {
      this.group.controls['161.8b'].enable();
      this.group.controls['161.9'].disable();
    }
  }

}
