import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-selfemployed-sr-worksheet2',
  templateUrl: './selfemployed-sr-worksheet2.component.html',
  styleUrls: ['./selfemployed-sr-worksheet2.component.css']
})
export class SelfemployedSrWorksheet2Component extends BaseComponent implements OnInit {
isNotEditable : boolean =false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    const ctrl:any = (this.group.get('FormArray'));
    // this.calculate(ctrl.controls[0])
    this.gross(ctrl.controls[0])
    this.grossTwo(ctrl.controls[0])
    this.grossThree(ctrl.controls[0]);
  }

  // calculate(arrayControl){
  //   const total1 = (+arrayControl.controls['312.7.2a0'].value) + (+arrayControl.controls['312.7.2a1'].value) +  (+arrayControl.controls['312.7.2a2'].value) + (+arrayControl.controls['312.7.2a3'].value) + (+arrayControl.controls['312.7.2a4'].value);
  //   const total2 = (+arrayControl.controls['312.7.2b0'].value) + (+arrayControl.controls['312.7.2b1'].value) +  (+arrayControl.controls['312.7.2b2'].value) + (+arrayControl.controls['312.7.2b3'].value) + (+arrayControl.controls['312.7.2b4'].value);
  //   const total3 = (+arrayControl.controls['312.7.2c0'].value) + (+arrayControl.controls['312.7.2c1'].value) +  (+arrayControl.controls['312.7.2c2'].value) + (+arrayControl.controls['312.7.2c3'].value) + (+arrayControl.controls['312.7.2c4'].value);

  //   arrayControl.controls['312.7.10a'].patchValue(total1);
  //   arrayControl.controls['312.7.10b'].patchValue(total2);
  //   arrayControl.controls['312.7.10c'].patchValue(total3);

  // }

  gross(arrayControl){
    let counter = 0;
    const amt1 = arrayControl.controls["312.7.2a0"].value;
    const amt2 = arrayControl.controls["312.7.2a1"].value;
    const amt3 = arrayControl.controls["312.7.2a2"].value;
    const amt4 = arrayControl.controls["312.7.2a3"].value;
    const amt5 = arrayControl.controls["312.7.2a4"].value;
    amt1 != null ? counter++: counter;
    amt2 != null ? counter++: counter;
    amt3 != null ? counter++: counter;
    amt4 != null ? counter++: counter;
    amt5 != null ? counter++: counter;
   const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5);
    arrayControl.controls["312.7.10a"].patchValue(res);
    const average =  (res)/counter;
    arrayControl.controls["312.7.11a"].patchValue(average);
    const amtX = arrayControl.controls["312.7.7a"].value;
    const equalTo =  (amtX)*average;
    arrayControl.controls["312.7.13a"].patchValue(equalTo);
    const amtHh = arrayControl.controls["312.7.8a"].value;
    const equalToTwo =  (equalTo)/amtHh;
    arrayControl.controls["312.7.14a"].patchValue(equalToTwo);
    const amtEligHh = arrayControl.controls["312.7.9a"].value;
    const equalToThree =  equalToTwo*amtEligHh;
    arrayControl.controls["312.7.15a"].patchValue(equalToThree);
  }
  grossTwo(arrayControl){
    let counter = 0;
    const amt1 = arrayControl.controls["312.7.2b0"].value;
    const amt2 = arrayControl.controls["312.7.2b1"].value;
    const amt3 = arrayControl.controls["312.7.2b2"].value;
    const amt4 = arrayControl.controls["312.7.2b3"].value;
    const amt5 = arrayControl.controls["312.7.2b4"].value;
    amt1 != null ? counter++: counter;
    amt2 != null ? counter++: counter;
    amt3 != null ? counter++: counter;
    amt4 != null ? counter++: counter;
    amt5 != null ? counter++: counter;
   const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5);
   arrayControl.controls["312.7.10b"].patchValue(res);
    const average =  (res)/counter;
    arrayControl.controls["312.7.11b"].patchValue(average);
    const amtX = arrayControl.controls["312.7.7b"].value;
    const equalTo =  (amtX)*average;
    arrayControl.controls["312.7.13b"].patchValue(equalTo);
    const amtHh = arrayControl.controls["312.7.8b"].value;
    const equalToTwo =  (equalTo)/amtHh;
    arrayControl.controls["312.7.14b"].patchValue(equalToTwo);
    const amtEligHh = arrayControl.controls["312.7.9b"].value;
    const equalToThree =  equalToTwo*amtEligHh;
    arrayControl.controls["312.7.15b"].patchValue(equalToThree);
    
  }
  grossThree(arrayControl){
    let counter = 0;
    const amt1 = arrayControl.controls["312.7.2c0"].value;
    const amt2 = arrayControl.controls["312.7.2c1"].value;
    const amt3 = arrayControl.controls["312.7.2c2"].value;
    const amt4 = arrayControl.controls["312.7.2c3"].value;
    const amt5 = arrayControl.controls["312.7.2c4"].value;
    amt1 != null ? counter++: counter;
    amt2 != null ? counter++: counter;
    amt3 != null ? counter++: counter;
    amt4 != null ? counter++: counter;
    amt5 != null ? counter++: counter;
   const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5);
   arrayControl.controls["312.7.10c"].patchValue(res);
   const average =  (res)/counter;
   arrayControl.controls["312.7.11c"].patchValue(average);
   const amtX = arrayControl.controls["312.7.7c"].value;
   const equalTo =  (amtX)*average;
   arrayControl.controls["312.7.13c"].patchValue(equalTo);
   const amtHh = arrayControl.controls["312.7.8c"].value;
   const equalToTwo =  (equalTo)/amtHh;
   arrayControl.controls["312.7.14c"].patchValue(equalToTwo);
   const amtEligHh = arrayControl.controls["312.7.9c"].value;
    const equalToThree =  equalToTwo*amtEligHh;
    arrayControl.controls["312.7.15c"].patchValue(equalToThree);
  }
}
