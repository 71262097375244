import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ApiManager } from 'src/app/common/api-manager.service';
import { ConstantsService } from '../../common/constants.service';
import { NavigationService } from '../../common/navigation.service';

export interface PeriodicElement {
  rvwNum: number;
  caseId: number;
  irn: number;
  // mdmId: number;
  pgmCd: string;
  pgmType: string;
  // districtOffice: number;
  ldssNm:string;
  // hohNm:string;
  // aplctnDt:string;
  pgmTypeDesc: string;
  // pgmStus:string;
  bnftMnth:string;
  bnftGrp:string;
  csStsCd:string;
  // rvwSts:string;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-snap-sample',
  templateUrl: './snap-sample.component.html',
  styleUrls: ['./snap-sample.component.css']
})

export class SnapSampleComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  snapSampleForm: FormGroup;
  isNotEditable: boolean = false;

  buildForm() {
    this.snapSampleForm = this.fb.group({ 
     })
  };

  displayedColumns: string[] = [
    // 'select',
    'rvwNum', 'caseId', 'irn'
  // ,'mdmId'
  // , 'pgmCd', 'ldssNm'
  // ,'hohNm'
  // , 'aplctnDt'
  // ,'pgmStus'
  ,'pgmTypeDesc'
  ,'bnftMnth'
  // ,'bnftGrp','csStsCd'
  // ,'rvwSts'
];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  dataSourceAll = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  monthYear : string;

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.rvwNum + 1}`;
  }

  constructor(
    private readonly router: Router,
    private fb: FormBuilder,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    public navigationService: NavigationService,
    )
  {
    this.dataSource = new MatTableDataSource();
    this.dataSourceAll = new MatTableDataSource();
    this.buildForm();
   }
 
  ngOnInit() {
    this.getSelectSample();
    let date:Date = new Date();
    this.monthYear= date.getFullYear()+""+(date.getMonth()+1<10?"0"+(date.getMonth()+1):date.getMonth()+1);
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

      if ((ref.accessMap[''] != null && ref.accessMap[''].accessMode != 'rw')) {
        this.isNotEditable = true;
      }
  }

  getSelectSample() {
    const queryParams: any[] = [];
    let appCd: string = '';
    appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    queryParams.push(appCd);
    this.apiManager.fetchData('getSelectSample', undefined, ApiManager.GET,
        undefined, undefined, undefined, queryParams)
        .subscribe(res => {
          if (res) {
            console.log(res);
            //add additional rvw month formated column to filter 
            res.map(item => {
              if(item && item.bnftMnth) {
                item['dispRvwMo'] = item.bnftMnth.substring(4) + '/' + item.bnftMnth.substring(0,4);
              }
          })
    
            this.dataSourceAll = new MatTableDataSource(res);
            this.dataSourceAll.data = res;
            this.dataSource.data = res.filter(item => item.bnftMnth === this.monthYear);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        });
  }
 
  submit() {
    const queryParams: any[] = [];
    let appCd: string = '';
    appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    queryParams.push(appCd);

    const selectedCaseIds: any[] = [];

    this.selection.select(this.dataSource.data[0]);
    this.selection.selected.forEach(row => {
      let id: number = row.caseId;
      let pgmTypeCd: string = row.pgmType;
      selectedCaseIds.push({caseId:id,pgmType:pgmTypeCd});
      });

    this.apiManager.fetchData('saveSelectSample', undefined, ApiManager.POST, selectedCaseIds, undefined, undefined, queryParams)
    .subscribe(
      res => {
      console.log("----Selected sample cases-----");
      this.selection.clear();
      this.dataSource.sort = this.sort;
    /*   this.casedataSourceDialog.data = res;
     this.dataSource.sort = this.sort;
      this.casedataSourceDialog.data = this.casedataSourceDialog.data.sort(); */
      this.ngOnInit();
    });
   
  }

  getDistinctMonth(){
    let distinctMonth: any = [];
      this.dataSourceAll.data.map(filterItem => {
        if (filterItem.bnftMnth &&
          distinctMonth.indexOf(filterItem.bnftMnth) === -1 ) {
            distinctMonth.push(filterItem.bnftMnth);
        }  
      });
    distinctMonth = distinctMonth.sort((a, b) => (+a < +b) ? 1 : -1);
    return distinctMonth;
  }



  getDistinctLDSS(){
    let distinctLDSS: any = [];
    this.dataSourceAll.data.map(filterItem => {
      if (filterItem.ldssNm &&
        distinctLDSS.indexOf(filterItem.ldssNm) === -1) {
        distinctLDSS.push(filterItem.ldssNm);
      }
    });
    return distinctLDSS;
  }

  filterData(type, value) {
    switch (type) {
      case 'benMonth':
        this.dataSource.data = this.dataSourceAll.data.filter(item => item.bnftMnth === value);
        break;
      case 'ldss':
        this.dataSource.data = this.dataSourceAll.data.filter(item => item.ldssNm === value);
        break;
        case 'year':
          this.dataSource.data = this.dataSourceAll.data.filter(item => item.bnftMnth.substring(0,4) === value.substring(0,4));
          break;
    }
  }

  handleCurrentMonth(val) {
    if (val.tab.textLabel==="Current") {
     this.dataSource.data =this.dataSourceAll.data.filter(item => item.bnftMnth === this.monthYear);
     this.dataSource.sort = this.sort;
    } 
    else if (val.tab.textLabel==="History") {
     this.dataSource.data = this.dataSourceAll.data;
     this.dataSource.sort = this.sort;
   }
 }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.sort = this.sort;
  }
  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }

  next() {
    this.navigationService.navigatePage(this.router.url, 'next');
  }
  
  back() {
    this.navigationService.navigatePage(this.router.url, 'back');
  } 
}