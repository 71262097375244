import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiManager } from 'src/app/common/api-manager.service';
import { ConstantsService } from '../../../../common/constants.service';
import { NavigationService } from '../../../../common/navigation.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DeleteDialogComponent } from 'src/app/shared-module/delete-dialog/delete-dialog.component';
import { FindRespComponent } from 'src/app/shared-module/find-resp/find-resp.component';


export interface RvwSts {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-ldss-review-case-list',
  templateUrl: './ldss-review-case-list.component.html',
  styleUrls: ['./ldss-review-case-list.component.css']
})
export class LdssReviewCaseListComponent implements OnInit {
  displayedColumns: string[] = [
    'ldssDoName',
    'rvwNum',
    'rvwSts',
    'caseId',
    'csWorkrNm',
    'findResp',
   'actions'
  ];
  
  dataSource;
  isNotEditable: boolean = false;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  
  
  rvwStses: RvwSts[] =
  [
    { value: "PE", viewValue: "In Progress" },
    { value: "ST", viewValue: "Submitted" },
    { value: "CP", viewValue: "Completed" },
    { value: "RR", viewValue: "Re-Review" }
  ];

  constructor(
    public constantsService: ConstantsService,
    // private apiManager: ApiManager,
    // public _utilService: AppUtilService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private apiManager: ApiManager,
    public navigationService: NavigationService,
    // private shareService: DataShareService,
    // private alertService: AlertService,
  ) { 
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.getPageData();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.determineAccess();
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/bpe/fsme/ldss-review-case-list'] != null && ref.accessMap['/bpe/fsme/ldss-review-case-list'].accessMode != 'rw')) {
        this.isNotEditable = true;
    }
  }

  getPageData() {
    const caseId: any = '';
    const queryParams: any[] = [];
    const appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    queryParams.push(appCd);

    queryParams.push(this.constantsService.ReviewInfo['ldssDoCd']);
    
    this.apiManager.fetchData('getLdssReviewList', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
      .subscribe(
        res => {
          this.dataSource.data = res;
        }
      );
  }

  getRvwSts(cd) {
    let typeValue;
    this.rvwStses.filter(item => item.value == cd).map(val => {
      typeValue = val.viewValue;
    })
    return typeValue;
  }

  openFindRespDialog(val: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = val;


    const dialogRef = this.dialog.open(FindRespComponent , dialogConfig);
    dialogRef.updateSize('75%', '85%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log('afterClose', val);
      }

    });
  }

  next() {
     
      this.navigationService.navigatePage(this.router.url, 'next');
    }
  

  back() {
    this.navigationService.navigatePage(this.router.url, 'back');
  }
  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }

}
