import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-employment-training',
  templateUrl: './employment-training.component.html',
  styleUrls: ['./employment-training.component.css']
})

export class EmploymentTrainingComponent extends BaseComponent implements OnInit {
isNotEditable: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  handleQ160_8(val){
    if(val === 'OT'){
      this.group.controls['160.8a'].enable();
    }else{
      this.group.controls['160.8a'].disable();
    }
}
}