import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-recertification-codes',
  templateUrl: './recertification-codes.component.html',
  styleUrls: ['./recertification-codes.component.css']
})
export class RecertificationCodesComponent extends BaseComponent implements OnInit {
  
  ngOnInit() {
    super.ngOnInit();
  }



}