import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-cbo-interview',
  templateUrl: './cbo-interview.component.html',
  styleUrls: ['./cbo-interview.component.css']
})
export class CboInterviewComponent extends BaseComponent implements OnInit {
  currentPage: number = 0;
  ngOnInit() {
    super.ngOnInit();
  }

}
