import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormBuilder, FormGroup,Validators, FormControl, FormArray,ControlValueAccessor } from "@angular/forms";
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ApiManager } from 'src/app/common/api-manager.service';
import { NavigationService } from 'src/app/common/navigation.service';
import { ConstantsService } from 'src/app/common/constants.service';
import { ViewReviewHistoryComponent } from '../view-review-history/view-review-history.component';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
  reviewMonth: string;
  stratum: number;
  statusCode: number;
  caseid: number;
  irn:number;
  hohName: string;
  assignee: string;
  rvwNum:number;
  Previous: string;
}

export interface Region {
  value: string;
  viewValue: string;
}

export interface DistrictOffice {
  value: string;
  viewValue: string;
}

export interface Reviewer {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-fsme-supervisory-review',
  templateUrl: './fsme-supervisory-review.component.html',
  styleUrls: ['./fsme-supervisory-review.component.css']
})

export class FsmeSupervisoryReviewComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  regions: Region[] = [
    { value: "region1", viewValue: "Region1" },
    { value: "region2", viewValue: "Region2" },
    { value: "tacos-3", viewValue: "Region3" }
  ];

  DistrictOffices: DistrictOffice[] = [
    { value: "districtOffice1", viewValue: "DistrictOffice1" },
    { value: "districtOffice2", viewValue: "DistrictOffice2" },
    { value: "districtOffice3", viewValue: "DistrictOffice3" }
  ];

  Reviewers: Reviewer[] = [
    { value: "1", viewValue: "Reviewer" },
    { value: "2", viewValue: "Reviewer" },
    { value: "3", viewValue: "Reviewer" }
  ];

  caseAssignForm: FormGroup;
  displayedColumns = ["ldss", "review", "reviewMonth", "caseId","irn", "pgmTypeDesc", "rewviewStatus","reviewer"
  // ,"select"
  ,"actions"
];
  casedataSourceDialogAll = new MatTableDataSource<any>();
  casedataSourceDialog = new MatTableDataSource();
  isNotEditable: boolean = false;
  monthYear : string;

  constructor( 
    private readonly router: Router,
    private apiManager: ApiManager,
    private fb: FormBuilder,
    public navigationService: NavigationService,
    public constantsService: ConstantsService,
    public dialog: MatDialog,
  ) { }
  
  ngAfterViewInit() {
    this.casedataSourceDialog.paginator = this.paginator;
    this.casedataSourceDialog.sort = this.sort;
  }

  ngOnInit() {
    const queryParams: any[] = [];
    queryParams.push('Assigned');
    // queryParams.push('BPE-FSME');
    let appCd: string = '';
    appCd = this.constantsService.getAppCd(this.navigationService.appCd);

    queryParams.push(appCd);

    queryParams.push('A');
    let date:Date = new Date();
    this.monthYear= date.getFullYear()+""+(date.getMonth()+1<10?"0"+(date.getMonth()+1):date.getMonth()+1);
    
    this.apiManager.fetchData('bpeAssigneUrl', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
    .subscribe(
      result => {
      //  console.log('result....',result)
      // this.dataSourceAll = new MatTableDataSource(res);
      // this.dataSourceAll.data = res;
       this.casedataSourceDialogAll = new MatTableDataSource(result);
       this.casedataSourceDialog.data = result;
       this.casedataSourceDialog.data = this.casedataSourceDialog.data.sort();
     
      });
    this.buildForm();
    this.determineAccess();
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

      if ((ref.accessMap[''] != null && ref.accessMap[''].accessMode != 'rw')) {
        this.isNotEditable = true;
      }
  }

  buildForm() {
    this.caseAssignForm = this.fb.group({
      region: ["", Validators.required],
      DistrictOffice: ["", Validators.required],
      Analyst: ["", Validators.required],
      Reviewer:["",Validators.required]
    });
  }
   
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() :
    this.casedataSourceDialog.data.forEach(row => this.selection.select(row));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.casedataSourceDialog.data.length;
    return numSelected === numRows;
  }

  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.rvwNum + 1}`;
  }

  review(rvwInfo) {
    this.constantsService.ReviewInfo = rvwInfo;
    if (this.constantsService.ReviewInfo) {
      this.refreshNav();
    }

  }

  refreshNav() {
    this.navigationService.getPrograms(this.navigationService.appCd).then(v => {
      this.afterNavChange();
    });
  }

  
  afterNavChange() {
    // Do Nothing
    // TODO : if any change after nav change while adding new program
    const navURL = this.constantsService.navigateByType();
    if (navURL) {
      this.router.navigateByUrl(navURL); 
    } else {
      this.navigationService.navigatePage(this.router.url, 'next');
    }
    
    
  }

  selection = new SelectionModel(true, []);
  @ViewChild('ref', { static: false }) ref: any;

  checkPageOnly() {
    let i = this.casedataSourceDialog.paginator.pageIndex * this.casedataSourceDialog.paginator.pageSize;
    let end = (this.casedataSourceDialog.paginator.pageIndex + 1) * this.casedataSourceDialog.paginator.pageSize;
    for (i; i < end; i++) {
      this.selection.select(this.casedataSourceDialog.data[i]);
    }
    console.log('selected...',this.selection.selected)
  }

  assign() {
    console.log('selected....', this.selection.selected);
    this.selection.selected.forEach(row => {
      let id: string = row.rvwNum;
      let pgmType = row.rvwType;
      const queryParams: any[] = [];
      queryParams.push(id);
      queryParams.push('CL');
      const body = {
        'rvwDate':  new Date()
      };
   
      this.apiManager.fetchData('saveReviewStatus', undefined, ApiManager.POST, body, undefined, undefined, queryParams)
        .subscribe(
          res => {
            console.log('----Assigned-----');
            const index = this.casedataSourceDialog.data.indexOf(row);
            this.casedataSourceDialog.data.splice(index, 1);
            this.casedataSourceDialog.data = this.casedataSourceDialog.data.sort();
          });
    });  
  }
 
  // getDistinctLDSS(){
  //   let distinctLDSS: any = [];
  //   this.casedataSourceDialogAll.data.map(filterItem => {
  //     if (filterItem.ldssNm &&
  //       distinctLDSS.indexOf(filterItem.ldssNm) === -1) {
  //       distinctLDSS.push(filterItem.ldssNm);
  //     }
  //   });
  //   return distinctLDSS;
  // }

  getDistinctReviwer(){
    let distinctReviwer: any = [];
    this.casedataSourceDialogAll.data.map(filterItem => {
      if (filterItem.assignee &&
        distinctReviwer.indexOf(filterItem.assignee) === -1) {
          distinctReviwer.push(filterItem.assignee);
      }
    });
    return distinctReviwer;
  }

  // filterData(type,value) {
  //   switch(type) {
  //     case 'ldss':
  //       this.casedataSourceDialog.data = this.casedataSourceDialogAll.data.filter(item => item.ldssNm === value);
  //       break;
  //     case 'reviewer':
  //       this.casedataSourceDialog.data = this.casedataSourceDialogAll.data.filter(item => item.analystId === value);
  //       break;
  //   }
  // }

  getDistinctMonth(){
    let distinctMonth: any = [];
      this.casedataSourceDialogAll.data.map(filterItem => {
        if (filterItem.rvwMo &&
          distinctMonth.indexOf(filterItem.rvwMo) === -1 ) {
            distinctMonth.push(filterItem.rvwMo);
        }  
      });
    return distinctMonth;
  }


  getDistinctLDSS(){
    let distinctLDSS: any = [];
    this.casedataSourceDialogAll.data.map(filterItem => {
      if (filterItem.ldssNm &&
        distinctLDSS.indexOf(filterItem.ldssNm) === -1) {
        distinctLDSS.push(filterItem.ldssNm);
      }
    });
    return distinctLDSS;
  }

  filterData(type, value) {
    switch (type) {
      case 'benMonth':
        this.casedataSourceDialog.data = this.casedataSourceDialogAll.data.filter(item => item.rvwMo === value);
        break;
      case 'ldss':
        this.casedataSourceDialog.data = this.casedataSourceDialogAll.data.filter(item => item.ldssNm === value);
        break;
        case 'year':
          this.casedataSourceDialog.data = this.casedataSourceDialogAll.data.filter(item => item.rvwMo.substring(0,4) === value.substring(0,4));
          break;
    }
  }
  openReviewHistory(rvwInfo) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    if (rvwInfo) {
      dialogConfig.data = {
        rvwId: rvwInfo.rvwNum,
      };
    }

    const dialogRef = this.dialog.open(ViewReviewHistoryComponent , dialogConfig);
    dialogRef.updateSize('60%', '42%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log('afterClose', val);
      }
      this.dialog.closeAll();
    });
  }
//   handleCurrentMonth(val) {
//     if (val.tab.textLabel==="Current") {
//      this.casedataSourceDialog.data =this.casedataSourceDialogAll.data.filter(item => item.bnftMnth === this.monthYear);
//     } 
//     else if (val.tab.textLabel==="History") {
//      this.casedataSourceDialog.data = this.casedataSourceDialogAll.data;
//    }
//  }

 handleCurrentMonth(val) {
  if (val.tab.textLabel==="Current") {
   this.casedataSourceDialog.data =this.casedataSourceDialogAll.data.filter(item => item.rvwMo === this.monthYear);
  } 
  else if (val.tab.textLabel==="Previous") {
   this.casedataSourceDialog.data = this.casedataSourceDialogAll.data;
 }
}
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.casedataSourceDialog.filter = filterValue.trim().toLowerCase();
  }
    
  next() {
    this.navigationService.navigatePage(this.router.url, 'next');
  }
  
  back() {
    this.navigationService.navigatePage(this.router.url, 'back');
  }
  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }
}