import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-property-over-asset-limit',
  templateUrl: './property-over-asset-limit.component.html',
  styleUrls: ['./property-over-asset-limit.component.css']
})
export class PropertyOverAssetLimitComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
