import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-previously-received-waag',
  templateUrl: './previously-received-waag.component.html',
  styleUrls: ['./previously-received-waag.component.css']
})
export class PreviouslyReceivedWAagComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
