import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-failed-to-comply-upfront-job-search',
  templateUrl: './failed-to-comply-upfront-job-search.component.html',
  styleUrls: ['./failed-to-comply-upfront-job-search.component.css']
})
export class FailedToComplyUpfrontJobSearchComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
