import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-voluntary-quit',
  templateUrl: './voluntary-quit.component.html',
  styleUrls: ['./voluntary-quit.component.css']
})

export class VoluntaryQuitComponent extends BaseComponent implements OnInit {
isNotEditable: boolean =false
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  
  get Is160_1():boolean{
      return this.group.controls['163.1'].value === 'Y';
  }
  changeQ163_1(val){
    if(val === "Y"){
      this.setControlValue('163.2','');
      this.group.controls['163.2'].disable();
    }else if(val === "N"){
      this.group.controls['163.2'].enable();
    }

  }

  // determineAccess() {
  //   const ref = this.constantsService.getSecurityObject();

  //   if ((ref.accessMap['/qualityControl/questionary-163?type=163'] != null && ref.accessMap['/qualityControl/questionary-163?type=163'].accessMode != 'rw')) {
  //       this.isNotEditable = true;
  //   }
  // }
  handleQ163_8(val){
    if(val === '9'){
      this.group.controls['163.8a'].enable();
    }else{
      this.group.controls['163.8a'].disable();
    }
}
}