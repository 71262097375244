import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-qc53-health-verification-form',
  templateUrl: './qc53-health-verification-form.component.html',
  styleUrls: ['./qc53-health-verification-form.component.css']
})
export class QC53HealthVerificationFormComponent extends BaseComponent implements OnInit {

 

  ngOnInit() {
    super.ngOnInit();
  }
  handleDob(individualName){

    let householdMembers = this.constantsService.householdMembers;
    householdMembers.map(indivId =>{
    if(this.utilService.getFullName(indivId)===individualName){
      this.setControlValue('500114.7',indivId.dob);
      this.setControlValue('500114.8',indivId.address1+indivId.address2);
  
    }
  });
  }

 
}


