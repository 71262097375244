import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-notepad',
  templateUrl: './notepad.component.html',
  styleUrls: ['./notepad.component.css']
})
export class NotepadComponent  extends BaseComponent implements OnInit {

  @Input() group: FormGroup;
  currentPage: number = 0;

  ngOnInit() {
    super.ngOnInit();
    
  }

}