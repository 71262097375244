import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-abawd-actives',
  templateUrl: './abawd-actives.component.html',
  styleUrls: ['./abawd-actives.component.css']
})

export class AbawdActivesComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/bpe/fsme/questionary-100012?type=100012'] != null && ref.accessMap['/bpe/fsme/questionary-100012?type=100012'].accessMode != 'rw')) {
        this.isNotEditable = true;
    }
  }

}