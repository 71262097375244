import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-other-unearned-income',
  templateUrl: './other-unearned-income.component.html',
  styleUrls: ['./other-unearned-income.component.css']
})
export class OtherUnearnedIncomeComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.handle346_2(this.getControlValue('346.2'));
  }
  get Is346_2():boolean{
    return this.group.controls['346.2'].value === '8';
  }

  changeQf346_1(val){
    if ( val === 'N') {
      this.group.controls['346.2'].disable();
      this.group.controls['346.3a'].disable();
      this.group.controls['346.3b'].disable();
      this.group.controls['346.3c'].disable();
      this.group.controls['346.3d'].disable();
      this.group.controls['346.3e'].disable();
      this.group.controls['346.4'].disable();
      this.group.controls['346.5'].disable();
      this.group.controls['346.6'].disable();
      this.group.controls['346.7'].disable();
      this.group.controls['346.8'].disable();
    }else {
      this.group.controls['346.2'].enable();
      this.group.controls['346.3a'].enable();
      this.group.controls['346.3b'].enable();
      this.group.controls['346.3c'].enable();
      this.group.controls['346.3d'].enable();
      this.group.controls['346.3e'].enable();
      this.group.controls['346.4'].enable();
      this.group.controls['346.5'].enable();
      this.group.controls['346.6'].enable();
      this.group.controls['346.7'].enable();
      this.group.controls['346.8'].disable();
    }
  }
  handle346_2(val){
    if(val === '8'){
      this.group.controls['346.3a'].disable();
      this.group.controls['346.3b'].disable();
      this.group.controls['346.3c'].disable();
      this.group.controls['346.3d'].disable();
      this.group.controls['346.3e'].disable();
      this.group.controls['346.4'].disable();
      this.group.controls['346.5'].disable();
      this.group.controls['346.6'].disable();
      this.group.controls['346.7'].disable();
      this.group.controls['346.8'].disable();    
      this.group.controls['346.11a'].enable();
      this.group.controls['346.11b'].enable();
      this.group.controls['346.11c'].enable();
      this.group.controls['346.11d'].enable();
      this.group.controls['346.11e'].enable();
      this.group.controls['346.11f'].enable();
    }else{
      this.group.controls['346.3a'].enable();
      this.group.controls['346.3b'].enable();
      this.group.controls['346.3c'].enable();
      this.group.controls['346.3d'].enable();
      this.group.controls['346.3e'].enable();
      this.group.controls['346.4'].enable();
      this.group.controls['346.5'].enable();
      this.group.controls['346.6'].enable();
      this.group.controls['346.7'].enable();
      this.group.controls['346.8'].enable();
      this.group.controls['346.11a'].disable();
      this.group.controls['346.11b'].disable();
      this.group.controls['346.11c'].disable();
      this.group.controls['346.11d'].disable();
      this.group.controls['346.11e'].disable();
      this.group.controls['346.11f'].disable();
    }
  }

}