import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Subject ,  Observable } from 'rxjs';
import { ApiManager } from './api-manager.service';

@Injectable()
export class StorageService {
  private locaCase: any;
  public usedTimeTravelOnce: boolean = false;
  private currentSysDate = null;
  public timeSubject = new Subject<any>();
  public allowTimeTravel: boolean = false;

  constructor(private storage: LocalStorageService,
    private session: SessionStorageService) { }

  setTimer(time) {
    this.session.store('timer', time);
  }
  getTimeSubject() {
    return this.timeSubject.asObservable();
  }
  getTimer() {
    return this.session.retrieve('timer');
  }
  getUniqueId() {
    return this.session.retrieve('unique');
  }
  destroyAll() {
    this.session.clear();
  }

  setConsumerControlForView(id){
    this.storage.store('consumerControlForView', id);
  }

  getConsumerControlForView(){
    return this.storage.retrieve('consumerControlForView');

  }

  setCaseIdForViewPagePrint(id){
    this.storage.store('caseIdForViewPagePrint', id);
  }

  getCaseIdForViewPagePrint(){
    return this.storage.retrieve('caseIdForViewPagePrint');

  }

  setAccessSecurity(sec) {
    this.session.store('security', sec);
  }
  getAccessSecurity() {
    return this.session.retrieve('security');
  }
  getTimerExtend() {
    return this.session.retrieve('timerModalExtend');
  }
  setTimerExtend(val) {
    return this.session.store('timerModalExtend', val);
  }

  setTimeTravelDate(date: Date) {
    this.session.store('timeTravel', date);
    this.allowTimeTravel = true;
    this.usedTimeTravelOnce = true;
    this.timeSubject.next(date);
  }

  storageIssueWorkaround(date: Date) {
    // internally the sessionstorage gets cjhanged to new date(), So I had to store the previous value to
    // voluntarily changed the sessionStorage date
    this.currentSysDate = (<any>Object).values(Object.assign({}, date.toDateString())).join('');
  }

  getTimeTravelDate() {
    if (this.allowTimeTravel) {
      let sessionDate = new Date(this.session.retrieve('timeTravel'));
      // console.log("Session date when getting is ", sessionDate, " previous Sys date is ", this.currentSysDate);
      if (sessionDate.toDateString() === this.currentSysDate) {
        return new Date(this.session.retrieve('timeTravel'));
      } else {
        this.setTimeTravelDate(new Date(this.currentSysDate));
        return new Date(this.currentSysDate);
      }
    }
  }

  setControlId(id) {
    this.session.store('controlId', id);
  }

  getControlId() {
    return this.session.retrieve('controlId');
  }

  getRoute() {
    return this.session.retrieve('route');
  }

  setRoute(route) {
    return this.session.store('route', route);
  }

  setCaseHome(val: any) {
    this.session.store('caseHome', val);
  }

  getCaseHome() {
    return this.session.retrieve('caseHome');
  }

  setCurrentModule(m) {
    this.session.store('currentModule', m);
  }

  getCurrentModule() {
    return this.session.retrieve('currentModule');
  }

  getModalOpen() {
    return this.session.retrieve('timerModal');
  }

  setModalOpen(val) {
    return this.session.store('timerModal', val);
  }

  setWorkItemSearch(val){
    this.session.store('workItemSearch', val);
  }

  getWorkItemSearch(){
    return this.session.retrieve('workItemSearch');
  }

  clearWorkItemSearch(){
    this.session.clear('workItemSearch');
  }

}
