import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-did-not-tryto-obtain-gov-bfts',
  templateUrl: './did-not-tryto-obtain-gov-bfts.component.html',
  styleUrls: ['./did-not-tryto-obtain-gov-bfts.component.css']
})
export class DidNotTrytoObtainGovBftsComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
