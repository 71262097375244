import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiManager } from 'src/app/common/api-manager.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';
interface IncomeLevel {
  determinationFactor: string;
}

@Component({
  selector: 'app-calcsheet-srmonth',
  templateUrl: './calcsheet-srmonth.component.html',
  styleUrls: ['./calcsheet-srmonth.component.css']
})

export class CalcsheetSRMonthComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;
  srMonthlist = [];
  incomeHhSize = [];
  @Input() group: FormGroup;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  displayedColumns: any = [
    'hhSize',
    'monthlyPercent',
    'monthlydoublePercent'];
  dataSource = new MatTableDataSource();
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.getIncomeLevels();

    if (this.constantsService.ReviewInfo.rvwType === 'FSPPositive' || this.constantsService.ReviewInfo.rvwType === 'ESAPPositive') {
      this.srMonthlist = this.constantsService.getLookUpByName(this.getLookupNameByQCd('883.1')).filter(Item => Item.code !== '7');
      this.incomeHhSize = [];
    }
    else if (this.constantsService.ReviewInfo.rvwType === 'FSPNegative') {
      this.srMonthlist = this.constantsService.getLookUpByName(this.getLookupNameByQCd('883.1')).filter(Item => Item.code === '7');
    }
  }



// Inside your component or service
getIncomeLevels() {
  const queryParams: any[] = [];

  if (this.constantsService.ReviewInfo && this.constantsService.ReviewInfo.rvwNum) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
  }

  this.apiManager.fetchData('getIncomeValues', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
      .subscribe(res => {
          if (res) {
              console.log(res);

              const ldss_hh_size = this.getControlValue('883.1b').toString();

              // Use type assertion to cast the response to the expected type (IncomeLevel[])
              const dataArray: IncomeLevel[] = res as IncomeLevel[];

              // Filter the data based on 'determinationFactor'
              const filteredData = dataArray.filter(item => item.determinationFactor === ldss_hh_size);

              // Assign filtered data to MatTableDataSource
              this.dataSource = new MatTableDataSource(filteredData);

              // Set up paginator and sort for the filtered data
              this.dataSource.paginator = this.paginator;
              this.dataSource.sort = this.sort;
          }
      });
}




}