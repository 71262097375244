import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-non-cooperation-with-elig-proc',
  templateUrl: './non-cooperation-with-elig-proc.component.html',
  styleUrls: ['./non-cooperation-with-elig-proc.component.css']
})
export class NonCooperationWithEligProcComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }
}
