import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-hhcomp-disabled-livewith-others',
  templateUrl: './hhcomp-disabled-livewith-others.component.html',
  styleUrls: ['./hhcomp-disabled-livewith-others.component.css']
})

export class HhcompDisabledLivewithOthersComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

 
}