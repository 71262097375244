import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgMaterialModule } from './../../angular-material/ag-matrial.module';
import { SharedModuleModule } from './../../shared-module/shared-module.module';
import {
  QuestionaryComponent,
  QuestionaryDialog
} from './../../shared-module/questionary/questionary.component';
import { QuestionaryResolver } from './../../shared-module/questionary/questionary.resolver';
import { PARoutingModule } from './payment-accuracy.routing';
import { HighchartsChartModule } from 'highcharts-angular';
import { PaDashboardComponent } from './pa-admin/pa-dashboard/pa-dashboard.component';
import { PaSampleComponent } from './pa-admin/pa-sample/pa-sample.component';
import { PaCaseAssignmentComponent } from './pa-admin/pa-case-assignment/pa-case-assignment.component';
import { PaCaseReviewComponent } from './payment-accuracy/pa-case-review/pa-case-review.component';



@NgModule({
  declarations: [
    PaDashboardComponent,
    PaSampleComponent,
    PaCaseAssignmentComponent,
    PaCaseReviewComponent
  ],
  imports: [
    CommonModule,
    PARoutingModule,
    SharedModuleModule,
    AgMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    HighchartsChartModule,
  ],
  exports: [PARoutingModule],
  entryComponents: [
    PaDashboardComponent,
    PaSampleComponent,
    PaCaseAssignmentComponent,
    PaCaseReviewComponent
  ],
  providers: [QuestionaryResolver]
})
export class PaymentAccuracyModule { }
