import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-fsp-pre-review-cases',
  templateUrl: './fsp-pre-review-cases.component.html',
  styleUrls: ['./fsp-pre-review-cases.component.css']
})
export class FspPreReviewCasesComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
