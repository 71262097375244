import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../shared-module/baseComponent.component';

@Component({
  selector: 'app-paris-alert',
  templateUrl: './paris-alert.component.html',
  styleUrls: ['./paris-alert.component.css']
})

export class ParisAlertComponent extends BaseComponent implements OnInit {
  
  ngOnInit() {
    super.ngOnInit();
  }
}