import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-singnificant-persons-not-living-work-book',
  templateUrl: './singnificant-persons-not-living-work-book.component.html',
  styleUrls: ['./singnificant-persons-not-living-work-book.component.css']
})
export class SingnificantPersonsNotLivingWorkBookComponent extends BaseComponent implements OnInit {
  // currentPage: number = 0;
  isNotEditable: boolean =false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
}
