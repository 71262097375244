import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-dependent-care-costfor-each-household-members',
  templateUrl: './dependent-care-costfor-each-household-members.component.html',
  styleUrls: ['./dependent-care-costfor-each-household-members.component.css']
})
export class DependentCareCostforEachHouseholdMembersComponent extends BaseComponent implements OnInit {


  isNotEditable: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }


}

