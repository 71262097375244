import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';

@Component({
  selector: 'app-fsme-case-assignment',
  templateUrl: './fsme-case-assignment.component.html',
  styleUrls: ['./fsme-case-assignment.component.css']
})

export class FsmeCaseAssignmentComponent implements OnInit {

  isNotEditable: boolean = false;

  constructor( public constantsService: ConstantsService) { }

  ngOnInit() {
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

      if ((ref.accessMap[''] != null && ref.accessMap[''].accessMode != 'rw')) {
        this.isNotEditable = true;
      }
  }
  
}