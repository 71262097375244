import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-disability-of-less-than3nths',
  templateUrl: './disability-of-less-than3nths.component.html',
  styleUrls: ['./disability-of-less-than3nths.component.css']
})
export class DisabilityOfLessThan3nthsComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
