import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-non-liquid-assets',
  templateUrl: './non-liquid-assets.component.html',
  styleUrls: ['./non-liquid-assets.component.css']
})

export class NonLiquidAssetsComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

  onChangeQ224_1_07(val){
    if(val==='N'){
      this.group.controls['224.1.08'].disable();
    }else if (val === 'Y'){
      this.group.controls['224.1.08'].enable();

    }
  }
}