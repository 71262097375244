import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-acceptance-of-employment',
  templateUrl: './acceptance-of-employment.component.html',
  styleUrls: ['./acceptance-of-employment.component.css']
})

export class AcceptanceOfEmploymentComponent extends BaseComponent implements OnInit {
  isNotEditable: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }



  get Is166_1(): boolean {

    return this.group.controls['166.1'].value === 'N';
  }

  get Is166_2(): boolean {
  
    return this.group.controls['166.2'].value === 'N';
  }


  changeof166_1(event:any){
    if(event.value === 'N') {
      this.group.controls['166.2'].disable();
    }else if(event.value === 'Y'){
      this.group.controls['166.2'].enable();
    }
  }
  handleQ166_8(val){
    if(val === '9'){
      this.group.controls['166.8a'].enable();
    }else{
      this.group.controls['166.8a'].disable();
    }
}

}