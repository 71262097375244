import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';
import { FormArray, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-earned-incomefrom-cares',
  templateUrl: './earned-incomefrom-cares.component.html',
  styleUrls: ['./earned-incomefrom-cares.component.css']
})
export class EarnedIncomefromCARESComponent extends BaseComponent implements OnInit {
  currentPage: number = 0;
isNotEditable: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    const ctrl:any = (this.group.get('FormArray'));
    this.sum(ctrl.controls[0])
  }
  sum(arrayControl){
    const amt1 = arrayControl.controls["311.4.02a"].value;
    const amt2 = arrayControl.controls["311.4.02d"].value;
    const amt3 = arrayControl.controls["311.4.02g"].value;
    const amt4 = arrayControl.controls["311.4.02j"].value;
    const amt5 = arrayControl.controls["311.4.02m"].value;
   const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5);
    arrayControl.controls["311.4.02p"].patchValue(res);
  }

}

