import { Component, OnInit, OnChanges, Inject, ViewChild, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiManager } from '../../common/api-manager.service';
import { ConstantsService } from '../../common/constants.service';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { HttpClient } from "@angular/common/http";
import { SelectionModel } from "@angular/cdk/collections";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, ControlValueAccessor } from "@angular/forms";
import { Region, DistrictOffice, Analyst } from '../../model/api/region-district-analyst.class';
import { AppUtilService } from "src/app/common/app.util.service";
import { NavigationService } from '../../common/navigation.service';


@Component({
  selector: "app-case-view",
  templateUrl: "./case-view.component.html",
  styleUrls: ["./case-view.component.css"]
})

export class CaseViewComponent implements OnInit, OnChanges {
  regions: any = [];

  districtOfficesList: DistrictOffice[] = [];
  Analysts: Analyst[] = [ ];
  caseViewForm: FormGroup;
  title: string;
  today;

  ngOnInit(): void {
    // throw new Error("Method not implemented.");
  }

  ngOnChanges() {

  }
  

  buildForm() {
    this.caseViewForm = this.fb.group({
      region: [""],
      DistrictOffice: [""],
      Analyst: ["", Validators.required]
    });
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  casedisplayedColumns = [
    "select",
    "ldssName",
    "review",
    "sampleMo",
    "reviewMonth",
    "caseId",
    "hohNm",
    "pgmTypeDesc",
    "assignment",
    "rvwStsDesc",
    "actions"
  ];
  assigneUrl: any;
  casedataSourceDialog: any = new MatTableDataSource();
  casedataSourceDialogAll: any = new MatTableDataSource();
  selection = new SelectionModel(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.casedataSourceDialog.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterTogglea() {
    let index=this.paginator.pageIndex+1;
    let size=this.paginator.pageSize;
    let indexnr=0;
    let startNr=(index-1)*size;
    startNr=startNr+1;
    let endNr=startNr+size;
    this.isAllSelected()
      ? this.selection.clear()
      : this.casedataSourceDialog.data.forEach((row: any) =>{
        indexnr=indexnr+1
        if (indexnr>=startNr && indexnr <endNr) {
          this.selection.select(row)
        }
        else {
          this.selection.deselect(row)
        }
      }
    );
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any) {
    this.selection.selected.push(row);
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${
      this.selection.isSelected(row) ? "deselect" : "select"
    } row ${row.ldssName + 1}`;
  }

  ngAfterViewInit() {
    this.casedataSourceDialog.paginator = this.paginator;
    this.casedataSourceDialog.sort = this.sort;
  }

  map: Map<any, any> = new Map();
  isAssigned = true;
  Analyst: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  assign() {
    console.log("selected....", this.selection.selected);
    this.selection.selected.forEach(row => {
      let id: string = row.rvwNum;
      let pgmCd: string = row.rvwType;
      let analyst = this.caseViewForm.get("Analyst").value;
        const queryParams: any[] = [];
        queryParams.push(id);
        queryParams.push(analyst);
        queryParams.push('QC');
        queryParams.push(pgmCd);

        this.apiManager.fetchData('saveAssignment', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
        .subscribe(
          res => {
          console.log("----Assigned-----");
        /*   this.casedataSourceDialog.data = res;
          this.casedataSourceDialog.data = this.casedataSourceDialog.data.sort(); */
        });
    });
    this.dialogRef.close();
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private apiManager: ApiManager,
    public navigationService: NavigationService,
    public constantsService: ConstantsService,
    private httpClient: HttpClient,
    private readonly dialogRef:MatDialogRef<CaseViewComponent>,
    private cdr: ChangeDetectorRef,
    public appUtilService: AppUtilService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.regions = this.constantsService.getDistinctRegions();
    this.Analysts = this.constantsService.getDistinctReviewer();
    this.today = new Date().toUTCString();
    if (data.action == "Cases") {
      this.casedisplayedColumns.splice(0,1);
      this.title = "Overall Case Details";
    } 
    else if (data.action == "Assigned") {
      this.casedisplayedColumns.splice(0,1);
      this.title = "Assigned Case Details";
    } 
    else if (data.action == "inProgress"){
      this.casedisplayedColumns.splice(0,1);
      this.title = "InProgress Case Details";
    } 
    else {
      this.casedisplayedColumns.splice(7,8);
      this.title = "UnAssigned Case Details";
    }
    this.buildForm();
    {
      console.log("action....", data.action);
      if (data.action == "UnAssigned") {
        this.isAssigned = false;
      }
      this.map.set("maCount", "MA");
      this.map.set("tcaCount", "TCA");
    //  this.map.set("esapCount", "ESAP");
      this.map.set("esapPstvCount", "ESAPPositive");
      this.map.set("esapNgtvCount", "ESAPNegative");
      this.map.set("otoCount", "OTO");
      this.map.set("fspNgtvCnt", "FSPNegative");
      this.map.set("fspPstvCount", "FSPPositive");
      this.map.set("Total", "Total")

      const queryParams: any[] = [];
      queryParams.push(data.action);
      queryParams.push(this.map.get(data.source));
      const samplingData  = this.appUtilService.changeDateToMonthFormat(this.constantsService.dashboardMonth);
    queryParams.push(samplingData) ;

        this.apiManager.fetchData('assigneUrl', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
        .subscribe(
          result => {
        this.casedataSourceDialogAll.data = result;
        this.casedataSourceDialog.data = result;
        if(data.analystName) {
          this.casedataSourceDialog.data =  this.casedataSourceDialog.data.filter(s=> s.assignee==data.analystName);
        }
        this.casedataSourceDialog.data = this.casedataSourceDialog.data.sort();
      });
    }
  }


  @ViewChild('ref', { static: false }) ref: any;

  masterToggle(_ref: any) {
    if(_ref.checked){
        this.checkPageOnly();
    }
    else {
        this.selection.clear();
    }
  }

  review(rvwInfo) {
    this.constantsService.ReviewInfo = rvwInfo;
    if (this.constantsService.ReviewInfo) {
      this.refreshNav();
    }
    this.dialogRef.close();

  }

  refreshNav() {
    this.navigationService.getPrograms(this.navigationService.appCd).then(v => {
      this.afterNavChange();
    });
  }

  afterNavChange() {
    // Do Nothing
    // TODO : if any change after nav change while adding new program
    this.navigationService.navigatePage('/qualityControl/caseHome');
  }

  checkPageOnly() {
      let i = this.casedataSourceDialog.paginator.pageIndex * this.casedataSourceDialog.paginator.pageSize;
      let end = (this.casedataSourceDialog.paginator.pageIndex + 1) * this.casedataSourceDialog.paginator.pageSize;
      for(i; i < end; i++) {
        this.selection.select(this.casedataSourceDialog.data[i]);
      }
     console.log('selected...',this.selection.selected)
  }

    pageChange() {
        this.ref.checked = false;
        this.masterToggle(this.ref);
    }

    isAssignEnabled():boolean{
      if(this.selection.selected.length>0  && this.caseViewForm.get('Analyst').value!='' ){
        return false;
      }
      else {
        return true;
      }
    }

    changeDistrictByRegionCd(regCd) {
      this.districtOfficesList = this.constantsService.getDoByRegionCdWithOutAppCd(regCd);
    }

    changeAnalystByDoCd(doCd) {
      // this.Analysts = this.constantsService.changeAnalystByDoCd(doCd);
      
      this.casedataSourceDialog.data = this.casedataSourceDialogAll.data.filter(item => item['ldss'] === +doCd);
    }
}