import { Component, OnInit, OnChanges, Inject, ViewChild, ChangeDetectorRef } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { ApiManager } from '../../common/api-manager.service';
import { ConstantsService } from '../../common/constants.service';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { HttpClient } from "@angular/common/http";
import { SelectionModel } from "@angular/cdk/collections";
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, ControlValueAccessor } from "@angular/forms";

@Component({
  selector: 'app-case-count-view',
  templateUrl: './case-count-view.component.html',
  styleUrls: ['./case-count-view.component.css']
})

export class CaseCountViewComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;  
  map: Map<any, any> = new Map();
  casedataSourceDialog: any = new MatTableDataSource();
  
  casedisplayedColumns = [
    "ldss",
    "review",
    "reviewMonth",
    "caseId",
    "hohNm",
    // "reviewType",
    "assignment"
  ];

  title: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    private httpClient: HttpClient,
    private readonly dialogRef:MatDialogRef<any>,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (data.action == "Cases") {
      this.casedisplayedColumns.splice(0,1);
      this.title = "Overall Case Details-" + this.data.source ;
    } 
    else if (data.action == "Assigned") {
      this.casedisplayedColumns.splice(0,1);
      this.title = "Assigned Case Details-" + this.data.source;
    } 
    else if (data.action == "discrepanciesCorrect") {
      this.casedisplayedColumns.splice(0,1);
      this.title = "Discrepancies Case Details Correct - " + this.data.title;
    } 
    else if (data.action == "discrepanciesInCorrect") {
      this.casedisplayedColumns.splice(0,1);
      this.title = "Discrepancies Case Details InCorrect - " + this.data.title;
    } 
    else if (data.action == "discrepanciesTotal") {
      this.casedisplayedColumns.splice(0,1);
      this.title = "Discrepancies Case Details Total - " + this.data.title;
    } 
    else {
      this.title = "UnAssigned Case Details-" + this.data.source;
    }

    this.getData(data);
   }

  ngOnInit() {
    
  }

  getData(data) {
    this.map.set("maCount", "MA");
    this.map.set("tcaCount", "TCA");
    this.map.set("esapCount", "ESAP");
    this.map.set("otoCount", "OTO");
    this.map.set("fspNgtvCnt", "FSPNegative");
    this.map.set("fspPstvCount", "FSPPositive");
     

    this.map.set("fsmeAprvdCnt", "FSME_FS_APPS_FILED");
    this.map.set("fsmeRcpntClms", "FSME_RECIPIENT_CLAIMS");
    this.map.set("fsmeClsdCases", "FSME_FS_CLSD_CASES");
    this.map.set("fsmeDndCnt", "FSME_FSP_DENIALS");
    this.map.set("fsmeSvesPrstnMatch", "FSME_SVES_PRISON_MATCH");
    this.map.set("fsmeRcpntIntgrty", "FSME_RECIPIENT_INTEGRITY");
     this.map.set("fsmeAbawdActives", "FSME_ABAWD_ACTV_CASES");
    this.map.set("fsmeAbawdClsd", "FSME_ABAWD_CLSD");
    this.map.set("fsmeDeathMatchAlerts", "FSME_DEATH_MATCH_ALERTS");
    this.map.set("fsmeFspPreRvwCases", "FSME_FSP_PRE_RVW_CASES");
    this.map.set("fsmeEbtMltplCases", "FSME_EBT_MLTPL_CASES");
     this.map.set("fsmeRedetCases", "FSME_REDET_CASES");
     this.map.set("fsmeMyDhrCases", "FSME_MY_DHR_CASES");
    

    const queryParams: any[] = [];
    queryParams.push(data.action);

    if(data.action==='discrepanciesCorrect' || data.action==='discrepanciesInCorrect' || data.action==='discrepanciesTotal') {
      queryParams.push( this.map.get(data.source));
    } else {
     queryParams.push(data.title);
    }
    queryParams.push(data.source);

      this.apiManager.fetchData('assigneUrl', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
      .subscribe(
        result => {
          this.casedataSourceDialog=[];
          this.casedataSourceDialog = new MatTableDataSource(result);
          this.casedataSourceDialog = this.casedataSourceDialog.data.sort();
    });
  }
}