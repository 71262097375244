import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { element } from 'protractor';
import { ApiManager } from 'src/app/common/api-manager.service';
import { ConstantsService } from 'src/app/common/constants.service';

@Component({
  selector: 'app-edit-view-workbook',
  templateUrl: './edit-view-workbook.component.html',
  styleUrls: ['./edit-view-workbook.component.css']
})
export class EditViewWorkbookComponent implements OnInit {

  public editViewWorkbookForm: FormGroup;
  templateName: any; 
  filesToUpload = []; 

  isNotEditable: boolean = false;
  uploadSet: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    private fb: FormBuilder,
  ) {
    
  }
  buildForm() {
    this.editViewWorkbookForm = this.fb.group({
      bookmarkname: [''],
      order: [''],
      qc_doc_repo_id: [''],
      rvw_num: [''],
      element_num:[''],
      cmmts:[''],
      notes:[''],
      create_dt:[''],
      dirtyStatus: ['UPDATE'],
    });
  }

  ngOnInit(){
    this.buildForm();
    if (this.data.index > -1) {
      const selectedFindResp = this.data.data;
      //this.data.data.create_dt
      this.editViewWorkbookForm.patchValue(selectedFindResp);
      // this.editViewWorkbookForm.controls.cmmts.setValue(this.getScreenVulue(selectedFindResp.element_num));
      }
  }


 
  getScreenVulue(element_num: any) {
    let screenList = this.constantsService.listOfScreens;
    if(screenList.find(item=>item === element_num)){
      return "_" + element_num;
    }else{
      return "";
    }
  }

  

  appendingBookmarkName(){
    if(this.data.ind!=='1' && this.constantsService.ReviewInfo.rvwType==='FSPPositive' && !(this.editViewWorkbookForm.get('bookmarkname').value)){
      let hohName = this.constantsService.ReviewInfo.hohNm;
      if(hohName.length > 0 && hohName.split(' ').length > 0){
        hohName = this.constantsService.ReviewInfo.hohNm.split(' ')[1]+"_"+this.constantsService.ReviewInfo.hohNm.split(' ')[0]
      }
      this.editViewWorkbookForm.get('bookmarkname').patchValue(
        this.constantsService.getDisplayReviewNum(this.constantsService.ReviewInfo.rvwNum)+"_"+hohName+"_");
    }
    if(this.constantsService.ReviewInfo.rvwType==='FSPNegative' )
    {
      if(this.editViewWorkbookForm.get('element_num').value === 'EligHs'){
        this.editViewWorkbookForm.get('bookmarkname').patchValue('EligibilityHistory');
      }else if(this.editViewWorkbookForm.get('element_num').value === 'BenHs'){
        this.editViewWorkbookForm.get('bookmarkname').patchValue('BenefitHistory');
      }else if(this.editViewWorkbookForm.get('element_num').value === 'SNAP'){
        this.editViewWorkbookForm.get('bookmarkname').patchValue('SupplementalNutritionAssistanceApplication');
      }else if(this.editViewWorkbookForm.get('element_num').value === 'Doc'){
        this.editViewWorkbookForm.get('bookmarkname').patchValue('Documentation');
      }
    }
  }
  async save(row) {
    console.log(row);
    const queryParams: any[] = [];
    queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    const responseArray = row.value.cmmts;
    let cmmts = '';
    if (responseArray && responseArray.length > 0) {
      cmmts = '_' + responseArray.join('_') ;
    }
    let objBody = {
      qc_doc_repo_id: row.value.qc_doc_repo_id,
      rvwNum: this.constantsService.ReviewInfo.rvwNum,
      tmplt_name:row.value.tmplt_name,
      bookmarkname:row.value.bookmarkname,
      element_num:row.value.element_num,
      cmmts:cmmts,
      notes:row.value.notes,
      pdf_bytes: await this.getFileByteArray(new Blob(this.filesToUpload[0]))
    }
    this.apiManager.fetchData('updateQcDocName', undefined, ApiManager.POST, objBody, undefined, undefined, queryParams)
      .subscribe(
        res => {
           this.dialogRef.close(res);
        });
  }
  async save1(row) {
    console.log(row);
    const queryParams: any[] = [];
    queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    let objBody = {
      qc_doc_repo_id: row.value.qc_doc_repo_id,
      rvwNum: this.constantsService.ReviewInfo.rvwNum,
      // tmplt_name:row.value.tmplt_name,
      // bookmarkname:row.value.bookmarkname,
      // element_num:row.value.element_num,
      // cmmts:row.value.cmmts,
      pdf_bytes: await this.getFileByteArray(new Blob(this.filesToUpload[0]))
    }
    this.apiManager.fetchData('updateQcDocFile', undefined, ApiManager.POST, objBody, undefined, undefined, queryParams)
      .subscribe(
        res => {
           this.dialogRef.close(res);
        });
  }
  onFileChange(event) {
    console.log(event);
    let unsupportedDocExist = this.checkUnsupportedDoc(event);
    if (unsupportedDocExist) {
      alert("You are uploading an unsupported Document(.js/.exe/.mov/.mp4), please check and reupload.");
      return null;
    }
    this.filesToUpload = [];
    if (event.target.files.length) {
      this.filesToUpload.push(event.target.files);
      console.log(this.filesToUpload);
    }
    this.uploadSet = false;
  }
  checkUnsupportedDoc(event) {
    let unsupportedDoc = false;
    for (let file of event.target.files) {
      if (file.type === 'text/javascript' || this.matchString('audio%', file.type) || this.matchString('video%', file.type)
        || file.type === 'application/x-msdownload'|| file.type === 'png') {
        unsupportedDoc = true;
        break;
      }
    }
    return unsupportedDoc;
  }
  matchString(source, input) {
    let match = true;
    for (let i = 0; (i < source.length && source[i] != '%'); i++) {
      if (input[i] !== source[i]) {
        match = false;
      }
    }
    return match;
  }
  getFileByteArray(file) {
    return new Promise((resolve, reject) => {
      try {
          let reader = new FileReader();
          let fileByteArray = [];
          reader.readAsArrayBuffer(file);
          reader.onloadend = (evt) => {
              if (evt.target.readyState == FileReader.DONE) {
                  let arrayBuffer = evt.target.result as ArrayBufferLike,
                      array = new Uint8Array(arrayBuffer);
                  for (var i = 0; i < array.length; i++) {
                        fileByteArray.push(array[i]);
                  }
              }
              resolve(fileByteArray);
          }
      }
      catch (e) {
          reject(e);
      } 
    });
  }
}