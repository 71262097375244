import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-selfemployed-income-worksheet2',
  templateUrl: './selfemployed-income-worksheet2.component.html',
  styleUrls: ['./selfemployed-income-worksheet2.component.css']
})
export class SelfemployedIncomeWorksheet2Component extends BaseComponent implements OnInit {
isNotEditable: boolean =false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    const ctrl:any = (this.group.get('FormArray'));
    this.gross(ctrl.controls[0])
    this.grossTwo(ctrl.controls[0])
    this.grossThree(ctrl.controls[0])
  }
 
  gross(arrayControl){
    let counter = 0;
    const amt1 = arrayControl.controls["312.6.5a0"].value;
    const amt2 = arrayControl.controls["312.6.5a1"].value;
    const amt3 = arrayControl.controls["312.6.5a2"].value;
    const amt4 = arrayControl.controls["312.6.5a3"].value;
    const amt5 = arrayControl.controls["312.6.5a4"].value;
    amt1 != null ? counter++: counter;
    amt2 != null ? counter++: counter;
    amt3 != null ? counter++: counter;
    amt4 != null ? counter++: counter;
    amt5 != null ? counter++: counter;
   const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5);
    arrayControl.controls["312.6.10a"].patchValue(res);
    const average =  (res)/counter;
    arrayControl.controls["312.6.11a"].patchValue(average);
    const amtX = arrayControl.controls["312.6.7a"].value;
    const equalTo =  (amtX)*average;
    arrayControl.controls["312.6.13a"].patchValue(equalTo);
    const amtHh = arrayControl.controls["312.6.8a"].value;
    const equalToTwo =  (equalTo)/amtHh;
    arrayControl.controls["312.6.14a"].patchValue(equalToTwo);
    const amtEligHh = arrayControl.controls["312.6.9a"].value;
    const equalToThree =  (equalToTwo*amtEligHh)
    arrayControl.controls["312.6.15a"].patchValue(equalToThree);
  }
  grossTwo(arrayControl){
    let counter = 0;
    const amt1 = arrayControl.controls["312.6.5b0"].value;
    const amt2 = arrayControl.controls["312.6.5b1"].value;
    const amt3 = arrayControl.controls["312.6.5b2"].value;
    const amt4 = arrayControl.controls["312.6.5b3"].value;
    const amt5 = arrayControl.controls["312.6.5b4"].value;
    amt1 != null ? counter++: counter;
    amt2 != null ? counter++: counter;
    amt3 != null ? counter++: counter;
    amt4 != null ? counter++: counter;
    amt5 != null ? counter++: counter;
   const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5);
   arrayControl.controls["312.6.10b"].patchValue(res);
    const average =  (res)/counter;
    arrayControl.controls["312.6.11b"].patchValue(average);
    const amtX = arrayControl.controls["312.6.7b"].value;
    const equalTo =  (amtX)*average;
    arrayControl.controls["312.6.13b"].patchValue(equalTo);
    const amtHh = arrayControl.controls["312.6.8b"].value;
    const equalToTwo =  (equalTo)/amtHh;
    arrayControl.controls["312.6.14b"].patchValue(equalToTwo);
    const amtEligHh = arrayControl.controls["312.6.9b"].value;
    const equalToThree =  (equalToTwo*amtEligHh)
    arrayControl.controls["312.6.15b"].patchValue(equalToThree);
    
  }
  grossThree(arrayControl){
    let counter = 0;
    const amt1 = arrayControl.controls["312.6.5c0"].value;
    const amt2 = arrayControl.controls["312.6.5c1"].value;
    const amt3 = arrayControl.controls["312.6.5c2"].value;
    const amt4 = arrayControl.controls["312.6.5c3"].value;
    const amt5 = arrayControl.controls["312.6.5c4"].value;
    amt1 != null ? counter++: counter;
    amt2 != null ? counter++: counter;
    amt3 != null ? counter++: counter;
    amt4 != null ? counter++: counter;
    amt5 != null ? counter++: counter;
   const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5);
   arrayControl.controls["312.6.10c"].patchValue(res);
   const average =  (res)/counter;
   arrayControl.controls["312.6.11c"].patchValue(average);
   const amtX = arrayControl.controls["312.6.7c"].value;
   const equalTo =  (amtX)*average;
   arrayControl.controls["312.6.13c"].patchValue(equalTo);
   const amtHh = arrayControl.controls["312.6.8c"].value;
   const equalToTwo =  (equalTo)/amtHh;
   arrayControl.controls["312.6.14c"].patchValue(equalToTwo);
   const amtEligHh = arrayControl.controls["312.6.9c"].value;
    const equalToThree =  (equalToTwo*amtEligHh)
    arrayControl.controls["312.6.15c"].patchValue(equalToThree);
  }

}
