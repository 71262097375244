import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-qc54-school-verification-form',
  templateUrl: './qc54-school-verification-form.component.html',
  styleUrls: ['./qc54-school-verification-form.component.css']
})
export class QC54SchoolVerificationFormComponent extends BaseComponent implements OnInit {
  householdMembers: any;

  ngOnInit() {
    this.householdMembers = this.constantsService.householdMembers;
    this.householdMembers.map(indivId =>{
      if(indivId.headOfHsehldInd =='Y'){
      this.setControlValue('500115.6', indivId.indvdlId);
      this.setControlValue('500115.7', indivId.dob);
      this.setControlValue('500115.8', indivId.address1+', '+indivId.address2);
      }
    });
    super.ngOnInit();
  }


}
