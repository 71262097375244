
import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ApiManager } from 'src/app/common/api-manager.service';
import { ConstantsService } from 'src/app/common/constants.service';

export interface PeriodicElement {
  county:any;
  rvwNum: any;
  do: any;
  caseId: any;
  pgmBgnDt:any;
  pgmEndDt:any;
  issncStsDt:any;
  issnTypCd:any;
  grntBnftAmt:any;
  pgmCd: any;
  aidCd: any;
  caseStatusCd: any;
  reviewStatus: any;
  reviewStatus15: any;
  reviewStatusbm: any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {county: '10/31/2019',rvwNum:'03',do:'Baltimore County',caseId:'AF',pgmBgnDt:'A',pgmEndDt:'AE',issncStsDt:'PB',issnTypCd:'AKHTAR,NICOLE',grntBnftAmt:440163210,pgmCd:'09/19',aidCd:'09/18/2019',caseStatusCd:'09/18/2019',reviewStatus:'',reviewStatus15:72.00,reviewStatusbm:72.00},
  {county: '10/31/2019',rvwNum:'03',do:'Baltimore County',caseId:'AF',pgmBgnDt:'A',pgmEndDt:'AE',issncStsDt:'PB',issnTypCd:'AKHTAR,NICOLE',grntBnftAmt:440163210,pgmCd:'09/19',aidCd:'09/18/2019',caseStatusCd:'09/18/2019',reviewStatus:'',reviewStatus15:72.00,reviewStatusbm:72.00},
  {county: '10/31/2019',rvwNum:'03',do:'Baltimore County',caseId:'AF',pgmBgnDt:'A',pgmEndDt:'AE',issncStsDt:'PB',issnTypCd:'AKHTAR,NICOLE',grntBnftAmt:440163210,pgmCd:'09/19',aidCd:'09/18/2019',caseStatusCd:'09/18/2019',reviewStatus:'',reviewStatus15:72.00,reviewStatusbm:72.00},
];

@Component({
  selector: 'app-overpayment-begs',
  templateUrl: './overpayment-begs.component.html',
  styleUrls: ['./overpayment-begs.component.css']
})

export class OverpaymentBegsComponent implements OnInit {

@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
@ViewChild(MatSort, {static: true}) sort: MatSort;
overpaymentForm: FormGroup;

buildForm() {
  this.overpaymentForm = this.fb.group({ 
   })
   };

displayedColumns: string[] = ['county','rvwNum','do','caseId','pgmBgnDt','pgmEndDt','issncStsDt','issnTypCd','grntBnftAmt','pgmCd','aidCd','caseStatusCd','reviewStatus','reviewStatus15','reviewStatusbm','BalanceDue'];
dataSource = ELEMENT_DATA;
isNotEditable: boolean = false;

constructor(
  private fb: FormBuilder,
  private apiManager: ApiManager,
  public constantsService: ConstantsService
  )
{
  // this.dataSource = new MatTableDataSource();
  this.buildForm();
 }

ngOnInit() {
  this.getSelectSample();
}

determineAccess() {
  const ref = this.constantsService.getSecurityObject();

  if ((ref.accessMap[''] != null && ref.accessMap[''].accessMode != 'rw')) {
      this.isNotEditable = true;
  }
}

getSelectSample() {
  // const queryParams: any[] = [];
  // queryParams.push('BPE-PA');
  // this.apiManager.fetchData('getSelectSample', undefined, ApiManager.GET,
  //     undefined, undefined, undefined, queryParams)
  //     .subscribe(res => {
  //       if (res) {
  //         console.log(res);

  //         this.dataSource = new MatTableDataSource(res);
  //         this.dataSource.paginator = this.paginator;
  //         this.dataSource.sort = this.sort;
  //       }
  //     });
  }
}