import { Component, OnInit, Inject } from '@angular/core';
import { ApiManager } from 'src/app/common/api-manager.service';
import { saveAs } from 'file-saver/FileSaver';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-qc16-fsnegaction-review',
  templateUrl: './qc16-fsnegaction-review.component.html',
  styleUrls: ['./qc16-fsnegaction-review.component.css']
})
export class Qc16FsnegactionReviewComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;
  QC16NCompletedStatus: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    // this.isDisabledCompltedQC16N();
  }
  // // DISABLED check for analyst 
  // isDisabledCompltedQC16N(){
  //   if("(this.group.controls['500022.1'].value=='true'?'false':'true') || (this.constantsService.ReviewInfo.rvwSts === 'CP' && (this.constantsService?.isAnalystUser()))")
  // {
  //   this.QC16NCompletedStatus = true;
  
  // }
  // else{
  //   this.QC16NCompletedStatus = false;
  // }
  // }
  
  get currentIndividual() {
    return this.shareService.getDataFromMap('currentIndividual');
  }

  set currentIndividual(id) {
    this.shareService.addDataToMap('currentIndividual', id);
  }

  get IsView():boolean{
    return this.field[0].ccId === 0;
  }

  viewQ16NegativeForm(){
    const queryParams: any[] = [];
    let ccId = this.field[0].ccId;
    if (ccId != 0) {
      queryParams.push(ccId);
    }
      this.apiManager.fetchData('getDocumentForDownload', undefined, ApiManager.FILEDOWNLOAD, undefined, undefined, undefined, queryParams)
      .subscribe(res => {
        if (res) {
      
       let blob = new Blob([res], { type: res.type });
         let fileURL = URL.createObjectURL(blob);
         var newWin = window.open(fileURL);
        }
      }
    );
  }
  downloadQ16NegativeForm(){
    const queryParams: any[] = [];
  if (this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }
    const invId: any = this.currentIndividual;
    if (invId) {
      queryParams.push(invId);
    }  
    this.screenType = "500022";
      queryParams.push(this.screenType); //screen type
    
    let filename: any;
    filename = this.constantsService.ReviewInfo.rvwNum;
    this.apiManager.fetchData('saveQ16NegativeQs', undefined, ApiManager.POST, undefined, undefined, undefined, queryParams)
     .subscribe(res => {
       if (res) {
        this.field[0].ccId = res.ccId;
        // let blob;
        // blob = new Blob([res], { type: 'application/pdf' });
        // saveAs(blob, filename);
        this._snackBar.open('QC16N PDF Generated Successfully.', undefined, {
          duration: 2000,
        });
       }
     },
     error => {
       this._snackBar.open('Save failed.Please save and try again. ', undefined, {
         duration: 2000,
       });
       console.log('look up API call failure: Error');
     }
   );
  }
}