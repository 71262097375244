import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-review-findings-work-book',
  templateUrl: './review-findings-work-book.component.html',
  styleUrls: ['./review-findings-work-book.component.css']
})
export class ReviewFindingsWorkBookComponent extends BaseComponent implements OnInit {
isNotEditable: boolean =false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
}
