import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-abawd',
  templateUrl: './abawd.component.html',
  styleUrls: ['./abawd.component.css']
})
export class ABAWDComponent extends BaseComponent implements OnInit {

  isChange_161_12_16:boolean = false;
  isChange_161_12_7 :boolean;
  
  ngOnInit() {
    super.ngOnInit();  
    // this.handle161_1();
    // this.handle_not_abawd();
    // this.handle_not_abawd_161_3();
    // this.handle_not_abawd_161_4();
    // this.handle_not_abawd_161_5();
    // this.handle_not_abawd_161_6(this.getControlValue('161.1.1.6'));
    // this.handle_not_abawd_161_7();
    // this.handle_not_abawd_161_8();
    // this.handle_not_abawd_161_9();
    // this.handle_not_abawd_161_10();
    // this.calc_161_1a();
 
   
  }

  calc_161_1a(){
    let age = this.ageFromDateOfBirthday(this.group.controls['161.1.1.1a'].value);

    if(age < 18) {
      this.group.controls['161.1.1.2'].disable();
      this.group.controls['161.1.1.3'].disable();
      this.group.controls['161.1.1.4'].disable();
      this.group.controls['161.1.1.5'].disable();
      this.group.controls['161.1.1.6'].disable();
      this.group.controls['161.1.1.7'].disable();
      this.group.controls['161.1.1.8'].disable();
      this.group.controls['161.1.1.9'].disable();
      this.group.controls['161.1.1.10'].disable();
      this.group.controls['161.1.1.11'].disable();
      this.group.controls['161.1.1.12'].disable();
      this.group.controls['161.1.1.13'].disable();
      this.group.controls['161.1.1.14'].disable();
      this.group.controls['161.1.1.15'].disable();
      this.group.controls['161.1.1.16'].disable();
      
      this.setControlValue('161.1.1.18','Customer is NOT an ABAWD');
    }
     else if(age > 18) {
    this.group.controls['161.1.1.2'].enable();
    this.group.controls['161.1.1.3'].enable();
    this.group.controls['161.1.1.4'].enable();
    this.group.controls['161.1.1.5'].enable();
    this.group.controls['161.1.1.6'].enable();
    this.group.controls['161.1.1.7'].enable();
    this.group.controls['161.1.1.8'].enable();
    this.group.controls['161.1.1.9'].enable();
    this.group.controls['161.1.1.10'].enable();
    this.group.controls['161.1.1.11'].enable();
    this.group.controls['161.1.1.12'].enable();
    this.group.controls['161.1.1.13'].enable();
    this.group.controls['161.1.1.14'].enable();
    this.group.controls['161.1.1.15'].enable();
    this.group.controls['161.1.1.16'].enable();
  
    this.setControlValue('161.1.1.18','');
    }
  }

  ageFromDateOfBirthday(dateOfBirth: any): number {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  

  
  handle161_1() {
    if (this.group.controls['161.1.1.11'].value === 'N') {
      this.group.controls['161.1.1.2'].disable();
      this.group.controls['161.1.1.12'].disable();
      this.group.controls['161.1.1.13'].disable();
      this.group.controls['161.1.1.14'].disable();
      this.group.controls['161.1.1.15'].disable();
      this.group.controls['161.1.1.16'].disable();
    
      this.setControlValue('161.1.1.19','Customer is  an ABAWD');
    }
    else if (this.group.controls['161.1.1.11'].value === 'Y') {
      this.group.controls['161.1.1.2'].enable();
      this.group.controls['161.1.1.12'].enable();
    this.group.controls['161.1.1.13'].enable();
    this.group.controls['161.1.1.14'].enable();
    this.group.controls['161.1.1.15'].enable();
    this.group.controls['161.1.1.16'].enable();
   
    this.setControlValue('161.1.1.19','');
    }
  }

  handle_not_abawd(){
    let q12 = this.group.controls['161.1.1.12'].value;
    let q13 = this.group.controls['161.1.1.13'].value;
    let q14 = this.group.controls['161.1.1.14'].value;
    let q15 = this.group.controls['161.1.1.15'].value;
    let q16 = this.group.controls['161.1.1.16'].value;
    if(q12 === 'N'|| q13 === 'N' || q14 === 'N'|| q15 === 'N'|| q16 === 'N') {
      this.group.controls['161.1.1.1'].disable();
      this.group.controls['161.1.1.1a'].disable();
      this.group.controls['161.1.1.2'].enable();
      this.group.controls['161.1.1.3'].disable();
      this.group.controls['161.1.1.4'].disable();
      this.group.controls['161.1.1.5'].disable();
      this.group.controls['161.1.1.6'].disable();
      this.group.controls['161.1.1.7'].disable();
      this.group.controls['161.1.1.8'].disable();
      this.group.controls['161.1.1.9'].disable();
      this.group.controls['161.1.1.10'].disable();
      this.group.controls['161.1.1.11'].disable();
      
      this.setControlValue('161.1.1.18','Customer is NOT an ABAWD');

    }
    else   if(q12 === 'Y'|| q13 === 'Y' || q14 === 'Y'|| q15 === 'Y'|| q16 === 'Y'){
      this.group.controls['161.1.1.1'].enable();
      this.group.controls['161.1.1.1a'].enable();
      this.group.controls['161.1.1.2'].disable();
    this.group.controls['161.1.1.3'].enable();
    this.group.controls['161.1.1.4'].enable();
    this.group.controls['161.1.1.5'].enable();
    this.group.controls['161.1.1.6'].enable();
    this.group.controls['161.1.1.7'].enable();
    this.group.controls['161.1.1.8'].enable();
    this.group.controls['161.1.1.9'].enable();
    this.group.controls['161.1.1.10'].enable();
    this.group.controls['161.1.1.11'].enable();
    
      this.setControlValue('161.1.1.18','');
    }
  }

  handle_not_abawd_161_3(){
    let q3 = this.group.controls['161.1.1.3'].value;
    if(q3 === 'Y') {
      this.group.controls['161.1.1.1'].disable();
      this.group.controls['161.1.1.1a'].disable();
      this.group.controls['161.1.1.2'].disable();
      this.group.controls['161.1.1.4'].disable();
      this.group.controls['161.1.1.5'].disable();
      this.group.controls['161.1.1.6'].disable();
      this.group.controls['161.1.1.7'].disable();
      this.group.controls['161.1.1.8'].disable();
      this.group.controls['161.1.1.9'].disable();
      this.group.controls['161.1.1.10'].disable();
      this.group.controls['161.1.1.11'].disable();
      this.group.controls['161.1.1.12'].disable();
      this.group.controls['161.1.1.13'].disable();
      this.group.controls['161.1.1.14'].disable();
      this.group.controls['161.1.1.15'].disable();
      this.group.controls['161.1.1.16'].disable();
      
      this.setControlValue('161.1.1.18','Customer is NOT an ABAWD');

    }
    else   if(q3 === 'N') {
      this.group.controls['161.1.1.1'].enable();
      this.group.controls['161.1.1.1a'].enable();
      this.group.controls['161.1.1.2'].enable();
    this.group.controls['161.1.1.4'].enable();
    this.group.controls['161.1.1.5'].enable();
    this.group.controls['161.1.1.6'].enable();
    this.group.controls['161.1.1.7'].enable();
    this.group.controls['161.1.1.8'].enable();
    this.group.controls['161.1.1.9'].enable();
    this.group.controls['161.1.1.10'].enable();
    this.group.controls['161.1.1.11'].enable();
    this.group.controls['161.1.1.12'].enable();
    this.group.controls['161.1.1.13'].enable();
    this.group.controls['161.1.1.14'].enable();
    this.group.controls['161.1.1.15'].enable();
    this.group.controls['161.1.1.16'].enable();
    
      this.setControlValue('161.1.1.18','');
    }
  }
 
  handle_not_abawd_161_4(){
    let q3 = this.group.controls['161.1.1.4'].value;
    if(q3 === 'Y') {
      this.group.controls['161.1.1.1'].disable();
      this.group.controls['161.1.1.1a'].disable();
      this.group.controls['161.1.1.2'].disable();
      this.group.controls['161.1.1.3'].disable();
      this.group.controls['161.1.1.5'].disable();
      this.group.controls['161.1.1.6'].disable();
      this.group.controls['161.1.1.7'].disable();
      this.group.controls['161.1.1.8'].disable();
      this.group.controls['161.1.1.9'].disable();
      this.group.controls['161.1.1.10'].disable();
      this.group.controls['161.1.1.11'].disable();
      this.group.controls['161.1.1.12'].disable();
      this.group.controls['161.1.1.13'].disable();
      this.group.controls['161.1.1.14'].disable();
      this.group.controls['161.1.1.15'].disable();
      this.group.controls['161.1.1.16'].disable();
      
      this.setControlValue('161.1.1.18','Customer is NOT an ABAWD');

    }
    else   if(q3 === 'N') {
      this.group.controls['161.1.1.1'].enable();
      this.group.controls['161.1.1.1a'].enable();
      this.group.controls['161.1.1.2'].enable();
    this.group.controls['161.1.1.3'].enable();
    this.group.controls['161.1.1.5'].enable();
    this.group.controls['161.1.1.6'].enable();
    this.group.controls['161.1.1.7'].enable();
    this.group.controls['161.1.1.8'].enable();
    this.group.controls['161.1.1.9'].enable();
    this.group.controls['161.1.1.10'].enable();
    this.group.controls['161.1.1.11'].enable();
    this.group.controls['161.1.1.12'].enable();
    this.group.controls['161.1.1.13'].enable();
    this.group.controls['161.1.1.14'].enable();
    this.group.controls['161.1.1.15'].enable();
    this.group.controls['161.1.1.16'].enable();
    
      this.setControlValue('161.1.1.18','');
    }
  }


  handle_not_abawd_161_5(){
    let q3 = this.group.controls['161.1.1.5'].value;
    if(q3 === 'Y') {
      this.group.controls['161.1.1.1'].disable();
      this.group.controls['161.1.1.1a'].disable();
      this.group.controls['161.1.1.2'].disable();
      this.group.controls['161.1.1.3'].disable();
      this.group.controls['161.1.1.4'].disable();
      this.group.controls['161.1.1.6'].disable();
      this.group.controls['161.1.1.7'].disable();
      this.group.controls['161.1.1.8'].disable();
      this.group.controls['161.1.1.9'].disable();
      this.group.controls['161.1.1.10'].disable();
      this.group.controls['161.1.1.11'].disable();
      this.group.controls['161.1.1.12'].disable();
      this.group.controls['161.1.1.13'].disable();
      this.group.controls['161.1.1.14'].disable();
      this.group.controls['161.1.1.15'].disable();
      this.group.controls['161.1.1.16'].disable();
      
      this.setControlValue('161.1.1.18','Customer is NOT an ABAWD');

    }
    else   if(q3 === 'N') {
      this.group.controls['161.1.1.1'].enable();
      this.group.controls['161.1.1.1a'].enable();
      this.group.controls['161.1.1.2'].enable();
    this.group.controls['161.1.1.3'].enable();
    this.group.controls['161.1.1.4'].enable();
    this.group.controls['161.1.1.6'].enable();
    this.group.controls['161.1.1.7'].enable();
    this.group.controls['161.1.1.8'].enable();
    this.group.controls['161.1.1.9'].enable();
    this.group.controls['161.1.1.10'].enable();
    this.group.controls['161.1.1.11'].enable();
    this.group.controls['161.1.1.12'].enable();
    this.group.controls['161.1.1.13'].enable();
    this.group.controls['161.1.1.14'].enable();
    this.group.controls['161.1.1.15'].enable();
    this.group.controls['161.1.1.16'].enable();
    
      this.setControlValue('161.1.1.18','');
    }
  }

  handle_not_abawd_161_6(val){
    let q3 = this.group.controls['161.1.1.6'].value;
    if(q3 === 'Y') {
      this.group.controls['161.1.1.1'].disable();
      this.group.controls['161.1.1.1a'].disable();
      this.group.controls['161.1.1.2'].disable();
      this.group.controls['161.1.1.3'].disable();
      this.group.controls['161.1.1.4'].disable();
      this.group.controls['161.1.1.5'].disable();
      this.group.controls['161.1.1.7'].disable();
      this.group.controls['161.1.1.8'].disable();
      this.group.controls['161.1.1.9'].disable();
      this.group.controls['161.1.1.10'].disable();
      this.group.controls['161.1.1.11'].disable();
      this.group.controls['161.1.1.12'].disable();
      this.group.controls['161.1.1.13'].disable();
      this.group.controls['161.1.1.14'].disable();
      this.group.controls['161.1.1.15'].disable();
      this.group.controls['161.1.1.16'].disable();
      
      this.setControlValue('161.1.1.18','Customer is NOT an ABAWD');

    }
    else  if(q3 === 'N'){
      this.group.controls['161.1.1.1'].enable();
      this.group.controls['161.1.1.1a'].enable();
      this.group.controls['161.1.1.2'].enable();
    this.group.controls['161.1.1.3'].enable();
    this.group.controls['161.1.1.4'].enable();
    this.group.controls['161.1.1.5'].enable();
    this.group.controls['161.1.1.7'].enable();
    this.group.controls['161.1.1.8'].enable();
    this.group.controls['161.1.1.9'].enable();
    this.group.controls['161.1.1.10'].enable();
    this.group.controls['161.1.1.11'].enable();
    this.group.controls['161.1.1.12'].enable();
    this.group.controls['161.1.1.13'].enable();
    this.group.controls['161.1.1.14'].enable();
    this.group.controls['161.1.1.15'].enable();
    this.group.controls['161.1.1.16'].enable();
    
      this.setControlValue('161.1.1.18','');
    }
  }

  handle_not_abawd_161_7(){
    let q3 = this.group.controls['161.1.1.7'].value;
    if(q3 === 'Y') {
      this.group.controls['161.1.1.1'].disable();
      this.group.controls['161.1.1.1a'].disable();
      this.group.controls['161.1.1.2'].disable();
      this.group.controls['161.1.1.3'].disable();
      this.group.controls['161.1.1.4'].disable();
      this.group.controls['161.1.1.5'].disable();
      this.group.controls['161.1.1.6'].disable();
      this.group.controls['161.1.1.8'].disable();
      this.group.controls['161.1.1.9'].disable();
      this.group.controls['161.1.1.10'].disable();
      this.group.controls['161.1.1.11'].disable();
      this.group.controls['161.1.1.12'].disable();
      this.group.controls['161.1.1.13'].disable();
      this.group.controls['161.1.1.14'].disable();
      this.group.controls['161.1.1.15'].disable();
      this.group.controls['161.1.1.16'].disable();
      
      this.setControlValue('161.1.1.20','ABAWD Meeting Work Requirements');

    }
    else {
      this.group.controls['161.1.1.1'].enable();
      this.group.controls['161.1.1.1a'].enable();
      this.group.controls['161.1.1.2'].enable();
    this.group.controls['161.1.1.3'].enable();
    this.group.controls['161.1.1.4'].enable();
    this.group.controls['161.1.1.5'].enable();
    this.group.controls['161.1.1.6'].enable();
    this.group.controls['161.1.1.8'].enable();
    this.group.controls['161.1.1.9'].enable();
    this.group.controls['161.1.1.10'].enable();
    this.group.controls['161.1.1.11'].enable();
    this.group.controls['161.1.1.12'].enable();
    this.group.controls['161.1.1.13'].enable();
    this.group.controls['161.1.1.14'].enable();
    this.group.controls['161.1.1.15'].enable();
    this.group.controls['161.1.1.16'].enable();
      this.setControlValue('161.1.1.20','');
    }
  }
  handle_not_abawd_161_8(){
    let q3 = this.group.controls['161.1.1.8'].value;
    if(q3 === 'Y') {
      this.group.controls['161.1.1.1'].disable();
      this.group.controls['161.1.1.1a'].disable();
      this.group.controls['161.1.1.2'].disable();
      this.group.controls['161.1.1.3'].disable();
      this.group.controls['161.1.1.4'].disable();
      this.group.controls['161.1.1.5'].disable();
      this.group.controls['161.1.1.6'].disable();
      this.group.controls['161.1.1.7'].disable();
      this.group.controls['161.1.1.9'].disable();
      this.group.controls['161.1.1.10'].disable();
      this.group.controls['161.1.1.11'].disable();
      this.group.controls['161.1.1.12'].disable();
      this.group.controls['161.1.1.13'].disable();
      this.group.controls['161.1.1.14'].disable();
      this.group.controls['161.1.1.15'].disable();
      this.group.controls['161.1.1.16'].disable();
      
      this.setControlValue('161.1.1.18','Customer is NOT an ABAWD');

    }
    else  if(q3 === 'N') {
      this.group.controls['161.1.1.1'].enable();
      this.group.controls['161.1.1.1a'].enable();
      this.group.controls['161.1.1.2'].enable();
    this.group.controls['161.1.1.3'].enable();
    this.group.controls['161.1.1.4'].enable();
    this.group.controls['161.1.1.5'].enable();
    this.group.controls['161.1.1.6'].enable();
    this.group.controls['161.1.1.7'].enable();
    this.group.controls['161.1.1.9'].enable();
    this.group.controls['161.1.1.10'].enable();
    this.group.controls['161.1.1.11'].enable();
    this.group.controls['161.1.1.12'].enable();
    this.group.controls['161.1.1.13'].enable();
    this.group.controls['161.1.1.14'].enable();
    this.group.controls['161.1.1.15'].enable();
    this.group.controls['161.1.1.16'].enable();
    
      this.setControlValue('161.1.1.18','');
    }
  }

  handle_not_abawd_161_9(){
    let q3 = this.group.controls['161.1.1.9'].value;
    if(q3 === 'Y') {
      this.group.controls['161.1.1.1'].disable();
      this.group.controls['161.1.1.1a'].disable();
      this.group.controls['161.1.1.2'].disable();
      this.group.controls['161.1.1.3'].disable();
      this.group.controls['161.1.1.4'].disable();
      this.group.controls['161.1.1.5'].disable();
      this.group.controls['161.1.1.6'].disable();
      this.group.controls['161.1.1.7'].disable();
      this.group.controls['161.1.1.8'].disable();
      this.group.controls['161.1.1.10'].disable();
      this.group.controls['161.1.1.11'].disable();
      this.group.controls['161.1.1.12'].disable();
      this.group.controls['161.1.1.13'].disable();
      this.group.controls['161.1.1.14'].disable();
      this.group.controls['161.1.1.15'].disable();
      this.group.controls['161.1.1.16'].disable();
   
      this.setControlValue('161.1.1.20','ABAWD Meeting Work Requirements');

    }
    else {
      this.group.controls['161.1.1.1'].enable();
      this.group.controls['161.1.1.1a'].enable();
      this.group.controls['161.1.1.2'].enable();
    this.group.controls['161.1.1.3'].enable();
    this.group.controls['161.1.1.4'].enable();
    this.group.controls['161.1.1.5'].enable();
    this.group.controls['161.1.1.6'].enable();
    this.group.controls['161.1.1.7'].enable();
    this.group.controls['161.1.1.8'].enable();
    this.group.controls['161.1.1.10'].enable();
    this.group.controls['161.1.1.11'].enable();
    this.group.controls['161.1.1.12'].enable();
    this.group.controls['161.1.1.13'].enable();
    this.group.controls['161.1.1.14'].enable();
    this.group.controls['161.1.1.15'].enable();
    this.group.controls['161.1.1.16'].enable();
      this.setControlValue('161.1.1.20','');
    }
  }
  handle_not_abawd_161_10(){
    let q3 = this.group.controls['161.1.1.10'].value;
    if(q3 === 'Y') {
      this.group.controls['161.1.1.1'].disable();
      this.group.controls['161.1.1.1a'].disable();
      this.group.controls['161.1.1.2'].disable();
      this.group.controls['161.1.1.3'].disable();
      this.group.controls['161.1.1.4'].disable();
      this.group.controls['161.1.1.5'].disable();
      this.group.controls['161.1.1.6'].disable();
      this.group.controls['161.1.1.7'].disable();
      this.group.controls['161.1.1.8'].disable();
      this.group.controls['161.1.1.9'].disable();
      this.group.controls['161.1.1.11'].disable();
      this.group.controls['161.1.1.12'].disable();
      this.group.controls['161.1.1.13'].disable();
      this.group.controls['161.1.1.14'].disable();
      this.group.controls['161.1.1.15'].disable();
      this.group.controls['161.1.1.16'].disable();
      
      this.setControlValue('161.1.1.18','Customer is NOT an ABAWD');
      this.setControlValue('161.1.1.19','');
    

    }
    else if(q3 === 'N'){
      this.group.controls['161.1.1.1'].enable();
      this.group.controls['161.1.1.1a'].enable();
      this.group.controls['161.1.1.2'].enable();
    this.group.controls['161.1.1.3'].enable();
    this.group.controls['161.1.1.4'].enable();
    this.group.controls['161.1.1.5'].enable();
    this.group.controls['161.1.1.6'].enable();
    this.group.controls['161.1.1.7'].enable();
    this.group.controls['161.1.1.8'].enable();
    this.group.controls['161.1.1.9'].enable();
    this.group.controls['161.1.1.11'].enable();
    this.group.controls['161.1.1.12'].enable();
    this.group.controls['161.1.1.13'].enable();
    this.group.controls['161.1.1.14'].enable();
    this.group.controls['161.1.1.15'].enable();
    this.group.controls['161.1.1.16'].enable();
    
      this.setControlValue('161.1.1.18','');
  
      this.setControlValue('161.1.1.19','');
    }
  }


  print(): void {
    // let printData = document.getElementById('dataToPrint').cloneNode(true);
    // document.body.appendChild(printData);
    // window.print();
    // document.body.removeChild(printData);

    let printData = document.getElementById('dataToPrint').innerHTML;
    let popupWin = window.open('','_blank','top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(
      `
      <html>
      <head>
        <title>
          Print tab
      </title>
      </head>
      <body onload="window.print();window.close()">${printData} </body>
      </html>`
    );
    popupWin.document.close();
  }
}
