import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BpeDashboardComponent } from './bpe-dashboard/bpe-dashboard.component';
import { BpeAppRoutingModule } from './bpe-app.routing';
import { FsmeModule } from './fsme/fsme.module';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgMaterialModule } from './../angular-material/ag-matrial.module';
import { SharedModuleModule } from './../shared-module/shared-module.module';
import { QuestionaryComponent, QuestionaryDialog } from './../shared-module/questionary/questionary.component';
import { QuestionaryResolver } from './../shared-module/questionary/questionary.resolver';
import { HighchartsChartModule } from "highcharts-angular";
import { PaymentAccuracyModule } from './payment-accuracy/payment-accuracy.module';
import { WprModule } from './wpr/wpr.module';
import { MonitoringModule } from './monitoring/monitoring.module';
import { CurrencyMaskModule } from "ng2-currency-mask";

@NgModule({
  declarations: [
    BpeDashboardComponent
  ],
  imports: [
    CommonModule,
    BpeAppRoutingModule,
    FsmeModule,
    SharedModuleModule,
    AgMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    HighchartsChartModule,
    PaymentAccuracyModule,
    WprModule,
    MonitoringModule,
    CurrencyMaskModule
  ],
  exports: [
    BpeAppRoutingModule,
  ],
  providers: [QuestionaryResolver]
})

export class BpeAppModule { }