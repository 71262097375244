import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-failure-to-apply-for-potential-income',
  templateUrl: './failure-to-apply-for-potential-income.component.html',
  styleUrls: ['./failure-to-apply-for-potential-income.component.css']
})
export class FailureToApplyForPotentialIncomeComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}