import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-ebt-module',
  templateUrl: './ebt-module.component.html',
  styleUrls: ['./ebt-module.component.css']
})
export class EbtModuleComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
