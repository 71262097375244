import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-abawd-clk',
  templateUrl: './abawd-clk.component.html',
  styleUrls: ['./abawd-clk.component.css']
})
export class AbawdClkComponent extends BaseComponent implements OnInit {
  isNotEditable: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  abawdclk_01a() {
    
    for (let i=1;i<37;i++) {
      let endDate = moment(this.group.controls['abawdclk.01a'].value).add(i-1, 'months');

      let ctrl = 'abawdclk.' + i + 'a';
      this.setControlValue(ctrl, (endDate));
      this.setControlValue('abawdclk.01b',this.getControlValue('abawdclk.36a'))
    }

  }



}
