import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-pa-case-review',
  templateUrl: './pa-case-review.component.html',
  styleUrls: ['./pa-case-review.component.css']
})

export class PaCaseReviewComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}