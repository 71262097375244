import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-secondary-error',
  templateUrl: './secondary-error.component.html',
  styleUrls: ['./secondary-error.component.css']
})
export class SecondaryErrorComponent extends BaseComponent implements OnInit {


  subElements: any = [];

  ngOnInit() {
    super.ngOnInit();
    if( this.getControlValue('887.1')){
      this.changeSubElementByElement('887.2', this.getControlValue('887.1'));
    }
  }

  changeSubElementByElement(lkpNameCd, eCd) {
    this.subElements = this.constantsService.getLookUpByName(this.getLookupNameByQCd(lkpNameCd));
    if (this.subElements.length > 0 && eCd) {
      this.subElements = this.subElements.filter(item => item.parentRef === eCd);
    }
  }
}