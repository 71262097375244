import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-ee-va-benefits',
  templateUrl: './ee-va-benefits.component.html',
  styleUrls: ['./ee-va-benefits.component.css']
})
export class EeVaBenefitsComponent extends BaseComponent implements OnInit {
  currentPage: number = 0;
  isNotEditable: boolean = false;
  
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
}
