import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-other-earned-income',
  templateUrl: './other-earned-income.component.html',
  styleUrls: ['./other-earned-income.component.css']
})

export class OtherEarnedIncomeComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.handle_311_3();
    this.handle_311_4();
  }
  get Is314_3():boolean{
    return this.group.controls['314.3'].value === 'N';
      }

  
  get  Is314_1():boolean{
        return this.group.controls['314.1'].value === 'N';
      }
      get  Is314_4():boolean{
        return this.group.controls['314.4'].value === 'Y';
      }
      get  Is314_4_N():boolean{
        return this.group.controls['314.4'].value === 'N';
      }
      handle_311_3(){
        let q3 = this.group.controls['314.3'].value;
        if(q3 === 'N') {
          this.group.controls['314.6'].disable();
        }
        else {
          this.group.controls['314.6'].enable();
        }
      }
      handle_311_4(){
          let q3 = this.group.controls['314.4'].value;
          if(q3 === 'N') {
            this.group.controls['314.6'].disable();
          }
          else {
            this.group.controls['314.6'].enable();
          }
        }
}