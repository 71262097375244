import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-benefits-previously-recieved',
  templateUrl: './benefits-previously-recieved.component.html',
  styleUrls: ['./benefits-previously-recieved.component.css']
})
export class BenefitsPreviouslyRecievedComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
