import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-total-gross-and-net-income',
  templateUrl: './total-gross-and-net-income.component.html',
  styleUrls: ['./total-gross-and-net-income.component.css']
})
export class TotalGrossAndNetIncomeComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  autofillFields(){
    
  }
}
