import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-cs-customer-pgm-access',
  templateUrl: './cs-customer-pgm-access.component.html',
  styleUrls: ['./cs-customer-pgm-access.component.css']
})
export class CsCustomerPgmAccessComponent extends BaseComponent implements OnInit {
  currentPage: number = 0;
isSpecial = true;
  // isquestionaccepted() {
    
  // }
  hasError = true;
  public meggaseClasses = {
    "text-success": !this.hasError,
    "text-danger":this.hasError
  }
  ngOnInit() {
    super.ngOnInit();
  }
  

}
