import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { QuestionaryComponent } from './../../shared-module/questionary/questionary.component';
import { QuestionaryResolver } from './../../shared-module/questionary/questionary.resolver';
import { CaseAssignmentComponent } from 'src/app/shared-module/case-assignment/case-assignment.component';
import { ReviewCaseListComponent } from 'src/app/shared-module/review-case-list/review-case-list.component';
import { WprDashboardComponent } from './wpr-admin/wpr-dashboard/wpr-dashboard.component';
import { WprSampleComponent } from './wpr-admin/wpr-sample/wpr-sample.component';
import { FsmeScheduleComponent } from 'src/app/shared-module/fsme-schedule/fsme-schedule.component';




const routes: Routes = [
  // {
  //   path: '',
  //   component: PaDashboardComponent
  // },
  
  {
    path: 'wpr-dashboard',
    component: WprDashboardComponent
  },
  {
    path: 'wpr-schedule',
    component: FsmeScheduleComponent
  },
  {
    path: 'wpr-sample',
    component: WprSampleComponent
  },
  {
      path: 'wpr-case-assignment',
      component: CaseAssignmentComponent
  },
  {
    path: 'wpr-case-review-list',
    component: ReviewCaseListComponent
  },
  {
    path: 'questionary-300006',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WPRRoutingModule { }
