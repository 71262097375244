import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiManager } from 'src/app/common/api-manager.service';
import { AppUtilService } from 'src/app/common/app.util.service';
import { ConstantsService } from 'src/app/common/constants.service';
import { CustomValidatorsService } from 'src/app/common/custom-validators.service';
import { NavigationService } from 'src/app/common/navigation.service';
import { saveAs } from 'file-saver/FileSaver';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import { Router } from '@angular/router';
import { ReviewInfoClass } from 'src/app/model/api/review-info.class';

export interface Section {
  name: string;
  updated: Date;
}
@Component({
  selector: 'app-review-documents',
  templateUrl: './review-documents.component.html',
  styleUrls: ['./review-documents.component.css']
})
export class ReviewDocumentsComponent implements OnInit {
  rvwMnthList = [];
  rvwMnthNumList;
  reviewTypeFilter;
  dataSource;
  rvwYear;
  filteredOptions: Observable<string[]>;
  public nameForm: FormGroup;


  constructor(
    public customValidatorsService: CustomValidatorsService,
    private utilService: AppUtilService,
    private apiManager: ApiManager,
    public dialog: MatDialog,
    public constantsService: ConstantsService,
    private fb: FormBuilder,
    public navigationService: NavigationService,
    private router: Router,
    public appUtilService: AppUtilService
  ) {
  }

  ngOnInit() {
    this.buildForm();
    this.valueChanged();

    // this.applyFilter();
    
  }

  buildForm() {
    this.nameForm = this.fb.group({
      samplingMonth: ['', [Validators.required]],
      reviewNumber: [''],
      reviewType:['']
    });
  }
  getReviewNumber() {

  };
  searchReview() {
    const queryParams: any[] = [];

    const reviewDoc = this.nameForm.value;
    this.rvwYear = reviewDoc.samplingMonth.substring(0,4);
    queryParams.push(reviewDoc.samplingMonth.substring(0,4) + reviewDoc.reviewNumber );
    // queryParams.push(reviewDoc.reviewNumber);
    queryParams.push(reviewDoc.reviewType);
    
    this.apiManager.fetchData('getQCDocRepo', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
      .subscribe(res => {
        if (res) {
          this.dataSource = res;
        }
      }
      )
     
  }

  valueChanged() {
    const queryParams: any[] = [];

    const reviewDoc = this.nameForm.value;

    if (reviewDoc.samplingMonth) {
      reviewDoc.samplingMonth = this.appUtilService.changeDateToMonthFormat(reviewDoc.samplingMonth);
      queryParams.push(reviewDoc.samplingMonth);
    } else {
      queryParams.push('All');
    }
    this.apiManager.fetchData('getRvwNumList', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
      .subscribe(
        res => {

          this.rvwMnthNumList = res.sort((a, b) => (+a.rvwNum > +b.rvwNum) ? 1 : -1);
          this.reviewTypeFilter = res;
          const distinctReviewmonth = [...new Set(this.rvwMnthList.map(rG => rG))];
          if (distinctReviewmonth.length === 0) {
            this.rvwMnthList = [];


             res.map(item => {
              if (this.rvwMnthList.filter(fnd => fnd === item.samplemonth).length === 0) {
                this.rvwMnthList.unshift(item.samplemonth);
              }
            })
          }
          this.rvwMnthList.sort(function(a, b){return b-a});
        }
      )

  }

  filterRvwNums(value) {
    this.rvwMnthNumList = this.reviewTypeFilter.filter(item => item['reviewType'] === value).sort((a, b) => (+a.rvwNum > +b.rvwNum) ? 1 : -1);
    this.applyFilter();
  }

  
  view(doc) {
    console.log("download method" + doc.qc_doc_repo_id);
    const queryParams: any[] = [];
    let filename: any;
    filename = doc.tmplt_name;
    queryParams.push(doc.qc_doc_repo_id);
    this.apiManager.fetchData('getDocumentForDownload', undefined, ApiManager.FILEDOWNLOAD, undefined, undefined, undefined, queryParams)
      .subscribe(res => {
        if (res) {

          let blob = new Blob([res], { type: res.type });
          let fileURL = URL.createObjectURL(blob);
          var newWin = window.open(fileURL);
        }
      }
      );
  }

  fileUpload() {
    let rvwnum = this.nameForm.value.reviewNumber;
    if (rvwnum.length <= 6) {
      rvwnum = this.rvwYear + rvwnum;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      reviewNum: rvwnum,
      fnsNames: true,
      screenType:"RD",
      reviewType : this.nameForm.value.reviewType
      // pageType: type,
      // individualId: this.currentIndividual,
      // parentPageType: this.screenType,
    };


    const dialogRef = this.dialog.open(FileUploadComponent, dialogConfig);
    dialogRef.updateSize('75%', '85%');
    dialogRef.afterClosed().subscribe(val => {
      // if (val) {
      setTimeout(() => {
        this.searchReview();
      }, 2000);

      console.log('afterClose', val);


    });
  }

  applyFilter() {
    this.filteredOptions  = this.nameForm.get('reviewNumber').valueChanges
    .pipe(
      startWith(''),map(value => typeof value === 'string' ? value : value.rvwNum),
      map(name => this.workerFilter(name))
    );
}

    

  private workerFilter(name: string) {
    const filterValue = name;
    return this.rvwMnthNumList.filter(option => option.rvwNum.substr(4).indexOf(filterValue) === 0);
  }

  fileScan(){
    this.router.navigate(['/qualityControl/fileScan'], { state : {fnsNames: true,reviewType: this.nameForm.get("reviewType") ? this.nameForm.get("reviewType").value : "",
       reviewNum:  this.nameForm.get("reviewNumber") ? this.nameForm.get("reviewNumber").value : "" }});
  }

  download(row){
    console.log("download method"+row.qc_doc_repo_id);
    const queryParams: any[] = [];
    let filename: any;
    filename = row.tmplt_name;
    queryParams.push(row.qc_doc_repo_id);
      this.apiManager.fetchData('getDocumentForDownload', undefined, ApiManager.FILEDOWNLOAD, undefined, undefined, undefined, queryParams)
      .subscribe(res => {
        if (res) {
         let blob;
         blob = new Blob([res], { type: 'application/pdf' });
         saveAs(blob, filename);
        }
      }
    );
   }


}
