import { Component, OnInit } from '@angular/core';
import { AppUtilService } from 'src/app/common/app.util.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-schedule380',
  templateUrl: './schedule380.component.html',
  styleUrls: ['./schedule380.component.css']
})
export class Schedule380Component extends BaseComponent implements OnInit {

isNotEditable: boolean =false
  ngOnInit() {
    super.ngOnInit();
    const valueQs7 = this.getControlValue('380-1.4.7');
    const valueQs8 = this.getControlValue('380-1.4.8');


    
    if(valueQs8 === '4' ){
      this.setControlValue('380-1.4.25','0');
      this.setControlValue('380-1.4.44a','1');
    this.setControlValue('380-1.4.44b','');
      this.setControlValue('380-1.4.45','0');
    }
    //  Sched Logic for field 25
    if(valueQs7 === '2' || valueQs7 === '3'){
      this.group.get('FormArray')['controls'].splice(0);
      this.setControlValue('380-1.4.25','');
    }
    this.group.get('FormArray')['controls'].map(
      (item, index) => {
        if(item.controls['380-1.4.46'].value === null) {
          item.controls['380-1.4.46'].patchValue(index+1);
        }
       
        if(item.controls['380-1.4.59'].value === null) {
          item.controls['380-1.4.59'].patchValue(index+1);
        } 
      
        //Commenting to check the income 
        
        const valueQs61 = item.controls['380-1.4.61'].value;
        const valueQs63 = item.controls['380-1.4.63'].value;
        const valueQs65 = item.controls['380-1.4.65'].value;
        const valueQs67 = item.controls['380-1.4.67'].value;
        if (valueQs61 === '0' || valueQs61 === null ) {
          item.controls['380-1.4.61'].value = '';
          item.controls['380-1.4.60'].value = '';
        }
        if (valueQs63 === '0' || valueQs61 === null) {
          item.controls['380-1.4.63'].value = '';
          item.controls['380-1.4.62'].value = '';
        }
        if (valueQs65 === '0' || valueQs61 === null) {
          item.controls['380-1.4.65'].value = '';
          item.controls['380-1.4.64'].value = '';
        }
        if (valueQs67 === '0' || valueQs61 === null) {
          item.controls['380-1.4.67'].value = '';
          item.controls['380-1.4.66'].value = '';
        }
        });
    this.determineAccess();
  }
  
  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/qualityControl/questionary-380-1.4?type=380-1.4'] != null && ref.accessMap['/qualityControl/questionary-380-1.4?type=380-1.4'].accessMode != 'rw')) {
       
      this.isNotEditable = true;
   
  }
  //making it false to unlock screens for  analyst 
  if(this.constantsService.isAnalystUser()){ 
    this.isNotEditable = false;
  }
 }
}