import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ApiManager } from 'src/app/common/api-manager.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-earned-income-employment-questios-part2',
  templateUrl: './earned-income-employment-questios-part2.component.html',
  styleUrls: ['./earned-income-employment-questios-part2.component.css']
})

export class EarnedIncomeEmploymentQuestiosPart2Component extends BaseComponent implements OnInit {
  
  @Input() group: FormGroup;
  
  currentPage: number = 0;
  isNotEditable: boolean = false;
  addEmployer: boolean = false;
  disable_311_2_10:boolean = false;
  disable_311_2_9:boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    if(this.group.get('FormArray')['controls']){
      this.group.get('FormArray')['controls'].map(s=>{
      this.changeQ311_2_1(s, false);
      this.changeQ311_2_2(s,false);
    });

  }
  }
  

 
  handleEmployer(){
    this.addEmployer = true;
  }
  saveEmployerInfo() {
    
    this.utilService.showLoader();
    const queryParams: any = [];
    let indvId = this.shareService.getDataFromMap('currentIndividual');
    let rvwNum = this.constantsService.ReviewInfo.rvwNum;
    // const distinctRecordGroup = [...new Set(this.field.map(rG => rG.recordGroupId))];
    let objBody = {
      indvId: indvId,
      rvwNum: rvwNum,
      screenId:this.screenType,
    //   rcdGrpId:   distinctRecordGroup[j],
    //   ernIncId:this.group.get('FormArray').value[0]['311.2.0'],
    //   emplrNm:this.group.get('FormArray').value[0]['311.2.3a'],
    //  // dirtyStatus:,
    //   qcAnswer:this.group.get('FormArray').value[0]['311.2.3b'],
    //   emplrZip:this.group.get('FormArray').value[0]['311.2.3f'],

    //    emplrCty:this.group.get('FormArray').value[0]['311.2.3e'],
    //    emplrSt:this.group.get('FormArray').value[0]['311.2.3d'],
    //   emplrAdd2:this.group.get('FormArray').value[0]['311.2.3c'],
    //    emplrAdd1:this.group.get('FormArray').value[0]['311.2.3b'],
    //    empInd:"Y"
    }
    this.apiManager.fetchData('saveEmployerInfo', undefined, ApiManager.POST, objBody, undefined, undefined, queryParams)
      .subscribe(
        res => {
          console.log(res);
          this.callSave.emit(undefined);
          this.utilService.hideLoader();
        }
      );


  }

  
  clearInputMethod(arrayControl, j) {
   
    const queryParams: any = [];
   
    // const distinctRecordGroup = [...new Set(this.field.map(rG => rG.recordGroupId))];
    const distinctRecordGroup = [...new Set(this.field.map(rG => rG.recordGroupId))];
    const deleteEmplrObj = {
      ernIncId: arrayControl.controls['311.2.0'].value,
      
      rcdGrpId: distinctRecordGroup[j],
      rvwNum: this.constantsService.ReviewInfo.rvwNum,
      indvId: this.shareService.getDataFromMap('currentIndividual'),
      screenId: this.screenType
    };
//  this.reset();

    this.apiManager.fetchData('deleteEmployerInfo', undefined, ApiManager.POST, deleteEmplrObj, undefined, undefined, queryParams)
    .subscribe(
      res => {
        console.log(res);
        // this.group.get('FormArray')['controls'].length = this.group.get('FormArray')['controls'].length - 1;
        // delete this.group.value.FormArray[j];
        // this.group.reset();
        this.callGet.emit(undefined);
        // setTimeout(() => {
        //   this.callGet.emit(undefined);
        // }, 2000);

     
      }
    );



    

   }

   reset() {
    const caseId: any = '';
    const invId: any = this.currentIndividual; // this.memberForm.get('individualId').value;
    const queryParams: any[] = [];
    queryParams.push(this.screenType); //screen type

    if (invId) {
      queryParams.push(invId);
    }


    if (this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }

    if (this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwType) {
      if (!this.screenType.startsWith('5001')) {
        queryParams.push(this.constantsService.ReviewInfo.rvwType);
      } else {
        queryParams.push('QCForm');
      }

    } else {
      queryParams.push('Evaluation');
    }

    this.apiManager.fetchData('getResetReviewQ', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
      .subscribe(
        res => {
          // this.questionarypreparePageData(res);
          console.log(res);
          // this.questionary.preparePageData(res);

        }
      );


  }





   



  // clearInputMethod() { cntls.reset() ; }
  
  changeQ311_2_1(val,src) {

    if (val.controls["311.2.1"].value == true || val.controls["311.2.1"].value == "true") {
      
      this.disableforQ311_2_4(true,val);
      val.controls['311.2.11'].disable();
      this.disable_311_2_10 = true;
      val.controls['311.2.3a'].patchValue('');
    } else {
      
      this.disableforQ311_2_4(false,val);
      val.controls['311.2.11'].enable();
      this.disable_311_2_10 = false;
      // val.controls['311.2.3a'].patchValue('');

    }
  //   if(!src){
  //  this.changeQ311_2_2(val,true);
  //   }
  }

  changeQ311_2_2(val,src) {

    if (val.controls["311.2.2"].value == true || val.controls["311.2.2"].value == "true") {
      this.disableforQ311_2_4(true,val);
    } else {
      this.disableforQ311_2_4(false,val);
    }
    if(!src){
      this.changeQ311_2_1(val,true);
    }
  }
  disableforQ311_2_4(val,cntls) {
    if (val) {
      cntls.controls['311.2.4'].disable();
      cntls.controls['311.2.5'].disable();
      cntls.controls['311.2.6'].disable();
      cntls.controls['311.2.7'].disable();
      cntls.controls['311.2.8'].disable();
      cntls.controls['311.2.9'].disable();
      cntls.controls['311.2.10'].disable();
    } else {
      cntls.controls['311.2.4'].enable();
      cntls.controls['311.2.5'].enable();
      cntls.controls['311.2.6'].enable();
      cntls.controls['311.2.7'].enable();
      cntls.controls['311.2.8'].enable();
      cntls.controls['311.2.9'].enable();
      cntls.controls['311.2.10'].enable();
    }

 }
 changeof312_9(event:any){
  if(event.value === 'N') {
    this.disable_311_2_9 = true;
  }else if(event.value === 'Y'){
    this.disable_311_2_9 = false;
  }
}
}


