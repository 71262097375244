import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-ssn',
  templateUrl: './ssn.component.html',
  styleUrls: ['./ssn.component.css']
})

export class SsnComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;
 
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    // if(this.getControlValue('')){
    //   this.
    // }
  }


  change170_1(ref:any) {
    if (ref.checked) {   
      
      this.setControlValue('170.1',this.getControlValue('170.01'));
    } else {
      this.group.controls['170.1'].enable();
      this.setControlValue('170.1',null);
    }
  }
  change_1(){
    this.setControlValue('170.1a',null);
  }

  
  change170_2(ref1:any) {
    if (ref1.checked) {  
      this.setControlValue('170.2',this.getControlValue('170.01'));
    } else {
      this.group.controls['170.2'].enable();
      this.setControlValue('170.2',null);
    }
  }
  change_2(){
    this.setControlValue('170.2a',null);
  }


  // determineAccess() {
  //   const ref = this.constantsService.getSecurityObject();

  //   if ((ref.accessMap['/qualityControl/questionary-Resdcy?type=140'] != null && ref.accessMap['/qualityControl/questionary-Resdcy?type=140'].accessMode != 'rw')) {
  //       this.isNotEditable = true;
  //   }
  // }
  handleQ170_7(val){
    if(val === '99'){
      this.group.controls['170.7a'].enable();
    }else{
      this.group.controls['170.7a'].disable();
    }
}
}