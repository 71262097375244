import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-rankthe-error',
  templateUrl: './rankthe-error.component.html',
  styleUrls: ['./rankthe-error.component.css']
})
export class RanktheErrorComponent extends BaseComponent implements OnInit {
isNotEditable: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    if(this.group.get('FormArray')['controls'].length == 1) {
      this.group.get('FormArray')['controls'][0]['controls']['380-1.1.1'].patchValue('1');
    }
  }

}
