import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import {
  FieldConfig,
  Validator,
  PageConfig,
} from "../../components/field.interface";
import { AppUtilService } from "../../common/app.util.service";
import { ConstantsService } from "src/app/common/constants.service";

@Component({
  exportAs: "dynamicForm",
  selector: "dynamic-form",
  styleUrls: ["./dynamic-form.component.css"],
  template: `
    <form class="dynamic-form" [formGroup]="form">
      <div id="print-section" print-section>
        <div class="col-sm-12 col-md-12 content-box">
          <div class="row" *ngFor="let pg of page">
            <ng-container
              dynamicField
              [page]="pg"
              [openDialog]="openDialog"
              [group]="form"
              [callGet]="callGet"
              [callSave]="callSave"
              [callGenerateSpanishPDF]="callGenerateSpanishPDF"
            >
            </ng-container>
          </div>
          <div
            class="mt-5 justifyCenter alignCenter"
            [hidden]="constantsService.ReviewInfo.rvwType === 'FSPNegative'"
          >
            <div class="checkboxGreen">
              <mat-checkbox
                formControlName="777"
                [value]="form?.controls['777']?.value"
                (change)="form?.controls['777']?.setValue($event.checked)"
                [disabled]="form?.controls['777']?.disabled"
                ><b>Complete</b></mat-checkbox
              >
            </div>
          </div>
        </div>
      </div>
    </form>
  `,
  styles: [],
})
export class DynamicFormComponent implements OnInit, OnChanges {
  @Output() callGet: EventEmitter<any> = new EventEmitter<any>();
  @Output() callSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() callGenerateSpanishPDF: EventEmitter<any> = new EventEmitter<any>();
  @Input() page: PageConfig[] = [];
  @Input() fields: FieldConfig[] = [];
  @Input() public openDialog: any;
  @Input() indvdlId: any;

  public form: FormGroup;

  get value() {
    return this.form.value;
  }
  constructor(
    public appUtilService: AppUtilService,
    private fb: FormBuilder,
    public constantsService: ConstantsService
  ) {}

  ngOnInit() {
    this.form = this.createControl();
  }

  ngOnChanges() {
    console.log(this.fields);
    console.log(this.page);
    this.form = this.createControl();
  }

  onSubmit(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.form.valid) {
      this.callSave.emit(this.form.value);
    } else {
      this.validateAllFormFields(this.form);
    }
  }

  createControl() {
    const group = this.fb.group({});

    //   this.fields.push(

    //     {
    //       "recordGroupId":0,
    //       "recordId": 130,
    //       "name": "110.8",
    //       "value": null,
    //       "dirtyStatus": null
    //     }
    // );

    //   this.fields.push(
    //       {
    //         "recordGroupId":1,
    //         "recordId": 131,
    //         "name": "110.8",
    //         "value": null,
    //         "dirtyStatus": null
    //       }

    //   );

    const distinctRecordGroup = [
      ...new Set(this.fields.map((rG) => rG.recordGroupId)),
    ];

    if (distinctRecordGroup.length > 0) {
      const controlFGs = [];
      const controlFAs = [];

      distinctRecordGroup.forEach((rG) => {
        if (rG !== undefined && rG > -1) {
          const faFG = this.fb.group(rG);
          this.fields
            .filter((fltRG) => fltRG.recordGroupId === rG)
            .map((fldCfg) => {
              const faFGCtrl = this.fb.control(
                this.getValue(fldCfg),
                this.bindValidations(fldCfg.validations || [])
              );
              faFG.addControl(fldCfg.name, faFGCtrl);
            });
          controlFAs.push(faFG);
        }
      });
      const FAGcontrol = this.fb.array(controlFAs);
      group.addControl("FormArray", FAGcontrol);
    }

    this.fields.forEach((field) => {
      if (field.type === "button") return;
      if (field.recordGroupId === -1) {
        const control = this.fb.control(
          this.getValue(field),
          this.bindValidations(field.validations || [])
        );
        control.updateValueAndValidity();
        group.addControl(field.name, control);
      }
    });
    group.addControl("777", this.fb.control(false));
    group.updateValueAndValidity();
    return group;
  }

  getValue(fldCnf: FieldConfig) {
    let val;
    if (fldCnf.type === "date" && fldCnf.value) {
      val = new Date(fldCnf.value);
      val = this.appUtilService.changeDateFormat(val);
    } else {
      val = fldCnf.value;
    }
    return val;
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach((valid) => {
        validList.push(valid.validator);
      });
      return Validators.compose(validList);
    }
    return null;
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }
}
