import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-incomeon-self-employed-household-members',
  templateUrl: './incomeon-self-employed-household-members.component.html',
  styleUrls: ['./incomeon-self-employed-household-members.component.css']
})
export class IncomeonSelfEmployedHouseholdMembersComponent extends BaseComponent implements OnInit {

isNotEditable : boolean= false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

}