import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-qc16-concluding-page',
  templateUrl: './qc16-concluding-page.component.html',
  styleUrls: ['./qc16-concluding-page.component.css']
})

export class Qc16ConcludingPageComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/qualityControl/questionary-500015?type=500015'] != null && ref.accessMap['/qualityControl/questionary-500015?type=500015'].accessMode != 'rw')) {
        this.isNotEditable = true;
    }
  }
}