import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-sves-prison-match',
  templateUrl: './sves-prison-match.component.html',
  styleUrls: ['./sves-prison-match.component.css']
})
export class SvesPrisonMatchComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
