import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-child-support-deductions',
  templateUrl: './child-support-deductions.component.html',
  styleUrls: ['./child-support-deductions.component.css']
})
export class ChildSupportDeductionsComponent extends BaseComponent implements OnInit {


  isNotEditable: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  handle366_1(val) {
    if (val === 'N') {
      this.group.controls['366.2'].disable();
      this.group.controls['366.3'].disable();
      this.group.controls['366.4'].disable();
      this.group.controls['366.5'].disable();
      this.group.controls['366.6'].disable();
      this.group.controls['366.7'].disable();
      this.group.controls['366.8'].disable();
      this.group.controls['366.9.1'].disable();
      this.group.controls['366.9.2a'].disable();
      this.group.controls['366.9.2b'].disable();
      this.group.controls['366.9.3'].disable();
      this.group.controls['366.10'].disable();
      this.group.controls['366.11'].disable();
    }
    else if (val === 'Y') {
      this.group.controls['366.2'].enable();
      this.group.controls['366.3'].enable();
      this.group.controls['366.4'].enable();
      this.group.controls['366.5'].enable();
      this.group.controls['366.6'].enable();
      this.group.controls['366.7'].enable();
      this.group.controls['366.8'].enable();
      this.group.controls['366.9.1'].enable();
      this.group.controls['366.9.2a'].enable();
      this.group.controls['366.9.2b'].enable();
      this.group.controls['366.9.3'].enable();
      this.group.controls['366.10'].enable();
      this.group.controls['366.11'].enable();
    }
  }
  handle366_2(val) {
    if (val === 'Y') {
      this.group.controls['366.3'].disable();
      this.group.controls['366.4'].disable();
      this.group.controls['366.5'].disable();
      this.group.controls['366.6'].disable();
      this.group.controls['366.7'].disable();
      this.group.controls['366.8'].disable();
      this.group.controls['366.9.1'].disable();
      this.group.controls['366.9.2a'].disable();
      this.group.controls['366.9.2b'].disable();
      this.group.controls['366.9.3'].disable();
      this.group.controls['366.10'].disable();
      this.group.controls['366.11'].disable();
    }
    else if (val === 'N') {
      this.group.controls['366.3'].enable();
      this.group.controls['366.4'].enable();
      this.group.controls['366.5'].enable();
      this.group.controls['366.6'].enable();
      this.group.controls['366.7'].enable();
      this.group.controls['366.8'].enable();
      this.group.controls['366.9.1'].enable();
      this.group.controls['366.9.2a'].enable();
      this.group.controls['366.9.2b'].enable();
      this.group.controls['366.9.3'].enable();
      this.group.controls['366.10'].enable();
      this.group.controls['366.11'].enable();
    }
  }
  handle366_3(val) {
    if (val === 'N') {
      this.group.controls['366.4'].disable();
      this.group.controls['366.5'].disable();
      this.group.controls['366.6'].disable();
      this.group.controls['366.7'].disable();
      this.group.controls['366.8'].disable();
      this.group.controls['366.9.1'].disable();
      this.group.controls['366.9.2a'].disable();
      this.group.controls['366.9.2b'].disable();
      this.group.controls['366.9.3'].disable();
      this.group.controls['366.10'].disable();
      this.group.controls['366.11'].disable();
    }
    else if (val === 'Y') {
      this.group.controls['366.4'].enable();
      this.group.controls['366.5'].enable();
      this.group.controls['366.6'].enable();
      this.group.controls['366.7'].enable();
      this.group.controls['366.8'].enable();
      this.group.controls['366.9.1'].enable();
      this.group.controls['366.9.2a'].enable();
      this.group.controls['366.9.2b'].enable();
      this.group.controls['366.9.3'].enable();
      this.group.controls['366.10'].enable();
      this.group.controls['366.11'].enable();
    }
  }

  handle366_4(val) {
    if (val === '1' || val === '2' || val === '3' || val === '4' || val === '5' || val === '6' || val === '7') {

      this.group.controls['366.5'].disable();
      this.group.controls['366.6'].disable();
      this.group.controls['366.7'].disable();
      this.group.controls['366.8'].disable();
      this.group.controls['366.9.1'].disable();
      this.group.controls['366.9.2a'].disable();
      this.group.controls['366.9.2b'].disable();
      this.group.controls['366.9.3'].disable();
      this.group.controls['366.10'].disable();
      this.group.controls['366.11'].disable();
    }
    else {
      this.group.controls['366.5'].enable();
      this.group.controls['366.6'].enable();
      this.group.controls['366.7'].enable();
      this.group.controls['366.8'].enable();
      this.group.controls['366.9.1'].enable();
      this.group.controls['366.9.2a'].enable();
      this.group.controls['366.9.2b'].enable();
      this.group.controls['366.9.3'].enable();
      this.group.controls['366.10'].enable();
      this.group.controls['366.11'].enable();
    }
  }

  handle366_10(val) {
    if (val === 'N') {
      this.group.controls['366.11'].disable();
    }
    else {
      this.group.controls['366.11'].enable();
    }
  }
  

  change366_11_17_ver(val){
    this.setControlValue('366.11',val);
    this.setControlValue('366.17',val);
  }

}