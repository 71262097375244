import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-became-employed',
  templateUrl: './became-employed.component.html',
  styleUrls: ['./became-employed.component.css']
})
export class BecameEmployedComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
