import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ViewChild } from "@angular/core";
import * as Highcharts from "highcharts";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiManager } from 'src/app/common/api-manager.service';
import { FsmeScheduleComponent } from '../../../../shared-module/fsme-schedule/fsme-schedule.component';
import { analyzeAndValidateNgModules, ThrowStmt } from '@angular/compiler';
import { ConstantsService } from 'src/app/common/constants.service';
import { NavigationService } from 'src/app/common/navigation.service';
import { CaseCountViewComponent } from 'src/app/shared-module/case-count-view/case-count-view.component';

@Component({
  selector: 'app-fsme-dashboard',
  templateUrl: './fsme-dashboard.component.html',
  styleUrls: ['./fsme-dashboard.component.css']
})

export class FsmeDashboardComponent implements OnInit {

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  chart;
  updateFlag = false;
  isNotEditable: boolean = false;
  //  setTimeout(()=>{  },200)  
  Highcharts = Highcharts;
  chartConstructor = "chart";
  chartCallback;
  chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      // Edit chart spacing
      spacingBottom: 15,
      spacingTop: 10,
      spacingLeft: 10,
      spacingRight: 10,
      // Explicitly tell the width and height of a chart
      width: 290,
      height: 500
    },
    title: {
      text: "Sampling Cases"
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    series: [
      {
        type: "pie",
        data: [
        ]
      }
    ],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 600
        }
      }]
    }
  };
  labelmap: Map<string, string> = new Map<string, string>();
  labeldatamap: Map<string, string> = new Map<string, string>();
  map: Map<any, any> = new Map();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  keys: Set<string> = new Set();
  sliced = {};
  data: any;
  today;

  constructor(
    private readonly router: Router,
    private httpClient: HttpClient,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    public navigationService: NavigationService,
    public dialog: MatDialog,
  ) {
    //labels mapping
    this.today = new Date().toUTCString();
    this.labelmap.set("fsmeAprvdCnt", "FSME_FS_APPS_FILED");
    this.labelmap.set("fsmeRcpntClms", "FSME_RECIPIENT_CLAIMS");
    this.labelmap.set("fsmeClsdCases", "FSME_FS_CLSD_CASES");
    this.labelmap.set("fsmeDndCnt", "FSME_FSP_DENIALS");
    this.labelmap.set("fsmeSvesPrstnMatch", "FSME_SVES_PRISON_MATCH");
    this.labelmap.set("fsmeRcpntIntgrty", "FSME_RECIPIENT_INTEGRITY");
     this.labelmap.set("fsmeAbawdActives", "FSME_ABAWD_ACTV_CASES");
    this.labelmap.set("fsmeAbawdClsd", "FSME_ABAWD_CLSD");
    this.labelmap.set("fsmeDeathMatchAlerts", "FSME_DEATH_MATCH_ALERTS");
    this.labelmap.set("fsmeFspPreRvwCases", "FSME_FSP_PRE_RVW_CASES");
    this.labelmap.set("fsmeEbtMltplCases", "FSME_EBT_MLTPL_CASES");
     this.labelmap.set("fsmeRedetCases", "FSME_REDET_CASES");
     this.labelmap.set("fsmeMyDhrCases", "FSME_MY_DHR_CASES");


     // label map
     this.labeldatamap.set("FSME_FS_APPS_FILED", "SNAP Apps Filed");
     this.labeldatamap.set("FSME_RECIPIENT_CLAIMS", "Recipient Claims");
     this.labeldatamap.set("FSME_FS_CLSD_CASES", "Closings");
     this.labeldatamap.set("FSME_FSP_DENIALS", "FSP Denials");
     this.labeldatamap.set("FSME_SVES_PRISON_MATCH", "SVES Prison Match");
     this.labeldatamap.set("FSME_RECIPIENT_INTEGRITY", "Recipient Integrity");
     this.labeldatamap.set("FSME_ABAWD_CLSD", "ABAWD Closed");
     this.labeldatamap.set("FSME_DEATH_MATCH_ALERTS", "Death Match Alerts");
     this.labeldatamap.set("FSME_FSP_PRE_RVW_CASES", "Pre Review Cases");
     this.labeldatamap.set("FSME_EBT_MLTPL_CASES", "EBT Multiple Issuances");
     this.labeldatamap.set("FSME_ABAWD_ACTV_CASES", "ABAWD Active");
     this.labeldatamap.set("FSME_REDET_CASES", "REDET Cases");
     this.labeldatamap.set("FSME_MY_DHR_CASES", "My DHR Cases");

     
   
  
    fsmeEbtMltplCases: 0
    // this.labelMap.set("maCount", "MA");

    const self = this;
    this.chartCallback = chart => {
      // saving chart reference
      self.chart = chart;
    };
  }
  slicedData;
  temp = [];
  dataSource = new MatTableDataSource();
  errordataSource = new MatTableDataSource();
  displayedColumns = ["source", "case", "assigned", "unassigned"];
  errordisplayedColumns = ["programName", "totalCases", "errorCount", "correctCount"];

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.errordataSource.paginator = this.paginator;
    this.errordataSource.sort = this.sort;
  }

  populateData() {
    //get call for Table
    // this.httpClient.get(this.cases_data).subscribe(result => {
    const queryParams: any[] = [];
    queryParams.push('BPE-FSME');

    this.apiManager.fetchData('getRtModelDashBoardData', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
      .subscribe(
        res => {
          if (res) {
            this.data = res;
            this.dataSource.data = [];
            this.errordataSource.data = [];


            //vertical Table code starts
            this.data.forEach(row => {
              Object.keys(row).forEach(key => {
                this.keys.add(key);
              });
              let temp: any = {};
              this.keys.forEach(key => {
                temp[key] = row[key];
              });
            });
            Array.from(this.keys)
              .sort((a, b) => b.localeCompare(a))
              .forEach(key => {
                let temp: any = {};
                if (key == "type") {
                  return;
                }
                temp.source = key;
                this.data.forEach(row => {
                  if (this.labelmap.get(key)) { //skip QC counts
                    temp[row.type] = row[key];
                  }
                });
                if (this.labelmap.get(key)) { //skip QC counts
                  this.map.set(temp.source, temp);
                  this.dataSource.data.push(temp);
                  this.errordataSource.data.push(temp);
                }

              });
          }
          this.dataSource.data = this.dataSource.data.sort();
          this.errordataSource.data = this.errordataSource.data.sort();

        });
  }

  ngOnInit() {
    //method of table 2

    this.populateData();
    // this.getErrorRequest().subscribe(data => {
    //   if (data) {
    //     this.errordataSource = data;
    //     this.paoverallGraph(this.errordataSource);
    //   }
    // });

    this.sendGetRequest().subscribe(data => {
      if (data) {
        this.sliced = Object.keys(data)
          .slice(0, 5)
          .reduce((result, key) => {
            result[key] = data[key];
            return result;
          }, {});
        var ctr = 0;
        Object.keys(this.sliced[0]).forEach(key => {
          if (key != "type") {
            var tmp = {};
            tmp["name"] = this.labeldatamap.get(this.labelmap.get(key));
            tmp["y"] = parseInt(this.sliced[0][key]);
            if (this.labelmap.get(key)) {
              this.temp.push(tmp);
            }
            ctr = ctr + 1;
            this.updateChart();
          }
        });
      }
    });

    console.log(this.temp);
    this.updateChart();
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

      if ((ref.accessMap[''] != null && ref.accessMap[''].accessMode != 'rw')) {
        this.isNotEditable = true;
      }
  }

  updateChart() {
    const self = this,
      chart = this.chart;
    setTimeout(() => {
      self.chartOptions.series[0].data = this.temp;
      console.log("setting temp....", this.temp);
      self.updateFlag = true;
      // this.paoverallGraph(this.data);
    }, 2000);
  }

  sendGetRequest() {
    let httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    httpHeaders.set(
      "role",
      "cn=EE_SUPUSR,|cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL,|cn=EE_SUPVRS"
    );
    httpHeaders.set("uid", "localUser");
    httpHeaders.set("uniqueid", "99999999999");
    // return this.httpClient.get('getRtDashBoardData', { headers: httpHeaders });
    const queryParams: any[] = [];
    queryParams.push('BPE-FSME');
    return this.apiManager.fetchData('getRtModelDashBoardData', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
  }

  // getErrorRequest() {

  //   var rvwMo =  new Date().getFullYear().toString()+ '0' + (new Date().getMonth() + 1).toString().slice(-2);
   
  //   const queryParams: any[] = [];
  //   queryParams.push('BPE-FSME');
  //   queryParams.push(rvwMo);
  //   return this.apiManager.fetchData('getTotalCasesErrorCases', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
  // }
  
  paoverallGraph(paOverall: any) {
    let rvCases=[];
    paOverall.map(item => {
      rvCases.push({"name": item.programName , "y": item.totalCases})  ;
    });

    setTimeout(()=>{ 
      Highcharts.chart('totalsamples',{
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie',
          width: 299,
          height: 600        
        },
        title: {
          text: 'Reviewed Cases'
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },   
            showInLegend: true
            }
          },
          series: [{
            type:'pie',
            name: 'Total',
            data: rvCases
          }],
        });
      
      },200)
}

dashboardItemNav(type,value) {
  // this.openDialog(type,value);
  switch(type){
    case 'Assigned':
    case 'Cases' :
      case 'discrepanciesCorrect':
        case 'discrepanciesInCorrect':
        case 'discrepanciesTotal': 
      this.router.navigate(['/bpe/fsme/case-review-list']);
        break;
         
    case 'Unassigned':
      this.router.navigate(['/bpe/fsme/fsme-case-assignment']);
        break;
    default:
  }
}

openDialog(action: any, element: any) {
  const dialogRef = this.dialog.open(CaseCountViewComponent, {
    height: "70%",
    width: "80%",
    data: { action: action, source: this.labeldatamap.get(this.labelmap.get(element.source)), title: this.labelmap.get(element.source) }
  });
  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
  });
}

openDiscrepanciesDialog(action: any, element: any) {
 
  let pgmKey;

  this.labelmap.forEach((val, key) => {
    if(val===element.programName || val===element.programTypeCd){
      pgmKey = key;
    } 
  })
 
  const dialogRef = this.dialog.open(CaseCountViewComponent, {
    height: "70%",
    width: "80%",
    data: { action: action, source: pgmKey, title: element.programName }
  });
  dialogRef.afterClosed().subscribe(result => {
    console.log(`Dialog result: ${result}`);
  });
}


next(){
  this.navigationService.navigatePage(this.router.url, 'next');
}
print(): void {
  // let printContents, printMember, printStyle, printLink;
  // printContents = document.getElementById('print-section');
  // printMember = document.getElementById('print-member');
  // printStyle = this.printService.getTagsHtml('style');
  // printLink = this.printService.getTagsHtml('link');
  // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
  // this.printService.printTable = printContents ? printContents.innerHTML : null;
  // this.printService.printStyle = printStyle;
  // this.printService.printLink = printLink;
  // this.printService.print();
  window.print();
}

// convertMonthToOveral(arrayValues:any) {
//   let arValues:[] = arrayValues;
//   let result: number = 0;
//   let arResult = [];
//   arValues.map(val => {
//       result = result + val;
//   })

//   return result;

// }
}