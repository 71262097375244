import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-qc14-bank-verification',
  templateUrl: './qc14-bank-verification.component.html',
  styleUrls: ['./qc14-bank-verification.component.css']
})

export class QC14BankVerificationComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  

  determineAccess() {
      const ref = this.constantsService.getSecurityObject();

      if ((ref.accessMap['/qualityControl/questionary-500104?type=500104'] != null && ref.accessMap['/qualityControl/questionary-500104?type=500104'].accessMode != 'rw')) {
          this.isNotEditable = true;
      }
  }
}