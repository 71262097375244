import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { QuestionaryComponent } from './../../shared-module/questionary/questionary.component';
import { QuestionaryResolver } from './../../shared-module/questionary/questionary.resolver';
import { FspPreReviewCasesComponent } from './fsme/fsp-pre-review-cases/fsp-pre-review-cases.component';
import { FsmeDashboardComponent } from './bpe-admin/fsme-dashboard/fsme-dashboard.component';
import { SnapSampleComponent } from '../../shared-module/snap-sample/snap-sample.component';
import { FsmeCaseAssignmentComponent } from './bpe-admin/fsme-case-assignment/fsme-case-assignment.component';
import { FsmeSupervisoryReviewComponent } from '../../shared-module/fsme-supervisory-review/fsme-supervisory-review.component';
import { ReviewCaseListComponent } from 'src/app/shared-module/review-case-list/review-case-list.component';
import { CaseAssignmentComponent } from 'src/app/shared-module/case-assignment/case-assignment.component';
import { FsmeScheduleComponent } from '../../shared-module/fsme-schedule/fsme-schedule.component';
import { ReviewSummaryComponent } from 'src/app/shared-module/review-summary/review-summary.component';
import { FindRespComponent } from 'src/app/shared-module/find-resp/find-resp.component';
import { FsmeLdssReportComponent } from './fsme-reports/fsme-ldss-report/fsme-ldss-report.component';
import { LdssReviewListComponent } from './interview/ldss-review-list/ldss-review-list.component';
import { LdssReviewCaseListComponent } from './interview/ldss-review-case-list/ldss-review-case-list.component';



const routes: Routes = [
  {
    path: '',
    component: FsmeDashboardComponent
  },
  {
    path: 'fsme-dashboard',
    component: FsmeDashboardComponent
  },
  {
    path: 'snap-sample',
    component: SnapSampleComponent
  },
  {
      path: 'fsme-case-assignment',
      component: CaseAssignmentComponent
  },
  {
      path: 'fsme-supervisory-review',
      component: FsmeSupervisoryReviewComponent
  },
  {
      path: 'fsme-schedule',
      component: FsmeScheduleComponent
  },
  {
    path: 'case-review-list',
    component: ReviewCaseListComponent
  },
  {
    path: 'ldss-review-list',
    component: LdssReviewListComponent
  },
  {
    path: 'ldss-review-case-list',
    component: LdssReviewCaseListComponent
  },
  
  {
    path: 'questionary-100006',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100007',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100008',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100009',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100010',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100011',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100012',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100013',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100014',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100015',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100016',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100017',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100018',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100019',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100020',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'review-summary',
    component: ReviewSummaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100022',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100023',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100024',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100024',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100025',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100026',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100027',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100028',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100029',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100030',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'questionary-100031',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'findings-response',
    component: FindRespComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
  {
    path: 'fsme-ldss-report',
    component: FsmeLdssReportComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },



];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FSMERoutingModule { }
