import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../../common/constants.service';
import { ApiManager } from '../../common/api-manager.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DataShareService } from '../../common/data-share.service';
import { NavigationService } from '../../common/navigation.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormArray } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';
import { TitleStripComponent } from '../../RootFrames/title-strip/title-strip.component';

@Component({
  selector: 'app-nstr',
  templateUrl: './nstr.component.html',
  styleUrls: ['./nstr.component.css']
})

export class NstrComponent extends BaseComponent implements OnInit {
isNotEditable: boolean = false;
isChange_6a_6b: boolean = false;
ischange880_8:boolean = true;
isChange_6a_none:boolean = false;
isChange_7_none:boolean = false;
// isChange_8_none:boolean = false;
disablefor880_3:boolean = false;
disablefor880_8b:boolean = false;
disablefor880_10:boolean = true;
handle_8c:boolean = false;

  ngOnInit() {
    this.changeOnload();
    this.handleQ880_1(this.getControlValue('880.1'));
    this.handleQ880_3(this.getControlValue('880.3'));
    this.handleQ880_4(this.getControlValue('880.4'));
    this.change880_5a();
    // this.handle880_6a();
    this.change880_7(this.getControlValue('880.7'));
    this.handle880_6a_none();
    this.handle880_7_none(); 
    this.change880_10();
   
    // this.change880_8(this.getControlValue('880.8'));
    super.ngOnInit();
    this.determineAccess();
  }

changeOnload(){
  this.group.controls['880.2'].disable();
  this.group.controls['880.3'].disable();
  this.group.controls['880.10'].disable();
  // this.group.controls['880.4'].disable();
  // this.handle880_7_none();
}

handleQ880_1(val){
  if(val === 'A' || val === 'D'|| val === 'E' || val === 'F'|| val === 'G'|| val === 'H'|| val === 'I'){
    this.group.controls['880.2'].disable();
    this.group.controls['880.3'].enable();
    this.group.controls['880.4'].disable();
  }
  if(val === 'B'||val === 'C'){
    this.group.controls['880.2'].enable();
    this.group.controls['880.3'].enable();
    this.group.controls['880.4'].disable();
  }
  if(val === 'J'){
    this.group.controls['880.2'].disable();
    this.group.controls['880.3'].disable();
    this.group.controls['880.4'].enable();
  }  
}



handleQ880_3(val){
  
  if(val && val  !== '8' ){

    this.group.controls['880.4'].disable();
    this.group.controls['880.5a'].disable();
    this.group.controls['880.5b'].disable();
    this.group.controls['880.6b'].disable();
    this.group.controls['880.6c'].disable();
    this.group.controls['880.7'].disable();
    this.group.controls['880.8'].disable();
    this.group.controls['880.9'].disable();
    this.setValue880_9a('NSTR');
    this.isChange_6a_6b = true;
    // this.disablefor880_3 = true;
    
  }
  else  if(val === '8'){
    this.group.controls['880.4'].enable();
    this.group.controls['880.5a'].enable();
    this.group.controls['880.5b'].enable();
    this.group.controls['880.6b'].enable();
    this.group.controls['880.6c'].enable();
    this.group.controls['880.7'].enable();
    this.group.controls['880.8'].enable();
    this.group.controls['880.9'].enable();
    this.setValue880_9a('');
    this.isChange_6a_6b = false;
    // this.disablefor880_3 = false;
  }
}

handleQ880_4(val){
  if(val === 'Y'){
    this.group.controls['880.5a'].disable();
    this.group.controls['880.5b'].disable();
    this.group.controls['880.6b'].disable();
    this.group.controls['880.6c'].disable();

  }
  else  if(val === 'N'){
    this.group.controls['880.5a'].enable();
    this.group.controls['880.5b'].enable();
    this.group.controls['880.6b'].enable();
    this.group.controls['880.6c'].enable();

  }


}

// get Is880_3(): boolean {
//   return this.group.controls['880.3'].value === '8';
// }

change880_5a(){
  let q1 = this.group.controls['880.5a'].value;
  let q2 = this.group.controls['880.5b'].value;
  if ( q1 === 'L' || q2 === 'L' ) {
    this.group.controls['880.6b'].disable();
    this.group.controls['880.6c'].disable();
  } else {
    this.group.controls['880.6b'].enable();
    this.group.controls['880.6c'].enable();
  }

}


handle880_6a(){ 
let q1 = this.group.controls['880.6b'].value;
let q2 = this.group.controls['880.6c'].value;
if((q1 === '1' || q1 === '2') && (q2 === '2' || q2 === '1')   ){
  this.setValue880_9a('NSTR');
  this.isChange_6a_6b = true;
  this.group.controls['880.9'].disable();
}

else if( q1 === '3' || q2 === '3' ){
  this.group.controls['880.7'].disable();
  this.group.controls['880.8'].disable();
  this.group.controls['880.9'].disable();
  // this.setControlValue('880.6b','3');
  // this.setControlValue('880.6c','3');
  this.group.controls['880.6c'].patchValue('3');
  this.setValue880_9a('NSTR');
  this.isChange_6a_6b = true;
  // this.disablefor880_3 = true;
}
else if( q1 !== '3' || q2 !== '3'){
  this.group.controls['880.7'].enable();
  this.group.controls['880.8'].enable();
  this.group.controls['880.9'].enable();
  this.setValue880_9a('');
  this.isChange_6a_6b = false;
  // this.disablefor880_3 = false;
}

}



handle880_6a_none(){
  let q1 = this.group.controls['880.6b'].value;
let q2 = this.group.controls['880.6c'].value;
// this.isChange_6a = true;
if(q1 && q2){


if( (q1 === '6' || q2 === '6')){
  this.isChange_6a_none = false;
  this.group.controls['880.9'].disable();
  this.setValue880_9a('NSTR');
  
}
else if( (q1 !== '6' || q2 !== '6') ){
  this.setValue880_9a('');
  this.group.controls['880.9'].enable();
  this.isChange_6a_none = false;

}
else if( (q1 === '9' || q2 === '9')){
  this.setValue880_9a('');
  this.group.controls['880.9'].enable();
  this.isChange_6a_none = true;
}
}

}

  change880_6b(val) {
    if ( (val === '3') || (val !== '6' && this.group.value['880.6c'] !== '6' ) ) {
      // 3-Collateral contact statement, 6-NONE
      this.setValue880_9a('NSTR');
      this.group.controls['880.9'].disable();
      this.disablefor880_3 = true;
    } else if ( !((val === '3') || (val !== '6' && this.group.value['880.6c'] !== '6' )) ) {
      this.setValue880_9a('');
      this.group.controls['880.9'].enable();
      this.disablefor880_3 = false;
    }

  }

  change880_6c(val) {
    if ( (this.group.value['880.6b'] === '3') || (this.group.value['880.6b'] !== '6' && val !== '6' ) ) {
      // 3-Collateral contact statement, 6-NONE
      this.setValue880_9a('NSTR');
      this.disablefor880_3 = true;
      this.group.controls['880.9'].disable();
    } else {
      this.setValue880_9a('');
      this.disablefor880_3 = true;
      this.group.controls['880.9'].enable();
    }

  }

  change880_7(val) {
    if(val){

    if ( val !== 'K' ) {  // K-NONE
      this.setValue880_9a('Incomplete');
      // this.isChange_7_none = true;
      this.handle_8c = true;
      this.group.controls['880.9'].enable();
    } else {
      this.setValue880_9a('');
      // this.isChange_7_none = false;
      this.handle_8c = false;
      this.group.controls['880.9'].enable();
    }
  }
  }
  handle880_7_none(){
    // k-None
    let q1 = this.group.controls['880.7'].value;
    if(q1){
      if( q1 !== 'K' ){
        
        this.group.controls['880.8'].disable();
        this.group.controls['880.9'].enable();
        this.setValue880_9a('Incomplete');
        this.handle_8c = true;
  
      }
      else   if( q1 === 'K' ){
        this.isChange_7_none = false;
        this.group.controls['880.8'].enable();
      //  this.group.controls['880.9'].disable();
        this.setValue880_9a('');
  
      }
    }
   
  }

  change880_8(val) {
    if ( val !== '8' ) {  // 8-NONE  
      this.disablefor880_3 = true;
      this.setValue880_9a('Incomplete');
      this.group.controls['880.9'].enable();

    } else {
      this.disablefor880_3 = false;
      this.setValue880_9a('');
      this.group.controls['880.9'].enable();
    }

  }
  

  setValue880_9a(val) {
    this.group.controls['880.9a'].patchValue(val);
    this.constantsService.nstr9a = val;
  }

  change880_10() {
    if(this.group.controls['880.9'].value === 'Y') {
      this.group.controls['880.10'].enable();
      this.setValue880_9a('');
    }
    else
      this.group.controls['880.10'].disable();
  }

  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }


  openProcessLogDialog() {
    this.dialog.closeAll();
    this.constantsService.rvwlog_rvwid = this.constantsService.ReviewInfo.rvwNum ;
    // this.router.navigate(['/qualityControl/fspReviewlog']);
    if (this.constantsService.ReviewInfo.rvwType ===  'FSPPositive' ) {
      this.router.navigate(['/qualityControl/fspReviewlog']);
    }
    else{
      this.router.navigate(['/qualityControl/fsNegReviewlog'])
    }
  
  }
}

