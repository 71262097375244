import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-qc35-b-quality-control-qc35-bprocess',
  templateUrl: './qc35-b-quality-control-qc35-bprocess.component.html',
  styleUrls: ['./qc35-b-quality-control-qc35-bprocess.component.css']
})
export class QC35BQualityControlQC35BProcessComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
    
  }

  open35BProcess() {
    const qc35BProcessURL='https://docs.google.com/document/d/1dxbHsBd3lbNAeRcoyC5hClZ0Tk3qQHhHc6E9LkfNnV8/preview';
    window.open(qc35BProcessURL, 'QC35BProcess',
        'location=no,width=800,height=700,scrollbars=yes,modal=yes');
  }
}