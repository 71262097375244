import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-contribution-others-specify',
  templateUrl: './contribution-others-specify.component.html',
  styleUrls: ['./contribution-others-specify.component.css']
})
export class ContributionOthersSpecifyComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;
    ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

}

