import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-failed-to-comply-job-prg1',
  templateUrl: './failed-to-comply-job-prg1.component.html',
  styleUrls: ['./failed-to-comply-job-prg1.component.css']
})
export class FailedToComplyJobPrg1Component extends BaseComponent implements OnInit {
 

  ngOnInit() {
    super.ngOnInit();
  }
 
 
  
 
}




