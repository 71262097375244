import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-under-prior-penalty',
  templateUrl: './under-prior-penalty.component.html',
  styleUrls: ['./under-prior-penalty.component.css']
})
export class UnderPriorPenaltyComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }


}
