import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../shared-module/baseComponent.component';

@Component({
  selector: 'app-prison-match',
  templateUrl: './prison-match.component.html',
  styleUrls: ['./prison-match.component.css']
})

export class PrisonMatchComponent extends BaseComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
  }
}