import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pa-case-assignment',
  templateUrl: './pa-case-assignment.component.html',
  styleUrls: ['./pa-case-assignment.component.css']
})

export class PaCaseAssignmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}