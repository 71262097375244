import { Component, OnInit } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';
import { DATE_FORMAT } from 'src/app/shared-module/month-format.directive';

@Component({
  selector: 'app-qc16-front-page',
  templateUrl: './qc16-front-page.component.html',
  styleUrls: ['./qc16-front-page.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})

export class Qc16FrontPageComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    if(this.getControlValue('500010.11')){
      this.setControlValue('500010.11',this.getControlValue('500010.11').toUpperCase());
    }
   
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/qualityControl/questionary-500010?type=500010'] != null && ref.accessMap['/qualityControl/questionary-500010?type=500010'].accessMode != 'rw')) {
       
      this.isNotEditable = true;
   
  }
  //making it false to unlock screens for  analyst 
  if(this.constantsService.isAnalystUser()){ 
    this.isNotEditable = false;
  }
 }
}