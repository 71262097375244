import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-transitional-benefits',
  templateUrl: './transitional-benefits.component.html',
  styleUrls: ['./transitional-benefits.component.css']
})

export class TransitionalBenefitsComponent extends BaseComponent implements OnInit {
  isNotEditable: boolean = false; 
  currentPage: number = 0;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
 
  changeQf530_2(val) {
    if ( val === 'N') {
      this.group.controls['530.3'].disable();
      this.group.controls['530.4'].disable();
      this.group.controls['530.5'].disable();
    }else {
      this.group.controls['530.3'].enable();
      this.group.controls['530.4'].enable();
      this.group.controls['530.5'].enable();
    }
  }

  // determineAccess() {
  //   const ref = this.constantsService.getSecurityObject();
  //   if ((ref.accessMap['/qualityControl/questionary-365?type=365'] != null && ref.accessMap['/qualityControl/questionary-365?type=365'].accessMode != 'rw')) {
  //     this.isNotEditable = true;
  //   }
  // }

  handleQ530_10(val){
    if(val === '9'){
      this.group.controls['530.10a'].enable();
    }else{
      this.group.controls['530.10a'].disable();
    }
  }
}