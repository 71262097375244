import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs/Observable";

@Injectable()
export class SheetResource {
    private readonly API_URL: string = 'https://sheets.googleapis.com/v4/spreadsheets';

    constructor(private httpClient: HttpClient) {
    }

    // authtoken as parameter only for demo purpose , better use a UserService to get the token
    public findById(spreadsheetId: string, authtoken: string): Observable<any> {
        return this.httpClient.get(this.API_URL + '/' + spreadsheetId, {
          headers: new HttpHeaders({
                Authorization: `Bearer ${authtoken}`
            })
        });
    }

    public create(authtoken: string): Observable<any> {
        return this.httpClient.post(this.API_URL,{}, {
          headers: new HttpHeaders({
                Authorization: `Bearer ${authtoken}`
            })
        });
    }

    public copySheet(sourceSpreadSheetId: string, sourceSheet: string, destinationSpreadSheetId: string, authtoken: string)
    : Observable<any> {
        return this.httpClient.post(this.API_URL
            + '/' + sourceSpreadSheetId
            + '/sheets/' + sourceSheet
            + ':copyTo'
            , {
                'destinationSpreadsheetId': destinationSpreadSheetId
            }, {
            headers: new HttpHeaders({
                Authorization: `Bearer ${authtoken}`
            })
        });
    }

    public copySpreadSheet(spreadsheetId: string, authtoken: string): Observable<any> {

        let newSheet;
        return Observable.create(observer => {
        this.create(authtoken).subscribe(nSt => {
            newSheet = nSt;
            this.findById(spreadsheetId, authtoken).subscribe(spSheet => {
                spSheet.sheets.map(sht => {
                    this.copySheet(spreadsheetId, sht.properties.sheetId, newSheet.spreadsheetId, authtoken)
                    .subscribe(res => {
                         res.title = sht.properties.title;
                         console.log(res);
                         observer.next(newSheet);
                         observer.complete();
                        } );
                });
            });

        });
        });

        // return new Observable();



    }
}
