import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-gross-income-overlimit',
  templateUrl: './gross-income-overlimit.component.html',
  styleUrls: ['./gross-income-overlimit.component.css']
})
export class GrossIncomeOverlimitComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
