import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-motor-vehicles',
  templateUrl: './motor-vehicles.component.html',
  styleUrls: ['./motor-vehicles.component.css']
})

export class MotorVehiclesComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
}