import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormArray, FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiManager } from 'src/app/common/api-manager.service';
import { ConstantsService } from '../../common/constants.service';
import { NavigationService } from '../../common/navigation.service';
import { MatSnackBar } from '@angular/material/snack-bar';


export interface PeriodicElement {
  rvwNum: number;
  caseId: number;
  irn: number;
  // mdmId: number;
  // pgmCd: string;
  // pgmType: string;
  // districtOffice: number;
  // ldssNm: string;
  hohNm:string;
  // aplctnDt:string;
  // pgmTypeDesc: string;
  // pgmStus:string;
  sampleMo: string;
  // bnftGrp: string;
  // csStsCd: string;
  // rvwSts:string;
  rvwType: string;
  // transDt: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];


@Component({
  selector: 'app-fns-transmission',
  templateUrl: './fns-transmission.component.html',
  styleUrls: ['./fns-transmission.component.css']
})
export class FnsTransmissionComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  fnsTransForm: FormGroup;
  isNotEditable: boolean = false;

  displayedColumns: string[] = [
    'select',
     'sampleMo',
    'rvwNum', 'caseId'
    // , 'irn'
    // ,'mdmId'
    // , 'pgmCd', 'ldssNm'
    ,'hohNm',
    // , 'aplctnDt'
    // ,'pgmStus'
    // , 'pgmTypeDesc'
    
    //  'bnftGrp', 'csStsCd'
    // ,'rvwSts',
    // 'transDt',
  ];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  dataSourceAll = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);
  monthYear: string;
  benMonth: string;
  rvwType: string;


  constructor(
    private readonly router: Router,
    private fb: FormBuilder,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    public navigationService: NavigationService,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _snackBar: MatSnackBar,
  ) {
    
    this.dataSource = new MatTableDataSource();
    this.dataSourceAll = new MatTableDataSource();
    this.buildForm();
  }

  ngOnInit() {
    let date: Date = new Date();
    this.monthYear = date.getFullYear() + '' + (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
    this.getSelectSample();
    console.log(this.data);
    if(this.data.index>-1){
      this.isNotEditable = true;
    }
  }


  buildForm() {
    this.fnsTransForm = this.fb.group({
      sampleMonth: ['', [Validators.required]],
      type: ['', [Validators.required]]
    });
  }

  getSelectSample() {
    const queryParams: any[] = [];
    let appCd: string = '';

    let rvwIds : any;

    queryParams.push('Assigned');
    appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    queryParams.push(appCd);
    queryParams.push('A');

    this.apiManager.fetchData('bpeAssigneUrl', undefined, ApiManager.GET,
      undefined, undefined, undefined, queryParams)
      .subscribe(res => {
        if (res) {
          this.dataSourceAll = new MatTableDataSource(res);
          this.dataSourceAll.data = res;
          rvwIds = this.data.fnsTransdata.rvwIds;
          // if ( res.filter(item => item.rvwMo === this.monthYear).length > 0 ) {
          //   this.dataSource.data = res.filter(item => item.rvwMo === this.monthYear && item.rvwStsDesc === "Completed");
          //   this.dataSourceAll.data = res.filter(item => item.rvwMo === this.monthYear && item.rvwStsDesc === "Completed");
          // } else {
            this.dataSource.data = res;
          // }
          if(rvwIds) {
            this.dataSource.data = this.dataSource.data.filter(item => 
              rvwIds.join(',').indexOf(item.rvwNum.toString())>-1
           );
          }
          this.dataSource.paginator = this.paginator;
          this.sort = new MatSort();
          this.sort.active = 'rvwNum';
          this.sort.direction = 'asc';
          this.sortData(this.sort);
          // this.dataSource.sortData();
        }
      });
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data;
    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'rvwNum' : return (a.rvwNum < b.rvwNum ? -1 : 1) * (isAsc ? 1 : -1);
        default: return 0;
      }
    });

    this.dataSource.data = [... this.dataSource.data];
  }

  compare(a: number | string | Date , b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  submit(model) {
    console.log(model);
    const queryParams: any[] = [];
    
    const selectedCaseIds: any[] = [];

    // this.selection.select(this.dataSource.data[0]);
    this.selection.selected.forEach(row => {
      let id: number = row.rvwNum;
      // let pgmTypeCd: string = row.pgmType;
      selectedCaseIds.push(id
        // , pgmType: pgmTypeCd 
      );
    });

    let sendObj = {
        fnsTransId: null,
        samplingMonth: model.sampleMonth  ,
        type: model.type,
        dirtyStatus: 'INSERT',
        rvwIds: selectedCaseIds
    };

    this.apiManager.fetchData('saveFNSTransmission', undefined, ApiManager.POST, sendObj, undefined, undefined, queryParams)
      .subscribe(
        res => {
          this._snackBar.open('Save Successful.', undefined, {
            duration: 2000,
          });
          this.selection.clear();
          this.dialogRef.close(res);
        },
        (err)=>{
          this._snackBar.open('Save Failed.', undefined, {
            duration: 2000,
          });
          this.dialogRef.close();
        }
        );

  }

  getDistinctMonth() {
    let distinctMonth: any = [];
    this.dataSourceAll.data.map(filterItem => {
      if (filterItem.sampleMo &&
        distinctMonth.indexOf(filterItem.sampleMo) === -1) {
        distinctMonth.push(filterItem.sampleMo);
      }
    });
    return distinctMonth.sort().reverse();
  }

  filterData(type, value) {
    this.selection.clear();
    switch (type) {
      case 'benMonth':
        this.benMonth = value;
        if(this.rvwType) {
          this.dataSource.data = this.dataSourceAll.data.filter(item => item.sampleMo === value && item.rvwType === this.rvwType );
        } else {
          this.dataSource.data = this.dataSourceAll.data.filter(item => item.sampleMo === value);
        }
        
        break;
      case 'rvwType':
        this.rvwType = value;
        if(this.benMonth) {
          this.dataSource.data = this.dataSourceAll.data.filter(item => item.rvwType === value && item.sampleMo === this.benMonth  );
        } else {
          this.dataSource.data = this.dataSourceAll.data.filter(item => item.rvwType === value);
        }
        
        break;
      
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterTogglea() {
    let index=this.paginator.pageIndex+1;
    let size=this.paginator.pageSize;
    let indexnr=0;
    let startNr=(index-1)*size;
    startNr=startNr+1;
    let endNr=startNr+size;
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row: any) =>{
        indexnr=indexnr+1
        if (indexnr>=startNr && indexnr <endNr) {
          this.selection.select(row)
        }
        else {
          this.selection.deselect(row)
        }
      }
    );
  }


  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.rvwNum + 1}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
     print(): void {
        // let printContents, printMember, printStyle, printLink;
        // printContents = document.getElementById('print-section');
        // printMember = document.getElementById('print-member');
        // printStyle = this.printService.getTagsHtml('style');
        // printLink = this.printService.getTagsHtml('link');
        // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
        // this.printService.printTable = printContents ? printContents.innerHTML : null;
        // this.printService.printStyle = printStyle;
        // this.printService.printLink = printLink;
        // this.printService.print();
        window.print();
      }

}
