import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-non-recurring-lump-sum-payment',
  templateUrl: './non-recurring-lump-sum-payment.component.html',
  styleUrls: ['./non-recurring-lump-sum-payment.component.css']
})

export class NonRecurringLumpSumPaymentComponent extends BaseComponent implements OnInit {
isNotEditable: boolean =false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.changeof212_1();
    this.changeof212_10();
    this.handle366_1(this.getControlValue('212.6'));
  }

  

  changeof212_1() {
    if ( this.group.controls['212.1'].value === 'N') {
      this.group.controls['212.2'].disable();
      this.group.controls['212.3'].disable();
      this.group.controls['212.4'].disable();
      this.group.controls['212.5'].disable();
      this.group.controls['212.6'].disable();
      this.group.controls['212.7'].disable();
      this.group.controls['212.8'].disable();
      this.group.controls['212.9'].disable();
      
      } else {
        this.group.controls['212.2'].enable();
        this.group.controls['212.3'].enable();
      this.group.controls['212.4'].enable();
      this.group.controls['212.5'].enable();
      this.group.controls['212.6'].enable();
      this.group.controls['212.7'].enable();
      this.group.controls['212.8'].enable();
      this.group.controls['212.9'].enable();

      }
  }

  get Is212_1():boolean{
    return this.group.controls['212.1'].value === 'N';
  }
  get Is212_6():boolean{
    return this.group.controls['212.6'].value === 'N';
  }

  // changeof212_6(event: any){
  //   if ( event.value === 'N') {
  //     this.group.controls['212.7'].disable();
  //     this.group.controls['212.8'].disable();
  //   }
  //   else {
  //     this.group.controls['212.7'].enable();
  //   this.group.controls['212.8'].enable();
  
   

  //   }
  // }


  
  changeof212_10() {
    if (this.group.controls['212.10'].value === 'N') {
      this.group.controls['212.11'].disable();
      this.group.controls['212.12'].disable();
      this.group.controls['212.13'].disable();
      
      
      } else {
        this.group.controls['212.11'].enable();
      this.group.controls['212.12'].enable();
      this.group.controls['212.13'].enable();
     

      }
  }



  handle366_1(val) {
    if (val === 'N') {
      this.group.controls['212.7'].disable();
      this.group.controls['212.8'].disable();
    }
    else if (val === 'Y') {
      this.group.controls['212.7'].enable();
      this.group.controls['212.8'].enable();
    }
  }

}