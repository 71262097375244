import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-max-benefits-recieved-md',
  templateUrl: './max-benefits-recieved-md.component.html',
  styleUrls: ['./max-benefits-recieved-md.component.css']
})
export class MaxBenefitsRecievedMdComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }


}

