import { Component, OnInit, Inject, Directive } from "@angular/core";
// import {
//   MatDialogConfig,
//   MatPaginator,
//   MatSort,
//   MatTableDataSource
// } from "@angular/material";
// import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ViewChild } from "@angular/core";
import * as Highcharts from "highcharts";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiManager } from 'src/app/common/api-manager.service';
import { ConstantsService } from 'src/app/common/constants.service';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { MonthFormatDirective, MNTH_FORMAT, DATE_FORMAT } from '../month-format.directive';

import { MomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, ThemePalette } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

import { AppUtilService } from 'src/app/common/app.util.service';
import { NavigationService } from 'src/app/common/navigation.service';
import { DeleteDialogComponent } from 'src/app/shared-module/delete-dialog/delete-dialog.component';
import { MonitoringMemosComponent, MonitoringMemoDialog } from '../../bpe-app/monitoring/monitoring-memos/monitoring-memos/monitoring-memos.component';
import moment from 'moment';


export interface PeriodicElementtwo {


  fsmeGenDueDt: any;
  rvwMnth: any;
  ldssDoCd: any;
  symbol: any;
  LeadReviewer: any;
  DatesofOnsiteReview: any;
  ConfirmationMemo: any;
  AdHocDatamonth: any;
}

const ELEMENT_DATA: PeriodicElementtwo[] = [];

@Component({
  selector: 'app-fsme-schedule',
  templateUrl: './fsme-schedule.component.html',
  styleUrls: ['./fsme-schedule.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})

export class FsmeScheduleComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  Countys: County[];
  DOs: DOOffice[];
  smallCounties:string[]=['kent County'];
  mediumCounties:string[]=['Allegany County','Cecil County','Dorchester County','Fredrick County','Howard County','St.Marys County','Somerset County','Washington County'];
  largeCounties:string[]=['Anne Arundel','Baltimore City','Baltimore County','Montgomery County','Prince George County'];

  scheduledisplayedColumns: string[] = ['fsmeGenDueDt', 'rvwMnth', 'ldssDoCd', 'symbol', 'LeadReviewer', 'DatesofOnsiteReview', 'ConfirmationMemo', 'AdHocDatamonth'];
  managedisplayedColumns: string[] = ['fsclYr', 'smallList', 'mediumList', 'largeList'];

  findRespSource: any;
  
  findRespSourceAll: any;
  manageschedSource: any;
  memberDetailsForm: FormGroup;
  title;
  isNotEditable: boolean = false;
  bpeFsmeJrsdctnSizeAll:any;
  
  public selSmallDOs: string[];
  public selMediumDOs: string[];
  public selLargeDOs: string[];

  // 
  // @ViewChild(manageSchedule) private manageSchedule: manageSchedule;
  // @ViewChild(scheduleDetails) private scheduleDetails: scheduleDetails;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    public constantsService: ConstantsService,
    public appUtilService: AppUtilService,
    private apiManager: ApiManager,
    public navigationService: NavigationService,
  ) {
    if (this.router.url.includes('/fsme/')) {
      this.title = 'SNAP Schedule';
    }
    else if (this.router.url.includes('/payment-accuracy/')) {
      this.title = 'Payment Accuracy Schedule';
    }
    else if (this.router.url.includes('/wpr/')) {
      this.title = 'WPR Schedule';
    }
    // this.DOs = this.getDistinctDOs();
    // this.Countys = this.getDistinctCounty();
    this.buildForm();
  }

  ngOnInit() {
    this.getPageData();
    this.determineAccess();
  }
 
 
  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/bpe/fsme/fsme-schedule'] != null && ref.accessMap['/bpe/fsme/fsme-schedule'].accessMode != 'rw')) {
      this.isNotEditable = true;
    }
  }

  getPageData() {
    const caseId: any = '';
    const queryParams: any[] = [];
    let appCd: string = '';
    appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    queryParams.push(appCd);
    // queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    this.apiManager.fetchData('getfmeschedule', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
      .subscribe(
        res => {
          this.findRespSourceAll = res.bpeFsmeSchdlModel;
          this.findRespSource = new MatTableDataSource(res.bpeFsmeSchdlModel); // .BpeFsmeSchdlModel
          // this.findRespSource = res;
          console.log(res);
          this.findRespSource.paginator = this.paginator;
          this.findRespSource.sort = this.sort;
          
          this.prepareManageSchedule(res.bpeFsmeJrsdctnSizeSelected); // .bpeFsmeJrsdctnSizeSelected
          this.bpeFsmeJrsdctnSizeAll = res.bpeFsmeJrsdctnSizeAll;
          this.pepareJrsdctnLookup();

          this.bpeFsmeJrsdctnSizeAll.allList = [];
          this.bpeFsmeJrsdctnSizeAll.smallList.map(item => {
            this.bpeFsmeJrsdctnSizeAll.allList.push(item);
          });
          this.bpeFsmeJrsdctnSizeAll.mediumList.map(item => {
            this.bpeFsmeJrsdctnSizeAll.allList.push(item);
          });
          this.bpeFsmeJrsdctnSizeAll.largeList.map(item => {
            this.bpeFsmeJrsdctnSizeAll.allList.push(item);
          });
           
          this.DOs = this.getDistinctDOs();
          this.Countys = this.getDistinctCounty();
        }
      );
  }
  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }
  getDistinctDOs() {
    let distinctDO: any = [];

    this.bpeFsmeJrsdctnSizeAll.allList.map(
      dos => {
        if (distinctDO.filter(dup => dup.ldssDoCd === dos.ldssDoCd).length === 0) {
          distinctDO.push({ ldssDoCd: dos.ldssDoCd, ldssDoName: dos.ldssDoNm });
        }

      }
    );
    return distinctDO;
  }

  getDistinctCounty() {
    // return this.regionDistrictAnalystLookup.filter((value, index, self) => self.indexOf(value) === index);
    let distinctCounty: any = [];
    this.bpeFsmeJrsdctnSizeAll.allList.map(
      cnty => {
        if (distinctCounty.filter(dup => dup.value === cnty.ldssCd && dup.viewValue === cnty.ldssNm).length === 0) {
          distinctCounty.push({ value: cnty.ldssCd, viewValue: cnty.ldssNm });
        }

      }
    );
    return distinctCounty;
  }
  // onTabChanged(event: MatTabChangeEvent) 
  // {
  //   if(event.index == 0)
  //   {
  //       this.manageSchedule.refresh();//Or whatever name the method is called
  //   }
  //   else
  //   {
  //       this.scheduleDetails.refresh(); //Or whatever name the method is called
  //   }
  // }

  prepareManageSchedule(data) {
    const manageSchedule: any = data;
    // manageSchedule.push(data);
    let dsManageSchedule:any=[];
    
    manageSchedule.fsclYrList.map(
      fsclYr => {
        if (dsManageSchedule.filter(dup => dup.fsclYr === fsclYr).length === 0) {
          let smList =[];
          manageSchedule.smallList.filter(dup => dup.fsclYr === fsclYr && dup.jrsdctnSize === 'S').map(item => {
            if ( smList.filter(sL => sL.ldssDoCd === item.ldssDoCd).length === 0 ){
              smList.push(item);
            }
          });
          let mdList =[];
          manageSchedule.mediumList.filter(dup => dup.fsclYr === fsclYr && dup.jrsdctnSize === 'M').map(item => {
            if ( mdList.filter(sL => sL.ldssDoCd === item.ldssDoCd).length === 0 ){
              mdList.push(item);
            }
          });
          let lgList=[];
          manageSchedule.largeList.filter(dup => dup.fsclYr === fsclYr && dup.jrsdctnSize === 'L').map(item => {
            if ( lgList.filter(sL => sL.ldssDoCd === item.ldssDoCd).length === 0 ){
              lgList.push(item);
            }
          });

          dsManageSchedule.push(
            { 
              'fsclYr': fsclYr,
              smallList: smList ,
              mediumList: mdList,
              largeList: lgList,
            });
        }

      }
    );
    this.manageschedSource = new MatTableDataSource(dsManageSchedule);
    
  }

  pepareJrsdctnLookup() {
    
    this.smallCounties = this.getCountyDObySize(this.bpeFsmeJrsdctnSizeAll.smallList);
    this.mediumCounties = this.getCountyDObySize(this.bpeFsmeJrsdctnSizeAll.mediumList);
    this.largeCounties = this.getCountyDObySize(this.bpeFsmeJrsdctnSizeAll.largeList);
  }
  

  getCountyDObySize(jurdList) {
    let sCounties = [];
    let allCountyDO = jurdList
    let allCounty = [];
    jurdList.map(cnt => {
      if ( allCounty.filter(item => item.ldssCd === cnt.ldssCd && item.ldssNm === cnt.ldssNm).length === 0 ) {
        allCounty.push(cnt);
      }
    });
    allCounty.map(item => {
      let groupItem = { code: item.ldssCd, value: item.ldssNm };
      let subGroupItem = [];
      allCountyDO.filter(jrsd => jrsd.ldssCd === item.ldssCd && jrsd.ldssNm === item.ldssNm).map(sItem => {
        let stritemdo:string = sItem.ldssDoNm;
        if(!stritemdo.endsWith(' LHD')){
          
     
        subGroupItem.push(
          {
            code: sItem.ldssDoCd,
            value: sItem.ldssDoNm
          }
        );
      }
      });
      sCounties.push({ group: groupItem, subGroup: subGroupItem });
    });

    

    return sCounties;
  }
  
  getCountyNameByCd(cntyCd) {
    let cntyName = '';
    this.bpeFsmeJrsdctnSizeAll.smallList.filter(item => item['ldssCd'] === cntyCd ).map(val => {
      cntyName = val['ldssNm'];
    });
    this.bpeFsmeJrsdctnSizeAll.mediumList.filter(item => item['ldssCd'] === cntyCd ).map(val => {
      cntyName = val['ldssNm'];
    });
    this.bpeFsmeJrsdctnSizeAll.largeList.filter(item => item['ldssCd'] === cntyCd ).map(val => {
      cntyName = val['ldssNm'];
    });
    return cntyName;
  }

  getDONameByCd(cntyCd) {
    let doName = '';
    this.bpeFsmeJrsdctnSizeAll.smallList.filter(item => item['ldssDoCd'] === cntyCd ).map(val => {
      doName = val['ldssDoNm'];
    });
    this.bpeFsmeJrsdctnSizeAll.mediumList.filter(item => item['ldssDoCd'] === cntyCd ).map(val => {
      doName = val['ldssDoNm'];
    });
    this.bpeFsmeJrsdctnSizeAll.largeList.filter(item => item['ldssDoCd'] === cntyCd ).map(val => {
      doName = val['ldssDoNm'];
    });
    return doName;
  }



  buildForm() {
    this.memberDetailsForm = this.fb.group({
     
      filterReviewMonth: [''],
      filterReviewYear: [''],
      countyCd: [''],
      doVal: [''],


      fsclYr:[''],
      bpeFsmeScdlId: [''],
      rmndrEmail: ['', Validators.required],
      cntyId: [''],
      EPEU: [''],
      ldRvwr: [''],

      // County: ["", Validators.required],
      bpeFsmeCntyList: this.fb.array([
        this.fb.group({
          bpeFsmeCntyId: [''], cntyId: [''], ldssDoCd: ['']
        })
      ]),
      bpeFsmeRvwrList: this.fb.array([
        this.fb.group({
          bpeFsmeRvwrId: [''], rvwrGrpId: [''], uniqueId: ['']
        })
      ]),
      bpeFsmeOnsiteRvwDtList: this.fb.array([
        this.fb.group({
          bpeFsmeOnsiteRvwDtid: [''], onsiteRvwDtId: [''], toDt: [''], frmDt: [''], lcNm: ['']
        })
      ]),
      bpeFsmeEpeuStaffList: this.fb.array([
        this.fb.group({
          bpeFsmeEpeuStaffId: [''], rvwrGrpId: [''], uniqueId: ['']
        })
      ]),
      fsmeGenDueDt: ["", Validators.required],
      cnfrmtnMemo: ["", Validators.required],
      LeadReviewer: ["", Validators.required],
      OnsiteReview: ["", Validators.required],
      rvwMnth: ["", Validators.required],
      // EPEU: [" ", Validators.required],
      onsiteRvwFromDt: ["", Validators.required],
      onsiteRvwToDt: ["", Validators.required],
      entranceConfDt: ["", Validators.required],
      // FSMEGeneratorDueDates: ["", Validators.required],
      tntExtConfDt: ["", Validators.required],
      crctyActDueDt: ["", Validators.required],
      adHocDataMnth: ["", Validators.required],
      appType: ['BPE-FSME'],
      dirtyStatus: ['INSERT'],
    })
  }

  // Reviewers = new FormControl();
  ReviewersList: rvwind[] =
    [
      { value: "1", viewValue: "Kortni" },
      { value: "2", viewValue: "Brandi" },
      { value: "3", viewValue: "Donyearl" },
      { value: "4", viewValue: "Vivian" },
    ];

  LeadReviewers: LeadReviewer[] = [
    { value: "1", viewValue: "Kortni" },
    { value: "2", viewValue: "Brandi" },
    { value: "3", viewValue: "Donyearl" },
    { value: "4", viewValue: "Vivian" },
  ];

  selectCountyDO(evt, jrd) {
    if ( evt.value ) {
      switch ( jrd ) {
        case 'S':
          this.selSmallDOs = evt.value;
          break;
        case 'M':
          this.selMediumDOs = evt.value;
          break;
        case 'L':
          this.selLargeDOs = evt.value;
          break;
      }
    }
    
  }
  filterData(type, value) {
    // switch (type) {
    //   case 'year':
    //     this.findRespSource.data = this.findRespSourceAll.filter(item => item.fsclYr === value);
    //     break;
    //     case 'rvwMnth':
    //       this.findRespSource.data = this.findRespSourceAll.filter(item => item.rvwMnth === value);
    //       break;
    //   case 'county':
    //     this.findRespSource.data = this.findRespSourceAll.filter(item => item.bpeFsmeCntyList[0].ldssDoCd === value);
    //     break;
    //   case 'docode':
    //     this.findRespSource.data = this.findRespSourceAll.filter(item => item.bpeFsmeOnsiteRvwDtList[0].lcNm === value);
    //     break;
    // }
    this.findRespSource.data = this.findRespSourceAll;
    if ( this.memberDetailsForm.get('filterReviewYear').value !== '' ) {
      const val = this.memberDetailsForm.get('filterReviewYear').value;
      if(val){
        this.findRespSource.data = this.findRespSource.data.filter(item => item.fsclYr === val);
      }
     
    }

    if ( this.memberDetailsForm.get('filterReviewMonth').value !== '' ) {
      const val = this.memberDetailsForm.get('filterReviewMonth').value;
      if(val){
        this.findRespSource.data = this.findRespSource.data.filter(item => 
          
          item.rvwMnth && moment(item.rvwMnth).format('MM/YYYY')  === val 
          );
      }
     
    }

    if ( this.memberDetailsForm.get('countyCd').value !== '' ) {
      const val = this.memberDetailsForm.get('countyCd').value;
      if(val){
        this.findRespSource.data = this.findRespSource.data.filter(item => item.bpeFsmeCntyList[0].ldssDoCd === val);
      }
      
    }

    if ( this.memberDetailsForm.get('doVal').value !== '' ) {
      const val = this.memberDetailsForm.get('doVal').value;
      if(val){
        this.findRespSource.data = this.findRespSource.data.filter(item => item.bpeFsmeOnsiteRvwDtList[0].lcNm === val);
      }
     
    }
  }

  getDistinctFFY() {
    
    let distinctFFY: any = [];
    let ds :any  = this.findRespSourceAll;
    if(ds){
      ds.map(filterItem => {
        if (filterItem.fsclYr &&
          distinctFFY.indexOf(filterItem.fsclYr) === -1) {
          distinctFFY.push(filterItem.fsclYr);
        }
      });
    }
  
    return distinctFFY;
  }
  getDistinctMonth(){

    let distinctMonth: any = [];
    let ds :any  = this.findRespSourceAll;
    if (ds) { 
      ds.map(filterItem => {
        if(filterItem.rvwMnth){
          let rvmonth = this.appUtilService.changeDateToMonthFormat(filterItem.rvwMnth);
          let tempRvMonth = rvmonth.substring(4)+ '/'  + rvmonth.substring(0,4);
          if (filterItem.rvwMnth &&
            distinctMonth.indexOf(tempRvMonth) === -1 ) {
              distinctMonth.push(tempRvMonth);
          }
        }
            
      });
    }
    return distinctMonth;
  }
  

  save(model) {
    // let tempModel;
    let cnty ;
    let multiRequest = [];

    let modelVal: any = {
      fsclYr: model.value.fsclYr ,
    bpeFsmeScdlId: '',
    rmndrEmail: '',
    cntyId: '',
    EPEU: '',
    ldRvwr: '',

    // County: ["", Validators.required],
    bpeFsmeCntyList: [
     {
        bpeFsmeCntyId: '',
        cntyId: '',
        ldssDoCd: '',
      }
    ],
    bpeFsmeRvwrList: [
      {
        bpeFsmeRvwrId: '',
        rvwrGrpId: '',
        uniqueId: ''
      }
    ],
    bpeFsmeOnsiteRvwDtList: [
      {
        bpeFsmeOnsiteRvwDtid: '',
        onsiteRvwDtId: '',
        toDt: '',
        frmDt: '',
        lcNm: ''
      }
    ],
    bpeFsmeEpeuStaffList: [
      {
        bpeFsmeEpeuStaffId: '',
        rvwrGrpId: '',
        uniqueId: ''
      }
    ],
    fsmeGenDueDt: '',
    cnfrmtnMemo: '',
    LeadReviewer: '',
    OnsiteReview: '',
    rvwMnth: '',
    // EPEU: [" ", Validators.required],
    onsiteRvwFromDt: '',
    onsiteRvwToDt: '',
    entranceConfDt: '',
    // FSMEGeneratorDueDates: ["", Validators.required],
    tntExtConfDt: '',
    crctyActDueDt: '',
    adHocDataMnth: '',
    appType: 'BPE-FSME',
    dirtyStatus: 'INSERT',
  };

    if ( this.selSmallDOs ) {
      
      for (let dos of this.selSmallDOs) {
        let tempModel: any = JSON.parse(JSON.stringify(modelVal));  
        cnty = '' ;
        this.bpeFsmeJrsdctnSizeAll.smallList.filter(item => item.ldssDoCd === dos).map(val =>{
          cnty = val.ldssCd;
        });
        tempModel.bpeFsmeCntyList[0].ldssDoCd = cnty;
        tempModel.bpeFsmeOnsiteRvwDtList[0].lcNm = dos;
        // this.callSaveApi(tempModel);
        multiRequest.push(tempModel);
        
      }
      
    }
    

    if ( this.selMediumDOs ) {
      
      for (let dos of this.selMediumDOs) {
        let tempModel: any = JSON.parse(JSON.stringify(modelVal));  
        cnty = '' ;
        this.bpeFsmeJrsdctnSizeAll.mediumList.filter(item => item.ldssDoCd === dos).map(val =>{
          cnty = val.ldssCd;
        });
        tempModel.bpeFsmeCntyList[0].ldssDoCd = cnty;
        tempModel.bpeFsmeOnsiteRvwDtList[0].lcNm = dos;
        // this.callSaveApi(tempModel);
        multiRequest.push(tempModel);
        
      }
      
    }
    
    if (this.selLargeDOs) {
      
      for (let dos of this.selLargeDOs) {
        let tempModel: any = JSON.parse(JSON.stringify(modelVal));  
        cnty = '' ;
        this.bpeFsmeJrsdctnSizeAll.largeList.filter(item => item.ldssDoCd === dos).map(val =>{
          cnty = val.ldssCd;
        });
        tempModel.bpeFsmeCntyList[0].ldssDoCd = cnty;
        tempModel.bpeFsmeOnsiteRvwDtList[0].lcNm = dos;
        // this.callSaveApi(tempModel);
        multiRequest.push(tempModel);
        
      }
     
    }
    
    if ( multiRequest.length > 0 ) {
      this.callSaveApi(multiRequest);
    }
  }

  callSaveApi(model) {
    
    console.log(model);
    const queryParams: any[] = [];
    // queryParams.push('BPE-FSME');
    let appCd: string = '';
    appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    // let fsmeschedObj: any = model;
    let fsmeschedObj: any = [];
    model.map(mdl => {
      mdl.dirtyStatus =  mdl.bpeFsmeScdlId !== '' ? 'UPDATE' : 'INSERT';
      mdl.bpeFsmeScdlId = mdl.bpeFsmeScdlId === '' ? null : parseInt(mdl.bpeFsmeScdlId);
      mdl.appType = appCd;
      mdl.fsclYr = mdl.fsclYr;
      fsmeschedObj.push(mdl)
    });
    
     

    this.apiManager.fetchData('savefsmeschedule', undefined, ApiManager.POST, fsmeschedObj, undefined, undefined, queryParams)
      .subscribe(
        res => {
          // this.findRespSource.data = res;
          // if ( finalCall ) {
            
            this.findRespSource = new MatTableDataSource(res.bpeFsmeSchdlModel); // .BpeFsmeSchdlModel
            // this.findRespSource = res;
            console.log(res);
            this.findRespSource.paginator = this.paginator;
            this.findRespSource.sort = this.sort;
            this.prepareManageSchedule(res.bpeFsmeJrsdctnSizeSelected); // .bpeFsmeJrsdctnSizeSelected
            this.bpeFsmeJrsdctnSizeAll = res.bpeFsmeJrsdctnSizeAll;
            this.pepareJrsdctnLookup();
            
          // }

        });
  }

  getTypeValue(cd) {
    let typeValue;
    this.ReviewersList.filter(item => item.value == cd).map(val => {
      typeValue = val.viewValue;
    })
    return typeValue;
  }

  getLeadReviewerTypeValue(cd) {
    let typeValue;
    this.LeadReviewers.filter(item => item.value == cd).map(val => {
      typeValue = val.viewValue;
    })
    return typeValue;
  }

  openDialog(row) {
    if (this.isNotEditable) {
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      // index: idx,
      index:this.findRespSource.data.indexOf(row),
      // data: this.findRespSource && this.findRespSource.data.length > 0 ? this.findRespSource.data[idx] : null,
      data:row,
      cnty: this.Countys,
      dos:this.DOs
      
    };
    const dialogRef = this.dialog.open(FSMEScheduleDialog, dialogConfig);
    dialogRef.updateSize('75%', '85%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log('afterClose', val);
        this.findRespSource.data = val;
        this.findRespSourceAll = val.bpeFsmeSchdlModel;
        this.findRespSource = new MatTableDataSource(val.bpeFsmeSchdlModel); // .BpeFsmeSchdlModel
        this.prepareManageSchedule(val.bpeFsmeJrsdctnSizeSelected); // .bpeFsmeJrsdctnSizeSelected
        this.bpeFsmeJrsdctnSizeAll = val.bpeFsmeJrsdctnSizeAll;
        this.pepareJrsdctnLookup();

        this.bpeFsmeJrsdctnSizeAll.allList = [];
        this.bpeFsmeJrsdctnSizeAll.smallList.map(item => {
          this.bpeFsmeJrsdctnSizeAll.allList.push(item);
        });
        this.bpeFsmeJrsdctnSizeAll.mediumList.map(item => {
          this.bpeFsmeJrsdctnSizeAll.allList.push(item);
        });
        this.bpeFsmeJrsdctnSizeAll.largeList.map(item => {
          this.bpeFsmeJrsdctnSizeAll.allList.push(item);
        });
        this.findRespSource.paginator = this.paginator;
            this.findRespSource.sort = this.sort;
        this.filterData('','');
      }
      //  this.ngOnInit();
    });
  }

  viewDialog(row) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      // index: idx,
      // data: this.findRespSource && this.findRespSource.data.length > 0 ? this.findRespSource.data[idx] : null
      index:this.findRespSource.data.indexOf(row),
      data:row,
    };
    const dialogRef = this.dialog.open(FSMEViewDialog, dialogConfig);
    dialogRef.updateSize('85%', '55%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log('afterClose', val);
      }
    });
  }

  openMemoDialog(row) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      index: this.findRespSource.data.indexOf(row),
      samplingdata: this.findRespSource && this.findRespSource.data.length > 0 ? this.findRespSource.data : null,
      fsmeSch: true,
    };
    const dialogRef = this.dialog.open(MonitoringMemoDialog, dialogConfig);
    dialogRef.updateSize('60%', '42%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log('afterClose', val);
      }
    });
  }

  openDeleteDialog(row) {
    if (this.isNotEditable) {
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '350px';
    dialogConfig.height = '180px';
    dialogConfig.data = row;

    const dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(val => {
      if (val == 'yes') {

        const queryParams: any[] = [];

        let findRespObj: any = this.findRespSource.data[this.findRespSource.data.indexOf(row)];
        findRespObj.dirtyStatus = 'DELETE';
        let sendObj = [];  
        sendObj.push(findRespObj);
        this.apiManager.fetchData('savefsmeschedule', undefined, ApiManager.POST,sendObj , undefined, undefined, queryParams)
          .subscribe(res => {
            if (res) {
              this.findRespSource = res;
              setTimeout(() => {
              });
            }
            this.dialog.closeAll();
          });
      }
    });
  }

  next() {
    this.navigationService.navigatePage(this.router.url, 'next');
  }

  back() {
    this.navigationService.navigatePage(this.router.url, 'back');
  }
}

export interface County {
  value: string;
  viewValue: string;
}

export interface DOOffice {
  ldssDoCd: string;
  ldssDoName: string;
}

export interface EPEU {
  value: string;
  viewValue: string;
}

export interface LeadReviewer {
  value: string;
  viewValue: string;
}

export interface rvwind {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'fsme-schedule-dialog',
  templateUrl: 'fsme-schedule-dialog.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})

export class FSMEScheduleDialog implements OnInit {
  fsmeschedule: FormGroup;
  findRespSource: any = [];
  dateAdapter: any;

  // Reviewers = new FormControl();
  ReviewersList: rvwind[] =
    [
      { value: "1", viewValue: "Kortni" },
      { value: "2", viewValue: "Brandi" },
      { value: "3", viewValue: "Donyearl" },
      { value: "4", viewValue: "Vivian" },
    ];

  LeadReviewers: LeadReviewer[] = [
    { value: "1", viewValue: "Kortni" },
    { value: "2", viewValue: "Brandi" },
    { value: "3", viewValue: "Donyearl" },
    { value: "4", viewValue: "Vivian" },
  ];
  Countys: County[];
  DOs: DOOffice[];
  EPEUs: EPEU[] = [
    { value: "1", viewValue: "Kortni" },
    { value: "2", viewValue: "Brandi" },
    { value: "3", viewValue: "Donyearl" },
    { value: "4", viewValue: "Vivian" },
  ];
  title;
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public appUtilService: AppUtilService,
    public navigationService: NavigationService,
  ) {
    if (this.router.url.includes('/fsme/')) {
      this.title = 'FSME Schedule';
    }
    else if (this.router.url.includes('/payment-accuracy/')) {
      this.title = 'Payment Accuracy Schedule';
    }
    else if (this.router.url.includes('/wpr/')) {
      this.title = 'WPR Schedule';
    }
    // this.Countys = this.constantsService.getDistinctCounty();
    // this.DOs = this.constantsService.getDistinctDOs();
  }

  ngOnInit() {
    this.buildForm();
    if (this.data.index > -1) {
      const selectedFindResp = this.data.data;
      this.fsmeschedule.patchValue(selectedFindResp);
      //this.fsmeschedule.get('fndngRspDt').patchValue(this.appUtilService.changeDateYYYYMMDD(selectedFindResp.fndngRspDt));
    }
    this.Countys = this.data.cnty;
    this.DOs = this.data.dos;
  }
  

  buildForm() {
    this.fsmeschedule = this.fb.group({
      fsclYr:[''],
      bpeFsmeScdlId: [''],
      rmndrEmail: ['', Validators.required],
      cntyId: [''],
      EPEU: [''],
      ldRvwr: [''],

      // County: ["", Validators.required],
      bpeFsmeCntyList: this.fb.array([
        this.fb.group({
          bpeFsmeCntyId: [''], cntyId: [''], ldssDoCd: ['']
        })
      ]),
      bpeFsmeRvwrList: this.fb.array([
        this.fb.group({
          bpeFsmeRvwrId: [''], rvwrGrpId: [''], uniqueId: ['']
        })
      ]),
      bpeFsmeOnsiteRvwDtList: this.fb.array([
        this.fb.group({
          bpeFsmeOnsiteRvwDtid: [''], onsiteRvwDtId: [''], toDt: [''], frmDt: [''], lcNm: ['']
        })
      ]),
      bpeFsmeEpeuStaffList: this.fb.array([
        this.fb.group({
          bpeFsmeEpeuStaffId: [''], rvwrGrpId: [''], uniqueId: ['']
        })
      ]),
      fsmeGenDueDt: ["", Validators.required],
      cnfrmtnMemo: ["", Validators.required],
      LeadReviewer: ["", Validators.required],
      OnsiteReview: ["", Validators.required],
      rvwMnth: ["", Validators.required],
      // EPEU: [" ", Validators.required],
      onsiteRvwFromDt: ["", Validators.required],
      onsiteRvwToDt: ["", Validators.required],
      entranceConfDt: ["", Validators.required],
      // FSMEGeneratorDueDates: ["", Validators.required],
      tntExtConfDt: ["", Validators.required],
      crctyActDueDt: ["", Validators.required],
      adHocDataMnth: ["", Validators.required],
      appType: ['BPE-FSME'],
      dirtyStatus: ['INSERT'],
    });
  }
    filterData(type, value) {
   
  }

  save(model) {
    let modelList=[];
    modelList.push(model.value);
    const queryParams: any[] = [];
    // queryParams.push('BPE-FSME');
    let appCd: string = '';
    appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    let fsmeschedObj: any = [];
    modelList.map(mdl => {
      mdl.dirtyStatus =  mdl.bpeFsmeScdlId !== '' ? 'UPDATE' : 'INSERT';
      mdl.bpeFsmeScdlId = mdl.bpeFsmeScdlId === '' ? null : parseInt(mdl.bpeFsmeScdlId);
      mdl.appType = appCd;
      mdl.fsclYr = mdl.fsclYr;
      fsmeschedObj.push(mdl);
    });
     

    this.apiManager.fetchData('savefsmeschedule', undefined, ApiManager.POST, fsmeschedObj, undefined, undefined, queryParams)
      .subscribe(
        res => {
          this.findRespSource = res.bpeFsmeSchdlModel;
          this.dialogRef.close(res);
        });
  }
}

export interface PeriodicElement {
  name: any;
  // position: any;
  weight: any;
  symbol: any;
  LeadReviewer: any;
  EPEU: any;
  ConfirmationMemo: any;
  // ReminderEmail: any;
  DatesofOnsiteReview: any;
  EntranceConferenceDate: any;
  TentativeDateofExitConference: any;
  AdHocDatamonth: any;
  CorrectiveActionPlansDueDates: any;
}

const ScheduleData: PeriodicElement[] = [
  {
    //////////////// position: '11/13/2019',
    name: '10/19/2019',
    weight: 'Anne Arundel County:Glen Burnie',
    symbol: 'Kortni',
    LeadReviewer: 'Donyearl',
    EPEU: 'Vivian',
    ConfirmationMemo: '11/2/2019',
    // ReminderEmail: '',
    DatesofOnsiteReview: '12/2/19-12/6/19',
    EntranceConferenceDate: '11/18/2019',
    TentativeDateofExitConference: '12/7/2019',
    AdHocDatamonth: '',
    CorrectiveActionPlansDueDates: '1/7/2020',
  }
];

@Component({
  selector: 'fsme-schedule-dialog',
  templateUrl: 'fsme-schedule-view.html',
  styleUrls: ['./fsme-schedule.component.css']
})

export class FSMEViewDialog implements OnInit {
  fsmeschedule: FormGroup;
  findRespSource: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    if (this.data.index > -1) {
      this.findRespSource.push(this.data.data);
    }
  }

  selection = new SelectionModel(true, []);

  ReviewersList: rvwind[] =
    [
      { value: "1", viewValue: "Kortni" },
      { value: "2", viewValue: "Brandi" },
      { value: "3", viewValue: "Donyearl" },
      { value: "4", viewValue: "Vivian" },
    ];

  LeadReviewers: LeadReviewer[] = [
    { value: "1", viewValue: "Kortni" },
    { value: "2", viewValue: "Brandi" },
    { value: "3", viewValue: "Donyearl" },
    { value: "4", viewValue: "Vivian" },
  ];

  scheduledisplayedColumns: string[] = [
    // 'position',
    'symbol', 'fsmeGenDueDt', 'LeadReviewer',
    'EPEU',
    'cnfrmtnMemo',
    // 'ReminderEmail',
    'DatesofOnsiteReview', 'entranceConfDt',
    'TentativeDateofExitConference',
    'CorrectiveActionPlansDueDates',
    'AdHocDatamonth',
  ];
  managedisplayedColumns: string[] = [
    // 'position',
    'symbol', 'fsmeGenDueDt', 'LeadReviewer',
    'EPEU',
    'cnfrmtnMemo',
    // 'ReminderEmail',
    'DatesofOnsiteReview', 'entranceConfDt',
    'TentativeDateofExitConference',
    'CorrectiveActionPlansDueDates',
    'AdHocDatamonth',
  ];
  getTypeValue(cd) {
    let typeValue;
    this.ReviewersList.filter(item => item.value == cd).map(val => {
      typeValue = val.viewValue;
    })
    return typeValue;
  }

  getLeadReviewerTypeValue(cd) {
    let typeValue;
    this.LeadReviewers.filter(item => item.value == cd).map(val => {
      typeValue = val.viewValue;
    })
    return typeValue;
  }

  scheduledataSource = this.scheduledisplayedColumns;


  // @ViewChild(MatSort, { static: true }) sort: MatSort;

}