import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-assistance-unit-marriage',
  templateUrl: './assistance-unit-marriage.component.html',
  styleUrls: ['./assistance-unit-marriage.component.css']
})
export class AssistanceUnitMarriageComponent  extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}

