import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';
import { TextMaskModule } from 'angular2-text-mask';


interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-qc-appointment-letter',
  templateUrl: './qc-appointment-letter.component.html',
  styleUrls: ['./qc-appointment-letter.component.css']
})

export class QcAppointmentLetterComponent extends BaseComponent implements OnInit {

  householdMembers: any;

  districtOffice: '';
  ldssOfficeName: '';

  ngOnInit() {

    this.householdMembers = this.constantsService.householdMembers;
    this.householdMembers.map(indivId => {
      if (indivId.headOfHsehldInd == 'Y') {
        if (!this.getControlValue('500101.2')) {
          this.setControlValue('500101.2', indivId.indvdlId);
        }
        this.setControlValue('500101.2a', indivId.address1),
          this.setControlValue('500101.2b', indivId.address2)
      }
    });
    super.ngOnInit();
    this.districtOffice = this.getControlValue('500101.22');
    this.ldssOfficeName = this.getControlValue('500101.25');

    this.checkValue(this.group.controls['500101.12'].value);
    this.determineAccess();
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();
    if ((ref.accessMap['/qualityControl/questionary-500101?type=500101'] != null && ref.accessMap['/qualityControl/questionary-500101?type=500101'].accessMode != 'rw')) {
      this.isNotEditable = true;
    }
  }

  checkValue(event: any) {
    if(event == "at your local Department of Social Services"){
      this.setControlValue('500101.22', this.districtOffice);
      this.setControlValue('500101.25', this.ldssOfficeName);
    }
    else  if(event == "HomeVisit"|| event == "Telephone" || event == "AgreedLocation" || event == null ) {
      this.setControlValue('500101.22', '');
      this.setControlValue('500101.25', '');
    }
   
  }

  convertSpanishPDF() {
    this.callGenerateSpanishPDF.emit(undefined);
  }
}