import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../components/field.interface";
import { NavigationService } from '../../common/navigation.service';
@Component({
  selector: "app-select",
  template: `
  <div class="row" [ngClass]="{'alt-strips': oddEven}">

    <div *ngIf="showQDesc" class="col-sm-8">
      <mat-label>{{field.qDesc}}</mat-label>
    </div>
  <div class="col-sm-4">
  <mat-form-field class="fullWidth" appearance="outline" [formGroup]="group">
  <mat-select [placeholder]="field.label" [formControlName]="field.name">
  <mat-option *ngFor="let entity of field.options;
  let i = index" [value]="entity.code">{{entity.value}}</mat-option>
  </mat-select>
  </mat-form-field>
  </div>
 
  </div>
`,
  styles: []
})
export class SelectComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  showQDesc: boolean = true;

  get oddEven() {
    
    let reminder = parseInt(this.field.name.split('.')[this.field.name.split('.').length-1]) % 2;
    if (reminder === 0 && this.field.qmode === 'OP'
    && this.navigationService.appCd !== 'QC' ) {
        return true;
    } else if (reminder === Math.round(reminder)) {
        return false;
    }

  }

  constructor(
    public navigationService: NavigationService,
  ) {}
  ngOnInit() {}
}
