import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-ssi-benefits',
  templateUrl: './ssi-benefits.component.html',
  styleUrls: ['./ssi-benefits.component.css']
})
export class SsiBenefitsComponent extends BaseComponent implements OnInit {
isNotEditable: boolean =false
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.changeQf333_1(this.getControlValue('333.1'));
  }
  get Is333_1(): boolean {
    return this.group.controls['333.1'].value === 'N';
  }
  changeQf333_1(val) {
    if ( val === 'N') {
      this.group.controls['333.2'].disable();
      this.group.controls['333.3'].disable();
      this.group.controls['333.4a'].disable();
      this.group.controls['333.4b'].disable();
      this.group.controls['333.4c'].disable();
      this.group.controls['333.4d'].disable();
      
    }else {
      this.group.controls['333.2'].enable();
      this.group.controls['333.3'].enable();
      this.group.controls['333.4a'].enable();
      this.group.controls['333.4b'].enable();
      this.group.controls['333.4c'].enable();
      this.group.controls['333.4d'].enable();
      
    }
  }
}
