import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../../shared-module/baseComponent.component";
import { NavigationService } from "../../common/navigation.service";
@Component({
  selector: "app-residency",
  templateUrl: "./residency.component.html",
  styleUrls: ["./residency.component.css"],
})
export class ResidencyComponent extends BaseComponent implements OnInit {
  navigationService: any = NavigationService;
  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.customIsEditable =
      this.constantsService.selectedIndividual.headOfHsehldInd === "Y"
        ? false
        : true;
  }
  setHomeAddress() {
    this.setControlValue("140.6a", this.getControlValue("140.01a"));
    this.setControlValue("140.6b", this.getControlValue("140.01b"));
    this.setControlValue("140.6c", this.getControlValue("140.01c"));
    this.setControlValue("140.6d", this.getControlValue("140.01d"));
  }
  setMailAddress() {
    this.setControlValue("140.7a", this.getControlValue("140.02a"));
    this.setControlValue("140.7b", this.getControlValue("140.02b"));
    this.setControlValue("140.7c", this.getControlValue("140.02c"));
    this.setControlValue("140.7d", this.getControlValue("140.02d"));
  }

  setHomeAddressForQstn7() {
    this.setControlValue("140.7a", this.getControlValue("140.01a"));
    this.setControlValue("140.7b", this.getControlValue("140.01b"));
    this.setControlValue("140.7c", this.getControlValue("140.01c"));
    this.setControlValue("140.7d", this.getControlValue("140.01d"));
  }

  get Is140_1(): boolean {
    return this.group.controls["140.1"].value === "N";
  }
  get Is140_4(): boolean {
    return this.group.controls["140.4"].value === "N";
  }

  // determineAccess() {
  //   const ref = this.constantsService.getSecurityObject();

  //   if ((ref.accessMap['/qualityControl/questionary-Resdcy?type=140'] != null && ref.accessMap['/qualityControl/questionary-Resdcy?type=140'].accessMode != 'rw')) {
  //       this.isNotEditable = true;
  //   }
  // }
  handleQ140_16(val) {
    if (val === "99") {
      this.group.controls["140.16a"].enable();
    } else {
      this.group.controls["140.16a"].disable();
    }
  }
}
