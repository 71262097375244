import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-student-status',
  templateUrl: './student-status.component.html',
  styleUrls: ['./student-status.component.css']
})

export class StudentStatusComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.changeof111_1(this.getControlValue('111.1'));
    this.changeof111_2(this.getControlValue('111.2'));
    this.changeof111_01();
  }

  changeof111_1(val:any){
    if(val === 'N') {
      this.group.controls['111.2'].disable();
      this.group.controls['111.3a'].disable();
      this.group.controls['111.3b'].disable();
      this.group.controls['111.3c'].disable();
      this.group.controls['111.3d'].disable();
      this.group.controls['111.3e'].disable();
      this.group.controls['111.3f'].disable();
      this.group.controls['111.3g'].disable();
      this.group.controls['111.3h'].disable();
    } else  if(val === 'Y') {
      this.group.controls['111.2'].enable();
      this.group.controls['111.3a'].enable();
      this.group.controls['111.3b'].enable();
      this.group.controls['111.3c'].enable();
      this.group.controls['111.3d'].enable();
      this.group.controls['111.3e'].enable();
      this.group.controls['111.3f'].enable();
      this.group.controls['111.3g'].enable();
      this.group.controls['111.3h'].enable();

    }
  }
  changeof111_2(val:any){
    if(val === 'N') {
      this.group.controls['111.3a'].disable();
      this.group.controls['111.3b'].disable();
      this.group.controls['111.3c'].disable();
      this.group.controls['111.3d'].disable();
      this.group.controls['111.3e'].disable();
      this.group.controls['111.3f'].disable();
      this.group.controls['111.3g'].disable();
      this.group.controls['111.3h'].disable();
    }
    else  if(val === 'Y'){
      this.group.controls['111.3a'].enable();
    this.group.controls['111.3b'].enable();
    this.group.controls['111.3c'].enable();
    this.group.controls['111.3d'].enable();
    this.group.controls['111.3e'].enable();
    this.group.controls['111.3f'].enable();
    this.group.controls['111.3g'].enable();
    this.group.controls['111.3h'].enable();

    }
  }
  
  changeof111_01() {
    const q111_01 = this.getControlValue('111.01');
    if (q111_01 === 'FS') {
      this.setControlValue('111.01a', '0');
    } else if (q111_01 === 'NO') {
      this.setControlValue('111.01a', '0');
    } else if (q111_01 === 'FT') {
      this.setControlValue('111.01a', '1');
    } else if (q111_01 === 'HT') {
      this.setControlValue('111.01a', '1');
    } else if (q111_01 === 'PT') {
      this.setControlValue('111.01a', '1');
    } else if (q111_01 === 'JC') {
      this.setControlValue('111.01a', '0');
    } else {
      this.setControlValue('111.01a', '');
    }
  }
  // determineAccess() {
  //   const ref = this.constantsService.getSecurityObject();

  //   if ((ref.accessMap['/qualityControl/questionary-StSts?type=111'] != null && ref.accessMap['/qualityControl/questionary-StSts?type=111'].accessMode != 'rw')) {
  //       this.isNotEditable = true;
  //   }
  // }
}