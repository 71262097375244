import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-recipient-claims',
  templateUrl: './recipient-claims.component.html',
  styleUrls: ['./recipient-claims.component.css']
})
export class RecipientClaimsComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }
}
