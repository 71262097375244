
import { catchError } from 'rxjs/operators';
import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantsService } from '../../common/constants.service';
import { DataShareService } from '../../common/data-share.service';
import { AppUtilService } from '../../common/app.util.service';
import { ApiManager } from '../../common/api-manager.service';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { DocumentSelectionInfo, DocumentUploadInfo, AllDocumentSelectionInfo } from '../../model/api/document-selection.model';

import { DocumentDetails } from '../../model/api/document-details.class';

import { Observable } from 'rxjs';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver/FileSaver';

import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { NavigationService } from '../../common/navigation.service';
import { PrintService } from 'src/app/common/print.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-case-document',
  templateUrl: './case-document.component.html',
  styleUrls: ['./case-document.component.css']
})
export class CaseDocumentComponent implements OnInit {
  isNotEditable: boolean = false;

  @Output() uploaded: EventEmitter<any> = new EventEmitter();
  // currentFiles: any;
  lkptyp = null;
  lkpshrtval = null;
  filesToDownload: DocumentSelectionInfo[] = [];
  filesToUpload = [];
  uploadedFiles: any[] = [];
  subElements: any = [];
  documentArrayRefrence: any = [];
  docNames: DocumentDetails[] = [];
  uploadProgress: any;
  fileUploading: Boolean = false;
  fileUploaded: Boolean = false;
  memberSelected: string;
  queryParams: string[] = [];
  caseId: string = null;
  individualId: string = null;
  verificationName: string = null;
  errorAlert: string = null;
  hasError: boolean = null;
  documentOptionsName: String = '';
  commonDocumentType = '';
  private genericDisplayString = 'File';
  maxfileCount: number = undefined;
  totalCount: number = 0;
  disableAddDocumentButton: boolean = false;
  readOnly = false;
  docTypeChange: boolean = false;
  subtype = null;
  dateLimit = '2000-02-01';
  // carResource = new DataTableResource(cars);
  docResource;
  docs = [];
  docCount = 0;
  // householdMembers: HouseholdMemberVO[] = [];
  // @ViewChild(DataTable) docsTable: DataTable;
  isBulkDelete: boolean = false;
  docInfo: FormGroup;
  noOfDocs;
  checkBoxValues = [];
  savedStatus: boolean = false;
  docMetadata = [];
  showDownloads: boolean = true;
  beans = [];
  deathVerification = 'deathVerification';
  raceVerification = 'raceVerification';
  livingArrangement = 'livingArrangement';
  pregnancyVerification = 'pregnancyVerification';
  lookupTypeCd;
  fileName:string=null;
  docOptions =  new Map([
    ['Agreement','v_agreement'],
   ['Application','v_application'],
 ['Assets','v_assets'],
 ['Clearances','v_clearances'],
 ['Correspondence','v_correspondence'],
 ['Disability Benefits Advocacy','v_disabilitybenefitsadvocacy'],
 ['Disqualifications','v_disqualifications'],
 ['Expense','v_expense'],
 ['Fiscal','v_fiscal'],
 ['Identity','v_identity'],
 ['Income','v_income'],
 ['Legal','v_legal'],
 ['LTC Forms','v_ltcforms'],
 ['MCP Special Programs','v_mcpspecialprograms'],
 ['Medical','v_medical'],
 ['Quality Control','v_qualitycontrol'],
 ['Redeterminations','v_redeterminations'],
 ['Referrals','v_referrals'],
 ['Verifications','v_verifications'],
 ['Work Program','v_workprogram']
]);
  
  globalCategoryList = [];
  globalCategoryObj = {};
  subtypeSearch = null;
  subTypeSearchCd = null;
  // createdName: PersonName;

  displayedColumns: string[] = ['select', 'individualName', 'docName', 'docCatagory', 'docDesc', 'createdBy', 'createdDt', 'action'];
  dataSource: any
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  searchDocEvent: any;
  public householdList: any;

  constructor(
    private fb: FormBuilder,
    public constantsService: ConstantsService,
    private _utilService: AppUtilService,
    private apiManager: ApiManager,
    private router: Router,
    private shareService: DataShareService,
    private printService: PrintService,
    public navigationService: NavigationService,
    public dialog: MatDialog,
    public _snackBar: MatSnackBar
  ) {
    this.documentOptionsName = '';
    this.queryParams.push(this.currentIndividual);

    this.apiManager.getLoaderStatus().subscribe(lStatus => {
      if (lStatus === false) {

      }
    });

  }

  get currentIndividual() {
    return this.shareService.getDataFromMap('currentIndividual');
  }


  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  // /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  ngOnInit() {
    this.constructDocumentForm();
    this.getDocsForCase();

  }

  
  changeType(event, docEvent, search) {
    this.lookupTypeCd = event.value;
    if (search == 'search') {
      this.subtypeSearch = this.globalCategoryObj[docEvent];
      this.searchDocEvent = docEvent
      this.subTypeSearchCd = '';
    } else {
      console.log('this.globalCategoryObj==>', this.globalCategoryObj)
      //  let subCatgry = docEvent.slice(docEvent.indexOf(': ') + 2);
      console.log("val:", this.docOptions.get(event.value));
      let index = this.subElements.findIndex(x => x.lookUpName === this.docOptions.get(event.value));
      this.subtype = this.subElements[index].options;
      console.log(this.subtype);
      // this.globalCategoryObj[docEvent];
      // this.docInfo.get('docSubtype').setValue(this.subtype[0].lookupBean.code);
      //this.docInfo.get('docSubtype').setValue("");
    }
  }

  changeSubType(event, st, search) {
    let getSubTypeList = this.subtype.map(subType => subType.lookupBean);
    let getSelectedSubType = getSubTypeList.filter(subType => subType.code === event.value);
    if (search == 'search') {
      let varNum = (<HTMLSelectElement>document.getElementById('docSubTypeSearchId')).selectedIndex;
      this.subTypeSearchCd = getSelectedSubType.length > 0 ? getSelectedSubType[0].code : null;;
    } else {
      let varNum = (<HTMLSelectElement>document.getElementById('docSubtypeId')).selectedIndex;

      this.lkptyp = getSelectedSubType.length > 0 ? getSelectedSubType[0].type : null;
      this.lkpshrtval = getSelectedSubType.length > 0 ? getSelectedSubType[0].longLabel : null;
    }
  }

  getdocumentTypeCdName(category, name) {

    let filterBasedCategory = this.globalCategoryList.filter(categoryname => categoryname.name === category);
    if (filterBasedCategory.length == 0) {
      return ''
    }
    let categoryBaseName = filterBasedCategory[0].list.reduce((all, item, index) => {
      if (item.lookupBean.code == name) {
        all = item.lookupBean.longLabel
      }
      return all;
    }, '')
    return categoryBaseName;
  }



  getDocsForCase() {
    const queryParams: any[] = [];
    queryParams.push(this.constantsService.ReviewInfo.rvwNum);

    this.apiManager.fetchData(
      'documentsForACaseUrl',
      undefined,
      ApiManager.GET,
      undefined,
      undefined,
      undefined,
      queryParams).pipe(catchError((Error: Response | any) => {
        const errMsg = Error.toString();
        let inputVal = (<HTMLInputElement>document.getElementById("input_0"));
        if (inputVal)
          inputVal.value = '';
        return errMsg;
      })).subscribe(res => {
        let filteredRes = [];
        this.docMetadata = res.docDetails;
        this.subElements = res.screenLvlLookup;
        this.getDocSelectionInfo();
        // this._utilService.hideLoader();
        if (this.docMetadata.length > 0) {

        }

      });
    // }

  }
 
  isImproperExt(docName){
    let ext = docName.toLowerCase().split('.').pop();
    if(ext == 'jpg' || ext== 'png'|| ext == 'pdf'|| ext == 'tiff' ||  ext == 'tif' || ext == 'jpeg')
      return  false;
    else 
      return true;      
    return false;
  }

  viewFile(docName, fileId: string, type: any) {
    let ext = docName.toLowerCase().split('.').pop();
    // ext = ext.toLowerCase();
    let indviId: any;
    let filename: any;
    let ownerRepo: any;
    let ownerSystem: any;
    this.docMetadata.map(ele => {
      if (ele.docId == fileId) {
        indviId = ele.individualId;
        filename = ele.docName;
        ownerRepo = ele.ownerRepo;
        ownerSystem = ele.ownerSystem;
      }
    });
    let that = this;
    this._utilService.showLoader();

    if (ext == 'docx' || ext == 'tif') {
      let roles = this.constantsService.getSecurityObject();
      let requestInfo = {
        fileId: fileId,
        individualId: this.currentIndividual,
        docTypeCd: type ? type : this.commonDocumentType,
        ownerRepo: ownerRepo,
        ownerSystem: ownerSystem,
        fileType: ext,
        "role": []
      };
      if (roles ? roles.user ? true : false : false) {
        roles.user.roles.forEach(element => {
          requestInfo.role.push(element)
        });
      }
      this.apiManager.fetchData(
        'viewFile',
        {},
        ApiManager.FILEDOWNLOAD,
        requestInfo,
        undefined,
        undefined,
        this.queryParams
      ).subscribe(res => {
       // this._utilService.hideLoader();

        let blob = new Blob([res], { type: res.type });
        let fileURL = URL.createObjectURL(blob);
        var newWin = window.open(fileURL);
        if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
          alert('To view the report please disabale pop up blocker !');
        }
      },
        error => {
          this.downloadFile(fileId, type);
          this._utilService.hideLoader();
        });
    } else {
      let roles = this.constantsService.getSecurityObject();
      let requestInfo = {
        fileId: fileId,
        individualId: this.currentIndividual,
        docTypeCd: type ? type : this.commonDocumentType,
        ownerRepo: ownerRepo,
        ownerSystem: ownerSystem,
        "role": []
      };
      if (roles ? roles.user ? true : false : false) {
        roles.user.roles.forEach(element => {
          requestInfo.role.push(element)
        });
      }
      this.apiManager.fetchData(
        'fileDownload',
        {},
        ApiManager.FILEDOWNLOAD,
        requestInfo,
        undefined,
        undefined,
        this.queryParams
      ).subscribe(res => {
        //this._utilService.hideLoader();

        let blob = new Blob([res], { type: res.type });
        let fileURL = URL.createObjectURL(blob);
        var newWin = window.open(fileURL);
        if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
          alert('To view the report please disabale pop up blocker !');
        }
      });
    }
  }

  downloadFile(fileId: string, type: any) {
    let indviId: any;
    let filename: any;
    let ownerRepo: any;
    let ownerSystem: any;
    this.docMetadata.map(ele => {
      if (ele.docId == fileId) {
        indviId = ele.individualId;
        filename = ele.docName;
        ownerRepo = ele.ownerRepo;
        ownerSystem = ele.ownerSystem;
      }
    });
    let that = this;
    // this._utilService.showLoader();
    let roles = this.constantsService.getSecurityObject();
    let requestModel = {
      'fileId': fileId,
      'individualId': this.currentIndividual,
      'docTypeCd': type ? type : this.commonDocumentType,
      "ownerRepo": ownerRepo,
      "ownerSystem": ownerSystem,
      "role": []
    };
    if (roles ? roles.user ? true : false : false) {
      roles.user.roles.forEach(element => {
        requestModel.role.push(element)
      });
    }
    this.apiManager.fetchData(
      'fileDownload',
      {},
      ApiManager.FILEDOWNLOAD,
      requestModel,
      undefined,
      undefined,
      this.queryParams
    ).subscribe(res => {
      // this._utilService.hideLoader();
      saveAs(res, filename);
    });
  }

  saveToFNS(fileId: string, type: any) {
    let indviId: any;
    let filename: any;
    let ownerRepo: any;
    let ownerSystem: any;
    let rvwNum: any;
    this.docMetadata.map(ele => {
      if (ele.docId == fileId) {
        indviId = ele.individualId;
        filename = ele.docName;
        ownerRepo = ele.ownerRepo;
        ownerSystem = ele.ownerSystem;
      }
    });
    let ext = filename.toLowerCase().split('.').pop();
    let roles = this.constantsService.getSecurityObject();
    let requestModel = {
      'fileId': fileId,
      'individualId': this.currentIndividual,
      'rvwNum':this.constantsService.ReviewInfo.rvwNum,
      'fileName':filename,
      'docTypeCd': type ? type : this.commonDocumentType,
      "ownerRepo": ownerRepo,
      "ownerSystem": ownerSystem,
      "role": []
    };
    if (roles ? roles.user ? true : false : false) {
      roles.user.roles.forEach(element => {
        requestModel.role.push(element)
      });
    }
    this.apiManager.fetchData( 
      'saveToFNS',
      {},
      ApiManager.FILEDOWNLOAD,
      requestModel,
      undefined,
      undefined,
      this.queryParams
    ).subscribe(
      (res: any) => {
        this._snackBar.open('Move to FNS Review Summary Successful.', undefined, {
          duration: 2000,
        });
        this.ngOnInit();
      },
      error => {
        alert('Found Error while Move to FNS Review Summary!');
      }
    );
  }

  getDocSelectionInfo() {

    this.docs = [];
    this.filesToDownload = [];
    if (this.docMetadata) {
      this.docMetadata.map(item => {
        let tempSvc = new AllDocumentSelectionInfo(item.docId, item.docName, false, item.documentTypeCd, item.createdBy, item.createdDt, item.updatedBy, item.updatedDt, item.individualName, item.docCatagory, item.docDesc, item.individualId, item.ownerRepo, item.ownerSystem);
        this.filesToDownload.push(tempSvc);
      });
      for (var i = 0; i < this.filesToDownload.length; i++) {
        if (this.docs.length == 0) {
          this.docs.push(this.filesToDownload[i]);
        }
        else {
          if (!this.docs.find((x: any) => x.docId == this.filesToDownload[i].docId)) {
            this.docs.push(this.filesToDownload[i]);
          }
        }
      }
      // this.docs = this.filesToDownload;
      console.log('gotSelectedInfo====>', this.docs)
      this.docMetadata = this.docs.slice();
      console.log("docs", this.docs.slice());
      this.docs.map(ele => {
        ele.documentTypeCd = (ele.docName.indexOf('DHMH-4233') != -1 || ele.docName.indexOf('DHMH-4210') != -1 || ele.docName.indexOf('DHMH-4235') != -1 || ele.docName.indexOf('DHMH-4236') != -1 || ele.docName.indexOf('DHMH-4235A') != -1 || ele.docName.indexOf('DHMH-4235B') != -1 || ele.docName.indexOf('DHMH-4235C') != -1 || ele.docName.indexOf('DHMH-4240') != -1) ? 'LENT' : ele.documentTypeCd;
        ele.documentTypeCd = (ele.docName.indexOf('DHMH-4227') != -1 || ele.docName.indexOf('DHMH-4229') != -1) ? 'LTN' : ele.documentTypeCd;
        ele.documentTypeCd = (ele.docCatagory == 'Application' && ele.docDesc == 'LTC-Application') ? 'ALTCA' : ele.documentTypeCd;
        ele.documentTypeCd = (ele.docCatagory == 'Correspondence' && (ele.docDesc == 'MA' || ele.docDesc == 'REQUEST FOR NON-COVERED SERVICES PRE/POST-ELIGIBILITY DEDUCTIONS')) ? 'ALTCA' : ele.documentTypeCd;
        ele.documentTypeCd = (ele.docCatagory == 'Correspondence' && (ele.docDesc == 'MARYLAND MEDICAL ASSISTANCE PROGRAM NOTICE OF ELIGIBILITY' || ele.docDesc == 'NOTICE OF CHANGE OF AVAILABLE INCOME')) ? 'LENT' : ele.documentTypeCd;
        ele.documentTypeCd = (ele.docCatagory == 'Correspondence' && ele.docDesc == 'FAMILY INVESTMENT ADMINISTRATION LTC MA REQUEST FOR INFORMATION TO VERIFY ELIGIBILITY') ? 'LVELG' : ele.documentTypeCd;
      });
      this.dataSource = new MatTableDataSource(this.docs.slice());
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      // this.docResource = new DataTableResource(this.docs.slice());
      // this.docResource.count().then(count => this.docCount = count);
      this.showDownloads = true;
    }
  }


  getIndividualName(individualId) {
    let personName = null;
    return personName === null ? "Case Related" : personName;
  }

  constructDocumentForm() {
    this.docInfo = this.fb.group({
      docType: [this.docOptions.get("Agreement")],
      docSubtype: [null],
      hhMember: [null]
      // docUploadFormArr: this.fb.array([])
    })

    // this.addDocForm();
  }
  
  matchString(source, input) {
    let match = true;
    for (let i = 0; (i < source.length && source[i] != '%'); i++) {
      if (input[i] !== source[i]) {
        match = false;
      }
    }
    return match;
  }

  checkUnsupportedDoc(event) {
    let unsupportedDoc = false;
    for (let file of event.target.files) {
      if (file.type === 'text/javascript' || this.matchString('audio%', file.type) || this.matchString('video%', file.type)
        || file.type === 'application/x-msdownload'|| file.type === 'png') {
        unsupportedDoc = true;
        break;
      }
    }
    return unsupportedDoc;
  }


  duplicateFileNamesExist(event) {
    let duplicateExists = false;
    for (let file of event.target.files) {
      for (let downloadFile of this.filesToDownload) {
        if (downloadFile.docName === file.name) {
          duplicateExists = true;
          break;
        }
      }
      if (duplicateExists)
        break;
    }
    return duplicateExists;
  }

  gotoScan() {

  }

  deleteFile(file) {
  }
  print(): void {
    let printContents, printMember;
    printContents = document.getElementById('print-section');
    printMember = document.getElementById('print-member');
    //this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    this.printService.printTable = printContents ? printContents.innerHTML : null;
    this.printService.print();
  }
  onFileChange(event) {
    //if (this.isNotEditable) {
   //   return true;
    // }

    let duplicateExist = this.duplicateFileNamesExist(event);
    let unsupportedDocExist = this.checkUnsupportedDoc(event);
    if (duplicateExist) {
      alert("Please upload files with unique names");
      return null;
    }
    if (unsupportedDocExist) {
      alert("You are uploading an unsupported Document(.js/.exe/.mov/.mp4), please check and reupload.");
      return null;
    }


    this.filesToUpload = [];
    if (event.target.files.length) {
      for (let file of event.target.files) {

        let entry: DocumentUploadInfo = {
          docName: file.name,
          documentTypeCd: this.docInfo.get('docSubtype').value,
          file: file
        }
        this.filesToUpload.push(entry);
      }
    }

    let inputVal = (<HTMLInputElement>document.getElementById("fileInput"));
    setTimeout(() => {
      inputVal.value = "";
    }, 1000);
    this.fileUpload();
  }


  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  }

  async fileUpload() {
    await this.asyncForEach(this.filesToUpload, async (file) => {
      console.log("file", file);
      await this.singleFileUpload(file);
    })
  }

  async singleFileUpload(file) {
    console.log("FILE UPLOADING");
    const uploadFile = file;
    this.hasError = false;
    if (!uploadFile) {
      this.hasError = true;
      this.errorAlert = 'Error: Unexpected Error occured. File couldn\'t be found.';
    } else if (uploadFile.docName.length > 100) {
      this.hasError = true;
      this.errorAlert = 'Error: File Name is too long.';
    }

    if (!this.hasError) {
      let fileReader = new FileReader();
      this.fileUploading = true;
      this.fileUploaded = false;
      this.apiManager.fileUploadtProgress$.subscribe(data => {
        this.uploadProgress = data;
      }
      );
      fileReader.readAsArrayBuffer(uploadFile.file);
      let zipFile: JSZip = new JSZip();
      let that = this;

      fileReader.onload = async function () {
        zipFile.file(uploadFile.docName, fileReader.result);
        zipFile.generateAsync({
          type: 'blob',
          compression: 'DEFLATE',
          compressionOptions: {
            level: 9
          },
          mimeType: uploadFile.type
        }).then(async (blob) => {
          // const file: File = new File([blob], uploadFile.docName, { type: 'application/zip' });
          await that.uploadDocument(uploadFile.file, uploadFile);
        });
      }
    }
  }

  delay() {
    return new Promise(resolve => setTimeout(resolve, 300));
  }

  async delayedLog(params: string) {
    await this.delay();
    console.log(params);
  }

  async processArray(array) {
    array.forEach(async (item) => {
      await this.delayedLog(item);
    })
    console.log("done@");
  }

  syncDocuments() {
    const queryParams: any[] = [];
    if (this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }
    this.apiManager.fetchData(
      'syncDocumentsForACase',
      undefined,
      ApiManager.GET,
      undefined,
      undefined,
      undefined,
      queryParams).pipe(catchError((Error: Response | any) => {
        const errMsg = Error.toString();
        let inputVal = (<HTMLInputElement>document.getElementById("input_0"));
        if (inputVal)
          inputVal.value = '';
        return errMsg;
      })).subscribe(res => {
        let filteredRes = [];
        this.docMetadata = res.docDetails;
        this.subElements = res.screenLvlLookup;
        this.getDocSelectionInfo();
      });
  }

  uploadDocument(file, uploadObj) {
    //if (this.isNotEditable) {
    //   return true;
    // }
    this._utilService.showLoader();

    let lkpObj =  this.subtype.find(x => x.code == uploadObj.documentTypeCd);

    console.log("file:", file);
    console.log("uploadObj:", uploadObj);

    this.apiManager.fetchData(
      'fileUpload',
      {
        'fileFormData': file,
        'individualId': this.docInfo.get('hhMember').value,
        'verificationName': this.verificationName,
        'documentTypeCd': uploadObj.documentTypeCd,
        'lookupShortVal': lkpObj.value,
        'lookupTypeCd': this.lookupTypeCd
      },
      ApiManager.FILEUPLOAD,
      undefined,
      undefined,
      () => {
        this.fileUploading = false;
        console.log("FILE FINISHED UPLOADING");
      },
      this.queryParams
    ).subscribe(res => {
     let docdtls = res.docDetails;
      if (docdtls.fileName) {
        if (docdtls.caseId) {
          this.totalCount = this.totalCount + 1;
          if (this.maxfileCount != undefined && (this.maxfileCount <= this.totalCount)) {
            this.disableAddDocumentButton = true;
          }
          let inputVal = (<HTMLInputElement>document.getElementById("fileInput"));
          inputVal.value = '';
          let _fileName = this.genericDisplayString + (this.uploadedFiles.length + 1);
          let tempSvc = new AllDocumentSelectionInfo(docdtls.fileId, docdtls.fileName, false, docdtls.docTypeCd, docdtls.createUserId, docdtls.createDt, docdtls.updatedUserId, docdtls.updatedDt,docdtls.individualName, docdtls.docCatagory, docdtls.docDesc, docdtls.individualId, docdtls.ownerRepo, docdtls.ownerSystem);
          this.docMetadata.length = 0;
          this.uploadedFiles.length = 0;
          this.uploadedFiles.push(tempSvc);
          this.docMetadata.push(tempSvc);
          this.docs = this.docMetadata.slice();
          this.dataSource = new MatTableDataSource(this.docs);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          console.log('fromService====>', this.docs);
          this.docResource = this.docMetadata;
          this.docResource.count().then(count => this.docCount = count);
          this.showDownloads = true;

          let thisRef = this;
          setTimeout(() => {
            thisRef.uploadedFiles = [];
          }, 2000);
        } else {
          alert(docdtls.fileName + " is infected, please upload good document");
        }
      }
      this._utilService.hideLoader();
      this.fileUploading = false;
      this.fileUploaded = true;
      this.ngOnInit();
    });
  }

}

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

interface UploadResponse {
  docId: number;
  docName: string;
  documentTypeCd: string;
}


