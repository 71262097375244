
import { map } from 'rxjs/operators';
// import { LookupContainerModel } from './../model/api/lookup-container-model.class';
// import { ApiManager } from 'app/common/api-manager.service';
import { Router, Event as RouterEvent, NavigationStart, NavigationEnd } from '@angular/router';
import { FormControl } from '@angular/forms';
// import { HouseholdMember } from './../model/api/household-member.class';
import { Injectable } from '@angular/core';
import { ConstantsService } from '../common/constants.service';
// import { DataShareService } from '../common/data-share.service';
// import { LocalizedLookupBean } from 'app/model/api/localized-lookup-bean.class';
// import { KeyValue } from './../model/api/key-value.class';
// import { Address } from '../model/api/address.class';
// import { PersonName } from '../model/api/person-name.class';
// import { HouseholdVerification } from '../model/api/household-verification.class';
// import { ClientVerification } from '../model/api/client-verification.class';
// import { ApiMessage } from '../model/api/api-message.class';
// import { SocialSecurityNumber } from '../model/api/social-security-number.class';
// import { IndividualHouseholdDetails } from '../model/custom/individual-household-details.class';
import { StorageService } from './storage.service';
import { environment } from '../../environments/environment';
import { ApiManager } from './api-manager.service';

// import { validateConfig } from '@angular/router/src/config';
declare var jquery: any;
declare var $: any;
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';

const moment = _rollupMoment || _moment;


@Injectable()
export class AppUtilService {
  statesDropdown: any[];
  public systemDate = null;
  public minDate = null;
  // Used to maintain Title Strip content
  curModule: any;
  curScreen: any;
  public curId: any;
  prevModule: string;
  public reloadCaseHome: boolean = false;
  private logoutUrl: any = environment.logoutURL;
  public isCasehasDocument: boolean = false;
  public isCasehasSpousalResourceAssessments: boolean = false;
    apiManager: any;
  // preModuleCopy: string;

  static isNil(value: any): value is (null | undefined) {
    return value === null || value === undefined || typeof (value) === 'undefined';
  }



  static isObject(value: any): boolean {
    return value && value.constructor === Object;
  }

  static isBlank(value: any): boolean {
    return this.isNil(value) ||
      (this.isObject(value) && Object.keys(value).length === 0) ||
      value.toString().trim() === '';
  }

  static isDataPresent(value: any): boolean {
    return !this.isBlank(value);
  }

  // ES6
  static removeDuplicates(arr) {
    const set = new Set(arr);
    const values = set.values();
    return Array.from(values);
  }

  constructor(
    private router: Router,
    public constantsService: ConstantsService,
    // private shareService: DataShareService,
    // private apiManager: ApiManager,
    private storage: StorageService
    ) {
    router.events.subscribe((event: RouterEvent) => {
      //this.navigationInterceptor(event);
    });
    this.storage.getTimeSubject().subscribe(dt => {
      this.systemDate = dt;
      if (dt)
        window["systemDate"] = dt;
    }
   );
  }

  ssnMaskingCustom(ssn) {

    if (ssn != null) {
      return ssn.substr(0, 3) + '-' + ssn.substr(3, 2) + '-' + ssn.substr(5, 4);
    }

  }

  getMemberNameByIndvId(indvId) {
    let personName = '';
    this.constantsService.householdMembers.map(mem => {
      if (mem.indvdlId === indvId) {
        personName = this.getFullName(mem);
      }
    });

    return personName;
  }

  getFullName(person: any): string {
    let fullName = '';
    if (!person) {
      return fullName;
    }
    if (person.firstNm) {
      fullName += person.firstNm;
    }
    // if (person.middleName) {
    //   fullName += ' ' + person.middleName;
    // }
    if (person.lastNm) {
      fullName += ' ' + person.lastNm;
    }
    
    return fullName;
  }

  showLoader() {
    // ConstantsService.loaderFlag = true;
    ConstantsService.loaderFlag = true;
    setTimeout(() => {
      ConstantsService.loaderFlag = ConstantsService.loaderFlag ? false : false;
    }, 3000);
  }

  hideLoader() {
    ConstantsService.loaderFlag = false;
  }

  getCurrentDate() {
    const tDate: Date = this.storage.getTimeTravelDate();
    if (tDate == undefined || tDate == null) {
      return new Date();
    }
    else {
      return tDate;
    }
  }

  setCurrentDate(dt) {
    this.systemDate = dt;
  }

  changeDateYYYYMMDD(dob: any): any {
    let final: string[];
    if (dob) {
      final = dob.toString().split('T');
      return final[0];
    }
    return dob;
  }

  changeDateFormat(val) {
    let result;
    if ( val ) {
      result = moment(val).format('MM/DD/YYYY');
    }
    return result;
  }

  changeDateToMonthFormat(val) {
    let result;
    if ( val ) {
      result = moment(val).format('YYYYMM');
    }
    return result;
  }

  changeDateToMonthYearFormat(val) {
    let result;
    if ( val ) {
      result = moment(val).format('MMYYYY');
    }
    return result;
  }

  changeDateFormatYYYYMMDD(val) { 
    if(val){
      val = val.toString().replace("T00:00:00.000+0000","");
    } 
    let result;
    if ( val ) {
      result = moment(val).format('YYYY-MM-DD');
    }
    return result;
  }  

  changeDateFormatMMDDYYYY(val) {
    if(val){
      val = val.toString().replace("T00:00:00.000+0000","");
    } 
    let result;
    if ( val ) {
      result = moment(val).format('MM-DD-YYYY');
    }
    return result;
  }

  changeMonthYearFormat(val) {
    let result;
    if ( val ) {
      result = moment(val).format('MM/YYYY');
    }
    return result;
  }
}


