import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-household-boarder',
  templateUrl: './household-boarder.component.html',
  styleUrls: ['./household-boarder.component.css']
})

export class HouseholdBoarderComponent extends BaseComponent implements OnInit {
isNotEditable : boolean =false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

  changeQ150_2_1(val){
  if(val === "N"){
    this.group.controls['150.2.2'].disable();
    this.group.controls['150.2.3'].disable();
    this.group.controls['150.2.5'].disable();
    this.group.controls['150.2.6'].disable();
    this.group.controls['150.2.7'].disable();
    this.group.controls['150.2.9a'].disable();
    this.group.controls['150.2.9b'].disable();
    this.group.controls['150.2.9c'].disable();
    this.group.controls['150.2.10'].disable();
    this.group.controls['150.2.11'].disable();
    this.group.controls['150.2.12'].disable();
    this.group.controls['150.2.13'].disable();
  }else if(val === "Y"){
    this.group.controls['150.2.2'].enable();
    this.group.controls['150.2.3'].enable();
    this.group.controls['150.2.5'].enable();
    this.group.controls['150.2.6'].enable();
    this.group.controls['150.2.7'].enable();
    this.group.controls['150.2.9a'].enable();
    this.group.controls['150.2.9b'].enable();
    this.group.controls['150.2.9c'].enable();
    this.group.controls['150.2.10'].enable();
    this.group.controls['150.2.11'].enable();
    this.group.controls['150.2.12'].enable();
    this.group.controls['150.2.13'].enable();
  }
  }

  calc_allot() {
    
    let in150BoardCalcCountDays;

    const in150BoardCalcCountMeals = +this.getControlValue('150.2.6a');
    const in150BoardMealPer  = +this.getControlValue('150.2.6');

    if (in150BoardMealPer === 1) {
      in150BoardCalcCountDays = 1
        
    }else if (in150BoardMealPer === 2) {
      in150BoardCalcCountDays = 7
    } else {
        // '****************************************
        // '*  Find the nbr of days in Review month
        // '****************************************
     
        const rvwMonth = +this.constantsService?.ReviewInfo?.rvwMo.substring(4);
        const rvwYear = +this.constantsService?.ReviewInfo?.rvwMo.substring(0,4);
        in150BoardCalcCountDays = new Date(rvwYear , rvwMonth, 0).getDate();
        
    }
    this.setControlValue('150.2.9a',in150BoardCalcCountMeals);

    this.setControlValue('150.2.9b',in150BoardCalcCountDays);

    const in150BoardCalcTotalMeals = +(in150BoardCalcCountMeals / in150BoardCalcCountDays).toFixed(2);
    
    this.setControlValue('150.2.9c',in150BoardCalcTotalMeals);

    const in150BoardCalcHhSize = +this.getControlValue('150.2.10');

    const in150BoardCalcShelterAmt = +this.getControlValue('150.2.11');

    let maxAllotHd;
    let in150BoardCalcMaxAllot;
    if (in150BoardCalcTotalMeals >= 2 ) {
        in150BoardCalcMaxAllot = this.getHH_MaxAllot(in150BoardCalcHhSize);
    } else {
        maxAllotHd = this.getHH_MaxAllot(in150BoardCalcHhSize);
        in150BoardCalcMaxAllot = (maxAllotHd * 0.666)
    }

    this.setControlValue('150.2.12',in150BoardCalcMaxAllot);

    let in150BoardMeetAllotVer;
    let in150BoardFspEligVer;
    if( in150BoardCalcShelterAmt >= in150BoardCalcMaxAllot ) {
        in150BoardMeetAllotVer = 'Yes';
        in150BoardFspEligVer = 'Yes';
    } else {
        in150BoardMeetAllotVer = 'No';
        in150BoardFspEligVer = 'Yes';
    }

    this.setControlValue('150.2.13',in150BoardMeetAllotVer);
    this.setControlValue('150.2.14',in150BoardFspEligVer);
  }
}