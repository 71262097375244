import { Component, OnInit } from "@angular/core";
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: "app-nonliquid-assets-office",
  templateUrl: "./nonliquid-assets-office.component.html",
  styleUrls: ["./nonliquid-assets-office.component.css"]
})
export class NonliquidAssetsOfficeComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }
  calc_sum(){
    let amt1 = +this.group.controls['224.5.2.4a'].value;
    let amt2 = +this.group.controls['224.5.2.4b'].value;
    let amt3 = +this.group.controls['224.5.2.4c'].value;
    let total = 0;
    if(amt3 === null){
      total = Math.abs(((+amt1) - (+amt2))) ;
    }else{
      total = Math.abs(((+amt1) - (+amt2)) / ((+amt3 + 1) )) ;
    }
    this.setControlValue('224.5.2.4d',total);
  }
  handleQstns(val){
    if ( val === 'N') {
      this.group.controls['224.5.2.2'].disable();
      this.group.controls['224.5.2.3'].disable();
    }else if(val === 'Y'){
      this.group.controls['224.5.2.2'].enable();
      this.group.controls['224.5.2.3'].enable();
    }

}
}