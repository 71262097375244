import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-dob',
  templateUrl: './dob.component.html',
  styleUrls: ['./dob.component.css']
})

export class DobComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;
  handle_Q110_1:boolean = false;
  
  ngOnInit() {
    super.ngOnInit();
    this.changeof110_1({value: this.group.controls['110.1'].value},false);
    this.determineAccess();
  }
  
  
  calc_110_3(){
    let age = this.ageFromDateOfBirthday(this.group.controls['110.2'].value);
    if(this.group.controls['110.2'].value){
      this.setControlValue('110.3',age);
    }else{
      this.setControlValue('110.3','');
    }
  }
   IsQ110_1(){
     return this.getControlValue('110.1').value === 'Y';
   }

  changeof110_1(event: any,clear) {
    if ( event.value === 'Y') {
      this.setControlValue('110.2',this.getControlValue(110.01));
      this.setControlValue('110.3',this.getControlValue(110.02));
        if(this.getControlValue('110.3')<=17){
          this.group.controls['110.4'].enable();
        } else {
          this.group.controls['110.4'].disable();
        }
      } else if ( event.value === 'N'){
        this.group.controls['110.2'].enable();
        this.group.controls['110.3'].enable();
        this.handle_Q110_1 = true;
        if(clear){
          this.setControlValue('110.2','');
          this.setControlValue('110.3','');
        }
        

      }
  }

    changeof110_4(event: any) {
    if ( this.ageFromDateOfBirthday(event.value) <= 17) {
      this.group.controls['110.4'].enable();
      } else {
        this.group.controls['110.4'].disable();
      }
      this.calc_110_3();
  }

  ageFromDateOfBirthday(dateOfBirth: any): number {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  handleQ110_9(val){
    if(val === 'OT'){
      this.group.controls['110.9a1'].enable();
    }else{
      this.group.controls['110.9a1'].disable();
    }
}

}