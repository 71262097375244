import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-qc13-a-living-arrangments',
  templateUrl: './qc13-a-living-arrangments.component.html',
  styleUrls: ['./qc13-a-living-arrangments.component.css']
})

export class Qc13ALivingArrangmentsComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/qualityControl/questionary-500103?type=500103'] != null && ref.accessMap['/qualityControl/questionary-500103?type=500103'].accessMode != 'rw')) {
        this.isNotEditable = true;
    }
  }
  convertSpanishPDF() {
    this.callGenerateSpanishPDF.emit(undefined);
  }
}