import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-fleeing-felon',
  templateUrl: './fleeing-felon.component.html',
  styleUrls: ['./fleeing-felon.component.css']
})
export class FleeingFelonComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
