import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../components/field.interface";
import { ConstantsService } from 'src/app/common/constants.service';
import { NavigationService } from '../../common/navigation.service';
@Component({
  selector: "app-checkbox",
  template: `
  <div class="row" [ngClass]="{'alt-strips': oddEven}">
  <div *ngIf="false" class="col-sm-2">
    <mat-label>{{field.name}}</mat-label>
  </div>
  <div  *ngIf="showQDesc" class="col-sm-5">
    <mat-label>{{field.qDesc}}</mat-label>
  </div>
  <div class="col-sm-4">
<div class="demo-full-width margin-top" [formGroup]="group" >
<mat-checkbox [formControlName]="field.name"></mat-checkbox>
</div>
</div>
</div>
`,
  styles: []
})
export class CheckboxComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  showQCd: boolean = true;
  showQDesc: boolean = true;

  
  constructor(
    public constantsService: ConstantsService,
    public navigationService: NavigationService,
  ) { }
  ngOnInit() {}

  get oddEven() {

    const reminder = parseInt(this.field.name.split('.')[this.field.name.split('.').length-1]) % 2;
    if (reminder === 0 && this.field.qmode === 'OP'
      && this.navigationService.appCd !== 'QC' ) {
        return true;
    } else if (reminder === Math.round(reminder)) {
        return false;
    }
  }
}
