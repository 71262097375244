import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiManager } from 'src/app/common/api-manager.service';
import { AppUtilService } from 'src/app/common/app.util.service';
import { Location } from "@angular/common";
import { ConstantsService } from 'src/app/common/constants.service';
import { FieldConfig } from 'src/app/components/field.interface';
import { Moment } from 'moment';
import moment from 'moment';

@Component({
  selector: 'app-fsneg-review-log',
  templateUrl: './fsneg-review-log.component.html',
  styleUrls: ['./fsneg-review-log.component.css']
})
export class FsnegReviewLogComponent implements OnInit {
  qcRvwProcessLogTcaForm: FormGroup;
  dsbleByRcrdVldty: boolean;
  subElements: any = [];
  minDate: Moment;
  maxDate: Moment;
  public isNotEditable:boolean = false
  group: FormGroup;
  field: FieldConfig[];
  constructor(
    private fb: FormBuilder,
    public appUtilService: AppUtilService,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    public _location: Location,
  ) {
    this.buildForm();
   }

  ngOnInit() {
    this.minDate = moment().subtract(2, 'days');
    this.maxDate = moment();
    this.getRvwData();
    if( this.getControlValue('elmt')){
      this.changeSubElementByElement('ntrCd', this.getControlValue('elmt'));
      }
  }
  buildForm() {
    this.qcRvwProcessLogTcaForm = this.fb.group({
      // caseId:[''],
      qaPrcLogId:[''],
      dspRvw:[''],
      rvwMo:[''],
      sampleMo:[''],
      caseId:[''],
      ldssCd:[''],
      firstNm:[''],
      lastNm:[''],
      qaRvwId:[''],
      rvwr:[''],
      sprVsr:[''],
      asgnDt: [''],
      dueDt:[''],
      statCd:[''],
      statRsn:[''],
      statDt:[''],
      caseRd:[''],
      subApr:[''],
      rtCor:[''],
      resub:[''],
      defNt:[''],
      dtSupNtAn:[''],
      dtRtDef:[''],
      dtDefResb:[''],
      adjPep:[''],
      sbmtToPlcySpec:[' '],
      rtrnForPlcySpec:[''],
      rsbmtToPlcySpec:[''],

      //New fields
      ntcRqrmnt:[''],
      rcrdRsn:[''],
      rcrdVldty:[''],
      disp:[''],
      elmt:[''],
      ntrCd:[''],
      plcySpclCmnts:[''],
      sprvsrCmnts:[''],
      dtOfTpng:[''],
      schdlSnt:[''],
      csRvwAsgnmtId:[],
      directorCmnts:[]
      // noAppRcdFnd:[''],
      // status:[''],
      // type:[''],
      // nstr:[''],
      // qc35199Sent:['']
    });
  }

  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }

  getRvwData() {
    const queryParams: any[] = [];
     queryParams.push(this.constantsService.rvwlog_rvwid);
     this.apiManager.fetchData('getFsposproc', undefined, ApiManager.GET,
     undefined, null, null, queryParams)
           .subscribe(res => {
             let formData = res;
             this.handleRcrdValidty(res.rcrdVldty);
             if ( res.screenLvlLookup ) {
              this.constantsService.lookUpValues = res.screenLvlLookup;
            }
             this.qcRvwProcessLogTcaForm.patchValue(formData);
           });
   }

   handleRcrdValidty(rcrdVldty){
     if(rcrdVldty=="1"){
       this.dsbleByRcrdVldty = true;
     } else
     if(rcrdVldty=="2" || rcrdVldty=="") {
      this.dsbleByRcrdVldty = false;
     }
   }
  
  save(model) {

    const queryParams: any[] = [];
    const negProcessData = model.value;
    this.apiManager.fetchData('saveFsposproc', undefined, ApiManager.POST,
    negProcessData, null, null, queryParams)
          .subscribe(res => {
            // this.dsQCSamplingSchedule = res[0].documents;
            // this.dialogRef.close();
            this._location.back();
          });
    
  }
  changeSubElementByElement(lkpNameCd, eCd) {
    this.subElements = this.constantsService.getLookUpByName('v_rt_fsNeg_sub_ele_list');
    if (this.subElements.length > 0 && eCd) {
      this.subElements = this.subElements.filter(item => item.parentRef === eCd);
    }
  }
  
    getControlValue(qtn) {
    return this.group.controls[qtn].value;
  }

  back() {
    this._location.back();
   }
   next(){
     
   }
}
