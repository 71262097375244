import { Component, OnInit, Optional, Inject, ViewChild } from '@angular/core';
import { ConstantsService } from '../../common/constants.service';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DATE_FORMAT } from '../month-format.directive';
import { ApiManager } from 'src/app/common/api-manager.service';
import { AppUtilService } from 'src/app/common/app.util.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Region, DistrictOffice, Analyst } from '../../model/api/region-district-analyst.class';
import { CustomValidatorsService } from 'src/app/common/custom-validators.service';
import moment from 'moment';
import { NavigationService } from 'src/app/common/navigation.service';

export interface informationElement {
  ldssCd: string,
  ldssName: string,
  ldssDoName: string,
  ldssDoCity: string,
  ldssDoAddress: string,
  ldssDoZipCd: string,
  ldssDoCd: string,
}

@Component({
  selector: 'app-ldss-information',
  templateUrl: './ldss-information.component.html',
  styleUrls: ['./ldss-information.component.css']
})
export class LdssInformationComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  qcLdssInformationForm: FormGroup;
  findSource = MatTableDataSource;

  isNotEditable: boolean = false;
  dsQCLdssInformation = new MatTableDataSource();
  @ViewChild(MatSort, {static: true}) sort: MatSort;  

  displayedColumns: string[] = [
    'ldssCd',
    'ldssName',
    'ldssDoCd',
    'ldssDoName',
    'ldssDoCity',
    'ldssDoAddress',
    'ldssDoZipCd',
    'actions'
    ];
  dataSource: MatTableDataSource<informationElement>;
 

  constructor(
    public constantsService: ConstantsService,
    private apiManager: ApiManager,
    private fb: FormBuilder,
    @Optional() public dialog: MatDialog,

   @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if ( this.data ) {
      this.buildForm();
      this.dataSource = new MatTableDataSource();
    }
   }

  ngOnInit() {
    this.initTables();
    this.getQCLdssInformation();
    this.determineAccess();
  }
  buildForm() {
    this.qcLdssInformationForm = this.fb.group({
      ldssName: [''],
    ldssDoName: [''],
    ldssDoCity: [''],
    ldssDoAddress:[''],
    ldssDoZipCd:[''],
    ldssDoCd:['']
    });
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/qualityControl/ldssInformation'] != null && ref.accessMap['/qualityControl/ldssInformation'].accessMode != 'rw')) {
        this.isNotEditable = true;
    }
}
initTables() {
  this.dsQCLdssInformation.sort = this.sort;
}
print(): void {
  // let printContents, printMember, printStyle, printLink;
  // printContents = document.getElementById('print-section');
  // printMember = document.getElementById('print-member');
  // printStyle = this.printService.getTagsHtml('style');
  // printLink = this.printService.getTagsHtml('link');
  // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
  // this.printService.printTable = printContents ? printContents.innerHTML : null;
  // this.printService.printStyle = printStyle;
  // this.printService.printLink = printLink;
  // this.printService.print();
  window.print();
}
getQCLdssInformation() {
  const id: any[] = [];
  this.apiManager.fetchData('getQCLdssInformation', undefined, ApiManager.GET, undefined, undefined, undefined, id)
    .subscribe(res => {
      if (res) {
        this.dataSource = new MatTableDataSource(res);
        this.findSource = res;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }
  );
}

openDialog(row) {
  if (this.isNotEditable) {
    return;
  }
  const dialogConfig = new MatDialogConfig();
  dialogConfig.disableClose = true;
  dialogConfig.autoFocus = true;
  dialogConfig.data = {
  index: this.dataSource.data.indexOf(row),
//  data: this.findSource && this.findSource.length > 0 ? this.findSource[idx] : null
data: row
  };
  const dialogRef = this.dialog.open(LdssInformationDialog, dialogConfig);
  dialogRef.updateSize('75%', '85%');
  dialogRef.afterClosed().subscribe(val => {
    if (val) {
      console.log('afterClose', val);
    }
    this.ngOnInit();
  });
}
}

@Component({
  selector: 'ldss-information-dialog',
  templateUrl: 'ldss-information-dialog.html',
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})

export class LdssInformationDialog implements OnInit {

  public ldssInformationForm: FormGroup;
  findSource: any;  
  PrimaryContact : Analyst[] = [];
  SecondaryContact : Analyst[] = [];
  isNotEditable: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    private fb: FormBuilder,
  ) {
    
  }

  ngOnInit() {
    this.buildForm();
    if (this.data.index > -1) {
      const selectedFindResp = this.data.data;
      this.PrimaryContact = this.constantsService.changeAnalystByDoCd(this.data.data.ldssDoCd, false);
      this.SecondaryContact = this.constantsService.changeAnalystByDoCd(this.data.data.ldssDoCd, false);
      this.ldssInformationForm.patchValue(selectedFindResp);
      this.ldssInformationForm.get('PrimaryContact').patchValue(selectedFindResp.primaryContact);
      this.ldssInformationForm.get('SecondaryContact').patchValue(selectedFindResp.secondaryContact);
      }
  }



  buildForm() {
    this.ldssInformationForm = this.fb.group({
      ldssId: [''],
      ldssCd:[''],
      ldssName: [''],
      ldssDoName: [''],
      ldssDoCity: [''],
      ldssDoAddress:[''],
      ldssDoZipCd:[''],
      ldssDoCd:[''],
      PrimaryContact:[''],
      SecondaryContact:[''],
      primaryContactFirstNm :[''],
      primaryContactLastNm :[''],
      primaryContactPhoneNo:[''],
      primaryContactTitle:[''],
      secondaryContactFirstNm :[''],
      secondaryContactLastNm :[''],
      secondaryContactPhoneNo:[''],
      secondaryContactTitle:[''],
      dirtyStatus: ['INSERT'],
    });

  }

  save(model) {
    console.log(model);
    let primaryContact = this.ldssInformationForm.get('PrimaryContact').value;
    let secondaryContact = this.ldssInformationForm.get('SecondaryContact').value;

    const queryParams: any[] = [];
    let qcLdssInfoObj: any= model.value;
    qcLdssInfoObj.dirtyStatus = model.dirty && model.value.bpeLdssEvltnId !== '' ? 'UPDATE' : 'INSERT';
    qcLdssInfoObj.ldssId = model.value.ldssId === '' ? null : parseInt(model.value.ldssId);
    qcLdssInfoObj.primaryContact = primaryContact;
    qcLdssInfoObj.secondaryContact = secondaryContact;
    this.apiManager.fetchData('saveLdssInformation', undefined, ApiManager.POST, qcLdssInfoObj, undefined, undefined, queryParams)
      .subscribe(
        res => {
          this.findSource = res;
           this.dialogRef.close(res);
        });
        this.ngOnInit();
  }
  onChangePrimaryFirstNameLastName(val){
    console.log("Primary"+val);

    this.PrimaryContact.filter(item=> item['uniqueid']===val).map(item=> {
      this.ldssInformationForm.get('primaryContactFirstNm').patchValue(item['firstName']); 
      this.ldssInformationForm.get('primaryContactLastNm').patchValue(item['lastName']); 
    })
  }

  changeSecondaryFirstNameAndLastName(val){
    this.SecondaryContact.filter(item=> item['uniqueid']===val).map(item=> {
      this.ldssInformationForm.get('secondaryContactFirstNm').patchValue(item['firstName']); 
      this.ldssInformationForm.get('secondaryContactLastNm').patchValue(item['lastName']); 
    })
  }

}
