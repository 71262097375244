import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-absent-parent-notpaying-childsupport',
  templateUrl: './absent-parent-notpaying-childsupport.component.html',
  styleUrls: ['./absent-parent-notpaying-childsupport.component.css']
})
export class AbsentParentNotpayingChildsupportComponent extends BaseComponent implements OnInit {
  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  changeof999_1(event:any){
    if(event.value === 'N') {
      this.group.controls['999.2a'].disable();
      this.group.controls['999.2b'].disable();
      this.group.controls['999.2c'].disable();
      this.group.controls['999.2d'].disable();
      this.group.controls['999.3a'].disable();
      this.group.controls['999.3b'].disable();
      this.group.controls['999.3c'].disable();
      this.group.controls['999.3d'].disable();
      this.group.controls['999.4a'].disable();
      this.group.controls['999.4b'].disable();
      this.group.controls['999.4c'].disable();
      this.group.controls['999.4d'].disable();
      this.group.controls['999.5a'].disable();
      this.group.controls['999.5b'].disable();
      this.group.controls['999.5c'].disable();
      this.group.controls['999.5d'].disable();
      this.group.controls['999.6a'].disable();
      this.group.controls['999.6b'].disable();
      this.group.controls['999.6c'].disable();
      this.group.controls['999.6d'].disable();
      this.group.controls['999.7a'].disable();
      this.group.controls['999.7b'].disable();
      this.group.controls['999.7c'].disable();
      this.group.controls['999.7d'].disable();
    }
    else
    {
      this.group.controls['999.2a'].enable();
      this.group.controls['999.2b'].enable();
      this.group.controls['999.2c'].enable();
      this.group.controls['999.2d'].enable();
      this.group.controls['999.3a'].enable();
      this.group.controls['999.3b'].enable();
      this.group.controls['999.3c'].enable();
      this.group.controls['999.3d'].enable();
      this.group.controls['999.4a'].enable();
      this.group.controls['999.4b'].enable();
      this.group.controls['999.4c'].enable();
      this.group.controls['999.4d'].enable();
      this.group.controls['999.5a'].enable();
      this.group.controls['999.5b'].enable();
      this.group.controls['999.5c'].enable();
      this.group.controls['999.5d'].enable();
      this.group.controls['999.6a'].enable();
      this.group.controls['999.6b'].enable();
      this.group.controls['999.6c'].enable();
      this.group.controls['999.6d'].enable();
      this.group.controls['999.7a'].enable();
      this.group.controls['999.7b'].enable();
      this.group.controls['999.7c'].enable();
      this.group.controls['999.7d'].enable();

    }
  }
}