import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-hi-ed-higher-education-form',
  templateUrl: './hi-ed-higher-education-form.component.html',
  styleUrls: ['./hi-ed-higher-education-form.component.css']
})
export class HiEdHigherEducationFormComponent extends BaseComponent implements OnInit {
  householdMembers: any;
  ngOnInit() {
    this.householdMembers = this.constantsService.householdMembers;
    
    super.ngOnInit();
  }
  checkValue(event) {
    let phnumber = event.currentTarget.value;
    const ctrl = this.group.get('phoneNumber').get('phoneNumberType');
    if (this.group.get('phoneNumber').get('phoneNumber').hasError('invalidPhone') || phnumber.length == 0) {
      ctrl.disable();
      ctrl.reset();
    } else {
      ctrl.enable();
    }
  }
  handleSsn(val){
    this.householdMembers.map(indivId =>{
      if(this.utilService.getFullName(indivId) === val){
      this.setControlValue('500118.8', indivId.ssn);
      }
    });

  }
 
}