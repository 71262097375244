import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-earned-income-simplified-reporting',
  templateUrl: './earned-income-simplified-reporting.component.html',
  styleUrls: ['./earned-income-simplified-reporting.component.css']
})
export class EarnedIncomeSimplifiedReportingComponent extends BaseComponent implements OnInit {
  currentPage: number = 0;
  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    const ctrl:any = (this.group.get('FormArray'));
    this.sum(ctrl.controls[0])
    this.gross(ctrl.controls[0])
    this.grossTwo(ctrl.controls[0])
    this.grossThree(ctrl.controls[0])
  }
  sum(arrayControl){
    const amt1 = arrayControl.controls["311.9.02a"].value;
    const amt2 = arrayControl.controls["311.9.02d"].value;
    const amt3 = arrayControl.controls["311.9.02g"].value;
    const amt4 = arrayControl.controls["311.9.02j"].value;
    const amt5 = arrayControl.controls["311.9.02m"].value;
   const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5);
    arrayControl.controls["311.9.02p"].patchValue(res);
    

  }
  gross(arrayControl){
    let counter = 0;
    const amt1 = arrayControl.controls["311.9.2a0"].value;
    const amt2 = arrayControl.controls["311.9.2a1"].value;
    const amt3 = arrayControl.controls["311.9.2a2"].value;
    const amt4 = arrayControl.controls["311.9.2a3"].value;
    const amt5 = arrayControl.controls["311.9.2a4"].value;
    amt1 != null ? counter++: counter;
    amt2 != null ? counter++: counter;
    amt3 != null ? counter++: counter;
    amt4 != null ? counter++: counter;
    amt5 != null ? counter++: counter;
   const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5);
    arrayControl.controls["311.9.10a"].patchValue(res);
    const average =  (res)/counter;
    arrayControl.controls["311.9.11a"].patchValue(average);
    const amtX = arrayControl.controls["311.9.4a"].value;
    const equalTo =  (amtX)*average;
    arrayControl.controls["311.9.13a"].patchValue(equalTo);
    const amtHh = arrayControl.controls["311.9.5a"].value;
    const equalToTwo =  (equalTo)/amtHh;
    arrayControl.controls["311.9.15a"].patchValue(equalToTwo);
    const amtEligHh = arrayControl.controls["311.9.6a"].value;
    const equalToThree =  equalToTwo*amtEligHh;
    arrayControl.controls["311.9.17a"].patchValue(equalToThree);
  }
  grossTwo(arrayControl){
    let counter = 0;
    const amt1 = arrayControl.controls["311.9.2b0"].value;
    const amt2 = arrayControl.controls["311.9.2b1"].value;
    const amt3 = arrayControl.controls["311.9.2b2"].value;
    const amt4 = arrayControl.controls["311.9.2b3"].value;
    const amt5 = arrayControl.controls["311.9.2b4"].value;
    amt1 != null ? counter++: counter;
    amt2 != null ? counter++: counter;
    amt3 != null ? counter++: counter;
    amt4 != null ? counter++: counter;
    amt5 != null ? counter++: counter;
   const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5);
   arrayControl.controls["311.9.10b"].patchValue(res);
    const average =  (res)/counter;
    arrayControl.controls["311.9.11b"].patchValue(average);
    const amtX = arrayControl.controls["311.9.4b"].value;
    const equalTo =  (amtX)*average;
    arrayControl.controls["311.9.13b"].patchValue(equalTo);
    const amtHh = arrayControl.controls["311.9.5b"].value;
    const equalToTwo =  (equalTo)/amtHh;
    arrayControl.controls["311.9.15b"].patchValue(equalToTwo);
    const amtEligHh = arrayControl.controls["311.9.6b"].value;
    const equalToThree =  equalToTwo*amtEligHh;
    arrayControl.controls["311.9.17b"].patchValue(equalToThree);
    
  }
  grossThree(arrayControl){
    let counter = 0;
    const amt1 = arrayControl.controls["311.9.2c0"].value;
    const amt2 = arrayControl.controls["311.9.2c1"].value;
    const amt3 = arrayControl.controls["311.9.2c2"].value;
    const amt4 = arrayControl.controls["311.9.2c3"].value;
    const amt5 = arrayControl.controls["311.9.2c4"].value;
    amt1 != null ? counter++: counter;
    amt2 != null ? counter++: counter;
    amt3 != null ? counter++: counter;
    amt4 != null ? counter++: counter;
    amt5 != null ? counter++: counter;
   const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5);
   arrayControl.controls["311.9.10c"].patchValue(res);
   const average =  (res)/counter;
   arrayControl.controls["311.9.11c"].patchValue(average);
   const amtX = arrayControl.controls["311.9.4c"].value;
   const equalTo =  (amtX)*average;
   arrayControl.controls["311.9.13c"].patchValue(equalTo);
   const amtHh = arrayControl.controls["311.9.5c"].value;
   const equalToTwo =  (equalTo)/amtHh;
   arrayControl.controls["311.9.15c"].patchValue(equalToTwo);
   const amtEligHh = arrayControl.controls["311.9.6c"].value;
    const equalToThree =  equalToTwo*amtEligHh;
    arrayControl.controls["311.9.17c"].patchValue(equalToThree);
  }



}