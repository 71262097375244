import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-voter-registration-reviewer',
  templateUrl: './voter-registration-reviewer.component.html',
  styleUrls: ['./voter-registration-reviewer.component.css']
})
export class VoterRegistrationReviewerComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
