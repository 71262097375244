import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: '[disableOnClick]'
})

export class DisableOnClickDirective {
    constructor(private ef: ElementRef,
        private rd: Renderer2) { }

    @HostListener('click') click() {
        if (this.ef && this.ef.nativeElement) {
            this.rd.setAttribute(this.ef.nativeElement, 'disabled', 'true');
        }
    }
}