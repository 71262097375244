import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-faxcover-sheet',
  templateUrl: './faxcover-sheet.component.html',
  styleUrls: ['./faxcover-sheet.component.css']
})
export class FAXCoverSheetComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }
  checkValue(event) {
    let phnumber = event.currentTarget.value;
    const ctrl = this.group.get('phoneNumber').get('phoneNumberType');
    if (this.group.get('phoneNumber').get('phoneNumber').hasError('invalidPhone') || phnumber.length == 0) {
      ctrl.disable();
      ctrl.reset();
    } else {
      ctrl.enable();
    }
  }
 
}
