import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-deemed-income',
  templateUrl: './deemed-income.component.html',
  styleUrls: ['./deemed-income.component.css']
})
export class DeemedIncomeComponent extends BaseComponent implements OnInit {


  isNotEditable: boolean = false;
  handleDeemed: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.valueof343_01();
    this.changeQf343_01(this.getControlValue('343.01'));
    this.changeQf343_1(this.getControlValue('343.1'));
    this.changeQf343_4(this.getControlValue('343.3'));
    this.changeQf343_7(this.getControlValue('343.6'));
  }
  valueof343_01() {
    if (this.getControlValue('343.01') === null) {
      this.setControlValue('343.01', 'N');
      this.group.controls['343.02'].disable();
    } else if (this.getControlValue('343.01') === 'Y') {
      this.group.controls['343.02'].enable();
    } else if (this.getControlValue('343.01') === 'N'){
      this.group.controls['343.02'].disable();
    }
  }
  changeQf343_1(val) {
    if (val === 'N') {
      this.group.controls['343.2a'].disable();
      this.group.controls['343.2b'].disable();
      this.group.controls['343.2c'].disable();
      this.group.controls['343.3'].disable();
      this.group.controls['343.4'].disable();
      this.group.controls['343.5'].disable();
      this.group.controls['343.6'].disable();
      this.group.controls['343.7'].disable();
      this.group.controls['343.8'].disable();
      this.group.controls['343.9'].disable();
      this.group.controls['343.11'].disable();
      this.group.controls['343.12a'].disable();
      this.handleDeemed = true;
    } else  if (val === 'Y') {
      this.group.controls['343.2a'].enable();
      this.group.controls['343.2b'].enable();
      this.group.controls['343.2c'].enable();
      this.group.controls['343.3'].enable();
      this.group.controls['343.4'].enable();
      this.group.controls['343.5'].enable();
      this.group.controls['343.6'].enable();
      this.group.controls['343.7'].enable();
      this.group.controls['343.8'].enable();
      this.group.controls['343.9'].enable();
      this.group.controls['343.11'].enable();
      this.group.controls['343.12a'].enable();
      this.handleDeemed = false;

    }
  }
  changeQf343_4(val) {
    if (val === 'N') {

      this.group.controls['343.4'].disable();
    } else  if (val === 'Y') {

      this.group.controls['343.4'].enable();


    }
  }
  changeQf343_7(val) {
    if (val === 'Y') {
      this.group.controls['343.7'].enable();
      this.group.controls['343.8'].enable();
      this.group.controls['343.9'].enable();
      this.handleDeemed = false;
      this.group.controls['343.11'].disable();
    } else  if (val === 'N') {
      this.group.controls['343.7'].disable();
      this.group.controls['343.8'].disable();
      this.group.controls['343.9'].disable();
      this.group.controls['343.11'].enable();
      this.handleDeemed = true;


    }
  }


  changeQf343_01(val) {
    if (val === 'N') {
      this.group.controls['343.02'].disable();
    } else  if (val === 'Y') {
      this.group.controls['343.02'].enable();
    }
  }



}