import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-earned-income-employment-questions',
  templateUrl: './earned-income-employment-questions.component.html',
  styleUrls: ['./earned-income-employment-questions.component.css']
})

export class EarnedIncomeEmploymentQuestionsComponent extends BaseComponent implements OnInit {

  @Input() group: FormGroup;
  currentPage: number = 0;
  isNotEditable: boolean = false;
  handle_311_1_19:boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  get Is_311_1_1(): boolean {
    return this.group.controls['311.1.1'].value === 'Y';
  }
  get Is_311_1_2(): boolean {
    return this.group.controls['311.1.2'].value === 'Y';
  }
  get Is_311_1_3(): boolean {
    return this.group.controls['311.1.3'].value === 'Y';
  }
  get Is_311_1_4(): boolean {
    return this.group.controls['311.1.4'].value === 'Y';
  }
  get Is_311_1_5(): boolean {
    return this.group.controls['311.1.5'].value === 'Y';
  }
  get Is_311_1_7(): boolean {
    return this.group.controls['311.1.7'].value === 'N';
  }
  get Is_311_1_8(): boolean {
    return this.group.controls['311.1.8'].value === 'N';
  }
  get Is_311_1_8_Y(): boolean {
    return this.group.controls['311.1.8'].value === 'Y';
  }
  get Is_311_1_17_N(): boolean {
    return this.group.controls['311.1.17'].value === 'N';
  }

  changeof311_1_12(event: any) {
    if (event.value === 'N') {
      this.group.controls['311.1.13a'].disable();
      this.group.controls['311.1.13b'].disable();
      this.group.controls['311.1.13c'].disable();
      this.group.controls['311.1.13d'].disable();
      this.group.controls['311.1.13e'].disable();
      this.group.controls['311.1.13f'].disable();
      this.group.controls['311.1.14'].disable();
      this.group.controls['311.1.15'].disable();
      this.group.controls['311.1.16'].disable();
      this.group.controls['311.1.17'].disable();
      this.group.controls['311.1.19'].disable();


    } else {
      this.group.controls['311.1.13a'].enable();
      this.group.controls['311.1.13b'].enable();
      this.group.controls['311.1.13c'].enable();
      this.group.controls['311.1.13d'].enable();
      this.group.controls['311.1.13e'].enable();
      this.group.controls['311.1.13f'].enable();
      this.group.controls['311.1.14'].enable();
      this.group.controls['311.1.15'].enable();
      this.group.controls['311.1.16'].enable();
      this.group.controls['311.1.17'].enable();
      this.group.controls['311.1.19'].enable();

    }
  }

  changeof311_1_17(event: any) {
    if (event.value === 'Y') {
      this.handle_311_1_19 = true;
      this.group.controls['311.1.19'].disable();


    } else {
      this.handle_311_1_19 = false;
      this.group.controls['311.1.19'].enable();

    }
  }

  changeof311_0_2(event: any) {
    if ( event.value === 'N') {
      this.group.controls['311.0.3'].disable();
      this.group.controls['311.0.4a'].disable();
      this.group.controls['311.0.4b'].disable();
      this.group.controls['311.0.4c'].disable();
      this.group.controls['311.0.5a'].disable();
      this.group.controls['311.0.5b'].disable();
      this.group.controls['311.0.5c'].disable();
      this.group.controls['311.0.6a'].disable();
      this.group.controls['311.0.6b'].disable();
      this.group.controls['311.0.6c'].disable();
      this.group.controls['311.0.7'].disable();
      } else {
        this.group.controls['311.0.3'].enable();
        this.group.controls['311.0.4a'].enable();
        this.group.controls['311.0.4b'].enable();
        this.group.controls['311.0.4c'].enable();
        this.group.controls['311.0.5a'].enable();
        this.group.controls['311.0.5b'].enable();
        this.group.controls['311.0.5c'].enable();
        this.group.controls['311.0.6a'].enable();
        this.group.controls['311.0.6b'].enable();
        this.group.controls['311.0.6c'].enable();
        this.group.controls['311.0.7'].enable();

      }
  }
}