import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-not-participating-rehabilitative-program',
  templateUrl: './not-participating-rehabilitative-program.component.html',
  styleUrls: ['./not-participating-rehabilitative-program.component.css']
})
export class NotParticipatingRehabilitativeProgramComponent  extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
