import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-repair-to-damage',
  templateUrl: './repair-to-damage.component.html',
  styleUrls: ['./repair-to-damage.component.css']
})
export class RepairToDamageComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  handleQ364_4_9(val){
    if(val === '99'){
      this.group.controls['364.4.9a'].enable();
    }else{
      this.group.controls['364.4.9a'].disable();
    }
}
}
