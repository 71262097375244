import { Component, OnInit, ViewChild, Inject, ElementRef, Optional } from '@angular/core';
import { ConstantsService } from '../../common/constants.service';
import { AppUtilService } from '../../common/app.util.service';
import { ApiManager } from '../../common/api-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { NavigationService } from '../../common/navigation.service';
import { PrintService } from '../../common/print.service';
import { CustomValidatorsService } from '../../common/custom-validators.service';
import { MonthFormatDirective, DATE_FORMAT, MNTH_FORMAT } from '../../shared-module/month-format.directive';
import { MomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { NgxSpinnerService } from 'ngx-spinner';
const moment = _rollupMoment || _moment;


@Component({
  selector: 'app-run-sampling',
  templateUrl: './run-sampling.component.html',
  styleUrls: ['./run-sampling.component.css']
})

export class RunSamplingComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;  
  displayedColumns: string[] = ['samplingMonth',
    'batchScheduledDt',
    'batchScheduleTime',
    'programType',
    // 'batchStatus',
    'samplingStartNum',
    'interval',
    'actions'];

    displayedBPEColumns: string[] = ['samplingMonth',
    'batchScheduledDt',
    'batchScheduleTime',
    // 'programType',
    // 'batchStatus',
    // 'samplingStartNum',
    // 'interval',
    'actions'];
  isNotEditable: boolean = false;
  dsQCSamplingSchedule = new MatTableDataSource();
  dsQCSamplingScheduleCurrent = new MatTableDataSource();
  dsQCSamplingScheduleCompleted = new MatTableDataSource();
  popupMode: boolean = false;

  constructor(
    public constantsService: ConstantsService,
    private utilService: AppUtilService,
    private apiManager: ApiManager,
    private router: Router,
    private route: ActivatedRoute,
    @Optional() public dialog: MatDialog,
    public navigationService: NavigationService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private printService: PrintService,
    private customValidatorsService: CustomValidatorsService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if ( this.data ) {
      this.popupMode = true;
      this.displayedColumns = this.displayedBPEColumns;
    }
   }

  ngOnInit() {
    this.initTables();
    this.getQCSamplingSchedule();
    this.determineAccess();
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/qualityControl/runSampling'] != null && ref.accessMap['/qualityControl/runSampling'].accessMode != 'rw')) {
        this.isNotEditable = true;
    }
  }

  initTables() {
    // this.dsQCSamplingSchedule = [];
    // this.dsQCSamplingSchedule = new MatTableDataSource(this.dsQCSamplingSchedule);
    this.dsQCSamplingSchedule.sort = this.sort;
  }

  getQCSamplingSchedule() {
    const id: any[] = [];
    this.apiManager.fetchData('getQCSamplingSchedule', undefined, ApiManager.GET, undefined, undefined, undefined, id)
      .subscribe(res => {
        if (res) {
          this.dsQCSamplingScheduleCompleted.data = res.historySampling;
          this.dsQCSamplingScheduleCurrent.data = res.authorizedSampling;
          this.dsQCSamplingSchedule.data = res.authorizedSampling;
          setTimeout(() => { 
            this.dsQCSamplingSchedule.paginator = this.paginator; 
          });
        }
      }
    );
  }

  handleCurrentMonth(val) {
    if (val.tab.textLabel === 'Current') {
      this.dsQCSamplingSchedule.data = this.dsQCSamplingScheduleCurrent.data;
    } else if (val.tab.textLabel === 'Completed') {
      this.dsQCSamplingSchedule.data = this.dsQCSamplingScheduleCompleted.data;
    }
    this.dsQCSamplingSchedule.paginator = this.paginator; 

}

  openDialog(index,row) {
    if (this.isNotEditable) {
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    if (index !== undefined && index > -1) {
      dialogConfig.data = {
        samplingdata: this.dsQCSamplingSchedule.data,
        selecteddata: row,
        index: index,
        popupMode: this.popupMode
      };
    } 
    else {
      dialogConfig.data = {
        samplingdata: this.dsQCSamplingSchedule.data,
        index: -1,
        popupMode: this.popupMode
      };
    }

    const dialogRef = this.dialog.open(RunSamplingDialog, dialogConfig);
    dialogRef.updateSize('60%', '42%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log('afterClose', val);
        // this.dsQCSamplingSchedule = new MatTableDataSource(val);
        // this.dialog.closeAll();
        this.ngOnInit();
        
      }
      
    });
  }
  // Run Report Dialog 

  openReportDialog(index,row) {
    if (this.isNotEditable) {
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    if (index !== undefined && index > -1) {
      dialogConfig.data = {
        samplingdata: row,
        index: index,
        popupMode: this.popupMode
      };
    } 
    else {
      dialogConfig.data = {
        samplingdata: row,
        index: -1,
        popupMode: this.popupMode
      };
    }

    const dialogRef = this.dialog.open(RunSamplingReportComponent, dialogConfig);
    dialogRef.updateSize('60%', '62%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log('afterClose', val);
        // this.dsQCSamplingSchedule = new MatTableDataSource(val);
        // this.dialog.closeAll();
        this.ngOnInit();
        
      }
      
    });
  }
  openDeleteDialog(row) {
    if (this.isNotEditable) {
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '350px';
    dialogConfig.height = '180px';
    dialogConfig.data = row;

    const dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(val => {
      if (val == 'yes') {

        const queryParams: any[] = [];

        let findRespObj: any = this.dsQCSamplingSchedule.data[row];
        findRespObj.dirtyStatus = 'delete';
        let sendObj = [];  
        sendObj.push(findRespObj);
        this.apiManager.fetchData('saveQCSamplingSchedule', undefined, ApiManager.POST,sendObj[0] , undefined, undefined, queryParams)
          .subscribe(res => {
            if (res) {
              this.dsQCSamplingSchedule = new MatTableDataSource();
              this.dsQCSamplingSchedule.data = res;
              setTimeout(() => {
              });
            }
            // this.dialog.closeAll();
            this.ngOnInit();
          });
      }
    });
  }

  getDropdownText(val) {
    return this.constantsService.ProgramTypes.filter(item => item.value === val )[0].viewValue;
  }
  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }

}


@Component({
  selector: 'run-sampling-dialog',
  templateUrl: 'run-sampling-dialog.html',
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})

export class RunSamplingDialog implements OnInit {

  public samplingForm: FormGroup;
  readonlyMode= false;
  constructor(
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    public appUtilService: AppUtilService,
    private fb: FormBuilder,
    private customValidatorsService: CustomValidatorsService,
  ) {
    this.buildForm();
  }

  ngOnInit() {
    if (this.data.index > -1) {
      this.readonlyMode= true;
      
      const selectedSampling = this.data.selecteddata;
      const tempDate = selectedSampling.samplingMonth;
      // selectedSampling.samplingMonth = tempDate.substring(0, 4) + '-' + tempDate.substring(4) + '-01';
      this.samplingForm.patchValue(selectedSampling);
      this.samplingForm.get('samplingMonth').patchValue(tempDate.substring(0, 4) + '-' + tempDate.substring(4) + '-01');
    }
    else{
      this.readonlyMode= false;
    }
  }

  buildForm() {
    this.samplingForm = this.fb.group({
      samplingMonth: ['', [Validators.required]],
      scheduleStartDt: ['', [Validators.required]],
      scheduleStartTime: ['', [Validators.required]],
      programType: ['', [Validators.required]],
      samplingStartNum: ['', [Validators.required]],
      interval: ['', [Validators.required]],
      dirtyStatus : ['INSERT']
    });

  }

  save(model) {

  
    const queryParams: any[] = [];
    const samplingData = model.value;
    samplingData.samplingMonth = this.appUtilService.changeDateToMonthFormat(samplingData.samplingMonth);

    this.apiManager.fetchData('saveQCSamplingSchedule', undefined, ApiManager.POST,
    samplingData, null, null, queryParams)
          .subscribe(res => {

            this.dialogRef.close(res);
          });
  }
  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }

  checkDuplicate() {
    let isDuplicate = false;
    if (this.data && this.data.samplingdata) {
      const smplMonth = moment(this.samplingForm.value.samplingMonth).format('YYYYMM');
      this.data.samplingdata.map((smpl, index) => { 
        if (index !== this.data.index
          && smplMonth === smpl.samplingMonth
          && this.samplingForm.value.programType === smpl.programType
        ) {
          isDuplicate = true;
        }
      });
    }
    return isDuplicate;
  }

  changeProgram(val) {
    if (val === 'ESAPPositive') {
      this.samplingForm.get('samplingStartNum').patchValue('1');
      this.samplingForm.get('interval').patchValue('4');
    } if (val === 'ESAPNegative') {
      this.samplingForm.get('samplingStartNum').patchValue('1');
      this.samplingForm.get('interval').patchValue('3');
    }
  }
}

/* Report Dialog Starts here */

@Component({
  selector: 'run-samplingReport-dialog',
  templateUrl: 'run-samplingReport-dialog.html',
  styleUrls: ['./run-sampling.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})

export class RunSamplingReportComponent implements OnInit {

  public runSamplingReportForm: FormGroup;
  displayProgressSpinner = true;

  readonlyMode= false;
  constructor(
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    private spinnerService: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    public appUtilService: AppUtilService,
    private fb: FormBuilder,
    private customValidatorsService: CustomValidatorsService,
  ) {
    
    this.buildForm();
  }

  ngOnInit() {
    this.getSamplingReport();
    this.samplingMonth;
    this.programType;
  }

  buildForm() {
    this.runSamplingReportForm = this.fb.group({
     
    });

  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  casedisplayedColumns = [
    "ldssCd",
    "ldssName",
    "ldssDoCd",
    "ldssDoName",
    "caseIdCount"       
  ];

  casedataSourceDialog: any = new MatTableDataSource();
  samplingMonth = this.data.samplingdata.samplingMonth;
  programType = this.data.samplingdata.programType;
  getDropdownText(val) {
    return this.constantsService.ProgramTypes.filter(item => item.value === val )[0].viewValue;
  }

  ngAfterViewInit() {
    this.casedataSourceDialog.paginator = this.paginator;
    this.casedataSourceDialog.sort = this.sort;
  }

  map: Map<any, any> = new Map();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  getSamplingReport() {
    
    const queryParams: any[] = [];

    // queryParams.push(this.constantsService.getSecurityObject().user.uniqueId); // TODO: set the analyst id from user id
    
    queryParams.push(this.data.samplingdata.recordId);
    queryParams.push(this.data.samplingdata.programType);
    queryParams.push(this.data.samplingdata.samplingMonth);
    queryParams.push(this.data.samplingdata.samplingStatus);
    this.apiManager.fetchData('getSamplingReport', undefined, ApiManager.GET,
        undefined, undefined, undefined, queryParams)
        .subscribe(res => {
          if (res) {
            console.log(res);
            this.displayProgressSpinner = false;
            this.spinnerService.hide();
            this.casedataSourceDialog = new MatTableDataSource(res);
            this.casedataSourceDialog.paginator = this.paginator;
            this.casedataSourceDialog.sort = this.sort;
          }
        },
        error => {
          this.displayProgressSpinner = false;
          this.spinnerService.hide();
        });
  }

  
}
