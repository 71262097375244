import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-misc-question',
  templateUrl: './misc-question.component.html',
  styleUrls: ['./misc-question.component.css']
})
export class MiscQuestionComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    const reviewNumber = this.constantsService.ReviewInfo.rvwNum;

    // Convert the number to a string
    const reviewNumberString = reviewNumber.toString();

    // Check if the sixth character is '2'
    if (reviewNumberString.charAt(5) !== '2' && this.constantsService.ReviewInfo.pgmTypeDesc != 'OTO') {
      // It's an OTO case
      this.group.controls['Misc.3a'].disable();
      this.setControlValue('Misc.3a', '');
      this.group.controls['Misc.3b'].disable();
      this.setControlValue('Misc.3b', '');
      this.group.controls['Misc.3c'].disable();
      this.setControlValue('Misc.3c', '');
      this.group.controls['Misc.3d'].disable();
      this.setControlValue('Misc.3d', '');
      this.group.controls['Misc.3e'].disable();
      this.setControlValue('Misc.3e', '');
      this.group.controls['Misc.3f'].disable();
      this.setControlValue('Misc.3f', '');
      console.log("Not an OTO Case");
    } else {
      // It's  OTO case
      console.log("OTO Case");
    }



  }
  changeOfMisc7() {

    if (this.group.controls['Misc.7'].value === 'Y') {
      this.group.controls['Misc.8'].enable();
    } else {
      this.group.controls['Misc.8'].disable();

    }
  }



}