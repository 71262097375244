import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-shelter-cost-hoa-condo-fee',
  templateUrl: './shelter-cost-hoa-condo-fee.component.html',
  styleUrls: ['./shelter-cost-hoa-condo-fee.component.css']
})
export class ShelterCostHoaCondoFeeComponent extends BaseComponent implements OnInit {
  currentPage: number = 0;
  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.handle363_8_9(this.getControlValue('363.1.5.9'));
    // const ctrl:any = (this.group.get('FormArray'));

  }
  handleCalcChart(val){
    if(val==='Y'){
      this.group.controls['363.1.5.1a'].disable();
      this.group.controls['363.1.5.1b'].disable();
      this.group.controls['363.1.5.1c'].disable();
      this.group.controls['363.1.5.1d'].disable();
      this.group.controls['363.1.5.1e'].disable();
      this.group.controls['363.1.5.1f'].disable();
      this.group.controls['363.1.5.1g'].disable();

      this.group.controls['363.1.5.2a'].disable();
      this.group.controls['363.1.5.2b'].disable();
      this.group.controls['363.1.5.2c'].disable();
      this.group.controls['363.1.5.2d'].disable();
      this.group.controls['363.1.5.2e'].disable();
      this.group.controls['363.1.5.2f'].disable();
      this.group.controls['363.1.5.2g'].disable();

      this.group.controls['363.1.5.3a'].disable();
      this.group.controls['363.1.5.3b'].disable();
      this.group.controls['363.1.5.3c'].disable();
      this.group.controls['363.1.5.3d'].disable();
      this.group.controls['363.1.5.3e'].disable();
      this.group.controls['363.1.5.3f'].disable();
      this.group.controls['363.1.5.3g'].disable();

      this.group.controls['363.1.5.4a'].disable();
      this.group.controls['363.1.5.4b'].disable();
      this.group.controls['363.1.5.4c'].disable();
      this.group.controls['363.1.5.4d'].disable();
      this.group.controls['363.1.5.4e'].disable();
      this.group.controls['363.1.5.4f'].disable();
      this.group.controls['363.1.5.4g'].disable();

      this.group.controls['363.1.5.5a'].disable();
      this.group.controls['363.1.5.5b'].disable();
      this.group.controls['363.1.5.5c'].disable();
      this.group.controls['363.1.5.5d'].disable();
      this.group.controls['363.1.5.5e'].disable();
      this.group.controls['363.1.5.5f'].disable();
      this.group.controls['363.1.5.5g'].disable();
    }else if(val==='N'){
      this.group.controls['363.1.5.1a'].enable();
      this.group.controls['363.1.5.1b'].enable();
      this.group.controls['363.1.5.1c'].enable();
      this.group.controls['363.1.5.1d'].enable();
      this.group.controls['363.1.5.1e'].enable();
      this.group.controls['363.1.5.1f'].enable();
      this.group.controls['363.1.5.1g'].enable();

      this.group.controls['363.1.5.2a'].enable();
      this.group.controls['363.1.5.2b'].enable();
      this.group.controls['363.1.5.2c'].enable();
      this.group.controls['363.1.5.2d'].enable();
      this.group.controls['363.1.5.2e'].enable();
      this.group.controls['363.1.5.2f'].enable();
      this.group.controls['363.1.5.2g'].enable();

      this.group.controls['363.1.5.3a'].enable();
      this.group.controls['363.1.5.3b'].enable();
      this.group.controls['363.1.5.3c'].enable();
      this.group.controls['363.1.5.3d'].enable();
      this.group.controls['363.1.5.3e'].enable();
      this.group.controls['363.1.5.3f'].enable();
      this.group.controls['363.1.5.3g'].enable();

      this.group.controls['363.1.5.4a'].enable();
      this.group.controls['363.1.5.4b'].enable();
      this.group.controls['363.1.5.4c'].enable();
      this.group.controls['363.1.5.4d'].enable();
      this.group.controls['363.1.5.4e'].enable();
      this.group.controls['363.1.5.4f'].enable();
      this.group.controls['363.1.5.4g'].enable();

      this.group.controls['363.1.5.5a'].enable();
      this.group.controls['363.1.5.5b'].enable();
      this.group.controls['363.1.5.5c'].enable();
      this.group.controls['363.1.5.5d'].enable();
      this.group.controls['363.1.5.5e'].enable();
      this.group.controls['363.1.5.5f'].enable();
      this.group.controls['363.1.5.5g'].enable();
    }
  }

  get Is211_1_2():boolean{
    return this.group.controls['363.1.5.13'].value !== '5';
      }

      calculate(){
        let expAmt1 = this.getControlValue('363.1.5.1a');
        let expAmt2 = this.getControlValue('363.1.5.1b');
        let expAmt3 = this.getControlValue('363.1.5.1c');
        let expAmt4 = this.getControlValue('363.1.5.1d');
        let expAmt5 = this.getControlValue('363.1.5.1e');
        let expAmt6 = this.getControlValue('363.1.5.1f');
        let expAmt7 = this.getControlValue('363.1.5.1g');
        
        //÷ by # in HH
        let divByHH1 = this.getControlValue('363.1.5.2a');
        let divByHH2 = this.getControlValue('363.1.5.2b');
        let divByHH3 = this.getControlValue('363.1.5.2c');
        let divByHH4 = this.getControlValue('363.1.5.2d');
        let divByHH5 = this.getControlValue('363.1.5.2e');
        let divByHH6 = this.getControlValue('363.1.5.2f');
        let divByHH7 = this.getControlValue('363.1.5.2g');
        
        // = 
        let eq_1_1 = expAmt1/divByHH1;
        let eq_1_2 = expAmt2/divByHH2;
        let eq_1_3 = expAmt3/divByHH3;
        let eq_1_4 = expAmt4/divByHH4;
        let eq_1_5 = expAmt5/divByHH5;
        let eq_1_6 = expAmt6/divByHH6;
        let eq_1_7 = expAmt7/divByHH7;
        
        this.setControlValue('363.1.5.3a',eq_1_1);
        this.setControlValue('363.1.5.3b',eq_1_2);
        this.setControlValue('363.1.5.3c',eq_1_3);
        this.setControlValue('363.1.5.3d',eq_1_4);
        this.setControlValue('363.1.5.3e',eq_1_5);
        this.setControlValue('363.1.5.3f',eq_1_6);
        this.setControlValue('363.1.5.3g',eq_1_7);
        
        //X # in Elig. HH
        let mulByHH1 = this.getControlValue('363.1.5.4a');
        let mulByHH2 = this.getControlValue('363.1.5.4b');
        let mulByHH3 = this.getControlValue('363.1.5.4c');
        let mulByHH4 = this.getControlValue('363.1.5.4d');
        let mulByHH5 = this.getControlValue('363.1.5.4e');
        let mulByHH6 = this.getControlValue('363.1.5.4f');
        let mulByHH7 = this.getControlValue('363.1.5.4g');
        
        // =
        let eq_2_1 = eq_1_1*mulByHH1;
        let eq_2_2 = eq_1_2*mulByHH2;
        let eq_2_3 = eq_1_3*mulByHH3;
        let eq_2_4 = eq_1_4*mulByHH4;
        let eq_2_5 = eq_1_5*mulByHH5;
        let eq_2_6 = eq_1_6*mulByHH6;
        let eq_2_7 = eq_1_7*mulByHH7;
        this.setControlValue('363.1.5.5a',eq_2_1);
        this.setControlValue('363.1.5.5b',eq_2_2);
        this.setControlValue('363.1.5.5c',eq_2_3);
        this.setControlValue('363.1.5.5d',eq_2_4);
        this.setControlValue('363.1.5.5e',eq_2_5);
        this.setControlValue('363.1.5.5f',eq_2_6);
        this.setControlValue('363.1.5.5g',eq_2_7);
        }

        handle363_8_9(val){
          if(val==='1'){
            this.group.controls['363.1.5.10'].disable();
            this.group.controls['363.1.5.11a'].disable();
            this.group.controls['363.1.5.11b'].disable();
            this.group.controls['363.1.5.12'].disable();
            this.group.controls['363.1.5.13'].disable();
            this.group.controls['363.1.5.15'].disable();
            this.group.controls['363.1.5.16'].disable();
            this.group.controls['363.1.5.17'].disable();
            this.group.controls['363.1.5.18'].disable();
            this.group.controls['363.1.5.19'].disable();
          }else if(val !=='1'){
            this.group.controls['363.1.5.10'].enable();
            this.group.controls['363.1.5.11a'].enable();
            this.group.controls['363.1.5.11b'].enable();
            this.group.controls['363.1.5.12'].enable();
            this.group.controls['363.1.5.13'].enable();
            this.group.controls['363.1.5.15'].enable();
            this.group.controls['363.1.5.16'].enable();
            this.group.controls['363.1.5.17'].enable();
            this.group.controls['363.1.5.18'].enable();
            this.group.controls['363.1.5.19'].enable();
          }
        }
}
