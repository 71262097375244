import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wpr-case-assignment',
  templateUrl: './wpr-case-assignment.component.html',
  styleUrls: ['./wpr-case-assignment.component.css']
})

export class WprCaseAssignmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}