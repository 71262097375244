import {Component, EventEmitter, Inject, Input, OnInit, ViewChild} from '@angular/core';
import { NavigationEnd, Event as RouterEvent, Router, ActivatedRoute } from '@angular/router';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Pipe, PipeTransform } from '@angular/core';


import { AppUtilService } from '../../common/app.util.service';
import { ConstantsService } from '../../common/constants.service';
import { DataShareService } from '../../common/data-share.service';


import {ApiManager} from "../../common/api-manager.service";
import {Dictionary} from "underscore";
import {NavClass} from "../../model/api/nav.class";
import * as _ from 'underscore';

import {NavigationService} from "../../common/navigation.service";
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';




@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.css"],
})
export class NavigationComponent implements OnInit {
  private navObj: any;
  panelOpenState = false;

  @Input() resizeNav: boolean;
  private navItems: Dictionary<NavClass>;
  private navHead: NavClass[];
  private navChildren: NavClass[];
  public currentParent: NavClass;

  private currPanel = "";
  private currMenuItemIndex;
  private prevMenuItemIndex;
  private optionalNavsList: NavClass[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilService: AppUtilService,
    public constantsService: ConstantsService,
    private shareService: DataShareService,
    public navigationService: NavigationService,
    public dialog: MatDialog
  ) {}
  hideValue: boolean;
  navVisible() {
    this.navigationService.getHideValue().subscribe((value) => {
      this.hideValue = value;
      console.log(this.hideValue, "getvalue");
    });
  }
  extractFirstCharacters(tag_txt: string): string {
    const words = tag_txt.split(" ");
    let firstCharacters = "";
    words.forEach((word) => {
      firstCharacters += word.charAt(0);
    });

    return firstCharacters;
  }
  ngOnInit() {
    this.navigationService.getHideValue().subscribe((value) => {
      this.hideValue = value;
      console.log(this.hideValue, "getvalue");
    });
  }

  ngOnDestroy() {}

  openLongTerm(index: number, flag) {
    if (flag) {
      document.getElementById("benefitMonth" + index).click();
    }
  }

  openDetails(id: any, index: number) {
    const id2 = id.replace("Btn", "");
    document.getElementById(id).focus();
    const expandId = id2.slice(0, id2.length - 1) + "Expand" + index;
    const classes = document.getElementById(expandId).className.split(" ");
    if (classes[1] !== "in") {
      document.getElementById(id2).click();
    }
    const classesMain = document
      .getElementById("long-term" + index)
      .className.split(" ");
    if (classesMain[1] !== "in") {
      this.openLongTerm(index, true);
    }
    document.getElementById(id).click();
  }

  private extractData(res) {
    let body = res;
    return body || {};
  }
  private handleError(error: any): Promise<any> {
    console.error("An error occurred", error);
    return Promise.reject(error.message || error);
  }

  changeColor() {
    let cols = document.getElementsByClassName("mat-expansion-indicator");
    for (let i = 0; i < cols.length; i++) {
      cols[i].setAttribute("color", "white!important");
    }
  }

  getNav() {
    this.navigationService.getNavs().subscribe((res) => {
      this.setNavs();
    });
  }

  setNavs() {
    this.navItems = this.navigationService.getNavItems();
    this.navHead = this.navigationService.getNavHead();
  }

  getNavSelected(nav) {
    if (
      this.navigationService.getSelectedNavHead() &&
      this.navigationService.getSelectedNavHead() == nav.prnt_desc
    ) {
      this.navigationService.setSelectedNavHead(nav.prnt_desc);
      return true;
    } else {
      return this.expandAccord(nav);
    }
  }

  giveRequiredNavs(parent_cd: string) {
    return this.giveChildren(parent_cd).filter(
      (item) =>
        //item.isRequired === 'N' &&
        item["navgtnInfo"] == undefined ||
        item["navgtnInfo"] == '{"selected": true}'
    );
    // .filter(item => (
    //   item.prnt_desc === 'parentCalcSheet'
    //   && this.constantsService.ReviewInfo
    //   && this.constantsService.ReviewInfo.rvwType ==='FSPNegative'
    //   && (this.constantsService.caseHomeData.stReasonCd === '301' || this.constantsService.caseHomeData.stReasonCd === '320')
    //   ?  : true )) // TODO: change isRequired to 'Y'
  }

  giveRequiredSubNavs(typeCd) {
    let sendChild: NavClass[];
    sendChild = _.filter(this.navigationService.getNavItems(), (val) => {
      return (
        val.typeCd &&
        val.typeCd.startsWith(typeCd.split(".")[0]) &&
        this.isSubGroupChildren(typeCd)
      );
    });
    return sendChild;
  }

  isSubGroup(typeCd) {
    let result = false;
    let subGroupList = ["150", "311.0"];

    subGroupList
      .filter((tCd) => tCd === typeCd)
      .map((item) => {
        result = true;
      });
    return result;
  }

  isSubGroupChildren(typeCd) {
    let result = false;
    let subGroupList = [
      "150",
      "150.2",
      "150.3",
      "150.4",
      "150.5",
      "150.6",
      "150.7",
      "150.8",
      "311.0",
      "311.1",
      "311.2",
      "311.3",
      "311.4",
      "311.5",
      "311.6",
      "311.7",
      "311.8",
      "311.9",
      "311.10",
      "311.312",
    ];
    if (typeCd) {
      subGroupList
        .filter((tCd) => tCd === typeCd)
        .map((item) => {
          result = true;
        });
    }
    return result;
  }

  giveChildren(parent_cd: string): NavClass[] {
    let sendChild: NavClass[];
    sendChild = _.filter(this.navigationService.getNavItems(), (val) => {
      return val.is_parent === false && val.prnt_desc === parent_cd;
    });
    return sendChild;
  }

  expandAccord(nav: NavClass) {
    let result = false;
    let currentNav: NavClass;
    currentNav = _.find(this.navigationService.getNavItems(), (val) => {
      return val.screen_defn == this.router.url;
    });
    if (currentNav) {
      result = currentNav.prnt_desc == nav.prnt_desc;
      if (result) {
        this.currPanel = currentNav.prnt_desc;
      }
    }

    return result;
  }

  isCurrentPage(child: NavClass, idx?) {
    let result = false;

    result = child.screen_defn == this.router.url;

    if (result && idx != this.currMenuItemIndex) {
      this.prevMenuItemIndex = this.currMenuItemIndex;
      this.currMenuItemIndex = idx;
    }
    return result;
  }
  navigateToPage(child: NavClass) {
    if (child.disabled == "N" && this.getNavObjDisable(child)) {
      const navUrl = child.screen_defn.split("?")[0];
      let navUrlParam: any;
      if (child.screen_defn.split("?").length > 1) {
        const navUrlQryStr = child.screen_defn.split("?")[1];
        if (navUrlQryStr.split("=").length > 1) {
          navUrlParam = { queryParams: { type: navUrlQryStr.split("=")[1] } };
        }
      }

      this.router.navigate([navUrl], navUrlParam);
    }
    this.navigationService.setSelectedNavHead(child.prnt_desc);
  }

  getNavObjDisable(child: NavClass) {
    let isDisable = true;

    // this.navObj.map(obj => {
    //   if (obj.id === child.html_tag) {
    //     isDisable = obj.clickable;
    //   }
    // });

    if (
      (child.nvgtn_cd == "nav-HouseholdCompositionAllotment" ||
        child.nvgtn_cd == "nav-CalcSheetVar") &&
      child.disabled == "Y"
    ) {
      isDisable = false;
    }
    if (
      !this.constantsService.ReviewInfo &&
      child.nvgtn_cd !== "nav-QCAnalystAvailability" &&
      child.nvgtn_cd !== "nav-QCCaseassignment" &&
      child.nvgtn_cd !== "nav-QCassignmentStatus" &&
      child.nvgtn_cd !== "nav-QCWorkloadassignment" &&
      child.nvgtn_cd !== "nav-QCSampleList" &&
      child.nvgtn_cd !== "nav-QCInquiry" &&
      child.nvgtn_cd !== "nav-ReviewInformation" &&
      child.nvgtn_cd !== "nav-case-review-list" &&
      child.nvgtn_cd !== "nav-FSME-dashboard" &&
      child.nvgtn_cd !== "nav-SNAP-sample" &&
      child.nvgtn_cd !== "nav-FSME-case-assignment" &&
      child.nvgtn_cd !== "nav-FSME-supervisory-review" &&
      child.nvgtn_cd !== "nav-FSME-schedule" &&
      child.nvgtn_cd !== "nav-ldss-review-list" &&
      // && child.nvgtn_cd !== 'nav-ReviewSummary'
      // && child.nvgtn_cd !== 'nav-Findings-Response'
      // && child.nvgtn_cd !== 'nav-FSME-LDSS-Report'
      child.nvgtn_cd !== "nav-PA-dashboard" &&
      child.nvgtn_cd !== "nav-payment-accuracy-sample" &&
      child.nvgtn_cd !== "nav-PA-case-assignment" &&
      child.nvgtn_cd !== "nav-pa-case-review-list" &&
      child.nvgtn_cd !== "nav-PA-supervisory-review" &&
      // && child.nvgtn_cd !== 'nav-PAReviewSummary'
      // && child.nvgtn_cd !== 'nav-PA-Findings-Response'
      child.nvgtn_cd !== "nav-WPR-dashboard" &&
      child.nvgtn_cd !== "nav-wpr-sample" &&
      child.nvgtn_cd !== "nav-WPR-case-assignment" &&
      child.nvgtn_cd !== "nav-WPR-case-review-list" &&
      child.nvgtn_cd !== "nav-Monitoring-dashboard" &&
      child.nvgtn_cd !== "nav-monitoring-sample" &&
      child.nvgtn_cd !== "nav-monitoring-case-assignment" &&
      child.nvgtn_cd !== "nav-Monitoring-case-review-list" &&
      child.nvgtn_cd !== "nav-monitoring-memos"
    ) {
      isDisable = false;
    }

    // //TODO: REMOVE ONCE FSME ADMIN PART DONE
    // if (this.router.url.includes('/bpe/monitoring/')) {
    //   isDisable = true;
    // }

    // diable case review or evaluation flow disable
    if (
      this.navigationService.appCd !== "QC" &&
      this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwType
    ) {
      if (
        this.constantsService.ReviewInfo.rvwType === "Evaluation" &&
        child.prnt_desc === "parentFSME" &&
        !(
          child.nvgtn_cd === "nav-case-review-list" ||
          child.nvgtn_cd === "nav-ldss-review-list"
        )
      ) {
        isDisable = false;
      } else if (
        this.constantsService.ReviewInfo.rvwType !== "Evaluation" &&
        child.prnt_desc === "parentInterview" &&
        !(
          child.nvgtn_cd === "nav-case-review-list" ||
          child.nvgtn_cd === "nav-ldss-review-list"
        )
      ) {
        isDisable = false;
      }
    }

    // disable QC16, 380-1 schedule, 380 WB if review status is PE-In Progress
    // if (this.navigationService.appCd === 'QC' && this.constantsService.ReviewInfo && this.constantsService.ReviewInfo.rvwType) {
    //   if (this.constantsService.ReviewInfo.rvwSts  === 'PE'
    //     && (
    //       child.prnt_desc === 'parentQC16FSP' ||
    //       child.prnt_desc === 'parentQCSchedule' ||
    //       child.prnt_desc === 'parentQC380Workbook' ||
    //       child.prnt_desc === 'parentQC16FSN' ||
    //       child.prnt_desc === 'parentQCFNS245Schedule'
    //       )
    //    ) {
    //     isDisable = false;
    //   }
    // }
    if (
      this.navigationService.appCd === "QC" &&
      this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwType &&
      this.constantsService.caseHomeData
    ) {
      if (
        this.constantsService.ReviewInfo.rvwType === "FSPNegative" &&
        (child.nvgtn_cd === "nav-CalcSheet" ||
          child.nvgtn_cd === "nav-SRMonth") &&
        !(
          this.constantsService.caseHomeData.stReasonCd === "301" ||
          this.constantsService.caseHomeData.stReasonCd === "320" ||
          this.constantsService.caseHomeData.stReasonCd === "000"
        )
      ) {
        isDisable = false;
      }
    }

    return isDisable;
  }

  getStatus(nav) {
    let result = false;
    this.navObj.map((obj) => {
      if (obj.id === nav.html_tag) {
        result = obj.status;
      }
    });
    return result;
  }

  // scrollPanel() {
  //   if (this.parentEligibilityDetermination && this.currMenuItemIndex && this.currMenuItemIndex != this.prevMenuItemIndex ){
  //   this.parentEligibilityDetermination.scrollToIndex(this.currMenuItemIndex);
  //   }
  // }

  refreshNav() {
    this.navigationService.getPrograms().then((v) => {
      this.afterNavChange();
    });
  }

  afterNavChange() {
    // Do Nothing
    // TODO : if any change after nav change while adding new program
  }

  openDialog(nvHead) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const nvChildrend = this.giveRequiredNavs(nvHead);
    dialogConfig.data = {
      navHead: nvHead,
      navChild: nvChildrend,
    };

    const dialogRef = this.dialog.open(NavigationDialog, dialogConfig);
    dialogRef.updateSize("50%", "85%");
    dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        console.log("afterClose", val);
      }
    });
  }
}


@Component({
  selector: 'navigation-dialog',
  templateUrl: 'navigation-dialog.html',
})

export class NavigationDialog implements OnInit {

  
  constructor(
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    public appUtilService: AppUtilService,
    private router: Router,
    public navigationService: NavigationService,
  ) {
  }

  ngOnInit() {
  }

  navigateToPage(child: NavClass){
    if(child.disabled == 'N' ) {
      const navUrl = child.screen_defn.split('?')[0];
      let navUrlParam: any ;
      if (child.screen_defn.split('?').length > 1) {
        const navUrlQryStr = child.screen_defn.split('?')[1];
        if ( navUrlQryStr.split('=').length > 1  ) {
          navUrlParam = { queryParams: { type: navUrlQryStr.split('=')[1] } } ;
        }
      }

      this.router.navigate([navUrl], navUrlParam);
    }
    
  }
}