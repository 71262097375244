import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-field68-a-after',
  templateUrl: './field68-a-after.component.html',
  styleUrls: ['./field68-a-after.component.css']
})
export class Field68AAfterComponent  extends BaseComponent implements OnInit {


isNotEditable: boolean = false;
btn68BackDisable: boolean = false;
handle68 : boolean = false;
  ngOnInit() {

    if(this.getControlValue('68a.5')=='Y'){
      this.group.controls['68a.6'].disable();
      this.group.controls['68a.7'].enable();
      this.group.controls['68a.8'].enable();
      this.group.controls['68a.9'].enable();
      this.group.controls['68a.10'].enable();
    } else 
    if(this.getControlValue('68a.5')=='N'){
      this.group.controls['68a.6'].enable();
      this.group.controls['68a.7'].disable();
      this.group.controls['68a.8'].disable();
      this.group.controls['68a.9'].disable();
      this.group.controls['68a.10'].disable();
    }
    
    if(this.getControlValue('68a.1')=='Y') {
      this.group.controls['68a.3'].disable();
    } else 
    if(this.getControlValue('68a.1')=='N'){
      this.group.controls['68a.2a'].disable();
      this.group.controls['68a.2b'].disable();
      this.group.controls['68a.2c'].disable();
    }

    if(this.getControlValue('68a.6')=='Y'){
      this.btn68BackDisable = true;
    } else 
    if(this.getControlValue('68a.6')=='N'){
      this.btn68BackDisable = false;
    }

    if(this.getControlValue('68a.7')=='N')
    {
    this.group.controls['68a.8'].disable();
    this.group.controls['68a.9'].disable();
    this.group.controls['68a.10'].disable();
    }

    if(this.getControlValue('68a.8')=='Y')
    {
    this.btn68BackDisable = true;  
    this.group.controls['68a.9'].disable();
    this.group.controls['68a.10'].disable();
    
    }

    if(this.getControlValue('68a.9')=='Y')
    {
    this.btn68BackDisable = true;
    this.group.controls['68a.10'].disable();
   }

   if ( this.constantsService.in_68_Code) {
    this.group.controls["68a.12"].patchValue(this.constantsService.in_68_Code);

  }
  this.handle68a1(this.getControlValue('68a.1'));
  this.handle68a5(this.getControlValue('68a.5'));
  this.handle68a6(this.getControlValue('68a.6'));
  this.handle68a7(this.getControlValue('68a.7'));
  this.handleselected68a8(this.getControlValue('68a.8'));
  this.handleselected68a9(this.getControlValue('68a.9'));
  this.handleselected68a10(this.getControlValue('68a.10'));
    super.ngOnInit();
    this.determineAccess();
  }

  handle68a1(val){

    if(val){
      if(val=='N'){
        this.group.controls['68a.2a'].disable();
        this.group.controls['68a.2b'].disable();
        this.group.controls['68a.2c'].disable();
         this.group.controls['68a.3'].enable();
      } else 
      if(val=='Y'){
        this.group.controls['68a.2a'].enable();
        this.group.controls['68a.2b'].enable();
        this.group.controls['68a.2c'].enable();
         this.group.controls['68a.3'].disable();
      }
    }
  }

  handle68a5(val){

    if(val){
      if(val=='Y'){
        this.group.controls['68a.6'].disable();
        this.group.controls['68a.7'].enable();
        this.group.controls['68a.8'].enable();
        this.group.controls['68a.9'].enable();
        this.group.controls['68a.10'].enable();
        this.handle68 = false;
        this.setControlValue('68a.12',3);
      } else 
      if(val=='N'){
        this.group.controls['68a.6'].enable();
        this.group.controls['68a.7'].disable();
        this.group.controls['68a.8'].disable();
        this.group.controls['68a.9'].disable();
        this.group.controls['68a.10'].disable();
        this.handle68 = true;
        this.setControlValue('68a.12',0);
      }
    }
  
  }

  handle68a6(val) {
    if(val){
      if(val=='Y'){
        this.btn68BackDisable = true;
        this.setControlValue('68a.12',3);
        this.handle68 = false;
      } else  if(val=='N')
      {
        this.btn68BackDisable = false;
        this.handle68 = true;
        this.setControlValue('68a.12',0);
      }
    }
  }

  handle68a7(val){
    if(val){
    if(val=='N')
      {
      this.group.controls['68a.8'].disable();
      this.group.controls['68a.9'].disable();
      this.group.controls['68a.10'].disable();
      this.handle68 = true;
      this.setControlValue('68a.12',0);
      }
      else{
        this.group.controls['68a.8'].enable();
      this.group.controls['68a.9'].enable();
      this.group.controls['68a.10'].enable();
      this.handle68 = true;
        this.setControlValue('68a.12',0);
      }
    }
  }

  handleselected68a8(val){
    if(val){
    if(val=='Y')
      {
        this.btn68BackDisable = true;
        this.group.controls['68a.9'].disable();
        this.group.controls['68a.10'].disable();
        this.handle68 = false;
        this.setControlValue('68a.12',3);
      } else 
      if(val=='N'){
        this.btn68BackDisable = false;
        this.group.controls['68a.9'].enable();
        this.group.controls['68a.10'].enable();
        this.handle68 = true;
        this.setControlValue('68a.12',0);
      }
    }
  }

  handleselected68a9(val){
    if(val){
    if(val=='Y')
      {
        this.group.controls['68a.10'].disable();
      } else 
      if(val=='N'){
        this.group.controls['68a.10'].enable();
      }
    }
  }

  handleselected68a10(val){
    if(val){
    if(val=='Y')
      {
        this.btn68BackDisable = true;
        this.handle68 = false;
        this.setControlValue('68a.12',3);
      } else 
      if(val=='N'){
        this.btn68BackDisable = false;
        this.handle68 = true;
        this.setControlValue('68a.12',0);
      }
    }
  }


}
