
import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-qc-interview-guide',
  templateUrl: './qc-interview-guide.component.html',
  styleUrls: ['./qc-interview-guide.component.css']
})

export class QcInterviewGuide extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }
}