import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-qc32-customer-information-request',
  templateUrl: './qc32-customer-information-request.component.html',
  styleUrls: ['./qc32-customer-information-request.component.css']
})
export class QC32CustomerInformationRequestComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }
  convertSpanishPDF() {
    this.callGenerateSpanishPDF.emit(undefined);
  }
}