export interface LookupClass {
      lookUpName: string;
      options: LookupOptions[];
  }

export interface LookupOptions {
      code: string;
      value: string;
  }

  export class LookupClass {

    constructor(
      public lookupTypeCd:string = '',
      public lkpDesc:string = '',
      public localeCd:string = '',
      public lookupLongVal:string = '',
      public sortOrderNbr:number = null,
      public lkpCatgryTyp: string = '')
    {}
  }
  