import { Injectable } from '@angular/core';



@Injectable()
export class DataShareService {

    dataMap: Map<string, any>;

    constructor() {
        this.dataMap = new Map();
    }

    addDataToMap(key: string, value: any) {
        if (key) {
          this.dataMap.set(key, value);
        }
      }

      getDataFromMap(key: string): any {
        let val: any;
        if (key) {
          val = this.dataMap.get(key);
        }
        if (key === 'currentOffice' && (this.dataMap.get(key) === undefined || this.dataMap.get(key) === '')) {
          return 'Harbor View';
        }
        return val;
      }
      removeDataFromMap(key: string) {
        this.dataMap.delete(key);
      }

}
