import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-information-request',
  templateUrl: './information-request.component.html',
  styleUrls: ['./information-request.component.css']
})
export class InformationRequestComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
