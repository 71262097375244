import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ApiManager } from 'src/app/common/api-manager.service';
import * as Highcharts from 'highcharts';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NavigationService } from 'src/app/common/navigation.service';
import { ConstantsService } from 'src/app/common/constants.service';

export interface PeriodicElement {
    name: string;
    position: number;
    weight: number;
    symbol: string;
    incorrect: number;
}

export interface Quarter {
    value: string;
    viewValue: string;
}

@Component({
    selector: 'app-pa-dashboard',
    templateUrl: './pa-dashboard.component.html',
    styleUrls: ['./pa-dashboard.component.css']
})

export class PaDashboardComponent implements OnInit {
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    dataSource = new MatTableDataSource();
    data: any;
    fromDate: Date;
    toDate: Date;
    isNotEditable: boolean = false;
    selection = new SelectionModel(true, []);
    constructor(
        private readonly router: Router,
        private apiManager: ApiManager,
        private fb: FormBuilder,
        public navigationService: NavigationService,
        public constantsService: ConstantsService
    ) {

        //  this.dataSource = new MatTableDataSource();
        this.buildForm();
        this.populateData(this.monthlist.value);
    }
    monthlist: FormGroup;
    displayedColumns: string[] = ['ldssName', 'correctCases', 'inCorrectCases', 'totalCases'];
    populateData(model) {

        const queryParams: any[] = [];
        // queryParams.push('BPE-PA');

        this.apiManager.fetchData('getcountycases', undefined, ApiManager.POST, model, undefined, undefined, queryParams)
            .subscribe(
                res => {
                    if (res) {
                        this.data = res;
                        this.dataSource.data = [];
                        this.dataSource.data = res.bpePymntAccrcyGraphData;
                        this.pacomparisonGraph(this.data);
                        this.pamonthlyGraph(this.data);
                        this.paoverallGraph(this.data);
                    }
                });
    }

    buildForm() {
        let currentDate = new Date();
        this.toDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        currentDate.setDate(currentDate.getDay()-30);
        this.fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        
        this.monthlist = this.fb.group({
            fromMonth: [this.fromDate, Validators.required],
            toMonth: [this.toDate, Validators.required]
        });
    }

    ngOnInit() {
        this.buildForm();
        // countysamples  
        setTimeout(() => {
            // this.getSelectSample();
            this.pacomparisonGraph(this.data);
            this.data.totalMonthList = this.formatMonth(this.data.totalMonthList)
            this.pamonthlyGraph(this.data);
            this.paoverallGraph(this.data);
        }, 2000)
    }
    @ViewChild('ref', { static: false }) ref: any;

    masterToggle(_ref: any) {
      if(_ref.checked){
          this.checkPageOnly();
      }
      else {
          this.selection.clear();
      }
    }
  
    checkPageOnly() {
        let i = this.dataSource.paginator.pageIndex * this.dataSource.paginator.pageSize;
        let end = (this.dataSource.paginator.pageIndex + 1) * this.dataSource.paginator.pageSize;
        for(i; i < end; i++) {
          this.selection.select(this.dataSource.data[i]);
        }
       console.log('selected...',this.selection.selected)
    }
  
      pageChange() {
          this.ref.checked = false;
          this.masterToggle(this.ref);
      }
    determineAccess() {
        const ref = this.constantsService.getSecurityObject();
    
        if ((ref.accessMap[''] != null && ref.accessMap[''].accessMode != 'rw')) {
                this.isNotEditable = true;
        }
    }
    
    pacomparisonGraph(paComparison: any) {
        var chart = Highcharts.chart('countysamples', {

            chart: {
                //renderTo: 'countysamples',
                type: 'column'
            },

            title: {
                text: 'Payment Accuracy samples for Oct 19-Comparison of Total, correct and incorrect samples'
            },

            // subtitle: {
            //     text: 'Resize the frame or click buttons to change appearance'
            // },

            legend: {
                //   align: 'right',
                verticalAlign: 'bottom',
                layout: 'vertical'
            },

            xAxis: {
                categories: paComparison.countyList,
                labels: {
                    x: -10
                }
            },

            yAxis: {
                allowDecimals: false,
                title: {
                    text: 'Count'
                }
            },

            series: [{
                name: 'Total Samples',
                data: paComparison.totalCasesPerCountyTca,
                type: undefined
            }, {
                name: 'Correct',
                data: paComparison.totalCorrectCasesPerCounty,
                type: undefined
            },
            {
                name: 'Incorrect',
                data: paComparison.totalIncorrectCasesPerCounty,
                type: undefined
            },
            ],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal'
                        },
                        yAxis: {
                            labels: {
                                align: 'left',
                                x: 0,
                                y: -5
                            },
                            title: {
                                text: null
                            }
                        },
                        subtitle: {
                            text: null
                        },
                        credits: {
                            enabled: false
                        }
                    }
                }]
            }
        });
    }

    pamonthlyGraph(paMonthly: any) {
        var chart = Highcharts.chart('monthlysamples', {
            chart: {
                // renderTo: 'monthlysamples',
                type: 'line'
            },

            title: {
                text: 'Total samples for the corresponding review month'
            },

            // subtitle: {
            //     text: 'Resize the frame or click buttons to change appearance'
            // },

            legend: {
                // align: 'right',
                verticalAlign: 'bottom',
                layout: 'vertical'
            },

            xAxis: {
                categories: paMonthly.totalMonthList,
                labels: {
                    x: -10
                }
            },

            yAxis: {
                allowDecimals: false,
                title: {
                    text: 'Count'
                }
            },

            series: [{
                name: 'Total Samples for the corresponding review month',
                data: paMonthly.totalCasesPerMonth,
                type: undefined
            }
            ],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal'
                        },
                        yAxis: {
                            labels: {
                                align: 'left',
                                x: 0,
                                y: -5
                            },
                            title: {
                                text: null
                            }
                        },
                        subtitle: {
                            text: null
                        },
                        credits: {
                            enabled: false
                        }
                    }
                }]
            }
        });
    }
    
    paoverallGraph(paOverall: any) {
        setTimeout(()=>{ 
            Highcharts.chart('totalsamples',{
              chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie'
              },
              title: {
                text: 'Total Sample for the period'
              },
              tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  dataLabels: {
                    enabled: false
                  },   
                  showInLegend: true
                  }
                },
                series: [{
                  type:'pie',
                  name: 'Total',
                  data: [{
                    name: 'Total Correct Cases', 
                    y : this.convertMonthToOveral(paOverall.totalCorrectCasesPerMonth),
                  }, {
                    name: 'Total Incorrect Cases' ,
                    y: this.convertMonthToOveral(paOverall.totalIncorrectCasesPerMonth)
                  }, {
                    name: 'Total To Be Reviewed',
                    y: this.convertMonthToOveral(paOverall.totalToBeReviewedCasesPerMonth)
                  },]
                }],
                responsive: {
                    rules: [{
                        condition: {
                            maxWidth: 1000
                        },
                        chartOptions: {
                            legend: {
                                align: 'center',
                                verticalAlign: 'bottom',
                                layout: 'horizontal'
                            },
                            yAxis: {
                                labels: {
                                    align: 'left',
                                    x: 0,
                                    y: -5
                                },
                                title: {
                                    text: null
                                }
                            },
                            subtitle: {
                                text: null
                            },
                            credits: {
                                enabled: false
                            }
                        }
                    }]
                }
              });
            },200)    
    }

    convertMonthToOveral(arrayValues:any) {
        let arValues:[] = arrayValues;
        let result: number = 0;
        let arResult = [];
        arValues.map(val => {
            result = result + val;
        })
        return result;
    }
    formatMonth(val){
        let monthlist = [];
        val.map(mnt => { 
            monthlist.push(mnt.substring(4) + '/' + mnt.substring(0,4))
        })
        return monthlist;
    }

    next() {
        this.navigationService.navigatePage(this.router.url, 'next');
    }
    print(): void {
        // let printContents, printMember, printStyle, printLink;
        // printContents = document.getElementById('print-section');
        // printMember = document.getElementById('print-member');
        // printStyle = this.printService.getTagsHtml('style');
        // printLink = this.printService.getTagsHtml('link');
        // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
        // this.printService.printTable = printContents ? printContents.innerHTML : null;
        // this.printService.printStyle = printStyle;
        // this.printService.printLink = printLink;
        // this.printService.print();
        window.print();
      }
      

    // getSelectSample() {
    //     const queryParams: any[] = [];

    //     queryParams.push('202002');
    //     this.apiManager.fetchData('getcountycases', undefined, ApiManager.GET,
    //         undefined, undefined, undefined, queryParams)
    //         .subscribe(res => {
    //             if (res) {
    //                 console.log(res);

    //             }
    //         });
    // }
}