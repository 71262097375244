export class ContactSupportClass {
    public category: string ='';
    public description: string = '';
    public expectedResult: string = '';
    public actualResult: string = '';
    public workerId: string = '';
    public severity: string = '';
    public distOffice: string = '';
    public county: string = '';
    public CategoryName: string = '';
    public categoryName: string = '';
    public defectCategory: string = '';
}