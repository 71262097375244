import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../../../../common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-fsme-ldss-report',
  templateUrl: './fsme-ldss-report.component.html',
  styleUrls: ['./fsme-ldss-report.component.css']
})
export class FsmeLdssReportComponent extends BaseComponent implements OnInit {


  ngOnInit() {
    super.ngOnInit();
    
   
  }


}