import { AfterViewInit, Component, OnInit, ViewChild, AfterContentChecked, Input } from '@angular/core';
import * as moment from 'moment';
import { ApiManager } from '../../common/api-manager.service';
import { Form, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Observable } from "rxjs";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ConstantsService } from '../../common/constants.service';
import { NavigationService } from '../../common/navigation.service';
import { startWith, map } from 'rxjs/operators';
import 'rxjs/operator/filter';
import { ConfirmationDialog } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { StorageService } from 'src/app/common/storage.service';

export interface UserList {
  uniqueid: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
}

@Component({
  selector: 'app-work-items',
  templateUrl: './work-items.component.html',
  styleUrls: ['./work-items.component.css']
})
export class WorkItemsComponent implements OnInit {
  public searchForm: FormGroup;
  public lookupValues: any[] = [];
  public filteredWorkers: Observable<any[]>;
  public filteredDO: Observable<any[]>;
  public result: any;
  public limit: number = 1000;
  public offset: number = 0;
  public pageEvent: PageEvent;
  @Input()
  isActive = true;
  public dispCol = ['reviewNum',
    // 'case', 
    // 'workItmStusCd',
    // 'caseStatus', 
    'hohName', 'irn', 'assignedDate', 'workItemDueDt', 'assingedTo', 'workItemTypeCd', 'workItemSubTypeCd', 'workItemDescription',
    'alertDt', 'workItemAction'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>([]);
  DOs: DOOffice[];
  usersList: UserList[];
  finalusersList: UserList[];

  constructor(
    private fb: FormBuilder,
    private apiManager: ApiManager,
    public dialog: MatDialog,
    private storageService: StorageService,
    public constantsService: ConstantsService,
    public navigationService: NavigationService,
  ) {
  }

  ngOnInit() {

    this.createSearchForm();
    setTimeout(() => {
      this.DOs = this.constantsService.getDistinctDOs();
      this.usersList = this.constantsService.getUsersList();
    }, 2000);
    this.getWI(undefined, undefined, false, true);

    this.getCaseWorkers();

  }

  createSearchForm() {
    let d = new Date();
    this.searchForm = this.fb.group({
      caseId: null,
      wiTypeCd: null,
      //wiAssignedTo: [this.constantsService.getSecurityObject()? this.constantsService.getSecurityObject().user.uniqueId : '' ],
      wiAssignedTo: null,
      dueDateEnd: null,
      dueDateStart: null,
      dueDateCheck: null,
      wiStatusCd: null,
      dashBoardPageInd: null,
      queuePageInd: null,
      createdDateEnd: [d, Validators.compose([Validators.required])],
      createdDateStart: [moment(d).subtract(7, 'days'), Validators.compose([Validators.required])],
      createdDateCheck: null,
      wiStatus: null,
      reviewStatus: null,
      wiSubTypeCd: null,
      wiDO: null,
      limit: null,
      offset: null,
      programCd: null
    });
  }

  getPaginatorData(val) {
    console.log(val);
    this.getWI(val.pageSize, val.pageIndex);
  }


  getCaseWorkers() {
    this.usersList = this.constantsService.getUsersList();
    this.usersList.forEach(value => {
      value.fullName = value.firstName + ' ' + value.lastName;
    });
    this.finalusersList = this.usersList;
    this.filterWorker();
    // this.getWI();
    console.log(this.lookupValues);
  }

  getWorkerName(user: UserList): string {
    //    worker = this.constantsService.getUsersList();
    return user && user.firstName ? user.firstName + " " + user.lastName : undefined;
  }
  getDoName(worker) {
    return worker ? worker.codeAndName : undefined;
  }

  pagNavigate(val) {
    this.offset = (this.offset == 0 && val == -1) ? this.offset : this.offset + val;
    this.getWI(this.limit, this.offset);
  }
  pagLimitChange(limit) {
    this.offset = 0;
    this.limit = limit;
    this.getWI(limit);
  }


  statusFormat(code) {
    if (!code) { return };
    code = code.trim();
    if (code == 'OP') {
      return 'OPEN'
    } else if (code == 'CL') {
      return 'CLOSED'
    } else if (code == 'PE') {
      return 'PENDING'
    } else if (code == 'IP') {
      return 'In Progress'
    }
  }


  typeFormat(code, val) {
    let result = '';

    if (val === 'WorkItemType') {
      if (code === 'AL') {
        result = 'Alert';
      }
    }

    if (val === 'WorkItemSubType') {
      if (code === 'RV') {
        result = 'Review';
      }
      else if (code === 'ER') {
        result = 'Error';
      } else if (code === 'RR') {
        result = 'Re-Review';
      }
    }

    // let obj = this.lookupValues[val];
    // let result = '';
    // if (code && obj) {
    //   for (let i = 0; i < obj.length; i++) {
    //     if (obj[i] && obj[i].lkpDesc && obj[i].lkpDesc.trim() == code.trim()) {
    //       result = obj[i].lookupShortVal
    //     }
    //   }
    // }
    return result;
  }

  filterWorker() {
    this.filteredWorkers = this.searchForm.get('wiAssignedTo').valueChanges.pipe(startWith(''), map(value => typeof value === 'string' ? value : value.fullName),
      map(name => name ? this.workerFilter(name) : this.finalusersList.slice()));
  }

  private workerFilter(name: string) {
    const filterValue = name.toLowerCase();
    return this.finalusersList.filter(option => option.fullName.toLowerCase().indexOf(filterValue) === 0);
  }
  getWI(limit?, off?, n?, isFromInit = false) {
    if (n) {
      this.offset = 0;
    }
    console.log(this.searchForm.value);
    let obj = this.searchForm.value;
    let worker = this.searchForm.value.wiAssignedTo;
    if (worker === null || worker === "") {
      // obj.wiAssignedTo = obj.wiAssignedTo && obj.wiAssignedTo.uniqueid ? obj.wiAssignedTo.uniqueid : this.constantsService.getSecurityObject().user.uniqueId;
    } else {
      let index = this.finalusersList.findIndex(x => x.fullName === worker);
      obj.wiAssignedTo = index < 0 ? null : this.finalusersList[index].uniqueid;
    }
    if (isFromInit) {
      obj.wiAssignedTo = this.storageService.getAccessSecurity().user.uniqueId;
      obj.createdDateEnd = null;
      obj.createdDateStart = null;
    }
    obj.createdDateCheck = obj.createdDateEnd && obj.createdDateStart ? true : null;
    obj.dueDateCheck = obj.dueDateEnd && obj.dueDateStart ? true : null;
    obj.limit = limit ? limit : this.limit;
    obj.offset = off ? off : 1;
    if ((obj.caseId != null) && (obj.caseId.length < 7)) {
      if (obj.caseId != "") {
        obj.caseId = new Date().getFullYear().toString() + obj.caseId;
      }
    }
    // obj.programcd= obj.programcdgi
    console.log(obj);
    const sendObj: any = {
      "search": {
        "caseId": null,
        "programCd": obj.programCd,
        "ldssCd": obj.wiDO,
        "workItemTypeCd": obj.wiTypeCd,
        "workItemSubTypeCd": obj.wiSubTypeCd,
        "workItemDescription": null,
        "assingedTo": obj.wiAssignedTo,
        "actionType": null,
        "workItemStatusCd": obj.wiStatus,
        "reviewNum": obj.caseId,
        "createdBy": null,
        "updatedBy": null,
        "assignedDate": obj.createdDateStart,
        "workItemDueDt": obj.dueDateStart,
        "workItemId": null,
        "oldWorkItemId": null,
        "workItemFromDueDt": obj.dueDateStart,
        "workItemToDueDt": obj.dueDateEnd,
        "assignedFromDt": obj.createdDateStart,
        "assignedToDt": obj.createdDateEnd,
        "personName": null,
        "deleteInd": this.isActive ? 'N' : 'Y'
      },
      "results": [],
      "paginationData": {
        "resultsPerPage": obj.limit,
        "pageNumber": obj.offset
      },
      "range": null
    };

    // this.apiManager.fetchData('workItemSearch', undefined, ApiManager.POST, obj).toPromise().then(val => {
    const queryParams: any[] = [];
    let appCd: string = '';
    appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    queryParams.push(appCd);
    queryParams.push(obj.reviewStatus);

    // queryParams.push(obj.wiAssignedTo );
    const objBody: any = this.constantsService.getSecurityObject().user.uniqueId;
    this.apiManager.fetchData('workItemSearch', undefined, ApiManager.POST, sendObj, undefined, undefined, queryParams)
      .toPromise().then(val => {
        console.log(val);
        this.result = val;
        if (isFromInit && this.isActive) {
          this.constantsService.alertCount = val.filter(row => row.workItemStatusCd === 'OP').length;
        }
        ConstantsService.isConfirmChecked = this.result['confirmSt'];
        this.dataSource = new MatTableDataSource<any>(val);
        setTimeout(() => this.dataSource.paginator = this.paginator);
      }).catch(err => {
        console.log(err);
      });
  }

  search(row) {

    const queryParams: any[] = [];
    queryParams.push(row.reviewNum);
    this.apiManager.fetchData('getCaseReviewSearch', undefined, ApiManager.GET,
      undefined, undefined, undefined, queryParams).subscribe(
        res => {
          if (res.length > 0) {
            this.review(res[0]);
          }

        },
        error => {
          console.log('look up API call failure: Error');
        }
      );

  }

  review(rvwInfo) {
    this.constantsService.ReviewInfo = rvwInfo;
    if (this.constantsService.ReviewInfo) {
      this.refreshNav();
    }

  }

  refreshNav() {
    this.navigationService.getPrograms(this.navigationService.appCd).then(v => {
      this.afterNavChange();
    });
  }

  afterNavChange() {
    // Do Nothing
    // TODO : if any change after nav change while adding new program
    let initiatePage;
    if (this.constantsService.ReviewInfo.appType === 'BPE-FSME') {
      initiatePage = '/bpe/fsme/case-review-list';
    } else if (this.constantsService.ReviewInfo.appType === 'BPE-PA') {
      initiatePage = '/bpe/payment-accuracy/case-review-list';
    } else if (this.constantsService.ReviewInfo.appType === 'BPE-WPR') {
      initiatePage = '/bpe/wpr/case-review-list';
    } else if (this.constantsService.ReviewInfo.appType === 'BPE-MONITOR') {
      initiatePage = '/bpe/fsme/case-review-list';
    } else {
      initiatePage = '/qualityControl/analystCaseSummary';
    }


    this.navigationService.navigatePage(initiatePage, 'next');
  }

  openConfirmationDialog(caseInfo) {

    const queryParams: any[] = [];
    const sendObj: any = {
      "workItemId": caseInfo.workItemId,
      "workItemStatusCd": 'CL'
    }
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: false
    });
    dialogRef.componentInstance.confirmMessage = "Are you sure you want to accept the case?"

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const index = this.result.findIndex(element => element.reviewNum === caseInfo.reviewNum);
        this.result[index].workItemStatusCd = 'CL';
        this.dataSource = new MatTableDataSource<any>(this.result);
        queryParams.push(caseInfo.workItemId);
        queryParams.push('CL');
        this.apiManager.fetchData('saveWorkItemStatus', undefined, ApiManager.POST,
          sendObj, undefined, undefined, queryParams).subscribe(
            res => {
              if (res.length > 0) {
                console.log("Successfully saved the work item status");
              }

            },
            error => {
              console.log(error);
            }
          );
        this.constantsService.alertCount = this.constantsService.alertCount - 1;
      }
    });
  }

  update(caseInfo, deleteInd) {

    const queryParams: any[] = [];
    const sendObj: any = {
      "workItemId": caseInfo.workItemId,
      "deleteInd": deleteInd
    }
    this.dataSource = new MatTableDataSource<any>(this.result);
    queryParams.push(caseInfo.workItemId);
    queryParams.push(deleteInd);
    this.apiManager.fetchData('deleteWorkItem', undefined, ApiManager.POST,
      sendObj, undefined, undefined, queryParams).subscribe(
        res => {
          console.log("Successfully updated the work item");
          this.getWI(undefined, undefined, false, true);
        },
        error => {
          console.log(error);
        }
      );
  }

}
export interface DOOffice {
  ldssDoCd: string;
  ldssDoName: string;
}