import { Injectable } from '@angular/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import {NavClass} from "../model/api/nav.class";
import * as _ from 'underscore';
import {Router} from "@angular/router";

import {BehaviorSubject, Observable} from "rxjs";
import {ApiManager} from "./api-manager.service";
import { DataShareService } from './data-share.service';
import { ConstantsService } from './constants.service';
import { AnyKindOfDictionary } from 'lodash';

@Injectable()
export class NavigationService {
  private navItems:NavClass[] =[];
  private navHead: NavClass[] = [];
  private navChildren: NavClass[];
  public currentNav: NavClass[];
  public nav: NavClass[] = [];
  public selectedPgms:string[] = [];
  private showSubmit: boolean = false;
  public appCd:string = '';
  constructor(
              private storage: LocalStorageService,
              private session: SessionStorageService,
              private router: Router,
              private apiManager: ApiManager,
              private shareService: DataShareService,
              public constantsService: ConstantsService) {

  }
  private hideValue:BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  getHideValue(): Observable<boolean> {
    return this.hideValue.asObservable();
  }

  setHideValue(value: boolean): void {
    console.log(value,"service");    
    this.hideValue.next(value);
  }

  private extractData(res) {
    let body =res;
    return body || {};
  }
  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  setNavItems(items){
    this.navItems = items;
    this.storage.store('allNavItems', items);
  }

  getNavItems(){
   return this.storage.retrieve('allNavItems')
  }

  setNavHead(items){
    this.navHead = items;
    this.storage.store('navHead', items);
  }

  getShowSubmit():boolean{
    return this.showSubmit;
  }

  getNavHead(){
    return this.storage.retrieve('navHead')
  }

  setSelectedNavHead(id) {
    this.storage.store('navHeadSelected', id);
  }

  getSelectedNavHead() {
    return this.storage.retrieve('navHeadSelected');
  }

  navigatePage(url, dir?: string): Observable<any> | Promise<any> | any {
    this.currentNav = this.getCurrentNav(url);
    const navUrlWithParam = this.getPage(url, dir);

    const navUrl = navUrlWithParam.split('?')[0];
    let navUrlParam: any ;
    if (navUrlWithParam.split('?').length > 1) {
      const navUrlQryStr = navUrlWithParam.split('?')[1];
      if ( navUrlQryStr.split('=').length > 1  ) {
        navUrlParam = { queryParams: { type: navUrlQryStr.split('=')[1] } } ;
      }
    }

    this.setSelectedNavHead(this.currentNav[0].prnt_desc);
    this.router.navigate([navUrl], navUrlParam);

  }

  getPage(url, dir: string) {
    this.currentNav = this.getCurrentNav(url);
    
    this.navChildren = this.giveChildren(this.currentNav[0].prnt_desc);
    let minMax = this.givMinMax(this.navChildren);
    let parentMinMax;
    let currentParentNum;
    let aParentCd;
    let sendUrl;
    let currPage:NavClass[];
    let navHead = this.getNavHead();
    let navItems = this.getNavItems();
    if(dir == 'next'){
      if(this.currentNav[0].sort_order_nbr < minMax.max){
        let nav;
        for(let u = this.currentNav[0].sort_order_nbr + 1; u <= minMax.max; u++) {
          nav = this.getCurrentNavSO(this.currentNav[0].prnt_desc, u);
          if (nav && nav.length == 1) {
            this.currentNav = nav;
            sendUrl = this.currentNav[0].screen_defn;
            break;
          }
        }
      }
      else{
        parentMinMax = this.givMinMax(navHead);
        currentParentNum = navHead.filter(v =>{
          if(v.prnt_desc == this.currentNav[0].prnt_desc){
            return v;
          }
        })[0].sort_order_nbr;
        if(currentParentNum < parentMinMax.max ){
          aParentCd = navHead.filter((v,index) =>{
            if(v.sort_order_nbr > currentParentNum){
              return v;
            }
          })[0].prnt_desc;
          this.navChildren = this.giveChildren(aParentCd);
          minMax = this.givMinMax(this.navChildren);
          currPage = this.getCurrentNavSO(aParentCd, minMax.min);
          this.currentNav = currPage;
          if(this.currentNav[0].sort_order_nbr == minMax.max && currentParentNum + 1 == parentMinMax.max){
          this.showSubmit = true;
          }
          sendUrl = currPage[0].screen_defn;
        }
      }
    }
    else if(dir == 'back'){

      if(this.currentNav[0].sort_order_nbr > minMax.min){
        let nav;
        for(let u = this.currentNav[0].sort_order_nbr -1; u >= minMax.min; u--) {
          nav = this.getCurrentNavSO(this.currentNav[0].prnt_desc, u);
          if (nav && nav.length == 1) {
            this.currentNav = nav;
            sendUrl = this.currentNav[0].screen_defn;
            break;
          }
        }
        this.showSubmit = false;
        sendUrl = this.currentNav[0].screen_defn;
      }
      else{
        parentMinMax = this.givMinMax(navHead);
        currentParentNum = navHead.filter(v =>{
          if(v.prnt_desc == this.currentNav[0].prnt_desc){
            return v;
          }
        })[0].sort_order_nbr;
        if(currentParentNum > parentMinMax.min){
          aParentCd = navHead.filter(v =>{
            if(v.sort_order_nbr == currentParentNum - 1){
              return v;
            }
          })[0].prnt_desc;
          this.navChildren = this.giveChildren(aParentCd);
          minMax = this.givMinMax(this.navChildren);
          currPage = this.getCurrentNavSO(aParentCd, minMax.max);
          this.currentNav = currPage;
          this.showSubmit = false;
          sendUrl = currPage[0].screen_defn;
        }
      }
    }else{
      this.showSubmit = false;
        sendUrl = this.currentNav[0].screen_defn;
    }
    return sendUrl;

  }

  getCurrentNavSO(parentCd, num){
    return this.getNavItems().filter(v =>{
      if(v.prnt_desc == parentCd && v.sort_order_nbr == num && v.is_parent == false){
        return v;
      }
    });
  }

  getParentCode(url):string{
    let code: string = '';
    this.getNavItems().forEach(v =>{
      if(v.screen_defn == url){
        code =  v.prnt_desc;
      }
    });
    return code;
  }

  checkForSubmit(url){
    let navHead = this.getNavHead();
    let parentCd = this.getParentCode(url);
    this.navHead.forEach(v =>{
      if(v.prnt_desc == parentCd){
        this.showSubmit = (v.sort_order_nbr == navHead.length);
      }
    })
  }

  getCurrentNav(url):NavClass[]{
    return this.getNavItems().filter(v =>{
      if(v.screen_defn === url){
        return v;
      }
    });
  }

  giveChildren (parent_cd: string): NavClass[] {
    let sendChild: NavClass[];
    sendChild = _.filter(this.getNavItems(), (val) => {
      return (val.is_parent === false && val.prnt_desc === parent_cd);
    });
    return sendChild;

  }
  givMinMax(obj: NavClass[]){
    let returnObj = {
      min: 1,
      max: 1
    };
    if(obj.length == 1){
      returnObj.min = obj[0].sort_order_nbr;
      returnObj.max = obj[0].sort_order_nbr;
    }
    else if(obj[0].sort_order_nbr > obj[1].sort_order_nbr){
        returnObj.min = obj[1].sort_order_nbr;
        returnObj.max = obj[0].sort_order_nbr;
    }
    else if(obj[0].sort_order_nbr < obj[1].sort_order_nbr){
        returnObj.min = obj[0].sort_order_nbr;
        returnObj.max = obj[1].sort_order_nbr;
    }
    for(let i = 0; i<obj.length; i++){
        if(obj[i].sort_order_nbr > returnObj.max){
          returnObj.max = obj[i].sort_order_nbr;
        }
        if(returnObj.min  > obj[i].sort_order_nbr){
          returnObj.min = obj[i].sort_order_nbr;
        }
    }

    return returnObj;

  }

  getScreenId(currentUrl?): number {
    this.nav = this.getNavItems();
    let screenId: number = 0;
    const url: string = currentUrl?currentUrl:this.router.url;
    this.nav.forEach(v => {
      if (v.screen_defn == url) {
        screenId = v.screen_id;
      }
    });

    return screenId;
  }

  getScreenNameById(scrnId?) {
    this.nav = this.getNavItems();
    let screenTxt;
    
    this.nav.forEach(v => {
      if (v.typeCd && v.typeCd===scrnId) {
        screenTxt = v.typeCd + '-'+ v.tag_txt;
      }
    });

    return screenTxt;
  }

  getPrograms(appCd?): Observable<any> | Promise<any> | any{
    let id: string[] = [];
    
    return this.navDBCall(appCd).then(val => {
        this.setNavHead(val.filter(v => {return v.is_parent == true;}));
        this.setNavItems(val);
    }).catch(err => {
        console.log(err);
    });
      

      
}



  getNavs(appCd?): Observable<any> {
    return Observable.create(observer => {
        this.navDBCall(appCd).then(val => {
          this.navItems = val;
          this.navHead = _.filter(this.navItems, (val) => {
            return val.is_parent == true;
          });
          this.setNavHead(this.navHead);
          this.setNavItems(this.navItems);
          observer.next(val);
          observer.complete()
        }).catch(err =>{
          observer.next(err);
          observer.complete();
        })
    });
  }


  enableAll(){
    let nav = this.getNavItems();
    this.setNavItems([]);
    this.setNavHead([]);
    nav.forEach((v,i)=>{
        nav[i].disabled = 'N';
    });
    this.setNavItems(nav);
    this.navHead = _.filter(this.getNavItems(), (val) => {
      return val.is_parent == true;
    });
    this.setNavHead(this.navHead)
  }

  navDBCall(appCd?) {
    const queryParams: any[] = [];
    this.selectedPgms = [];

    if (this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwType) {
        if (this.constantsService.ReviewInfo.rvwType === 'OTO' ||
        this.constantsService.ReviewInfo.rvwType === 'ESAPPositive' ) {
          this.selectedPgms.push('FSPPositive');
        } else if (this.constantsService.ReviewInfo.rvwType === 'ESAPNegative' ) {
          this.selectedPgms.push('FSPNegative');
        } else {
          this.selectedPgms.push(this.constantsService.ReviewInfo.rvwType);
        }
        
    }

    queryParams.push(0);
    const sendObj = {
      localeCode: 'en_us',
      appType: appCd  ,
      pgmType:  this.selectedPgms && this.selectedPgms.length > 0 ?
      (appCd  === 'QC' ? this.selectedPgms  : null) :
      (appCd  === 'QC' ? ['FSPPositive'] : null),
      uId: (this.constantsService.getSecurityObject().user && this.constantsService.getSecurityObject().user.uniqueId ?
      this.constantsService.getSecurityObject().user.uniqueId : null),
      rvwNum: this.constantsService.ReviewInfo? this.constantsService.ReviewInfo.rvwNum : null
    };
    // return this.apiManager.fetchData('workerNav', undefined, ApiManager.POST, sendObj)
    //   .toPromise().then(this.extractData).catch(this.handleError);
      return this.apiManager.fetchData('workerGetCaseNavs', undefined, ApiManager.POST, sendObj, undefined, undefined, queryParams)
      .toPromise().then(this.extractData).catch(this.handleError);
  }

  saveOptionalNavs(caseID, selNavs: any): Observable<any> | Promise<any> | any {
    const queryParams: any[] = [];
    queryParams.push(caseID);
    const sendObj = {
      appType: 'ED',
      selectedNavs: selNavs
    };
    return this.apiManager.fetchData('workerSaveNavs', undefined, ApiManager.POST, sendObj, undefined, undefined, queryParams);
      // .toPromise().then(this.extractData).catch(this.handleError);
    }

    saveOptionalCurrentNavs(caseID, selNavs: String): Observable<any> | Promise<any> | any {
      const queryParams: any[] = [];
      queryParams.push(caseID);
      queryParams.push(selNavs);

      return this.apiManager.fetchData('workerSaveNavs', undefined, ApiManager.POST, undefined, undefined, undefined, queryParams);
        // .toPromise().then(this.extractData).catch(this.handleError);
      }


  getCaseNavs(caseID) : Observable<any> | Promise<any> | any{
    const queryParams: any[] = [];
    queryParams.push(caseID);
    const sendObj = {
      localeCode: 'en_us',
      appType: 'ED' ,
      pgmType: this.selectedPgms && this.selectedPgms.length > 0 ? this.selectedPgms : ['FS']
    };
    return this.apiManager.fetchData('workerGetCaseNavs', undefined, ApiManager.POST, sendObj, undefined, undefined, queryParams);
    // return ;
    // .subscribe(res => {
    //   return res;
    // });
      }
  

}
