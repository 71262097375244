import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Highcharts from 'highcharts';
import { HttpClient } from "@angular/common/http";
import { ApiManager } from 'src/app/common/api-manager.service';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ViewChild } from "@angular/core";
import { ConstantsService } from 'src/app/common/constants.service';

@Component({
    selector: 'app-bpe-dashboard',
    templateUrl: './bpe-dashboard.component.html',
    styleUrls: ['./bpe-dashboard.component.css']
})

export class BpeDashboardComponent implements OnInit {

    chart;
    updateFlag = false;
    Highcharts = Highcharts;
    chartConstructor = "chart";
    chartCallback;
    chartOptions;
    labelmap: Map<string, string> = new Map<string, string>();
    map: Map<any, any> = new Map();
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    keys: Set<string> = new Set();
    sliced = {};
    data: any;
    isNotEditable: boolean = false;
    quarterNum;
    today = new Date();
    constructor(
        private readonly router: Router,
        private apiManager: ApiManager,
        public constantsService: ConstantsService
    ) {
        this.populateData();
        const self = this;
        this.chartCallback = chart => {
            // saving chart reference
            self.chart = chart;
        };
    }

    slicedData;
    temp = [];
    dataSource = new MatTableDataSource();
    displayedColumns = ["source", "case", "assigned", "unassigned"];

    populateData() {
        const queryParams: any[] = [];
        queryParams.push('BPE-FSME');
        var rvwMo =  new Date().getFullYear().toString()+ '0' + (new Date().getMonth() + 1).toString().slice(-2);

        queryParams.push(rvwMo);
        this.apiManager.fetchData('getTotalCasesReviewedCases', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
            .subscribe(
                res => {
                    if (res) {
                        this.data = res;
                        this.dataSource.data = [];
                        this.drawFSMEGraph(this.data);
                        this.drawPAGraph(this.data.totalCasesPerCounty);
                        this.drawWPRGraph();
                    }
                });
    }

    ngOnInit() {

        let month = new Date().getMonth()+1;
        if(month>=10 && month <=12) {
            this.quarterNum = 'Q1';
        } else 
        if(month>=1 && month <=3){
            this.quarterNum = 'Q2';
        } else 
        if(month>=4 && month <=6){
            this.quarterNum = 'Q3';
        } else 
        if(month>=7 && month <=9){
            this.quarterNum = 'Q4';
        }
        var chart = Highcharts.chart('container2', {
            chart: {
                type: 'column'
            },
            title: {
                text: '% Achieved for Application & Redets'
            },
            // subtitle: {
            //     text: 'Resize the frame or click buttons to change appearance'
            // },
            legend: {
                verticalAlign: 'bottom',
                layout: 'vertical'
            },
            xAxis: {
                categories: ['Baltimore', 'Carrol', 'calvert', 'Corchester', 'Kent', 'Saint Marys', 'Somerset', 'Anne Arundel', 'Prince George', 'Howard', 'Worcester', 'Queen Annes', 'Montgomery', 'Wicomico', 'calvery'],
                labels: {
                    x: -10
                }
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: 'Count'
                }
            },
            series: [{
                name: '% Achieved for application Pre-reviews',
                data: [1, 4, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
                type: undefined
            }, {
                name: '% Achieved for redetermination Pre-reviews',
                data: [6, 4, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
                type: undefined
            },
            ],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal'
                        },
                        yAxis: {
                            labels: {
                                align: 'left',
                                x: 0,
                                y: -5
                            },
                            title: {
                                text: null
                            }
                        },
                        subtitle: {
                            text: null
                        },
                        credits: {
                            enabled: false
                        }
                    }
                }]
            }
        });
        console.log(this.temp);
    }

    determineAccess() {
        const ref = this.constantsService.getSecurityObject();

        if ((ref.accessMap[''] != null && ref.accessMap[''].accessMode != 'rw')) {
            this.isNotEditable = true;
        }
    }

    
    drawPAGraph(pagraphdata: any) {
        this.chartOptions = {
            colors: ['#7cb5ec', '#f7a35c', '#90ee7e', '#7798BF', '#aaeeee', '#ff0066',
                '#eeaaee', '#55BF3B', '#DF5353', '#7798BF', '#aaeeee'],
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: "pie"
            },
            title: {
                text: "Samples for "+this.quarterNum
            },
            tooltip: {
                pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
            },

            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: "pointer",
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true
                }
            },
            series: [
                {
                    type: "pie",
                    data: pagraphdata,
                }
            ]
        };
    }

    drawFSMEGraph(fsmedata: any) {
        var chart = Highcharts.chart('container', {
            chart: {
                type: 'column'
            },
            title: {
                text: 'Total count per sampling type'
            },
            legend: {
                align: 'right',
                verticalAlign: 'middle',
                layout: 'vertical'
            },
            xAxis: {
                categories: fsmedata.appType,
                labels: {
                    x: -10
                }
            },
            yAxis: {
                allowDecimals: false,
                title: {
                    text: 'Count'
                }
            },
            series: [{
                name: 'Total Samples',
                data: fsmedata.totalSamples,
                type: undefined
            }, {
                name: 'To be Reviewed',
                data: fsmedata.toBeReviewed,
                type: undefined
            },
            ],

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            align: 'center',
                            verticalAlign: 'bottom',
                            layout: 'horizontal'
                        },
                        yAxis: {
                            labels: {
                                align: 'left',
                                x: 0,
                                y: -5
                            },
                            title: {
                                text: null
                            }
                        },
                        subtitle: {
                            text: null
                        },
                        credits: {
                            enabled: false
                        }
                    }
                }]
            }
        });
    }

    drawWPRGraph() {
        Highcharts.chart('wpr', {
            chart: {
                type: 'line'
            },
            title: {
                text: 'Year-to-Date:Verified sample cases Vs LDSS Self-reported'
            },
            // subtitle: {
            //     text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
            // },
            xAxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
                title: {
                    text: null
                }
            },
            yAxis: {
                min: 0,
                title: {
                    text: 'In %',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                }
            },
            tooltip: {
                valueSuffix: 'millions'
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true
                    }
                }
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'top',
                x: -40,
                y: 80,
                floating: true,
                borderWidth: 1,
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
                shadow: true
            },
            credits: {
                enabled: false
            },
            series: [{
                name: 'Federal Samples',
                data: [90, 45, 65, 38, 68, 42],
                type: undefined
            }, {
                name: 'Universal WPR(LDSS Self-reported)',
                data: [60, 48, 63, 56, 68, 43],
                type: undefined
            }
            ]
        });
    }

    goFSME() {
        this.router.navigate(['/bpe/fsme/fsme-dashboard']);
    }

    goTPA() {
        this.router.navigate(['/bpe/payment-accuracy/pa-dashboard']);
    }

    goWPR() {
        this.router.navigate(['/bpe/wpr/wpr-dashboard']);
    }

    goMonitoring() {
        this.router.navigate(['/bpe/monitoring/monitoring-dashboard']);
    }

    goReports() {
        this.router.navigate(['/bpe/fsme/fsme-dashboard']);
    }
}