import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-qc199-non-cooperation-form',
  templateUrl: './qc199-non-cooperation-form.component.html',
  styleUrls: ['./qc199-non-cooperation-form.component.css']
})
export class QC199NonCooperationFormComponent extends BaseComponent implements OnInit {

    pleaseContactCheclBox = false;

    analystName;
    analystPhone;
    
  
  ngOnInit() {
    super.ngOnInit();

    if(this.getControlValue('500117.10') == 'false' || this.getControlValue('500117.10') == null) {
    this.analystName = this.getControlValue('500117.11');
    this.analystPhone = this.getControlValue('500117.12');

    this.setControlValue('500117.11','');
    this.setControlValue('500117.12','');
    this.setControlValue('500117.20','');
    }
  }

  handlePleaseContact(){

    if(this.getControlValue('500117.10') == true) {
    
      this.setControlValue('500117.11',this.analystName);
      this.setControlValue('500117.12',this.analystPhone);
      this.setControlValue('500117.20',this.analystName);
    } 
    else {

      this.setControlValue('500117.11','');
      this.setControlValue('500117.12','');
      this.setControlValue('500117.20','');
    }
      
  }

  convertSpanishPDF() {
    this.callGenerateSpanishPDF.emit(undefined);
  }

}
