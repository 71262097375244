import { Component, OnInit } from '@angular/core';
import { ConstantsService } from '../../common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-employ-status-se-ei-deduction',
  templateUrl: './employ-status-se-ei-deduction.component.html',
  styleUrls: ['./employ-status-se-ei-deduction.component.css']
})
export class EmployStatusSeEiDeductionComponent extends BaseComponent implements OnInit {
isNotEditable: boolean =false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }


}
