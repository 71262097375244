import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-disabled-and-elderly',
  templateUrl: './disabled-and-elderly.component.html',
  styleUrls: ['./disabled-and-elderly.component.css']
})
export class DisabledAndElderlyComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  get Isda_1a(): boolean {
    return this.group.controls['DA.1a'].value === 'N';
  }
  get Isda_1b(): boolean {
    return this.group.controls['DA.1b'].value === 'N';
  }
  get Isda_1c(): boolean {
    return this.group.controls['DA.1c'].value === 'N';
  }
  get Isda_1d(): boolean {
    return this.group.controls['DA.1d'].value === 'N';
  }
  get Isda_1e(): boolean {
    return this.group.controls['DA.1e'].value === 'N';
  }
  get Isda_1f(): boolean {
    return this.group.controls['DA.1f'].value === 'N';
  }
  get Isda_2a(): boolean {
    return this.group.controls['DA.2a'].value === 'N';
  }
  get Isda_2b(): boolean {
    return this.group.controls['DA.2b'].value === 'N';
  }
  get Isda_2c(): boolean {
    return this.group.controls['DA.2c'].value === 'N';
  }
  get Isda_2d(): boolean {
    return this.group.controls['DA.2d'].value === 'N';
  }
  get Isda_2e(): boolean {
    return this.group.controls['DA.2e'].value === 'N';
  }
  get Isda_2f(): boolean {
    return this.group.controls['DA.2f'].value === 'N';
  }


}