import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-contribution-child-care',
  templateUrl: './contribution-child-care.component.html',
  styleUrls: ['./contribution-child-care.component.css']
})
export class ContributionChildCareComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;
    ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

}

