import { Component, OnInit ,ViewChild} from '@angular/core';
// import { AppService } from '../app.service';
// import { ParallelHasher } from 'ts-md5/dist/parallel_hasher';
// import { environment } from '../../environments/environment';
declare var Dynamsoft: any;
declare var EnumDWT_ImageType: any;
import {FormBuilder, FormGroup, NgForm} from '@angular/forms';
import {AppUtilService} from "../../common/app.util.service";
import {ConstantsService} from "../../common/constants.service";
import {EnumDWT_InterpolationMethod} from "./file-scan.component";
import { LocationStrategy } from '@angular/common';
import {LookupManagerService} from "../../common/lookup-manager.service";
import { Router } from '@angular/router';
// import {HouseholdMember} from "../../model/api/household-member.class";
// import { FileSearchComponent } from '../file-search/file-search.component';
// import { EcmsDocument } from '../file-search/ecm-document';
// import { CRDRequest } from '../file-search/crd-request';

@Component({
  selector: 'app-file2-scan',
  templateUrl: './file-scan.component.html',
  styleUrls: ['./file-scan.component.css']
})

export class FileScan2Component implements OnInit {
  dwObject: any;
  uploading: boolean = false;
  fileName: string;
  img_width: string;
  data: any;
//   members: HouseholdMember[] = [];
  isFileScanned:boolean;
  docNames: any;
  docInfo: FormGroup;
  img_height: string;
  interpolationDD: string;
  selectedInterpolation: number;
  subtype = null;
  lkptype = null;
  subtypeSearch = null;
  subTypeSearchCd = null;
  lkpshortval = null;
  showDialog: boolean;
  searchDocEvent = null;
  lookupValues: any[]
  DW_TotalImage: any = 0;
  globalCategoryObj = {};
  DW_CurrentImage: any = 0;
  myData: any;
  fileExistsMsg: any;
  isUploading: boolean = false;
  disableScanBtn:boolean = false;
  isNotEditable: boolean = false;
  // @ViewChild(FileSearchComponent) pageRefresh: FileSearchComponent;
  docOptions = [
    "Agreement",
    "Application",
    "Assets",
    "Clearances",
    "Correspondence",
    "DisabilityBenefitsAdvocacy",
    'Disqualifications',
    "Expenses",
    "Fiscal",
    "Identity",
    "Income",
    "Legal",
    "LTCForms",
    "MCPSpecialPrograms",
    "Medical",
    "QualityControl",
    "Redeterminations",
    "Referrals",
    "Verifications",
    "WorkProgram"
  ];
  docOptions2 = [
    "Agreement",
    "Application",
    "Assets",
    "Clearances",
    "Correspondence",
    "DisabilityBenefitsAdvocacy",
    'Disqualifications',
    "Expense",
    "Fiscal",
    "Identity",
    "Income",
    "Legal",
    "LTCForms",
    "MCPSpecialPrograms",
    "Medical",
    "QualityControl",
    "Redeterminations",
    "Referrals",
    "Verifications",
    "WorkProgram"
  ];
  selectedScreen: string;
  constructor(public appUtilService: AppUtilService,
              public constantsService: ConstantsService,
              public _platformStrategy: LocationStrategy,
              public fb: FormBuilder,
              private router: Router,
              public lookups: LookupManagerService) {
    this.buildForm();
    this.docNames=[];
    let routeState = this.router.getCurrentNavigation().extras.state;
    this.data = {fnsNames: routeState ? routeState.fnsNames : false, reviewNum: routeState ? routeState.reviewNumber : ""}
  }
  setSelectedScreen(val) {
    this.selectedScreen = val;
  }

  changeType(event, docEvent, search?) {
    let value = this.lookups.getLookupDesc('Documents', docEvent);
    docEvent = value;
    console.log(event);
    console.log('docEvent===>', docEvent);
    this.lkptype = docEvent;

    if (search == 'search') {
      this.subtypeSearch = this.globalCategoryObj[docEvent];
      console.log('this.subtypeSearch==>', this.subtypeSearch);
      console.log('this.globalCategoryObj==>', this.globalCategoryObj);
      this.searchDocEvent = docEvent;
      this.subTypeSearchCd = '';
      this.lkptype = docEvent;
    }
    else {
      this.lkptype = docEvent;
      console.log('this.globalCategoryObj==>', this.globalCategoryObj)
      let docEvents = docEvent.split(' ');
      let subCategory = '';
      docEvents.map(event => subCategory += event);
      console.log(subCategory);
      this.subtype = this.globalCategoryObj[subCategory];
      // this.docInfo.get('docSubtype').setValue(this.subtype[0].lookupBean.code);
      //this.docInfo.get('docSubtype').setValue("");
    }
  }

  changeSubType(event) {
    let varNum = (<HTMLSelectElement>document.getElementById("docSubtypeId")).selectedIndex;
    // this.lkptype = this.subtype[varNum - 1].lookupBean.type;
    // this.lkpshortval = this.subtype[varNum - 1].lookupBean.shortLabel;
    let getSubTypeList = this.subtype.map(subType => subType.lookupBean);
    let getSelectedSubType = getSubTypeList.filter(subType => subType.code === event.value);
    this.lkptype = getSelectedSubType.length > 0 ? getSelectedSubType[0].type : null;
    this.lkpshortval = getSelectedSubType.length > 0 ? getSelectedSubType[0].shortLabel : null;
  }

  selectInterpolcationChangeHandler(event: any) {
    this.selectedInterpolation = event.target.value;
  }

  btnChangeImageSize_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    if (this.dwObject !== undefined) {
      this.showDialog = true;
      this.img_width = this.dwObject.GetImageWidth(this.dwObject.CurrentImageIndexInBuffer) + "";
      this.img_height = this.dwObject.GetImageHeight(this.dwObject.CurrentImageIndexInBuffer) + "";
    }
    else {
      alert("Please scan a document");
      this.showDialog = false;
      return;
    }
  }

   /*----------------Crop Image--------------------*/
   btnCrop_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    console.log("crop called");
    // if (this._iLeft != 0 || this._iTop != 0 || this._iRight != 0 || this._iBottom != 0) {
    //   console.log("crop2");
    //   this.dwObject.Crop(
    //     this.dwObject.CurrentImageIndexInBuffer,
    //     this._iLeft, this._iTop, this._iRight, this._iBottom
    //   );
    //   this._iLeft = 0;
    //   this._iTop = 0;
    //   this._iRight = 0;
    //   this._iBottom = 0;
    //   return;
    // }
    // else {
    //   alert("Please select the area you'd like to crop");
    // }
  }

  btnFirstImage_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.CurrentImageIndexInBuffer = 0;
    this.updatePageInfo();
  }

  btnLastImage_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    let k: number = this.dwObject.HowManyImagesInBuffer - 1;
    this.dwObject.CurrentImageIndexInBuffer = k;
    this.updatePageInfo();
  }

  btnPreImage_onclick() {
    this.dwObject.CurrentImageIndexInBuffer = this.dwObject.CurrentImageIndexInBuffer - 1;
    this.updatePageInfo();
  }

  btnChangeImageSizeOK_onclick(changeImageSizeForm: NgForm) {
    this.dwObject.ChangeImageSize(this.dwObject.CurrentImageIndexInBuffer, parseInt(this.img_width), parseInt(this.img_height), this.selectedInterpolation);
    this.showDialog = false;
  }

  back() {

      this._platformStrategy.back();

  }

  btnNextImage_onclick() {
    let i: number;
    let j: number;
    if (this.dwObject !== undefined) {
      i = this.dwObject.HowManyImagesInBuffer;
      j = this.dwObject.CurrentImageIndexInBuffer;
    }

    if (!this.checkIfImagesInBuffer()) {
      return;
    }/* else if (this.dwObject.CurrentImageIndexInBuffer == i - 1) {
      console.log("nNextImage curr1::"+this.dwObject.CurrentImageIndexInBuffer);
      return;
    }*/
    this.dwObject.CurrentImageIndexInBuffer = j + 1;
    this.updatePageInfo();
  }

  ngOnInit() {
    // this.members = ConstantsService.householdDetails;
    // this.dwObject = Dynamsoft.WebTwainEnv.GetWebTwain('dwtcontrolContainer');
   //this.InitBtnGroupBtm(false);
   console.log("qqq1::"+this.dwObject);
    // this.appService.listFiles().subscribe(response => {
    //   this.myData = response.json();
    //   for(var list=0; list < this.myData.length; list++) {
    //     if(this.myData[list].documentName !== undefined) {
    //       this.docNames.push(this.myData[list].documentName);
    //     }
    //   }
    // }, err=> {
    //   alert("search failed");
    // })
  }
  buildForm() {
    this.docInfo = this.fb.group({
      docType: [this.docOptions2[0]],
      docSubtype: [null],
      hhMember: [null]
    })
  }

  interpolationKeys(): Array<string> {
    return Object.keys(EnumDWT_InterpolationMethod).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }

  acquireImage1(): void {
    this.dwObject = Dynamsoft.WebTwainEnv.GetWebTwain('dwtcontrolContainer');
    console.log("this.dwobj::"+this.dwObject);
    const bSelected = this.dwObject.SelectSource();
    if (bSelected) {
      const onAcquireImageSuccess = () => { this.dwObject.CloseSource(); };
      const onAcquireImageFailure = onAcquireImageSuccess;
      this.dwObject.OpenSource();
      this.dwObject.AcquireImage({}, onAcquireImageSuccess, onAcquireImageFailure);
    }
  }

  onSubmit(scanFileForm: NgForm) {
    if (scanFileForm.valid) {
      console.log(scanFileForm.controls.fileName.value);
      this.fileName = scanFileForm.controls.fileName.value;
      console.log("fileName::"   + this.fileName);
      this.upLoad();
      // ...our form is valid, we can submit the data
    }
  }

  title = 'Using Dynamic Web TWAIN in Angular Project';

  acquireImage(): void {
   // this.dwObject = Dynamsoft.WebTwainEnv.GetWebTwain('dwtcontrolContainer');
   // console.log("qqq::"+this.dwObject);
    const bSelected = this.dwObject.SelectSource();

      if (bSelected) {
        const onAcquireImageSuccess = () => {
          this.isFileScanned = true;
              this.dwObject.ConvertToBlob([0], EnumDWT_ImageType.IT_PDF, (res) => {
                console.log('****' + res.size);

              // this.dwObject.CloseSource();
            }, (num, err) => {
                console.log('error is ' + err);
            });
            this.disableScanBtn = true;
        };
        const onAcquireImageFailure = onAcquireImageSuccess;
        this.dwObject.OpenSource();
        this.dwObject.AcquireImage({}, onAcquireImageSuccess, onAcquireImageFailure);
      }
  }

  //   private prepareSave(file, ecmsDocument: EcmsDocument, requestInfo: CRDRequest): any {
  //   let input = new FormData();
  //   let hasher = new ParallelHasher(environment.mdhashUrl);

  //   hasher.hash(file).then(function (result) {
  //     file.mdhash = result;
  //     ecmsDocument.filemdhash = result;
  //     console.log("ecmsDocument.filemdhash::"+ecmsDocument.filemdhash);
  //   //  input.append('uploadFile', file);
  //     //input.append('uploadRequest', '{"filemdhash":"' + result + '","originatingSystem":"ECMS","agencyCode":"DHS","ownerSystem":"ECMS","documentName":"' + file.name + '","documentDescr":"Uploaded through ecms ui","documentType":"SSN","category":"Client Documents","clientId":"123456","caseId":"23","piiInd":false,"uploadedBy":"' + this.appService.loggedInUserId + '","deletedInd":false,"tags":[  {  "code":"verification_cd","value":"PEND"}]}');
  //     let formData = new FormData();
  //     formData.append('uploadFile', file);
  //     formData.append("ecmsDocumentInfo", this.createJsonBlob(ecmsDocument));
  //     formData.append('requestInfo', this.createJsonBlob(requestInfo));
  //     const formModel =formData;
  //     var isFileExist = this.docNames.includes(file.name);
  //     console.log("isFileExist bef::"+isFileExist);

  //       if(isFileExist !== true) {
  //           this.appService.upload(formModel).subscribe(response => { console.log("isFileExist aft::"+isFileExist);

  //             console.log('Response json ' + response.json());
  //             this.isFileScanned = false;
  //             this.disableScanBtn = false;
  //             file.status = "success";
  //           },err => {
  //             if(err.statusCode == 422) {
  //               alert(err.errorMesssage);
  //             }
  //             else{
  //               file.status = "failed";
  //             }
  //           });
  //       } else {
  //         this.fileExistsMsg= file.name +" - This file name already exists, please enter another file name";
  //         file.status = "fileExists";
  //        alert(this.fileExistsMsg);
  //     }
  //     if(file.status == "success"){
  //       alert("File uploaded successfully");
  //     }
  //     this.dwObject.CloseSource();
  //     this.dwObject.RemoveAllImages(0);

  //   }.bind(this));
  //  // this.pageRefresh.refresh();
  //   return input;
  // }
  private createJsonBlob<T>(content: T) {
    return new Blob([JSON.stringify(content)], { type: "application/json" });
  }

  public blobToFile(theBlob: Blob, fileName: string): File {
    var b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return <File>b;
  }
  btnShowImageEditor_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.ShowImageEditor();
  }

  btnRotateLeft_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.RotateLeft(this.dwObject.CurrentImageIndexInBuffer);
  }

  btnRotateRight_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.RotateRight(this.dwObject.CurrentImageIndexInBuffer);
  }

  btnRotate180_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.Rotate(this.dwObject.CurrentImageIndexInBuffer, 180, true);
  }

  btnMirror_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.Mirror(this.dwObject.CurrentImageIndexInBuffer);
  }

  btnFlip_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.Flip(this.dwObject.CurrentImageIndexInBuffer);
  }

  btnRemoveCurrentImage_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.RemoveAllSelectedImages();
    if (this.dwObject.HowManyImagesInBuffer == 0) {
      if (document.getElementById("DW_TotalImage")) {
        (<HTMLInputElement>document.getElementById("DW_TotalImage")).value = this.dwObject.HowManyImagesInBuffer + "";
        this.isFileScanned = false;
      }
      if (document.getElementById("DW_CurrentImage")) {
        (<HTMLInputElement>document.getElementById("DW_CurrentImage")).value = 0 + "";
      }
      return;
    }
    else {
      this.updatePageInfo();
    }
  }

  updatePageInfo() {
    //console.log("updatePageInfo called" );
    if (document.getElementById("DW_TotalImage")) {
      (<HTMLInputElement>document.getElementById("DW_TotalImage")).value = this.dwObject.HowManyImagesInBuffer + "";
    }
    let currImgIndex: number = this.dwObject.CurrentImageIndexInBuffer + 1;
    if (document.getElementById("DW_CurrentImage")) {
      (<HTMLInputElement>document.getElementById("DW_CurrentImage")).value = currImgIndex + "";
    }
  }

  checkIfImagesInBuffer() {
    if (this.dwObject !== undefined) {
      if (this.dwObject.HowManyImagesInBuffer == 0) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  btnRemoveAllImages_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.RemoveAllImages();
    this.isFileScanned = false;
    if (document.getElementById("DW_TotalImage")) {
      (<HTMLInputElement>document.getElementById("DW_TotalImage")).value = 0 + "";
    }
    if (document.getElementById("DW_CurrentImage")) {
      (<HTMLInputElement>document.getElementById("DW_CurrentImage")).value = 0 + "";
    }
  }

  upLoad() {
      this.dwObject.ConvertToBlob([0], EnumDWT_ImageType.IT_PDF, (res) => {
      let file = this.blobToFile(res, this.fileName);
       ///
    // let ecmsDocument: EcmsDocument = {
    //   filemdhash: "",
    //   agencyCode: "DHS",
    //   originatingSystem: "ECMS",
    //   ownerSystem: "ECMS",
    //   documentName: file.name,
    //   documentDescr: "Uploaded through ecms ui",
    //   documentType: "SSN",
    //   documentCategory: "Client Documents",
    //   version: "2",
    //   clientId: "123456",
    //   caseId: "23",
    //   uploadedBy: this.appService.loggedInUserId,
    //   piiInd: false,
    //   deletedInd: false,
    //   tags: [{ code: "verification_cd", value: "PEND" }],
    //   //adding legacy fields
    //   itemTypeName: "ecm admin UI",
    //   logicalPlatterNum: "ecm admin UI",
    //   filePath: "ecm admin UI",
    //   clientFirstName: "ecm admin UI",
    //   clientLastName: "ecm admin UI",
    //   alienNumber: "ecm admin UI",
    //   caseManagerName: "ecm admin UI",
    //   familyViolence: "ecm admin UI",
    //   sailControlId: "ecm admin UI",
    //   administration: "ecm admin UI",
    //   jusrisdiction: "ecm admin UI",
    //   site: "ecm admin UI",
    //   scannerOperatorId: "ecm admin UI",
    //   scannerId: "ecm admin UI",
    //   source: "ecm admin UI",
    //   completedBy1: "ecm admin UI",
    //   completedBy2: "ecm admin UI",
    //   completedBy3: "ecm admin UI",
    //   restrictedAccess: "ecm admin UI",
    //   hdfsFilemovedtostage: "ecm admin UI",
    //   diskGroupName: "ecm admin UI",
    //   clidIrn: "ecm admin UI",
    //   itemNum: "ecm admin UI",
    //   headOfHouseHoldId: "ecm admin UI",
    //   clientssn: "ecm admin UI",
    //   compassNumber: "ecm admin UI",
    //   receivedDate: "ecm admin UI",
    //   dateOfBirth: "ecm admin UI",
    //   scannedDate: "ecm admin UI",
    //   retainUntil: "ecm admin UI"
    // }
    // let requestInfo: CRDRequest = {
    //   ownerSystem: "ECMS",
    //   originatingSystem: "ECMS",
    //   agencyCode: "DHS",
    // }

    ////
    //  const formModel = this.prepareSave(file);
      // const formModel = this.prepareSave(file, ecmsDocument, requestInfo);
  }, (num, err) => {
      console.log('error is ' + err);
    });
  }

  rotate_left() {
    this.dwObject.RotateLeft(0);
  }

  rotate_right() {
    this.dwObject.RotateRight(0);
  }

  flip() {
    this.dwObject.Flip(0);
  }

  showImageEditor() {
    this.dwObject.ShowImageEditor(0);
  }

  rotate180() {
    this.dwObject.Rotate(0, 180, true);
  }

  mirror() {
    this.dwObject.Mirror(0);
  }

  removeCurrentImage() {
    this.dwObject.RemoveCurrentImage(0);
  }

  removeAllImages() {
    this.dwObject.RemoveAllImages(0);
    this.isFileScanned = false;
    this.disableScanBtn = false;
  }

  changeImageSize() {
    this.dwObject.ChangeImageSize(0);
    /*
    let iWidth = this.dwObject.GetImageWidth(0);
    if (iWidth != -1)
    this.dwObject.GetImageWidth(this.dwObject.CurrentImageIndexInBuffer).iWidth = iWidth;

    let iHeight = this.dwObject.GetImageHeight( this.dwObject.CurrentImageIndexInBuffer);
    if (iHeight != -1)
    this.dwObject.GetImageHeight( this.dwObject.CurrentImageIndexInBuffer).value = iHeight;
    */
  }

  crop() {
    this.dwObject.Crop(0);
  }

  changeImgWidth(img_width){
    console.log(img_width.value);
  }

  InitBtnGroupBtm(bNeebBack) {
    var btnGroupBtm = document.getElementById("btnGroupBtm");
    if (btnGroupBtm) {
        let objString = "";
		objString += "<div class='ct-lt'>Page: ";
		objString += "<input id='DW_btnFirstImage' onclick='btnFirstImage_onclick()' type='button' value=' |&lt; '/>&nbsp;";
		objString += "<input id='DW_btnPreImage' onclick='btnPreImage_onclick()' type='button' value=' &lt; '/>&nbsp;&nbsp;";
		objString += "<input type='text' size='2' id='DW_CurrentImage' readonly='readonly'/> / ";
		objString += "<input type='text' size='2' id='DW_TotalImage' readonly='readonly'/>&nbsp;&nbsp;";
		objString += "<input id='DW_btnNextImage' onclick='btnNextImage_onclick()' type='button' value=' &gt; '/>&nbsp;";
		objString += "<input id='DW_btnLastImage' onclick='btnLastImage_onclick()' type='button' value=' &gt;| '/></div>";
		objString += "<div class='ct-rt'>Preview Mode: ";
		objString += "<select size='1' id='DW_PreviewMode' onchange ='setlPreviewMode();'>";
		objString += "    <option value='0'>1X1</option>";
		objString += "</select><br /></div>";
		if (bNeebBack) {
		    objString += "<div class='removeImage'><input id='DW_btnRemoveCurrentImage' onclick='btnRemoveCurrentImage_onclick()' type='button' value='Remove Selected Images'/>";
        objString += "<input id='DW_btnRemoveAllImages' onclick='btnRemoveAllImages_onclick()' type='button' value='Remove All Images'/></div>";
    }
		// btnGroupBtm.style.display = "";
        btnGroupBtm.innerHTML = objString;
		// Fill the init data for preview mode selection
    }
  }
}
