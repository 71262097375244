import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-variance',
  templateUrl: './variance.component.html',
  styleUrls: ['./variance.component.css']
})
export class VarianceComponent extends BaseComponent implements OnInit {
  isNotEditable: boolean =false;
  subElements: any = [];
  var_ind :boolean = false;
    ngOnInit() {
      this.subElements.push();
      this.subElements.push();
      this.subElements.push();
     
      super.ngOnInit();
      if( this.getControlValue('Var.3')){
        this.changeSubElementByElement('Var.4', this.getControlValue('Var.4'),0);
        }
  
   
      this.determineAccess();
      this.varianceInd();
    }
  
    changeSubElementByElement(lkpNameCd, eCd,row) {
      this.subElements[row] = this.constantsService.getLookUpByName(this.getLookupNameByQCd(lkpNameCd));
      if (this.subElements[row].length > 0 && eCd) {
        this.subElements[row] = this.subElements[row].filter(item => item.parentRef === eCd);
      }
    }


    varianceInd(){
      if(this.getControlValue('Var.1a')){
        this.var_ind = true;
      }
      else {
        this.var_ind = false;
      }

      let value = this.getControlValue('Var.1a');
      value = value.replace("$","");
      value = parseInt(value);
      //update the variance amount to 55 for fiscal 2023
      // if(value > 48){
      //   this.isNotEditable = true;
      // }

      if(value > 54){
        this.isNotEditable = true;
      }

    }
 



}
