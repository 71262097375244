import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-demonstration-projects',
  templateUrl: './demonstration-projects.component.html',
  styleUrls: ['./demonstration-projects.component.css']
})
export class DemonstrationProjectsComponent  extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

  handleQ820_18(val){
    if(val === '9'){
      this.group.controls['820.18a'].enable();
    }else{
      this.group.controls['820.18a'].disable();
    }
  }

}
