import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../components/field.interface";
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';
import { ConstantsService } from 'src/app/common/constants.service';
import { NavigationService } from '../../common/navigation.service';
@Component({
  selector: "app-radiobutton",
  template: `
  <div class="row" [ngClass]="{'alt-strips': oddEven}">
  <div *ngIf="false" class="col-sm-2">
    <mat-label>{{field.name}}</mat-label>
  </div>
  <div  *ngIf="showQDesc" class="col-sm-5">
    <mat-label>{{field.qDesc}}</mat-label>
  </div>
  <div class="col-sm-4">
    <div  [formGroup]="group">

    <mat-radio-group [formControlName]="field.name">
    <mat-radio-button  class="radio-group col-sm-3 text-center" *ngFor="let entity of field.options;
    let i = index" [value]="entity.code">{{entity.value}}</mat-radio-button>
    </mat-radio-group>
    </div>
</div>
</div>
`,
  styles: []
})
export class RadiobuttonComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  showQCd: boolean = true;
  showQDesc: boolean = true;

  
   constructor(
    public navigationService: NavigationService,
   ) {}

  ngOnInit() {
    // super.ngOnInit();
  }

  get oddEven() {

    const reminder = parseInt(this.field.name.split('.')[this.field.name.split('.').length-1]) % 2;
    if (reminder === 0 && this.field.qmode === 'OP'
    && this.navigationService.appCd !== 'QC' ) {
        return true;
    } else if (reminder === Math.round(reminder)) {
        return false;
    }
  }

}
