import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-client-moved-outof-state',
  templateUrl: './client-moved-outof-state.component.html',
  styleUrls: ['./client-moved-outof-state.component.css']
})
export class ClientMovedOutofStateComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
