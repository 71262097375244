import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-denial-code-for-interview',
  templateUrl: './denial-code-for-interview.component.html',
  styleUrls: ['./denial-code-for-interview.component.css']
})
export class DenialCodeForInterviewComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
