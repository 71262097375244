import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-q16-fsneg-income-and-shelter-expenses',
  templateUrl: './q16-fsneg-income-and-shelter-expenses.component.html',
  styleUrls: ['./q16-fsneg-income-and-shelter-expenses.component.css']
})
export class Q16FSNegIncomeAndShelterExpensesComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
