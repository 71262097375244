import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-qc35-a-rescind-case-closure-request',
  templateUrl: './qc35-a-rescind-case-closure-request.component.html',
  styleUrls: ['./qc35-a-rescind-case-closure-request.component.css']
})
export class QC35ARescindCaseClosureRequestComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
    this.setControlValue('500108.10','SNAP')
  }

}