import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-rsdi-benefits',
  templateUrl: './rsdi-benefits.component.html',
  styleUrls: ['./rsdi-benefits.component.css']
})

export class RsdiBenefitsComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.changeQf331_02(this.getControlValue('331.02'));
    this.changeQf331_1(this.getControlValue('331.1'));
    this.changeQf331_11(this.getControlValue('331.11'));
    this.determineAccess();
  }
  changeQf331_02(val) {
    if ( val === 'N') {
      this.group.controls['331.03'].disable();
      this.group.controls['331.04'].disable();
      this.group.controls['331.07'].disable();
    }else {
      this.group.controls['331.03'].enable();
      this.group.controls['331.04'].enable();
      this.group.controls['331.07'].enable();
      
    }
  }
  changeQf331_1(val) {
    if ( val === 'N') {
      this.group.controls['331.2'].disable();
      this.group.controls['331.3'].disable();
      this.group.controls['331.5'].disable();

      this.group.controls['331.9'].enable();
      this.group.controls['331.10'].enable();
      this.group.controls['331.11'].enable();
      this.group.controls['331.12'].enable();
      this.group.controls['331.13'].enable();
      this.group.controls['331.14'].enable();
      this.group.controls['331.15'].enable();

    }else  if ( val === 'Y'){
      this.group.controls['331.2'].enable();
      this.group.controls['331.3'].enable();
      this.group.controls['331.5'].enable();
      this.group.controls['331.9'].disable();
      this.group.controls['331.10'].disable();
      this.group.controls['331.11'].disable();
      this.group.controls['331.12'].disable();
      this.group.controls['331.13'].disable();
      this.group.controls['331.14'].disable();
      this.group.controls['331.15'].disable();
      
    }
  }

  changeQf331_11(val){
    if ( val === '2') {
      this.group.controls['331.12'].disable();
      this.group.controls['331.13'].disable();
    }else  if ( val === '1' || val === '3' ){
      this.group.controls['331.12'].enable();
      this.group.controls['331.13'].enable(); 
    }

  }


  get Is331_02(): boolean {
    return this.group.controls['331.02'].value === 'N';
  }

  get Is331_1(): boolean {
    return this.group.controls['331.1'].value === 'N';
  }
  get Is331_9(): boolean {
    return this.group.controls['331.9'].value === 'N';
  }
  get Is331_14(): boolean {
    return this.group.controls['331.14'].value === 'N';
  }
}