import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgMaterialModule } from './../../angular-material/ag-matrial.module';
import { SharedModuleModule } from './../../shared-module/shared-module.module';
import {
  QuestionaryComponent,
  QuestionaryDialog
} from './../../shared-module/questionary/questionary.component';
import { QuestionaryResolver } from './../../shared-module/questionary/questionary.resolver';
import { HighchartsChartModule } from 'highcharts-angular';
import { MonitoringRoutingModule } from './monitoring.routing';

import { MonitoringDashboardComponent } from './monitoring-admin/monitoring-dashboard/monitoring-dashboard.component';
import { MonitoringSampleComponent } from './monitoring-admin/monitoring-sample/monitoring-sample.component';
import { MonitoringAssignmentComponent } from './monitoring-admin/monitoring-assignment/monitoring-assignment.component';
import { ParisAlertComponent } from './monitoring-alerts/paris-alert/paris-alert.component';
import { NationalNewHireComponent } from './monitoring-alerts/national-new-hire/national-new-hire.component';
import { StateHireComponent } from './monitoring-alerts/state-hire/state-hire.component';
import { PreReivewDirectComponent } from './monitoring-alerts/pre-reivew-direct/pre-reivew-direct.component';
import { PrisonMatchComponent } from './monitoring/prison-match/prison-match.component';
import { ChildSupportMonitorComponent } from './monitoring/child-support-monitor/child-support-monitor.component';
import { ReactivationsComponent } from './monitoring/reactivations/reactivations.component';
import { OverpaymentBegsComponent } from './monitoring/overpayment-begs/overpayment-begs.component';
import { MonitoringMemosComponent,MonitoringMemoDialog } from './monitoring-memos/monitoring-memos/monitoring-memos.component';
// import { CaseAssignmentComponent } from 'src/app/shared-module/case-assignment/case-assignment.component';



@NgModule({
  declarations: [
    MonitoringDashboardComponent,
    MonitoringSampleComponent,
    MonitoringAssignmentComponent,
    ParisAlertComponent,
    NationalNewHireComponent,
    StateHireComponent,
    PreReivewDirectComponent,
    PrisonMatchComponent,
    ChildSupportMonitorComponent,
    ReactivationsComponent,
    OverpaymentBegsComponent,
    MonitoringMemosComponent,
    MonitoringMemoDialog
  ],
  imports: [
    CommonModule,
    SharedModuleModule,
    AgMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    HighchartsChartModule,
    MonitoringRoutingModule
  ],
  exports: [MonitoringRoutingModule],
  entryComponents: [
    MonitoringDashboardComponent,
    MonitoringSampleComponent,
    MonitoringAssignmentComponent,
    ParisAlertComponent,
    NationalNewHireComponent,
    StateHireComponent,
    PreReivewDirectComponent,
    PrisonMatchComponent,
    ChildSupportMonitorComponent,
    ReactivationsComponent,
    OverpaymentBegsComponent,
    MonitoringMemosComponent,
    MonitoringMemoDialog
  ]
})
export class MonitoringModule { }
