import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-other-government-benefits',
  templateUrl: './other-government-benefits.component.html',
  styleUrls: ['./other-government-benefits.component.css']
})

export class OtherGovernmentBenefitsComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.govBenefitsChange();
    this.changeQs336_4();
  }


    govBenefitsChange() {
      if ( this.group.controls['336.1'].value === 'N') {
      this.group.controls['336.2'].disable();
      this.group.controls['336.3'].disable();
      this.group.controls['336.4'].disable();
      this.group.controls['336.5a'].disable();
    this.group.controls['336.5b'].disable();
    this.group.controls['336.5c'].disable();
    this.group.controls['336.5d'].disable();
    this.group.controls['336.5e'].disable();
    this.group.controls['336.5f'].disable();
      this.group.controls['336.6'].disable();
      this.group.controls['336.7'].disable();
      this.group.controls['336.8'].disable();
      this.group.controls['336.9.1'].disable();
      this.group.controls['336.9.2'].disable();
      this.group.controls['336.9.3'].disable();
      this.group.controls['336.9.3a'].disable();
      
 }else if ( this.group.controls['336.1'].value === 'Y'){
      this.group.controls['336.2'].enable();
      this.group.controls['336.3'].enable();
      this.group.controls['336.4'].enable();
      this.group.controls['336.5'].enable();
      this.group.controls['336.5b'].enable();
      this.group.controls['336.5c'].enable();
      this.group.controls['336.5d'].enable();
      this.group.controls['336.5e'].enable();
      this.group.controls['336.5f'].enable();
      this.group.controls['336.6'].enable();
      this.group.controls['336.7'].enable();
      this.group.controls['336.8'].enable();
      this.group.controls['336.9.1'].enable();
      this.group.controls['336.9.2'].enable();
      this.group.controls['336.9.3'].enable();
      this.group.controls['336.9.3a'].enable();
      
	}
}

changeQs336_4() {
  if (this.group.controls['336.4'].value === 'Y') {
    this.group.controls['336.5a'].disable();
    this.group.controls['336.5b'].disable();
    this.group.controls['336.5c'].disable();
    this.group.controls['336.5d'].disable();
    this.group.controls['336.5e'].disable();
    this.group.controls['336.5f'].disable();
  }else if (this.group.controls['336.4'].value === 'N'){
    this.group.controls['336.5'].enable();
    this.group.controls['336.5b'].enable();
    this.group.controls['336.5c'].enable();
    this.group.controls['336.5d'].enable();
    this.group.controls['336.5e'].enable();
    this.group.controls['336.5f'].enable();
  }

}


  //RBAC
  // determineAccess() {
  //   const ref = this.constantsService.getSecurityObject();

  //   if ((ref.accessMap['/qualityControl/questionary-314?type=314'] != null && ref.accessMap['/qualityControl/questionary-314?type=314'].accessMode != 'rw')) {
  //       this.isNotEditable = true;
  //   }
  // }
}