import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-educational-grants',
  templateUrl: './educational-grants.component.html',
  styleUrls: ['./educational-grants.component.css']
})
export class EducationalGrantsComponent extends BaseComponent implements OnInit {

isNotEditable: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  changeQf345_1(val){
    if ( val === 'N') {
      this.group.controls['345.2'].disable();
      this.group.controls['345.3a'].disable();
      this.group.controls['345.3b'].disable();
      this.group.controls['345.3c'].disable();
      this.group.controls['345.3d'].disable();
      this.group.controls['345.4'].disable();
      this.group.controls['345.5'].disable();
      this.group.controls['345.6'].disable();
      this.group.controls['345.7'].disable();
    }else {
      this.group.controls['345.2'].enable();
      this.group.controls['345.3a'].enable();
      this.group.controls['345.3b'].enable();
      this.group.controls['345.3c'].enable();
      this.group.controls['345.3d'].enable();
      this.group.controls['345.4'].enable();
      this.group.controls['345.5'].enable();
      this.group.controls['345.6'].enable();
      this.group.controls['345.7'].enable();
    }
  }


}

