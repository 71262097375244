import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-total-income-over-limit',
  templateUrl: './total-income-over-limit.component.html',
  styleUrls: ['./total-income-over-limit.component.css']
})
export class TotalIncomeOverLimitComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
