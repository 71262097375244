import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-closed-to-avoid-dual-participation',
  templateUrl: './closed-to-avoid-dual-participation.component.html',
  styleUrls: ['./closed-to-avoid-dual-participation.component.css']
})
export class ClosedToAvoidDualParticipationComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
