import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-qc19-child-support-absence',
  templateUrl: './qc19-child-support-absence.component.html',
  styleUrls: ['./qc19-child-support-absence.component.css']
})
export class QC19ChildSupportAbsenceComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}