import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-calcsheet-comparision',
  templateUrl: './calcsheet-comparision.component.html',
  styleUrls: ['./calcsheet-comparison.component.css']
})
export class CalcsheetcomparisonComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;
  resultText:String;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.resultText = this.getComparisonResultText();
    const rvvnumber = this.constantsService.ReviewInfo.rvwNum;
    const firstFour = "2023";
    const variance = rvvnumber.toString().startsWith(firstFour) ? 48 : 54;
    

    let comp_1 = this.group.controls['890.2a'].value;
    let comp_2 = this.group.controls['890.2b'].value;
    let comp_4 = this.group.controls['890.4'].value;

    // "if(comp1 < var) &&( right side wording != 'inelegible') {
    //   LDSS Month Vs LDSS Corrected  = ' ';
    //   disable (comp2)
    //   comp1 is selected;
    //   right side wording will populate ""Correct""
    //   }"


    if (comp_1 < variance && !(comp_4 === 'Ineligible')) {
      this.group.controls['890.1c'].disable();
      this.group.controls['890.2b'].reset();
      this.group.controls['890.2b'].disable();
      this.setControlValue('890.3a', '1');
      this.setControlValue('890.4', 'Correct');
    }
    // "if (comp1 > var) {
    //   if(comp2 <= var ){
    //   comp2 will be selected;
    //   right side text will be 'Correct'
    //   }
    //   if(comp1 = comp2) {
    //   comp1 will be selected;
    //   if(sample month < ldss value){
    //   \\compare LDSS to sample month.
    //    If sample is less than LDSS, then over-issuance on right side box.
    //    If sample is greater than LDSS, under-issuance on the right side box
    //   }
    //   }"

    if (comp_1 > variance && !(comp_4 === 'Ineligible')) {
      if (comp_2 <= variance) {
        this.setControlValue('890.3a', '2');
        this.setControlValue('890.4', 'Correct');
      } else if (comp_1 = comp_2) {
        this.setControlValue('890.3a', '1');
        this.change_890_3a();
      }
    }

    //     "if comp1 and comp2 over the limit,then it selects the lesser of the two comps,

    if ((comp_1 > variance) && (comp_2 > variance)&& (comp_1 !== comp_2) && (comp_4 !== 'Ineligible')) {
      if (comp_1 < comp_2) {
        this.setControlValue('890.3a', '1');
      } else {
        this.setControlValue('890.3a', '2');
      }
      this.change_890_3a();

    }
// Logic for Ineligible
    if (comp_4 === 'Ineligible') {
      this.group.controls['890.2a'].disable();
      this.group.controls['890.2b'].disable();
      this.group.controls['890.3a'].disable();
    }

  
  }


  change_890_3a() {

    const ldds_Allotment = this.group.controls['890.1a'].value;
    const sample_Allotment = this.group.controls['890.1b'].value;
    const ldssCrrctd_Alltmnt = this.group.controls['890.1c'].value;
    if (this.getControlValue('890.3a') === '1') {
      this.setControlValue('890.4', ldds_Allotment > sample_Allotment
        ? 'Over-Issuance'
        : (ldds_Allotment < sample_Allotment
          ? 'Under-Issuance'
          : 'Correct'));
    } else if (this.getControlValue('890.3a') === '2') {
      this.setControlValue('890.4', ldds_Allotment > ldssCrrctd_Alltmnt
        ? 'Over-Issuance'
        : (ldds_Allotment < ldssCrrctd_Alltmnt
          ? 'Under-Issuance'
          : 'Correct Case'));
    }
    else {
      this.getControlValue('890.3a');
    }
  }
 
  
  
   getComparisonResultText(): string {
    const rvvnumber = this.constantsService.ReviewInfo.rvwNum;
    const firstFour = "2023";
    const variance = rvvnumber.toString().startsWith(firstFour) ? 48 : 54;
    let comp_1 = this.group.controls['890.2a'].value;
    let comp_2 = this.group.controls['890.2b'].value;
    let comp_4 = this.group.controls['890.4'].value;

      if (comp_1 < variance) {
          return "Comparison 1 recorded, comparison 2 was not needed";
      } else if (comp_1 > variance && comp_1 < comp_2) {
          return "Did comparison 2, recorded comparison 1";
      } else if (comp_1 > variance && comp_1 > comp_2) {
          return "Did comparison 2, recorded comparison 2";
      } else if (comp_1 > variance && comp_1 === comp_2) {
          return "Comparison 1 equals comparison 2";
      } else if (comp_4 === 'Ineligible') {
          return "Case ineligible, No comparison 1 or comparison 2 done.";
      } else {
          // Handle other cases if necessary
          return "Other result";
      }
      
  }
  

}
