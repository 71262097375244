import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgMaterialModule } from './../../angular-material/ag-matrial.module';
import { SharedModuleModule } from './../../shared-module/shared-module.module';
import {
  QuestionaryComponent,
  QuestionaryDialog
} from './../../shared-module/questionary/questionary.component';
import { QuestionaryResolver } from './../../shared-module/questionary/questionary.resolver';
import { HighchartsChartModule } from 'highcharts-angular';
import { WPRRoutingModule } from './wpr.routing';
import { WprDashboardComponent } from './wpr-admin/wpr-dashboard/wpr-dashboard.component';
import { WprSampleComponent } from './wpr-admin/wpr-sample/wpr-sample.component';
import { WprCaseAssignmentComponent } from './wpr-admin/wpr-case-assignment/wpr-case-assignment.component';
import { WprCaseReviewComponent } from './wpr/wpr-case-review/wpr-case-review.component';



@NgModule({
  declarations: [
    WprDashboardComponent,
    WprSampleComponent,
    WprCaseAssignmentComponent,
    WprCaseReviewComponent
  ],
  imports: [
    CommonModule,
    WPRRoutingModule,
    SharedModuleModule,
    AgMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    HighchartsChartModule
  ],
  exports: [WPRRoutingModule],
  entryComponents: [
    WprDashboardComponent,
    WprSampleComponent,
    WprCaseAssignmentComponent,
    WprCaseReviewComponent
  ]
})
export class WprModule { }
