import { Component, OnInit } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { PageConfig } from 'src/app/components/field.interface';
import { BaseComponent } from '../../baseComponent.component';
import { SendEmailComponent } from '../send-email/send-email.component';

@Component({
  selector: 'app-qc33-case-closure-request',
  templateUrl: './qc33-case-closure-request.component.html',
  styleUrls: ['./qc33-case-closure-request.component.css']
})

export class QC33CaseClosureRequestComponent extends BaseComponent implements OnInit {
  pageDataConfig: PageConfig[];
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.setControlValue('500107.10','SNAP')
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/qualityControl/questionary-500107?type=500107'] != null && ref.accessMap['/qualityControl/questionary-500107?type=500107'].accessMode != 'rw')) {
        this.isNotEditable = true;
    }
  }
  getHoh(event){
    if(event.checked == true){
      let hohName = this.constantsService?.ReviewInfo?.hohNm
      this.setControlValue('500107.21b',hohName);
    }
    else {
      this.setControlValue('500107.21b','');
    }
  }
getHohTwo(event){
  if(event.checked == true){
    let hohName = this.constantsService?.ReviewInfo?.hohNm
    this.setControlValue('500107.22b',hohName);
  }
  else {
    this.setControlValue('500107.22b','');
  }

}
}
