import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-earned-income-comparisonof-income',
  templateUrl: './earned-income-comparisonof-income.component.html',
  styleUrls: ['./earned-income-comparisonof-income.component.css']
})
export class EarnedIncomeComparisonofIncomeComponent extends BaseComponent implements OnInit {
  currentPage: number = 0;
  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    
    
  }
  
 



}
