import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-client-id-error',
  templateUrl: './client-id-error.component.html',
  styleUrls: ['./client-id-error.component.css']
})
export class ClientIdErrorComponent extends BaseComponent implements OnInit {
  ngOnInit() {
     super.ngOnInit();
   }

}
