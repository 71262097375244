import {
  Component,
  ViewChild,
  OnInit,
  OnChanges,
  OnDestroy,
  ChangeDetectorRef,
  Inject,
  ElementRef,
} from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { FieldConfig, PageConfig } from "../../components/field.interface";
import { DynamicFormComponent } from "../dynamic-form/dynamic-form.component";
import { ConstantsService } from "../../common/constants.service";
import { ApiManager } from "../../common/api-manager.service";
import { NavigationService } from "../../common/navigation.service";
import { DataShareService } from "../../common/data-share.service";
import { AppUtilService } from "../../common/app.util.service";
import { CustomValidatorsService } from "../../common/custom-validators.service";

import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { validateHorizontalPosition } from "@angular/cdk/overlay";
import { CaseDocumentComponent } from "../case-document/case-document.component";
import { ClarifyComponent } from "../clarify/clarify.component";
import { PrintService } from "../../common/print.service";
import { saveAs as importedSaveAs } from "file-saver";
import { NstrComponent } from "src/app/components/nstr/nstr.component";
import { GoogleSheetComponent } from "../google-sheet/google-sheet.component";
import * as jspdf from "jspdf";
import html2canvas from "html2canvas";
import { FileUploadComponent } from "../file-upload/file-upload.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SendEmailComponent } from "../qc-forms/send-email/send-email.component";
import { ModelModule } from "src/app/model/model.module";
import moment from "moment";
import { analyzeAndValidateNgModules } from "@angular/compiler";
import { Observable } from "rxjs";

@Component({
  selector: "app-questionary",
  templateUrl: "./questionary.component.html",
  styleUrls: ["./questionary.component.css"],
})
export class QuestionaryComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(DynamicFormComponent, { static: false })
  form: DynamicFormComponent;
  @ViewChild("printPDF", { static: false }) pdf: ElementRef;
  navigationSubscription;

  screenType: string = "";

  pageDataConfig: PageConfig[];
  memberForm: FormGroup;
  householdMembers: any;
  isQCForms: boolean =
    this.screenType && this.screenType.length > 0
      ? this.screenType.startsWith("5001")
      : false;
  showMemberDropdown: boolean = true;
  splitNameCapitals: string = "i";
  hideBackButton: boolean = false;
  hidePdfButton: boolean = true;
  hidePdfButtontwo: boolean = true;
  hideNextButton: boolean = false;
  showCompleteButton: boolean = false;
  hideAbawdButton: boolean = true;
  public isNotEditable: boolean = false;
  isEnableErrorButton: boolean = false;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private apiManager: ApiManager,
    public navigationService: NavigationService,
    private fb: FormBuilder,
    private shareService: DataShareService,
    public constantsService: ConstantsService,
    public appUtilService: AppUtilService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    public printService: PrintService,
    private customValidatorsService: CustomValidatorsService,
    private _snackBar: MatSnackBar
  ) {
    if (
      this.activeRoute.snapshot.data.pageData &&
      this.activeRoute.snapshot.data.pageData.memberList
    ) {
      this.householdMembers =
        this.activeRoute.snapshot.data.pageData.memberList;
    }
    // this.pageDataConfig = [];
    // this.pageDataConfig.push(this.activeRoute.snapshot.data.pageData.pageFieldConfig);
    this.preparePageData(
      this.activeRoute.snapshot.data.pageData.pageFieldConfig
    );
    this.isEnableErrorButton = this.getEnableErrorButton(
      this.activeRoute.snapshot.data.pageData.pageFieldConfig.pageType
    );
    this.openDialog = this.openDialog.bind(this);

    this.setDefaultIndividual();

    this.activeRoute.queryParams.subscribe((queryParams) => {
      if (queryParams["type"]) {
        this.screenType = queryParams["type"];
        this.setButtonSettings(this.screenType);
      }
    });

    this.isQCForms =
      this.screenType && this.screenType.length > 0
        ? this.screenType.startsWith("5001")
        : false;
    this.navigationService.getCurrentNav(this.router.url).map((nv) => {
      if (this.screenType === "1") {
        this.showMemberDropdown = false;
      } else
        this.showMemberDropdown =
          nv.prnt_desc === "parentCaseReview" || this.screenType === "700135";
    });

    // subscribe to the router events. Store the subscription so we can
    // unsubscribe later.
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        // this.initialiseInvites();
      }
    });

    this.memberForm = this.fb.group({
      individualId: ["" || this.currentIndividual],
    });
  }

  getEnableErrorButton(pageType: any): boolean {
    let pageTypeList = [
      "110",
      "111",
      "130",
      "140",
      "150.1",
      "151",
      "160",
      "161",
      "162",
      "163",
      "164",
      "165",
      "166",
      "170",
      "211",
      "212",
      "213",
      "221",
      "222",
      "224",
      "225",
      "311.3",
      "312.1",
      "312.2",
      "311.312",
      "314",
      "321",
      "323.1",
      "331",
      "332",
      "333",
      "334",
      "335",
      "336",
      "342",
      "343",
      "344",
      "345",
      "346",
      "347",
      "350.1",
      "999",
      "361",
      "363",
      "364",
      "365",
      "366",
      "371",
      "520",
      "530",
      "820",
    ];
    let pageTypeElement = pageTypeList.find((item) => item === pageType);
    return pageTypeElement ? true : false;
  }

  ngOnInit() {
    // this.initialiseInvites();
  }

  setButtonSettings(scrnType) {
    this.hideBackButton = false;
    this.hidePdfButton = true;
    this.hidePdfButtontwo = true;
    this.hideAbawdButton = true;
    this.hideNextButton = false;
    this.showCompleteButton = false;

    switch (scrnType) {
      case "700141": // FNS workbook
        this.hideBackButton = true;
        // this.hideNextButton = true;
        // this.showCompleteButton = true;
        break;
      case "500101": // Appointment Letter
      case "500102": // Shelter Cost Verification Letter
      case "500103": // Living Arrangement
      case "500104": // Bank Verification
      case "500105":
      case "500106":
      case "500107":
      case "500108":
      case "500109":
      case "500110":
      case "500111":
      case "500112":
      case "500113":
      case "500114":
      case "500115":
      case "500116":
      case "500117":
      case "500118":
      case "500119":
      case "500120":
      case "INTRV":
      case "500121":
        this.hideBackButton = true;
        this.hidePdfButton = false;
        this.hidePdfButtontwo = false;
        break;
      case "f26": // F26
        this.hideBackButton = true;
        break;
      case "500010": //QC16 Front page
        this.hideBackButton = true;
        break;
      case "700010": // workbook
        this.hideBackButton = true;
        break;
      case "600210": // FNS 245 schedule
        this.hideBackButton = true;
        this.hidePdfButton = false;
        this.hidePdfButtontwo = false;
        break;
      case "500020": //QC16N Front page
        this.hideBackButton = true;
        break;
      case "380-1.4": // 380-1 Schedule
        this.hideBackButton = true;
        this.hidePdfButton = false;
        this.hidePdfButtontwo = true;
        break;
      case "161": // ABAWD clock
        this.hideAbawdButton = false;
        break;
      default:
    }
  }

  openCaseDocumentDialog(value: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      individualId: this.currentIndividual,
    };

    const dialogRef = this.dialog.open(CaseDocumentComponent, dialogConfig);
    dialogRef.updateSize("75%", "85%");
    dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        console.log("afterClose", val);
      }
    });
  }
  openClarifyDialog(value: any) {
    let caseRecInfo: string = "";
    let discoveryVerification: string = "";
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      individualId: this.currentIndividual,
    };
    let queryParams = [];
    queryParams.push(this.screenType + "." + value);
    queryParams.push(this.currentIndividual);
    queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    queryParams.push(this.constantsService.ReviewInfo.rvwType);
    var number = this.screenType;

    if (
      (value && number === "110") ||
      (value && number === "111") ||
      (value && number === "130") ||
      (value && number === "140") ||
      (value && number === "150.1") ||
      (value && number === "151") ||
      (value && number === "160") ||
      (value && number === "161") ||
      (value && number === "162") ||
      (value && number === "163") ||
      (value && number === "164") ||
      (value && number === "165") ||
      (value && number === "166") ||
      (value && number === "170") ||
      (value && number === "211") ||
      (value && number === "212") ||
      (value && number === "213") ||
      (value && number === "221") ||
      (value && number === "222") ||
      (value && number === "224") ||
      (value && number === "225") ||
      (value && number === "311.1") ||
      (value && number === "311.2") ||
      (value && number === "311.3") ||
      (value && number === "312.1") ||
      (value && number === "312.2") ||
      (value && number === "311.312") ||
      (value && number === "314") ||
      (value && number === "321") ||
      (value && number === "323.1") ||
      (value && number === "331") ||
      (value && number === "332") ||
      (value && number === "333") ||
      (value && number === "334") ||
      (value && number === "335") ||
      (value && number === "336") ||
      (value && number === "342") ||
      (value && number === "343") ||
      (value && number === "344") ||
      (value && number === "345") ||
      (value && number === "346") ||
      (value && number === "347") ||
      (value && number === "350.1") ||
      (value && number === "999") ||
      (value && number === "361") ||
      (value && number === "363") ||
      (value && number === "364") ||
      (value && number === "365") ||
      (value && number === "366") ||
      (value && number === "530") ||
      (value && number === "820")
    ) {
      this.apiManager
        .fetchData(
          "getReviewQ",
          undefined,
          ApiManager.GET,
          undefined,
          undefined,
          undefined,
          queryParams
        )
        .subscribe((res) => {
          if (res && res.fieldConfig) {
            const fieldConfig = res.fieldConfig;
            discoveryVerification = fieldConfig.find(
              (fieldConfig) => fieldConfig.name == number + ".888.11"
            )?.value;
            caseRecInfo = fieldConfig.find(
              (fieldConfig) => fieldConfig.name == number + ".888.4"
            )?.value;
          }
          dialogConfig.data.discoveryVerification = discoveryVerification;
          dialogConfig.data.caseRecInfo = caseRecInfo;
          this.openClarifyModal(dialogConfig);
        });
    } else {
      this.openClarifyModal(dialogConfig);
    }
  }

  openClarifyModal(dialogConfig) {
    const dialogRef = this.dialog.open(ClarifyComponent, dialogConfig);
    dialogRef.updateSize("75%", "85%");
    dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        console.log("afterClose", val);
      }
    });
  }

  ngOnChanges() {}

  initialiseInvites() {
    // Set default values and re-fetch any data you need.
    this.getPageData();
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  setDefaultIndividual() {
    if (this.householdMembers) {
      this.householdMembers.map((hhm) => {
        if (
          this.currentIndividual !== hhm.indvdlId &&
          hhm.headOfHsehldInd === "Y"
        ) {
          this.currentIndividual = hhm.indvdlId;
          this.constantsService.selectedIndividual = hhm;
        }
      });
    }
  }

  changeMember(indiId) {
    console.log(indiId, "clicked!!!");
    if (this.householdMembers) {
      this.householdMembers.map((hhm) => {
        if (hhm.indvdlId === indiId) {
          this.currentIndividual = hhm.indvdlId;
          this.constantsService.selectedIndividual = hhm;
        }
      });
    }

    this.initialiseInvites();
    this.cdr.detectChanges();
  }

  get currentIndividual() {
    return this.shareService.getDataFromMap("currentIndividual");
  }

  set currentIndividual(id) {
    this.shareService.addDataToMap("currentIndividual", id);
  }

  getPageData() {
    const caseId: any = "";
    const invId: any = this.currentIndividual; // this.memberForm.get('individualId').value;
    const queryParams: any[] = [];
    queryParams.push(this.screenType); //screen type
    if (invId && !this.constantsService.ReviewInfo.bpeLdssEvltnId) {
      queryParams.push(invId);
    } else {
      queryParams.push(0); // for evualtion flow
    }

    if (
      this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum
    ) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }

    if (
      this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwType
    ) {
      if (!this.screenType.startsWith("5001")) {
        queryParams.push(this.constantsService.ReviewInfo.rvwType);
      } else {
        queryParams.push("QCForm");
      }
    } else {
      queryParams.push("Evaluation");
    }

    this.apiManager
      .fetchData(
        "getReviewQ",
        undefined,
        ApiManager.GET,
        undefined,
        undefined,
        undefined,
        queryParams
      )
      .subscribe((res) => {
        this.preparePageData(res);

        // this.form.page = this.pageDataConfig;
        // this.form.fields = this.pageDataConfig[0].fieldConfig;
        // this.form.openDialog = this.openDialog;
        // this.form.indvdlId = this.currentIndividual;
        // this.form.ngOnInit();
      });
  }

  preparePageData(res) {
    this.pageDataConfig = [];
    this.pageDataConfig.push(res);
    if (res.screenLvlLookup) {
      this.constantsService.lookUpValues = res.screenLvlLookup;
    }

    if (
      this.pageDataConfig &&
      this.pageDataConfig.length > 0 &&
      this.pageDataConfig[0].pageType == null
    ) {
      this.pageDataConfig[0].pageType = this.screenType;
    }

    this.pageDataConfig[0].fieldConfig.map((fld) => {
      if (fld.qtype === "checkbox") {
        fld.value = fld.value === "true" ? true : null;
      }
      if (
        fld.qmode === "OP" &&
        (fld.qtype === "select" || fld.lookupName) &&
        this.navigationService.appCd !== "QC"
      ) {
        if (fld) {
          if (!fld.validations) {
            fld.validations = [];
          }
          fld.validations.push({
            name: "answerNO",
            // validator: Validators.pattern(
            //   "\b(N|NO|No|no)\b"
            // ),
            validator: this.customValidatorsService.showErrorIfAnswerNO,
            message: "Error Case",
          });
        }
      }

      if (fld.qtype === "date") {
        if (fld) {
          if (!fld.validations) {
            fld.validations = [];
          }
          fld.validations.push({
            name: "invalidDate",
            // validator: Validators.pattern(
            //   "\b(N|NO|No|no)\b"
            // ),
            validator: this.customValidatorsService.validDate,
            message: "Invalid Date",
          });
        }
      }
    });
  }

  reset(model) {
    const caseId: any = "";
    const invId: any = this.currentIndividual; // this.memberForm.get('individualId').value;
    const queryParams: any[] = [];
    queryParams.push(this.screenType); //screen type
    if (invId && !this.constantsService.ReviewInfo.bpeLdssEvltnId) {
      queryParams.push(invId);
    } else {
      queryParams.push(0); // for evualtion flow
    }

    if (
      this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum
    ) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }

    if (
      this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwType
    ) {
      if (!this.screenType.startsWith("5001")) {
        queryParams.push(this.constantsService.ReviewInfo.rvwType);
      } else {
        queryParams.push("QCForm");
      }
    } else {
      queryParams.push("Evaluation");
    }

    this.apiManager
      .fetchData(
        "getResetReviewQ",
        undefined,
        ApiManager.GET,
        undefined,
        undefined,
        undefined,
        queryParams
      )
      .subscribe((res) => {
        if (this.screenType === "700100") {
          const formValue = model.form.getRawValue();
          const reviewer = formValue["700100.15"];
          const assignedDate = formValue["700100.16"];
          const supervisor = formValue["700100.20"];
          let reviewerField = res.fieldConfig.find(
            (field) => field.name == "700100.15"
          );
          if (reviewerField && reviewer) {
            reviewerField.value = reviewer;
          }
          let assignedDateField = res.fieldConfig.find(
            (field) => field.name == "700100.16"
          );
          if (assignedDateField && assignedDate) {
            assignedDateField.value = assignedDate;
          }
          let supervisorField = res.fieldConfig.find(
            (field) => field.name == "700100.20"
          );
          if (supervisorField && supervisor) {
            supervisorField.value = supervisor;
          }
        }

        this.preparePageData(res);

        // this.form.page = this.pageDataConfig;
        // this.form.fields = this.pageDataConfig[0].fieldConfig;
        // this.form.openDialog = this.openDialog;
        // this.form.indvdlId = this.currentIndividual;
        // this.form.ngOnInit();
      });
  }

  doSubmit(model: any, isNext: boolean = false, buttonId: any) {
    this.submit(model, isNext, buttonId).subscribe();
  }

  submit(model: any, isNext: boolean = false, buttonId: any): Observable<any> {
    console.log(model);
    let model_1 = model.form.getRawValue();
    if (
      this.constantsService.checkMandatoryForDisable(
        model,
        this.screenType,
        this.form
      )
    ) {
      this._snackBar.open("Please enter the mandatory fields.", undefined, {
        duration: 2000,
      });
      return;
    }
    if (this.screenType === "380-1.4") {
      let valueQs7 = model_1["380-1.4.7"];
      if (valueQs7 === "2" || valueQs7 === "3") {
        this.pageDataConfig[0].fieldConfig =
          this.pageDataConfig[0].fieldConfig.filter(
            (item) => item.recordGroupId === -1
          );
      }
    }
    if (this.screenType === "600210") {
      let valueQs23a = model_1["600210.23a"];
      if (valueQs23a === "2") {
        model_1["600210.39"] = "";
        model_1["600210.25a"] = "";
        model_1["600210.26a"] = "";
        model_1["600210.27"] = "";
        model_1["600210.28"] = "";
        model_1["600210.29"] = "";
        model_1["600210.30"] = "";
        model_1["600210.31"] = "";
        model_1["600210.32"] = "";
        model_1["600210.32a"] = "";
        model_1["600210.33"] = "";
        model_1["600210.33c"] = "";
        model_1["600210.33d"] = "";
        model_1["600210.34a"] = "";
        model_1["600210.34b"] = "";
        model_1["600210.34"] = "";
        model_1["600210.35"] = "";
        model_1["600210.36a"] = "";
        model_1["600210.37a"] = "";
        model_1["600210.36b"] = "";
        model_1["600210.37b"] = "";
        model_1["600210.36c"] = "";
        model_1["600210.37c"] = "";
        model_1["600210.38"] = "";
      }
    }
    const distinctRecordGroup = [
      ...new Set(
        this.pageDataConfig[0].fieldConfig
          .filter((rGId) => rGId.recordGroupId > -1)
          .map((rG) => rG.recordGroupId)
      ),
    ];

    if (this.pageDataConfig && this.pageDataConfig[0].fieldConfig) {
      this.pageDataConfig[0].fieldConfig.map((quest) => {
        quest.value = model_1[quest.name];
        if (quest.qtype.toLowerCase() === "date" && quest.value) {
          quest.value = this.appUtilService.changeDateFormatYYYYMMDD(
            quest.value
          );
        } else if (quest.qtype.toLowerCase() === "checkbox") {
          if (
            quest.name === "777" &&
            !quest.value &&
            this.navigationService.getSelectedNavHead() !== "parentCaseReview"
          ) {
            quest.value = true; // set page complete checkbox true
          } else {
            quest.value = quest.value === true ? true : null;
          }
        }

        if (
          model.form.controls[quest.name] &&
          model.form.controls[quest.name].dirty
        ) {
          quest.dirtyStatus = "update";
        }
        if (model.form.get("FormArray")) {
          for (let i = 0; i < model.form.get("FormArray").value.length; i++) {
            const val = model.form.get("FormArray").value[i];

            if (
              val[quest.name] &&
              distinctRecordGroup[i] === quest.recordGroupId
            ) {
              quest.dirtyStatus = "update";

              quest.value = val[quest.name];
              if (
                (quest.qtype.toLowerCase() === "date" ||
                  moment.isMoment(quest.value)) &&
                quest.value
              ) {
                quest.value = this.appUtilService.changeDateFormatYYYYMMDD(
                  quest.value
                );
              } else if (
                quest.qtype.toLowerCase() === "checkbox" ||
                (quest.qtype.toLocaleLowerCase() === "grid" &&
                  typeof quest.value === "boolean")
              ) {
                if (
                  quest.name === "777" &&
                  !quest.value &&
                  this.navigationService.getSelectedNavHead() !==
                    "parentCaseReview"
                ) {
                  quest.value = true; // set page complete checkbox true
                } else {
                  quest.value = quest.value === true ? true : null;
                }
              }
            }
          }
        }
      });

      delete this.pageDataConfig[0].screenLvlLookup;

      const caseId: any = "";
      const invId: any = this.memberForm.get("individualId").value;
      const queryParams: any[] = [];
      queryParams.push(this.screenType); //screen type
      // if (invId) {
      //   queryParams.push(invId);
      // }

      // if (this.constantsService.ReviewInfo &&
      //   this.constantsService.ReviewInfo.rvwNum) {
      //   queryParams.push(this.constantsService.ReviewInfo.rvwNum);
      // }
      if (
        this.constantsService.ReviewInfo &&
        this.constantsService.ReviewInfo.rvwType
      ) {
        if (!this.screenType.startsWith("5001" || "INTRV")) {
          queryParams.push(this.constantsService.ReviewInfo.rvwType);
        } else {
          queryParams.push("QCForm");
        }
      } else {
        queryParams.push("Evaluation");
      }

      queryParams.push(buttonId);
      return this.apiManager.fetchData(
        "saveReviewQ",
        undefined,
        ApiManager.POST,
        this.pageDataConfig[0],
        undefined,
        undefined,
        queryParams
      );
    }

    //return true;
  }

  next(model) {
    if (this.screenType === "600210" || this.screenType === "380-1.4") {
      this.navigationService.navigatePage(this.router.url, "next");
      return;
    }

    // if (this.screenType === '110' || this.screenType === '130' || this.screenType === '150.1' || this.screenType === '150.8' ||this.screenType === '160' || this.screenType === '161' || this.screenType === '162'||this.screenType === '311.312') {
    //   this.submit(model, true, '0');
    //   // setTimeout(() => {

    //   // }, 90000);

    //   setTimeout(() => this.navigationService.navigatePage(this.router.url, 'next'), 15000);
    //   // this.navigationService.navigatePage(this.router.url, 'next');
    //   return;
    // }

    this.submit(model, true, "0").subscribe((res) => {
      /*  if (!isNext) {
            this._snackBar.open('Save Successful.', undefined, {
              duration: 1000,
            });

            // this.pageDataConfig[0] = res;
            this.preparePageData(res);

            if (this.screenType.startsWith('5001')
              || this.screenType === '380-1.4' // 380-1 schedule
              || this.screenType === '600210' // FNS 245 schedule
            ) {
              // this.downloadPDF();
            }
          } */
      // return true;
      switch (this.screenType) {
        case "163":
          this.navigateToPage("165");
          break;
        case "221":
          this.navigateToPage("224");
          break;
        case "Misc":
          this.navigateToPage("883");
          break;
        case "520":
          if (
            this.navigationService.getNavItems().filter((v) => {
              if (v.nvgtn_cd == "nav-HouseholdCompositionAllotment") {
                if (v.disabled == "Y") {
                  this.navigateToPage("f26");
                } else {
                  this.navigateToPage("150.7");
                }
              }
            })
          )
            break;
        default:
          this.navigationService.navigatePage(this.router.url, "next");
      }
    });
  }

  back() {
    let varianceInd = false;
    this.navigationService.getNavItems().map((v) => {
      if (
        v.nvgtn_cd == "nav-CalcSheetVar" &&
        this.constantsService.ReviewInfo.rvwType === "FSPPositive" &&
        this.router.url === "/qualityControl/questionary-Bgt?type=Bgt"
      ) {
        if (v.disabled == "Y") {
          varianceInd = true;
          this.navigationService.navigatePage(
            "/qualityControl/questionary-Var?type=Var",
            "back"
          );
          return;
        }
      }
    });
    if (!varianceInd) {
      this.navigationService.navigatePage(this.router.url, "back");
    }
  }

  gotoCaseHome() {
    this.router.navigate(["qualityControl/caseHome"]);
  }

  navigateToPage(typeCd) {
    this.router.navigateByUrl(
      "/qualityControl/questionary-" + typeCd + "?type=" + typeCd
    );
  }

  complete(model) {
    this.saveReviewStatus("CP", new Date());
  }

  saveReviewStatus(status, rvwDate) {
    const queryParams: any[] = [];
    const appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    if (!this.constantsService.ReviewInfo.appType) {
      this.constantsService.ReviewInfo.appType = this.constantsService.getAppCd(
        this.navigationService.appCd
      );
    }
    queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    queryParams.push(status);
    queryParams.push(appCd);
    const body = {
      rvwDate: rvwDate,
    };
    this.apiManager
      .fetchData(
        "saveReviewStatus",
        undefined,
        ApiManager.POST,
        body,
        undefined,
        undefined,
        queryParams
      )
      .subscribe((res) => {
        this.constantsService.ReviewInfo.rvwSts = status;
        this.constantsService.ReviewInfo.rvwStsDesc =
          this.constantsService.getReviewStatusDescByCd(status);

        this.router.navigate([this.constantsService.getNavigateToRvwList()]);
        // this.cdr.detectChanges();
      });
  }

  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }

  openDialog(type, idx: any = -1, pData: any = null) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    let validateQ;
    if (this.screenType === "150.1") {
      validateQ = this.form.form.controls["150.1.43"].value;
    }

    if (pData) {
      dialogConfig.data = pData;
    } else {
      dialogConfig.data = {
        pageType: type,
        individualId: this.currentIndividual,
        parentPageType: this.screenType,
        groupId: idx,
        parentParentPageType: null,
        validateQVal: validateQ,
      };
    }

    const caseId: any = "";
    const invId: any = this.currentIndividual; // this.memberForm.get('individualId').value;
    const queryParams: any[] = [];
    if (
      dialogConfig.data.parentPageType &&
      (dialogConfig.data.pageType === "888" ||
        dialogConfig.data.pageType === "881" ||
        dialogConfig.data.pageType === "500119")
    ) {
      // for Error screen and QCForm Covering sheet
      queryParams.push(
        dialogConfig.data.parentPageType + "." + dialogConfig.data.pageType
      ); //screen type
    } else if (
      dialogConfig.data.parentPageType &&
      dialogConfig.data.parentParentPageType &&
      dialogConfig.data.pageType === "887"
    ) {
      queryParams.push(
        dialogConfig.data.parentParentPageType +
          "." +
          dialogConfig.data.pageType
      ); //screen type
    } else {
      queryParams.push(dialogConfig.data.pageType); //screen type
    }

    if (invId) {
      queryParams.push(invId);
    }

    if (
      this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum
    ) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }

    if (
      this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwType
    ) {
      if (!this.screenType.startsWith("5001")) {
        queryParams.push(this.constantsService.ReviewInfo.rvwType);
      } else {
        queryParams.push("QCForm");
      }
    } else {
      queryParams.push("Evaluation");
    }

    this.apiManager
      .fetchData(
        "getReviewQ",
        undefined,
        ApiManager.GET,
        undefined,
        undefined,
        undefined,
        queryParams
      )
      .subscribe((res) => {
        dialogConfig.data.pageDataConfig = res;
        const dialogRef = this.dialog.open(QuestionaryDialog, dialogConfig);
        dialogRef.updateSize("75%", "85%");
        dialogRef.afterClosed().subscribe((val) => {
          if (val) {
            console.log("afterClose", val);
            if (val.pageType && val.pageType !== this.screenType) {
              this.openDialog(val.pageType, -1, val);
            }
            if (
              this.screenType === "68-B" &&
              this.constantsService.in_68_Code &&
              this.constantsService.caseHomeData.typOfActn !== "2"
            ) {
              this.form.form.controls["68-B.12"].patchValue(
                this.constantsService.in_68_Code
              );
            }

            if (val.childType && val.childType === "881") {
              this.pageDataConfig[0].fieldConfig
                .filter(
                  (fld) =>
                    fld["qdesc"] === "Additional Information" &&
                    fld.qmode == "FN" &&
                    fld.qtype === "checkbox"
                )
                .map((ctrl) => {
                  if (val.is881Value && val.is881Value.trim().length > 0) {
                    this.form.form.controls[ctrl.name].patchValue(true);
                  } else {
                    this.form.form.controls[ctrl.name].patchValue(false);
                  }
                });
            }
          }
        });
      });
  }

  downloadPDF() {
    let queryParams = [];
    if (this.pageDataConfig[0].ccId) {
      queryParams.push(this.pageDataConfig[0].ccId);
    } else {
      queryParams.push("37537");
    }

    this.apiManager
      .fetchData(
        "pdfDownload",
        undefined,
        ApiManager.FILEDOWNLOAD,
        undefined,
        undefined,
        undefined,
        queryParams
      )
      .subscribe(
        (res: any) => {
          let blob;
          blob = new Blob([res], { type: "application/pdf" });
          importedSaveAs(blob, "");
        },
        (error) => {
          this._snackBar.open(
            "PDF not available.Please save and try again. ",
            undefined,
            {
              duration: 2000,
            }
          );
          console.log("look up API call failure: Error");
        }
      );
  }

  get IsView(): boolean {
    return this.pageDataConfig[0].ccId === 0;
  }
  getMycolor(key) {
    let color = [
      "#F09494",
      "#FBDCB6",
      "#94F0EA",
      "#94F0EA",
      "#FAE6A2",
      "#FDBBC7",
      "#90EB9F",
    ];
    let myColors = color[key % 7];

    return myColors;
  }
  viewPDF(model: any) {
    const queryParams: any[] = [];
    let ccId = this.pageDataConfig[0].ccId;
    if (ccId != 0) {
      queryParams.push(ccId);
    }
    this.apiManager
      .fetchData(
        "getDocumentForDownload",
        undefined,
        ApiManager.FILEDOWNLOAD,
        undefined,
        undefined,
        undefined,
        queryParams
      )
      .subscribe((res) => {
        if (res) {
          let blob = new Blob([res], { type: res.type });
          let fileURL = URL.createObjectURL(blob);
          var newWin = window.open(fileURL);
        }
      });
  }
  convertPDF(model: any, buttonId: any) {
    let currentDate: Date;
    let rvwMonth;
    let screenName = "";
    let fileName = "";

    if (
      this.screenType.startsWith("5001") ||
      this.screenType === "380-1.4" || // 380-1 schedule
      this.screenType === "600210" || // FNS 245 schedule
      this.screenType === "INTRV"
    ) {
      // if((this.screenType === '380-1.4') && !this.validateSchedule(model)){
      //   //  alert('Please,enter mandatory')
      //   return;
      // }
      /*  if (this.submit(model, false, buttonId)) {
        return;

      } */

      this.submit(model, false, buttonId).subscribe((res) => {
        this._snackBar.open("Save Successful.", undefined, {
          duration: 1000,
        });

        // this.pageDataConfig[0] = res;
        this.preparePageData(res);

        if (
          this.screenType.startsWith("5001") ||
          this.screenType === "380-1.4" || // 380-1 schedule
          this.screenType === "600210" // FNS 245 schedule
        ) {
          // this.downloadPDF();
        }
        return;
      });
    }

    let data = document.getElementById("convertPDF");
    // let data = document.getElementsByTagName('dynamic-form')[0];
    console.log(data);

    switch (this.screenType) {
      case "100031": // FSME LDSS Report
        currentDate = new Date(this.constantsService.ReviewInfo.rvwMo);
        rvwMonth = currentDate.getMonth() + "/" + currentDate.getFullYear();
        fileName =
          rvwMonth + "-" + this.constantsService.ReviewInfo["ldssDoName"];
        break;
      default:
        rvwMonth = this.constantsService.ReviewInfo.rvwMo;
        this.navigationService.getCurrentNav(this.router.url).map((nav) => {
          screenName = "-" + nav.tag_txt;
        });
        fileName = this.constantsService.ReviewInfo.rvwNum + screenName;
    }

    // html2canvas(data).then(canvas => {
    //   var imgData = canvas.toDataURL('image/png');
    //   var imgWidth = 210;
    //   var pageHeight = 295;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight;
    //   var doc = new jspdf('p', 'mm');
    //   var position = 10; // give some top padding to first page

    //   doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //   heightLeft -= pageHeight;

    //   while (heightLeft >= 0) {
    //     position += heightLeft - imgHeight; // top padding for other pages
    //     doc.addPage();
    //     doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //     heightLeft -= pageHeight;
    //   }
    //   doc.save(fileName + '.pdf');

    // });

    const HTML_Width = data.clientWidth; //this.pdf.nativeElement.width;
    const HTML_Height = data.clientHeight; // this.pdf.nativeElement.width ;
    const top_left_margin = 15;
    const PDF_Width = HTML_Width + top_left_margin * 2;
    const PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
    const canvas_image_width = HTML_Width;
    const canvas_image_height = HTML_Height;

    const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    html2canvas(data).then((canvas) => {
      canvas.getContext("2d");

      console.log(canvas.height + "  " + canvas.width);

      const imgData = canvas.toDataURL("image/jpeg", 1.0);

      const pdf = new jspdf("p", "pt", [PDF_Width, PDF_Height]);
      pdf.addImage(
        imgData,
        "JPG",
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      );

      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage(PDF_Width, PDF_Height);
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          -(PDF_Height * i) + top_left_margin * 4,
          canvas_image_width,
          canvas_image_height
        );
      }

      pdf.save(fileName + ".pdf");
    });
  }

  validatePdf(model: any) {
    let validated = true;
    let emptyFields = [];
    //section 1, field #7 has code 1
    if (
      this.getModelValue(model, "380-1.4.7") === "1" &&
      this.getModelValue(model, "380-1.4.8") !== "4"
    ) {
      // Field 8 must display a code 1,2, or 3
      if (
        this.getModelValue(model, "380-1.4.8") !== "1" &&
        this.getModelValue(model, "380-1.4.8") !== "2" &&
        this.getModelValue(model, "380-1.4.8") !== "3"
      ) {
        emptyFields.push(" 8. Finding must have a code(1, 2, 3, or 4)");
        validated = false;
      }
      // If field 8 has code 1, then field 10 must have a 0 value
      else if (
        this.getModelValue(model, "380-1.4.8") === "1" &&
        this.getModelValue(model, "380-1.4.10") != "0"
      ) {
        emptyFields.push(" Field 10 must have a code(0)");
        validated = false;
      }
      // If field 8 has code 2, or 3 then field 10 must have a value > 0
      else if (
        this.getModelValue(model, "380-1.4.8") === "2" ||
        (this.getModelValue(model, "380-1.4.8") === "3" &&
          this.getModelValue(model, "380-1.4.10") <= 0)
      ) {
        emptyFields.push(" Field 10 must have a code(greater than 0)");
        validated = false;
      }

      // If field 10 has a value >0 but <= FFY error threshold(49), then there must be data displayed in
      // section 2 for fields 12 and 13 only.
      if (
        this.getModelValue(model, "380-1.4.10") != null &&
        this.getModelValue(model, "380-1.4.10") > 0 &&
        this.getModelValue(model, "380-1.4.10") <= 49
      ) {
        if (
          this.getModelValue(model, "380-1.4.12") === "" ||
          this.getModelValue(model, "380-1.4.12") == null
        ) {
          emptyFields.push(" 12. Element must have a code");
          validated = false;
        }
        if (
          this.getModelValue(model, "380-1.4.13") === "" ||
          this.getModelValue(model, "380-1.4.13") == null
        ) {
          emptyFields.push(" 13. Nature must have a code");
          validated = false;
        }

        if (
          (this.getModelValue(model, "380-1.4.14") != null &&
            this.getModelValue(model, "380-1.4.14") != "") ||
          (this.getModelValue(model, "380-1.4.15") != null &&
            this.getModelValue(model, "380-1.4.15") != "") ||
          (this.getModelValue(model, "380-1.4.16") != null &&
            this.getModelValue(model, "380-1.4.16") != "") ||
          (this.getModelValue(model, "380-1.4.17") != null &&
            this.getModelValue(model, "380-1.4.17") != "") ||
          (this.getModelValue(model, "380-1.4.18") != null &&
            this.getModelValue(model, "380-1.4.18") != "") ||
          (this.getModelValue(model, "380-1.4.19") != null &&
            this.getModelValue(model, "380-1.4.19") != "")
        ) {
          emptyFields.push("Only Fields 12 and 13 must be filled in section 2");
        }
      }

      // If field 10 value > than FFY error threshold then section 2 fields must all have values
      if (
        this.getModelValue(model, "380-1.4.10") != null &&
        this.getModelValue(model, "380-1.4.10") > 49
      ) {
        if (
          (this.getModelValue(model, "380-1.4.14") == null &&
            this.getModelValue(model, "380-1.4.14") != "") ||
          (this.getModelValue(model, "380-1.4.15") == null &&
            this.getModelValue(model, "380-1.4.15") != "") ||
          (this.getModelValue(model, "380-1.4.16") == null &&
            this.getModelValue(model, "380-1.4.16") != "") ||
          (this.getModelValue(model, "380-1.4.17") == null &&
            this.getModelValue(model, "380-1.4.17") != "") ||
          (this.getModelValue(model, "380-1.4.18") == null &&
            this.getModelValue(model, "380-1.4.18") != "") ||
          (this.getModelValue(model, "380-1.4.19") == null &&
            this.getModelValue(model, "380-1.4.19") != "")
        ) {
          emptyFields.push("All fields in section must be filled");
        }
      }

      if (
        this.getModelValue(model, "380-1.4.9") === "" ||
        this.getModelValue(model, "380-1.4.9") == null
      ) {
        emptyFields.push(" 9. SNAP Allotment Under Review");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.10") === "" ||
        this.getModelValue(model, "380-1.4.10") == null
      ) {
        emptyFields.push(" 10. Error Amount");
        validated = false;
      }

      // Section 3 must have values in field 20, 21, 22, 23, (if 23 is code 1 then leave 24 blank, if 23 has
      // code 2 or code 3, there must be a value in 24), 25, 26, 27, 28, 29, 30, 31, 34, 35, 36, 37, 38, 39,
      // 40, 41, 42, 43, 44a (if 44a is code 1, then 45 must be blank, otherwise a code must be in 45)
      if (
        this.getModelValue(model, "380-1.4.20") === "" ||
        this.getModelValue(model, "380-1.4.20") == null
      ) {
        emptyFields.push(" 20. Most Recent Cert. Action");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.21") === "" ||
        this.getModelValue(model, "380-1.4.21") == null
      ) {
        emptyFields.push(" 21. Type of Action");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.22") === "" ||
        this.getModelValue(model, "380-1.4.22") == null
      ) {
        emptyFields.push(" 22. Length of Cert Period # of months");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.23") === "" ||
        this.getModelValue(model, "380-1.4.23") == null
      ) {
        emptyFields.push(" 23. Allotment Adjustment");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.23") === "1" &&
        this.getModelValue(model, "380-1.4.24") != ""
      ) {
        emptyFields.push(" Field 24 must be blank");
        validated = false;
      } else if (
        (this.getModelValue(model, "380-1.4.23") === "2" ||
          this.getModelValue(model, "380-1.4.23") === "3") &&
        (this.getModelValue(model, "380-1.4.24") === "" ||
          this.getModelValue(model, "380-1.4.24") == null)
      ) {
        emptyFields.push(" 24. Amount of Allotment Adjustment");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.25") === "" ||
        this.getModelValue(model, "380-1.4.25") == null
      ) {
        emptyFields.push(" 25. Number of Household Members");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.26") === "" ||
        this.getModelValue(model, "380-1.4.26") == null
      ) {
        emptyFields.push(" 26. Receipt of Expedited Service");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.27") === "" ||
        this.getModelValue(model, "380-1.4.27") == null
      ) {
        emptyFields.push(" 27. Authorized Representative Used at Application");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.28") === "" ||
        this.getModelValue(model, "380-1.4.28") == null
      ) {
        emptyFields.push(" 28. Categorical Eligibility");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.29") === "" ||
        this.getModelValue(model, "380-1.4.29") == null
      ) {
        emptyFields.push(" 29. Reporting Requirement");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.30") === "" ||
        this.getModelValue(model, "380-1.4.30") == null
      ) {
        emptyFields.push(" 30. Liquid");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.31") === "" ||
        this.getModelValue(model, "380-1.4.31") == null
      ) {
        emptyFields.push(" 31. Property (excluding home)");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.34") === "" ||
        this.getModelValue(model, "380-1.4.34") == null
      ) {
        emptyFields.push(" 34. Other Non-Liquid");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.35") === "" ||
        this.getModelValue(model, "380-1.4.35") == null
      ) {
        emptyFields.push(" 35. Gross");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.36") === "" ||
        this.getModelValue(model, "380-1.4.36") == null
      ) {
        emptyFields.push(" 36.Net");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.37") === "" ||
        this.getModelValue(model, "380-1.4.37") == null
      ) {
        emptyFields.push(" 37. Earned Income");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.38") === "" ||
        this.getModelValue(model, "380-1.4.38") == null
      ) {
        emptyFields.push(" 38. Medical");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.39") === "" ||
        this.getModelValue(model, "380-1.4.39") == null
      ) {
        emptyFields.push(" 39. Dependent Care");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.40") === "" ||
        this.getModelValue(model, "380-1.4.40") == null
      ) {
        emptyFields.push(" 40. Child Support");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.41") === "" ||
        this.getModelValue(model, "380-1.4.41") == null
      ) {
        emptyFields.push(" 41. Shelter");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.42") === "" ||
        this.getModelValue(model, "380-1.4.42") == null
      ) {
        emptyFields.push(" 42. Homeless");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.43") === "" ||
        this.getModelValue(model, "380-1.4.43") == null
      ) {
        emptyFields.push(" 43.Rent/Mortgage");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.44a") === "" ||
        this.getModelValue(model, "380-1.4.44a") == null
      ) {
        emptyFields.push(" 44a.usage");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.44a") === "1" &&
        this.getModelValue(model, "380-1.4.44b") !== ""
      ) {
        emptyFields.push(" Field 44b must be blank");
        validated = false;
      } else if (
        this.getModelValue(model, "380-1.4.44a") !== "1" &&
        this.getModelValue(model, "380-1.4.44b") === ""
      ) {
        emptyFields.push(" Field 44b must have a code");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.45") === "" ||
        this.getModelValue(model, "380-1.4.45") == null
      ) {
        emptyFields.push(" Field 45");
        validated = false;
      }

      /*
      Section 4 - for each row of household members - fields 46 and 47 must always have a value and if 47 
      is code 1 then 48, 49, 50, 51, 52, 53, 54, 55, 56, and 57 must have values.  If 47 is any other code 
      besides 1 then field 48 is the only required field.
      */
      if (this.getModelValue(model, "380-1.4.46") !== null) {
        if (
          this.getModelValue(model, "380-1.4.46") === "" ||
          this.getModelValue(model, "380-1.4.46") == null
        ) {
          emptyFields.push(" Field 46");
          validated = false;
        }
        if (
          this.getModelValue(model, "380-1.4.47") === "" ||
          this.getModelValue(model, "380-1.4.47") == null
        ) {
          emptyFields.push(" 47. SNAP Participation");
          validated = false;
        } else if (this.getModelValue(model, "380-1.4.47") === "1") {
          if (
            this.getModelValue(model, "380-1.4.48") === "" ||
            this.getModelValue(model, "380-1.4.48") == null
          ) {
            emptyFields.push(" 48. Relation Head of HH");
            validated = false;
          }
          if (
            this.getModelValue(model, "380-1.4.49") === "" ||
            this.getModelValue(model, "380-1.4.49") == null
          ) {
            emptyFields.push(" 49. Age");
            validated = false;
          }
          if (
            this.getModelValue(model, "380-1.4.50") === "" ||
            this.getModelValue(model, "380-1.4.50") == null
          ) {
            emptyFields.push(" 50. Sex");
            validated = false;
          }
          if (
            this.getModelValue(model, "380-1.4.51") === "" ||
            this.getModelValue(model, "380-1.4.51") == null
          ) {
            emptyFields.push(" 51. Race");
            validated = false;
          }
          if (
            this.getModelValue(model, "380-1.4.52") === "" ||
            this.getModelValue(model, "380-1.4.52") == null
          ) {
            emptyFields.push(" 52. Citizen Status");
            validated = false;
          }
          if (
            this.getModelValue(model, "380-1.4.53") === "" ||
            this.getModelValue(model, "380-1.4.53") == null
          ) {
            emptyFields.push(" 53. Edu.Level");
            validated = false;
          }
          if (
            this.getModelValue(model, "380-1.4.54a") === "" ||
            this.getModelValue(model, "380-1.4.54a") == null
          ) {
            emptyFields.push(" 54.Employment Status");
            validated = false;
          }
          if (
            this.getModelValue(model, "380-1.4.54b") === "" ||
            this.getModelValue(model, "380-1.4.54b") == null
          ) {
            emptyFields.push(" 54.Employment Hours");
            validated = false;
          }
          if (
            this.getModelValue(model, "380-1.4.55") === "" ||
            this.getModelValue(model, "380-1.4.55") == null
          ) {
            emptyFields.push(" 55. SNAP.Work Reg.");
            validated = false;
          }
          if (
            this.getModelValue(model, "380-1.4.56") === "" ||
            this.getModelValue(model, "380-1.4.56") == null
          ) {
            emptyFields.push(" 56. SNAP E&T");
            validated = false;
          }
          if (
            this.getModelValue(model, "380-1.4.57") === "" ||
            this.getModelValue(model, "380-1.4.57") == null
          ) {
            emptyFields.push(" ABAWD Status");
            validated = false;
          }
        } else {
          if (
            this.getModelValue(model, "380-1.4.48") === "" ||
            this.getModelValue(model, "380-1.4.48") == null
          ) {
            emptyFields.push(" 48. Relation Head of HH");
            validated = false;
          }
        }
      }

      /*
      Section 5
      IF field 35 has a value greater than 0, then field 59 must have data for at least one household member. 
      If there are members displayed in field 59 then income type (field 60) must have a value and (field 61) must have an amount. 
      So if 60 has a value 61 must also.  Same for 62 and 63, 64 and 65, 66 and 67.
      */
      if (
        this.getModelValue(model, "380-1.4.35") != null &&
        this.getModelValue(model, "380-1.4.35") > 0 &&
        (this.getModelValue(model, "380-1.4.59") == null ||
          this.getModelValue(model, "380-1.4.59") === "")
      ) {
        emptyFields.push(
          " Field 59 must have data for at least one HOH member"
        );
        validated = false;
      }
      if (this.getModelValue(model, "380-1.4.59") !== "") {
        if (
          this.getModelValue(model, "380-1.4.60") !== "" &&
          this.getModelValue(model, "380-1.4.60") != null &&
          (this.getModelValue(model, "380-1.4.61") === "" ||
            this.getModelValue(model, "380-1.4.61") == null)
        ) {
          emptyFields.push(" 61. Amount ");
          validated = false;
        } else if (
          this.getModelValue(model, "380-1.4.61") !== "" &&
          this.getModelValue(model, "380-1.4.61") != null &&
          (this.getModelValue(model, "380-1.4.60") === "" ||
            this.getModelValue(model, "380-1.4.60") == null)
        ) {
          emptyFields.push(" 60. Income Type");
          validated = false;
        }

        if (
          this.getModelValue(model, "380-1.4.62") !== "" &&
          this.getModelValue(model, "380-1.4.62") != null &&
          (this.getModelValue(model, "380-1.4.63") === "" ||
            this.getModelValue(model, "380-1.4.63") == null)
        ) {
          emptyFields.push(" 63. Amount ");
          validated = false;
        } else if (
          this.getModelValue(model, "380-1.4.63") != "" &&
          this.getModelValue(model, "380-1.4.63") != null &&
          (this.getModelValue(model, "380-1.4.62") === "" ||
            this.getModelValue(model, "380-1.4.62") == null)
        ) {
          emptyFields.push(" 62. Income Type");
          validated = false;
        }

        if (
          this.getModelValue(model, "380-1.4.64") !== "" &&
          this.getModelValue(model, "380-1.4.64") != null &&
          (this.getModelValue(model, "380-1.4.65") === "" ||
            this.getModelValue(model, "380-1.4.65") == null)
        ) {
          emptyFields.push(" 65. Amount");
          validated = false;
        } else if (
          this.getModelValue(model, "380-1.4.65") !== "" &&
          this.getModelValue(model, "380-1.4.65") != null &&
          (this.getModelValue(model, "380-1.4.64") === "" ||
            this.getModelValue(model, "380-1.4.64") == null)
        ) {
          emptyFields.push(" 64. Income Type");
          validated = false;
        }

        if (
          this.getModelValue(model, "380-1.4.66") !== "" &&
          this.getModelValue(model, "380-1.4.66") != null &&
          (this.getModelValue(model, "380-1.4.67") === "" ||
            this.getModelValue(model, "380-1.4.67") == null)
        ) {
          emptyFields.push(" 67. Amount ");
          validated = false;
        } else if (
          this.getModelValue(model, "380-1.4.67") !== "" &&
          this.getModelValue(model, "380-1.4.67") != null &&
          (this.getModelValue(model, "380-1.4.66") === "" ||
            this.getModelValue(model, "380-1.4.66") == null)
        ) {
          emptyFields.push(" 66. Income Type");
          validated = false;
        }
      }

      //Section 6 - fields 68, 69, 70, 71 must all have values at all times
      if (
        this.getModelValue(model, "380-1.4.68") === "" ||
        this.getModelValue(model, "380-1.4.68") == null
      ) {
        emptyFields.push(" Field 68.");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.69") === "" ||
        this.getModelValue(model, "380-1.4.69") == null
      ) {
        emptyFields.push(" Field 69.");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.70") === "" ||
        this.getModelValue(model, "380-1.4.70") == null
      ) {
        emptyFields.push(" Field 70.");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.71") === "" ||
        this.getModelValue(model, "380-1.4.71") == null
      ) {
        emptyFields.push(" Field 71.");
        validated = false;
      }
    }

    // Rules For When Field 7 is Code 1 and Field 8 is Code 4
    else if (
      this.getModelValue(model, "380-1.4.7") === "1" &&
      this.getModelValue(model, "380-1.4.8") === "4"
    ) {
      // If field 8 is code 4, then field 9 must equal field 10 and field 16.
      if (
        this.getModelValue(model, "380-1.4.9") !==
        (this.getModelValue(model, "380-1.4.10") &&
          this.getModelValue(model, "380-1.4.16"))
      ) {
        emptyFields.push(" Field 9 must be equal to Field 10 and 16");
        validated = false;
      }
      // Field 15 must be code 4.
      if (this.getModelValue(model, "380-1.4.15") !== "4") {
        emptyFields.push(" Field 15 must be code(4)");
        validated = false;
      }
      // Fields 20, 21, 22, 23 must have values
      if (
        this.getModelValue(model, "380-1.4.20") === "" ||
        this.getModelValue(model, "380-1.4.20") == null
      ) {
        emptyFields.push(" 20. Most Recent Cert. Action");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.21") === "" ||
        this.getModelValue(model, "380-1.4.21") == null
      ) {
        emptyFields.push(" 21. Type of Action");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.22") === "" ||
        this.getModelValue(model, "380-1.4.22") == null
      ) {
        emptyFields.push(" 22. Length of Cert Period # of months");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.23") === "" ||
        this.getModelValue(model, "380-1.4.23") == null
      ) {
        emptyFields.push(" 23. Allotment Adjustment");
        validated = false;
      }

      // If 23 is code 1 then 24 must be blank
      if (
        this.getModelValue(model, "380-1.4.23") === "1" &&
        this.getModelValue(model, "380-1.4.24") != ""
      ) {
        emptyFields.push(" Field 24 must be blank");
        validated = false;
      }
      // If 23 is code 2 or 3 then 24 must have value
      else if (
        this.getModelValue(model, "380-1.4.23") === "2" ||
        (this.getModelValue(model, "380-1.4.23") === "3" &&
          this.getModelValue(model, "380-1.4.24") === "")
      ) {
        emptyFields.push(" 24. Amount of Allotment Adjustment");
        validated = false;
      }

      // Field 25 must be 0
      if (this.getModelValue(model, "380-1.4.25") !== "0") {
        emptyFields.push(" Field 25 must be 0");
        validated = false;
      }

      // Fields 26, 27, 28, 29, 30, 31, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44a must have values.
      if (
        this.getModelValue(model, "380-1.4.26") === "" ||
        this.getModelValue(model, "380-1.4.26") == null
      ) {
        emptyFields.push(" 26. Receipt of Expedited Service");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.27") === "" ||
        this.getModelValue(model, "380-1.4.27") == null
      ) {
        emptyFields.push(" 27. Authorized Representative Used at Application");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.28") === "" ||
        this.getModelValue(model, "380-1.4.28") == null
      ) {
        emptyFields.push(" 28. Categorical Eligibility");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.29") === "" ||
        this.getModelValue(model, "380-1.4.29") == null
      ) {
        emptyFields.push(" 29. Reporting Requirement");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.30") === "" ||
        this.getModelValue(model, "380-1.4.30") == null
      ) {
        emptyFields.push(" 30. Liquid");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.31") === "" ||
        this.getModelValue(model, "380-1.4.31") == null
      ) {
        emptyFields.push(" 31. Property (excluding home)");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.34") === "" ||
        this.getModelValue(model, "380-1.4.34") == null
      ) {
        emptyFields.push(" 34. Other Non-Liquid");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.35") === "" ||
        this.getModelValue(model, "380-1.4.35") == null
      ) {
        emptyFields.push(" 35. Gross");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.36") === "" ||
        this.getModelValue(model, "380-1.4.36") == null
      ) {
        emptyFields.push(" 36.Net");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.37") === "" ||
        this.getModelValue(model, "380-1.4.37") == null
      ) {
        emptyFields.push(" 37. Earned Income");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.38") === "" ||
        this.getModelValue(model, "380-1.4.38") == null
      ) {
        emptyFields.push(" 38. Medical");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.39") === "" ||
        this.getModelValue(model, "380-1.4.39") == null
      ) {
        emptyFields.push(" 39. Dependent Care");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.40") === "" ||
        this.getModelValue(model, "380-1.4.40") == null
      ) {
        emptyFields.push(" 40. Child Support");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.41") === "" ||
        this.getModelValue(model, "380-1.4.41") == null
      ) {
        emptyFields.push(" 41. Shelter");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.42") === "" ||
        this.getModelValue(model, "380-1.4.42") == null
      ) {
        emptyFields.push(" 42. Homeless");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.43") === "" ||
        this.getModelValue(model, "380-1.4.43") == null
      ) {
        emptyFields.push(" 43.Rent/Mortgage");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.44a") === "" ||
        this.getModelValue(model, "380-1.4.44a") == null
      ) {
        emptyFields.push(" 44a. usage");
        validated = false;
      }

      // if 44a is code 1 then 44b must be blank, otherwise 44b must have a value
      if (
        this.getModelValue(model, "380-1.4.44a") === "1" &&
        this.getModelValue(model, "380-1.4.44b") !== ""
      ) {
        emptyFields.push(" Field 44b. must be blank");
        validated = false;
      } else if (
        this.getModelValue(model, "380-1.4.44a") !== "1" &&
        this.getModelValue(model, "380-1.4.44b") === ""
      ) {
        emptyFields.push(" Field 44b. proration");
        validated = false;
      }

      // Field 45 must have a value
      if (
        this.getModelValue(model, "380-1.4.45") === "" ||
        this.getModelValue(model, "380-1.4.45") == null
      ) {
        emptyFields.push(" 45.Utilities (SUA or Actual)");
        validated = false;
      }

      // Section 4 and Section 5 must be blank and field 71 must have a code 5
      if (this.getModelValue(model, "380-1.4.46") != null) {
        emptyFields.push("Section 4 must be blank.");
      }

      if (this.getModelValue(model, "380-1.4.59") != null) {
        emptyFields.push("Section 5 must be blank.");
      }

      // Fields 68, 69, 70 must also show values
      if (
        this.getModelValue(model, "380-1.4.68") === "" ||
        this.getModelValue(model, "380-1.4.68") == null
      ) {
        emptyFields.push(" 68.");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.69") === "" ||
        this.getModelValue(model, "380-1.4.69") == null
      ) {
        emptyFields.push(" 69.");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.70") === "" ||
        this.getModelValue(model, "380-1.4.70") == null
      ) {
        emptyFields.push(" 70.");
        validated = false;
      }

      // Field 71 must have a code 5.
      if (this.getModelValue(model, "380-1.4.71") != "5") {
        emptyFields.push("Field 71 must have a code(5)");
      }
    }

    // Rules For Code 2,3, or 4 in Field 7.
    else if (
      this.getModelValue(model, "380-1.4.7") === "2" ||
      this.getModelValue(model, "380-1.4.7") === "3" ||
      this.getModelValue(model, "380-1.4.7") === "4"
    ) {
      if (
        this.getModelValue(model, "380-1.4.68") === "" ||
        this.getModelValue(model, "380-1.4.68") == null
      ) {
        emptyFields.push(" 68.");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.69") === "" ||
        this.getModelValue(model, "380-1.4.69") == null
      ) {
        emptyFields.push(" 69.");
        validated = false;
      }
      if (
        this.getModelValue(model, "380-1.4.70") === "" ||
        this.getModelValue(model, "380-1.4.70") == null
      ) {
        emptyFields.push(" 70.");
        validated = false;
      }
    }

    // Additional rules that apply to individual fields regardless of status:
    // If Field 70 shows code 30 then Field 22 must display code 98.
    if (
      this.getModelValue(model, "380-1.4.70") === "30" &&
      this.getModelValue(model, "380-1.4.22") !== "98"
    ) {
      emptyFields.push(" Field 22 must have a code(98)");
      validated = false;
    }

    if (!validated) {
      alert("Please complete the required fields: " + emptyFields);
    } else {
      alert("Validation Successful");
    }

    return validated;
  }

  validateSchedule(model: any) {
    let resultValidate = true;
    let q380_10 = +this.getModelValue(model, "380-1.4.10");
    let q380_12 = this.getModelValue(model, "380-1.4.12");
    let q380_13 = this.getModelValue(model, "380-1.4.13");
    let q380_14 = this.getModelValue(model, "380-1.4.14");
    let q380_15 = this.getModelValue(model, "380-1.4.15");
    let q380_16 = this.getModelValue(model, "380-1.4.16");
    let q380_17 = this.getModelValue(model, "380-1.4.17");
    let q380_18 = this.getModelValue(model, "380-1.4.18");
    let q380_19 = this.getModelValue(model, "380-1.4.19");
    let q380_20 = this.getModelValue(model, "380-1.4.20");
    let q380_21 = this.getModelValue(model, "380-1.4.21");
    let q380_22 = this.getModelValue(model, "380-1.4.22");
    let q380_23 = this.getModelValue(model, "380-1.4.23");
    let q380_24 = this.getModelValue(model, "380-1.4.24");
    let q380_25 = this.getModelValue(model, "380-1.4.25");
    let q380_26 = this.getModelValue(model, "380-1.4.26");
    let q380_27 = this.getModelValue(model, "380-1.4.27");
    let q380_28 = this.getModelValue(model, "380-1.4.28");
    let q380_29 = this.getModelValue(model, "380-1.4.29");
    let q380_30 = this.getModelValue(model, "380-1.4.30");
    let q380_31 = this.getModelValue(model, "380-1.4.31");
    let q380_34 = this.getModelValue(model, "380-1.4.34");
    let q380_35 = this.getModelValue(model, "380-1.4.35");
    let q380_35n = +this.getModelValue(model, "380-1.4.35");
    let q380_36 = this.getModelValue(model, "380-1.4.36");
    let q380_37 = this.getModelValue(model, "380-1.4.37");
    let q380_38 = this.getModelValue(model, "380-1.4.38");
    let q380_39 = this.getModelValue(model, "380-1.4.39");
    let q380_40 = this.getModelValue(model, "380-1.4.40");
    let q380_41 = this.getModelValue(model, "380-1.4.41");
    let q380_42 = this.getModelValue(model, "380-1.4.42");
    let q380_43 = this.getModelValue(model, "380-1.4.43");
    let q380_44a = this.getModelValue(model, "380-1.4.44a");
    let q380_44b = this.getModelValue(model, "380-1.4.44b");
    let q380_45 = this.getModelValue(model, "380-1.4.45");
    let q380_46 = this.getModelValue(model, "380-1.4.46");
    let q380_47 = this.getModelValue(model, "380-1.4.47");
    let q380_48 = this.getModelValue(model, "380-1.4.48");
    let q380_49 = this.getModelValue(model, "380-1.4.49");
    let q380_50 = this.getModelValue(model, "380-1.4.50");
    let q380_51 = this.getModelValue(model, "380-1.4.51");
    let q380_52 = this.getModelValue(model, "380-1.4.52");
    let q380_53 = this.getModelValue(model, "380-1.4.53");
    let q380_54 = this.getModelValue(model, "380-1.4.54");
    let q380_55 = this.getModelValue(model, "380-1.4.55");
    let q380_56 = this.getModelValue(model, "380-1.4.56");
    let q380_57 = this.getModelValue(model, "380-1.4.57");
    let q380_59 = this.getModelValue(model, "380-1.4.59");
    let q380_60 = this.getModelValue(model, "380-1.4.60");
    let q380_61 = this.getModelValue(model, "380-1.4.61");
    let q380_62 = this.getModelValue(model, "380-1.4.62");
    let q380_63 = this.getModelValue(model, "380-1.4.63");
    let q380_64 = this.getModelValue(model, "380-1.4.64");
    let q380_65 = this.getModelValue(model, "380-1.4.65");
    let q380_66 = this.getModelValue(model, "380-1.4.66");
    let q380_67 = this.getModelValue(model, "380-1.4.67");
    let q380_68 = this.getModelValue(model, "380-1.4.68");
    let q380_69 = this.getModelValue(model, "380-1.4.69");
    let q380_70 = this.getModelValue(model, "380-1.4.70");
    let q380_71 = this.getModelValue(model, "380-1.4.71");

    //1 - Field 8 must display a code 1,2,3 or 4.  If code 1 is used, then field 10 must have a 0 value.
    // If field 8 has code 2, 3, or 4 then field 10 must have a value greater than 0.

    if (this.getModelValue(model, "380-1.4.7") == "1") {
      if (
        !(
          this.getModelValue(model, "380-1.4.8") == "1" ||
          this.getModelValue(model, "380-1.4.8") == "2" ||
          this.getModelValue(model, "380-1.4.8") == "3" ||
          this.getModelValue(model, "380-1.4.8") == "4"
        )
      ) {
        resultValidate = false;
      }
      if (this.getModelValue(model, "380-1.4.8") == "1" && q380_10 != 0) {
        resultValidate = false;
      }
      if (
        this.getModelValue(model, "380-1.4.8") == "2" ||
        this.getModelValue(model, "380-1.4.8") == "3" ||
        (this.getModelValue(model, "380-1.4.8") == "4" && q380_10 < 0)
      ) {
        resultValidate = false;
      }

      // If field 10 has a values greater than 0 but less than or equal to the FFY error threshold((<=49- calc sheet),
      // then there must be data displayed in section 2 for fields 12 and 13 only
      if (q380_10 > 0 && q380_10 <= 49 && (q380_12 != "" || q380_13 != "")) {
        resultValidate = false;
      }
      // if field 10 value is greater than FFY error threshold (from calcsheet) then section 2 fields 12, 13, 14, 15, 16, 17, 18,
      // and 19 must all have values.

      if (
        q380_10 >= 49 &&
        ((q380_12 = "") ||
          (q380_13 = "") ||
          (q380_14 = "") ||
          (q380_15 = "") ||
          (q380_16 = "") ||
          (q380_17 = "") ||
          (q380_18 = "") ||
          (q380_19 = ""))
      ) {
        resultValidate = false;
      }

      // Section 3 must have values in field 20, 21, 22, 23, (if 23 is code 1 then leave 24 blank, if 23 has code 2 or code 3,
      //   there must be a value in 24), 25, 26, 27, 28, 29, 30, 31, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43,
      //    44a (if 44a is code 1, then 45 must be blank, otherwise a code must be in 45)
      if (
        (q380_20 = "") ||
        (q380_21 = "") ||
        (q380_22 = "") ||
        (q380_23 = "") ||
        (q380_24 = "") ||
        (q380_25 = "") ||
        (q380_26 = "") ||
        (q380_27 = "") ||
        (q380_28 = "") ||
        (q380_29 = "") ||
        (q380_30 = "") ||
        (q380_31 = "") ||
        (q380_34 = "") ||
        (q380_35 = "") ||
        (q380_36 = "") ||
        (q380_37 = "") ||
        (q380_38 = "") ||
        (q380_39 = "") ||
        (q380_40 = "") ||
        (q380_41 = "") ||
        (q380_42 = "") ||
        (q380_43 = "") ||
        (q380_44a = "")
      ) {
        resultValidate = false;
      }
      // (if 23 is code 1 then leave 24 blank, if 23 has code 2 or code 3, there must be a value in 24)

      if ((q380_23 = 1) && q380_24 != "") {
        resultValidate = false;
      }
      if (((q380_23 = 2) || (q380_23 = 3)) && (q380_24 = "")) {
        resultValidate = false;
      }

      //    44a (if 44a is code 1, then 45 must be blank, otherwise a code must be in 45)
      if ((q380_44a = 1) && q380_45 != "") {
        resultValidate = false;
      }

      // Section 4 - for each row of household members - fields 46 and 47 must always have a value and
      // if 47 is code 1 then 48, 49, 50, 51, 52, 53, 54, 55, 56, and 57 must have values.
      //  If 47 is any other code besides 1 then field 48 is the only required field.

      if (q380_46 != "" && q380_47 != "") {
        resultValidate = false;
      }
      if (
        (q380_47 =
          1 &&
          q380_48 != "" &&
          q380_48 != "" &&
          q380_49 != "" &&
          q380_50 != "" &&
          q380_51 != "" &&
          q380_52 != "" &&
          q380_53 != "" &&
          q380_54 != "" &&
          q380_55 != "" &&
          q380_56 != "" &&
          q380_57 != "")
      ) {
        resultValidate = false;
      }
      if (q380_47 != 1 && q380_48 != "") {
        resultValidate = false;
      }
      // 6 - IF field 35 has a value greater than 0, then field 59 must have data for at least one household member.
      // If there are members displayed in field 59 then income type (field 60) must have a value and (field 61) must have an amount.
      //  So if 60 has a value 61 must also.  Same for 62 and 63, 64 and 65, 66 and 67.

      if (q380_35n > 0 && q380_59 != "") {
        resultValidate = false;
      }

      if (q380_59 != "" && q380_60 != "" && q380_61 != "") {
        resultValidate = false;
      }

      if (q380_59 != "" && q380_62 != "" && q380_63 != "") {
        resultValidate = false;
      }

      if (q380_59 != "" && q380_64 != "" && q380_65 != "") {
        resultValidate = false;
      }

      if (q380_59 != "" && q380_66 != "" && q380_67 != "") {
        resultValidate = false;
      }

      // Section 6 - fields 68, 69, 70, 71 must all have values at all times

      if (q380_68 != "" || q380_69 != "" || q380_70 != "" || q380_71 != "") {
        resultValidate = false;
      }
    }

    //   RULES FOR CODE 2, 3, OR 4 APPEARING  in field 7 of section 1
    // JUST ONE RULE - fields 68, 69, and 70 must have values.
    // The rest of the fields on this schedule will be blank besides 1-7 and 68,69,70.
    if (
      (this.getModelValue(model, "380-1.4.7") == "2" ||
        this.getModelValue(model, "380-1.4.7") == "3" ||
        this.getModelValue(model, "380-1.4.7") == "4") &&
      ((q380_68 = "") || q380_69 != "" || q380_70 != "")
    ) {
      resultValidate = false;
    }

    // If field 8 is code 4, then field 9 must equal field 10 and field 16.  Field 15 must be code 4.

    if (
      this.getModelValue(model, "380-1.4.8") == "4" &&
      this.getModelValue(model, "380-1.4.9") !=
        (this.getModelValue(model, "380-1.4.10") &&
          this.getModelValue(model, "380-1.4.16") &&
          this.getModelValue(model, "380-1.4.15") != "4")
    ) {
      resultValidate = false;
    }

    //2 - Field 9 must have a value greater than 0.
    if (this.getModelValue(model, "380-1.4.7") == "2") {
      let q380_9 = +this.getModelValue(model, "380-1.4.9");
      if (q380_9 < 0) {
        resultValidate = false;
      }
    }

    if (this.getModelValue(model, "380-1.4.7") == "3") {
      if (q380_10 > 0) {
        if (q380_12 != "" && q380_13 != "") {
          resultValidate = false;
        }
      }
      //if field 10 value is greater than FFY error threshold then section 2 fields 12, 13, 14, 15, 16, 17, 18, and 19 must all have values.
      if (q380_10 > 0) {
        if (
          q380_12 != "" &&
          q380_13 != "" &&
          q380_14 != "" &&
          q380_15 != "" &&
          q380_16 != "" &&
          q380_17 != "" &&
          q380_18 != "" &&
          q380_19 != ""
        ) {
          resultValidate = false;
        }
      }
    }
    //4 - section 3 must have values in field 20, 21, 22, 23, 24 (if 23 is 1 then leave 24 blank, if 23 has code 2 or 3, there must be a value in 24),
    // 25, 26, 27, 28, 29, 30, 31, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44a, 44b, 45
    if (this.getModelValue(model, "380-1.4.7") == "4") {
    }

    //5 - for each row of household members fields 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, and 57 must have values.  58 is optionable.
    if (this.getModelValue(model, "380-1.4.7") == "5") {
    }

    //6 - section 5 must have at least 1 member displayed IF field 35 (gross) has a value greater than 0.
    //If there are members displayed here than if the income type has a value, there must also be an amount.
    // So if 60 has a value 61 must also.  Same for 62 and 63, 64 and 65, 66 and 67.
    if (this.getModelValue(model, "380-1.4.7") == "6") {
    }

    if (
      this.getModelValue(model, "380-1.4.7") == "1" &&
      (this.getModelValue(model, "380-1.4.8") == "" ||
        this.getModelValue(model, "380-1.4.9") == "" ||
        this.getModelValue(model, "380-1.4.10") == "")
    ) {
      // alert('Please,enter mandatory')
      resultValidate = false;
    }
    if (
      this.getModelValue(model, "380-1.4.23") == "1" &&
      this.getModelValue(model, "380-1.4.24") != ""
    ) {
      // alert('Please,enter mandatory')
      resultValidate = false;
    }
    if (
      (this.getModelValue(model, "380-1.4.23") == "2" ||
        this.getModelValue(model, "380-1.4.23") == "3") &&
      (this.getModelValue(model, "380-1.4.24") == "" ||
        this.getModelValue(model, "380-1.4.25") == "" ||
        this.getModelValue(model, "380-1.4.26") == "" ||
        this.getModelValue(model, "380-1.4.27") == "" ||
        this.getModelValue(model, "380-1.4.28") == "" ||
        this.getModelValue(model, "380-1.4.29") == "" ||
        this.getModelValue(model, "380-1.4.30") == "" ||
        this.getModelValue(model, "380-1.4.31") == "" ||
        this.getModelValue(model, "380-1.4.34") == "" ||
        this.getModelValue(model, "380-1.4.35") == "" ||
        this.getModelValue(model, "380-1.4.36") == "" ||
        this.getModelValue(model, "380-1.4.37") == "" ||
        this.getModelValue(model, "380-1.4.38") == "" ||
        this.getModelValue(model, "380-1.4.39") == "" ||
        this.getModelValue(model, "380-1.4.40") == "" ||
        this.getModelValue(model, "380-1.4.41") == "" ||
        this.getModelValue(model, "380-1.4.42") == "" ||
        this.getModelValue(model, "380-1.4.43") == "" ||
        this.getModelValue(model, "380-1.4.44a") == "" ||
        this.getModelValue(model, "380-1.4.44b") == "" ||
        this.getModelValue(model, "380-1.4.45") == "")
    ) {
      // alert('Please,enter mandatory')
      resultValidate = false;
    }
    if (
      this.getModelValue(model, "380-1.4.47") == "" &&
      this.getModelValue(model, "380-1.4.48") == "" &&
      this.getModelValue(model, "380-1.4.49") == "" &&
      this.getModelValue(model, "380-1.4.50") == "" &&
      this.getModelValue(model, "380-1.4.51") == "" &&
      this.getModelValue(model, "380-1.4.52") == "" &&
      this.getModelValue(model, "380-1.4.53") == "" &&
      this.getModelValue(model, "380-1.4.54") == "" &&
      this.getModelValue(model, "380-1.4.55") == "" &&
      this.getModelValue(model, "380-1.4.56") == "" &&
      this.getModelValue(model, "380-1.4.57") == ""
    ) {
      // alert('Please,enter mandatory')
      resultValidate = false;
    }
    if (
      (this.getModelValue(model, "380-1.4.60") == "" &&
        this.getModelValue(model, "380-1.4.61") != "") ||
      (this.getModelValue(model, "380-1.4.60") != "" &&
        this.getModelValue(model, "380-1.4.61") == "")
    ) {
      resultValidate = false;
    }
    if (
      (this.getModelValue(model, "380-1.4.68") == "" &&
        this.getModelValue(model, "380-1.4.69") == "") ||
      (this.getModelValue(model, "380-1.4.70") == "" &&
        this.getModelValue(model, "380-1.4.71") == "")
    ) {
      resultValidate = false;
    }

    if (
      this.getModelValue(model, "380-1.4.8") == "2" ||
      this.getModelValue(model, "380-1.4.8") == "3" ||
      this.getModelValue(model, "380-1.4.8") == "4"
    ) {
      if (
        this.getModelValue(model, "380-1.4.68") == "" ||
        this.getModelValue(model, "380-1.4.69") == "" ||
        this.getModelValue(model, "380-1.4.70") == ""
      ) {
      }
    }

    if (
      this.getModelValue(model, "380-1.4.7") == "2" ||
      this.getModelValue(model, "380-1.4.7") == "3" ||
      (this.getModelValue(model, "380-1.4.7") == "4" &&
        (this.getModelValue(model, "380-1.4.68") == "" ||
          this.getModelValue(model, "380-1.4.69") == "" ||
          this.getModelValue(model, "380-1.4.70") == ""))
    ) {
      // alert('Please,enter mandatory')
      resultValidate = false;
    }
    if (
      this.getModelValue(model, "380-1.4.8") == "2" ||
      this.getModelValue(model, "380-1.4.8") == "3" ||
      (this.getModelValue(model, "380-1.4.8") == "4" &&
        (this.getModelValue(model, "380-1.4.12") == "" ||
          this.getModelValue(model, "380-1.4.13") == ""))
    ) {
      // alert('Please,enter mandatory')
      resultValidate = false;
    }

    if (resultValidate == false) {
      alert("Check for a correct Schedule before generating PDF");
    }
  }
  getModelValue(model: any, qstCd: any) {
    let value;
    let valueArr = model.fields.filter((v) => v.name == qstCd);

    if (valueArr.length > 0) {
      value = valueArr[0].value;
    } else {
      value = "";
    }
    return value;
  }

  calcSheet() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      // pageType: type,
      // individualId: this.currentIndividual,
      // parentPageType: this.screenType,
    };

    const dialogRef = this.dialog.open(GoogleSheetComponent, dialogConfig);
    dialogRef.updateSize("50%", "60%");
    dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        console.log("afterClose", val);
      }
    });
  }

  fileUpload() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      reviewNum: this.constantsService.ReviewInfo.rvwNum,
      fnsNames: true,
      // pageType: type,
      // individualId: this.currentIndividual,
      // parentPageType: this.screenType,
    };

    const dialogRef = this.dialog.open(FileUploadComponent, dialogConfig);
    dialogRef.updateSize("75%", "85%");
    dialogRef.afterClosed().subscribe((val) => {
      if (val) {
        console.log("afterClose", val);
      }
    });
  }
  fileScan() {
    this.router.navigate(["/qualityControl/fileScan"]);
  }
}

@Component({
  selector: "questionary-dialog",
  templateUrl: "questionary-dialog.html",
})
export class QuestionaryDialog implements OnInit {
  @ViewChild(DynamicFormComponent, { static: false })
  form: DynamicFormComponent;
  screenType: any;
  pageDataConfig: PageConfig[] = [];
  currentIndividual: any;
  pgDialog: any;
  checkMandate: boolean = false;
  checkMandateHH: boolean = false;
  showBackPages = [
    "311.5",
    "311.6",
    "311.7",
    "311.8",
    "311.9",
    "311.10",
    "312.4",
    "312.5",
    "312.6",
    "312.7",
    "312.8",
    "312.9",
  ];
  showNextPages = [
    "311.4",
    "311.5",
    "311.6",
    "311.7",
    "311.8",
    "311.9",
    "312.3",
    "312.4",
    "312.5",
    "312.6",
    "312.7",
    "312.8",
  ];
  myColor: string;
  constructor(
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    public appUtilService: AppUtilService,
    public navigationService: NavigationService,
    private customValidatorsService: CustomValidatorsService,
    private _snackBar: MatSnackBar
  ) {
    this.pgDialog = dialog;
    this.openDialog = this.openDialog.bind(this);
    this.screenType = this.data.pageType;

    this.currentIndividual = this.data.individualId;

    if (this.data.pageDataConfig) {
      this.preparePageData(this.data.pageDataConfig);
    } else {
      this.pageDataConfig.push({ pageType: this.screenType, fieldConfig: [] });
      this.getPageData();
    }
  }

  ngOnInit() {}

  getPageData() {
    const caseId: any = "";
    const invId: any = this.currentIndividual; // this.memberForm.get('individualId').value;
    const queryParams: any[] = [];
    if (
      this.data.parentPageType &&
      (this.screenType === "888" ||
        this.screenType === "881" ||
        this.screenType === "500119")
    ) {
      // for Error screen and QCForm Covering sheet
      queryParams.push(this.data.parentPageType + "." + this.screenType); //screen type
    } else if (
      this.data.parentPageType &&
      this.data.parentParentPageType &&
      this.screenType === "887"
    ) {
      queryParams.push(this.data.parentParentPageType + "." + this.screenType); //screen type
    } else {
      queryParams.push(this.screenType); //screen type
    }

    if (invId) {
      queryParams.push(invId);
    }

    if (
      this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum
    ) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }

    if (
      this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwType
    ) {
      if (!this.screenType.startsWith("5001")) {
        queryParams.push(this.constantsService.ReviewInfo.rvwType);
      } else {
        queryParams.push("QCForm");
      }
    } else {
      queryParams.push("Evaluation");
    }

    this.apiManager
      .fetchData(
        "getReviewQ",
        undefined,
        ApiManager.GET,
        undefined,
        undefined,
        undefined,
        queryParams
      )
      .subscribe((res) => {
        this.preparePageData(res);
      });
  }

  preparePageData(res) {
    this.pageDataConfig = [];
    this.pageDataConfig.push(res);
    if (res && res.screenLvlLookup) {
      res.screenLvlLookup.map((lkp) => {
        if (!this.constantsService.lookUpValues) {
          this.constantsService.lookUpValues = [];
        }
        this.constantsService.lookUpValues.push(lkp);
      });
    }

    if (
      this.pageDataConfig &&
      this.pageDataConfig.length > 0 &&
      this.pageDataConfig[0].pageType == null
    ) {
      this.pageDataConfig[0].pageType = this.screenType;
    }

    if (
      this.data.parentPageType &&
      (this.screenType === "888" ||
        this.screenType === "887" ||
        this.screenType === "881" ||
        this.screenType === "500119")
    ) {
      // for Error screen and QCForm Covering sheet
      this.pageDataConfig[0].pageType = this.screenType;
      this.pageDataConfig[0].fieldConfig.map((fld) => {
        fld.name = fld.name.replace(
          (this.screenType === "887"
            ? this.data.parentParentPageType
            : this.data.parentPageType) + ".",
          ""
        );
      });
    }

    if (this.data.groupId !== -1) {
      // to filter only record group id if page inside array
      this.pageDataConfig[0].fieldConfig =
        this.pageDataConfig[0].fieldConfig.filter(
          (fld) => fld.recordGroupId === this.data.groupId
        );
    }

    this.pageDataConfig[0].fieldConfig.map((fld) => {
      if (fld.qtype === "checkbox") {
        fld.value = fld.value === "true" ? true : null;
      }
      if (
        fld.qmode === "OP" &&
        (fld.qtype === "select" || fld.lookupName) &&
        this.navigationService.appCd !== "QC"
      ) {
        if (fld) {
          if (!fld.validations) {
            fld.validations = [];
          }
          fld.validations.push({
            name: "answerNO",
            // validator: Validators.pattern(
            //   "\b(N|NO|No|no)\b"
            // ),
            validator: this.customValidatorsService.showErrorIfAnswerNO,
            message: "Error Case",
          });
        }
      }

      if (fld.qtype === "date") {
        if (fld) {
          if (!fld.validations) {
            fld.validations = [];
          }
          fld.validations.push({
            name: "invalidDate",
            // validator: Validators.pattern(
            //   "\b(N|NO|No|no)\b"
            // ),
            validator: this.customValidatorsService.validDate,
            message: "Invalid Date",
          });
        }
      }
    });
  }

  reset() {
    const caseId: any = "";
    const invId: any = this.currentIndividual; // this.memberForm.get('individualId').value;
    const queryParams: any[] = [];
    if (
      this.data.parentPageType &&
      (this.screenType === "888" ||
        this.screenType === "881" ||
        this.screenType === "500119")
    ) {
      // for Error screen and QCForm Covering sheet
      queryParams.push(this.data.parentPageType + "." + this.screenType); //screen type
    } else if (
      this.data.parentPageType &&
      this.data.parentParentPageType &&
      this.screenType === "887"
    ) {
      queryParams.push(this.data.parentParentPageType + "." + this.screenType); //screen type
    } else {
      queryParams.push(this.screenType); //screen type
    }

    if (invId) {
      queryParams.push(invId);
    }

    if (
      this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum
    ) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }

    if (
      this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwType
    ) {
      if (!this.screenType.startsWith("5001")) {
        queryParams.push(this.constantsService.ReviewInfo.rvwType);
      } else {
        queryParams.push("QCForm");
      }
    } else {
      queryParams.push("Evaluation");
    }

    let apiURL = "getResetReviewQByIndex";

    if (
      this.screenType === "150.8" ||
      this.screenType === "350.2" ||
      this.screenType === "323.2"
    ) {
      apiURL = "getResetReviewQ";
    } else {
      queryParams.push(this.data.groupId);
    }

    this.apiManager
      .fetchData(
        apiURL,
        undefined,
        ApiManager.GET,
        undefined,
        undefined,
        undefined,
        queryParams
      )
      .subscribe((res) => {
        if (
          (this.screenType === "68a" || this.screenType === "68.1") &&
          this.constantsService.in_68_Code
        ) {
          this.constantsService.in_68_Code = null;
        }
        this.preparePageData(res);
      });
  }

  save(model: any, buttonId: any) {
    console.log(model);
    this.checkMandate = this.constantsService.checkMandatoryForDisable(
      model,
      this.screenType,
      this.data
    );
    if (this.checkMandate && this.screenType === "150.8") {
      this._snackBar.open(
        'Please complete question 15 "List of Names"',
        undefined,
        {
          duration: 2000,
        }
      );
      return;
    } else if (this.checkMandate && this.screenType !== "150.8") {
      this._snackBar.open("Please enter the mandatory fields.", undefined, {
        duration: 2000,
      });
      return;
    }

    const distinctRecordGroup = [
      ...new Set(
        this.pageDataConfig[0].fieldConfig
          .filter((rGId) => rGId.recordGroupId > -1)
          .map((rG) => rG.recordGroupId)
      ),
    ];
    if (this.pageDataConfig && this.pageDataConfig[0].fieldConfig) {
      this.pageDataConfig[0].fieldConfig.map((quest) => {
        quest.value = model.value[quest.name];
        if (quest.qtype.toLowerCase() === "date" && quest.value) {
          quest.value = this.appUtilService.changeDateFormatYYYYMMDD(
            quest.value
          );
        } else if (quest.qtype.toLowerCase() === "checkbox") {
          if (
            quest.name === "777" &&
            !quest.value &&
            this.navigationService.getSelectedNavHead() !== "parentCaseReview"
          ) {
            quest.value = true; // set page complete checkbox true
          } else {
            quest.value = quest.value === true ? true : null;
          }
        }

        if (
          model.form.get("FormArray") &&
          model.form.get("FormArray").value.length > 0
        ) {
          for (let i = 0; i < model.form.get("FormArray").value.length; i++) {
            const val = model.form.get("FormArray").value[i];
            Object.keys(val).map((fld) => {
              const chkRGrpId =
                this.screenType === "150.8" ||
                this.screenType === "323.2" ||
                this.screenType === "350.2" ||
                distinctRecordGroup.length === 1
                  ? distinctRecordGroup[i] === quest.recordGroupId
                  : distinctRecordGroup[this.data.groupId] ===
                    quest.recordGroupId;
              if (fld === quest.name && chkRGrpId) {
                quest.dirtyStatus = "update";
                quest.value = val[quest.name];
                if (
                  (quest.qtype.toLowerCase() === "date" ||
                    moment.isMoment(quest.value)) &&
                  quest.value
                ) {
                  quest.value = this.appUtilService.changeDateFormatYYYYMMDD(
                    quest.value
                  );
                } else if (
                  quest.qtype.toLowerCase() === "checkbox" ||
                  (quest.qtype.toLocaleLowerCase() === "grid" &&
                    typeof quest.value === "boolean")
                ) {
                  if (
                    quest.name === "777" &&
                    !quest.value &&
                    this.navigationService.getSelectedNavHead() !==
                      "parentCaseReview"
                  ) {
                    quest.value = true; // set page complete checkbox true
                  } else {
                    quest.value = quest.value === true ? true : null;
                  }
                }
              }
            });
          }
        } else if (model.form.controls[quest.name].dirty) {
          quest.dirtyStatus = "update";
        }
      });

      if (
        this.data.parentPageType &&
        (this.screenType === "888" ||
          this.screenType === "881" ||
          this.screenType === "500119")
      ) {
        // for Error screen and QCForm Covering sheet
        this.pageDataConfig[0].pageType =
          this.data.parentPageType + "." + this.screenType;
        this.pageDataConfig[0].fieldConfig.map((fld) => {
          fld.name = this.data.parentPageType + "." + fld.name;
        });
      }

      delete this.pageDataConfig[0].screenLvlLookup;

      const caseId: any = "";
      // const invId: any = this.memberForm.get('individualId').value;
      const queryParams: any[] = [];
      // queryParams.push(caseId);
      // queryParams.push(invId);
      if (
        this.data.parentPageType &&
        (this.screenType === "888" ||
          this.screenType === "881" ||
          this.screenType === "500119")
      ) {
        // for Error screen and QCForm Covering sheet
        queryParams.push(this.data.parentPageType + "." + this.screenType); //screen type
      } else {
        queryParams.push(this.screenType); //screen type
      }

      if (
        this.constantsService.ReviewInfo &&
        this.constantsService.ReviewInfo.rvwType
      ) {
        if (!this.screenType.startsWith("5001")) {
          queryParams.push(this.constantsService.ReviewInfo.rvwType);
        } else {
          queryParams.push("QCForm");
        }
      } else {
        queryParams.push("Evaluation");
      }
      queryParams.push(buttonId); //buttonId

      this.apiManager
        .fetchData(
          "saveReviewQ",
          undefined,
          ApiManager.POST,
          this.pageDataConfig[0],
          undefined,
          undefined,
          queryParams
        )
        .subscribe((res) => {
          return true;
        });
    }

    return true;
  }

  closeDialog(type) {
    let is881Val;
    if (this.screenType === "881") {
      this.pageDataConfig[0].fieldConfig.map((quest) => {
        if (quest.name.indexOf("881.2") > 0) {
          is881Val = quest.value;
        }
      });
    }
    const dgData = {
      pageType: type,
      individualId: this.currentIndividual,
      parentPageType: this.data.parentParentPageType,
      parentParentPageType: null,
      groupId: this.data.groupId,
      is881Value: is881Val,
      childType: this.screenType,
    };
    if (!this.checkMandate) {
      this.dialogRef.close(dgData);
    }
  }

  openDialog(type) {
    this.save(this.form, "0");
    const dgData = {
      pageType: type,
      individualId: this.currentIndividual,
      parentPageType: this.screenType,
      parentParentPageType: this.data.parentPageType,
      groupId: this.data.groupId,
    };

    setTimeout(() => {
      this.dialogRef.close(dgData);
    }, 200);
    return;
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.data = {
    //   pageType: type,
    //   individualId: this.currentIndividual,
    //   parentPageType: this.screenType,
    //   parentParentPageType: this.data.parentPageType,
    // };

    // const dialogRef = this.pgDialog.open(QuestionaryDialog, dialogConfig);
    // dialogRef.updateSize('75%', '85%');
    // dialogRef.afterClosed().subscribe(val => {
    //   if (val) {
    //     console.log('afterClose', val);

    //   }

    // });
  }

  openAnotherDialog(type, idx: any = -1, pData: any = null) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    let validateQ;
    if (this.screenType === "150.1") {
      validateQ = this.form.form.controls["150.1.43"].value;
    }

    if (pData) {
      dialogConfig.data = pData;
    } else {
      dialogConfig.data = {
        pageType: type,
        individualId: this.currentIndividual,
        parentPageType: this.screenType,
        groupId: idx,
        parentParentPageType: null,
        validateQVal: validateQ,
      };
    }

    const caseId: any = "";
    const invId: any = this.currentIndividual; // this.memberForm.get('individualId').value;
    const queryParams: any[] = [];
    if (
      dialogConfig.data.parentPageType &&
      (dialogConfig.data.pageType === "888" ||
        dialogConfig.data.pageType === "881" ||
        dialogConfig.data.pageType === "500119")
    ) {
      // for Error screen and QCForm Covering sheet
      queryParams.push(
        dialogConfig.data.parentPageType + "." + dialogConfig.data.pageType
      ); //screen type
    } else if (
      dialogConfig.data.parentPageType &&
      dialogConfig.data.parentParentPageType &&
      dialogConfig.data.pageType === "887"
    ) {
      queryParams.push(
        dialogConfig.data.parentParentPageType +
          "." +
          dialogConfig.data.pageType
      ); //screen type
    } else {
      queryParams.push(dialogConfig.data.pageType); //screen type
    }

    if (invId) {
      queryParams.push(invId);
    }

    if (
      this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum
    ) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }

    if (
      this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwType
    ) {
      if (!this.screenType.startsWith("5001")) {
        queryParams.push(this.constantsService.ReviewInfo.rvwType);
      } else {
        queryParams.push("QCForm");
      }
    } else {
      queryParams.push("Evaluation");
    }

    this.apiManager
      .fetchData(
        "getReviewQ",
        undefined,
        ApiManager.GET,
        undefined,
        undefined,
        undefined,
        queryParams
      )
      .subscribe((res) => {
        dialogConfig.data.pageDataConfig = res;
        const dialogRef = this.dialog.open(QuestionaryDialog, dialogConfig);
        dialogRef.updateSize("75%", "85%");
        dialogRef.afterClosed().subscribe((val) => {
          // if (val) {
          //   console.log('afterClose', val);
          //   if (val.pageType && val.pageType !== this.screenType ) {
          //     this.openDialog(val.pageType, -1, val);
          //   }
          //   if ( this.screenType === '68b' && this.constantsService.in_68_Code && this.constantsService.caseHomeData.typOfActn !== "2") {
          //     this.form.form.controls["68b.2"].patchValue(this.constantsService.in_68_Code);
          //   }
          // }
        });
      });
  }

  showBack() {
    return this.showBackPages.indexOf(this.data.pageType) > -1;
  }

  showNext() {
    return (
      this.showNextPages.indexOf(this.data.pageType) > -1 &&
      this.data.parentPageType != "312.1"
    );
  }

  back() {
    this.closeDialog(null);
    const previousPage =
      this.data.pageType.split(".")[0] +
      "." +
      (Number(this.data.pageType.split(".")[1]) - 1);
    this.openAnotherDialog(previousPage, this.data.groupId);
  }

  next() {
    this.closeDialog(null);
    const nextPage =
      this.data.pageType.split(".")[0] +
      "." +
      (Number(this.data.pageType.split(".")[1]) + 1);
    this.openAnotherDialog(nextPage, this.data.groupId);
  }
}
