import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-whereabouts-unknowns',
  templateUrl: './whereabouts-unknowns.component.html',
  styleUrls: ['./whereabouts-unknowns.component.css']
})
export class WhereaboutsUnknownsComponent extends BaseComponent implements OnInit {
 ngOnInit() {
    super.ngOnInit();
  }

}



