import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-combined-resources',
  templateUrl: './combined-resources.component.html',
  styleUrls: ['./combined-resources.component.css']
})
export class CombinedResourcesComponent extends BaseComponent implements OnInit {
  isNotEditable: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    // this.calcTotals();

  }
  calcTotals() {
    let all_sum_value = this.getControlValue('225.1');
    let all_sum_value_array = all_sum_value.split('~');
    this.setControlValue('225.2a', all_sum_value_array[0]);
    this.setControlValue('225.2b', all_sum_value_array[1]);
    this.setControlValue('225.2c', all_sum_value_array[2]);
    this.setControlValue('225.2d', all_sum_value_array[3]);
    this.setControlValue('225.2e', all_sum_value_array[4]);
    const total = (+all_sum_value_array[0]) + (+all_sum_value_array[1]) + (+all_sum_value_array[2]) + (+all_sum_value_array[3]) + (+all_sum_value_array[4])
    this.setControlValue('225.2f', total);

  }

}
