import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ConstantsService } from '../../common/constants.service';
import { ApiManager } from '../../common/api-manager.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DataShareService } from '../../common/data-share.service';
import { NavigationService } from '../../common/navigation.service';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormArray } from '@angular/forms';
import { Moment } from 'moment';
import moment from 'moment';


@Component({
  selector: 'app-review-summary',
  templateUrl: './review-summary.component.html',
  styleUrls: ['./review-summary.component.css']
})
export class ReviewSummaryComponent implements OnInit {

  screenType: string ;
  householdMembers: any;
  reviewSubmitForm: FormGroup;
  reviewSubmitData: any;
  minDate: Moment;
  maxDate: Moment;
  isNotEditable = false;
  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    public constantsService: ConstantsService,
    private apiManager: ApiManager,
    private shareService: DataShareService,
    public navigationService: NavigationService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
      if (this.activeRoute.snapshot.data.pageData && this.activeRoute.snapshot.data.pageData.memberList) {
        this.householdMembers = this.activeRoute.snapshot.data.pageData.memberList;
      }

      this.setDefaultIndividual();

    //   this.activeRoute.queryParams.subscribe(queryParams => {
    //     if (queryParams['type']) {
    //       this.screenType =  queryParams['type'];
    //     }
    // });
   }

  ngOnInit() {
    this.buildForm();
    this.getPageData();
    this.minDate = moment().subtract(2, 'days');
    this.maxDate = moment();
  }

  
  get currentIndividual() {
    return this.shareService.getDataFromMap('currentIndividual');
  }

  set currentIndividual(id) {
    this.shareService.addDataToMap('currentIndividual', id);
  }

  setDefaultIndividual() {
    if (this.householdMembers) {
      this.householdMembers.map(hhm => {
        if (this.currentIndividual === undefined  && hhm.headOfHsehldInd === 'Y') {
          this.currentIndividual = hhm.indvdlId;
        }
      });
    }
  }

  buildForm() {
    if(this.constantsService.isAnalystUser()) {
      this.reviewSubmitForm = this.fb.group({
        rvwStatus: [''],
        rvwDate: [''],
        reRvwStatus: [''],
        reSubmitDate: [''],
        dropcase:[''],
        clarifyList: this.fb.array([
        ])
      });
    } else {
      this.reviewSubmitForm = this.fb.group({
        rvwStatus: [''],
        rvwDate: [''],
        dropcase: [''],
        clarifyList: this.fb.array([
          // this.fb.group({
          //   dirtyStatus: ['INSERT'],
          //   qaClrfyId: [''],
          //   clrfy: [''],
          //   rvwSts: ['']
          // })
        ])
      });
    }  
  }
  
  getPageData() {
    const caseId: any = '';
    const invId: any = this.currentIndividual; // this.memberForm.get('individualId').value;
    const queryParams: any[] = [];
    
    if (invId) {
      queryParams.push(invId);
    }
    queryParams.push('All'); //screen type

    let appCd: string = '';
    appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    let rvwNum = this.constantsService.ReviewInfo.rvwNum;
    queryParams.push(appCd);
    queryParams.push(rvwNum);


    this.apiManager.fetchData('getClarify', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
    .subscribe(
      res => {
        this.reviewSubmitData = res;
        if ( this.reviewSubmitData && this.reviewSubmitData.length === 0 ) {
          this.reviewSubmitData.push( {
            clarifyList: [
              {
                dirtyStatus: ['INSERT'],
                qaClrfyId: '',
                clrfy: '',
                scrnId: '',
              }
            ]
          });
        }
        // this.reviewSubmitForm.patchValue(this.reviewSubmitData);
        if (this.constantsService.ReviewInfo && this.constantsService.ReviewInfo.rvwSts) {
          this.reviewSubmitForm.get('rvwStatus').patchValue(this.constantsService.ReviewInfo.rvwSts);
          this.reviewSubmitForm.get('rvwDate').patchValue(this.constantsService.ReviewInfo.rvwStDt);
          this.reviewSubmitForm.get('dropcase').patchValue(this.constantsService.ReviewInfo.dropcase);
        }
        
        this.reviewSubmitData.map(item => {
          if( !item.scrnId ) {
          (this.reviewSubmitForm.get('clarifyList') as FormArray).push(
            this.fb.group({
              dirtyStatus: item.dirtyStatus,
              qaClrfyId: item.qaClrfyId,
              clrfy: item.clrfy,
              scrnId: item.scrnId,
            })
          )
        }
      });
      }
    );


  }

  save(model) {
  if(((model.value.rvwStatus != 'RR' && model.value.rvwStatus != 'ST') || (model.value.rvwStatus == 'RR' && model.value.reRvwStatus != 'ST')) && this.constantsService.isAnalystUser()){
      alert("Complete the review log before submitting");
  }else{
    const caseId: any = '';
    const invId: any = this.currentIndividual; // this.memberForm.get('individualId').value;
    let appCd: string = '';
    let drtySts: string = '';
    let rvwSts: string = model.value.rvwStatus;
    let rvwDate = moment(model.value.rvwDate);
    let dropcase:boolean = model.value.dropcase;
    let reRvwStatus: string = model.value.reRvwStatus;
    let reSubmitDate = model.value.reSubmitDate;
    const queryParams: any[] = [];

    
    
    appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    

    let clarifyObj: any = [];
    model.value.clarifyList.map(clfy =>{
      clarifyObj.push( {
        dirtyStatus : model.dirty && clfy.qaClrfyId  ? 'UPDATE' : 'INSERT' ,
        qaClrfyId : clfy.qaClrfyId,
        clrfy : clfy.clrfy,
        indvdlId: invId,
        scrnId: clfy.scrnId,
        rvwNum: this.constantsService.ReviewInfo.rvwNum ,
        appType: appCd
  
      });
    } );
   


    this.apiManager.fetchData('saveClarify', undefined, ApiManager.POST, clarifyObj, undefined, undefined, queryParams)
    .subscribe(
      res => {
        
      }
    );

    this.saveReviewStatus(rvwSts, rvwDate, reRvwStatus, reSubmitDate,dropcase);
    }
  }

  saveReviewStatus(status, rvwDate, reRvwStatus, reSubmitDate,dropcase) {
    const queryParams: any[] = [];
    const appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    if ( !this.constantsService.ReviewInfo.appType ) {
      this.constantsService.ReviewInfo.appType =  this.constantsService.getAppCd(this.navigationService.appCd) ;
    }
    queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    queryParams.push(status);
    queryParams.push(appCd);
    const body = {
      'reRvwStatus': reRvwStatus,
      'reSubmitDate': reSubmitDate,
      'rvwDate': rvwDate,
      'dropcase': dropcase
    };
    this.apiManager.fetchData('saveReviewStatus', undefined, ApiManager.POST, body, undefined, undefined, queryParams)
    .subscribe(
      res => {
          let rtrnType = res;
          if(rtrnType == '1'){
            alert("Complete the review log before submitting");
        } else if(rtrnType == '2'){
          alert("Correct the submit date.");
        } else{
        this.constantsService.ReviewInfo.rvwSts = status;
        this.constantsService.ReviewInfo.rvwStsDesc = this.constantsService.getReviewStatusDescByCd(status);
        
        this.router.navigate([this.constantsService.getNavigateToRvwList()]);
        // this.cdr.detectChanges();
      }});
  }
  

  back() {
    this.navigationService.navigatePage(this.router.url, 'back');
  }

  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }


  openProcessLogDialog() {
    this.constantsService.rvwlog_rvwid = this.constantsService.ReviewInfo.rvwNum ;
    // this.router.navigate(['/qualityControl/fspReviewlog']);
    if (this.constantsService.ReviewInfo.rvwType ===  'FSPPositive' || this.constantsService.ReviewInfo.rvwType ===  'ESAPPositive' ) {
      this.router.navigate(['/qualityControl/fspReviewlog']);
    }
    else{
      this.router.navigate(['/qualityControl/fsNegReviewlog'])
    }
  
  }
}
