import { Component, OnInit, ViewChild, ElementRef, EventEmitter, Output, Inject } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Router } from '@angular/router';
import { ConstantsService } from '../../common/constants.service';
import { DataShareService } from '../../common/data-share.service';
import { AppUtilService } from '../../common/app.util.service';
import { ApiManager } from '../../common/api-manager.service';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver/FileSaver';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationService } from '../../common/navigation.service';
import { PersonName } from 'src/app/model/person-name.class';
import { DocumentSelectionInfo } from 'src/app/model/api/document-selection.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventEmitterService } from 'src/app/common/event.emitter.service';
import { constants } from 'os';
// import { LookupManagerService } from 'src/app/common/lookup-manager.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  @ViewChild("fileUploadElement",{static:false}) fileUploadElement: any;
  @ViewChild('documentType',{static:false}) documentType: any;
  @ViewChild('fileInput',{static:false}) inputEl: ElementRef;
  @Output() uploaded: EventEmitter<any> = new EventEmitter();
  hasError: boolean = null;
  uploadProgress: any;
  uploadedFiles: any[] = [];
  documentArrayRefrence: any = [];
  fileUploading: Boolean = false;
  docOptionsUpdated = false;
  fileUploaded: Boolean = false;
  filesToUpload = [];
  docs = [];
  noOfDocs;
  docCount = 0;
  docResource;
  queryParams: string[] = [];
  filesToDownload: DocumentSelectionInfo[] = [];
  docMetadata = [];
  caseId: string = null;
  documentOptionsName: string = '';
  readOnly = false;
  totalCount: number = 0;
  individualId: string = null;
  verificationName: string = null;
  memberSelected: string;
  maxfileCount: number = undefined;
  disableAddDocumentButton: boolean = false;
  docInfo: FormGroup;
  lkptyp = null;
  lkpType = null;
  lookupValues: any[] = [];
  docOptions:any =  new Map([
    ['Workbook', 'Workbook'],
    ['Other', 'Other']
    
  ]);
  subtype = null;
  subtypeSearch = null;
  searchDocEvent: any;
  subTypeSearchCd = null;
  globalCategoryObj = {};
  selectedScreen: string = "";
  constructor(
    private fb: FormBuilder,
    public constantsService: ConstantsService,
    private _utilService: AppUtilService,
    private apiManager: ApiManager,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private shareService: DataShareService,
    // public lookups: LookupManagerService,
    // private printService: PrintService,
    public navigationService: NavigationService,
    public dialog: MatDialog,
    private spinnerService: NgxSpinnerService,
    public emitter: EventEmitterService,
  
  ) { 
    this.documentOptionsName = '';
  }

  ngOnInit() {
    // this.lookups.getLookUpValues(['Documents'], 'en_US')
    //   .then(res => {
    //     this.lookupValues = res;
    //   }).catch(err => {
    //     console.log(err);
    //   });
    let validDocOptions = [];
    // this.docOptions.map(optn => {
    //   let subOptns = this.constantsService.getDropDownFromLookup(optn.toString());
    //   if (subOptns.length == 0) {
    //     console.log(`Document type ${optn} does not have subtypes, so ignoring`);
    //     // alert(`Document type ${optn} does not have subtypes, so ignoring`);
    //   }
    //   else {
    //     this.globalCategoryObj[optn] = subOptns;
    //   }
    // });
    for (let key in this.globalCategoryObj) {
      validDocOptions.push(key);
    }
    ;
    this.docOptions = validDocOptions;
    this.constructDocumentForm();
  }
  
  matchString(source, input) {
    let match = true;
    for (let i = 0; (i < source.length && source[i] != '%'); i++) {
      if (input[i] !== source[i]) {
        match = false;
      }
    }
    return match;
  }

  checkUnsupportedDoc(event) {
    let unsupportedDoc = false;
    for (let file of event.target.files) {
      if (file.type === 'text/javascript' || this.matchString('audio%', file.type) || this.matchString('video%', file.type)
        || file.type === 'application/x-msdownload') {
        unsupportedDoc = true;
        break;
      }
    }
    return unsupportedDoc;
  }
  onFileChange(event) {
    let docType = document.getElementById("docTypeElem")
    let unsupportedDocExist = this.checkUnsupportedDoc(event);
    this.filesToUpload = [];
    if (event.target.files.length) {
      let idx = 1;
      for (let file of event.target.files) {

        const entry: any = {
          id: null,
          type: docType,
          fileName: file.name,
          index: idx++,
          file: file
        }
        this.filesToUpload.push(entry);
      }
    }
    if (unsupportedDocExist) {
      alert("You are uploading an unsupported Document(.js/.exe/.mov/.mp4/)");
      return null;
    }


    let inputVal = (<HTMLInputElement>document.getElementById("fileInput"));
    setTimeout(() => {
      inputVal.value = "";
    }, 1000);
    // this.fileUpload();
  }

  save() {
    this.fileUpload();
  }
  reloadSelections(params) {
    this.docResource.query(params).then(cars => this.docs = this.docMetadata);
  }
  getDocSelectionInfo() {
    this.docs = [];
    this.filesToDownload = [];
    this.docMetadata.map(item => {
      let tempSvc = new DocumentSelectionInfo(item.docId, item.docName, false, item.documentTypeCd, item.createdBy, item.createdDt, item.updatedBy, item.updatedDt);
      this.filesToDownload.push(tempSvc);
    });
    this.docs = this.filesToDownload;
    if (this.docResource) this.docResource.count().then(count => this.docCount = count);
    return this.filesToDownload;
  }
  constructDocumentForm() {
    this.docInfo = this.fb.group({
      docType: [this.documentOptionsName],
      docSubtype: [null],
      element_num: ['']
    })
  }
  getDocumentsForScreen() {
    let reqBody = {
      "caseId": this.queryParams[0],
      "individualId": this.queryParams[1],
      "docLocation": this.queryParams[2]
    };
    this.apiManager.fetchData(
      'getDocumentsForScreen', undefined, ApiManager.POST, reqBody).catch((err: Response | any) => {
        this._utilService.hideLoader();
        alert("error fetching documents" + err);
        return Observable.throw(err);
      }).subscribe(res => {
        this._utilService.hideLoader();
        this.docMetadata = res;
        this.noOfDocs = this.docMetadata.length;
        this.getDocSelectionInfo();
      });
  }
  
  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  }

  async fileUpload() {
    await this.asyncForEach(this.filesToUpload, async (file) => {
      console.log("file", file);
      await this.singleFileUpload(file);
    })
  }

  async singleFileUpload(file) {
    console.log("FILE UPLOADING");
    const uploadFile = file;
    this.hasError = false;
    
    if (!this.hasError) {
      let fileReader = new FileReader();
      this.fileUploading = true;
      this.fileUploaded = false;
      // this.apiManager.fileUploadtProgress$.subscribe(data => {
      //   this.uploadProgress = data;
      // }
      // );
      fileReader.readAsArrayBuffer(uploadFile.file);
      let zipFile: JSZip = new JSZip();
      let that = this;

      fileReader.onload = async function () {
        zipFile.file(uploadFile.docName, fileReader.result);
        zipFile.generateAsync({
          type: 'blob',
          compression: 'DEFLATE',
          compressionOptions: {
            level: 9
          },
          mimeType: uploadFile.type
        }).then(async (blob) => {
          // const file: File = new File([blob], uploadFile.docName, { type: 'application/zip' });
          await that.uploadDocument(uploadFile.file, uploadFile);
          
        });
      }
    }
  }

  delay() {
    return new Promise(resolve => setTimeout(resolve, 300));
  }

  async delayedLog(params: string) {
    await this.delay();
    console.log(params);
  }

  async processArray(array) {
    array.forEach(async (item) => {
      await this.delayedLog(item);
    })
    console.log("done@");
  }



  uploadDocument(file, uploadObj) {
    let user = this.constantsService.getUser();
    let queryParams = [];
    this._utilService.showLoader();
    if (this.data.reviewNum) {
    queryParams.push(this.data.reviewNum);
      }
      if(this.data.screenType){
        queryParams.push(this.data.screenType);
      }

      queryParams.push(user.user.uniqueId);

      setTimeout(()=>{
      this.emitter.broadcast('refreshFileUpload', 'nothing');
      }
      ,60000);
    this.apiManager.fetchData(
      'saveQcDocRepo',
      {
        'fileFormData': file,
        'elemNum': this.selectedScreen,
      },
      ApiManager.FILEUPLOAD,
      undefined,
      undefined,
      () => {
        
        this.fileUploading = false;
        console.log("FILE FINISHED UPLOADING");
      },
      queryParams
    ).subscribe(res => {
      
     let docdtls = res.docDetails;
     this.spinnerService.hide();
     
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.filesToUpload, event.previousIndex, event.currentIndex);
  }
  clearInputs() {
    this.docs = [];
    this.docMetadata = [];
    this.filesToDownload = [];
  }

  // changeSubtype(event, docEvent?, search?) {
  //   let value = this.lookups.getLookupDesc('Documents', docEvent);
  //   docEvent = value;
  //   this.lkptyp = docEvent;
  //   if (search == 'search') {
  //     this.subtypeSearch = this.globalCategoryObj[docEvent];
  //     this.searchDocEvent = docEvent;
  //     this.subTypeSearchCd = '';
  //     this.lkptyp = docEvent;
  //   }
  //   else {
  //     this.lkptyp = docEvent;
  //     let docEvents = docEvent.split(' ');
  //     let subCategory = '';
  //     docEvents.map(event => subCategory += event);
  //     this.lookups.getLookUpValues([subCategory], 'en_US')
  //       .then(res => {
  //         this.globalCategoryObj = res;
  //         this.subtype = this.globalCategoryObj[subCategory];
  //       });
  //   }
  // }

  initializeFileUploadPopUp(
    personName: PersonName,
    fileArray?: any[],
    caseId?: any,
    individualId?: string,
    verificationName?: string,
    documents?: any,
    verificationType?: any) {
    this.docOptionsUpdated = false;
    this.clearInputs();
    this.individualId = individualId;
    if (!personName && !individualId) {
      this.memberSelected = this._utilService.getFullName(ConstantsService.humanServiceModel.benefitsCase.householdMembers[0].personName);
      this.individualId = ConstantsService.humanServiceModel.benefitsCase.householdMembers[0].individualId;
      this.queryParams.push(this.individualId);
    } else if (!personName || !individualId) {
      if (!personName) {
        ConstantsService.humanServiceModel.benefitsCase.householdMembers.map(hoh => {
          if (hoh.individualId === individualId) {
            this.memberSelected = this._utilService.getFullName(hoh.personName)
          }
        })
        this.individualId = individualId
      }
      if (!individualId) {
        ConstantsService.humanServiceModel.benefitsCase.householdMembers.map(hoh => {
          if (hoh.personName.firstName === personName.firstName && hoh.personName.lastName === personName.lastName && hoh.personName.recordId === personName.recordId) {
            this.individualId = hoh.individualId;
            this.queryParams.push(this.individualId)
          }
        })
        this.memberSelected = this._utilService.getFullName(personName)
      }
    } else {
      if (personName) {
        this.memberSelected = this._utilService.getFullName(personName);
      }
      if (individualId) {
        this.individualId = individualId;
        this.queryParams.push(
          this.individualId
        );
      }
      else {
        this.queryParams.push(
          null
        );
        alert("Individual ID is null");
      }
    }

    if (caseId) {
      this.caseId = caseId;
    }
    this.queryParams = [];
    this.queryParams.push(
      this.caseId
    );
    if (verificationName) {
      this.verificationName = verificationName;
    }
    let that = this;
    this.readOnly = ConstantsService.isReadOnlyScreen;
    if (!documents) {
      documents = [];
    }
    this.totalCount = documents.length;
    if (this.maxfileCount != undefined && (this.maxfileCount <= this.totalCount)) {
      this.disableAddDocumentButton = true;
    }
    this.uploadedFiles = [];
    this.documentArrayRefrence = documents;
    this.hasError = false;
    this.docInfo.get('docType').patchValue(verificationType);
    // this.changeSubtype(verificationType);
  }
  
  // get value of Screen dropdown
  setSelectedScreen(value) {
    this.selectedScreen = value;
  }
}
