import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-qc56-releaseof-information-form',
  templateUrl: './qc56-releaseof-information-form.component.html',
  styleUrls: ['./qc56-releaseof-information-form.component.css']
})
export class QC56ReleaseofInformationFormComponent extends BaseComponent implements OnInit {
  householdMembers: any;

  ngOnInit() {

    this.householdMembers = this.constantsService.householdMembers;
    this.householdMembers.map(indivId =>{
      if(indivId.headOfHsehldInd =='Y'){
      this.setControlValue('500116.2', indivId.address1),
      this.setControlValue('500116.3', indivId.address2)
      }
    });

    super.ngOnInit();
  }
  convertSpanishPDF() {
    this.callGenerateSpanishPDF.emit(undefined);
  }
}