import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-qc16-fsneg-frontpage',
  templateUrl: './qc16-fsneg-frontpage.component.html',
  styleUrls: ['./qc16-fsneg-frontpage.component.css']
})
export class Qc16FsnegFrontpageComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    if(this.getControlValue('500020.9')){
      this.setControlValue('500020.9',this.getControlValue('500020.9').toUpperCase());
    }
    if(this.getControlValue('500020.10')){
      this.setControlValue('500020.10',this.getControlValue('500020.10').toUpperCase());
    }
    if(this.getControlValue('500020.11')){

      this.setControlValue('500020.11',this.getControlValue('500020.11').toUpperCase());
    }
    if(this.getControlValue('500020.14')){

      this.setControlValue('500020.14',this.getControlValue('500020.14').toUpperCase());
    }
    if(this.getControlValue('500020.15')){

      this.setControlValue('500020.15',this.getControlValue('500020.15').toUpperCase());
    }
   
  }
  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/qualityControl/questionary-500020?type=500020'] != null && ref.accessMap['/qualityControl/questionary-500020?type=500020'].accessMode != 'rw')) {
        this.isNotEditable = true;
    }
    // if(this.constantsService.isAnalystUser()){ 
    //   this.isNotEditable = true;
    // }
  }

}