import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ClarifyComponent } from 'src/app/shared-module/clarify/clarify.component';

@Component({
  selector: 'app-workbook-summary',
  templateUrl: './workbook-summary.component.html',
  styleUrls: ['./workbook-summary.component.css']
})
export class WorkbookSummaryComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;
  lqdAssetsDisable : boolean = true;

  ngOnInit() {
    super.ngOnInit();
    // this.changeof110_1({value: this.group.controls['110.1'].value});
    this.determineAccess();
    if (this.group.controls['211.03'].value === 'Y') {
      this.lqdAssetsDisable = false;
    }
  }

  isThereAnyLqdAsstDiff(val){

    if (val) {
      if (val === 'N') {
        this.lqdAssetsDisable = true;
      } else if(val === 'Y') {
        this.lqdAssetsDisable = false;
      }
    } else {
      this.lqdAssetsDisable = true;
    }
  }

  get currentIndividual() {
    return this.shareService.getDataFromMap('currentIndividual');
  }

  set currentIndividual(id) {
    this.shareService.addDataToMap('currentIndividual', id);
  }
  
  openClarifyDialog(value: any) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      individualId: this.currentIndividual,
      screenId : value
    };

    const dialogRef = this.dialog.open(ClarifyComponent, dialogConfig);
    dialogRef.updateSize('75%', '85%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log('afterClose', val);
      }
    });
  }
}
