import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-failed-to-comply-job-prg3',
  templateUrl: './failed-to-comply-job-prg3.component.html',
  styleUrls: ['./failed-to-comply-job-prg3.component.css']
})
export class FailedToComplyJobPrg3Component extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
