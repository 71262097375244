import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { QuestionaryComponent } from './../../shared-module/questionary/questionary.component';
import { QuestionaryResolver } from './../../shared-module/questionary/questionary.resolver';
import { PaDashboardComponent } from './pa-admin/pa-dashboard/pa-dashboard.component';
import { PaSampleComponent } from './pa-admin/pa-sample/pa-sample.component';
import { CaseAssignmentComponent } from 'src/app/shared-module/case-assignment/case-assignment.component';
import { ReviewCaseListComponent } from 'src/app/shared-module/review-case-list/review-case-list.component';
import { ReviewSummaryComponent } from 'src/app/shared-module/review-summary/review-summary.component';
import { FindRespComponent } from 'src/app/shared-module/find-resp/find-resp.component';
import { FsmeScheduleComponent } from 'src/app/shared-module/fsme-schedule/fsme-schedule.component';
import { FsmeSupervisoryReviewComponent } from '../../shared-module/fsme-supervisory-review/fsme-supervisory-review.component';



const routes: Routes = [
  // {
  //   path: '',
  //   component: PaDashboardComponent
  // },
  {
    path: 'pa-dashboard',
    component: PaDashboardComponent
  },
  {
    path: 'pa-schedule',
    component: FsmeScheduleComponent
},
  {
    path: 'payment-accuracy-sample',
    component: PaSampleComponent
  },
  {
      path: 'pa-case-assignment',
      component: CaseAssignmentComponent
  },
  {
    path: 'pa-supervisory-review',
    component: FsmeSupervisoryReviewComponent
},
  {
    path: 'pa-case-review-list',
    component: ReviewCaseListComponent
  },
  {
    path: 'review-summary',
    component: ReviewSummaryComponent
  }
  ,
  {
    path: 'findings-response',
    component: FindRespComponent
  }
  ,
  {
    path: 'questionary-200006',
    component: QuestionaryComponent,
    runGuardsAndResolvers: "always",
    resolve: { pageData: QuestionaryResolver }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PARoutingModule { }
