import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Login } from './../model/api/login.class';
import { ApiManager } from './api-manager.service';
import { ConstantsService } from './constants.service';
import { DOCUMENT } from '@angular/common';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {

    // LOGIN STATUS
    private isLogged: boolean = false;
    private result: string;
    private loginObj: any;
    private model: Login;

    constructor(
        private http: HttpClient,
        private router: Router,
        private apiManager: ApiManager,
        private consts: ConstantsService,
        @Inject(DOCUMENT) private document: any) { }

    matchDetails(): void {
        this.loginObj = JSON.parse(this.result);

        this.setLoginStatus(true);
        this.router.navigate(['/dashboard/homeScreen']);
        // if ((this.model.email === this.loginObj[0].email) && (this.model.password === this.loginObj[0].password)) {

        // 	this.setLoginStatus(true);
        // 	this.router.navigate(['/dashboard/screening/clientInformation']);
        // } else {
        // 	this.setLoginStatus(false);
        // }
    }

    matchLoginDetails(model: Login, router: Router): any {
        this.model = model;
        this.router = router;
        this.consts.loginModel = model;

        this.apiManager.fetchData('customLogin', undefined, ApiManager.POST,
            { 'userName': model.email, 'password': model.pwd }, undefined, undefined, undefined, undefined)
            .subscribe((res: Response) => {
                this.result = JSON.stringify(res);
                console.log(this.result);
                if (this.result.includes('DHS Login')) {
                    this.clientLogout();
                } else {
                    this.matchDetails();
                }
            });
    }

    // logout() {
    //     this.apiManager.fetchData('logoutApiUrl', undefined, ApiManager.GET,
    //      undefined, undefined, undefined, undefined, undefined).subscribe(
    //         res => {
    //             console.log('api for logging the logout was called succesfully');
    //         }
    //     );
    //     this.setLoginStatus(false);
    //     this.router.navigate(['/Login']);
    // }

    getLoginStatus(): boolean {
        return this.isLogged;
    }

    setLoginStatus(isLogged: boolean): void {
        this.isLogged = isLogged;
    }
    wpSignOn() {
        const wpSignOn = ConstantsService.loginURL;
        let start = Date.now();
        const goto = '?id=' + start;
        window.open(wpSignOn + goto, '_top');
      }

    clientLogout() {
        this.apiManager.fetchData('logoutApiUrl', undefined, ApiManager.GET,
            undefined, undefined, undefined, undefined, undefined).subscribe(
                res => {
                    console.log('api for logging the logout was called succesfully');
                }
            );
        const goto = '&goto=';
        this.document.location.href = environment.logoutURL + goto + environment.loginURL;
    }
}
