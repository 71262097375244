import { Component, OnInit } from '@angular/core';


import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

// import { MonthFormatDirective, DATE_FORMAT, MNTH_FORMAT } from '../../shared-module/month-format.directive';
import { MomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DATE_FORMAT } from 'src/app/shared-module/month-format.directive';

@Component({
  selector: 'app-fns245-schedule',
  templateUrl: './fns245-schedule.component.html',
  styleUrls: ['./fns245-schedule.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})
export class Fns245ScheduleComponent extends BaseComponent implements OnInit {
  subElements: any = [[0,1,2],[]];
  // subElementsTwo:any=[];
  // subElementsThree:any=[];
  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    
    if (this.getControlValue('600210.36a')) {
      this.changeSubElementByElement('600210.37a', this.getControlValue('600210.36a'),0);
    }
    if (this.getControlValue('600210.36b')) {
      this.changeSubElementByElement('600210.37b', this.getControlValue('600210.36b'),1);
    }
    if (this.getControlValue('600210.36c')) {
      this.changeSubElementByElement('600210.37c', this.getControlValue('600210.36c'),2);
    }

    if (this.getControlValue('600210.23a')) {
      this.changeSubElementByElement('600210.37c', this.getControlValue('600210.36c'),2);
    }

    
    if(!this.getControlValue('600210.38')){
      this.setControlValue("600210.38",'01');
    }
    // if(this.getControlValue('600210.23a') && (this.getControlValue('600210.23a') == '2')){

    //   this.setControlValue("600210.25a",'');
    //   this.setControlValue("600210.26a",'');
    //   this.setControlValue("600210.27",'');
    //   this.setControlValue("600210.28",'');
    //   this.setControlValue("600210.29",'');
    //   this.setControlValue("600210.30",'');
    //   this.setControlValue("600210.31",'');
    //   this.setControlValue("600210.32",'');
    //   this.setControlValue("600210.32a",'');
    //   this.setControlValue("600210.33",'');
    //   this.setControlValue("600210.33c",'');
    //   this.setControlValue("600210.33d",'');
    //   this.setControlValue("600210.34a",'');
    //   this.setControlValue("600210.34b",'');
    //   this.setControlValue("600210.34",'');
    //   this.setControlValue("600210.35",'');
    //   this.setControlValue("600210.36a",'');
    //   this.setControlValue("600210.37a",'');
    //   this.setControlValue("600210.36b",'');
    //   this.setControlValue("600210.37b",'');
    //   this.setControlValue("600210.36c",'');
    //   this.setControlValue("600210.37c",'');
    //   this.setControlValue("600210.38",'');
    // }

    this.changeQf600210_23a(this.getControlValue('600210.23a'));
    this.changeQf600210_26a(this.getControlValue('600210.26a'));
    this.determineAccess();
  }

  changeSubElementByElement(lkpNameCd, eCd,index) {
    this.subElements[index] = this.constantsService.getLookUpByName(this.getLookupNameByQCd(lkpNameCd));
    if (this.subElements[index].length > 0 && eCd) {
      this.subElements[index] = this.subElements[index].filter(item => item.parentRef === eCd);
    }
  }

  changeQf600210_23a(val) {
    if(val == '1'){
      // this.setControlValue('600210.39', '04' ); 
      this.setControlValue('600210.24b','');
    }
      
    else if (val == '2') {
      // this.setControlValue('600210.24b',this.getControlValue('600210.24b'));
   
    }

    // else if( val !== 1 ){
    //   this.setControlValue('600210.39', ''); 
    // }
    
    else {
      this.setControlValue('600210.24b','');
      // this.setControlValue('600210.39', ''); 
      if( val == '00'){
        this.setControlValue('600210.25a','2');
      }
  
    }
  }

  // 10a has a code 2 appear when case is NSTR
// at that point the logic should be only read for 10B and every other field should remain blank
// 


  changeQf600210_26a(val) {
      if( val == '00'){
        this.setControlValue('600210.25a','2');
      }
  }

}

