import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BpeDashboardComponent } from './bpe-dashboard/bpe-dashboard.component';
import { RootFrameComponent } from '../RootFrames/root-frame.component';

const routes: Routes = [
    {
      path: '',
      component: RootFrameComponent,
        children: [
        {
            path: 'bpe',
            component: BpeDashboardComponent,
            // redirectTo: 'dashboard',
            pathMatch: 'full'
        },
        {
          path: 'fsme',
          loadChildren: () => import('./fsme/fsme.module').then(mod => mod.FsmeModule),
        },
        {
          path: 'payment-accuracy',
          loadChildren: () => import('./payment-accuracy/payment-accuracy.module').then(mod => mod.PaymentAccuracyModule),
        },
        {
          path: 'wpr',
          loadChildren: () => import('./wpr/wpr.module').then(mod => mod.WprModule),
        },
        {
          path: 'monitoring',
          loadChildren: () => import('./monitoring/monitoring.module').then(mod => mod.MonitoringModule),
        },
    ]
    },    
];

@NgModule({
imports: [RouterModule.forChild(routes)],
exports: [RouterModule]
})

export class BpeAppRoutingModule {}