import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import { ApiManager } from 'src/app/common/api-manager.service';

@Component({
  selector: 'app-earned-income-worksheet',
  templateUrl: './earned-income-worksheet.component.html',
  styleUrls: ['./earned-income-worksheet.component.css']
})
export class EarnedIncomeWorksheetComponent extends BaseComponent implements OnInit {
  currentPage: number = 0;
  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    const ctrl:any = (this.group.get('FormArray'));
    this.sum(ctrl.controls[0])
    this.gross(ctrl.controls[0])
    this.grossTwo(ctrl.controls[0])
    this.grossThree(ctrl.controls[0]);

    this.getDataFor3114("311.4",0,null,ctrl.controls[0]);
  }

  getDataFor3114(type, idx, pData,formGroup) {
     
      const invId: any = this.currentIndividual; // this.memberForm.get('individualId').value;
      const queryParams: any[] = [];
     
      queryParams.push(type); //screen type
      if (invId) {
        queryParams.push(invId);
      }
  
      if (this.constantsService.ReviewInfo &&
        this.constantsService.ReviewInfo.rvwNum) {
        queryParams.push(this.constantsService.ReviewInfo.rvwNum);
      }
      
      if (this.constantsService.ReviewInfo &&
        this.constantsService.ReviewInfo.rvwType) {
          if (!this.screenType.startsWith('5001') ) {
            queryParams.push(this.constantsService.ReviewInfo.rvwType);
          } else {
            queryParams.push('QCForm');
          }
      } else {
        queryParams.push('Evaluation');
      }
  
      this.apiManager.fetchData('getReviewQ', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
      .subscribe(
        res => {
               console.log(res);
               formGroup.controls["311.8.01"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.01').value);
               formGroup.controls["311.8.02"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.02').value);
               formGroup.controls["311.8.02a"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.02a').value);
               formGroup.controls["311.8.02b"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.02b').value);
               formGroup.controls["311.8.02c"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.02c').value);
               formGroup.controls["311.8.02d"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.02d').value);
               formGroup.controls["311.8.02e"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.02e').value);
               formGroup.controls["311.8.02f"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.02f').value);
               formGroup.controls["311.8.02g"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.02g').value);
               formGroup.controls["311.8.02h"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.02h').value);
               formGroup.controls["311.8.02i"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.02i').value);
               formGroup.controls["311.8.02j"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.02j').value);
               formGroup.controls["311.8.02k"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.02k').value);
               formGroup.controls["311.8.02l"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.02l').value);
               formGroup.controls["311.8.02m"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.02m').value);
               formGroup.controls["311.8.02n"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.02n').value);
               formGroup.controls["311.8.02o"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.02o').value);
               formGroup.controls["311.8.02p"].patchValue(res.fieldConfig.find(item=>item.name === '311.4.02p').value);
          });
    }

  sum(arrayControl){
    const amt1 = arrayControl.controls["311.8.02a"].value;
    const amt2 = arrayControl.controls["311.8.02d"].value;
    const amt3 = arrayControl.controls["311.8.02g"].value;
    const amt4 = arrayControl.controls["311.8.02j"].value;
    const amt5 = arrayControl.controls["311.8.02m"].value;
   const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5);
    arrayControl.controls["311.8.02p"].patchValue(res);
  }

  gross(arrayControl){
    let counter = 0;
    const amt1 = arrayControl.controls["311.8.5a0"].value;
    const amt2 = arrayControl.controls["311.8.5a1"].value;
    const amt3 = arrayControl.controls["311.8.5a2"].value;
    const amt4 = arrayControl.controls["311.8.5a3"].value;
    const amt5 = arrayControl.controls["311.8.5a4"].value;
    amt1 != null ? counter++: counter;
    amt2 != null ? counter++: counter;
    amt3 != null ? counter++: counter;
    amt4 != null ? counter++: counter;
    amt5 != null ? counter++: counter;
   const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5);
    arrayControl.controls["311.8.10a"].patchValue(res);
    const average =  (res)/counter;
    arrayControl.controls["311.8.11a"].patchValue(average);
    const amtX = arrayControl.controls["311.8.7a"].value;
    const equalTo =  (amtX)*average;
    arrayControl.controls["311.8.13a"].patchValue(equalTo);
    const amtHh = arrayControl.controls["311.8.8a"].value;
    const equalToTwo =  (equalTo)/amtHh;
    arrayControl.controls["311.8.14a"].patchValue(equalToTwo);
    const amtEligHh = arrayControl.controls["311.8.9a"].value;
    const equalToThree =  equalToTwo*amtEligHh;
    arrayControl.controls["311.8.15a"].patchValue(equalToThree);
  }
  grossTwo(arrayControl){
    let counter = 0;
    const amt1 = arrayControl.controls["311.8.5b0"].value;
    const amt2 = arrayControl.controls["311.8.5b1"].value;
    const amt3 = arrayControl.controls["311.8.5b2"].value;
    const amt4 = arrayControl.controls["311.8.5b3"].value;
    const amt5 = arrayControl.controls["311.8.5b4"].value;
    amt1 != null ? counter++: counter;
    amt2 != null ? counter++: counter;
    amt3 != null ? counter++: counter;
    amt4 != null ? counter++: counter;
    amt5 != null ? counter++: counter;
   const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5);
   arrayControl.controls["311.8.10b"].patchValue(res);
    const average =  (res)/counter;
    arrayControl.controls["311.8.11b"].patchValue(average);
    const amtX = arrayControl.controls["311.8.7b"].value;
    const equalTo =  (amtX)*average;
    arrayControl.controls["311.8.13b"].patchValue(equalTo);
    const amtHh = arrayControl.controls["311.8.8b"].value;
    const equalToTwo =  (equalTo)/amtHh;
    arrayControl.controls["311.8.14b"].patchValue(equalToTwo);
    const amtEligHh = arrayControl.controls["311.8.9b"].value;
    const equalToThree =  equalToTwo*amtEligHh;
    arrayControl.controls["311.8.15b"].patchValue(equalToThree);
    
  }
  grossThree(arrayControl){
    let counter = 0;
    const amt1 = arrayControl.controls["311.8.5c0"].value;
    const amt2 = arrayControl.controls["311.8.5c1"].value;
    const amt3 = arrayControl.controls["311.8.5c2"].value;
    const amt4 = arrayControl.controls["311.8.5c3"].value;
    const amt5 = arrayControl.controls["311.8.5c4"].value;
    amt1 != null ? counter++: counter;
    amt2 != null ? counter++: counter;
    amt3 != null ? counter++: counter;
    amt4 != null ? counter++: counter;
    amt5 != null ? counter++: counter;
   const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5);
   arrayControl.controls["311.8.10c"].patchValue(res);
   const average =  (res)/counter;
   arrayControl.controls["311.8.11c"].patchValue(average);
   const amtX = arrayControl.controls["311.8.7c"].value;
   const equalTo =  (amtX)*average;
   arrayControl.controls["311.8.13c"].patchValue(equalTo);
   const amtHh = arrayControl.controls["311.8.8c"].value;
   const equalToTwo =  (equalTo)/amtHh;
   arrayControl.controls["311.8.14c"].patchValue(equalToTwo);
   const amtEligHh = arrayControl.controls["311.8.9c"].value;
    const equalToThree =  equalToTwo*amtEligHh;
    arrayControl.controls["311.8.15c"].patchValue(equalToThree);
  }


}
