import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-arithmatic-computation',
  templateUrl: './arithmatic-computation.component.html',
  styleUrls: ['./arithmatic-computation.component.css']
})
export class ArithmaticComputationComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;
  is520_4:boolean = false;

  is_520_4_Enalble: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    let q520_01  = this.getControlValue('520.01');
    if(q520_01){
      this.setControlValue('520.01',parseInt(q520_01));
    }
    let q520_02  = this.getControlValue('520.02');
    if(q520_02){
      this.setControlValue('520.02',parseInt(q520_02));
    }
    let q520_03  = this.getControlValue('520.03');
    if(q520_03){
      this.setControlValue('520.03',parseInt(q520_03));
    }
    let q520_04  = this.getControlValue('520.04');
    if(q520_04){
      this.setControlValue('520.04',parseInt(q520_04));
    }
    let q520_05  = this.getControlValue('520.05');
    if(q520_05){
      this.setControlValue('520.05',parseInt(q520_05));
    }
    let q520_06  = this.getControlValue('520.06');
    if(q520_06){
      this.setControlValue('520.06',parseInt(q520_06));
    }
  
    // this.handleQ520_4();
  }
  calculateTotal(){
    this.setControlValue('520.1',this.getControlValue('520.0'));
  }
 
  get Is520_8(): boolean {
    return this.group.controls['520.8'].value === 'N';
  }
  
  get Is520_5(): boolean {
    return this.group.controls['520.5'].value === 'N';
  }

  get Is520_1(): boolean {
    return this.group.controls['520.01'].value===this.group.controls['520.1'].value;
  }

 handleQ520_4(){
   if( this.group.controls['520.3'].value == "false" || this.group.controls['520.3a'].value == "false") {
      this.group.controls['520.4'].disable();
     } else {
      this.group.controls['520.4'].enable();
     }
 } 

handleQ520_5(val){
  if(val === 'N'){
     this.group.controls['520.7'].enable();
  }else{
     this.group.controls['520.7'].disable();
  }
}


handleQ520_8(val){
  if(val === 'N'){
     this.group.controls['520.10'].enable();
  }else{
     this.group.controls['520.10'].disable();
  }
}

handleQ520_1(){
  this.Is520_1;
}



handle_520_4(event: any){
  if (event.checked == true){
    this.is520_4 = true;
  } else {
    this.is520_4 = false;
  }
  
}
}
