import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-hh-comp-benfit-estimate',
  templateUrl: './hh-comp-benfit-estimate.component.html',
  styleUrls: ['./hh-comp-benfit-estimate.component.css']
})

export class HhCompBenfitEstimateComponent  extends BaseComponent implements OnInit {

isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

  calc_hh_tot_size() {
    const hh1 = this.getControlValue('150.7.2a');
    const hh2 = this.getControlValue('150.7.2b');
    const hh3 = this.getControlValue('150.7.2c');
    const hh4 = this.getControlValue('150.7.2d');
    this.setControlValue('150.7.2', (+hh1) + (+hh2) + (+hh3) + (+hh4));

  }


  calc_hh_tot_benamt() {
    const hh1 = this.getControlValue('150.7.3a');
    const hh2 = this.getControlValue('150.7.3b');
    const hh3 = this.getControlValue('150.7.3c');
    const hh4 = this.getControlValue('150.7.3d');
    this.setControlValue('150.7.3', (+hh1) + (+hh2) + (+hh3) + (+hh4));
  }

  calc_allot_Click() {
    this.setControlValue('150.7.10', '100');//fixed to 100

    let strContinue = 'Y';

    // ********************************
    // '* 150.7.5   Total amt issued
    // '********************************
    let in_150_allot_hh_bnft_amt_total = this.getControlValue('150.7.3');

    this.setControlValue('150.7.5', in_150_allot_hh_bnft_amt_total);

    // '******************************************
    // '* 150.7.6   Lookup the max allotment
    // '******************************************
    // TODO: need to get max_allot from lookup 
    // in_150_allot_calc_max_allot(150.7.6) = DLookup("[max_allot]", "qry_lookup_max_allot", "")
    // const hhsize = this.getControlValue('150.7.2a');
    const in_150_allot_calc_max_allot = this.getControlValue('150.7.6');
    this.setControlValue('150.7.6', in_150_allot_calc_max_allot);


    // '**********************************
    // '* 150.7.7   Amt of over-issuance
    // '**********************************
    let in_150_allot_calc_total_amt_iss = this.getControlValue('150.7.5');

    let in_150_allot_calc_over_iss_amt = Math.abs((+in_150_allot_calc_total_amt_iss) - (+in_150_allot_calc_max_allot));
    this.setControlValue('150.7.7', (+in_150_allot_calc_over_iss_amt));

// '* in_150_allot_calc_over_iss_amt  changes as per the fiscal year.

    if (in_150_allot_calc_over_iss_amt < 40) {
      strContinue = 'N';
    }

    if (strContinue === 'Y') {
        // '**********************************
        // '* 150.7.8   HH #1 Benefit amt
        // '**********************************
        const in_150_allot_hh1_bnft_amt = this.getControlValue('150.7.02');
        this.setControlValue('150.7.8', in_150_allot_hh1_bnft_amt);
        const in_150_allot_calc_hh1_bnft_amt = +in_150_allot_hh1_bnft_amt;
        
        // '********************************
        // '* 150.7.9   Total of Benefit amt
        // '**********************************
        in_150_allot_hh_bnft_amt_total = this.getControlValue('150.7.3');
        this.setControlValue('150.7.9', in_150_allot_hh_bnft_amt_total);
        const in_150_allot_calc_total_bnft_amt = +in_150_allot_hh_bnft_amt_total;
        
        // '**********************************
        // '* 150.7.11   % of allotment
        // '**********************************
        const unb_divide = in_150_allot_calc_hh1_bnft_amt / in_150_allot_calc_total_bnft_amt;
        const in_150_allot_calc_pct_allotment = unb_divide * 100;
        this.setControlValue('150.7.11', (in_150_allot_calc_pct_allotment).toFixed(2));
        
        // '**********************************
        // '* 150.7.12   Amt of over-issuance
        // '**********************************
        const in_150_allot_calc_over_iss_amt_concl = in_150_allot_calc_over_iss_amt;
        this.setControlValue('150.7.12', in_150_allot_calc_over_iss_amt_concl);
        
        // '**********************************
        // '* 150.7.13    % of allotment
        // '**********************************
        const in_150_allot_calc_pct_allotment_concl = in_150_allot_calc_pct_allotment;
        this.setControlValue('150.7.13', ((in_150_allot_calc_pct_allotment_concl)/100).toFixed(2));
        
        // '**********************************
        // '* 150.7.14   Amt of error
        // '**********************************
        const in_150_allot_calc_error_amt = in_150_allot_calc_over_iss_amt * this.getControlValue('150.7.13');
        this.setControlValue('150.7.14', Math.round(in_150_allot_calc_error_amt));
        
        // '**********************************
        // '* 150.7.15   Determination
        // '**********************************
        this.setControlValue('150.7.15', 'Error' );
    } else {
        // '**********************************
        // '* must zero all fields if LT $25
        // '**********************************
        
        this.setControlValue('150.7.8', ''); // in_150_allot_calc_hh1_bnft_amt = Null
        this.setControlValue('150.7.9', ''); // in_150_allot_calc_total_bnft_amt = Null
        this.setControlValue('150.7.11', ''); // in_150_allot_calc_pct_allotment = Null
        this.setControlValue('150.7.12', ''); // in_150_allot_calc_over_iss_amt_concl = Null
        this.setControlValue('150.7.13', ''); // in_150_allot_calc_pct_allotment_concl = Null
        this.setControlValue('150.7.14', ''); // in_150_allot_calc_error_amt = Null
        this.setControlValue('150.7.15', 'Correct' );
        
    }

  }

  

  


}