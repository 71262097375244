import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiManager } from 'src/app/common/api-manager.service';
import { AppUtilService } from 'src/app/common/app.util.service';
import { ConstantsService } from 'src/app/common/constants.service';
import { CustomValidatorsService } from 'src/app/common/custom-validators.service';
import { NavigationService } from 'src/app/common/navigation.service';


@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css']
})
export class SendEmailComponent  implements OnInit {
  emailform
  constructor(
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    public appUtilService: AppUtilService,
    public navigationService: NavigationService,
    private customValidatorsService: CustomValidatorsService,
    public fb: FormBuilder,
  ) {

 }

  ngOnInit(
  ) {
    this.buildForm();

  }
  buildForm() {
    this.emailform = this.fb.group({
      emailFrom: ["eligibilityenrollment@mdthink.maryland.gov", Validators.required],
      emailTo: ["", Validators.required],
    })
  }

 
  save(model) {
 
 const queryParams: any[] = [];
 let ccid = this.data.ccid;
 queryParams.push(ccid);
let emailObj: any = {
      from:model.value.emailFrom,
      to:model.value.emailTo

    };
    //review number and template name
    if (this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }
    queryParams.push(this.data.screenId);

    this.apiManager.fetchData('saveEmail', undefined, ApiManager.POST, emailObj, undefined, undefined, queryParams)
      .subscribe(
        res => {
         
        });
  }
}

