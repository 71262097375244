import { ApiManager } from './api-manager.service';
import { throwError as observableThrowError, of as observableOf, Observable, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse, HttpHeaders } from "@angular/common/http";
import { Dictionary } from 'underscore';
import { KeyValue } from '../model/key-value.class';
import { LookupContainerModel } from '../model/lookup-container-model.class';
import { LookupClass } from '../model/api/lookup.class';
import { ReviewInfoClass } from '../model/api/review-info.class';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { environment } from '../../environments/environment';
import { NavigationService } from './navigation.service';


export enum USER_ROLES {
  QC_ADMIN = "EE_QC_ADMIN",
  QC_SUPERVISOR = "EE_QC_SUPERVISOR",
  QC_ANALYST = "EE_QC_ANALYST",
  BPE_DIRECTOR = "EE_BPE_DIRECTOR",
  BPE_MANAGER = "EE_BPE_MANAGER",
  BPE_REGIONAL_CORDINATOR = "EE_BPE_REGIONAL_CORDINATOR",
  BPE_PROGRAM_ANALYST = "EE_BPE_PROGRAM_ANALYST",
}

let logoutURL: any = environment.logoutURL;
let loginURL: any = environment.loginURL;



@Injectable()
export class ConstantsService {
  static goToHomePage: boolean = true;
  static isReadOnlyScreen: boolean;
  static citizenshipFileUpload: boolean = false;
  public householdMembers: any;
  public ReviewInfo: ReviewInfoClass;
  public caseHomeData: any;
  public caseHomeContactData: any;
  lookupDataInterview: any;
  localLookupData: LookupContainerModel;
  lookupData: any;
  DD: Map<string, KeyValue[]>;
  public lookUpValues: Dictionary<LookupClass[]>[] = [];
  static humanServiceModel: any;
  userData: any;
  regionDistrictAnalystLookup: any;
  static loaderFlag: boolean = false;
  static docInfo: any;
  static loginURL: string = loginURL;
  public navigationService: NavigationService;
  public appCd: any;
  public rvwlog_rvwid:any;
  public securityObject: any;
  public selectedIndividual: any;
  public nstr9a: any;
  static isConfirmChecked: string = "";
  public dashboardMonth : any;

  listOfScreens:any = ["110","111","130","140","150","151","160","161","162","163","164","165","166","170","211","212","213","221","222","224","225","311","312","314","321","323","331","332","333","334","335","336","342","343","344","345","346","347","350","361","363","364","365","366","530","820"];

  private securityUserObj: any = {
    "navigationFlowRequested": false,
    "accessMap": {
    },
    "user": {
      "userId": "2b1ed9b2d095206b8cc59bcefbe7ed",
      "uniqueId": "2b1ed9b2d095206b8cc59bcefbe7ed",
      "name": "",
      "roles": [
        "EE_QC_ADMIN",
        "EE_SUPER",
        "EE_SCREE",
        "EE_FINAL",
        "EE_INTER",
        "Internal Users"
      ],
      "county": "Harford County",
      "districtOffice": "033"
    }
  };

  loginModel = {
    'email': 'screener1', // 'user' need to be removed after proper functioning of logout service
    'pwd': ''
  };

  //******************LOOKUP********* */
  ReviewStatusList: any =
    [
      { value: "PE", viewValue: "In Progress" },
      { value: "ST", viewValue: "Submitted" },
      { value: "CP", viewValue: "Completed" },
      { value: "RR", viewValue: "Re-Review" },
      { value: "IC", viewValue: "In Complete" },
      { value: "NS", viewValue: "NSTR" },
      ,
    ];

  ProgramTypes: any =
    [
      { value: "FSPPositive", viewValue: "SNAP Positive" },
      { value: "FSPNegative", viewValue: "SNAP Negative" },
      { value: "OTO", viewValue: "OTO" },
      // { value: "ESAP", viewValue: "ESAP" },
      { value: "ESAPPositive", viewValue: "ESAP Positive" },
      { value: "ESAPNegative", viewValue: "ESAP Negative" },
    ];

  MemoTypes: any =
    [
      { value: "CHS", viewValue: "Child Support" },
      { value: "MPR", viewValue: "Monthly Pre-Reviews" },
      { value: "OCA", viewValue: "OverPayment Claim Alerts" },
      { value: "PSN", viewValue: "Paris State National Alerts" },
      { value: "PPR", viewValue: "Preliminary Pre-Review" },
      { value: "PAC", viewValue: "Pre-Review Awaiting Corrections" },
      { value: "PRM", viewValue: "Prison Match" },
      { value: "REA", viewValue: "Reactivation" },
      { value: "FECM", viewValue: "FSME entrance conference memo" },

    ];


  static logoutUrl: string = logoutURL;

  in_68_Code : any;
  public maxDate = new Date();
  alertCount: number;
  updatesCount: number;


  //******************************** */

  getCurrentHousehold(): any[] {
    let currentIndividual;
    if (this.householdMembers) {
      this.householdMembers.map(hhm => {
        if (hhm.headOfHsehldInd === 'Y') {
          currentIndividual = hhm;
        }
      });
    }
    return currentIndividual;
  }
  getConstant(arg0: string) {
    throw new Error("Method not implemented.");
  }
  getText(key: string): string {
    return ConstantsService[key];
  }


  setSecurityObject(val: any) {
    if (!this.securityObject || window.location.hash.replace('#', '') == '/dashboard/homeScreen') {
      this.securityObject = val;
    } else if (window.location.hash.replace('#', '') == '/Login') {
      this.securityObject = null;
    }
    if (val)
      this.securityObject = val;
  }

  getSecurityObject() {
    // let secureObj = this.userData;
    // return secureObj;
    let secureObj: {};
    if (this.userData) {
      secureObj = this.userData;
    } else if (this.userData == undefined) {
      secureObj = this.securityUserObj;
    }
    return JSON.parse(JSON.stringify(secureObj));

  }

  getDashboardPage() {
    let path = '';
    const ref = this.getSecurityObject().user;
    if (ref && ref.roles.length > 0) {
      const role = ref.roles.join(',');


      if (role.includes(USER_ROLES.QC_ADMIN) ||
        role.includes(USER_ROLES.QC_SUPERVISOR) || role.includes(USER_ROLES.QC_ANALYST))
       {
        path = 'homePage';
      }
      //   else if( role.includes(USER_ROLES.QC_ANALYST)){
      //     path ='qualityControl/analystCaseSummary';
    
      // } 
      else if (role.includes(USER_ROLES.BPE_DIRECTOR) ||
        role.includes(USER_ROLES.BPE_MANAGER) ||
        role.includes(USER_ROLES.BPE_REGIONAL_CORDINATOR) ||
        role.includes(USER_ROLES.BPE_PROGRAM_ANALYST)
      ) {
        path = '/bpe';
      }

    }

    return path;


  }

  isAnalystUser() {
    const objUser = this.getSecurityObject();
    const roleAnalyst = objUser.user.roles.filter(role => role.includes('ANALYST'));
    return (roleAnalyst && roleAnalyst.length > 0) ? true : false;
  }

  getUser() {
    let objUser = this.getSecurityObject();
   return objUser;
  }

  isLDSSUser() {
    let roleLDSSUser = false;
    const objUser = this.getSecurityObject();
    // const roleLDSSUser = objUser.user.roles.filter(role => (role.includes('EE_') && !role.includes('EE_BPE') && !role.includes('EE_QC')) );
    objUser.user.roles.map(role => {
      if (role.includes('EE_') && !role.includes('EE_BPE') && !role.includes('EE_QC')) {
        roleLDSSUser = true;
      } else {
        roleLDSSUser = false;
      }
    });
    return roleLDSSUser;
  }

  getNavigateToRvwList() {
    let initiatePage;
    if (this.ReviewInfo && this.ReviewInfo.appType === 'BPE-FSME') {
      initiatePage = '/bpe/fsme/case-review-list';
    } else if (this.ReviewInfo && this.ReviewInfo.appType === 'BPE-PA') {
      initiatePage = '/bpe/payment-accuracy/pa-case-review-list';
    } else if (this.ReviewInfo && this.ReviewInfo.appType === 'BPE-WPR') {
      initiatePage = '/bpe/wpr/wpr-case-review-list';
    } else if (this.ReviewInfo && this.ReviewInfo.appType === 'BPE-MONITOR') {
      initiatePage = '/bpe/fsme/monitoring-case-review-list';
    } else {
      initiatePage = '/qualityControl/analystCaseSummary';
    }


    return initiatePage;
  }

  getNavigateToRvwListByUrl(url) {
    let initiatePage;
    if (url.includes('/bpe/fsme')) {
      initiatePage = '/bpe/fsme/case-review-list';
    } else if (url.includes('/bpe/payment-accuracy')) {
      initiatePage = '/bpe/payment-accuracy/case-review-list';
    } else if (url.includes('/bpe/wpr')) {
      initiatePage = '/bpe/wpr/case-review-list';
    } else if (url.includes('/bpe/monitoring')) {
      initiatePage = '/bpe/fsme/case-review-list';
    } else if (url.includes('/bpe')) {
      initiatePage = '';
    } else {
      initiatePage = '/qualityControl/analystCaseSummary';
    }

    return initiatePage;
  }


  getAppCd(navAppCd) {
    let appCd: string = '';
    switch (navAppCd) {
      case 'QC':
        appCd = 'QC';
        break;
      case 'BPEFSME':
        appCd = 'BPE-FSME';
        break;
      case 'BPEPA':
        appCd = 'BPE-PA';
        break;
      case 'BPEWPR':
        appCd = 'BPE-WPR';
        break;
      case 'BPEMONITOR':
        appCd = 'BPE-MONITOR';
        break;
      default:
        appCd = 'QC';
    }
    return appCd;
  }

  navigateByType() {
    let navURL = undefined;
    if (this.ReviewInfo.rvwType) {
      switch (this.ReviewInfo.rvwType) {
        case 'FSME_FS_APPS_FILED':
          navURL = '/bpe/fsme/questionary-100007?type=100007';
          break;
        case 'FSME_MY_DHR_CASES':
          navURL = '/bpe/fsme/questionary-100008?type=100008';
          break;
        case 'FSME_RECIPIENT_CLAIMS':
          navURL = '/bpe/fsme/questionary-100018?type=100018';
          break;
        case 'FSME_FSP_DENIALS':
          navURL = '/bpe/fsme/questionary-100010?type=100010';
          break;
        case 'FSME_SVES_PRISON_MATCH':
          navURL = '/bpe/fsme/questionary-100015?type=100015';
          break;
        case 'FSME_RECIPIENT_INTEGRITY':
          navURL = '/bpe/fsme/questionary-100014?type=100014';
          break;
        case 'FSME_ABAWD_CLSD':
          navURL = '/bpe/fsme/questionary-100013?type=100013';
          break;
        case 'FSME_DEATH_MATCH_ALERTS':
          navURL = '/bpe/fsme/questionary-100016?type=100016';
          break;
        case 'FSME_FSP_PRE_RVW_CASES':
          navURL = '/bpe/fsme/questionary-100006?type=100006';
          break;
        case 'FSME_EBT_MLTPL_CASES':
          navURL = '/bpe/fsme/questionary-100019?type=100019';
          break;
        case 'PA_TCA':
          navURL = '/bpe/payment-accuracy/questionary-200006?type=200006';
          break;

        default:
          break;
      }
    }

    return navURL;
  }

  getReviewStatusDescByCd(rvwStsCd) {
    let rvwDesc;
    const rvwSts = this.ReviewStatusList.filter(item => item.value === rvwStsCd);
    if (rvwSts && rvwSts.length > 0) {
      rvwDesc = rvwSts[0].viewValue;
    }
    return rvwDesc;
  }

  getLookUpByName(lkpName) {
    const lookupObj: LookupClass[] = this.lookUpValues.filter(lkp => lkp.lookUpName === lkpName);
    if (lookupObj && lookupObj.length > 0) {
      return lookupObj[0].options ? lookupObj[0].options : [];
    }

    return [];
  }

  getDropdownValue(key: string, value: string): string {
    /*     for (const entry of this.getDropDownFromLookup(key)) {
        const patt = new RegExp('0*' + entry.lookupBean.code);
        const res = patt.test(value);
            if (res == true) {
            return entry.lookupBean.longLabel;
            } else if (entry.lookupBean.shortLabel == value) {
            return entry.lookupBean.longLabel;
            }
        }
        } */
    if (key && value) {
      for (const entry of this.getDropDownFromLookup(key)) {
        const patt = new RegExp('0*' + value);
        const res = patt.test(entry.lookupBean.code);
        if (res == true) {
          return entry.lookupBean.longLabel;
        } else if (entry.lookupBean.shortLabel == value) {
          return entry.lookupBean.longLabel;
        }
      }
      return 'Unknown';
    }
  }

  getDistinctCounty() {
    // return this.regionDistrictAnalystLookup.filter((value, index, self) => self.indexOf(value) === index);
    let distinctCounty: any = [];
    this.regionDistrictAnalystLookup.filter((value, index, self) => this.appCd.includes(value.appType)  ).map(
      cnty => {
        if (distinctCounty.filter(dup => dup.value === cnty.ldssCd).length === 0) {
          distinctCounty.push({ value: cnty.ldssCd, viewValue: cnty.county });
        }

      }
    );
    return distinctCounty;
  }

  getDistinctRegions() {
    // return this.regionDistrictAnalystLookup.filter((value, index, self) => self.indexOf(value) === index);
    let distinctRegion: any = [];
    if(this.regionDistrictAnalystLookup){
    this.regionDistrictAnalystLookup
    // .filter((value, index, self) => this.appCd.includes(value.appType)  )
    .map(
      reg => {
        if (distinctRegion.filter(dup => dup.regionName === reg.regionName).length === 0) {
          distinctRegion.push({ ldssCd: reg.ldssCd, regionName: reg.regionName, regionCode: reg.regionCode });
        }

      }
    );
    }
    return distinctRegion;
  }

  findRegionCdByRegionName(region) {
    if (this.regionDistrictAnalystLookup) {
      let reg = this.regionDistrictAnalystLookup.filter(item  => item.regionName === region)
      return reg[0].regionCode;
    }  
  } 

  getDoByRegionCd(regCd) {
    let distinctDO: any = [];
    this.regionDistrictAnalystLookup.filter(item => item.regionName === regCd && this.appCd.includes(item.appType)).map(
      dos => {
        if (distinctDO.filter(dup => dup.ldssDoCd === dos.ldssDoCd).length === 0) {
          distinctDO.push({ ldssDoCd: dos.ldssDoCd, ldssDoName: dos.ldssDoName });
        }

      }
    );
    return distinctDO;
  }

  getDoByRegionCdWithOutAppCd(regCd) {
    let distinctDO: any = [];
    this.regionDistrictAnalystLookup.filter(item => item.regionName === regCd && item.ldssDoCd!='000').map(
      dos => {
        if (distinctDO.filter(dup => dup.ldssDoCd === dos.ldssDoCd).length === 0) {
          distinctDO.push({ ldssDoCd: dos.ldssDoCd, ldssDoName: dos.ldssDoName });
        }

      }
    );
    return distinctDO;
  }

  getDistinctDOs() {
    let distinctDO: any = [];
    if(this.regionDistrictAnalystLookup){
    this.regionDistrictAnalystLookup
    .filter((value, index, self) => this.appCd.includes(value.appType) )
    .map(
      dos => {
        if (distinctDO.filter(dup => dup.ldssDoCd === dos.ldssDoCd).length === 0) {
          distinctDO.push({ ldssDoCd: dos.ldssDoCd, ldssDoName: dos.ldssDoName });
        }

      }
    );
    }
    return distinctDO;
  }

  getDistinctDOsWithOutAppType() {
    let distinctDO: any = [];
    if(this.regionDistrictAnalystLookup){
    this.regionDistrictAnalystLookup
    .map(
      dos => {
        if (distinctDO.filter(dup => dup.ldssDoCd === dos.ldssDoCd).length === 0) {
          distinctDO.push({ ldssDoCd: dos.ldssDoCd, ldssDoName: dos.ldssDoName });
        }

      }
    );
    }
    return distinctDO;
  }

  getUsersList() {
    let userList: any = [];
    let chkApp = true;

    if(this.regionDistrictAnalystLookup) {
    this.regionDistrictAnalystLookup.map(
      usr => {
        if (userList.filter(dup => dup.uniqueid === usr.uniqueid).length === 0) {
          userList.push({ uniqueid: usr.uniqueid, email: usr.email, firstName: usr.firstName, lastName: usr.lastName });
        }

      }
    );
    }
    return userList;
  }

  changeAnalystByDoCd(doCd, chkApp = true) {
    let userList: any = [];
    
    this.regionDistrictAnalystLookup.filter(item => item.ldssDoCd === doCd && (chkApp ? this.appCd.includes(item.appType) : true )).map(
      usr => {
        if (userList.filter(dup => dup.uniqueid === usr.uniqueid).length === 0) {
          userList.push({ uniqueid: usr.uniqueid, email: usr.email, firstName: usr.firstName, lastName: usr.lastName });
        }

      }
    );
    return userList;
  }

  getDistinctReviewer() {
    let userList: any = [];
    this.regionDistrictAnalystLookup.filter((value, index, self) => this.appCd.includes(value.appType) && value.roleName == 'EE_QC_ANALYST'  ).map(
      usr => {
        if (userList.filter(dup => dup.uniqueid === usr.uniqueid).length === 0) {
          userList.push({ uniqueid: usr.uniqueid, email: usr.email, firstName: usr.firstName, lastName: usr.lastName });
        }

      }
    );
    return userList;
  }

  

  getDistinctGroups() {
   
    let distinctGroup: any = [];
    if(this.regionDistrictAnalystLookup){
    this.regionDistrictAnalystLookup
    .map(
      group => {
        if (distinctGroup.filter(dup => dup.value === group.groupName).length === 0) {
          distinctGroup.push({ groupName: group.groupName });
        }

      }
    );
    }
    return distinctGroup;
  }
  
  getDistinctSupervisors() {
    
    let userList: any = [];
    let chkApp = true;

    if(this.regionDistrictAnalystLookup) {
    this.regionDistrictAnalystLookup.map(
      usr => {
        if (userList.filter(dup => dup.uniqueid === usr.uniqueid).length === 0 &&  usr.roleName == USER_ROLES.QC_SUPERVISOR) {
          userList.push({ uniqueid: usr.uniqueid, email: usr.email, firstName: usr.firstName, lastName: usr.lastName });
        }

      }
    );
    }
    return userList;

  }

  getCaseStatusByCd(stsCd) {
    let csStsDesc = '';
    switch (stsCd) {
      case 'OP':
        csStsDesc = 'Open';
        break;
      case 'PE':
        csStsDesc = 'Pending';
        break;
      case 'CL':
        csStsDesc = 'Closed';
        break;
      case 'DS':
        csStsDesc = 'Discontinued';
        break;
    }
    return csStsDesc;
  }

  getDropDownFromLookup(val: string, checkServiceData: boolean = true): any {

    if (checkServiceData) {
      if (this.lookupDataInterview && this.lookupDataInterview.localizedLookups[val] &&
        this.lookupDataInterview.localizedLookups[val].beans) {
        return this.lookupDataInterview.localizedLookups[val].beans;
      }
      if (this.lookupData && this.lookupData.localizedLookups[val] && this.lookupData.localizedLookups[val].beans) {
        return this.lookupData.localizedLookups[val].beans;
      }
    }
    if (this.localLookupData && this.localLookupData.localizedLookups[val] && this.localLookupData.localizedLookups[val].beans) {
      return this.localLookupData.localizedLookups[val].beans;
    }
    return [];
  }

  private extractLookupData(response: HttpResponse<any>): Map<string, KeyValue[]> {
    const body = response.body;
    const data = body.localizedLookups;
    if (data) {
      const keys: string[] = Object.keys(data); // get all the keys
      this.DD = new Map<string, KeyValue[]>();
      if (keys) {
        for (let i = 0; i < keys.length; i++) {
          const beansArray = data[keys[i]].beans;
          const optionsArray: Array<KeyValue> = new Array<KeyValue>();
          for (let j = 0; j < beansArray.length; j++) {
            optionsArray.push(new KeyValue(beansArray[j].lookupBean.code, beansArray[j].lookupBean.longLabel))
          }
          this.DD.set(keys[i], optionsArray)
        }
      }
      return this.DD;
    } else {
      return null;
    }
  }

  getDisplayReviewNum(rvwNum) {
    rvwNum = rvwNum + '';
    if (rvwNum.length > 5) {
      return rvwNum.substring(4);
    } else {
      return rvwNum;
    }

  }

  gotoEE() {
    window.open(environment.EELink);
  }

  checkMandatoryForDisable(model:any,screenId:any,data:any) {
    let isDisable = false;
    let qstns;

    if (screenId === '520') {
      qstns = ['520.01','520.02','520.06','520.07'];
    }

    if (screenId === '150.8' && data.validateQVal) {
      qstns = ['150.8.6', '150.8.10','150.8.9'];
    } 
    else if (screenId.includes('888')) {
      qstns = ['888.1'];
    }

    if ( qstns ) {
      isDisable = this.checkValForMandatory(model, qstns);
    }

    return isDisable;
  }

  checkValForMandatory(model, qstns) {
    let result = false;
    qstns.map(qs => {
      if (model.value[qs] === null || model.value[qs] === '') {
        result = true;
      }

      if (result === false && model.value.FormArray.length >0 ) {
        model.value.FormArray.map( ary => {
          if (ary[qs] === null || ary[qs] === '') {
            result = true;
          }
        }) 
      }
      
    });

    return result;

  }

  setDocInfo(docInfo: any) {
    if (docInfo) ConstantsService.docInfo = docInfo;
    else ConstantsService.docInfo = null;
  }

  get docInfo(): any {
    return ConstantsService.docInfo;
  }

  

  // check_150_8 (model:any,screenId:any){
  //   let isDisable = false;
  //   let qstns;
  //   if (screenId === '150.8') {
  //     qstns = ['150.8.6'] || ['150.8.10'] || ['150.8.9'];
  //   }
  //   return isDisable; 
  // }
}

