import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-field68-b',
  templateUrl: './field68-b.component.html',
  styleUrls: ['./field68-b.component.css']
})
export class Field68BComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;
  handle68: boolean = false;
  handleTypofCert:boolean = false;
  typeOfAction :string;

  // set68FieldCode(){

  //   this.group.controls['68b.2'].patchValue(3);
  // }

  ngOnInit() {
    if (this.constantsService.caseHomeData.typOfActn === "2") {
      if (this.getfieldInfo('68-B.2').recordId === 0) {
        this.group.controls['68-B.12'].patchValue(3);
        this.typeOfAction = 'Recertification';
        this.isNotEditable = true;
        this.disableforTypeofAction();
        
       
      }
    } else {
      this.typeOfAction = 'Certification';
      this.enableforTypeofAction();
    }
    
    super.ngOnInit();
    this.determineAccess();
    this.handle68b01b();
    this.handle68b9();
    this.handle68b10();
    this.handle68b11();
    this.handle68b4(this.getControlValue('68-B.4'));
    this.handle68b4b(this.getControlValue('68-B.4B'));
    this.calc_sum();
    this.calc_sum_68_B();
  }

  calc_min_max() {
    let amt_1 = +this.getControlValue('68-B.1');
    let amt_2 = +this.getControlValue('68-B.2');
    let liquid_rate = +this.getControlValue('68-B.1a');
    let income_rate = +this.getControlValue('68-B.2b');

    this.group.controls['68-B.3'].enable();
    this.group.controls['68-B.4'].enable();
    this.group.controls['68-B.4B'].enable();
    this.group.controls['68-B.5'].enable();
    this.group.controls['68-B.6'].enable();
    this.group.controls['68-B.7'].enable();
    this.group.controls['68-B.8'].enable();
    this.group.controls['68-B.9'].enable();
    this.group.controls['68-B.10'].enable();

    if ((amt_1 < liquid_rate) && (amt_2 < income_rate)) {
      this.group.controls['68-B.3'].disable();
      this.group.controls['68-B.4'].disable();
      this.group.controls['68-B.4B'].disable();
      this.group.controls['68-B.5'].disable();
      this.group.controls['68-B.6'].disable();
      this.group.controls['68-B.7'].disable();
      this.group.controls['68-B.8'].disable();
      this.group.controls['68-B.9'].disable();
      this.group.controls['68-B.10'].disable();
    }

  }
  calc_sum() {
    let amt1 = +this.group.controls['68-B.1'].value;
    let amt2 = +this.group.controls['68-B.2'].value;
    let amt3 = +this.group.controls['68-B.3'].value;
    let amt4 = +this.group.controls['68-B.5'].value;
    let amt5 = +this.group.controls['68-B.7'].value;

    let total = ((+amt1) + (+amt2)) - ((+amt3) + (+amt4) + (+amt5));
    let amt2b = +this.group.controls['68-B.2b'].value;

    this.setControlValue('68-B.8', total);
    if (total < 0 || (amt2 < amt2b)) {
      this.group.controls['68-B.9'].disable();
      this.group.controls['68-B.10'].disable();
      this.calc_sum_68_B();
      // this.group.controls['68-B.11'].enable();
    }
    else {
      // this.handleTypeOfCert();
      this.group.controls['68-B.9'].enable();
      this.group.controls['68-B.10'].enable();
      this.calc_sum_68_B();
      this.group.controls['68-B.11'].disable();
    }
  }
  calc_sum_68_B() {
    let amt_1 = +this.getControlValue('68-B.1');
    let amt_2 = +this.getControlValue('68-B.2');
    let amt3 = this.getControlValue('68-B.8');
    let liquid_rate = +this.getControlValue('68-B.1a');
    let income_rate = +this.getControlValue('68-B.2b');
    if (amt3 >= 0 && (amt_1 > liquid_rate && amt_2 > income_rate)) {
      this.group.controls['68-B.11'].disable();
    } else if (amt3 >= 0 && (amt_1 < liquid_rate || amt_2 < income_rate)) {
      // this.handleTypeOfCert();
      this.group.controls['68-B.11'].enable();
    }
  }


  handle68b4(val) {

    if (val) {
      if (val == 'N') {
        this.group.controls['68-B.6'].disable();
        this.group.controls['68-B.7'].disable();
        this.group.controls['68-B.5'].enable();
        this.group.controls['68-B.4B'].enable();
      } else
        if (val == 'Y') {
          this.group.controls['68-B.6'].enable();
          this.group.controls['68-B.7'].enable();
          this.group.controls['68-B.5'].disable();
          this.group.controls['68-B.4B'].disable();
        }
    }
  }
  handle68b4b(val) {
    if (val) {
      if (val == 'N') {
        this.group.controls['68-B.5'].enable();
        this.group.controls['68-B.6'].disable();
        this.group.controls['68-B.7'].disable();

      } else
        if (val == 'Y') {
          this.group.controls['68-B.5'].disable();
          this.group.controls['68-B.6'].enable();
          this.group.controls['68-B.7'].enable();
        }
    }
  }
  handle68b9() {
    let amt_1 = this.getControlValue('68-B.9');


    if (amt_1 === 'Y') {
      this.group.controls['68-B.10'].disable();
      this.setControlValue('68-B.12', 1);
      this.handle68 = true;
    }
    else if (amt_1 === 'N') {
      this.group.controls['68-B.10'].enable();
      //  this.setControlValue('68-B.12',0);
      this.handle68 = true;
    }
  }
  handle68b10() {
    let amt_1 = this.getControlValue('68-B.10');
    let amt_2 = this.getControlValue('68-B.9');

    if (amt_2 === 'N' && amt_1 === 'Y') {
      this.setControlValue('68-B.12', 1);
      this.handle68 = true;
    }
    else if (amt_2 === 'N' && amt_1 === 'N') {
      this.setControlValue('68-B.12', 2);
      this.handle68 = true;
    }

    else if (amt_1 === 'Y') {
      this.setControlValue('68-B.12', 3);
      this.handle68 = true;
    }
    else if (amt_1 === 'N') {
      this.setControlValue('68-B.12', 2);
      this.handle68 = true;
    }

  }
  handle68b11() {
    let amt_1 = this.getControlValue('68-B.11');

    if (amt_1 === 'Y') {
      this.setControlValue('68-B.12', 1);
      this.handle68 = true;
    }
    else if (amt_1 === 'N') {
      this.setControlValue('68-B.12', 2);
      this.handle68 = true;
    }
  }
  handle68b01b() {
    let amt_1 = this.getControlValue('68-B.01b');

    if (amt_1 === 'N') {
      this.setControlValue('68-B.12', 3);
      this.group.controls['68-B.1'].disable();
      this.group.controls['68-B.1a'].disable();
      this.group.controls['68-B.2'].disable();
      this.group.controls['68-B.2b'].disable();
      this.group.controls['68-B.3'].disable();
      this.group.controls['68-B.3b'].disable();
      this.group.controls['68-B.4'].disable();
      this.group.controls['68-B.4B'].disable();
      this.group.controls['68-B.5'].disable();
      this.group.controls['68-B.6'].disable();
      this.group.controls['68-B.7'].disable();
      this.group.controls['68-B.8'].disable();
      this.group.controls['68-B.9'].disable();
      this.group.controls['68-B.10'].disable();
      this.group.controls['68-B.11'].disable();
      this.group.controls['68-B.12'].disable();
      this.isNotEditable = true;
      this.handle68 = true;
    }
    else if (amt_1 === 'Y') {
      // this.handleTypeOfCert();
      this.group.controls['68-B.1'].enable();   
      this.group.controls['68-B.1a'].enable();
      this.group.controls['68-B.2'].enable();
      this.group.controls['68-B.2b'].enable();
      this.group.controls['68-B.3'].enable();
      this.group.controls['68-B.3b'].enable();
      this.group.controls['68-B.4'].enable();
      this.group.controls['68-B.4B'].enable();
      this.group.controls['68-B.5'].enable();
      this.group.controls['68-B.6'].enable();
      this.group.controls['68-B.7'].enable();
      this.group.controls['68-B.8'].enable();
      this.group.controls['68-B.9'].enable();
      this.group.controls['68-B.10'].enable();
      this.group.controls['68-B.11'].enable();
      this.group.controls['68-B.12'].enable();
    }
  }
  // handleTypeOfCert() {
  //   if (this.typeOfAction = 'Recertification') {
  //     // this.group.controls['68-B.01b'].disable();
  //     this.group.controls['68-B.1'].disable();
  //     this.group.controls['68-B.1a'].disable();
  //     this.group.controls['68-B.2'].disable();
  //     this.group.controls['68-B.2b'].disable();
  //     this.group.controls['68-B.3'].disable();
  //     this.group.controls['68-B.3b'].disable();
  //     this.group.controls['68-B.4'].disable();
  //     this.group.controls['68-B.4B'].disable();
  //     this.group.controls['68-B.5'].disable();
  //     this.group.controls['68-B.6'].disable();
  //     this.group.controls['68-B.7'].disable();
  //     this.group.controls['68-B.8'].disable();
  //     this.group.controls['68-B.9'].disable();
  //     this.group.controls['68-B.10'].disable();
  //     this.group.controls['68-B.11'].disable();
  //     // this.group.controls['68-B.12'].disable();
  //     this.handle68 = true;
  //   } else if (this.typeOfAction = 'Certification') {
  //     // this.group.controls['68-B.01b'].enable()
  //     this.group.controls['68-B.1'].enable();
  //     this.group.controls['68-B.1a'].enable();
  //     this.group.controls['68-B.2'].enable();
  //     this.group.controls['68-B.2b'].enable();
  //     this.group.controls['68-B.3'].enable();
  //     this.group.controls['68-B.3b'].enable();
  //     this.group.controls['68-B.4'].enable();
  //     this.group.controls['68-B.4B'].enable();
  //     this.group.controls['68-B.5'].enable();
  //     this.group.controls['68-B.6'].enable();
  //     this.group.controls['68-B.7'].enable();
  //     this.group.controls['68-B.8'].enable();
  //     this.group.controls['68-B.9'].enable();
  //     this.group.controls['68-B.10'].enable();
  //     this.group.controls['68-B.11'].enable();
  //     // this.group.controls['68-B.12'].enable();

  //   }
  // }
  disableforTypeofAction(){
    this.group.controls['68-B.01b'].disable();
    this.group.controls['68-B.1'].disable();
    this.group.controls['68-B.1a'].disable();
    this.group.controls['68-B.2'].disable();
    this.group.controls['68-B.2b'].disable();
    this.group.controls['68-B.3'].disable();
    this.group.controls['68-B.3b'].disable();
    this.group.controls['68-B.4'].disable();
    this.group.controls['68-B.4B'].disable();
    this.group.controls['68-B.5'].disable();
    this.group.controls['68-B.6'].disable();
    this.group.controls['68-B.7'].disable();
    this.group.controls['68-B.8'].disable();
    this.group.controls['68-B.9'].disable();
    this.group.controls['68-B.10'].disable();
    this.group.controls['68-B.11'].disable();
    this.handleTypofCert = true;
    this.handle68 = true; 
  }
  enableforTypeofAction(){
    this.group.controls['68-B.01b'].enable();
    this.group.controls['68-B.1'].enable();
    this.group.controls['68-B.1a'].enable();
    this.group.controls['68-B.2'].enable();
    this.group.controls['68-B.2b'].enable();
    this.group.controls['68-B.3'].enable();
    this.group.controls['68-B.3b'].enable();
    this.group.controls['68-B.4'].enable();
    this.group.controls['68-B.4B'].enable();
    this.group.controls['68-B.5'].enable();
    this.group.controls['68-B.6'].enable();
    this.group.controls['68-B.7'].enable();
    this.group.controls['68-B.8'].enable();
    this.group.controls['68-B.9'].enable();
    this.group.controls['68-B.10'].enable();
    this.group.controls['68-B.11'].enable();
    this.handleTypofCert = false;
  }
}
