import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-qc39-child-care-verificationform',
  templateUrl: './qc39-child-care-verificationform.component.html',
  styleUrls: ['./qc39-child-care-verificationform.component.css']
})
export class QC39ChildCareVerificationformComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }
  convertSpanishPDF() {
    this.callGenerateSpanishPDF.emit(undefined);
  }
}