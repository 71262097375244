import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ViewChild } from "@angular/core";
import * as Highcharts from "highcharts";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CaseViewComponent } from "./case-view/case-view.component";
import { ApiManager } from "../common/api-manager.service";
import { ConstantsService, USER_ROLES } from "../common/constants.service";
import { WorkItemsComponent } from "../shared-module/work-items/work-items.component";
import { NavigationService } from "../common/navigation.service";
import { StorageService } from "../common/storage.service";
import { query } from "@angular/animations";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppUtilService } from "../common/app.util.service";
import moment from "moment";

@Component({
  selector: "app-home-page",
  templateUrl: "./home-page.component.html",
  styleUrls: ["./home-page.component.css"],
})
export class HomePageComponent implements OnInit {
  public samplingForm: FormGroup;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  send_date = new Date();
  formattedDate: any;
  sliced = {};
  errordata = false;
  // datafromapi=[];
  chart;
  chartError;
  updateFlag = false;
  updateErrorFlag = false;
  Highcharts = Highcharts;
  HighchartsError = Highcharts;
  chartConstructor = "chart";
  chartErrorConstructor = "chartError";
  chartCallback;
  chartErrorCallback;
  chartOptions = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: "Overall Case Load",
    },

    colors: [
      "#058DC7",
      "#50B432",
      "#ED561B",
      "#DDDF00",
      "#24CBE5",
      "#64E572",
      "#FF9655",
      "#FFF263",
      "#6AF9C4",
    ],
    backgroundColor: {
      linearGradient: [0, 0, 500, 500],
      stops: [
        [0, "rgb(255, 255, 255)"],
        [1, "rgb(240, 240, 255)"],
      ],
    },

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        type: "pie",
        data: [],
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
        },
      ],
    },
  };
  chartErrorOptions = {
    chartError: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
    },
    title: {
      text: "Overall Error Rate",
    },

    colors: [
      "#058DC7",
      "#50B432",
      "#ED561B",
      "#DDDF00",
      "#24CBE5",
      "#64E572",
      "#FF9655",
      "#FFF263",
      "#6AF9C4",
    ],
    backgroundColor: {
      linearGradient: [0, 0, 500, 500],
      stops: [
        [0, "rgb(255, 255, 255)"],
        [1, "rgb(240, 240, 255)"],
      ],
    },

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        type: "pie",
        data: [],
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },
        },
      ],
    },
  };
  labelmap: Map<string, string> = new Map<string, string>();
  map: Map<any, any> = new Map();
  pquestion;

  labelmapAnalyst: Map<string, string> = new Map<string, string>();
  mapAnalyst: Map<any, any> = new Map();
  pquestionAnalyst;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  keys: Set<string> = new Set();
  data: any;
  today;

  constructor(
    private readonly router: Router,
    public constantsService: ConstantsService,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private apiManager: ApiManager,
    private navigationService: NavigationService,
    private storageService: StorageService,
    private fb: FormBuilder,
    public appUtilService: AppUtilService
  ) {
    this.today = new Date().toUTCString();

    this.labelmap.set("fspNgtvCnt", "SNAP Negative (-)");
    this.labelmap.set("fspPstvCount", "SNAP Postive (+)");
    this.labelmap.set("otoCount", "OTO");
    // this.labelmap.set("esapCount", "ESAP");
    this.labelmap.set("esapPstvCount", "ESAP Positive");
    this.labelmap.set("esapNgtvCount", "ESAP Negative");

    // this.labelmap.set("pastDueDate", "past Due Date");

    //  this.labelmap.set("tcaCount", "TCA");
    // git this.labelmap.set("maCount", "MA");
    this.labelmapAnalyst.set("fspPstvCount", "FSP Positive");
    this.labelmapAnalyst.set("fspNgtvCnt", "FSP Negative");
    // this.labelmapAnalyst.set("fspPstvInProgress","FSP Positive");
    // this.labelmapAnalyst.set("fspNgtvInProgress","FSP Negative");
    this.labelmapAnalyst.set("esapPstvCount", "ESAP Positive");
    this.labelmapAnalyst.set("esapNgtvCount", "ESAP Negative");
    this.labelmapAnalyst.set("otoCount", "OTO");
    this.labelmapAnalyst.set("PostDueDate", "total Post Due Date");
    this.labelmapAnalyst.set("Total", "Total");

    const self = this;
    this.chartCallback = (chart) => {
      // saving chart reference
      self.chart = chart;
    };
    const error = this;
    this.chartErrorCallback = (chartError) => {
      error.chartError = chartError;
    };
  }

  slicedData;
  processed_json = new Array();
  temp = [];

  determineAccess() {
    let path = "";
    path = this.constantsService.getDashboardPage();
    if (path !== "homePage") {
      this.router.navigate([path]);
      return false;
    } else {
      return true;
    }
  }

  //Dialog
  openDialog(action: any, element: any) {
    this.constantsService.dashboardMonth =
      this.samplingForm.value.samplingMonth;
    const dialogRef = this.dialog.open(CaseViewComponent, {
      height: "94%",
      width: "94%",
      data: { action: action, source: element.source },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      this.populateData();
    });
  }

  //Json Table2 mapping html to ts
  casedataSource = new MatTableDataSource();
  casedataSourceAnalyst = new MatTableDataSource();
  casedataSourceDialog = new MatTableDataSource();
  casedisplayedColumns = ["source", "case", "assigned", "unassigned"];
  casedisplayedAnalystColumns = [
    "source",
    "Cases",
    "InProgress",
    "Submitted",
    "PostDueDate",
  ];
  casedisplayedColumnsError = [
    "programName",
    "totalCases",
    "errorCount",
    "correctCount",
  ];
  casedataSourceerror = new MatTableDataSource();

  ngAfterViewInit() {
    this.casedataSource.paginator = this.paginator;
    this.casedataSource.sort = this.sort;
    this.casedataSourceAnalyst.paginator = this.paginator;
    this.casedataSourceAnalyst.sort = this.sort;
    this.casedataSourceerror.paginator = this.paginator;
    this.casedataSourceerror.sort = this.sort;
  }
  buildForm() {
    this.samplingForm = this.fb.group({
      samplingMonth: ["", [Validators.required]],
    });
  }

  // Get last 12 months for samplingMonths
  getMonths() {
    let samplingMonths = [];
    for (let i = 0; i < 12; i++) {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1 - i;
      if (month <= 0) {
        year -= 1;
        month += 12;
      }
      let val, viewVal;

      if (month < 10) {
        val = `${year}0${month}`;
        viewVal = `0${month}/${year}`;
      } else {
        val = `${year}${month}`;
        viewVal = `${month}/${year}`;
      }
      let obj = { value: val, viewValue: viewVal };
      samplingMonths.push(obj);
    }
    console.log(samplingMonths);
    return samplingMonths;
  }

  populateData() {
    //get call for Table
    // this.httpClient.get(this.cases_data).subscribe(result => {
    const queryParams: any[] = [];
    queryParams.push("QC");
    const samplingData = this.samplingForm.value;
    console.log(samplingData);
    //samplingData.samplingMonth = this.appUtilService.changeDateToMonthFormat(samplingData.samplingMonth);
    queryParams.push(samplingData.samplingMonth);
    let url;
    if (this.constantsService.isAnalystUser()) {
      url = "getRtDashBoardDataAnalyst";
      //     const samplingData = this.samplingForm.value;
      //     samplingData.samplingMonth = this.appUtilService.changeDateToMonthFormat(samplingData.samplingMonth);
      // queryParams.push(samplingData.samplingMonth) ;
    } else {
      url = "getRtDashBoardData";
    }
    this.apiManager
      .fetchData(
        url,
        undefined,
        ApiManager.GET,
        undefined,
        undefined,
        undefined,
        queryParams
      )
      .subscribe((res) => {
        if (res) {
          this.data = res;
          this.casedataSource.data = [];
          this.casedataSourceAnalyst.data = [];

          //vertical Table code starts
          this.data.forEach((row) => {
            Object.keys(row).forEach((key) => {
              this.keys.add(key);
            });
            let temp: any = {};
            this.keys.forEach((key) => {
              temp[key] = row[key];
            });
          });
          Array.from(this.keys)
            .sort((a, b) => b.localeCompare(a))
            .forEach((key) => {
              let temp: any = {};
              if (key == "type") {
                return;
              }
              temp.source = key;
              this.data.forEach((row) => {
                if (this.labelmap.get(key)) {
                  //skip BPE counts
                  temp[row.type] = row[key];
                }
                if (this.labelmapAnalyst.get(key)) {
                  //skip BPE counts
                  temp[row.type] = row[key];
                }
              });
              if (this.labelmap.get(key)) {
                //skip BPE counts
                this.map.set(temp.source, temp);
                this.casedataSource.data.push(temp);
              }
              if (this.labelmapAnalyst.get(key)) {
                //skip BPE counts
                this.map.set(temp.source, temp);
                this.casedataSourceAnalyst.data.push(temp);
              }
            });
          this.data.filter((row) => {
            row.u;
          });

          this.populateChartData(res);
        }

        let totalCases = 0;
        let totalInProgress = 0;
        let totalPostDueDate = 0;
        let totalSubmitted = 0;
        this.casedataSourceAnalyst.data.forEach((element: any) => {
          totalCases += element.Cases;
          totalInProgress += element.InProgress;
          totalPostDueDate += element.PostDueDate;
          totalSubmitted += element.Submitted;
        });
        let json = {
          Cases: totalCases,
          InProgress: totalInProgress,
          PostDueDate: totalPostDueDate,
          Submitted: totalSubmitted,
          source: "Total",
        };

        this.casedataSourceAnalyst.data.push(json);

        this.casedataSource.data = this.casedataSource.data.sort();
        this.casedataSourceAnalyst.data =
          this.casedataSourceAnalyst.data.sort();
      });
  }

  sampleMonths = [];
  ngOnInit() {
    if (!this.determineAccess()) {
      return;
    }
    //method of table 2
    this.buildForm();
    const tempDate = moment().format("YYYYMM");
    this.samplingForm.get("samplingMonth").patchValue(tempDate);
    this.sampleMonths = this.getMonths();

    this.populateData();

    // this.populateChartData();

    // selectedSampling.samplingMonth = tempDate.substring(0, 4) + '-' + tempDate.substring(4) + '-01';

    // this.updateChart();
  }

  fecthAndPopulateChartData() {
    this.sendGetRequest().subscribe((data) => {
      if (data) {
        this.sliced = Object.keys(data)
          .slice(0, 5)
          .reduce((result, key) => {
            result[key] = data[key];
            return result;
          }, {});
        var ctr = 0;
        Object.keys(this.sliced[0]).forEach((key) => {
          if (key != "type") {
            var tmp = {};
            tmp["name"] = this.labelmap.get(key);
            tmp["y"] = parseInt(this.sliced[0][key]);
            if (this.labelmap.get(key)) {
              this.temp.push(tmp);
            }
            ctr = ctr + 1;
            this.updateChart();
          }
        });
      }
    });
  }

  populateChartData(data) {
    if (data) {
      this.sliced = Object.keys(data)
        .slice(0, 5)
        .reduce((result, key) => {
          result[key] = data[key];
          return result;
        }, {});
      var ctr = 0;
      Object.keys(this.sliced[0]).forEach((key) => {
        if (key != "type") {
          var tmp = {};
          tmp["name"] = this.labelmap.get(key);
          tmp["y"] = parseInt(this.sliced[0][key]);
          if (this.labelmap.get(key)) {
            this.temp.push(tmp);
          }
          ctr = ctr + 1;
          this.updateChart();
        }
      });
    }
  }

  updateChart() {
    const self = this;
    self.chartOptions.series[0].data = this.temp;
    self.updateFlag = true;
    self.chartErrorOptions.series[0].data = this.temp;
    self.updateErrorFlag = true;
  }

  updateErrorChart() {
    const error = this,
      chart = this.chartError;
    setTimeout(() => {
      error.chartOptions.series[0].data = this.temp;
      console.log("setting temp....", this.temp);
      error.updateFlag = true;
      error.chartErrorOptions.series[0].data = this.temp;
      error.updateErrorFlag = true;
    }, 2000);
  }

  //Get call for chart
  sendGetRequest() {
    let httpHeaders = new HttpHeaders();
    httpHeaders.set("Content-Type", "application/json");
    httpHeaders.set(
      "role",
      "cn=EE_SUPUSR,|cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL,|cn=EE_SUPVRS"
    );
    httpHeaders.set("uid", "localUser");
    httpHeaders.set("uniqueid", "99999999999");
    // return this.httpClient.get('getRtDashBoardData', { headers: httpHeaders });
    const queryParams: any[] = [];
    queryParams.push("QC");
    const samplingData = this.samplingForm.value;
    samplingData.samplingMonth = this.appUtilService.changeDateToMonthFormat(
      samplingData.samplingMonth
    );
    queryParams.push(samplingData.samplingMonth);
    let url;
    if (this.constantsService.isAnalystUser()) {
      url = "getRtDashBoardDataAnalyst";
      //     const samplingData = this.samplingForm.value;
      //     samplingData.samplingMonth = this.appUtilService.changeDateToMonthFormat(samplingData.samplingMonth);
      // queryParams.push(samplingData.samplingMonth) ;
    } else {
      url = "getRtDashBoardData";
    }
    return this.apiManager.fetchData(
      "getRtDashBoardData",
      undefined,
      ApiManager.GET,
      undefined,
      undefined,
      undefined,
      queryParams
    );
  }
  getErrorData() {
    this.sendGetRequestError().subscribe((data) => {
      this.casedataSourceerror.data = [];
      this.casedataSourceerror = data;
      this.updateErrorChart();
    });
  }

  sendGetRequestError() {
    this.errordata = true;
    // return this.httpClient.get('getRtDashBoardData', { headers: httpHeaders });
    const queryParams: any[] = [];
    // var rvwMo =
    //   new Date().getFullYear().toString() +
    //   "0" +
    //   (new Date().getMonth() + 1).toString().slice(-2);
    // console.log("rvwMo", rvwMo);
    const samplingData = this.samplingForm.value;
    queryParams.push("QC");
    queryParams.push(samplingData.samplingMonth);
    return this.apiManager.fetchData(
      "getTotalCasesErrorCases",
      undefined,
      ApiManager.GET,
      undefined,
      undefined,
      undefined,
      queryParams
    );
  }

  print() {
    window.print();
  }

  onAlertTabChanged(event) {
    if (event.index == 1) {
    }
  }
  onUpdatesTabChanged(event) {
    if (event.index == 1) {
    }
  }
  next() {}
  back() {}
}
