import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';


@Component({
  selector: 'app-no-req-verification-info',
  templateUrl: './no-req-verification-info.component.html',
  styleUrls: ['./no-req-verification-info.component.css']
})
export class NoReqVerificationInfoComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
