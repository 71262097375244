import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-wpr-case-review',
  templateUrl: './wpr-case-review.component.html',
  styleUrls: ['./wpr-case-review.component.css']
})
export class WprCaseReviewComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}