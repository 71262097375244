import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-dependent-care-deductionthree',
  templateUrl: './dependent-care-deductionthree.component.html',
  styleUrls: ['./dependent-care-deductionthree.component.css']
})
export class DependentCareDeductionthreeComponent extends BaseComponent implements OnInit {
  isNotEditable: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

}
