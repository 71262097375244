import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../components/field.interface";
import { NavigationService } from '../../common/navigation.service';
@Component({
  selector: "app-input",
  template: `
  <div class="row" [ngClass]="{'alt-strips': oddEven}">
  
  <div *ngIf="showQDesc" class="col-sm-8">
    <mat-label>{{field.qDesc}}</mat-label>
  </div>

  <div class="col-sm-4">
    <mat-form-field  class="fullWidth" appearance="outline" [formGroup]="group">
        <input matInput [formControlName]="field.name">
        <ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
    <!--<mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>-->
    </ng-container>
    </mat-form-field>
    </div>
  </div>

`,
  styles: []
})
export class InputComponent implements OnInit {
   field: FieldConfig;
  group: FormGroup;
  showQCd: boolean = true;
  showQDesc: boolean = true;


 


  constructor(
    public navigationService: NavigationService,
  ) {}
  ngOnInit() {}

  get oddEven() {

    const reminder = parseInt(this.field.name.split('.')[this.field.name.split('.').length-1]) % 2;
    if (reminder === 0 && this.field.qmode === 'OP'
    && this.navigationService.appCd !== 'QC' ) {
        return true;
    } else if (reminder === Math.round(reminder)) {
        return false;
    }
  }

}
