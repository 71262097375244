import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-household-tdaa',
  templateUrl: './household-tdaa.component.html',
  styleUrls: ['./household-tdaa.component.css']
})

export class HouseholdTDAAComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  changeofQf_150_4_1(val) {
    if(val === 'N'){

      this.group.controls['150.4.2'].disable();
      this.setControlValue('150.4.3','N')
    }
    else {
      this.group.controls['150.4.2'].enable();
      this.setControlValue('150.4.3','')
    }
  }

  changeofQf_150_4_2(val) {
    if(val === 'N'){  
      this.setControlValue('150.4.3','N')
    }
    else {
      this.setControlValue('150.4.3','Y')
    }
  }

}