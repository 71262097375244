import { Component, OnInit, Inject , Optional } from "@angular/core";
import { MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ViewChild } from "@angular/core";
import * as Highcharts from "highcharts";
import { Router, ActivatedRoute } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiManager } from 'src/app/common/api-manager.service';
import { ConstantsService } from 'src/app/common/constants.service';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { DataShareService } from '../../common/data-share.service';
import { NavigationService } from '../../common/navigation.service';
import { AppUtilService } from '../../common/app.util.service';
import {DeleteDialogComponent} from '../delete-dialog/delete-dialog.component';

export interface PeriodicElement {
  fndngRspDt: any;
  type: any;
  fndngRsp: any;
  action: any;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    fndngRspDt: '',
    type: '',
    fndngRsp: '',
    action: ''
  }
];

@Component({
  selector: 'app-find-resp',
  templateUrl: './find-resp.component.html',
  styleUrls: ['./find-resp.component.css']
})

export class FindRespComponent implements OnInit {

  scheduledisplayedColumns: string[] = [
    'fndngRspDt', 'type', 'fndngRsp', 'action'];
  findRespSource = ELEMENT_DATA;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  LeadReviewers: LeadReviewer[] = [
    { value: "1", viewValue: "Reviewer" },
    { value: "2", viewValue: "LDSS Worker" },
  ];

  isNotEditable: boolean  = false;
  popupMode: boolean = false;
  currentRvwNum;

  constructor(
    @Optional() public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private shareService: DataShareService,
    public constantsService: ConstantsService,
    private apiManager: ApiManager,
    private router: Router,
    public navigationService: NavigationService,
    public appUtilService: AppUtilService,
  ) {

    if (this.data && this.data.rvwNum) {
      this.popupMode = true;
      this.currentRvwNum = this.data.rvwNum;
    } else if ( this.constantsService.ReviewInfo && this.constantsService.ReviewInfo.rvwNum ) {
      this.currentRvwNum = this.constantsService.ReviewInfo.rvwNum ;
    }
                          
  }


  ngOnInit() {
    this.getPageData();
    this.determineAccess();
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/bpe/fsme/findings-response'] != null && ref.accessMap['/bpe/fsme/findings-response'].accessMode != 'rw')) {

      this.isNotEditable = true;
    }

    
    
  }

  isRecordEditable(entity) {
    let ret = true;
    if ( this.constantsService.isLDSSUser() && entity.type === '1' ) {
      ret = false;
    }

    return ret;

  }

  getPageData() {
    const caseId: any = '';
    const queryParams: any[] = [];
    // let appCd: string = '';
    // appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    // queryParams.push(appCd);

    queryParams.push(this.currentRvwNum);

    this.apiManager.fetchData('getFindingsResponses', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
      .subscribe(
        res => {
          this.findRespSource = res;
        }
      );
  }
  back() {
    this.navigationService.navigatePage(this.router.url, 'back');
  }
  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }

  openDialog(idx) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      index: idx,
      data: this.findRespSource && this.findRespSource.length > 0 ? this.findRespSource[idx] : null,
      rvwData: this.data,
      popupMode: this.popupMode,
    };

    const dialogRef = this.dialog.open(FindResponseDialog, dialogConfig);
    dialogRef.updateSize('75%', '85%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log('afterClose', val);
        //this.ngOnInit();
        this.findRespSource = val;
      }
      this.ngOnInit();
    });
  }

  openDeleteDialog(index) {
   
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '350px';
      dialogConfig.height = '180px';
      dialogConfig.data = index;

      const dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(val => {
        if (val == 'yes') {

          const queryParams: any[] = [];
          let appCd: string = '';
          appCd = this.constantsService.getAppCd(this.navigationService.appCd);

          let findRespObj: any = this.findRespSource[index];
          findRespObj.dirtyStatus =  'DELETE';
          this.apiManager.fetchData('saveFindingsResponses', undefined, ApiManager.POST, findRespObj, undefined, undefined, queryParams)
              .subscribe(res => {
                if (res) {

                  this.findRespSource = res;
                  // setTimeout(() => { this.findRespSource.paginator = this.paginator; });
                }
                this.dialog.closeAll();
              });
        }
      });
  }

  getTypeValue(cd){
    let typeValue;
    this.LeadReviewers.filter(item => item.value == cd ).map(val=> {
      typeValue = val.viewValue;
    })
    return typeValue;
  }  
  
}

export interface County {
  value: string;
  viewValue: string;
}

export interface Reviewer {
  value: string;
  viewValue: string;
}

export interface LeadReviewer {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'find-response',
  templateUrl: 'find-respdialog.html',
  styleUrls: ['./find-respdialog.css']
})

export class FindResponseDialog implements OnInit {
  findRespForm: FormGroup;
  LeadReviewers: LeadReviewer[] = [
    { value: "1", viewValue: "Reviewer" },
    { value: "2", viewValue: "LDSS Worker" },
  ];

  isNotEditable: boolean = false;
  popupMode: boolean = false;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public constantsService: ConstantsService,
    private apiManager: ApiManager,
    public navigationService: NavigationService,
    public appUtilService: AppUtilService,
  ) { 
    
  }

  ngOnInit() {
    this.buildForm();

    if (this.data.index > -1) {
      const selectedFindResp = this.data.data;
      this.findRespForm.patchValue(selectedFindResp);
      this.findRespForm.get('fndngRspDt').patchValue(this.appUtilService.changeDateYYYYMMDD(selectedFindResp.fndngRspDt));
      this.popupMode = this.data.popupMode;
    }
    this.determineAccess();
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/bpe/fsme/findings-response'] != null && ref.accessMap['/bpe/fsme/findings-response'].accessMode != 'rw')) {

      this.isNotEditable = true;
    }

    
  }

  buildForm() {
    this.findRespForm = this.fb.group({
      qaFndngRspId: [''],
      fndngRsp: [''],
      type: ['', Validators.required],
      uId: [''],
      rvwNum: [''],
      appType: ['BPE-FSME'],
      dirtyStatus: ['INSERT'],
      fndngRspDt: ['', Validators.required],
    });
  }


  save(model) {
    console.log(model);
    const queryParams: any[] = [];
    // queryParams.push('BPE-FSME');

    let appCd: string = '';
    appCd = this.constantsService.getAppCd(this.navigationService.appCd);

    let findRespObj: any = {
      dirtyStatus: model.dirty && model.value.qaFndngRspId !== '' ? 'UPDATE' : 'INSERT',
      qaFndngRspId: model.value.qaFndngRspId === '' ? null : parseInt(model.value.qaFndngRspId),
      fndngRsp: model.value.fndngRsp,
      type: model.value.type,
      uId: this.constantsService.getSecurityObject().user.uniqueId,
      rvwNum: this.constantsService.ReviewInfo.rvwNum,
      appType: appCd,
      fndngRspDt: model.value.fndngRspDt
    }


    this.apiManager.fetchData('saveFindingsResponses', undefined, ApiManager.POST, findRespObj, undefined, undefined, queryParams)
      .subscribe(
        res => {
          this.dialogRef.close(res);
        });
  }
}