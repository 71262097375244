/// <reference types="dwt" />
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as JSZip from 'jszip';
import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ElementRef } from '@angular/core';

import { NgForm } from '@angular/forms';

import { FormGroup, FormBuilder, FormArray } from '@angular/forms';

import { Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { DocumentSelectionInfo, DocumentUploadInfo } from 'src/app/model/api/document-selection.model';
import { DataShareService } from 'src/app/common/data-share.service';
import { ConstantsService } from 'src/app/common/constants.service';
import { AppUtilService } from 'src/app/common/app.util.service';
import { ApiManager } from 'src/app/common/api-manager.service';
import { PersonName } from 'src/app/model/person-name.class';
// import { HouseholdMember } from 'app/model/api/household-member.class';

declare var Dynamsoft: any;

@Component({
  selector: 'app-file-scan',
  templateUrl: './file-scan.component.html',
  styleUrls: ['./file-scan.component.css']
})

export class FileScanComponent implements OnInit {
  Dynamsoft: any;
  data: any;
  selectedScreen: string;
  dwObject: WebTwain;
  scanFile: WebTwain;
  uploading: boolean = false;
  isNotEditable: boolean = false;
  isFileScanned: boolean;
  fileName: any;
  docNames: any;
  existingFileNameList: any;
  globalCategoryObj = {};
  img_height: string;
  caseId: string = null;
  img_width: string;
  interpolationDD: string;
  subtype = null;
  lkptype = null;
  lkpshortval = null;
  selectedInterpolation: number;
  searchDocEvent: any;
  verificationName: string = null;
  fileUploading: Boolean = false;
  imgChangeDialog: any;
  subtypeSearch = null;
  subTypeSearchCd = null;
  showDialog: boolean;
  // scanForm: FormGroup;
  queryParams: string[] = [];
  documentOptionsName: String = '';
  DW_TotalImage: any = 0;
  DW_CurrentImage: any = 0;
  individualId: string = null;
  _iLeft: any;
  docInfo: FormGroup;
  memberSelected: string;
  documentArrayRefrence: any = [];
  // members: HouseholdMember[] = [];
  _iTop: any;
  _iRight: any;
  noOfDocs;
  uploadProgress: any;
  _iBottom: any;
  lookupValues: any[] = [];
  totalCount: number = 0;
  maxfileCount: number = undefined;
  disableAddDocumentButton: boolean = false;
  public genericDisplayString = 'File';
  uploadedFiles: any[] = [];
  readOnly = false;
  docOptionsUpdated = false;
  docMetadata = [];
  fileUploaded: Boolean = false;
  docs = [];
  hasError: boolean = null;
  docResource;
  docCount = 0;
  errorAlert: string = null;
  filesToUpload = [];
  file: any;
  //frmData: FormData = null;
  filesToDownload: DocumentSelectionInfo[] = [];
  DW_PreviewMode: any;
  docOptions = [
    "Agreement",
    "Application",
    "Assets",
    "Clearances",
    "Correspondence",
    "DisabilityBenefitsAdvocacy",
    'Disqualifications',
    "Expenses",
    "Fiscal",
    "Identity",
    "Income",
    "Legal",
    "LTCForms",
    "MCPSpecialPrograms",
    "Medical",
    "QualityControl",
    "Redeterminations",
    "Referrals",
    "Verifications",
    "WorkProgram"
  ];
  docOptions2 = [
    "Agreement",
    "Application",
    "Assets",
    "Clearances",
    "Correspondence",
    "DisabilityBenefitsAdvocacy",
    'Disqualifications',
    "Expense",
    "Fiscal",
    "Identity",
    "Income",
    "Legal",
    "LTCForms",
    "MCPSpecialPrograms",
    "Medical",
    "QualityControl",
    "Redeterminations",
    "Referrals",
    "Verifications",
    "WorkProgram"
  ];
  docInfoValue: any;

  @Input() closable = true;
  @Input() visible: boolean;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public shareService: DataShareService,
    public constantsService: ConstantsService,
    public appUtilService: AppUtilService,
    public _platformStrategy: LocationStrategy,
    public apiManager: ApiManager,
    public router: Router,
    public fb: FormBuilder) {
    this.docNames = [];
    this._iLeft = 0;
    this._iTop = 0;
    this._iRight = 0;
    this._iBottom = 0;
    this.buildForm();
    const ref = this.constantsService.getSecurityObject();
    const routeState = this.router.getCurrentNavigation().extras.state;
    this.data = {
      fnsNames: routeState ? routeState.fnsNames : false,
      reviewNum: routeState ? routeState.reviewNum : "",
      reviewType: routeState ? routeState.reviewType : ""
    }
    // this.members = ConstantsService.householdDetails;
    // if ((ref.accessMap['/dashboard/screening/contactInformationV1'] != null && ref.accessMap['/dashboard/screening/contactInformationV1'].accessMode != 'rw')
    //   || (ConstantsService.humanServiceModel.benefitsCase.caseStatusCode != null && ConstantsService.humanServiceModel.benefitsCase.caseStatusCode == 'CL')) {
    //   this.isNotEditable = true;
    // }
    // if (Dynamsoft) {
    //   Dynamsoft.WebTwainEnv.Load();
    //   Dynamsoft.WebTwainEnv.Trial = false;
    //   Dynamsoft.WebTwainEnv.ProductKey = 'f0068MgAAACJ3b5cCUdaKoq8ZDpT0C95I+QDN8Z7cqZYK2797MNIJFl9pa2GmqWI+LZrgDOWpakDQRF483NdjyPv9+qx/03w=';
    //   Dynamsoft.WebTwainEnv.ResourcesPath = 'assets/images/dwt/scanner';
    // }
    //this.loadScripts();
  }

  setSelectedScreen(val) {
    this.selectedScreen = val;
  }

  populateDocInfo() {
    this.docInfoValue = this.constantsService.docInfo;
    if (this.docInfoValue) {
      if (this.docInfoValue.docType) {
        this.docInfo.get('docType').patchValue(this.docInfoValue.docType);
        this.lkptype = this.docInfoValue.docType;
        console.log('this.globalCategoryObj==>', this.globalCategoryObj)
        let docEvents = this.docInfoValue.docType.split(' ');
        let subCategory = '';
        docEvents.map(event => subCategory += event);
        this.subtype = this.lookupValues[subCategory];
      }
      if (this.docInfoValue.docSubtype) {
        this.docInfo.get('docSubtype').patchValue(this.docInfoValue.docSubtype);
      }
      if (this.docInfoValue.hhMember) this.docInfo.get('hhMember').patchValue(this.docInfoValue.hhMember);
    }
  }

  interpolationKeys(): Array<string> {
    return Object.keys(EnumDWT_InterpolationMethod).filter(
      (type) => isNaN(<any>type) && type !== 'values'
    );
  }

  buildForm() {
    this.docInfo = this.fb.group({
      docType: [this.docOptions2[0]],
      docSubtype: [null],
      hhMember: [null]
    })
  }

  // loadScripts() {
  //   let node = document.createElement('script');
  //   var x = document.getElementsByTagName('head');
  //   node.src = 'src/assets/images/dwt/scanner/dynamsoft.webtwain.initiate.js';
  //   node.type = 'text/javascript';
  //   node.async = true;
  //   node.charset = 'utf-8';
  //   let node1 = document.createElement('script');
  //   node1.src = 'src/assets/images/dwt/scanner/dynamsoft.webtwain.config.js';
  //   node1.type = 'text/javascript';
  //   node1.async = true;
  //   node1.charset = 'utf-8';
  //   document.getElementsByTagName('head')[0].appendChild(node);
  //   document.getElementsByTagName('head')[0].appendChild(node1);
  // }

  close() {
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  ngOnInit() {
    // this.lookups.getLookUpValues(['Documents', 'Agreement',
    //   'Applications',
    //   'Assets',
    //   'Clearances',
    //   'Correspondence',
    //   'DisabilityBenefitsAdvocacy',
    //   'Disqualifications',
    //   'Expenses',
    //   'Fiscal',
    //   'Identity',
    //   'Income',
    //   'Legal',
    //   'LTCForms',
    //   'MCPSpecialPrograms',
    //   'Medical',
    //   'QualityControl',
    //   'Redeterminations',
    //   'Referrals',
    //   'Verifications',
    //   'WorkProgram'], 'en_US')
    //   .then(res => {
    //     this.lookupValues = res;
    //     if (this.docInfoValue) {
    //       if (this.docInfoValue.docType) {
    //         let value = this.lookups.getLookupDesc('Documents', this.docInfoValue.docType);
    //         this.lkptype = value;
    //         console.log('this.globalCategoryObj==>', this.globalCategoryObj)
    //         let docEvents = value.split(' ');
    //         let subCategory = '';
    //         docEvents.map(event => subCategory += event);
    //         this.subtype = this.lookupValues[subCategory];
    //       }
    //       if (this.docInfoValue.docSubtype) {
    //         let getSelectedSubType = this.subtype ? this.subtype.filter(subType => subType.lkpDesc === this.docInfoValue.docSubtype) : [];
    //         this.lkpshortval = getSelectedSubType.length > 0 ? getSelectedSubType[0].lookupLongVal : null;
    //       }
    //     }
    //   }).catch(err => {
    //     console.log(err);
    //   });
    let validDocOptions = [];
    this.docOptions2.map(optn => {
      let subOptns = this.lookupValues[optn.toString()];
      if (subOptns ? subOptns.length === 0 : false) {
      }
      else this.globalCategoryObj[optn] = subOptns;
    });
    for (let key in this.globalCategoryObj) {
      validDocOptions.push(key);
    }
    this.docOptions2 = validDocOptions;
    this.subtype = this.globalCategoryObj[this.docOptions2[0]];
    if (Dynamsoft) {
      Dynamsoft.WebTwainEnv.Load();
      Dynamsoft.WebTwainEnv.Trial = false;
      Dynamsoft.WebTwainEnv.ProductKey = 'f0068NQAAADFHV7UVUwws72K/ZVw9Qx8T9Aid4aQUsMrEcvKlcqq6BkbD+ZVSQZeNSmxQboVaVt5+wQi1H5fbghmMDVW8HP8=';
      Dynamsoft.WebTwainEnv.ResourcesPath = 'assets/images/dwt/scanner';
    }
    this.populateDocInfo();
    //Dynamsoft.WebTwainEnv.Load();
    // this.appService.listFiles().subscribe(response => {
    //   this.existingFileNameList = response.json();
    //   for (var list = 0; list < this.existingFileNameList.length; list++) {
    //     if (this.existingFileNameList[list].documentName !== undefined) {
    //       this.docNames.push(this.existingFileNameList[list].documentName);
    //     }
    //   }
    // }, err => {
    //   alert("File upload failed, please try again");
    // })
    // for (let key in this.globalCategoryObj) {
    //   validDocOptions.push(key);
    // }
    // this.docOptions2 = validDocOptions;
    // this.subtype = this.globalCategoryObj[this.docOptions2[0]];
  }

  ngOnDestroy() {
    if (Dynamsoft) {
      Dynamsoft.WebTwainEnv.Unload();
    }
  }

  acquireImage() {
    this.dwObject = Dynamsoft.WebTwainEnv.GetWebTwain('dwtcontrolContainer');
    this.dwObject.RegisterEvent("OnTopImageInTheViewChanged", this.Dynamsoft_OnTopImageInTheViewChanged);
    this.dwObject.RegisterEvent("OnMouseClick", this.Dynamsoft_OnMouseClick);
    this.updatePageInfo();
    this.dwObject.RegisterEvent("OnPostTransfer", this.Dynamsoft_OnPostTransfer);
    this.dwObject.RegisterEvent("OnPostLoad", this.Dynamsoft_OnPostLoadfunction);
    this.dwObject.RegisterEvent("OnPostAllTransfers", this.Dynamsoft_OnPostAllTransfers);
    this.dwObject.RegisterEvent("OnImageAreaSelected", this.Dynamsoft_OnImageAreaSelected);
    this.dwObject.RegisterEvent("OnImageAreaDeSelected", this.Dynamsoft_OnImageAreaDeselected);
    this.dwObject.RegisterEvent("OnGetFilePath", this.Dynamsoft_OnGetFilePath);
    this.isFileScanned = true;

    const bSelected = this.dwObject.SelectSource();

    if (bSelected) {
      const onAcquireImageSuccess = () => {
        this.dwObject.CloseSource();
        //this.isFileScanned = true;
      };
      const onAcquireImageFailure = () => {
        this.dwObject.CloseSource();
        //this.isFileScanned = false;
      };
      this.dwObject.OpenSource();
      this.dwObject.AcquireImage({}, onAcquireImageSuccess, onAcquireImageFailure);
    }
  }

  onSubmit(scanFileForm: NgForm) {
    // if (this.selectedScreen == null || this.selectedScreen ==='') {
    //   this.selectedScreen = scanFileForm.controls.fnsName.value;
    // }
    this.appUtilService.showLoader();
    if (scanFileForm.valid) {
      this.fileName = scanFileForm.controls.fileName.value;
      //console.log("obj1 is "+this.dwObject);
      this.upLoad(this.dwObject, this.fileName);
    }
  }

  async upLoad(sObj: WebTwain, flname) {
    // console.log(this.selectedScreen)
    let convBlob = await this.toBlob(sObj);
    let bbFile: Blob = convBlob as Blob;
    let convFile = await this.blobToFile(bbFile, flname + '.tiff');
    let frmData = new FormData();

    //console.log("docSubtype value is "+this.docInfo.get('docSubtype').value);
    if (convFile != undefined) {
      frmData.append('fileFormData', convFile, flname + '.tiff');
      // frmData.append('documentTypeCd', this.docInfo.get('docSubtype').value);
      // frmData.append('verificationName', this.verificationName);
      // frmData.append('individualId', this.docInfo.get('hhMember').value);
      // frmData.append('lookupTypeCd', this.lkptype);
      // frmData.append('lookupShortVal', this.lkpshortval);
    }
    this.queryParams.push(this.data.reviewNum === "" ? this.constantsService.ReviewInfo.rvwNum : this.data.reviewNum);
    this.queryParams.push(flname);
    let roles = this.constantsService.getSecurityObject();
    //console.log("lookupTypeCd is ", this.lkptype);
    //console.log("lookupShortVal is ", this.lkpshortval);
    this.apiManager.fetchData(
      'saveScannedDocToFNS',
      {
        'fileFormData': frmData.get('fileFormData'),
        'elementNum': this.selectedScreen,
        // 'caseId': ConstantsService.humanServiceModel.benefitsCase.caseId,
        // 'verificationName': frmData.get('verificationName'),
        // 'documentTypeCd': frmData.get('documentTypeCd'),
        //'documentSubType': frmData.get('documentSubType'),
        // 'lookupShortVal': frmData.get('lookupShortVal'),
        // 'lookupTypeCd': frmData.get('lookupTypeCd'),
        'user': roles ? roles.user ? roles.user.uniqueId : null : null,
        'role': [roles ? roles.user ? roles.user.roles : null : null]
      },
      ApiManager.FILEUPLOAD,
      undefined,
      undefined,
      () => {
        this.fileUploading = false;
        //console.log('FILE FINISHED UPLOADING');
      },
      this.queryParams
    ).subscribe(res => {
      this.ngOnInit();
      if (res.fileName) {
        if (res.caseId) {
          this.totalCount = this.totalCount + 1;
          if (this.maxfileCount != undefined && (this.maxfileCount <= this.totalCount)) {
            this.disableAddDocumentButton = true;
          }
          let inputVal = (<HTMLInputElement>document.getElementById('fileInput'));
          // inputVal.value = '';
          let _fileName = this.genericDisplayString + (this.uploadedFiles.length + 1);
          if (!res.fileId) {
            alert("File ID not created for this file");
          }
          let doc = {
            docId: res.fileId,
            docName: res.fileName,
            documentTypeCd: res.docTypeCd,
            createdBy: res.createUserId,
            createdDt: res.createDt,
            updatedBy: res.updatedUserId,
            updatedDt: res.updatedDt
          }
          this.uploadedFiles.push(doc);
          this.docMetadata.push(doc);
          this.getDocSelectionInfo();
          let thisRef = this;
          setTimeout(() => {
            thisRef.uploadedFiles = [];
          }, 2000);
        }
        else {
          alert(res.fileName + " is infected, please upload good document");
        }
      }
      this.fileUploading = false;
      this.fileUploaded = true;
      // if (this.fileUploaded) {
      //   alert("File Uploaded Successfully")
      // }
      this.appUtilService.hideLoader();
    });
  }

  //this.dwObject
  toBlob(scanObj: WebTwain) {
    return new Promise(
      function (resolve, reject) {
        let imgArry: number[] = [];
        let totalNum: number = scanObj.HowManyImagesInBuffer;
        for (let i = 0; i < totalNum; i++) {
          imgArry.push(i);
        }
        scanObj.ConvertToBlob(imgArry, EnumDWT_ImageType.IT_TIF, (res) => {
          resolve(res);
        },
          (num, err) => {
            //console.log('error is ' + err);
          }
        );
      });
  }

  /*
     ToFile(blobHere, filenm) {
      return new Promise(
        function (resolve, reject) {
        let  scanFile = this.blobToFile(blobHere, filenm);
              this.dwObject.ConvertToBlob(imgArry, EnumDWT_ImageType.IT_TIF,
                (error, data) => {
                    if (error) {
                        reject(error);
                    } else {
                        resolve(data);
                    }
                });
            });
     }
     */

  public createJsonBlob<T>(content: T) {
    return new Blob([JSON.stringify(content)], { type: "application/json" });
  }

  public blobToFile(theBlob: Blob, fileName: string): File {
    var b: any = theBlob;
    b.lastModifiedDate = new Date();
    b.name = fileName;
    return <File>b;
  }

  //************************** Edit Image ******************************
  btnShowImageEditor_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.ShowImageEditor();
  }

  btnRotateLeft_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.RotateLeft(this.dwObject.CurrentImageIndexInBuffer);
  }

  btnRotateRight_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.RotateRight(this.dwObject.CurrentImageIndexInBuffer);
  }

  btnRotate180_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.Rotate(this.dwObject.CurrentImageIndexInBuffer, 180, true);
  }

  btnMirror_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.Mirror(this.dwObject.CurrentImageIndexInBuffer);
  }

  btnFlip_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.Flip(this.dwObject.CurrentImageIndexInBuffer);
  }

  btnRemoveCurrentImage_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.RemoveAllSelectedImages();
    if (this.dwObject.HowManyImagesInBuffer == 0) {
      if (document.getElementById("DW_TotalImage")) {
        (<HTMLInputElement>document.getElementById("DW_TotalImage")).value = this.dwObject.HowManyImagesInBuffer + "";
        this.isFileScanned = false;
      }
      if (document.getElementById("DW_CurrentImage")) {
        (<HTMLInputElement>document.getElementById("DW_CurrentImage")).value = 0 + "";
      }
      return;
    }
    else {
      this.updatePageInfo();
    }
  }

  btnRemoveAllImages_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.RemoveAllImages();
    this.isFileScanned = false;
    if (document.getElementById("DW_TotalImage")) {
      (<HTMLInputElement>document.getElementById("DW_TotalImage")).value = 0 + "";
    }
    if (document.getElementById("DW_CurrentImage")) {
      (<HTMLInputElement>document.getElementById("DW_CurrentImage")).value = 0 + "";
    }
  }

  /*----------------Change Image Size--------------------*/
  btnChangeImageSize_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    if (this.dwObject !== undefined) {
      this.showDialog = true;
      this.img_width = this.dwObject.GetImageWidth(this.dwObject.CurrentImageIndexInBuffer) + "";
      this.img_height = this.dwObject.GetImageHeight(this.dwObject.CurrentImageIndexInBuffer) + "";
    }
    else {
      alert("Please scan a document");
      this.showDialog = false;
      return;
    }
  }

  btnChangeImageSizeOK_onclick(changeImageSizeForm: NgForm) {
    this.dwObject.ChangeImageSize(this.dwObject.CurrentImageIndexInBuffer, parseInt(this.img_width), parseInt(this.img_height), this.selectedInterpolation);
    this.showDialog = false;
  }

  selectInterpolcationChangeHandler(event: any) {
    this.selectedInterpolation = event.target.value;
  }

  /*----------------Crop Image--------------------*/
  btnCrop_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    console.log("crop called");
    if (this._iLeft != 0 || this._iTop != 0 || this._iRight != 0 || this._iBottom != 0) {
      console.log("crop2");
      this.dwObject.Crop(
        this.dwObject.CurrentImageIndexInBuffer,
        this._iLeft, this._iTop, this._iRight, this._iBottom
      );
      this._iLeft = 0;
      this._iTop = 0;
      this._iRight = 0;
      this._iBottom = 0;
      return;
    }
    else {
      alert("Please select the area you'd like to crop");
    }
  }

  //************************** Navigator functions***********************************
  setlPreviewMode() {
    let varNum = (<HTMLSelectElement>document.getElementById("DW_PreviewMode")).selectedIndex;
    varNum = varNum + 1;
    let btnCrop1 = (<HTMLImageElement>document.getElementById("btnCrop"));
    if (btnCrop1) {
      var tmpstr = btnCrop1.src;
      if (varNum > 1) {
        tmpstr = tmpstr.replace('Crop.', 'Crop_gray.');
        btnCrop1.src = tmpstr;
        btnCrop1.onclick = function () {
        };
      }
      else {
        tmpstr = tmpstr.replace('Crop_gray.', 'Crop.');
        btnCrop1.src = tmpstr;
        btnCrop1.onclick = function () {
          this.btnCrop_onclick()
        }.bind(this);
      }
    }
    this.dwObject.SetViewMode(varNum, varNum);
    if (Dynamsoft.Lib.env.bMac || Dynamsoft.Lib.env.bLinux) {
      return;
    }
    else if (this.DW_PreviewMode.selectedIndex !== 0) {
      this.dwObject.MouseShape = true;
    }
    else {
      this.dwObject.MouseShape = false;
    }
  }

  updatePageInfo() {
    //console.log("updatePageInfo called" );
    if (document.getElementById("DW_TotalImage")) {
      (<HTMLInputElement>document.getElementById("DW_TotalImage")).value = this.dwObject.HowManyImagesInBuffer + "";
    }
    let currImgIndex: number = this.dwObject.CurrentImageIndexInBuffer + 1;
    if (document.getElementById("DW_CurrentImage")) {
      (<HTMLInputElement>document.getElementById("DW_CurrentImage")).value = currImgIndex + "";
    }
  }

  checkIfImagesInBuffer() {
    if (this.dwObject !== undefined) {
      if (this.dwObject.HowManyImagesInBuffer == 0) {
        return false;
      }
      else {
        return true;
      }
    }
  }

  public Dynamsoft_OnMouseClick = function Dynamsoft_OnMouseClick(index) {
    // console.log("Dynamsoft_OnMouseClick called" );
    this.updatePageInfo();
  }.bind(this);

  public Dynamsoft_OnPostTransfer = function Dynamsoft_OnPostTransfer() {
    //console.log("Dynamsoft_OnPostTransfer called" );
    this.updatePageInfo();
  }.bind(this);

  public Dynamsoft_OnMouseRightClick = function Dynamsoft_OnMouseRightClick(index) {

  }.bind(this);

  public Dynamsoft_OnPostLoadfunction = function Dynamsoft_OnPostLoadfunction(path, name, type) {
    //console.log("Dynamsoft_OnPostLoadfunction called" );
    this.updatePageInfo();
  }.bind(this);

  public Dynamsoft_OnPostAllTransfers = function Dynamsoft_OnPostAllTransfers() {
    //console.log("Dynamsoft_OnPostAllTransfers called" );
    if (this.dwObject !== undefined) {
      this.dwObject.CloseSource();
    }
    this.updatePageInfo();
    // this.checkErrorString();
  }.bind(this);

  public Dynamsoft_OnTopImageInTheViewChanged = function Dynamsoft_OnTopImageInTheViewChanged(index) {
    // console.log("Dynamsoft_OnTopImageInTheViewChanged called" );
    this._iLeft = 0;
    this._iTop = 0;
    this._iRight = 0;
    this._iBottom = 0;
    this.dwObject.CurrentImageIndexInBuffer = index;
    this.updatePageInfo();
  }.bind(this);

  public Dynamsoft_OnReady = function Dynamsoft_OnReady() {
    // console.log("Dynamsoft_OnReady called");
    this.dwObject.RegisterEvent("OnTopImageInTheViewChanged", this.OnTopImageInTheViewChanged);
    this.dwObject.RegisterEvent("OnMouseClick", this.Dynamsoft_OnMouseClick);
    this.updatePageInfo();
    this.dwObject.RegisterEvent("OnPostTransfer", this.Dynamsoft_OnPostTransfer);
    this.dwObject.RegisterEvent("OnPostLoad", this.Dynamsoft_OnPostLoadfunction);
    this.dwObject.RegisterEvent("OnPostAllTransfers", this.Dynamsoft_OnPostAllTransfers);
  }.bind(this);

  public Dynamsoft_OnImageAreaSelected = function Dynamsoft_OnImageAreaSelected(index, left, top, right, bottom) {
    this._iLeft = left;
    this._iTop = top;
    this._iRight = right;
    this._iBottom = bottom;
  }.bind(this);

  public Dynamsoft_OnImageAreaDeselected = function Dynamsoft_OnImageAreaDeselected(index) {
    this._iLeft = 0;
    this._iTop = 0;
    this._iRight = 0;
    this._iBottom = 0;
  }.bind(this);

  public Dynamsoft_OnGetFilePath = function Dynamsoft_OnGetFilePath(bSave, count, index, path, name) {
  }

  btnFirstImage_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    this.dwObject.CurrentImageIndexInBuffer = 0;
    this.updatePageInfo();
  }

  btnLastImage_onclick() {
    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    let k: number = this.dwObject.HowManyImagesInBuffer - 1;
    this.dwObject.CurrentImageIndexInBuffer = k;
    this.updatePageInfo();
  }

  btnPreImage_onclick() {
    this.dwObject.CurrentImageIndexInBuffer = this.dwObject.CurrentImageIndexInBuffer - 1;
    this.updatePageInfo();
  }

  btnNextImage_onclick() {
    let i: number;
    let j: number;
    if (this.dwObject !== undefined) {
      i = this.dwObject.HowManyImagesInBuffer;
      j = this.dwObject.CurrentImageIndexInBuffer;
    }

    if (!this.checkIfImagesInBuffer()) {
      return;
    }
    /* else if (this.dwObject.CurrentImageIndexInBuffer == i - 1) {
          console.log("nNextImage curr1::"+this.dwObject.CurrentImageIndexInBuffer);
          return;
        }*/
    this.dwObject.CurrentImageIndexInBuffer = j + 1;
    this.updatePageInfo();
  }

  btnPreImage_wheel() {
    if (this.dwObject.HowManyImagesInBuffer !== 0)
      this.btnPreImage_onclick();
  }

  btnNextImage_wheel() {
    if (this.dwObject.HowManyImagesInBuffer !== 0)
      this.btnNextImage_onclick();
  }

  back() {
    this._platformStrategy.back();
 
  }

  duplicateFileNamesExist(event) {
    let duplicateExists = false;
    for (let file of this.fileName) {
      for (let downloadFile of this.filesToDownload) {
        if (downloadFile.docName === file.name) {
          duplicateExists = true;
          break;
        }
      }
      if (duplicateExists)
        break;
    }
    return duplicateExists;
  }

  matchString(source, input) {
    let match = true;
    for (let i = 0; (i < source.length && source[i] != '%'); i++) {
      if (input[i] !== source[i]) {
        match = false;
      }
    }
    return match;
  }

  checkUnsupportedDoc(event) {
    let unsupportedDoc = false;
    // for (let file of this.fileName) {
    if (this.matchString('audio%', "application/pdf") || this.matchString('video%', "application/pdf")) {
      unsupportedDoc = true;
      // break;
      // }
    }
    return unsupportedDoc;
  }

  onFileChange(event) {
    let duplicateExist = this.duplicateFileNamesExist(event);
    let unsupportedDocExist = this.checkUnsupportedDoc(event);
    if (duplicateExist) {
      alert("Please upload files with unique names");
      return null;
    }
    // if (unsupportedDocExist) {
    //   alert("You are uploading an unsupported Document(.js/.exe/.mov/.mp4), please check and reupload.");
    //   return null;
    // }

    // let docTypeCdArr = [];
    // let docSubtypeCdArr = [];
    // (this.docInfo.get('docUploadFormArr') as FormArray).controls.forEach(doc => {
    //   docTypeCdArr.push(doc.get('docType').value);
    //   docSubtypeCdArr.push(doc.get('docSubtype').value);
    // })
    // if(!this.docInfo.get('docSubtype').value) {
    //   alert("doc subtype null");
    // } else {
    this.filesToUpload = [];
    if (this.fileName) {
      // for (let file of this.fileName) {
      let uniqueName = this.fileName + Math.floor((Math.random() * 1000) + 1);
      console.log("File", this.fileName);
      let entry: DocumentUploadInfo = {
        docName: uniqueName,
        documentTypeCd: '',
        file: this.fileName
      }
      this.filesToUpload.push(entry);
      // }
      // }

      // this.docInfo.reset();
      // let inputVal = (<HTMLInputElement>document.getElementById('fileInput'));
      // setTimeout(() => {
      //   inputVal.value = '';
      // }, 1000);
      this.fileUpload();
    }
  }

  async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array)
    }
  }

  async fileUpload() {
    await this.asyncForEach(this.filesToUpload, async (file) => {
      //console.log('file', file);
      await this.singleFileUpload(file);
    })
  }

  async singleFileUpload(file) {
    console.log('FILE UPLOADING');
    const uploadFile = file;
    this.hasError = false;
    if (!uploadFile) {
      this.hasError = true;
      this.errorAlert = 'Error: Unexpected Error occured. File couldn\'t be found.';
    }
    else if (uploadFile.docName.length > 100) {
      this.hasError = true;
      this.errorAlert = 'Error: File Name is too long.';
    }

    if (!this.hasError) {
      let fileReader = new FileReader();
      this.fileUploading = true;
      this.fileUploaded = false;
      // this.apiManager.fileUploadtProgress$.subscribe(data => {
      //   this.uploadProgress = data;
      // }
      // );
      // fileReader.readAsArrayBuffer(uploadFile.file);
      let zipFile: JSZip = new JSZip();
      let that = this;

      // fileReader.onload = async function () {
      //   zipFile.file(uploadFile.docName, fileReader.result);
      //   zipFile.generateAsync({
      //     type: 'blob',
      //     compression: 'DEFLATE',
      //     compressionOptions: {
      //       level: 9
      //     },
      //     mimeType: uploadFile.type
      //   }).then(async (blob) => {
      //     // const file: File = new File([blob], uploadFile.docName, { type: uploadFile.file.type });
      //     await that.uploadDocument(uploadFile.file, uploadFile);
      //   });
      // }
      //console.log("this.file2 is "+this.file)
      await that.uploadDocument(uploadFile.file, uploadFile);
    }
  }

  uploadDocument(file, uploadObj) {
    //console.log("this.file1 is "+this.file)
    this.queryParams.push(ConstantsService.humanServiceModel.benefitsCase.caseId);
    this.appUtilService.showLoader();
    //if (this.individualId == null) {
    //alert("Saving as General Case Document");
    //}
    let imgArry: number[] = [];
    let totalNum: number = this.dwObject.HowManyImagesInBuffer;
    for (let i = 0; i < totalNum; i++) {
      imgArry.push(i);
    }
    let r = totalNum - 1;
    this.dwObject.ConvertToBlob(imgArry, EnumDWT_ImageType.IT_TIF, (res) => {

      file = this.blobToFile(res, this.fileName);
      //console.log(file, 'testtdfjk');
    },
      (num, err) => {
        //console.log('error is ' + err);
      }
    );
    let frmData = new FormData();
    if (this.file != undefined) {
      this.file = this.blobToFile(this.file, this.fileName);
      frmData.append('fileFormData', this.file, this.fileName);
      frmData.append('documentTypeCd', this.docInfo.get('docType').value);
      frmData.append('verificationName', this.verificationName);
      frmData.append('individualId', ConstantsService.humanServiceModel.benefitsCase.householdMembers[0].individualId);
    }
    let fileType = {
      lastModified: 1521606413814,
      lastModifiedDate: new Date,
      name: this.fileName + '.tiff',
      size: 6040335,
      type: "application/tiff",
      webkitRelativePath: "",
    }
    //console.log("individualid is "+frmData.get('individualId'));
    //console.log("documentTypeCd is "+frmData.get('documentSubType'));
    this.apiManager.fetchData(
      'fileUpload',
      {
        // 'fileFormData': fileType,
        'fileFormData': frmData.get('fileFormData'),
        'individualId': frmData.get('individualId'),
        'verificationName': frmData.get('verificationName'),
        'documentTypeCd': frmData.get('documentTypeCd'),
        'documentSubType': frmData.get('documentSubType')
      },
      ApiManager.FILEUPLOAD,
      undefined,
      undefined,
      () => {
        this.fileUploading = false;
        //console.log('FILE FINISHED UPLOADING');
      },
      this.queryParams
    ).subscribe(res => {
      if (res.fileName) {
        if (res.caseId) {
          this.totalCount = this.totalCount + 1;

          if (this.maxfileCount != undefined && (this.maxfileCount <= this.totalCount)) {
            this.disableAddDocumentButton = true;
          }

          let inputVal = (<HTMLInputElement>document.getElementById('fileInput'));
          // inputVal.value = '';
          let _fileName = this.genericDisplayString + (this.uploadedFiles.length + 1);

          if (!res.fileId) {
            alert("File ID not created for this file");
          }

          let doc = {
            docId: res.fileId,
            docName: res.fileName,
            documentTypeCd: res.docTypeCd,
            createdBy: res.createUserId,
            createdDt: res.createDt,
            updatedBy: res.updatedUserId,
            updatedDt: res.updatedDt
          }
          this.uploadedFiles.push(doc);
          this.docMetadata.push(doc);
          this.getDocSelectionInfo();
          let thisRef = this;
          setTimeout(() => {
            thisRef.uploadedFiles = [];
          }, 2000);
        }

        else {
          alert(res.fileName + " is infected, please upload good document");
        }

      } this.back();
      // this.appUtilService.hideLoader();
      this.fileUploading = false;
      this.fileUploaded = true;
    });
  }

  getDocSelectionInfo() {
    // this.docMetadata = docDetails;
    this.docs = [];
    this.filesToDownload = [];
    // console.log('uploadedFiles, docMetadata', this.uploadedFiles, this.docMetadata);
    this.docMetadata.map(item => {
      let tempSvc = new DocumentSelectionInfo(item.docId, item.docName, false, item.documentTypeCd, item.createdBy, item.createdDt, item.updatedBy, item.updatedDt);
      this.filesToDownload.push(tempSvc);
    });
    this.docs = this.filesToDownload;
    // this.docResource = new DataTableResource(this.filesToDownload);
    this.docResource.count().then(count => this.docCount = count);
    // console.log('docSelectedFlag', this.filesToDownload, this.docs);
    return this.filesToDownload;
  }

  getDocumentsForScreen() {
    //console.log("queryparams", this.queryParams);
    let reqBody = {
      "caseId": this.queryParams[0],
      "individualId": this.queryParams[1],
      "docLocation": this.queryParams[2]
    }

    this.apiManager.fetchData(
      'getDocumentsForScreen',
      undefined,
      ApiManager.POST,
      reqBody
    ).pipe(catchError((err: Response | any) => {
      this.appUtilService.hideLoader();
      console.log('API call failure: Catch', err);
      alert("error fetching documents" + err);
      return observableThrowError(err);
    })).subscribe(res => {
      //console.log("docs from new API", res);
      // alert("check documents from new API");
      this.appUtilService.hideLoader();
      this.docMetadata = res;
      this.noOfDocs = this.docMetadata.length;
      this.getDocSelectionInfo();
    });
  }

  getDocumentsUpdate() {
    this.queryParams.push(this.documentOptionsName.toString());
    //console.log("qp", this.documentOptionsName.toString());
    this.getDocumentsForScreen();
  }

  initializeFileUploadPopUp(
    personName: PersonName,
    fileArray?: any[],
    caseId?: string,
    individualId?: string,
    verificationName?: string,
    documents?: any
  ) {
    this.docOptionsUpdated = false;
    this.clearInputs();

    if (caseId) {
      this.caseId = caseId;
    }
    else {
      this.caseId = 'C000000001458694';
    }
    this.queryParams = [];
    this.queryParams.push(
      this.caseId
    );
    if (individualId) {
      this.individualId = individualId;
      this.queryParams.push(
        this.individualId
      );
    }
    else {
      this.queryParams.push(
        null
      );
      alert("Individual ID is null");
    }
    if (verificationName) {
      this.verificationName = verificationName;

    }
    let that = this;
    setTimeout(() => {
      if (that.documentOptionsName !== '') {
        that.docInfo.get('docType').setValue(that.documentOptionsName);
        // that.changeType(that.documentOptionsName);
        that.getDocumentsUpdate();
      }
      else {
        alert("not received doc options name");
      }
    }, 500);

    this.readOnly = ConstantsService.isReadOnlyScreen;
    if (!documents) {
      documents = [];
    }
    this.totalCount = documents.length;
    if (this.maxfileCount != undefined && (this.maxfileCount <= this.totalCount)) {
      this.disableAddDocumentButton = true;
    }
    this.uploadedFiles = [];
    this.documentArrayRefrence = documents;

    if (personName) {
      this.memberSelected = this.appUtilService.getFullName(personName);
    }
    this.hasError = false;
  }

  clearInputs() {
    this.docs = [];
    this.docMetadata = [];
    this.filesToDownload = [];
  }

  // changeType(event, docEvent, search?) {
  //   let originDocEvent = String(docEvent);
  //   let value = this.lookups.getLookupDesc('Documents', docEvent);
  //   docEvent = value;
  //   console.log(event);
  //   console.log('docEvent===>', docEvent);
  //   this.lkptype = docEvent;

  //   if (search == 'search') {
  //     this.subtypeSearch = this.lookupValues[originDocEvent.replace("", '')];
  //     console.log('this.subtypeSearch==>', this.subtypeSearch);
  //     console.log('this.globalCategoryObj==>', this.globalCategoryObj);
  //     this.searchDocEvent = docEvent;
  //     this.subTypeSearchCd = '';
  //     this.lkptype = docEvent;
  //   }
  //   else {
  //     this.lkptype = docEvent;
  //     console.log('this.globalCategoryObj==>', this.globalCategoryObj)
  //     let docEvents = docEvent.split(' ');
  //     let subCategory = '';
  //     docEvents.map(event => subCategory += event);
  //     console.log(subCategory);
  //     this.subtype = this.lookupValues[subCategory];
  //     // this.docInfo.get('docSubtype').setValue(this.subtype[0].lookupBean.code);
  //     //this.docInfo.get('docSubtype').setValue("");
  //   }
  // }

  changeSubType(event) {
    // let getSubTypeList = this.subtype.map(subType => subType.lookupBean);
    let getSelectedSubType = this.subtype.filter(subType => subType.lkpDesc === event.value);
    // if (search == 'search') {
    //   let varNum = (<HTMLSelectElement>document.getElementById('docSubTypeSearchId')).selectedIndex;
    //   this.subTypeSearchCd = getSelectedSubType.length > 0 ? getSelectedSubType[0].code : null;
    // }
    // else {
    let varNum = (<HTMLSelectElement>document.getElementById('docSubtypeId')).selectedIndex;
    // this.lkptyp = getSelectedSubType.length > 0 ? getSelectedSubType[0].type : null
    this.lkpshortval = getSelectedSubType.length > 0 ? getSelectedSubType[0].lookupLongVal : null;
  }

  // navCaseHome()
  // {
  //   this.router.navigate(['qualityControl/caseHome']);
  // }

  // navFnsFiles()
  // {
  //   this.router.navigate(['qualityControl/questionary-700141'], {queryParams: {type:700141}});
  // }
}

export enum EnumDWT_InterpolationMethod {
  IM_NEARESTNEIGHBOUR = 0,
  IM_BILINEAR,
  IM_BICUBIC,
  IM_BESTQUALITY
};
