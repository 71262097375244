import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-earned-income-employment-info',
  templateUrl: './earned-income-employment-info.component.html',
  styleUrls: ['./earned-income-employment-info.component.css']
})
export class EarnedIncomeEmploymentInfoComponent extends BaseComponent implements OnInit {
  currentPage: number = 0;
  isNotEditable: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    if(this.group.get('FormArray')['controls']){
      this.group.get('FormArray')['controls'].map(s=>{
      this.ldss(s);
      this.interview(s);
      this.sampleMonth(s);
      this.correctedLdss(s);
    });
  }
  }

  ldss(arrayControl){
    if (arrayControl.controls["311.7.0a"].value == true || arrayControl.controls["311.7.0a"].value == "true") {
      arrayControl.controls['311.7.1a'].patchValue(arrayControl.controls['311.7.01'].value);
      arrayControl.controls['311.7.1.1a'].patchValue(arrayControl.controls['311.7.01a'].value);
      arrayControl.controls['311.7.1.2a'].patchValue(arrayControl.controls['311.7.01b'].value);
      arrayControl.controls['311.7.1.3a'].patchValue(arrayControl.controls['311.7.01c'].value);
      arrayControl.controls['311.7.1.4a'].patchValue(arrayControl.controls['311.7.01d'].value);
      arrayControl.controls['311.7.1.7a'].patchValue(arrayControl.controls['311.7.01e'].value);
      arrayControl.controls['311.7.1.5a'].patchValue(arrayControl.controls['311.7.01f'].value);
      arrayControl.controls['311.7.1.6a'].patchValue(arrayControl.controls['311.7.01g'].value);     
    } else {
      arrayControl.controls['311.7.1a'].patchValue('');
      arrayControl.controls['311.7.1.1a'].patchValue('');
      arrayControl.controls['311.7.1.2a'].patchValue('');
      arrayControl.controls['311.7.1.3a'].patchValue('');
      arrayControl.controls['311.7.1.4a'].patchValue('');
      arrayControl.controls['311.7.1.7a'].patchValue('');
      arrayControl.controls['311.7.1.5a'].patchValue('');
      arrayControl.controls['311.7.1.6a'].patchValue('');     
    }
    
  }


  interview(arrayControl){
    if (arrayControl.controls["311.7.0b"].value == true || arrayControl.controls["311.7.0b"].value == "true") {
      arrayControl.controls['311.7.1b'].patchValue(arrayControl.controls['311.7.01'].value);
      arrayControl.controls['311.7.1.1b'].patchValue(arrayControl.controls['311.7.01a'].value);
      arrayControl.controls['311.7.1.2b'].patchValue(arrayControl.controls['311.7.01b'].value);
      arrayControl.controls['311.7.1.3b'].patchValue(arrayControl.controls['311.7.01c'].value);
      arrayControl.controls['311.7.1.4b'].patchValue(arrayControl.controls['311.7.01d'].value);
      arrayControl.controls['311.7.1.7b'].patchValue(arrayControl.controls['311.7.01e'].value);
      arrayControl.controls['311.7.1.5b'].patchValue(arrayControl.controls['311.7.01f'].value);
      arrayControl.controls['311.7.1.6b'].patchValue(arrayControl.controls['311.7.01g'].value);     
    } else {
      arrayControl.controls['311.7.1b'].patchValue('');
      arrayControl.controls['311.7.1.1b'].patchValue('');
      arrayControl.controls['311.7.1.2b'].patchValue('');
      arrayControl.controls['311.7.1.3b'].patchValue('');
      arrayControl.controls['311.7.1.4b'].patchValue('');
      arrayControl.controls['311.7.1.7b'].patchValue('');
      arrayControl.controls['311.7.1.5b'].patchValue('');
      arrayControl.controls['311.7.1.6b'].patchValue('');     
    }
  }

  sampleMonth(arrayControl){
    if (arrayControl.controls["311.7.0c"].value == true || arrayControl.controls["311.7.0c"].value == "true") {
      arrayControl.controls['311.7.1c'].patchValue(arrayControl.controls['311.7.01'].value);
      arrayControl.controls['311.7.1.1c'].patchValue(arrayControl.controls['311.7.01a'].value);
      arrayControl.controls['311.7.1.2c'].patchValue(arrayControl.controls['311.7.01b'].value);
      arrayControl.controls['311.7.1.3c'].patchValue(arrayControl.controls['311.7.01c'].value);
      arrayControl.controls['311.7.1.4c'].patchValue(arrayControl.controls['311.7.01d'].value);
      arrayControl.controls['311.7.1.7c'].patchValue(arrayControl.controls['311.7.01e'].value);
      arrayControl.controls['311.7.1.5c'].patchValue(arrayControl.controls['311.7.01f'].value);
      arrayControl.controls['311.7.1.6c'].patchValue(arrayControl.controls['311.7.01g'].value);     
    } else {
      arrayControl.controls['311.7.1c'].patchValue('');
      arrayControl.controls['311.7.1.1c'].patchValue('');
      arrayControl.controls['311.7.1.2c'].patchValue('');
      arrayControl.controls['311.7.1.3c'].patchValue('');
      arrayControl.controls['311.7.1.4c'].patchValue('');
      arrayControl.controls['311.7.1.7c'].patchValue('');
      arrayControl.controls['311.7.1.5c'].patchValue('');
      arrayControl.controls['311.7.1.6c'].patchValue('');     
    }
    
  }
  correctedLdss(arrayControl){
    if (arrayControl.controls["311.7.0d"].value == true || arrayControl.controls["311.7.0d"].value == "true") {
      arrayControl.controls['311.7.1d'].patchValue(arrayControl.controls['311.7.01'].value);
      arrayControl.controls['311.7.1.1d'].patchValue(arrayControl.controls['311.7.01a'].value);
      arrayControl.controls['311.7.1.2d'].patchValue(arrayControl.controls['311.7.01b'].value);
      arrayControl.controls['311.7.1.3d'].patchValue(arrayControl.controls['311.7.01c'].value);
      arrayControl.controls['311.7.1.4d'].patchValue(arrayControl.controls['311.7.01d'].value);
      arrayControl.controls['311.7.1.7d'].patchValue(arrayControl.controls['311.7.01e'].value);
      arrayControl.controls['311.7.1.5d'].patchValue(arrayControl.controls['311.7.01f'].value);
      arrayControl.controls['311.7.1.6d'].patchValue(arrayControl.controls['311.7.01g'].value);     
    } else {
      arrayControl.controls['311.7.1d'].patchValue('');
      arrayControl.controls['311.7.1.1d'].patchValue('');
      arrayControl.controls['311.7.1.2d'].patchValue('');
      arrayControl.controls['311.7.1.3d'].patchValue('');
      arrayControl.controls['311.7.1.4d'].patchValue('');
      arrayControl.controls['311.7.1.7d'].patchValue('');
      arrayControl.controls['311.7.1.5d'].patchValue('');
      arrayControl.controls['311.7.1.6d'].patchValue('');     
    }
  }
}

