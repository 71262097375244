import { Component, OnInit, ViewChild, Inject, ElementRef, Optional } from '@angular/core';
import { ConstantsService } from '../../common/constants.service';
import { AppUtilService } from '../../common/app.util.service';
import { ApiManager } from '../../common/api-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { NavigationService } from '../../common/navigation.service';
import { PrintService } from '../../common/print.service';
import { CustomValidatorsService } from '../../common/custom-validators.service';
import { MonthFormatDirective, DATE_FORMAT, MNTH_FORMAT } from '../../shared-module/month-format.directive';
import { MomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { FnsTransmissionComponent } from '../fns-transmission/fns-transmission.component';
const moment = _rollupMoment || _moment;
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver/FileSaver';

@Component({
  selector: 'app-fns-transmission-summary',
  templateUrl: './fns-transmission-summary.component.html',
  styleUrls: ['./fns-transmission-summary.component.css']
})
export class FnsTransmissionSummaryComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
 // @ViewChild(MatSort, {static: true}) sort: MatSort;  
  @ViewChild('textFilesSort') textFilesSort: MatSort;
  @ViewChild('fnsStatusSort') fnsStatusSort: MatSort;
  displayedColumns: string[] = [
    'fnsTransId',
    'transmissionDt',
    'samplingMonth',
    'type',
    'transmissionSts',
    'downloadFile',
    'actions'];
  
  fnsDisplayColumns: string[] = [
    'fnsTransId',
    'samplingMonth',
    'rvwNum',
    'type',
    'transmissionSts',
    'comment',
    'actions'
  ];

    isNotEditable: boolean = false;
    dsQCFNSTransmission = new MatTableDataSource<any>();
    popupMode: boolean = false;
    searchForm: FormGroup;
  
    benMonth: string;
    rvwType: string;
    rvwNum;
    filteredData= new MatTableDataSource<any>();
  
  constructor(
    public constantsService: ConstantsService,
    private utilService: AppUtilService,
    private apiManager: ApiManager,
    private router: Router,
    private route: ActivatedRoute,
    @Optional() public dialog: MatDialog,
    public navigationService: NavigationService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private printService: PrintService,
    private customValidatorsService: CustomValidatorsService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.searchForm = this.fb.group({
      sampleMonth: [''],
      type: [''],
      reviewNumber: [''],
      fnsArray: this.fb.array([
        this.fb.group({
          transmissionSts: [''],
          comment: ['']
        })
      ])
    });
    this.initTables();
    this.getQCFNSTransmission();
    this.determineAccess();
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/qualityControl/fnsTransmission'] != null && ref.accessMap['/qualityControl/fnsTransmission'].accessMode != 'rw')) {
        this.isNotEditable = true;
    }
  }

  initTables() {
    // this.dsQCSamplingSchedule = [];
    // this.dsQCSamplingSchedule = new MatTableDataSource(this.dsQCSamplingSchedule);
    this.dsQCFNSTransmission.sort = this.textFilesSort;
    this.filteredData.sort = this.fnsStatusSort;
  }

  getQCFNSTransmission() {
    const id: any[] = [];
    this.apiManager.fetchData('getFNSTransmission', undefined, ApiManager.GET, undefined, undefined, undefined, id)
      .subscribe(res => {
        if (res) {
          this.dsQCFNSTransmission.data = res;
          this.filteredData.data = res;
          this.createFormArray();
          this.initTables();
          
          setTimeout(() => { this.dsQCFNSTransmission.paginator = this.paginator; });
        }
      }
    );
  }

  createFormArray() {
    const fnsCtrl = this.searchForm.get('fnsArray') as FormArray;
    fnsCtrl.clear();
    this.filteredData.data.forEach((fns)=>{
      fnsCtrl.push( this.fb.group({
        transmissionSts:[fns.transmissionSts],
        comment:[fns.director_comments]
      }));
    });
  }

  getDistinctMonth() {
    let distinctMonth: string [] = [];
    this.dsQCFNSTransmission.data.map(filterItem => {
      if (filterItem.samplingMonth &&
        distinctMonth.indexOf(filterItem.samplingMonth + '') === -1) {
        distinctMonth.push(filterItem.samplingMonth + '');
      }
    });
    return distinctMonth;
  }  

  filterData() {
    const benMonth = this.searchForm.get('sampleMonth').value;
    const rvwType = this.searchForm.get('type').value;
    const rvwNum = this.searchForm.get('reviewNumber').value;
    
    this.filteredData.data = this.dsQCFNSTransmission.data.filter(item => 
      (!rvwType || item.type === rvwType) && (!benMonth || item.samplingMonth == benMonth) &&
      (!rvwNum || item.rvwNum + "" === rvwNum)) ;
      
    this.createFormArray();
  }

  onSelectionChangeTransmissionStatus(entity,i){
    entity.transmissionSts = this.searchForm.get('fnsArray')['controls'][i].value.transmissionSts;
    entity.director_comments = this.searchForm.get('fnsArray')['controls'][i].value.comment;
    entity.dirtyStatus = 'UPDATE';
    console.log(entity);
    const queryParams = [];

  this.apiManager.fetchData('saveFNSTransmission', undefined, ApiManager.POST, entity, undefined, undefined, queryParams)
    .subscribe(
      res => {
        alert('Save Successful.');
      },
      (err)=>{
        alert('Save Failed.');
      }
      );
  }

  onChangeComment(entity,i){
    entity.transmissionSts = this.searchForm.get('fnsArray')['controls'][i].value.transmissionSts;
    entity.director_comments = this.searchForm.get('fnsArray')['controls'][i].value.comment;
    entity.dirtyStatus = 'UPDATE';
    console.log(entity);
    const queryParams = [];

  this.apiManager.fetchData('saveFNSTransmission', undefined, ApiManager.POST, entity, undefined, undefined, queryParams)
    .subscribe(
      res => {
        alert('Save Successful.');
      },
      (err)=>{
        alert('Save Failed.');
      }
      );
  }
  
  openProcessLogDialog(row) {
    this.constantsService.rvwlog_rvwid = row.rvwNum;
    // this.router.navigate(['/qualityControl/fspReviewlog']);
    if (row.type ===  'FSPPositive' ) {
      this.router.navigate(['/qualityControl/fspReviewlog']);
    }
    else{
      this.router.navigate(['/qualityControl/fsNegReviewlog'])
    }
  
  }

  gotoCaseHome(row) {
    const queryParams: any[] = [];
    queryParams.push(row.rvwNum);
    this.apiManager.fetchData('getCaseReviewSearch', undefined, ApiManager.GET,
      undefined, undefined, undefined, queryParams).subscribe(
        res => {
          if (res.length > 0) {
            this.review(res[0]);
          }

        },
        error => {
          console.log('look up API call failure: Error');
        }
      );

  }

  review(rvwInfo) {
    this.constantsService.ReviewInfo = rvwInfo;
    if (this.constantsService.ReviewInfo) {
      this.refreshNav();
    }

  }

  refreshNav() {
    this.navigationService.getPrograms(this.navigationService.appCd).then(v => {
      this.afterNavChange();
    });
  }

  afterNavChange() {
    // Do Nothing
    // TODO : if any change after nav change while adding new program
    let initiatePage;
    if (this.constantsService.ReviewInfo.appType === 'BPE-FSME') {
      initiatePage = '/bpe/fsme/case-review-list';
    } else if (this.constantsService.ReviewInfo.appType === 'BPE-PA') {
      initiatePage = '/bpe/payment-accuracy/case-review-list';
    } else if (this.constantsService.ReviewInfo.appType === 'BPE-WPR') {
      initiatePage = '/bpe/wpr/case-review-list';
    } else if (this.constantsService.ReviewInfo.appType === 'BPE-MONITOR') {
      initiatePage = '/bpe/fsme/case-review-list';
    } else {
      initiatePage = '/qualityControl/analystCaseSummary';
    }


    this.navigationService.navigatePage(initiatePage, 'next');
  }

  saveReview(entity, i) {
    entity.transmissionSts = this.searchForm.get('fnsArray')['controls'][i].value.transmissionSts;
    entity.director_comment = this.searchForm.get('fnsArray')['controls'][i].value.comment;
    entity.dirtyStatus = 'INSERT';
    console.log(entity);
    const queryParams = [];

  this.apiManager.fetchData('saveFNSTransmission', undefined, ApiManager.POST, entity, undefined, undefined, queryParams)
    .subscribe(
      res => {
        alert('Save Successful.');
      },
      (err)=>{
        alert('Save Failed.');
      }
      );
  }

  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }

  openDialog(index, row) {
    if (this.isNotEditable) {
      return;
    }
  
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    if (index !== undefined && index > -1) {
      dialogConfig.data = {
        fnsTransdata: row,
        index: index,
        popupMode: this.popupMode
      };
    } 
    else {
      dialogConfig.data = {
        fnsTransdata: this.dsQCFNSTransmission.data,
        index: -1,
        popupMode: this.popupMode
      };
    }

    const dialogRef = this.dialog.open(FnsTransmissionComponent, dialogConfig);
    dialogRef.updateSize('60%', '85%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log('afterClose', val);
        // this.dsQCSamplingSchedule = new MatTableDataSource(val);
        // this.dialog.closeAll();
        this.ngOnInit();
        
      }
      
    });
  }
  openVerifyDialog(index, row) {
    if (this.isNotEditable) {
      return;
    }
  
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    if (index !== undefined && index > -1) {
      dialogConfig.data = {
        fnsTransdata: row,
        index: index,
        popupMode: this.popupMode
      };
    } 
    else {
      dialogConfig.data = {
        fnsTransdata: this.dsQCFNSTransmission.data,
        index: -1,
        popupMode: this.popupMode
      };
    }

    const dialogRef = this.dialog.open(fnsVerifyComponent, dialogConfig);
    dialogRef.updateSize('60%', '85%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log('afterClose', val);
        // this.dsQCSamplingSchedule = new MatTableDataSource(val);
        // this.dialog.closeAll();
        this.ngOnInit();
        
      }
      
    });
  }

  getDropdownText(val) {
    return this.constantsService.ProgramTypes.filter(item => item.value === val )[0].viewValue;
  }

  downloadFile(fileData) {
    const fileName = fileData.fnsTransId + '_'+ fileData.samplingMonth + '_'
                    + (fileData.type === 'FSPPositive' ? 'SNAPPositive' : 'SNAPNegative')
                    + '_Combine_Sorted.txt' ;
    const data = fileData.downloadFile;
    const blob = new Blob([data], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
  }

}

/* Verify Dialog Starts here */

@Component({
  selector: 'fns-verify-dialog',
  templateUrl: 'fnsVerify-dialog.html',
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})

export class fnsVerifyComponent implements OnInit {

  public fnsVerifyForm: FormGroup;
  displayProgressSpinner = true;

  readonlyMode= false;
  constructor(
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    public appUtilService: AppUtilService,
    private fb: FormBuilder,
    private customValidatorsService: CustomValidatorsService,
  ) {
    
    this.buildForm();
  }

  ngOnInit() {
    this.casedataSourceDialog.data = this.data.fnsTransdata.fnsFileVerifyList;
    this.samplingMonth;
    this.type;
  }

  buildForm() {
    this.fnsVerifyForm = this.fb.group({
     
    });

  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  casedisplayedColumns = [
    "type",
    "typeDesc",
    "count"
       
  ];

  casedataSourceDialog: any = new MatTableDataSource();
  samplingMonth = this.data.fnsTransdata.samplingMonth ;
  type = this.data.fnsTransdata.type ;

  ngAfterViewInit() {
    this.casedataSourceDialog.paginator = this.paginator;
    this.casedataSourceDialog.sort = this.sort;
  }

  map: Map<any, any> = new Map();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
 
}
