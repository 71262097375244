import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-ca-initiatives-implemented',
  templateUrl: './ca-initiatives-implemented.component.html',
  styleUrls: ['./ca-initiatives-implemented.component.css']
})
export class CaInitiativesImplementedComponent extends BaseComponent implements OnInit {
  currentPage: number = 0;
  ngOnInit() {
    super.ngOnInit();
  }

}
