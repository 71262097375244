import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-earned-income-qcmabswage',
  templateUrl: './earned-income-qcmabswage.component.html',
  styleUrls: ['./earned-income-qcmabswage.component.css']
})
export class EarnedIncomeQCMABSWageComponent extends BaseComponent implements OnInit {

  currentPage: number = 0;
  isNotEditable : boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

}
