import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-shelter-cost-expenses',
  templateUrl: './shelter-cost-expenses.component.html',
  styleUrls: ['./shelter-cost-expenses.component.css']
})
export class ShelterCostExpensesComponent extends BaseComponent implements OnInit {
isNotEditable: boolean =false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

  changeQ363_3_1(val){
    if(val === 'N'){
      this.group.controls['363.3.2'].disable();
      this.group.controls['363.3.4'].disable();
      this.group.controls['363.3.5'].disable();
      this.group.controls['363.3.6'].disable();
      this.group.controls['363.3.7'].disable();
      this.group.controls['363.3.8'].disable();

    }else if(val === 'Y'){
      this.group.controls['363.3.2'].enable();
      this.group.controls['363.3.4'].enable();
      this.group.controls['363.3.5'].enable();
      this.group.controls['363.3.6'].enable();
      this.group.controls['363.3.7'].enable();
      this.group.controls['363.3.8'].enable();

    }
  }

  get Is363_3_1():boolean{
    let q1 = this.group.controls['363.3.1'].value;
    if (q1 && q1 === 'N'){
      return true
    }
    else {
      return false
    }
  }

  get Is363_3_2():boolean{
    let q1 = this.group.controls['363.3.2'].value ;
    if (q1 && q1 !== '3'){
      return true
    }
    else {
      return false
    } 
  }

  get Is363_3_4():boolean{
    return this.group.controls['363.3.4'].value === 'N';
  }
  get Is363_3_5():boolean{
    return this.group.controls['363.3.5'].value === 'Y';
  }
  get Is363_3_6():boolean{
    return this.group.controls['363.3.6'].value === 'N';
  }
  get Is363_3_8():boolean{
    return this.group.controls['363.3.8'].value === 'Y';
  }
  get Is363_3_8_N():boolean{
    return this.group.controls['363.3.8'].value === 'N';
  }

  changeQ363_3_4(val){
    if(val === 'N'){    
      this.group.controls['363.3.5'].disable();
      this.group.controls['363.3.6'].disable();
      this.group.controls['363.3.7'].disable();
      this.group.controls['363.3.8'].disable();

    }else if(val === 'Y'){
      this.group.controls['363.3.5'].enable();
      this.group.controls['363.3.6'].enable();
      this.group.controls['363.3.7'].enable();
      this.group.controls['363.3.8'].enable();

    }
  }

  changeQ363_3_5(val){
    if(val === 'Y'){    
      this.group.controls['363.3.6'].disable();
      this.group.controls['363.3.7'].disable();
      this.group.controls['363.3.8'].disable();

    }else if(val === 'N'){
      this.group.controls['363.3.6'].enable();
      this.group.controls['363.3.7'].enable();
      this.group.controls['363.3.8'].enable();

    }

  }




  get Is363_3_7():boolean{
    let q1 = this.group.controls['363.3.7'].value ;
    if (q1 && q1 !== '3'){
      return true
    }
    else {
      return false
    } 
  }

  get Is363_3_7_A():boolean{
    let q1 = this.group.controls['363.3.7'].value ;
    if (q1 === '1' || q1 === '2' || q1 === '3'){
      return true
    }
    else {
      return false
    } 
  }



}
