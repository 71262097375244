import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';
import { ApiManager } from 'src/app/common/api-manager.service';

@Component({
  selector: 'app-qc16-fsneg-findings',
  templateUrl: './qc16-fsneg-findings.component.html',
  styleUrls: ['./qc16-fsneg-findings.component.css']
})
export class Qc16FsnegFindingsComponent extends BaseComponent implements OnInit {
  isNotEditable: boolean = false;
  QC16NCompletedStatus: boolean = false;
  ngOnInit() {
    this.field[0].ccId = 0;
    super.ngOnInit();
  }
  changeHeight() {
    const txtArea : HTMLElement = document.getElementById('txtArea_500021_1');
    txtArea.style.minHeight = '600px';  
  }
  changeHeighttwo(){
    const txtAreaTwo : HTMLElement = document.getElementById('txtArea_500021_2');
    txtAreaTwo.style.minHeight = '600px';
  }
  changeHeightthree(){
    const txtAreaThree : HTMLElement = document.getElementById('txtArea_500021_3');
    txtAreaThree.style.minHeight = '600px';
  }
  changeHeightfour(){
    const txtAreaFour : HTMLElement = document.getElementById('txtArea_500021_4');
    txtAreaFour.style.minHeight = '600px';
  }

  changeBack(){
    const txtArea : HTMLElement = document.getElementById('txtArea_500021_1');
    txtArea.style.minHeight = '200px'; 
  }

  changeBackTwo(){
    const txtAreaTwo : HTMLElement = document.getElementById('txtArea_500021_2');
    txtAreaTwo.style.minHeight = '200px';
  }
  changeBackThree(){
    const txtAreaThree : HTMLElement = document.getElementById('txtArea_500021_3');
    txtAreaThree.style.minHeight = '200px';
  }
  changeBackFour(){
    const txtAreaFour : HTMLElement = document.getElementById('txtArea_500021_4');
    txtAreaFour.style.minHeight = '200px';
  }
  get currentIndividual() {
    return this.shareService.getDataFromMap('currentIndividual');
  }

  set currentIndividual(id) {
    this.shareService.addDataToMap('currentIndividual', id);
  }

  get IsView():boolean{
    return this.field[0].ccId === 0;
  }

  viewQ16NegativeForm(){
    const queryParams: any[] = [];
    let ccId = this.field[0].ccId;
    if (ccId != 0) {
      queryParams.push(ccId);
    }
      this.apiManager.fetchData('getDocumentForDownload', undefined, ApiManager.FILEDOWNLOAD, undefined, undefined, undefined, queryParams)
      .subscribe(res => {
        if (res) {
      
       let blob = new Blob([res], { type: res.type });
         let fileURL = URL.createObjectURL(blob);
         var newWin = window.open(fileURL);
        }
      }
    );
  }

  downloadQ16NegativeForm(){
    const queryParams: any[] = [];
  if (this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }
    const invId: any = this.currentIndividual;
    if (invId) {
      queryParams.push(invId);
    }  
    this.screenType = "500021";
      queryParams.push(this.screenType); //screen type
    
    let filename: any;
    filename = this.constantsService.ReviewInfo.rvwNum;
    this.apiManager.fetchData('saveQ16NegativeQs', undefined, ApiManager.POST, undefined, undefined, undefined, queryParams)
     .subscribe(res => {
       if (res) {
        this.field[0].ccId = res.ccId;
        // let blob;
        // blob = new Blob([res], { type: 'application/pdf' });
        // saveAs(blob, filename);
        this._snackBar.open('QC16N PDF Generated Successfully.', undefined, {
          duration: 2000,
        });
       }
     },
     error => {
       this._snackBar.open('Save failed.Please save and try again. ', undefined, {
         duration: 2000,
       });
       console.log('look up API call failure: Error');
     }
   );
  }

}