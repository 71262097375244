import { Component, OnInit } from '@angular/core';
import { ApiManager } from 'src/app/common/api-manager.service';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-self-emp-business-names',
  templateUrl: './self-emp-business-names.component.html',
  styleUrls: ['./self-emp-business-names.component.css']
})

export class SelfEmpBusinessNamesComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;
  currentPage =0;

  ngOnInit() {
    this.determineAccess();
    super.ngOnInit();
  }

    saveSelfEmploymentInfo() {
      this.utilService.showLoader();
      const queryParams: any = [];
      let indvId = this.shareService.getDataFromMap('currentIndividual');
      let rvwNum = this.constantsService.ReviewInfo.rvwNum;
      let objBody = {
        indvId: indvId,
        rvwNum: rvwNum,
        screenId:this.screenType
      }
      this.apiManager.fetchData('saveEmployerInfo', undefined, ApiManager.POST, objBody, undefined, undefined, queryParams)
        .subscribe(
          res => {
            console.log(res);
            this.callSave.emit(undefined);
            this.utilService.hideLoader();
          }
        );
    }
  
    clearInputMethod(arrayControl, j) {
   
      const queryParams: any = [];
     
      const distinctRecordGroup = [...new Set(this.field.map(rG => rG.recordGroupId))];
      const deleteEmplrObj = {
        ernIncId: arrayControl.controls['312.2.0'].value,
        rcdGrpId: distinctRecordGroup[j],
        rvwNum: this.constantsService.ReviewInfo.rvwNum,
        indvId: this.shareService.getDataFromMap('currentIndividual'),
        screenId: this.screenType
      };
      this.apiManager.fetchData('deleteEmployerInfo', undefined, ApiManager.POST, deleteEmplrObj, undefined, undefined, queryParams)
      .subscribe(
        res => {
          console.log(res);
          this.callGet.emit(undefined);
        }
      );
  
     }
    
    
  // determineAccess() {
  //   const ref = this.constantsService.getSecurityObject();

  //   if ((ref.accessMap['/qualityControl/questionary-312.2?type=312.2'] != null && ref.accessMap['/qualityControl/questionary-312.2?type=312.2'].accessMode != 'rw')) {
  //       this.isNotEditable = true;
  //   }
  // } 
}