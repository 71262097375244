export const environment = {
  production: false,
  envName: "dev2",
  isWso2: false,
  isWso2Ver: "/v1",
  sdrWso2Ver: "/v2",
  disableConsole: true,
  //  baseUrlDev: 'https://dev2.cbs.qc.mdthink.maryland.gov/eeqc/',
  //  baseUrlDev: 'https://stg.cbs.qc.mdthink.maryland.gov/eeqc/',

 // baseUrlDev: "https://d3sz2sx9d21kc7.cloudfront.net/eeqc/",
  // baseUrlDev: "http://localhost:8091/eeqc/",
  // baseUrlDev: 'https://dev2.qc.mdthink.maryland.gov/api/eeqc/dev2/',
  
//Cloud Services
//  baseUrlDev: 'http://3.223.0.90:8080/eeqc/',
  
//Cloud Services
//    loginURL: 'http://3.223.0.90:8080/eeqc/',

//On-prem Services

baseUrlDev: "https://devqcapi.kcares.kyybaapps.com/eeqc/",
  loginURL: "https://devqcapi.kcares.kyybaapps.com/eeqc/",
  //logoutURL: 'https://dev.access.mdthink.maryland.gov/openam/UI/Logout',
  logoutURL: "https://dev2.qc.mdthink.maryland.gov/openam/logout",
  SDAT: "https://sdat.dat.maryland.gov/RealProperty/Pages/default.aspx",
  SAVE: "https://save.uscis.gov/Web/vislogin.aspx?JS=YES",
  AVS: "https://md.pcgusavs.com/",
  MedicaidCOMAR: "http://www.dsd.state.md.us/COMAR/ComarHome.html",
  ecmLink: "https://ecm.mdthink.maryland.gov/ecmclient/",
  FIAActionTransmittals:
    "http://kb.dhs.maryland.gov/family-investment-administration/action-transmittals/",
  DHSKnowledgeBaseFIA:
    "http://kb.dhs.maryland.gov/family-investment-administration/",
  MarylandCaseSearch: "http://casesearch.courts.state.md.us/casesearch/",

  ZipCodeSearch: "https://tools.usps.com/go/ZipLookupAction_input",
  MarylandLandRecords: "https://mdlandrec.net/main/",
  enterpriseSearchURL: "https://dev2.search.mdthink.maryland.gov/search/",
  marylandHealthConnection: "https://www.marylandhealthconnection.gov/",
  MarylandEBT: "https://www.connectebt.com/",
  addForgerockHeaders: true,
  timeOut: 10,
  contactSupport: true,
  DHSSecurityForms: "http://kb.dhs.maryland.gov/",
  QlikSense: "https://www.kyyba.com/",
  QlikView: "https://reporting.mdthink.maryland.gov/qlikview/index.htm",
  EELink: "http://qa.kcarewp.kyyba.com/",
  EEAPILink: "http://dev2-ee2wp-app.mdcloud.local/eeapi/",
  // EEAPILink: 'http://localhost:8080/eeapi/',
  OIGLink:
    "http://kb.dhs.maryland.gov/office-of-the-inspector-general/customer-fraud-complaint-form/",
};
