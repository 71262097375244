import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-deemed-income-part-two',
  templateUrl: './deemed-income-part-two.component.html',
  styleUrls: ['./deemed-income-part-two.component.css']
})
export class DeemedIncomePartTwoComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
   this.changeQf343_2_1(this.getControlValue('343.2.1'));
   this.changeQf343_2_4(this.getControlValue('343.2.4'));
   this.sum();
   this.onDivide();
  }
  changeQf343_2_1(val) {
    if ( val === 'Y') {
      this.group.controls['343.2.4'].disable();
      this.group.controls['343.2.5'].disable();
      this.group.controls['343.2.3a'].enable();
      this.group.controls['343.2.3b'].enable();
      this.group.controls['343.2.3c'].enable();
    } else if ( val === 'N'){
      this.group.controls['343.2.4'].enable();
      this.group.controls['343.2.5'].enable();
      this.group.controls['343.2.3a'].disable();
      this.group.controls['343.2.3b'].disable();
      this.group.controls['343.2.3c'].disable();
    }
  };

  changeQf343_2_4(val) {
    if ( val === 'N') {
      this.group.controls['343.2.5'].disable();
     
    } else  if ( val === 'Y') {
      this.group.controls['343.2.5'].enable();
    }
  };

  sum(){
    const amt1 = this.group.controls["343.2.2a"].value;
    const amt2 = this.group.controls["343.2.2b"].value;
    const amt3 = this.group.controls["343.2.2c"].value;
    const amt4 = this.group.controls["343.2.2d"].value;
    const amt5 = this.group.controls["343.2.2e"].value;
    const amt6 = this.group.controls["343.2.2f"].value;
    const amt7 = this.group.controls["343.2.2g"].value;
    const amt8 = this.group.controls["343.2.2h"].value;
   const res = (+amt1) + (+amt2) + (+amt3) + (+amt4) + (+amt5) + (+amt6) + (+amt7) + (+amt8);
   this.group.controls["343.2.3a"].patchValue(res);

  }
  onDivide(){
    if(this.getControlValue('343.2.3a') && this.getControlValue('343.2.3a')) {
      const amt1 = this.group.controls["343.2.3a"].value;
      const amt2 = this.group.controls["343.2.3b"].value;
      const res = (amt1) / (amt2) ;
      this.group.controls["343.2.3c"].patchValue(res);
    }
  
  }




}
