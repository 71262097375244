import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';

import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConstantsService } from '../../common/constants.service';
import { AppUtilService } from '../../common/app.util.service';
import { ApiManager } from '../../common/api-manager.service';


@Component({
  selector: 'app-view-review-history',
  templateUrl: './view-review-history.component.html',
  styleUrls: ['./view-review-history.component.css']
})
export class ViewReviewHistoryComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  

  displayedColumns: string[] = ['efctvEndDt',
    'rvwStsDesc',
    'assignee',
    ];

    dataSource = new MatTableDataSource();
  
  constructor(
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    
  ) { }

  ngOnInit() {
    this.getPageData();
  }

  getPageData() {    
      const id: any[] = [];
      id.push(this.data.rvwId);
      this.apiManager.fetchData('getReviewHistory', undefined, ApiManager.GET, undefined, undefined, undefined, id)
        .subscribe(res => {
          if (res) {
            // res.forEach((element,index) => {
            //   if(index !== 0){
            //     if(element.rvwStsDesc === 'PE'){
            //       element.rvwStsDesc = "Submitted";
            //     }
            //     if(element.rvwStsDesc === 'ST'){
            //       element.rvwStsDesc = "Re-review";
            //     }
            //   }
            // });
            this.dataSource.data = res;
            setTimeout(() => { this.dataSource.paginator = this.paginator; });
          }
        }
      );

  }

}
