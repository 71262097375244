import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-real-propert-non-liquid-resources',
  templateUrl: './real-propert-non-liquid-resources.component.html',
  styleUrls: ['./real-propert-non-liquid-resources.component.css']
})
export class RealPropertNonLiquidResourcesComponent extends BaseComponent implements OnInit {
  isNotEditable: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.handleQ221_3(this.getControlValue('221.3'));
    this.handleQ221_4(this.getControlValue('221.4'));
    this.calculateQ221_10();
    this.calculateQ221_28();
    this.calculateQ221_44();
  }
  get Is_221_1(): boolean {
    return this.group.controls['221.1'].value === 'N';
  }
  get Is_221_2(): boolean {
    return this.group.controls['221.2'].value === 'Y';
  }

  get Is_221_3(): boolean {
    return this.group.controls['221.3'].value === '1';
  }

  get Is_221_4(): boolean {
    return this.group.controls['221.4'].value === 'N';
  }

  get Is_221_37(): boolean {
    return this.group.controls['221.37'].value === 'Y';
  }

  get Is_221_24(): boolean {
    return this.group.controls['221.24'].value === 'Y';
  }

  get Is_221_38(): boolean {
    return this.group.controls['221.38'].value === 'N';
  }

  get Is_221_15_N(): boolean {
    return this.group.controls['221.15'].value === 'N';
  }

  get Is_221_17(): boolean {
    return this.group.controls['221.17'].value === 'N';
  }
  get Is_221_18(): boolean {
    return this.group.controls['221.18'].value === 'Y';
  }
  get Is_221_19(): boolean {
    return this.group.controls['221.19'].value === 'Y';
  }
  get Is_221_19_N(): boolean {
    return this.group.controls['221.19'].value === 'Y';
  }
  get Is_221_21(): boolean {
    return this.group.controls['221.21'].value === 'Y';
  }
  get Is_221_33(): boolean {
    return this.group.controls['221.33'].value === 'N';
  }
  get Is_221_35(): boolean {
    return this.group.controls['221.35'].value === 'N';
  }
  get Is_221_49_N(): boolean {
    return this.group.controls['221.49'].value === 'N';
  }
  changeof221_1(event:any){
    if (event.value === 'Y') {

      this.group.controls['221.6'].enable();
      this.group.controls['221.7'].enable();
      this.group.controls['221.8'].enable();
      this.group.controls['221.9'].enable();
      this.group.controls['221.10'].enable();
    } else {
      this.group.controls['221.6'].disable();
      this.group.controls['221.7'].disable();
      this.group.controls['221.8'].disable();
      this.group.controls['221.9'].disable();
      this.group.controls['221.10'].disable();

    }
  }
  changeof221_2(event:any){
    if (event.value === 'Y') {
      this.group.controls['221.7'].disable();
      this.group.controls['221.8'].disable();
      this.group.controls['221.9'].disable();
      this.group.controls['221.10'].disable();
      
      
      
    } else {
      this.group.controls['221.7'].enable();
      this.group.controls['221.8'].enable();
      this.group.controls['221.9'].enable();
      this.group.controls['221.10'].enable();
     
    }
  }

  changeof221_17(val) {
    if (val === 'Y') {
      this.group.controls['221.24'].disable();
      this.group.controls['221.25'].enable();
      this.group.controls['221.26'].enable();
      this.group.controls['221.27'].enable();
      this.group.controls['221.28'].enable();
    } else {
      this.group.controls['221.24'].disable();
      this.group.controls['221.25'].disable();
      this.group.controls['221.26'].disable();
      this.group.controls['221.27'].disable();
      this.group.controls['221.28'].disable();

    }
  }

  changeof221_18(val) {
    if (val === 'N') {
      this.group.controls['221.24'].disable();
      this.group.controls['221.25'].enable();
      this.group.controls['221.26'].enable();
      this.group.controls['221.27'].enable();
      this.group.controls['221.28'].enable();
    } else {
      this.group.controls['221.24'].disable();
      this.group.controls['221.25'].disable();
      this.group.controls['221.26'].disable();
      this.group.controls['221.27'].disable();
      this.group.controls['221.28'].disable();

    }
  }

  changeof221_20(val) {
    if (val === 'Y') {
     
      this.group.controls['221.21'].enable();
      this.group.controls['221.22'].enable();
      this.group.controls['221.27'].enable();
     
    } else {
      this.group.controls['221.21'].disable();
      this.group.controls['221.22'].disable();
      this.group.controls['221.27'].disable();

    }
  }
  changeof221_22(val) {
    if (val === 'N') {
     
      this.group.controls['221.25'].enable();
      this.group.controls['221.26'].enable();
      this.group.controls['221.27'].enable();
      this.group.controls['221.28'].enable();
     
    } else {
      this.group.controls['221.25'].disable();
      this.group.controls['221.26'].disable();
      this.group.controls['221.27'].disable();
      this.group.controls['221.28'].disable();

    }
  }
  changeof221_35(event: any) {
    if (event.value === 'N') {

      this.group.controls['221.41'].enable();
      this.group.controls['221.42'].enable();
      this.group.controls['221.43'].enable();
      this.group.controls['221.44'].enable();
    } else {
      this.group.controls['221.41'].disable();
      this.group.controls['221.42'].disable();
      this.group.controls['221.43'].disable();
      this.group.controls['221.44'].disable();

    }
  }
  handleQ221_55(val){
    if(val === '9'){
      this.group.controls['221.55a'].enable();
    }else{
      this.group.controls['221.55a'].disable();
    }
}

handleQ221_3(val){
  if(val === '1'){
    this.group.controls['221.4'].disable();
    this.group.controls['221.5'].disable();
    this.group.controls['221.6'].disable();
    this.group.controls['221.11'].enable();
    this.group.controls['221.12'].enable();
    this.group.controls['221.13'].enable();
    this.group.controls['221.14'].enable();
  }else{
    this.group.controls['221.4'].enable();
    this.group.controls['221.5'].enable();
    this.group.controls['221.6'].enable();
    this.group.controls['221.11'].disable();
    this.group.controls['221.12'].disable();
    this.group.controls['221.13'].disable();
    this.group.controls['221.14'].disable();
  }
}

handleQ221_4(val){
if(val==='N'){
  
  this.group.controls['221.4'].disable();
  this.group.controls['221.5'].disable();
  this.group.controls['221.6'].disable();
  this.group.controls['221.9'].disable();
}else{
  this.group.controls['221.4'].enable();
  this.group.controls['221.5'].enable();
  this.group.controls['221.6'].enable();
  this.group.controls['221.9'].enable();
}
}

calculateQ221_10(){
  let Q221_10=0;
  let Q221_7=0;
  let Q221_8=0;
  let Q221_9=0;
  if(null != this.getControlValue('221.7')){
    Q221_7 = this.getControlValue('221.7');
  }
  if(null != this.getControlValue('221.8')){
   Q221_8 = this.getControlValue('221.8');
  }
  if(null != this.getControlValue('221.9')){
   Q221_9 = this.getControlValue('221.9');
  }
  if(Q221_7 != 0){
    Q221_10 = (Q221_7 - Q221_8) / (1 + Q221_9);
  }
  this.setControlValue('221.10',Q221_10);
}

changeof221_24(val){
if(val === 'Y'){
  this.group.controls['221.25'].disable();
  this.group.controls['221.26'].disable();
  this.group.controls['221.27'].disable();
  this.group.controls['221.28'].disable();

}else{
  this.group.controls['221.25'].enable();
  this.group.controls['221.26'].enable();
  this.group.controls['221.27'].enable();
  this.group.controls['221.28'].enable();
}
}


calculateQ221_28(){
  let Q221_25=0;
  let Q221_26=0;
  let Q221_27=0;
  let Q221_28=0;
  if(null != this.getControlValue('221.25')){
    Q221_25 = this.getControlValue('221.25');
  }
  if(null != this.getControlValue('221.26')){
   Q221_26 = this.getControlValue('221.26');
  }
  if(null != this.getControlValue('221.27')){
   Q221_27 = this.getControlValue('221.27');
  }
  if(Q221_25 != 0){
    Q221_28 = (Q221_25 - Q221_26) / (1 + Q221_27);
  }
  this.setControlValue('221.28',Q221_28);
}
handleQ221_37(val){
  if(val === 'Y'){
    this.group.controls['221.38'].disable();
    this.group.controls['221.39'].disable();
    this.group.controls['221.40'].disable();
    this.group.controls['221.45'].enable();
    this.group.controls['221.46'].enable();
    this.group.controls['221.47'].enable();
    this.group.controls['221.48'].enable();
  }else{
    this.group.controls['221.38'].enable();
    this.group.controls['221.39'].enable();
    this.group.controls['221.40'].enable();
    this.group.controls['221.45'].disable();
    this.group.controls['221.46'].disable();
    this.group.controls['221.47'].disable();
    this.group.controls['221.48'].disable();
  }
}
handleQ221_38(val){
if(val==='N'){
  this.group.controls['221.39'].disable();
  this.group.controls['221.40'].disable();
  this.group.controls['221.43'].disable();
}else{
  this.group.controls['221.39'].enable();
  this.group.controls['221.40'].enable();
  this.group.controls['221.43'].enable();
}
}

calculateQ221_44(){
  let Q221_44=0;
  let Q221_41=0;
  let Q221_42=0;
  let Q221_43=0;
  if(null != this.getControlValue('221.41')){
    Q221_41 = this.getControlValue('221.41');
  }
  if(null != this.getControlValue('221.42')){
   Q221_42 = this.getControlValue('221.42');
  }
  if(null != this.getControlValue('221.43')){
   Q221_43 = this.getControlValue('221.43');
  }
  if(Q221_41 != 0){
    Q221_44 = (Q221_41 - Q221_42) / (1 + Q221_43);
  }
  this.setControlValue('221.44',Q221_44);
}
}