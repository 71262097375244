import { Component, OnInit } from '@angular/core';
import { ApiManager } from 'src/app/common/api-manager.service';
import { saveAs } from 'file-saver/FileSaver';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-gen380-workbook',
  templateUrl: './gen380-workbook.component.html',
  styleUrls: ['./gen380-workbook.component.css']
})
export class Gen380WorkbookComponent extends BaseComponent implements OnInit {

  
  isNotEditable: boolean = false;
 
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  
  get currentIndividual() {
    return this.shareService.getDataFromMap('currentIndividual');
  }

  set currentIndividual(id) {
    this.shareService.addDataToMap('currentIndividual', id);
  }
  generate380Workbook(){
    const queryParams: any[] = [];

    

    if (this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }
    const invId: any = this.currentIndividual;
    if (invId) {
      queryParams.push(invId);
    }  
    this.screenType = "700140";
      queryParams.push(this.screenType); //screen type
      queryParams.push("1"); //buttonId   
    let filename: any;
    filename = this.constantsService.getDisplayReviewNum(this.constantsService.ReviewInfo.rvwNum)+"_FNS_380_Workbook";
    this.apiManager.fetchData('generate380Workbook', undefined, ApiManager.POST, undefined, undefined, undefined, queryParams)
     .subscribe(res => {
       if (res) {
        this.field[0].ccId = res.ccId;
        // let blob;
        // blob = new Blob([res], { type: 'application/pdf' });
        // saveAs(blob, filename);
        this._snackBar.open('380 Workbook PDF Generated Successfully.', undefined, {
          duration: 2000,
        });
      }
     },
     error => {
       this._snackBar.open('Save failed.Please save and try again. ', undefined, {
         duration: 2000,
       });
       console.log('look up API call failure: Error');
     }
     
   );
  }

  get IsView():boolean{
    return this.field[0].ccId === 0;
  }

  view380WBForm(){
    const queryParams: any[] = [];
    let ccId = this.field[0].ccId;
    if (ccId != 0) {
      queryParams.push(ccId);
    }
      this.apiManager.fetchData('getDocumentForDownload', undefined, ApiManager.FILEDOWNLOAD, undefined, undefined, undefined, queryParams)
      .subscribe(res => {
        if (res) {
      
       let blob = new Blob([res], { type: res.type });
         let fileURL = URL.createObjectURL(blob);
         var newWin = window.open(fileURL);
        }
      }
    );
  }

  generate380Workbook2(){
    const queryParams: any[] = [];

    

    if (this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }
    const invId: any = this.currentIndividual;
    if (invId) {
      queryParams.push(invId);
    }  
    this.screenType = "700140";
      queryParams.push(this.screenType); //screen type
      queryParams.push("2"); //buttonId
    let filename: any;
    filename = this.constantsService.getDisplayReviewNum(this.constantsService.ReviewInfo.rvwNum)+"_FNS_380_Workbook_2";
    this.apiManager.fetchData('generate380Workbook', undefined, ApiManager.POST, undefined, undefined, undefined, queryParams)
     .subscribe(res => {
       if (res) {
        this.field[0].ccId = res.ccId;
        // let blob;
        // blob = new Blob([res], { type: 'application/pdf' });
        // saveAs(blob, filename);
        this._snackBar.open('380 Workbook PDF Generated Successfully.', undefined, {
          duration: 2000,
        });
       }
     }
   );
  }
  
  
  validate380Workbook(){
    const queryParams: any[] = [];
    if (this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }
    this.apiManager.fetchData('validate380Workbook', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
    .subscribe(
      res => {
          let errorMessage = res.notes;
            alert('380 Workbook Validation Completed.'+errorMessage);
            // this._snackBar.open('380 Workbook Validated Successfully.'+errorMessage, undefined, {
            //   duration: 4000,
            // });
        }
    );
  }
}