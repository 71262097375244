import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../../shared-module/baseComponent.component";

@Component({
  selector: "app-recipient-disqualification",
  templateUrl: "./recipient-disqualification.component.html",
  styleUrls: ["./recipient-disqualification.component.css"]
})

export class RecipientDisqualificationComponent extends BaseComponent implements OnInit {
  isNotEditable: boolean = false;
   Is151_1: boolean = false

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.changeof151_1();
    this.changeof151_15();
   
    // this.changeof151_17();
  }

  Is150_17_grey:boolean = false;
 
  changeof151_1(){
    if (this.group.controls['151.1'].value === 'N') {
      this.Is151_1 = true;
      this.group.controls['151.2'].disable();
    } else {
      this.Is151_1 = false;
      this.group.controls['151.2'].enable();
    }
  }
  // get Is151_1(): boolean {
  //   return this.group.controls['151.1'].value === 'N';
  // }


  get Is151_2(): boolean {
    let q1 = ['IPV','FPS','TFBD' ,'TFBM' ,'CTFM' ,'CFAD' ,'FWR' ,'FFPV' ,'NA'];
    return  q1.filter(item => item = this.group.controls['151.2'].value).length > 0 ;
  }

  get Is151_6(): boolean {
    return this.group.controls['151.6'].value === 'N';
  }

  get Is151_6_Y(): boolean {
    return this.group.controls['151.6'].value === 'Y';
  }

  get Is151_11(): boolean {
    return this.group.controls['151.11'].value === 'N';
  }
  get Is150_5(): boolean {
    return this.group.controls['151.5'].value === 'N';
  }
  get Is150_17(): boolean {
    return this.group.controls['151.17'].value === 'N';
  }


  changeof151_15() {
    if (this.group.controls['151.15'].value === 'N') {
      this.group.controls['151.16.1'].disable();
      this.group.controls['151.16.2'].disable();
      this.group.controls['151.16.3'].disable();
      this.group.controls['151.17'].disable();
      this.group.controls['151.18'].disable();
    } else {
      this.group.controls['151.16.1'].enable();
      this.group.controls['151.16.2'].enable();
      this.group.controls['151.16.3'].enable();
      this.group.controls['151.17'].enable();
      this.group.controls['151.18'].enable();

    }
  }
  // changeof151_17()  {
  //   if (this.group.controls['151.17'].value === 'N') {
  //     this.group.controls['151.18'].disable();
  //   }
  //   else if(this.group.controls['151.17'].value === 'Y')
  //   {
  //     this.group.controls['151.18'].enable();

  //   }
  // }

  get Is_151_17_N():boolean{
    let q1 = this.group.controls['151.17'].value ;
    if (q1 === 'N'){
      this.Is150_17_grey = true;
      return true
    }
    else if (q1 === 'Y') {
      this.Is150_17_grey = false;
      return false
    } 
  }
  get Is_151_17_Y():boolean{
    let q1 = this.group.controls['151.17'].value ;
    if (q1 === 'Y'){
      return true
    }
    else if (q1 === 'Y') {
      return false
    } 
  }
  // determineAccess() {
  //   const ref = this.constantsService.getSecurityObject();

  //   if ((ref.accessMap['/qualityControl/questionary-151?type=151'] != null && ref.accessMap['/qualityControl/questionary-151?type=151'].accessMode != 'rw')) {
  //       this.isNotEditable = true;
  //   }
  // }
  handleQ151_24(val){
    if(val === '9'){
      this.group.controls['151.24a'].enable();
    }else{
      this.group.controls['151.24a'].disable();
    }
}
}