import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-nstr-explain',
  templateUrl: './nstr-explain.component.html',
  styleUrls: ['./nstr-explain.component.css']
})
export class NstrExplainComponent extends BaseComponent implements OnInit {
isNotEditable: boolean = false
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

}