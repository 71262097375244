import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-shel-screen',
  templateUrl: './shel-screen.component.html',
  styleUrls: ['./shel-screen.component.css']
})
export class ShelScreenComponent extends BaseComponent implements OnInit {
  currentPage: number = 0;
  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    // const ctrl:any = (this.group.get('FormArray'));

  }
 



}
