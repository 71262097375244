import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-qc16-error-description',
  templateUrl: './qc16-error-description.component.html',
  styleUrls: ['./qc16-error-description.component.css']
})

export class Qc16ErrorDescriptionComponent extends BaseComponent implements OnInit {

  @Input() group: FormGroup;
  currentPage: number = 0;
  isNotEditable: boolean = false;
  subElements: any = [];

  ngOnInit() {
    super.ngOnInit();
    const ctrl:any = (this.group.get('FormArray'));
    //this.sortingValues(ctrl.controls[0]);
    this.subElements = this.constantsService.getLookUpByName('v_rt_fs_sub_ele_list');

    this.determineAccess();
  }
  sortingValues(arrayControl){
    if(arrayControl.controls['500011.1'].value){
      this.changeSubElementByElement('500011.2', arrayControl.controls['500011.1'].value);
    }
  }
  changeSubElementByElement(lkpNameCd, eCd) {
    this.subElements = this.constantsService.getLookUpByName(this.getLookupNameByQCd(lkpNameCd));
    if (this.subElements.length > 0 && eCd) {
      this.subElements = this.subElements.filter(item => item.parentRef === eCd);
    }
  }

}