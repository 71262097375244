import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-citizen',
  templateUrl: './citizen.component.html',
  styleUrls: ['./citizen.component.css']
})

export class CitizenComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.changeQf130_1(this.getControlValue('130.1'));
    this.changeQf130_3(this.getControlValue('130.3'));
    this.changeQf130_10(this.getControlValue('130.10'));
    this.changeof130_11();
    this.changeof130_15();
  }
  changeQf130_1(val) {
    if ( val === 'Y') {
      this.group.controls['130.2'].enable();
      this.group.controls['130.3'].enable();
      this.group.controls['130.5'].disable();
      this.group.controls['130.6'].disable();
      this.group.controls['130.7'].disable();
      this.group.controls['130.8'].disable();
      this.group.controls['130.9'].disable();
      this.group.controls['130.10'].disable();
      this.group.controls['130.11'].disable();

     
    }else {
      this.group.controls['130.2'].disable();
      this.group.controls['130.3'].disable();
      this.group.controls['130.5'].enable();
      this.group.controls['130.6'].enable();
      this.group.controls['130.7'].enable();
      this.group.controls['130.8'].enable();
      this.group.controls['130.9'].enable();
      this.group.controls['130.10'].enable();
      this.group.controls['130.11'].enable();

    }
  }

  get Is_130_1_Y():boolean{
    let q1 = this.group.controls['130.1'].value ;
    if (q1 === 'Y'){
      return true
    }
    else {
      return false
    } 
  }
 
  changeQf130_3(val) {
    if ( val === 'Y') {
      this.group.controls['130.4'].disable();
    }else {
      this.group.controls['130.4'].enable();
    }
  }
  changeQf130_10(val) {
    if ( val === 'Y') {
      this.group.controls['130.11'].disable();
      this.group.controls['130.12'].disable();
      this.group.controls['130.13'].disable();
      this.group.controls['130.16'].disable();
    }else {
      this.group.controls['130.11'].enable();
      this.group.controls['130.12'].enable();
      this.group.controls['130.13'].enable();
      this.group.controls['130.16'].enable();
    }
  }
  changeof130_11() {
    let q1 = this.group.controls['130.11'].value ;
    if (q1 === 'Y') {
      this.group.controls['130.12'].enable();
      this.group.controls['130.13'].disable(); 
      } else  if ( q1 === 'N') {
        this.group.controls['130.12'].disable();
        this.group.controls['130.13'].enable();

      }
  }
  
  get Is_130_11():boolean{
    let q1 = this.group.controls['130.11'].value ;
    if (q1 === 'Y'){
      return true
    }
    else {
      return false
    } 
  }
  get Is_130_11_N():boolean{
    let q1 = this.group.controls['130.11'].value ;
    if (q1 === 'N'){
      return true
    }
    else {
      return false
    } 
  }

  get Is_130_8_N():boolean{
    let q1 = this.group.controls['130.8'].value ;
    if (q1 === 'N'){
      return true
    }
    else {
      return false
    } 
  }
  
  changeof130_15() {
    if ( this.group.controls['130.15'].value === 'Y') {
      this.group.controls['130.16'].disable();
      } else {
        this.group.controls['130.16'].enable();

      }
  }

  changeof130_8() {
    if ( this.group.controls['130.8'].value === 'N') {
      this.group.controls['130.9'].disable();
      this.group.controls['130.10'].disable();
      this.group.controls['130.11'].disable();
      this.group.controls['130.12'].disable();
      this.group.controls['130.13'].disable();
      } else {
        this.group.controls['130.9'].enable();
        this.group.controls['130.10'].enable();
        this.group.controls['130.11'].enable();
        this.group.controls['130.12'].enable();
        this.group.controls['130.13'].enable();
      }
  }
//   handleQ130_22(val){
//     if(val === '99'){
//       this.group.controls['130.22a'].enable();
//     }else{
//       this.group.controls['130.22a'].disable();
//     }
// }
}
