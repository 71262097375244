import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { HttpClient } from "@angular/common/http";
import { ApiManager } from 'src/app/common/api-manager.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { stringify } from 'querystring';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/common/navigation.service';
import { ConstantsService } from 'src/app/common/constants.service';

@Component({
  selector: 'app-monitoring-dashboard',
  templateUrl: './monitoring-dashboard.component.html',
  styleUrls: ['./monitoring-dashboard.component.css']
})

export class MonitoringDashboardComponent implements OnInit {

  constructor( public readonly router: Router,
    public navigationService: NavigationService,
    public constantsService: ConstantsService) { }
  Highcharts = Highcharts;
  ngOnInit() {
    setTimeout(()=>{ 
    Highcharts.chart('container',{
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      title: {
        text: 'Paris Alert Status for Dec, 2019'
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },   
          showInLegend: true
          }
        },
        series: [{
          type:'pie',
          name: 'Alerts',
          data: [{
            name: 'Alert already dispositioned',
            y: 11.84
          }, {
            name: 'Alert Dispositioned',
            y: 10.85
          }, {
            name: 'Could not disposition',
            y: 4.67
          }, {
            name: 'Balance to do',
            y: 4.18
          },]
        }]
      });
      Highcharts.chart('containertwo',{
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: 'National New Hire Alert Status for Dec, 2019'
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },   
            showInLegend: true
            }
          },
          series: [{
            type:'pie',
            name: 'Alerts',
            data: [{
              name: 'Alert already dispositioned',
              y: 25.3
            }, {
              name: 'Alert Dispositioned',
              y: 12.5
            }, {
              name: 'Could not disposition',
              y: 14.5
            }, {
              name: 'Balance to do',
              y: 25.4
            },]
          }]
        });
        Highcharts.chart('containerthree',{
          chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
          },
          title: {
            text: 'State Hire Alert Status for Dec, 2019'
          },
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false
              },   
              showInLegend: true
              }
            },
            series: [{
              type:'pie',
              name: 'Alerts',
              data: [{
                name: 'Alert already dispositioned',
                y: 20.12
              }, {
                name: 'Alert Dispositioned',
                y: 4.16
              }, {
                name: 'Could not disposition',
                y: 4.67
              }, {
                name: 'Balance to do',
                y: 10.16
              },]
            }]
          });
},200)
  }
  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }
  back(){
    this.navigationService.navigatePage(this.router.url, 'back');
  }

  next(){
    this.navigationService.navigatePage(this.router.url, 'next');
  }
}