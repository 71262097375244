import { Component, OnInit, Input } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-qc16-additional-information',
  templateUrl: './qc16-additional-information.component.html',
  styleUrls: ['./qc16-additional-information.component.css']
})

export class Qc16AdditionalInformationComponent extends BaseComponent implements OnInit {

  @Input() group: FormGroup;
  currentPage: number = 0;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/qualityControl/questionary-500012?type=500012'] != null && ref.accessMap['/qualityControl/questionary-500012?type=500012'].accessMode != 'rw')) {
        this.isNotEditable = true;
    }
  }
}