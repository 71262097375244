
import { Component, OnInit, ChangeDetectorRef, HostListener, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormsModule } from '@angular/forms';
import { ConstantsService } from '../../common/constants.service';
import { CustomValidatorsService } from '../../common/custom-validators.service';
import { AppUtilService } from '../../common/app.util.service';
import { ApiManager } from './../../common/api-manager.service';
import { Router } from '@angular/router';
import { NavigationService } from '../../common/navigation.service';
import { environment } from '../../../environments/environment';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialogRef, MAT_DIALOG_DATA} from "@angular/material/dialog";
import { RunSamplingComponent } from 'src/app/shared-module/run-sampling/run-sampling.component';
import * as jspdf from 'jspdf';  
import html2canvas from 'html2canvas';
import { FnsTransmissionComponent } from 'src/app/quality-control/fns-transmission/fns-transmission.component';
import { LdssInformationComponent } from 'src/app/shared-module/ldss-information/ldss-information.component';



@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css'],

})

export class NavMenuComponent implements OnInit {
  @ViewChild('searchButton', { static: false }) searchButton: ElementRef;


  searchCaseForms: FormGroup;
  public securityObject = undefined;
  errorMessage: Boolean = false;
  public showPage: Boolean = false;
  cbool = true;
  caseId: string;
  errorMsg = '';
  private
  roleArraySize: number;
  showSettings = false;
  showVendAccess: boolean = true;
  helpTab: any = 'home';
  //private helpDoc: any = environment.helpDoc;
  showicon: boolean = false;
  showSchedule: boolean = false;
  showMenu: boolean = false;
  isNotEditable: boolean = false;
  enableInquiry: boolean = true;
  enableAppReg: boolean = true;
  enableEligibilityDet: boolean = true;
  enableCaseNarrative: boolean = true;
  enableRedetermination: boolean = true;
  enableCorrespondence: boolean = true;
  enableInterfaceSearch: boolean = true;
  enableSVESRequest: boolean = true;
  enableFederalReporting: boolean = true;
  disableControls: boolean = false;

  constructor(
    private router: Router,
    // private shareService: DataShareService,
    public constantsService: ConstantsService,
    private utilService: AppUtilService,
    public customValidatorsService: CustomValidatorsService,
    private apiManager: ApiManager,
    // private authService: AuthService,
    private fb: FormBuilder,
    // private dataShareObservableService: DataShareObservableService,
    // private cdRef: ChangeDetectorRef,
    // private storage: StorageService,
    // private emitter: EventEmitterService,
    public dialog: MatDialog,
    public navigationService: NavigationService,
  ) {

  }

  hideValue: boolean;
  someMethod(): void {
    this.navigationService.setHideValue(!this.hideValue);
    console.log(this.hideValue,"setvalue");   
  }
  ngOnInit() {
    this.navigationService.getHideValue().subscribe((value) => {
      this.hideValue = value;
      console.log(this.hideValue, "getvalue");
    });
    // this.apiManager.fetchData('lookupType', undefined,
    // ApiManager.GET, undefined, undefined,
    // undefined, undefined).subscribe(
    // res => {
    //   this.constantsService.localLookupData = res as any as LookupContainerModel;
    // }
    // );
    // this.showicon = this.shareService.getDataFromMap('showicon');
    //     if (ConstantsService.humanServiceModel.benefitsCase.caseId) {
    //       this.showicon = true;
    //     }
    this.initForm();
    this.hideSettings();
    this.showPage = true;
    this.determineAccess();
    //     this.checkInquiryAccess()
    //     this.checkAppRegAccess();
    //     this.checkEligibilityDeterminationAccess();
    //     this.checkCaseNarrativeAccess();
    //     this.checkRedeterminationAccess();
    //     this.checkCorrespondenceAccess();
    //     this.checkInterfaceSearchAccess();
    //     this.checkSVESRequestAccess();
    //     this.checkControlAccess();

  }

  //   checkControlAccess() {
  //     const ref = this.constantsService.getSecurityObject();
  //     let roleArraySize = ref.user.roles.length;
  //     for (let i = 0; i < roleArraySize; i++) {
  //       if (ref.user.roles[i] == "EE_MDH_USER" || ref.user.roles[i] == "EE_DORS_USER") {
  //         this.disableControls = true;
  //       }
  //     }

  //     // Set the value back to false if they have another role that will override this.
  //     for (let i = 0; i < roleArraySize; i++) {
  //       if (ref.user.roles[i] != "EE_MDH_USER" && ref.user.roles[i] != "EE_DORS_USER") {
  //         this.disableControls = false;
  //       }
  //     }
  //   }

  initForm() {
    this.searchCaseForms = this.fb.group({
      'caseId': [''],
      'districtOffice': [''],
      'ssn': [''],
      'memberId': [''],
      'firstName': [''],
      'middleName': [''],
      'lastName': [''],
      'suffix': ['']
    })
  }

  gotoReports() {
    window.open(environment.QlikSense);
  }

  gotoMABS() {
    
  }

  gotoOIG() {
    window.open(environment.OIGLink);
  }

  gotoSchedule(appCd) {
    if (appCd === 'FSME') {
      this.router.navigate(['/bpe/fsme/fsme-schedule']);
    }
    else if (appCd === 'PA') {
      this.router.navigate(['/bpe/payment-accuracy/pa-schedule']);
    }

    else if (appCd === 'WPR') {
      this.router.navigate(['/bpe/wpr/wpr-schedule']);
    }
  }

  gotoReview() {

    const reviewUrl = this.constantsService.getNavigateToRvwListByUrl(this.router.url);
    if (reviewUrl) {
      this.router.navigate([reviewUrl]);
    }


  }
  
  gotoLDSS(){
    this.router.navigate(['/qualityControl/ldssInformation']);
  }

  

  gotoReviewDocuments(){
    this.router.navigate(['/qualityControl/ReviewDocuments']);
  }
  gotoSampling() {
    this.router.navigate(['/qualityControl/runSampling']);
  }

  gotoProcessLog(){
    this.router.navigate(['/qualityControl/qcInquiry']);
  }

  gotoAllocation(){
    this.router.navigate(['/qualityControl/qcAllocation']);
  }

  gotoFileScan(){
    // this.constantsService.setDocInfo(this.docInfo.value);
    this.router.navigate(['/qualityControl/fileScan']);
  }

  gotoQc_10(){
    this.router.navigate(['/qualityControl/Qc10']);
  }

  openBPESamplingDialog(value:any){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      
    };


    const dialogRef = this.dialog.open(RunSamplingComponent, dialogConfig);
    dialogRef.updateSize('65%', '75%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log('afterClose', val);
      }

    });
  }

  gotoFNSTrans() {
    this.router.navigate(['/qualityControl/fnsTransmission']);
    
    // const dialogConfig = new MatDialogConfig();
    // dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    // dialogConfig.data = {
      
    // };


    // const dialogRef = this.dialog.open(FnsTransmissionComponent, dialogConfig);
    // dialogRef.updateSize('65%', '85%');
    // dialogRef.afterClosed().subscribe(val => {
    //   if (val) {
    //     console.log('afterClose', val);
    //   }

    // });
  }

  convertPDF() {

    let data = document.getElementById('appPagePDF');
    html2canvas(data).then(canvas => {
      const imgWidth = 208;
      const pageHeight = 295;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png');
      const pdf = new jspdf('p', 'mm', 'a4'); 
      const position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      let rvwMonthDate = new Date().toString() ;
      pdf.save(rvwMonthDate + '.pdf');
    });
  }

  //   openMenu() {
  //     this.showMenu = true;
  //   }

  //   closeMenu() {
  //     this.showMenu = false;
  //   }

  //   showPopover() {
  //     this.trigger.togglePopover();
  //   }

  //   triggerCaseDoc() {
  //     this.appCaseDoc.close();
  //   }

  hideSettings() {
    // const ref = this.constantsService.getSecurityObject();
    // this.roleArraySize = ref.user.roles.length;
    // if (ref && this.roleArraySize == 1 && (ref.user.roles[0] == 'EE_VEND_ADMN' || ref.user.roles[0] == 'EE_OP_CASEMANAGER' || ref.user.roles[0] == 'EE_OP_SUPERVISOR')) {
    //   this.showVendAccess = false;
    // } else {
    this.showVendAccess = true;
    // }
    // for (let i = 0; i < this.roleArraySize; i++) {
    //   if (ref.user.roles[i] == 'EE_SUPUSR' || ref.user.roles[i] == 'EE_VEND_ADMN' || ref.user.roles[i] == 'EE_OP_CASEMANAGER' || ref.user.roles[i] == 'EE_OP_SUPERVISOR') {
    return this.showSettings = true;
    //   }
    // }


  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();
    if ((ref.accessMap[this.router.url] != null && ref.accessMap[this.router.url].accessMode != "rw") 
    || (ref.user.roles.find((x: any) => x == 'EE_QC_ANALYST'))
    || (ref.user.roles.find((x: any) => x == 'EE_EGWKR'))) {
      this.isNotEditable = true;
    }
    if (ref.user.roles.find((x: any) => x == 'EE_QC_ADMIN')){
      
      this.isNotEditable = false;
    }
  }


  search() {
    if (this.caseId === undefined || this.caseId === null) {
      this.utilService.hideLoader();
      this.searchButton.nativeElement.disabled = false;
      this.errorMsg = '';
      // this.checkCaseIdError(this.caseId)
    } else if (true) {
      const caseIdFull = this.caseId;
      // if ( caseIdFull.length < 7  ) {
      //   caseIdFull = new Date().getFullYear().toString() + caseIdFull;
      // }

      const queryParams: any[] = [];
      queryParams.push(caseIdFull);
      this.router.navigate(['/qualityControl/analystCaseSummary']);

      this.apiManager.fetchData('getCaseReviewSearch', undefined, ApiManager.GET,
        undefined, undefined, undefined, queryParams).subscribe(
          res => {
            if (res.length > 0) {
              if(res[0].analystId==="Not Accessible"){
                this.utilService.hideLoader();
                this.searchButton.nativeElement.disabled = false;
                this.errorMsg = 'Review Number Not Accessible';
              }else{
                this.review(res[0]);
              }
            } else {
              this.utilService.hideLoader();
              this.searchButton.nativeElement.disabled = false;
              this.errorMsg = 'Invalid Review Number';
            }

          },
          error => {
            console.log('look up API call failure: Error');
          }
        );
    } else {
      this.utilService.hideLoader();
      this.searchButton.nativeElement.disabled = false;
      this.errorMsg = 'Invalid Case ID';
      // this.checkCaseIdError(this.caseId);
    }
  }

  review(rvwInfo) {
    this.constantsService.ReviewInfo = rvwInfo;
    ConstantsService.isConfirmChecked = rvwInfo['confirmSt'];
    if (this.constantsService.ReviewInfo) {
      this.refreshNav();
    }

  }

  refreshNav() {
    this.navigationService.getPrograms(this.navigationService.appCd).then(v => {
      this.afterNavChange();
    });
  }

  afterNavChange() {
    // Do Nothing
    // TODO : if any change after nav change while adding new program
    const initiatePage = this.constantsService.getNavigateToRvwList();

    this.navigationService.navigatePage(initiatePage, 'next');
  }
  activeMenu: string = '';

  toggleMenu(menu: string) {
    this.activeMenu = this.activeMenu === menu ? '' : menu;
  }
  
  isMenuOpen(menu: string): boolean {
    return this.activeMenu === menu;
  }
  
  onMenuClosed(menu: string) {
    if (this.activeMenu === menu) {
      this.activeMenu = '';
    }
  }
}
