import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-cs-staff-pgm-access',
  templateUrl: './cs-staff-pgm-access.component.html',
  styleUrls: ['./cs-staff-pgm-access.component.css']
})
export class CsStaffPgmAccessComponent extends BaseComponent implements OnInit {
  currentPage: number = 0;
  ngOnInit() {
    super.ngOnInit();
  }

}
