import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ApiManager } from 'src/app/common/api-manager.service';
import { ConstantsService } from 'src/app/common/constants.service';
import { NavigationService } from 'src/app/common/navigation.service';
import { Analyst } from 'src/app/model/api/region-district-analyst.class';

@Component({
  selector: 'app-create-alert-dialog',
  templateUrl: './create-alert-dialog.component.html',
  styleUrls: ['./create-alert-dialog.component.css']
})
export class CreateAlertDialogComponent implements OnInit {
  public alertForm: FormGroup;
  allAnalysts: Analyst[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<any>,
    private fb: FormBuilder,
    private constantsService:ConstantsService,
    private navigationService:NavigationService,
    public apiManager: ApiManager
    ) {
        this.allAnalysts = this.constantsService.getDistinctReviewer();
    }

  ngOnInit() {
    this.createAlertForm();
  }

  createAlertForm() {
    this.alertForm = this.fb.group({
      alertTitle: null,
      alertBody: null,
      reviewNum: null,
      workItemDueDt: null,
      assingedTo : null,
      workItemSubTypeCd : "CS"
    });
  }

  submit(){
    const data = this.alertForm.getRawValue();
    const queryParams: any[] = [];
    const appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    queryParams.push(appCd);
    this.apiManager.fetchData('createWorkItem', undefined, ApiManager.POST, data, undefined, undefined, queryParams)
      .toPromise().then(val => {
        console.log(val);
        if(val && val.status === 200){
          this.close('yes');
          alert("Alert created successfully");
        }
      }).catch(err => {
        console.log(err);
      });
  }

  close(val){
    this.data = val;
    this.dialogRef.close(this.data);
  }
}
