import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgMaterialModule } from './../../angular-material/ag-matrial.module';
import { SharedModuleModule } from './../../shared-module/shared-module.module';
import {
  QuestionaryComponent,
  QuestionaryDialog
} from './../../shared-module/questionary/questionary.component';
import { QuestionaryResolver } from './../../shared-module/questionary/questionary.resolver';
import { FSMERoutingModule } from './fsme.routing';
import { FsmeDashboardComponent } from './bpe-admin/fsme-dashboard/fsme-dashboard.component';
import { FsmeCaseAssignmentComponent } from './bpe-admin/fsme-case-assignment/fsme-case-assignment.component';
// import { FsmeSupervisoryReviewComponent } from '../../shared-module/fsme-supervisory-review/fsme-supervisory-review.component';
import { FspPreReviewCasesComponent } from './fsme/fsp-pre-review-cases/fsp-pre-review-cases.component';
import { FsAppsFiledComponent } from './fsme/fs-apps-filed/fs-apps-filed.component';
import { MyDhrOnlineAppsComponent } from './fsme/my-dhr-online-apps/my-dhr-online-apps.component';
import { RedetsComponent } from './fsme/redets/redets.component';
import { DenialsComponent } from './fsme/denials/denials.component';
import { ClosingsComponent } from './fsme/closings/closings.component';
import { AbawdActivesComponent } from './fsme/abawd-actives/abawd-actives.component';
import { AbawdClosuresComponent } from './fsme/abawd-closures/abawd-closures.component';
import { ReceipientIntegrityComponent } from './fsme/receipient-integrity/receipient-integrity.component';
import { SvesPrisonMatchComponent } from './fsme/sves-prison-match/sves-prison-match.component';
import { DeathMatchComponent } from './fsme/death-match/death-match.component';
import { NewHireAlertsComponent } from './fsme/new-hire-alerts/new-hire-alerts.component';
import { RecipientClaimsComponent } from './fsme/recipient-claims/recipient-claims.component';
import { EbtMultipleIssuancesComponent } from './fsme/ebt-multiple-issuances/ebt-multiple-issuances.component';
import { CaseDocumentsComponent } from './fsme/case-documents/case-documents.component';
import { CsCustomerPgmAccessComponent } from './interview/cs-customer-pgm-access/cs-customer-pgm-access.component';
import { CsStaffPgmAccessComponent } from './interview/cs-staff-pgm-access/cs-staff-pgm-access.component';
import { EbtModuleComponent } from './interview/ebt-module/ebt-module.component';
import { ReviewerObservationsComponent } from './interview/reviewer-observations/reviewer-observations.component';
import { VoterRegistrationReviewerComponent } from './interview/voter-registration-reviewer/voter-registration-reviewer.component';
import { CaInitiativesImplementedComponent } from './interview/ca-initiatives-implemented/ca-initiatives-implemented.component';
import { BlindPhoneCallComponent } from './interview/blind-phone-call/blind-phone-call.component';
import { CboInterviewComponent } from './interview/cbo-interview/cbo-interview.component';
import { CaBestPracticesComponent } from './interview/ca-best-practices/ca-best-practices.component';
import { HighchartsChartModule } from "highcharts-angular";

import { FsmeLdssReportComponent } from './fsme-reports/fsme-ldss-report/fsme-ldss-report.component';
import { LdssReviewListComponent, LDssEvaluationDialog } from './interview/ldss-review-list/ldss-review-list.component';
import { SimplifiedReportingComponent } from './fsme/simplified-reporting/simplified-reporting.component';
import { LdssReviewCaseListComponent } from './interview/ldss-review-case-list/ldss-review-case-list.component';




@NgModule({
  declarations: [
    FsmeDashboardComponent,
    FsmeCaseAssignmentComponent,
    // FsmeSupervisoryReviewComponent,
    FspPreReviewCasesComponent,
    FsAppsFiledComponent,
    MyDhrOnlineAppsComponent,
    RedetsComponent,
    DenialsComponent,
    ClosingsComponent,
    AbawdActivesComponent,
    AbawdClosuresComponent,
    ReceipientIntegrityComponent,
    SvesPrisonMatchComponent,
    DeathMatchComponent,
    NewHireAlertsComponent,
    RecipientClaimsComponent,
    EbtMultipleIssuancesComponent,
    CaseDocumentsComponent,
    CsCustomerPgmAccessComponent,
    CsStaffPgmAccessComponent,
    EbtModuleComponent,
    ReviewerObservationsComponent,
    VoterRegistrationReviewerComponent,
    CaInitiativesImplementedComponent,
    BlindPhoneCallComponent,
    CboInterviewComponent,
    CaBestPracticesComponent,
    LDssEvaluationDialog,
    FsmeLdssReportComponent,
    LdssReviewListComponent,
    SimplifiedReportingComponent,
    LdssReviewCaseListComponent
  ],
  imports: [
    CommonModule,
    FSMERoutingModule,
    SharedModuleModule,
    AgMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    HighchartsChartModule,
  ],
  exports: [FSMERoutingModule],
  entryComponents: [
    FsmeCaseAssignmentComponent,
    // FsmeSupervisoryReviewComponent,
    FspPreReviewCasesComponent,
    FsAppsFiledComponent,
    MyDhrOnlineAppsComponent,
    RedetsComponent,
    DenialsComponent,
    ClosingsComponent,
    AbawdActivesComponent,
    AbawdClosuresComponent,
    ReceipientIntegrityComponent,
    SvesPrisonMatchComponent,
    DeathMatchComponent,
    NewHireAlertsComponent,
    RecipientClaimsComponent,
    EbtMultipleIssuancesComponent,
    SimplifiedReportingComponent,
    CaseDocumentsComponent,
    CsCustomerPgmAccessComponent,
    CsStaffPgmAccessComponent,
    EbtModuleComponent,
    ReviewerObservationsComponent,
    VoterRegistrationReviewerComponent,
    CaInitiativesImplementedComponent,
    BlindPhoneCallComponent,
    CboInterviewComponent,
    CaBestPracticesComponent,
    LDssEvaluationDialog
  ],
  providers: [QuestionaryResolver]
})
export class FsmeModule { }
