
import { MatDialogConfig } from '@angular/material/dialog';
import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ConstantsService} from '../../common/constants.service';
import {ApiManager} from '../../common/api-manager.service';
// import {DataShareService} from 'app/common/data-share.service';
// import {AppUtilService} from 'app/common/app.util.service';
import {CustomValidatorsService} from './../../common/custom-validators.service';
import {Router} from '@angular/router';
// import {ContactSupportClass} from "../../model/api/contact-support.class";
import * as JSZip from 'jszip';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { DataShareService } from 'src/app/common/data-share.service';
import { AppUtilService } from 'src/app/common/app.util.service';
import { ContactSupportClass } from 'src/app/model/contact-support.class';
import { NavigationService } from 'src/app/common/navigation.service';
// import { userInfo } from 'os';

@Component({
  selector: 'app-report-issue',
  templateUrl: './report-issue.component.html',
  styleUrls: ['./report-issue.component.css']
})
export class ReportIssueComponent implements OnInit {
  @ViewChild('canvas',{static:false}) mycanvas: any;
  @ViewChild(FileUploadComponent,{static:false}) fileUpload: FileUploadComponent;
  irn: number;
  maId: string;
  caseMode: string;
  districtOfficeName: string;
  public securityObject = undefined;
  filesToUpload = [];
  hasError: boolean = null;
  errorAlert: string = null;
  fileUploading: Boolean = false;
  fileUploaded: Boolean = false;
  uploadProgress: any;
  file: any = '';
  workerId: string = '';
  ldssName: string;
  county: string;
  emailId: string;
  screenshot: any;
  contactSupportForm: FormGroup;

  constructor(public fb: FormBuilder, 
              public constantsService: ConstantsService,
              public shareService: DataShareService,
              public customValidatorsService: CustomValidatorsService,
              public apiManager: ApiManager,
              public utilService: AppUtilService,
              public router: Router,
              public navigationService: NavigationService,
  ) { 
  }

  ngOnInit() {
    this.screenshot = this.shareService.getDataFromMap('screenshot');
    console.log(this.screenshot);
    let queryParam: any [] = [];
    const ref = this.constantsService.getSecurityObject();
    if (ref.user.uniqueId) {
      this.workerId = ref.user.uniqueId;
      this.emailId = ref.user.userId;
    }
  
    this.setReviewerLdssById(this.emailId);
    this.buildContactForm();


  }

  setReviewerLdssById(wrkId) {
    
    this.constantsService.regionDistrictAnalystLookup.map(
      usr => {
        if (usr.uniqueid === this.workerId|| usr.uniqueid  === this.emailId) {
          this.county = usr.county;
          this.ldssName = usr.ldssDoName;
        }

      }
    );
    
  }

  saveContactSupport(model) {
    let contactSupport = new ContactSupportClass();
    let doc = model.doc;
    delete model.doc;
    model.defectDescription = model.defectDescription + ' Screen Element:' + model.defectCategory;

    contactSupport = model;
    if (contactSupport.workerId == null) {
      contactSupport.workerId = '99999999999';
    }
   
    if (contactSupport.defectCategory == 'DS') {
      contactSupport.CategoryName = 'Dashboard';
    } else if (contactSupport.defectCategory == 'SP') {
      contactSupport.CategoryName = 'Sampling';
    } else if (contactSupport.defectCategory == 'FT') {
      contactSupport.CategoryName = 'File Transmission';
    } else if (contactSupport.defectCategory == 'OT') {
      contactSupport.CategoryName = 'Other';
    }
    else {
      contactSupport.CategoryName = 'Case Review';
    }
    contactSupport.categoryName = "App Registration";
    this.apiManager.fetchData('contactSupport', undefined, ApiManager.POST,
      contactSupport, undefined, undefined, undefined).subscribe(
      res => {
        let recordId = res.recordId;
        //document.getElementById('alertButton').click();
        if(this.shareService.getDataFromMap('screenshot')){
          let screenshot = this.shareService.getDataFromMap('screenshot');
          // screenshot = this.singleFileUpload(screenshot);
          let queryParams: any[] = [];
          queryParams.push(recordId, 'Y');
          this.apiManager.fetchData('contactSupportUpload', {doc: screenshot}, ApiManager.FILEUPLOAD, undefined, undefined, undefined, queryParams)
            .subscribe(res => {
              this.shareService.removeDataFromMap('screenshot');
            })
        }
        if (this.file == undefined || this.file == "") {
          this.contactSupportForm.reset();
        }
        else {
          let queryParams: any[] = [];
          queryParams.push(recordId, 'N');
          this.apiManager.fetchData('contactSupportUpload', {doc: this.file}, ApiManager.FILEUPLOAD, undefined, undefined, undefined, queryParams)
            .subscribe(res => {
              this.contactSupportForm.reset();
              this.file = 'No File';
            })
        }
      })
  }

  setCurrentSelection() {
    let verification = 'Verifications';
    const clientId = this.shareService.getDataFromMap('currentIndividual');
    // const caseId = this.constantsService.getConstant('humanServiceModel')['benefitsCase']['caseId'];
    ConstantsService.citizenshipFileUpload = true;
    // this.fileUpload.initializeFileUploadPopUp(undefined, undefined, caseId, clientId, undefined,
    //   undefined, verification);
  }

  buildContactForm() {
    this.contactSupportForm = this.fb.group({
      defectCategory: ['', Validators.required],
      workerId: this.workerId,
      defectDescription: ['', Validators.required],
      expectedResult: ['', Validators.required],
      actualResult: ['', Validators.required],
      programName: ['', Validators.required],
      doc: [''],
      caseId: ['' || this.constantsService.ReviewInfo?this.constantsService.ReviewInfo.rvwNum:''],
      title: ['', Validators.required],
      type: ['', Validators.required],
      county: this.county,
      distOffice: this.ldssName,
      severity: [''],
      CategoryName: [''],
      categoryName: ['']
    });
  }
  clearData(){
    this.contactSupportForm.reset();
    this.shareService.removeDataFromMap('screenshot');
  }

  checkUnsupportedDoc(event) {
    let unsupportedDoc = false;
    for (let file of event.target.files) {
      if (file.type === 'text/javascript' || this.matchString('audio%', file.type) || this.matchString('video%', file.type)
        || file.type === 'application/x-msdownload') {
        unsupportedDoc = true;
        break;
      }
    }
    return unsupportedDoc;
  }

  matchString(source, input) {
    let match = true;
    for (let i = 0; (i < source.length && source[i] != '%'); i++) {
      if (input[i] !== source[i]) {
        match = false;
      }
    }
    return match;
  }

  onFileChange(event) {
    let unsupportedDocExist = this.checkUnsupportedDoc(event);
    if (unsupportedDocExist) {
      alert("You are uploading an unsupported Document(.js/.exe/.mov/.mp4), please check and reupload.");
      return null;
    }
    this.filesToUpload = [];
    if (event.target.files.length) {
      for (let file of event.target.files) {
        let uniqueName = file.name + Math.floor((Math.random() * 1000) + 1);
        console.log("File", file);
        let inputVal = (<HTMLInputElement>document.getElementById('fileInput'));
        this.singleFileUpload(file);
      }
    }
  }

  async singleFileUpload(file) {
    console.log('FILE UPLOADING');
    const uploadFile = file;
    this.hasError = false;
    if (!uploadFile) {
      this.hasError = true;
      this.errorAlert = 'Error: Unexpected Error occured. File couldn\'t be found.';
    }
    if (!this.hasError) {
      let fileReader = new FileReader();
      this.fileUploading = true;
      this.fileUploaded = false;
      this.apiManager.fileUploadtProgress$.subscribe(data => {
        this.uploadProgress = data;
      });
      fileReader.readAsArrayBuffer(uploadFile);
      let zipFile: JSZip = new JSZip();
      let that = this;
      fileReader.onload = async function () {
        zipFile.file(uploadFile.name, fileReader.result);
        zipFile.generateAsync({
          type: 'blob',
          compression: 'DEFLATE',
          compressionOptions: {
            level: 9
          },
          mimeType: uploadFile.type
        }).then(async (blob) => {
          await that.uploadDocument(uploadFile);
        });
      };
    }
  }

  uploadDocument(file) {
    this.file = file;
  }
}