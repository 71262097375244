import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-self-employment',
  templateUrl: './self-employment.component.html',
  styleUrls: ['./self-employment.component.css']
})

export class SelfEmploymentComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  get Is312_1_1(): boolean {
    return this.group.controls['312.1.1'].value === 'Y';
  }
  get Is312_1_2(): boolean {
    return this.group.controls['312.1.2'].value === 'Y';
  }
  get Is312_1_29(): boolean {
    return this.group.controls['312.1.29'].value === 'N';
  }
  get Is312_1_30(): boolean {
    return this.group.controls['312.1.30'].value === 'Y';
  }
  get Is312_1_30_N(): boolean {
    return this.group.controls['312.1.30'].value === 'N';
  }

  changeof312_0_2(event: any) {
    if ( event.value === 'N') {
      this.group.controls['312.0.3'].disable();
      this.group.controls['312.0.4a'].disable();
      this.group.controls['312.0.4b'].disable();
      this.group.controls['312.0.4c'].disable();
      this.group.controls['312.0.5a'].disable();
      this.group.controls['312.0.5b'].disable();
      this.group.controls['312.0.5c'].disable();
      this.group.controls['312.0.6a'].disable();
      this.group.controls['312.0.6b'].disable();
      this.group.controls['312.0.6c'].disable();
      this.group.controls['312.0.7'].disable();
      } else {
        this.group.controls['312.0.3'].enable();
        this.group.controls['312.0.4a'].enable();
        this.group.controls['312.0.4b'].enable();
        this.group.controls['312.0.4c'].enable();
        this.group.controls['312.0.5a'].enable();
        this.group.controls['312.0.5b'].enable();
        this.group.controls['312.0.5c'].enable();
        this.group.controls['312.0.6a'].enable();
        this.group.controls['312.0.6b'].enable();
        this.group.controls['312.0.6c'].enable();
        this.group.controls['312.0.7'].enable();

      }
  }
  
}