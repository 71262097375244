import { AfterViewInit, ChangeDetectorRef, Component, OnInit, SimpleChanges } from '@angular/core';
import { Console } from 'console';
import { ApiManager } from 'src/app/common/api-manager.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-qc16-narration',
  templateUrl: './qc16-narration.component.html',
  styleUrls: ['./qc16-narration.component.css']
})

export class Qc16NarrationComponent extends BaseComponent implements OnInit ,AfterViewInit{
  questionsArray:any[]=[];
  ngAfterViewInit(): void {
   this.questionsArray.forEach(question=>{
     if(this.group.controls[question.name].value == true){
       let event:any={
         checked:true
       }
        this.change500013_1a(question.val,event);
     }
   })
  }

  selectedCode: string; // Declare selectedCode variable
  isChecked: boolean;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.questionsArray=[{name:'500013.1b',val:'1'},{name:'500013.1c',val:'2'},{name:'500013.1d',val:'3'},{name:'500013.1e',val:'4'},{name:'500013.1f',val:'5'},{name:'500013.1g',val:'6'},{name:'500013.1h',val:'7'}]
  }


  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/qualityControl/questionary-500013?type=500013'] != null && ref.accessMap['/qualityControl/questionary-500013?type=500013'].accessMode != 'rw')) {
      this.isNotEditable = true;
    }
  }
  

  change500013_1a(selectedCode: any, event) {
    const isChecked = event.checked;

    const codeToControlsMap = {
        '1': ['500013.1c', '500013.1d', '500013.1e', '500013.1f', '500013.1g', '500013.1h'],
        '2': ['500013.1b', '500013.1d', '500013.1e', '500013.1f', '500013.1g', '500013.1h'],
        '3': ['500013.1b', '500013.1c', '500013.1e', '500013.1f', '500013.1g', '500013.1h'],
        '4': ['500013.1b', '500013.1c', '500013.1d', '500013.1f', '500013.1g', '500013.1h'],
        '5': ['500013.1b', '500013.1c', '500013.1d', '500013.1e', '500013.1g', '500013.1h'],
        '6': ['500013.1b', '500013.1c', '500013.1d', '500013.1e', '500013.1f', '500013.1h'],
        '7': ['500013.1b', '500013.1c', '500013.1d', '500013.1e', '500013.1f', '500013.1g']
    };

    // Flatten the array of controls based on selectedCode
    const controlsToDisable = codeToControlsMap[selectedCode] ? codeToControlsMap[selectedCode].reduce((acc, val) => acc.concat(val), []) : [];

    for (const control of controlsToDisable) {
        this.group.controls[control].disable();
    }

    if (isChecked) {
        const queryParams: any[] = [
            this.constantsService.ReviewInfo?.rvwNum,
            this.currentIndividual,
            this.screenType,
            selectedCode
        ].filter(param => param !== undefined);

        this.apiManager.fetchData('getQcNarrative', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
            .subscribe(res => {
                if (res) {
                    console.log('getQcNarrative Response ::', res.msg);
                    this.setControlValue('500013.1', res.msg);
                }
            });
    } else {
        // Enable all controls
        for (const control of controlsToDisable) {
            this.group.controls[control].enable();
        }
        this.setControlValue('500013.1', '');
    }
}

}