import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiManager } from 'src/app/common/api-manager.service';
import { ConstantsService } from '../../common/constants.service';
import { NavigationService } from '../../common/navigation.service';
import { ReviewSummaryComponent } from '../review-summary/review-summary.component';

 export interface anylsisElement {
  ldss: number,
  hohNm: string,
  rvwType: string,
  // aidCd: string,
  rvwNum: string,
  rvwMo: string,
  rgnCd: string,
  irn:number,
  rvwStsDesc: string,
}

@Component({
  selector: 'app-review-case-list',
  templateUrl: './review-case-list.component.html',
  styleUrls: ['./review-case-list.component.css']
})
export class ReviewCaseListComponent implements OnInit {

  analystAvailabilityForm: FormGroup;
  // tslint:disable-next-line: max-line-length
  displayedColumns: string[] = ['rvwNum', 'rvwMo','pgmTypeDesc','rvwStsDesc',  
  // 'rgnCd', 
  'ldss', 'caseId','irn',
  //  'aidCd', 
   'actions'];
  dataSource: MatTableDataSource<anylsisElement>;
  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  

  constructor(
    public constantsService: ConstantsService,
    // private apiManager: ApiManager,
    // public _utilService: AppUtilService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private apiManager: ApiManager,
    public navigationService: NavigationService,

    // private shareService: DataShareService,
    // private alertService: AlertService,
    ) {
      this.buildForm();
      this.dataSource = new MatTableDataSource();
      // this.dataSource = [{"ldss":220,"csWrkrNm":null,"caseId":200096426,"hohname":"Person One","rvwType":null,"aidCd":null,"analystId":null,"rvwNum":null,"rvwMo":null,"rgnCd":null,"rvwSts":null},{"ldss":100,"csWrkrNm":null,"caseId":200097868,"hohname":"Jason one","rvwType":null,"aidCd":null,"analystId":null,"rvwNum":null,"rvwMo":null,"rgnCd":null,"rvwSts":null}];

    }

  ngOnInit() {
    
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getAnalystCaseSummary();
  }

  buildForm() {
    this.analystAvailabilityForm = this.fb.group({
      ldss: [''],
      hohNm: [''],
      rvwType: [''],
      // aidCd: [''],
      rvwNum: [''],
      rvwMo: [''],
      rgnCd: [''],
      rvwStsDesc: [''],
      caseId:[''],
      irn:['']

    });
  }

  getAnalystCaseSummary() {

    const queryParams: any[] = [];
    
    let appCd: string = '';
    appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    queryParams.push(appCd);

    const objBody: any = this.constantsService.getSecurityObject().user.uniqueId ;

    
    this.apiManager.fetchData('getAnalystCaseSummary', undefined, ApiManager.POST,
    objBody, undefined, undefined, queryParams)
        .subscribe(res => {
          if (res) {
            console.log(res);

            this.dataSource = new MatTableDataSource(res);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        });
  }

  review(rvwInfo) {
    this.constantsService.ReviewInfo = rvwInfo;
    if (this.constantsService.ReviewInfo) {
      this.refreshNav();
    }

  }

  refreshNav() {
    this.navigationService.getPrograms(this.navigationService.appCd).then(v => {
      this.afterNavChange();
    });
  }

  afterNavChange() {
    // Do Nothing
    // TODO : if any change after nav change while adding new program
    const navURL = this.constantsService.navigateByType();
    if (navURL) {
      this.router.navigateByUrl(navURL); 
    } else {
      this.navigationService.navigatePage(this.router.url, 'next');
    }
    
  }

  submit(rvwInfo) {
    this.review(rvwInfo);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      
    };


    const dialogRef = this.dialog.open(ReviewSummaryComponent , dialogConfig);
    dialogRef.updateSize('75%', '85%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log('afterClose', val);
      }

    });

  }


  print() {

  }

  edit() {

  }

  override() {

  }

  delete() {

  }

  next() {

  }

}
