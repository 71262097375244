import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-failure-to-give-info',
  templateUrl: './failure-to-give-info.component.html',
  styleUrls: ['./failure-to-give-info.component.css']
})
export class FailureToGiveInfoComponent extends BaseComponent implements OnInit {
 ngOnInit() {
    super.ngOnInit();
  }

}



