import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../../shared-module/baseComponent.component";

@Component({
  selector: "app-household",
  templateUrl: "./household.component.html",
  styleUrls: ["./household.component.css"],
})
export class HouseholdComponent extends BaseComponent implements OnInit {
  isNotEditable: boolean = false;
  is150_1_27: Boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.changeQf150_1_3(this.getControlValue("150.1.3"));
    this.Is_150_5;
    this.Is_150_5_RLA;
    this.Is_150_12;
    this.Is_150_1_22;
    this.changeQf150_1_20(this.getControlValue("150.1.20"));
    this.changeQf150_1_22(this.getControlValue("150.1.22"));
    this.changeQf150_1_27();
    this.handleQ150_1_48(this.getControlValue("150.1.48"));
  }

  get Is_150_5(): boolean {
    return this.group.controls["150.1.13"].value === "OH";
  }
  get Is_150_5_RLA(): boolean {
    return this.group.controls["150.1.13"].value === "RLA";
  }
  get Is_150_12(): boolean {
    return this.group.controls["150.1.12"].value === "Y";
  }

  changeQf150_1_3(val) {
    if (val === "Y") {
      this.group.controls["150.1.4"].disable();
      this.group.controls["150.1.5"].disable();
    } else {
      this.group.controls["150.1.4"].enable();
      this.group.controls["150.1.5"].enable();
    }
  }

  handleDialog() {
    let q12 = this.group.controls["150.1.12"].value;
    let q13 = this.group.controls["150.1.13"].value;
    if (q12 && q12 === "Y") {
      switch (q13) {
        case "RLA":
        case "RLO":
        case "HS":
        case "SA":
        case "SO":
        case "NDL":
        case "SP":
          this.openDialog("150.2");
          break;
        case "GH":
          this.openDialog("150.3");
          break;
        case "TCD":
          this.openDialog("150.4");
          break;
        case "DLO":
          this.openDialog("150.5");
          break;
        case "RHS":
          this.openDialog("150.6");
          break;

        default:
      }
    }
  }

  get Is_150_1_12(): boolean {
    return this.group.controls["150.1.12"].value === "N";
  }

  get Is_150_1_22(): boolean {
    return this.group.controls["150.1.22"].value === "Y";
  }
  changeQf150_1_20(val) {
    if (val === "Y") {
      this.group.controls["150.1.21"].disable();
    } else {
      this.group.controls["150.1.21"].enable();
    }
  }
  changeQf150_1_22(val) {
    if (val === "Y") {
      this.group.controls["150.1.23"].disable();
      this.group.controls["150.1.24"].disable();
    } else {
      this.group.controls["150.1.23"].enable();
      this.group.controls["150.1.24"].enable();
    }
  }
  get Is_150_1_27(): boolean {
    return this.group.controls["150.1.27"].value === "N";
  }
  changeQf150_1_27() {
    let val1 = this.group.controls["150.1.26"].value;
    let val2 = this.group.controls["150.1.27"].value;
    if (val1 === "N" && val2 === "N") {
      this.group.controls["150.1.28"].disable();
      this.group.controls["150.1.29"].disable();
      this.group.controls["150.1.30"].disable();
      this.group.controls["150.1.31"].disable();
      this.group.controls["150.1.32"].disable();
      this.group.controls["150.1.33"].disable();
      this.group.controls["150.1.35"].disable();
      this.group.controls["150.1.37"].disable();
      this.is150_1_27 = true;
    } else if (
      (val1 === "Y" && val2 === "N") ||
      (val1 === "N" && val2 === "Y")
    ) {
      this.group.controls["150.1.28"].enable();
      this.group.controls["150.1.29"].enable();
      this.group.controls["150.1.30"].enable();
      this.group.controls["150.1.31"].enable();
      this.group.controls["150.1.32"].enable();
      this.group.controls["150.1.33"].enable();
      this.group.controls["150.1.35"].enable();
      this.group.controls["150.1.37"].enable();
      this.is150_1_27 = false;
    }
  }

  handleQ150_1_48(val) {
    if (val === "99") {
      this.group.controls["150.1.48a"].enable();
    } else {
      this.group.controls["150.1.48a"].disable();
    }
  }
}
