import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-hh-comp-homeless-shelter',
  templateUrl: './hh-comp-homeless-shelter.component.html',
  styleUrls: ['./hh-comp-homeless-shelter.component.css']
})

export class HhCompHomelessShelterComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  changeof150_6_1(event: any) {
    if ( event.value === 'N') {
      this.group.controls['150.6.2'].disable();
      this.group.controls['150.6.3'].disable();
      this.group.controls['150.6.4'].disable();
      this.group.controls['150.6.5'].disable();
      } else {
        this.group.controls['150.6.2'].enable();
        this.group.controls['150.6.3'].enable();
        this.group.controls['150.6.4'].enable();
        this.group.controls['150.6.5'].enable();

      }
  }

  changeof150_6_2(event: any) {
    if ( event.value === 'Y') {  
      this.group.controls['150.6.3'].disable();
      this.group.controls['150.6.4'].disable();
      this.group.controls['150.6.5'].disable();
      this.setControlValue('150.6.6','0');
      } else {
        this.group.controls['150.6.3'].enable();
        this.group.controls['150.6.4'].enable();
        this.group.controls['150.6.5'].enable();
        this.setControlValue('150.6.6','');

      }
  }
  
  changeQf150_6_3(val){
    if ( val === 'N') {
     
      this.group.controls['150.6.4'].disable();
      this.group.controls['150.6.5'].disable();
      this.setControlValue('150.6.6','0');
      } else {
     
        this.group.controls['150.6.4'].enable();
        this.group.controls['150.6.5'].enable();
        this.setControlValue('150.6.6','');

      }
  }
  Is_4(event: Event) {
    let Q4 = + this.getControlValue('150.6.4');
    if(Q4>0)
    {
      this.setControlValue('150.6.6','156.74');

    }
    else{
      this.setControlValue('150.6.6','0');
    }
  }
}