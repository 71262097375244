import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-fs-apps-filed',
  templateUrl: './fs-apps-filed.component.html',
  styleUrls: ['./fs-apps-filed.component.css']
})

export class FsAppsFiledComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  
  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/bpe/fsme/questionary-100007?type=100007'] != null && ref.accessMap['/bpe/fsme/questionary-100007?type=100007'].accessMode != 'rw')) {
        this.isNotEditable = true;
    }
  }
}