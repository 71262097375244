import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { SlimLoadingBarModule } from "ng2-slim-loading-bar";
// import { AlertService } from './common/alert.service';
import { AuthService } from "./common/auth.service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent, TimeoutDialogComponent } from "./app.component";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AgMaterialModule } from "./angular-material/ag-matrial.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RootFrameComponent } from "./RootFrames/root-frame.component";
import { HomePageComponent } from "./home-page/home-page.component";
import { TopMenuComponent } from "./RootFrames/top-menu/top-menu.component";
import { BodyComponent } from "./RootFrames/body/body.component";
import {
  NavigationComponent,
  NavigationDialog,
} from "./RootFrames/navigation/navigation.component";
import { TitleStripComponent } from "./RootFrames/title-strip/title-strip.component";
import { NavMenuComponent } from "./RootFrames/nav-menu/nav-menu.component";
import { FooterComponent } from "./RootFrames/footer/footer.component";
import { HeaderOneComponent } from "./RootFrames/header-one/header-one.component";
import { HeaderTwoComponent } from "./RootFrames/header-two/header-two.component";
import { NavigationService } from "./common/navigation.service";
import { NavigationResolver } from "./resolvers/navigation.resolver";
import { ConstantsService } from "./common/constants.service";
import { AppUtilService } from "./common/app.util.service";
import { DataShareService } from "./common/data-share.service";
import { StorageService } from "./common/storage.service";
import { ApiManager } from "./common/api-manager.service";
import { NgxWebstorageModule } from "ngx-webstorage";
import { HighchartsChartModule } from "highcharts-angular";
import { CaseViewComponent } from "./home-page/case-view/case-view.component";
import { HouseholdComponent } from "./components/household/household.component";
import { HouseholdBoarderComponent } from "./components/household-boarder/household-boarder.component";
import { HouseholdGroupHomeComponent } from "./components/household-group-home/household-group-home.component";
import { HouseholdTDAAComponent } from "./components/household-tdaa/household-tdaa.component";
import { BankAccountsComponent } from "./components/bank-accounts/bank-accounts.component";
import { FnsTransmissionComponent } from "./quality-control/fns-transmission/fns-transmission.component";
import { BpeAppModule } from "./bpe-app/bpe-app.module";
import { SharedModuleModule } from "./shared-module/shared-module.module";
import { PrintService } from "./common/print.service";
import { EventEmitterService } from "./common/event.emitter.service";
import { HelpTextService } from "./common/help-text.service";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ConfirmationDialog } from "src/app/shared-module/confirmation-dialog/confirmation-dialog.component";
import { MatGridListModule } from "@angular/material/grid-list";
import {
  GoogleApiModule,
  GoogleApiService,
  GoogleAuthService,
  NgGapiClientConfig,
  NG_GAPI_CONFIG,
  GoogleApiConfig,
} from "ng-gapi";
import { UserService } from "./common/UserService";
import { SheetResource } from "./common/SheetResource";

import { AppOverlayModule } from "./overlay/overlay.module";
import { ProgressSpinnerModule } from "./progress-spinner/progress-spinner.module";
import { AppAuthGuard } from "./app-auth.guard";
import { QualityControlReviewComponent } from "./quality-control-review/quality-control-review.component";
import { NgxSpinnerModule } from "ngx-spinner";

let gapiClientConfig: NgGapiClientConfig = {
  client_id:
    "875137922742-t434gcpnoglhqjat7k77f7t4u6c0e6uq.apps.googleusercontent.com",
  discoveryDocs: [
    "https://analyticsreporting.googleapis.com/$discovery/rest?version=v4",
  ],
  ux_mode: "popup",
  // redirect_uri: "https://ng-gapi-example.stackblitz.io/redirect",
  scope: ["https://www.googleapis.com/auth/spreadsheets"].join(" "),
};

// import { PrimaryErrorComponent } from './components/primary-error/primary-error.component';
// import { SecondaryErrorComponent } from './components/secondary-error/secondary-error.component';

@NgModule({
  declarations: [
    AppComponent,
    RootFrameComponent,
    HomePageComponent,
    TopMenuComponent,
    BodyComponent,
    NavigationComponent,
    TitleStripComponent,
    NavMenuComponent,
    FooterComponent,
    HeaderOneComponent,
    HeaderTwoComponent,
    CaseViewComponent,
    HouseholdComponent,
    HouseholdBoarderComponent,
    HouseholdGroupHomeComponent,
    HouseholdTDAAComponent,
    TimeoutDialogComponent,
    FnsTransmissionComponent,
    NavigationDialog,
    QualityControlReviewComponent,
    ConfirmationDialog,

    // ProgressSpinnerComponent

    // PrimaryErrorComponent,
    // SecondaryErrorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    AgMaterialModule,
    HighchartsChartModule,
    NgxWebstorageModule.forRoot(),
    HttpClientModule,
    NgxSpinnerModule,
    SlimLoadingBarModule.forRoot(),
    BpeAppModule,
    MatGridListModule,
    SharedModuleModule,
    GoogleApiModule.forRoot({
      provide: NG_GAPI_CONFIG,
      useValue: gapiClientConfig,
    }),
    AppOverlayModule,
    ProgressSpinnerModule,
    CurrencyMaskModule,
  ],
  entryComponents: [
    CaseViewComponent,
    HouseholdComponent,
    HouseholdBoarderComponent,
    HouseholdGroupHomeComponent,
    HouseholdTDAAComponent,
    TimeoutDialogComponent,
    // ProgressSpinnerComponent,
    FnsTransmissionComponent,
    NavigationDialog,
    ConfirmationDialog,
  ],
  providers: [
    ConstantsService,
    NavigationService,
    NavigationResolver,
    AppUtilService,
    // AlertService,
    DataShareService,
    StorageService,
    ApiManager,
    AuthService,
    // PrintService,
    EventEmitterService,
    HelpTextService,
    GoogleApiModule,
    UserService,
    SheetResource,
    AppAuthGuard,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
