import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-depe',
  templateUrl: './depe.component.html',
  styleUrls: ['./depe.component.css']
})
export class DepeComponent  extends BaseComponent implements OnInit {
  currentPage: number = 0;

  isNotEditable: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }


}
