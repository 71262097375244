import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../shared-module/baseComponent.component';


@Component({
  selector: 'app-national-new-hire',
  templateUrl: './national-new-hire.component.html',
  styleUrls: ['./national-new-hire.component.css']
})
export class NationalNewHireComponent extends BaseComponent implements OnInit {
  
  ngOnInit() {
    super.ngOnInit();
  }

}
