import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-non-cooperation-with-earequirements',
  templateUrl: './non-cooperation-with-earequirements.component.html',
  styleUrls: ['./non-cooperation-with-earequirements.component.css']
})
export class NonCooperationWithEArequirementsComponent extends BaseComponent implements OnInit {
 ngOnInit() {
    super.ngOnInit();
  }

}



