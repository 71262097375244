import { NgModule } from '@angular/core';
// import { Observable } from 'rxjs/Observable ';
import { Routes, RouterModule } from '@angular/router';
import { QualityControlModule } from './quality-control/quality-control.module';
import { CommonModule } from '@angular/common';
import { RootFrameComponent } from './RootFrames/root-frame.component';
import { HomePageComponent } from './home-page/home-page.component';
import { NavigationResolver } from './resolvers/navigation.resolver';
import { BpeDashboardComponent } from './bpe-app/bpe-dashboard/bpe-dashboard.component';
import { AppAuthGuard } from './app-auth.guard';


const routes: Routes = [
  // { path: '', redirectTo: 'qualityControl' },
  {
    path: 'bpe',
    component: RootFrameComponent,
    children: [
      {
        path: 'fsme',
        loadChildren: () => import('./bpe-app/fsme/fsme.module').then(mod => mod.FsmeModule),
        resolve: { prog: NavigationResolver}
      },
      {
        path: 'payment-accuracy',
        loadChildren: () => import('./bpe-app/payment-accuracy/payment-accuracy.module').then(mod => mod.PaymentAccuracyModule),
        resolve: { prog: NavigationResolver}
      },
      {
        path: 'wpr',
        loadChildren: () => import('./bpe-app/wpr/wpr.module').then(mod => mod.WprModule),
        resolve: { prog: NavigationResolver}
      },
      {
        path: 'monitoring',
        loadChildren: () => import('./bpe-app/monitoring/monitoring.module').then(mod => mod.MonitoringModule),
        resolve: { prog: NavigationResolver}
      },
      {
      path: '',
      component: BpeDashboardComponent,
      resolve: { prog: NavigationResolver}
    },
    ]
  },
  {
    path: 'QC',
    component: RootFrameComponent,
    children: [
      {
        path: 'homePage',
        component: HomePageComponent,
        loadChildren: () => import('./shared-module/shared-module.module').then(mod => mod.SharedModuleModule),
        // redirectTo: 'dashboard',
        pathMatch: 'full',
        resolve: { prog: NavigationResolver},
        data: { preload: true , delayTime: 1000  }
      },
      {
        path: 'qualityControl',
        loadChildren: () => import('./quality-control/quality-control.module').then(mod => mod.QualityControlModule),
        resolve: { prog: NavigationResolver}
      },
      {
        path: '**',
        component: HomePageComponent,
        resolve: { prog: NavigationResolver }
      },
    ]
  },
  {
    path: '',
    component: RootFrameComponent,
    children: [
      {
        path: 'homePage',
        component: HomePageComponent,
        loadChildren: () => import('./shared-module/shared-module.module').then(mod => mod.SharedModuleModule),
        // redirectTo: 'dashboard',
        pathMatch: 'full',
        resolve: { prog: NavigationResolver},
        data: { preload: true , delayTime: 1000  }
      },
      {
        path: 'qualityControl',
        canActivate: [AppAuthGuard],
        loadChildren: () => import('./quality-control/quality-control.module').then(mod => mod.QualityControlModule),
        resolve: { prog: NavigationResolver}
      },
  
      {
        path: '**',
        component: HomePageComponent,
        canActivate: [AppAuthGuard],
        resolve: { prog: NavigationResolver }
      },
    ]
  },
  {
    path: '**',
    component: RootFrameComponent,
    children: [
      {
        path: '**',
        component: HomePageComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

