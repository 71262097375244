import { Component, OnInit } from '@angular/core';
import { CustomValidatorsService } from 'src/app/common/custom-validators.service';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-qc37-employment-verification-letter',
  templateUrl: './qc37-employment-verification-letter.component.html',
  styleUrls: ['./qc37-employment-verification-letter.component.css']
})
export class QC37EmploymentVerificationLetterComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

  handleSSN(individualName){

    let householdMembers = this.constantsService.householdMembers;
    householdMembers.map(indivId =>{
    if(this.utilService.getFullName(indivId)===individualName){
      let ssnMask = CustomValidatorsService.patchSSN(indivId.ssn) 
      this.setControlValue('500111.14',ssnMask );
      this.setControlValue('500111.15',indivId.address1+', '+indivId.address2);

    }
  });
}

convertSpanishPDF() {
  this.callGenerateSpanishPDF.emit(undefined);
}
}