import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {

  
  displayedColumns: string[] = [
    'element_num',
    'bookmarkname',
    'tmplt_name'
    ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<any>) { }

  ngOnInit() {
  }

  close(val){
    this.data = val;
    this.dialogRef.close(this.data);
  }
}
