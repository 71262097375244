import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-validate-calc-sheet-dialog',
  templateUrl: './validate-calc-sheet-dialog.component.html',
  styleUrls: ['./validate-calc-sheet-dialog.component.css']
})
export class ValidateCalcSheetDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<any>) { }

  ngOnInit() {
  }

  close(val){
    this.data = val;
    this.dialogRef.close(this.data);
  }
}
