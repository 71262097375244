import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiManager } from 'src/app/common/api-manager.service';
import { AppUtilService } from 'src/app/common/app.util.service';
import { Location } from "@angular/common";
import { ConstantsService } from 'src/app/common/constants.service';
import { Moment } from 'moment';
import moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-fsp-review-log',
  templateUrl: './fsp-review-log.component.html',
  styleUrls: ['./fsp-review-log.component.css']
})
export class FspReviewLogComponent implements OnInit {
  qcRvwProcessLogTcaForm: FormGroup;
  public isNotEditable:boolean = false
  minDate: Moment;
  maxDate: Moment;
  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public appUtilService: AppUtilService,
    public constantsService: ConstantsService,
    private apiManager: ApiManager,
    public _location: Location,
  ) {
    this.buildForm();
    this.getRvwData();
   }

  ngOnInit(): void {
    this.minDate = moment().subtract(2, 'days');
    this.maxDate = moment();
    const queryParams: any[] = [];
    queryParams.push(this.constantsService.rvwlog_rvwid);
    this.apiManager.fetchData('getCaseReviewSearch', undefined, ApiManager.GET,
      undefined, undefined, undefined, queryParams).subscribe(
        res => {
          if (res.length > 0) {
            this.constantsService.ReviewInfo = res[0];
          }

        },
        error => {
          console.log('look up API call failure: Error');
        }
      );
  }
  buildForm() {
    this.qcRvwProcessLogTcaForm = this.fb.group({
      qaPrcLogId:[''],
      rvwMo:[''],
      sampleMo:[''],
      dspRvw:[''],
      qaRvwId:[''],
      rvwr:[''],
      sprVsr:[''],
      ldssCd:[''],
      caseId:[''],
      firstNm:[''],
      lastNm:[''],
      asgnDt: [''],
      dueDt:[''],
      // statCd:[''],
      // statDt:[''],
      // statRsn:[''],
      caseRd:[''],
      subApr:[''],
      rtCor:[''],
      resub:[''],
      defNt:[''],
      dtSupNtAn:[''],
      dtRtDef:[''],
      dtDefResb:[''],
      adjPep:[''],
      transStat:[''],
      elmt:[''],
      fndDol:[''],
      disp:[ ''],
      errCs:[''],
      occDt:[''],
      occrdCd:[''],
      pgmType:[''],
      csRvwAsgnmtId:[],


      //New Fields Section 2
      hmVstItrvw:[''],
      qcSent:[''],
      drpCsIndctr:[''],
      nstr:[''],
      drpReopnDt:[''],
      tworetdfrCrrct:[''],
      tworesubfrCrrct:[''],
    

      //New fields section3 
      sprvsrCmnts:[''],
      plcySpclCmnts:[''],
      dtOfpolicySpec:[''],
      rtrndfrPlcyCorrctn:[''],
      retrnDrpReopntoSup:[''],
      resubtoPlcySpclst:[''],
      rtrnDrpRopnfrCorrctn:[''],
      rsubDrpRopnfrPOLSpcApprvl:[],
      dfcyCrrctdbyPlSpc:[''],
      dtPlSpcNtfdSprvsr:[''],
      dtPlSpcRtrnfrDfcny:[''],
      dtspRsbDfcncy:[''],
      dysAdjstmntbyPlSpc:[''],
      sbmtdToQCMngr:[''],
      rtrndfrmQCMngr:[''],
      rsbmttdtoQCMngr:[''],
      dtToTypPstv:[' '],
      rtrnDrpRopntoPlSpc:[''],
      rtrndDrpReopnfrmQCMngrAppr:[''],
      rtrnDrpReopntoTypng:[''],
      directorCmnts:['']






      // noAppRcdFnd:[''],
     
      // status:[''],
     
     
      // type:[''],
      
      // nstr:[''],

      // qc35199Sent:['']
    });
  }

  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }
  getRvwData() {
   const queryParams: any[] = [];
    queryParams.push(this.constantsService.rvwlog_rvwid);
    this.apiManager.fetchData('getFsposproc', undefined, ApiManager.GET,
    undefined, null, null, queryParams)
          .subscribe(res => {
              let formData = res;

              if ( res.screenLvlLookup ) {
                this.constantsService.lookUpValues = res.screenLvlLookup;
              }
              
              this.qcRvwProcessLogTcaForm.patchValue(res);
          });
  }
  save(model) {

    const queryParams: any[] = [];
    const posProcessData = model.value;
    this.apiManager.fetchData('saveFsposproc', undefined, ApiManager.POST,
    posProcessData, null, null, queryParams)
          .subscribe(res => {
            let formData = res;
            this.qcRvwProcessLogTcaForm.patchValue(formData);
            // this.router.navigate([this.constantsService.getNavigateToRvwList()]);
            this.constantsService.ReviewInfo.dropReOpenInd = formData.drpCsIndctr;
            this._location.back();
          });
  }
  back() {
    this._location.back();
   }
}
