import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-bank-account-cash-on-hand',
  templateUrl: './bank-account-cash-on-hand.component.html',
  styleUrls: ['./bank-account-cash-on-hand.component.css']
})

export class BankAccountCashOnHandComponent extends BaseComponent implements OnInit {
  isNotEditable: boolean = false;
  lqdAssetsDisable : boolean = true;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    if(this.group.controls['211.03'].value=='Y') {
      this.lqdAssetsDisable = false;
    } 
  }

  isThereAnyLqdAsstDiff(val){

    if(val) {
      if(val ==='N') {
        this.lqdAssetsDisable = true;
      } else 
      if(val ==='Y') {
        this.lqdAssetsDisable = false;
      } 
    } 
  }
  get Is211_1_2():boolean{
    return this.group.controls['211.1.2'].value === 'N';
      }

      get Is211_1_5():boolean{
        return this.group.controls['211.1.5'].value === 'N';
          }

handleQ211_1_13(val){
      if(val === '99'){
         this.group.controls['211.1.13b'].enable();
      }else{
         this.group.controls['211.1.13b'].disable();
      }
  }
}
