export class AllDocumentSelectionInfo {
    constructor(
      public docId: string,
      public docName: string,
      public docSelectedFlag: boolean,
      public documentTypeCd: string,
      public createdBy: string,
      public createdDt: string,
      public updatedBy: string,
      public updatedDt: string,
      public individualName: string,
      public docCatagory: string,
      public docDesc: string,
      public individualId: number,
      public ownerRepo: any,
      public ownerSystem: any
    ) { 

    }
  }

export class DocumentSelectionInfo {
    constructor(
     public docId: string,
     public docName: string,
     public docSelectedFlag: boolean,
     public documentTypeCd: string,
     public createdBy: string,
     public createdDt: string,
     public updatedBy: string,
     public updatedDt: string
    ) { }
   }
   
export class DocumentUploadInfo {
    constructor(
        public docName: string,
        public documentTypeCd: string,
        public file: HTMLInputEvent
       ) { }
}

interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
  }