import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-calcsheet-calcSheetfns',
  templateUrl: './calcsheet-calcSheetfns.component.html',
  styleUrls: ['./calcsheet-calcSheetfns.component.css']
})
export class CalcSheetfnsComponent  extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;
  selectedTab = 'C';

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();

  }

  eventForTabchange(val){
  
     if (val.tab.textLabel==="Current") {
       this.selectedTab = 'C';
     } 
     else if (val.tab.textLabel==="Previous") {
       this.selectedTab = 'P';
         
   }
 }


  
}