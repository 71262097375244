import { ChangeDetectorRef, Component, Inject, EventEmitter, HostListener } from '@angular/core';
import { Event as RouterEvent, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, ActivationEnd } from '@angular/router';
import { ApiManager } from './common/api-manager.service';
import { ConstantsService } from './common/constants.service';
import { StorageService } from './common/storage.service';
import { environment } from 'src/environments/environment';
// import { EventEmitterService } from './common/event.emitter.service';
import { interval as observableInterval, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { AppUtilService } from './common/app.util.service';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { DataShareService } from './common/data-share.service';
import { HelpTextService } from './common/help-text.service';
import { AuthService } from './common/auth.service';
import { EventEmitterService } from './common/event.emitter.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  private timerSubscription: Subscription;
  public timer: any;
  public timeTravelFlag: boolean = false;
  private applicationDate: any = new Date();


  title = 'quality-control';

  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  displayProgressSpinner = false;
  


  public constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    public constantsService: ConstantsService,
    private apiManager: ApiManager,
    private consts: ConstantsService,
    private storage: StorageService,
    private emitter: EventEmitterService,
    private utilService: AppUtilService,
    public dialog: MatDialog,
    private overlay: Overlay,
    private authService: AuthService,
    private spinnerService: NgxSpinnerService,
  ) {


    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
    this.getUserDetails();
    this.getRegionDistrictAnalyst();

  }

  showProgressSpinner = () => {
    this.displayProgressSpinner = true;
    setTimeout(() => {
      this.displayProgressSpinner = false;
    }, 3000);
  }


  toggleTimeTravel(e) {
    this.storage.allowTimeTravel = this.timeTravelFlag;
    if (!this.storage.allowTimeTravel) {
      this.applicationDate = new Date();
      window["systemDate"] = this.applicationDate;
      this.utilService.setCurrentDate(this.applicationDate);
      this.router.navigate(['../../homeScreen']);
    }
  }

  ngOnInit() {
    console.log(environment.envName);
    this.startTimer(25, false);
    this.emitter.subscribe('timer', (data) => {
      this.startTimer(25, true);
    });
    console.log(this.storage.getTimeTravelDate(), new Date());
  }
  presumeTimer(time, byPass?) {
    var seconds;
    let sec;
    let min;
    let minutes;
    let storageTime = this.storage.getTimer();
    let presumeTime: boolean = true;
    if (storageTime != undefined && !byPass) {
      console.log(this.timerSubscription);
      minutes = storageTime.min;
      presumeTime = false;
      seconds = storageTime.sec + storageTime.min * 60;
      this.timer = minutes + ':' + ((storageTime.sec == 0) ? '00' : storageTime.sec);
    }
    else {
      minutes = time;
      seconds = time * 60;
      this.timer = minutes + ':00';
      presumeTime = false;
      this.storage.setTimer({ 'min': minutes, 'sec': seconds });
    }
    if (presumeTime) {
      var interval = setInterval(() => {
        seconds = seconds - 1;
        min = Math.floor(seconds / 60);
        sec = seconds - min * 60;
        if (min < 9 && !this.storage.getModalOpen()) {
          console.log('Open popup');
          this.storage.setModalOpen(true);
          // document.getElementById('openTimeOutModal').click();
          this.openTimeoutDialogDialog();
          clearInterval(interval);
          // this.timerSubscription.unsubscribe();
        }
      }, 1000);
    }
  }

  startTimer(time, byPass?) {
    console.log('Time' + time);
    console.log('ByPasss' + byPass);
    let minutes;
    let seconds;
    let sec;
    let min;
    let action = true;
    let storageTime = this.storage.getTimer();
    if (storageTime != undefined && !byPass) {
      if (this.timerSubscription != undefined) {
        this.timerSubscription.unsubscribe();
      }
      console.log(this.timerSubscription);
      minutes = storageTime.min;
      seconds = storageTime.sec + storageTime.min * 60;
      this.timer = minutes + ':' + ((storageTime.sec == 0) ? '00' : storageTime.sec);
    }
    else {
      if (this.timerSubscription != undefined) {
        this.timerSubscription.unsubscribe();
      }
      minutes = time;
      seconds = time * 60;
      this.timer = minutes + ':00';
      this.storage.setTimer({ 'min': minutes, 'sec': seconds });
    }
    console.log('Timer start' + this.timer);
    console.log('action:' + action);
    this.storage.setModalOpen(false);
    minutes = time;
    seconds = time * 60;
    this.storage.setTimer({ 'min': minutes, 'sec': seconds });
    this.timerSubscription = observableInterval(1000).pipe(
      takeWhile(() => action))
      .subscribe(i => {
        if (this.storage.getTimerExtend()) {
          minutes = time;
          seconds = time * 60;
          this.storage.setTimerExtend(false);
        }
        var x = time;
        seconds = seconds - 1;
        min = Math.floor(seconds / 60);
        sec = seconds - min * 60;
        if (min < 5 && !this.storage.getModalOpen()) {
          console.log('Open popup');
          this.storage.setModalOpen(true);
          // document.getElementById('openTimeOutModal').click();
          this.timerSubscription.unsubscribe();
          this.openTimeoutDialogDialog();
        }
        sec = (sec.toString().length == 1) ? '0' + sec : sec;
        this.timer = min + ':' + ((sec == 0) ? '00' : sec);
        this.storage.setTimer({ 'min': min, 'sec': sec });
      })
  }

  @HostListener('click') onClick() {
    this.storage.setTimerExtend(true);
    environment.timeOut = 10;
  }

  extendTime() {
    this.apiManager.fetchData('accessSecurity', undefined,
      ApiManager.GET, undefined, undefined,
      undefined, undefined).subscribe(
        res => {
          this.storage.setModalOpen(false);
          this.storage.setTimerExtend(true);
          environment.timeOut = 10;
        }
      );
  }

  openTimeoutDialogDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    // dialogConfig.autoFocus = true;
    dialogConfig.hasBackdrop = true;
    const dialogRef = this.dialog.open(TimeoutDialogComponent, dialogConfig);
    dialogRef.backdropClick().subscribe(() => {
      dialogRef.close();
      this.extendTime();
    })
    // dialogRef.updateSize("80%", "80%");
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        this.extendTime();
      }
    });
  }

  getUserDetails() {
    this.apiManager.fetchData('accessSecurity', undefined,
      ApiManager.GET, undefined, undefined,
      undefined, undefined).toPromise()
      .then(res => {

        console.log(res);
        if (res) {
          this.consts.userData = res;
          let checkAccess = (res.user.roles.find((x: string) => x.includes('EE_QC_')));
          if (checkAccess) {
            this.storage.setAccessSecurity(res);
            this.consts.setSecurityObject(res);
          }
          else
            this.authService.clientLogout();
        }

      }).catch(err => {
        location.reload();
        console.log(err);
      });
  }

  getRegionDistrictAnalyst() {
    this.apiManager.fetchData('getRegionDistricAnalystLookup', undefined,
      ApiManager.GET, undefined, undefined,
      undefined, undefined).toPromise()
      .then(res => {
        console.log(res);
        this.consts.regionDistrictAnalystLookup = res;
      }).catch(err => {
        location.reload();
        console.log(err);
      });
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.displayProgressSpinner = true;
      this.spinnerService.show();

      this.utilService.showLoader();
    }
    if (event instanceof NavigationEnd) {
      this.displayProgressSpinner = false;
      this.spinnerService.hide();
      this.utilService.hideLoader();
    }
    if (event instanceof ActivationEnd) {
      this.displayProgressSpinner = false;
        
      this.utilService.hideLoader();
    }
    if (event instanceof NavigationCancel) {
      this.displayProgressSpinner = false;
      this.spinnerService.hide();
      this.utilService.hideLoader();
    }
    if (event instanceof NavigationError) {
      this.displayProgressSpinner = false;
      this.spinnerService.hide();
      this.utilService.hideLoader();
    }
    this.cdr.detectChanges();
  }


}

@Component({
  selector: 'timeout-dialog',
  templateUrl: 'timeout-dialog.html',
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})

export class TimeoutDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<TimeoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    public constantsService: ConstantsService,
    private appUtil: AppUtilService,
    private _utilService: AppUtilService,
    private fb: FormBuilder,
    private shareService: DataShareService,
    private helpTextService: HelpTextService,
    private apiManager: ApiManager,
    private storage: StorageService,
    public dialog: MatDialog,
    public emitter: EventEmitterService,
    private authService: AuthService) {
  }
  private timerSubscription: Subscription;
  public timer: any;
  onAdd = new EventEmitter();

  ngOnInit() {
    this.startTimer(10);
  }
  startTimer(time, byPass?) {
    console.log('Time' + time);
    console.log('ByPasss' + byPass);
    let minutes;
    let seconds;
    let sec;
    let min;
    let action = true;
    let storageTime = this.storage.getTimer();
    minutes = time;
    seconds = time * 60;
    this.timer = minutes + ':00';
    this.storage.setTimer({ 'min': minutes, 'sec': seconds });
    console.log('Timer start' + this.timer);
    console.log('action:' + action);
    this.timerSubscription = observableInterval(1000).pipe(
      takeWhile(() => action))
      .subscribe(i => {
        seconds = seconds - 1;
        min = Math.floor(seconds / 60);
        sec = seconds - min * 60;
        sec = (sec.toString().length == 1) ? '0' + sec : sec;
        this.timer = min + ':' + ((sec == 0) ? '00' : sec);
        this.storage.setTimer({ 'min': min, 'sec': sec });
        if (min == 0 && sec == 0) {
          this.storage.destroyAll();
          this.timerSubscription.unsubscribe();
          this.dialogRef.close(false);
          this.authService.wpSignOn();
        }
      })
    // var interval = setInterval(() => {
    //   seconds = seconds - 1;
    //   min = Math.floor(seconds / 60);
    //   sec = seconds - min * 60;
    //   sec = (sec.toString().length == 1) ? '0' + sec : sec;
    //   this.timer = min + ':' + ((sec == 0) ? '00' : sec);
    //   this.storage.setTimer({ 'min': min, 'sec': sec });
    //   if (min == 0 && sec == 0) {
    //     clearInterval(interval);
    //     this.storage.destroyAll();
    //     this.dialogRef.close(false);
    //     this.authService.clientLogout();
    //   }
    // }, 1000);
    // this.timerSubscription = observableInterval(1000).pipe(
    //   takeWhile(() => action))
    //   .subscribe(i => {
    //     seconds = seconds - 1;
    //     min = Math.floor(seconds / 60);
    //     sec = seconds - min * 60;
    //     // if (min < 9) {
    //     //   console.log('Open popup');
    //     //   this.storage.setModalOpen(true);
    //     // }
    //     if (min == 0 && sec == 0) {
    //       action = false;
    //       console.log('close popup')
    //       this.storage.destroyAll();
    //       this.dialogRef.close(false);
    //       this.authService.clientLogout();
    //     }

    //     sec = (sec.toString().length == 1) ? '0' + sec : sec;
    //     this.timer = min + ':' + ((sec == 0) ? '00' : sec);
    //     this.storage.setTimer({ 'min': min, 'sec': sec });
    //   })
  }

  ngOnDestroy() {
    this.timerSubscription.unsubscribe();
  }

  extendTime() {
    this.dialogRef.close(true);
    this.apiManager.fetchData('accessSecurity', undefined, ApiManager.GET, undefined, undefined, undefined, undefined).subscribe(res => {
      this.storage.setModalOpen(false);
      this.storage.setTimerExtend(true);
      environment.timeOut = 10;
    });
  }

}