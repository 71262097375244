import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FieldConfig } from "../../components/field.interface";
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';
import { NavigationService } from '../../common/navigation.service';
@Component({
  selector: "app-date",
  template: `
  <div class="row" [ngClass]="{'alt-strips': oddEven}">
 
  <div *ngIf="showQDesc" class="col-sm-8">
    <mat-label>{{field.qDesc}}</mat-label>
  </div>


<div class="col-sm-4">
<mat-form-field class="fullWidth" appearance="outline" [formGroup]="group">
<input matInput [matDatepicker]="picker" [formControlName]="field.name" placeholder="MM/DD/YYYY">
<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
<mat-datepicker #picker></mat-datepicker>
<mat-hint></mat-hint>
<ng-container *ngFor="let validation of field.validations;" ngProjectAs="mat-error">
<!--<mat-error *ngIf="group.get(field.name).hasError(validation.name)">{{validation.message}}</mat-error>-->
</ng-container>
</mat-form-field>
</div>
</div>
`,
  styles: []
})
export class DateComponent implements OnInit {
  field: FieldConfig;
  group: FormGroup;
  showQCd: boolean = true;
  showQDesc: boolean = true;
  constructor(
    public constantsService: ConstantsService,
    public navigationService: NavigationService,
  ) { }
  // ngOnInit() {}
  ngOnInit() {
    // super.ngOnInit();
  }

  get oddEven() {

    const reminder = parseInt(this.field.name.split('.')[this.field.name.split('.').length - 1]) % 2;
    if (reminder === 0 && this.field.qmode === 'OP'
      && this.navigationService.appCd !== 'QC') {
      return true;
    } else if (reminder === Math.round(reminder)) {
      return false;
    }
  }
}
