import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-contribution-cleaning-supplies',
  templateUrl: './contribution-cleaning-supplies.component.html',
  styleUrls: ['./contribution-cleaning-supplies.component.css']
})
export class ContributionCleaningSuppliesComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;
    ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

}

