import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-nt-elg-to-rec-msnap',
  templateUrl: './nt-elg-to-rec-msnap.component.html',
  styleUrls: ['./nt-elg-to-rec-msnap.component.css']
})
export class NtElgToRecMSNAPComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
