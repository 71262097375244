import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-simplified-reporting',
  templateUrl: './simplified-reporting.component.html',
  styleUrls: ['./simplified-reporting.component.css']
})
export class SimplifiedReportingComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }
}
