import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-general-assistance',
  templateUrl: './general-assistance.component.html',
  styleUrls: ['./general-assistance.component.css']
})
export class GeneralAssistanceComponent  extends BaseComponent implements OnInit {


isNotEditable : boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.changeQ344_01Qs(this.getControlValue('344.01'));
    this.changeQ344_1(this.getControlValue('344.1'));
    // this.changeQ344_01();
  }

  // changeQ344_01(){
  //   this.setControlValue('344.01','N');
  //   this.group.controls['344.02'].disable();
  //   this.group.controls['344.03'].disable();
  // }
  
  changeQ344_1(val){
    if(val === "N"){
      this.setControlValue('344.2','');
      this.group.controls['344.2'].disable();
    }else if(val === "Y"){
      this.group.controls['344.2'].enable();
    }
  }

  changeQ344_01Qs(val){
    if(val === "N"){
      this.group.controls['344.02'].disable();
      this.group.controls['344.03'].disable();
    }else if(val === "Y"){
      this.group.controls['344.02'].enable();
      this.group.controls['344.03'].enable();
    }
  }
}
