
import { Component, OnInit, Input, Output, ChangeDetectorRef, HostListener, EventEmitter } from '@angular/core';
// import { DataShareService } from '../../common/data-share.service';
import { ConstantsService } from '../../common/constants.service';
// import { AppUtilService } from '../../common/app.util.service';
// import { Router } from '@angular/router';
// import { ApiManager } from "../../common/api-manager.service";

@Component({
  selector: 'app-title-strip',
  templateUrl: './title-strip.component.html',
  styleUrls: ['./title-strip.component.css']
})

export class TitleStripComponent implements OnInit {
  @Output() resizeNavChange = new EventEmitter<boolean>();
  @Input() checked: boolean;
  // firstName: string = '';
  // lastName: string = '';
  details: any[] = [];
  constructor(
    // private apiManager: ApiManager,
    // private cdRef: ChangeDetectorRef,
    // private shareService: DataShareService,
    public constantsService: ConstantsService,
    // public appUtilService: AppUtilService,
    // private router: Router
  ) { }

  ngOnInit() {
    //this.getHouseholdDetails();
  }

  ngAfterViewChecked() {
    // this.cdRef.detectChanges();
  }

  showTitleStrip() {
    // if (this.shareService.getDataFromMap('currentModule') === "SETTINGS_MODULE") {
    //   ConstantsService.headerTitle = "System Settings";
    //   return false;
    // }
    // if (this.shareService.getDataFromMap('currentModule') === this.constantsService.getText('screeningModule')) {
    //   if ((this.router.url === '/dashboard/screening/programSelection') ||
    //     (this.router.url === '/dashboard/screening/customerRegistrationV1') ||
    //     (this.router.url === '/dashboard/screening/clearanceSearchComponent') ||
    //     (this.router.url == '/dashboard/screening/initiateScreening') ||
    //     ((this.router.url === '/dashboard/screening/customerRegisterationAddHouseholdMembersComponent') && ConstantsService.humanServiceModel.benefitsCase.householdMembers.length == 0)) {
    //     return false;
    //   }
    //   else if ((this.router.url === '/dashboard/screening/contactInformationV1') ||
    //     (this.router.url === '/dashboard/screening/caseDocuments') ||
    //     (this.router.url === '/dashboard/screening/fileScan') ||
    //     (this.router.url === '/dashboard/screening/authRepresentative') ||
    //     (this.router.url === '/dashboard/screening/screeningDisposition') ||
    //     ((this.router.url === '/dashboard/screening/customerRegisterationAddHouseholdMembersComponent') && ConstantsService.humanServiceModel.benefitsCase.householdMembers.length > 0)) {
    //     return true;
    //   }

    // }
    // else if ((this.shareService.getDataFromMap('currentModule') === this.constantsService.getText('caseNarrativeModule'))) {
    //   return (this.router.url !== '/dashboard/caseNarrative/initiateNarrative')
    // }
    // else {
    //   return true;
    // }
  }

  // getHouseholdDetails() {
  //   let queryParams: any[] = [];
  //   queryParams.push(ConstantsService.humanServiceModel.benefitsCase.caseId);
  //   this.apiManager.fetchData('getListOfHousehold', undefined, ApiManager.GET,
  //     undefined, undefined, undefined, queryParams)
  //     .subscribe(res => {
  //       ConstantsService.humanServiceModelWithDOB.benefitsCase.householdMembers= res.householdMemberDetailsList;
  //       this.details = res.householdMemberDetailsList;
  //       this.details && this.details.map(hhm => {
  //         if (hhm.headOfHouseholdYN == 'Y') {
  //           this.firstName = hhm.personName.firstName;
  //           this.lastName = hhm.personName.lastName;
  //         }
  //       })
  //     },
  //       error => {
  //         console.log('get household call failure')
  //       });
  // }

  resizeNavBar() {
    this.checked = !this.checked;
    this.resizeNavChange.emit(this.checked);
  }
}
