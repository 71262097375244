import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-noncooperation-with-qc-requirements',
  templateUrl: './noncooperation-with-qc-requirements.component.html',
  styleUrls: ['./noncooperation-with-qc-requirements.component.css']
})
export class NoncooperationWithQcRequirementsComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
