import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {ApiManager} from "../common/api-manager.service";
// import {Observable} from "rxjs";
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import {tap, map} from 'rxjs/operators';

import {NavigationService} from "../common/navigation.service";
import * as _ from "underscore";
import { ConstantsService } from '../common/constants.service';

@Injectable()
export class NavigationResolver implements Resolve<any>{

  constructor(
    // private intakeService: IntakeService,
    public navigationService: NavigationService,
    public constantsService: ConstantsService,
    private apiManager: ApiManager){

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    let appCd = '';
    
    if ( state.url.includes('/bpe/fsme')) {
      appCd = 'BPEFSME';
    } else if ( state.url.includes('/bpe/payment-accuracy')) {
      appCd = 'BPEPA';
    } else if ( state.url.includes('/bpe/wpr')) {
      appCd = 'BPEWPR';
    } else if ( state.url.includes('/bpe/monitoring')) {
      appCd = 'BPEMONITOR';
    } else if ( state.url.includes('/bpe')) {
      appCd = 'BPE';
    } else {
      appCd = 'QC';
    }

    if ( this.navigationService.appCd !== appCd ) {
      this.constantsService.ReviewInfo = null;
    }
    

    this.navigationService.appCd = appCd;
    this.constantsService.appCd = appCd;

    // return this.getPrograms().map(([ programs]) => ({ programs}));
    return this.navigationService.navDBCall(appCd).then(val => {
      if (this.constantsService.ReviewInfo && this.constantsService.ReviewInfo.rvwType ==='FSPNegative' 
          && this.constantsService.caseHomeData
          && !(this.constantsService.caseHomeData.stReasonCd === '301' 
          || this.constantsService.caseHomeData.stReasonCd === '320'
          || this.constantsService.caseHomeData.stReasonCd === '000') ){
            val = val.filter(v => v.prnt_desc !== 'parentCalcSheet' );
          }
      this.navigationService.setNavHead(val.filter(v => { return v.is_parent == true; }));
      this.navigationService.setNavItems(val);
    }).catch(err =>{
      console.log(err);
    });

  }

 

  
}
