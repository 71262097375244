import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';



@Component({
  selector: 'app-va-benefits',
  templateUrl: './va-benefits.component.html',
  styleUrls: ['./va-benefits.component.css']
})
export class VaBenefitsComponent  extends BaseComponent implements OnInit {
isNotEditable: boolean =false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  get Is332_1(): boolean {
    return this.group.controls['332.1'].value === 'N';
  }
  get Is332_6(): boolean {
    return this.group.controls['332.6'].value === 'N';
  }
  get Is332_11(): boolean {
    return this.group.controls['332.11'].value === 'N';
  }
  
  changeof332_4(event: any) {
    if ( event.value === 'N') {
      this.group.controls['332.7'].disable();
      this.group.controls['332.8'].disable();
      this.group.controls['332.10'].disable();
      this.group.controls['332.14a'].disable();
      this.group.controls['332.14b'].disable();
      this.group.controls['332.14c'].disable();
      this.group.controls['332.14d'].disable();
      
    
      } else {
       
        this.group.controls['332.7'].enable();
      this.group.controls['332.8'].enable();
      this.group.controls['332.10'].enable();
      this.group.controls['332.14a'].enable();
      this.group.controls['332.14b'].enable();
      this.group.controls['332.14c'].enable();
      this.group.controls['332.14d'].enable();

      }
  }
  get Is332_4(): boolean {
    return this.group.controls['332.4'].value === 'N';
  }


  changeof332_6(event: any){
    if ( event.value === 'N') {
      this.group.controls['332.7'].disable();
      this.group.controls['332.8'].disable();
      this.group.controls['332.9'].disable();
      this.group.controls['332.10'].disable();
      this.group.controls['332.11'].disable();
      this.group.controls['332.12'].disable();
      this.group.controls['332.14a'].disable();
      this.group.controls['332.14b'].disable();
      this.group.controls['332.14c'].disable();
      this.group.controls['332.14d'].disable();
    }else{
      this.group.controls['332.7'].enable();
      this.group.controls['332.8'].enable();
      this.group.controls['332.9'].enable();
      this.group.controls['332.10'].enable();
      this.group.controls['332.11'].enable();
      this.group.controls['332.12'].enable();
      this.group.controls['332.14a'].enable();
      this.group.controls['332.14b'].enable();
      this.group.controls['332.14c'].enable();
      this.group.controls['332.14d'].enable();
    }
  }
  changeof332_11(event: any){
    if ( event.value === 'N') {
      this.group.controls['332.12'].disable();
    }else{
      this.group.controls['332.12'].enable();
    }
  }
}