import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { NavigationService } from 'src/app/common/navigation.service';
import { ApiManager } from 'src/app/common/api-manager.service';
import { ConstantsService } from 'src/app/common/constants.service';

export interface PeriodicElement {
  bnftMnth: any;
  rvwNum: number;
  ldssDoNm: number;
  caseId: number;
  pgmBgnDt:number;
  pgmEndDt:number;
  issncStsDt:number;
  issnTypCd:number;
  grntBnftAmt:number;
  // pgmCd: string;
  aidCd:string;
  csStsCd:string;
  reviewStatus:string;
  pgmType:string;

}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-pa-sample',
  templateUrl: './pa-sample.component.html',
  styleUrls: ['./pa-sample.component.css']
})

export class PaSampleComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  snapSampleForm: FormGroup;
  isNotEditable: boolean = false;
  monthYear : string;

  buildForm() {
    this.snapSampleForm = this.fb.group({ 
     })
  };

  displayedColumns: string[] = ['select'
  // , 'rvwNum'
  ,'ldssDoName','caseId','pgmBgnDt','pgmEndDt','issncStsDt','issnTypCd','grntBnftAmt'
  // ,'pgmCd'
  ,'aidCd','csStsCd'
  // ,'reviewStatus'
];
  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  dataSourceAll = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel<PeriodicElement>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() :
    this.dataSource.data.forEach(row => this.selection.select(row));
  }
  

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PeriodicElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.rvwNum + 1}`;
  }

  constructor(
    private readonly router: Router,
    private fb: FormBuilder,
    private apiManager: ApiManager,
    public navigationService: NavigationService,
    public constantsService: ConstantsService
    )
  {
    this.dataSource = new MatTableDataSource();
    this.buildForm();
   }
 
  ngOnInit() {
    this.getSelectSample();
    let date:Date = new Date();
    this.monthYear= date.getFullYear()+""+(date.getMonth()+1<10?"0"+(date.getMonth()+1):date.getMonth()+1);
  }
  @ViewChild('ref', { static: false }) ref: any;

  // masterToggle(_ref: any) {
  //   if(_ref.checked){
  //       this.checkPageOnly();
  //   }
  //   else {
  //       this.selection.clear();
  //   }
  // }

  checkPageOnly() {
      let i = this.dataSource.paginator.pageIndex * this.dataSource.paginator.pageSize;
      let end = (this.dataSource.paginator.pageIndex + 1) * this.dataSource.paginator.pageSize;
      for(i; i < end; i++) {
        this.selection.select(this.dataSource.data[i]);
      }
     console.log('selected...',this.selection.selected)
  }

    // pageChange() {
    //     this.ref.checked = false;
    //     this.masterToggle(this.ref);
    // }

  determineAccess() {
      const ref = this.constantsService.getSecurityObject();

      if ((ref.accessMap[''] != null && ref.accessMap[''].accessMode != 'rw')) {
          this.isNotEditable = true;
      }
  }

  handleCurrentMonth(val) {
    if (val.tab.textLabel==="Current") {
     this.dataSource.data =this.dataSourceAll.data.filter(item => item.bnftMnth === this.monthYear);
    } 
    else if (val.tab.textLabel==="History") {
     this.dataSource.data = this.dataSourceAll.data;
   }
 }

  getSelectSample() {
    const queryParams: any[] = [];
    queryParams.push('BPE-PA');
    this.apiManager.fetchData('getSelectSample', undefined, ApiManager.GET,
        undefined, undefined, undefined, queryParams)
        .subscribe(res => {
          if (res) {
            console.log(res);
            this.dataSourceAll = new MatTableDataSource(res);
            this.dataSourceAll.data = res;
            this.dataSource.data = res.filter(item => item.bnftMnth === this.monthYear);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        });
  }
 
  submit(model) {
    console.log(model);
    const queryParams: any[] = [];
    queryParams.push('BPE-PA');

    const selectedCaseIds: any[] = [];
    this.selection.selected.forEach(row => {
      let id: number = row.caseId;
      let pgmTypeCd: string = row.pgmType;
      selectedCaseIds.push({caseId:id,pgmType:pgmTypeCd});
      });

    this.apiManager.fetchData('saveSelectSample', undefined, ApiManager.POST, selectedCaseIds, undefined, undefined, queryParams)
    .subscribe(
      res => {
      console.log("----Selected sample cases-----");
      this.selection.clear();
    /*   this.casedataSourceDialog.data = res;
      this.casedataSourceDialog.data = this.casedataSourceDialog.data.sort(); */
      this.ngOnInit();
    });  
  }

  back(){
    this.navigationService.navigatePage(this.router.url, 'back');
  }

  next(){
    this.navigationService.navigatePage(this.router.url, 'next');
  }
  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }
  
}