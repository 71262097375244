import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-q16-fsneg-verification-request',
  templateUrl: './q16-fsneg-verification-request.component.html',
  styleUrls: ['./q16-fsneg-verification-request.component.css']
})
export class Q16FSNegVerificationRequestComponent extends BaseComponent implements OnInit {
  
  ngOnInit() {
    super.ngOnInit();
  }

}
