import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-abawd-closures',
  templateUrl: './abawd-closures.component.html',
  styleUrls: ['./abawd-closures.component.css']
})

export class AbawdClosuresComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/bpe/fsme/questionary-100013?type=100013'] != null && ref.accessMap['/bpe/fsme/questionary-100013?type=100013'].accessMode != 'rw')) {
        this.isNotEditable = true;
    }
  }
}