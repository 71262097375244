import { Injectable } from '@angular/core';
import { ConstantsService } from './constants.service';

@Injectable({ providedIn: 'root' })
export class PrintService {
    public printMember: any;
    public printTable: any;
    public printStyle: any;
    public printLink: any;

    print(): void {
        let popupWin;
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
          <html>
            <head>
              <title></title>
              <link rel="stylesheet" href="node_modules/bootstrap/dist/css/bootstrap.css" 'media="screen,print">
              <link rel="stylesheet" href="style.css" media="screen,print">
              ${this.printLink}
              ${this.printStyle}
              <style>
             table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }
    
    td, th {
      border: 2px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
              </style>
            </head>
        <body onload="window.print();window.close()">
        <div class="blog-post" [innerHtml]=" ${this.printMember} ${this.printTable}"></div>
       
        </body>
          </html>`
        );
        popupWin.document.close();
    }

    getTagsHtml(tagName: keyof HTMLElementTagNameMap): string  {
        const htmlStr: string[] = [];
        const elements = document.getElementsByTagName(tagName);
        for (let idx = 0; idx < elements.length; idx++) {
                htmlStr.push(elements[idx].outerHTML);
            }

        return htmlStr.join('\r\n');
    }
}
