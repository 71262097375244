import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { AgMaterialModule } from '../angular-material/ag-matrial.module';


@NgModule({
  imports: [
    CommonModule,
    AgMaterialModule
  ],
  declarations: [
    DialogComponent
  ],
  exports: [
    DialogComponent
  ]
})

export class DialogModule { }