import { Component, OnInit, Inject } from '@angular/core';
import { ApiManager } from 'src/app/common/api-manager.service';
import { saveAs } from 'file-saver/FileSaver';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-qc16-convert-topdf',
  templateUrl: './qc16-convert-topdf.component.html',
  styleUrls: ['./qc16-convert-topdf.component.css']
})

export class Qc16ConvertTopdfComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  
  get currentIndividual() {
    return this.shareService.getDataFromMap('currentIndividual');
  }

  set currentIndividual(id) {
    this.shareService.addDataToMap('currentIndividual', id);
  }
  downloadQ16Form(){
    const queryParams: any[] = [];

    

    if (this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }
    const invId: any = this.currentIndividual;
    if (invId) {
      queryParams.push(invId);
    }  
    this.screenType = "500016";
      queryParams.push(this.screenType); //screen type
      queryParams.push("1"); //buttonId
    if (this.constantsService.ReviewInfo &&
        this.constantsService.ReviewInfo.rvwType) {
          if (!this.screenType.startsWith('5001') ) {
            queryParams.push(this.constantsService.ReviewInfo.rvwType);
          } else {
            queryParams.push('QCForm');
          }
        
      } else {
        queryParams.push('Evaluation');
      }
    let filename: any;
    filename = this.constantsService.getDisplayReviewNum(this.constantsService.ReviewInfo.rvwNum)+"_QC_Review_Narrative_Summary_FNS";
    this.apiManager.fetchData('saveQ16Qs', undefined, ApiManager.POST, undefined, undefined, undefined, queryParams)
     .subscribe(res => {
       if (res) {
        this.field[0].ccId = res.ccId;
        this._snackBar.open('QC16 PDF Generated Successfully.', undefined, {
          duration: 2000,
        });
        // let blob;
        // blob = new Blob([res], { type: 'application/pdf' });
        // saveAs(blob, filename);
       }
       this.callGet.emit(undefined);
     } ,
      error => {
      this._snackBar.open('Save failed.Please save and try again. ', undefined, {
        duration: 2000,
      });
      console.log('look up API call failure: Error');
    }
   );
  }
  get IsView():boolean{
    return this.field[0].ccId === 0;
  }

  viewQ16PositiveForm(){
    const queryParams: any[] = [];
    let ccId = this.field[0].ccId;
    if (ccId != 0) {
      queryParams.push(ccId);
    }
      this.apiManager.fetchData('getDocumentForDownload', undefined, ApiManager.FILEDOWNLOAD, undefined, undefined, undefined, queryParams)
      .subscribe(res => {
        if (res) {
      
       let blob = new Blob([res], { type: res.type });
         let fileURL = URL.createObjectURL(blob);
         var newWin = window.open(fileURL);
        }
      }
    );
  }

  downloadQ16_2Form(){
    const queryParams: any[] = [];

    

    if (this.constantsService.ReviewInfo &&
      this.constantsService.ReviewInfo.rvwNum) {
      queryParams.push(this.constantsService.ReviewInfo.rvwNum);
    }
    const invId: any = this.currentIndividual;
    if (invId) {
      queryParams.push(invId);
    }  
    this.screenType = "500016";
      queryParams.push(this.screenType); //screen type
      queryParams.push("2"); //buttonId
      if (this.constantsService.ReviewInfo &&
        this.constantsService.ReviewInfo.rvwType) {
          if (!this.screenType.startsWith('5001') ) {
            queryParams.push(this.constantsService.ReviewInfo.rvwType);
          } else {
            queryParams.push('QCForm');
          }
        
      } else {
        queryParams.push('Evaluation');
      }

    let filename: any;
    filename = this.constantsService.getDisplayReviewNum(this.constantsService.ReviewInfo.rvwNum)+"_QC_Review_Narrative_Summary_LDSS";
    this.apiManager.fetchData('saveQ16Qs', undefined, ApiManager.POST, undefined, undefined, undefined, queryParams)
     .subscribe(res => {
       if (res) {
        this.field[0].ccId = res.ccId;
        this._snackBar.open('QC16 PDF Generated Successfully.', undefined, {
          duration: 2000,
        });
        // let blob;
        // blob = new Blob([res], { type: 'application/pdf' });
        // saveAs(blob, filename);
        
     }
    
       this.callGet.emit(undefined);
     },
     error => {
       this._snackBar.open('Save failed.Please save and try again. ', undefined, {
         duration: 2000,
       });
       console.log('look up API call failure: Error');
     }
   );
  }
}