import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-dependent-care-deductiontwo',
  templateUrl: './dependent-care-deductiontwo.component.html',
  styleUrls: ['./dependent-care-deductiontwo.component.css']
})
export class DependentCareDeductiontwoComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;
  currentPage: number = 0;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    this.getMemberNameByIndvIdForQ323_2_1();
  }


  get currentIndividual() {
    return this.shareService.getDataFromMap('currentIndividual');
  }

  set currentIndividual(id) {
    this.shareService.addDataToMap('currentIndividual', id);
  }

  getMemberNameByIndvIdForQ323_2_1(){
    this.setControlValue('323.2.1',this.utilService.getMemberNameByIndvId(this.currentIndividual));
  };
 
}