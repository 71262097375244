import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-recertification',
  templateUrl: './recertification.component.html',
  styleUrls: ['./recertification.component.css']
})
export class RecertificationComponent extends BaseComponent implements OnInit {
isNotEditable: boolean = false;
  ngOnInit() {
    this.changeQf70_1();
    super.ngOnInit();
    this.determineAccess();
   
  }

  // Recertification Logic for Analyst
  changeQf70_1(){
    let Q_70_1 = this.getControlValue('70.1')
    if (Q_70_1 === "1"){
      this.group.controls['70.2'].disable();
      this.group.controls['70.3'].disable();
      this.group.controls['70.4'].disable();
      if(this.getfieldInfo('70.5').recordId === 0){
        this.setControlValue('70.5','40');
      }
      this.group.controls['70.6'].disable();
      this.group.controls['70.8'].disable();
      this.group.controls['70.16'].disable();
      this.group.controls['70.9'].disable();
      this.group.controls['70.10'].disable();
      this.group.controls['70.11'].disable();
      this.group.controls['70.12'].disable();
      this.group.controls['70.13'].disable();
      this.group.controls['70.14'].disable();
      this.group.controls['70.15'].disable();
      this.group.controls['70.16'].disable();
      this.group.controls['70.17'].disable();
    
    }
    else {
      this.group.controls['70.2'].enable();
      this.group.controls['70.3'].enable();
      this.group.controls['70.4'].enable();
      if(this.getfieldInfo('70.5').recordId === 0){
        this.setControlValue('70.5','');
      }
      this.group.controls['70.6'].enable();
      this.group.controls['70.8'].enable();
      this.group.controls['70.16'].enable();
      this.group.controls['70.9'].enable();
      this.group.controls['70.10'].enable();
      this.group.controls['70.11'].enable();
      this.group.controls['70.12'].enable();
      this.group.controls['70.13'].enable();
      this.group.controls['70.14'].enable();
      this.group.controls['70.15'].enable();
      this.group.controls['70.16'].enable();
      this.group.controls['70.17'].enable();
     
    } 
  }
  changeQf70_2(val){
    if (val === 'N'){
      this.group.controls['70.6'].disable();
      this.group.controls['70.16'].disable();
      this.group.controls['70.8'].disable();
      this.group.controls['70.9'].disable();
      this.group.controls['70.10'].disable();
      this.group.controls['70.11'].disable();
      this.group.controls['70.12'].disable();
      this.group.controls['70.13'].disable();
      this.group.controls['70.14'].disable();
      this.group.controls['70.15'].disable();
      this.group.controls['70.16'].disable();
      this.group.controls['70.17'].disable();
      this.group.controls['70.3'].disable();
      this.group.controls['70.4'].disable();
      this.setControlValue('70.5','50');
    }
    else {
      this.group.controls['70.6'].enable();
      this.group.controls['70.16'].enable();
      this.group.controls['70.8'].enable();
      this.group.controls['70.9'].enable();
      this.group.controls['70.10'].enable();
      this.group.controls['70.11'].enable();
      this.group.controls['70.12'].enable();
      this.group.controls['70.13'].enable();
      this.group.controls['70.14'].enable();
      this.group.controls['70.15'].enable();
      this.group.controls['70.16'].enable();
      this.group.controls['70.17'].enable();
      this.group.controls['70.3'].enable();
      this.group.controls['70.4'].enable();
      this.setControlValue('70.5','');
    } 
  }

  changeQf70_4(val){
    let Q70_3 = + this.getControlValue('70.3');
    let Q70_4 = + this.getControlValue('70.4');
    if ( Q70_3 >=  Q70_4 ) {
      this.setControlValue('70.5','01');
    
    }
    else {
      this.setControlValue('70.5','11');
    }
  }


  changeQf70_6(val){
    if(val === 'Y') {
      this.group.controls['70.16'].disable();
      this.group.controls['70.8'].disable();
      this.group.controls['70.9'].disable();
      this.group.controls['70.10'].disable();
      this.group.controls['70.11'].disable();
      this.group.controls['70.12'].disable();
      this.group.controls['70.13'].disable();
      this.group.controls['70.14'].disable();
      this.group.controls['70.15'].disable();
      this.group.controls['70.16'].disable();
      this.group.controls['70.17'].disable();
      this.setControlValue('70.5', '30');
    } else {
      this.group.controls['70.16'].enable();
      this.group.controls['70.8'].enable();
      this.group.controls['70.9'].enable();
      this.group.controls['70.10'].enable();
      this.group.controls['70.11'].enable();
      this.group.controls['70.12'].enable();
      this.group.controls['70.13'].enable();
      this.group.controls['70.14'].enable();
      this.group.controls['70.15'].enable();
      this.group.controls['70.16'].enable();
      this.group.controls['70.17'].enable();
      this.setControlValue('70.5', '');
    }
  }

  changeQf70_17(val){
    if(val === 'Y') {
      this.setControlValue('70.5', '01');
    } else {
      this.setControlValue('70.5', '');
    }
  }
  
  

}
