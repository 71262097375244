import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-receipient-integrity',
  templateUrl: './receipient-integrity.component.html',
  styleUrls: ['./receipient-integrity.component.css']
})

export class ReceipientIntegrityComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}