import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-workfare',
  templateUrl: './workfare.component.html',
  styleUrls: ['./workfare.component.css']
})

export class WorkfareComponent extends BaseComponent implements OnInit {
isNotEditable: boolean =false
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }


}