import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-cost-to-produce-expenses',
  templateUrl: './cost-to-produce-expenses.component.html',
  styleUrls: ['./cost-to-produce-expenses.component.css']
})
export class CostToProduceExpensesComponent extends BaseComponent implements OnInit {


  isNotEditable: boolean = false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    const ctrl:any = (this.group.get('FormArray'));
    this.calculate(ctrl.controls[0])
  }

  calculate(arrayControl) {
    const getValue = (controlName) => {
      const value = arrayControl.controls[controlName].value;
      return Number.isNaN(parseInt(value)) ? 0 : parseInt(value);
    };
  
    let totalLDSS =
      getValue('312.9.1a') + getValue('312.9.2a') + getValue('312.9.3a') +
      getValue('312.9.4a') + getValue('312.9.5a') + getValue('312.9.6a') +
      getValue('312.9.7a') + getValue('312.9.8a') + getValue('312.9.9a') +
      getValue('312.9.10a') + getValue('312.9.11a') + getValue('312.9.12a') +
      getValue('312.9.13a') + getValue('312.9.14a') + getValue('312.9.15a') +
      getValue('312.9.16a') + getValue('312.9.17a') + getValue('312.9.18a') +
      getValue('312.9.19a') + getValue('312.9.20b');
  
    let totalInterview =
      getValue('312.9.1b') + getValue('312.9.2b') + getValue('312.9.3b') +
      getValue('312.9.4b') + getValue('312.9.5b') + getValue('312.9.6b') +
      getValue('312.9.7b') + getValue('312.9.8b') + getValue('312.9.9b') +
      getValue('312.9.10b') + getValue('312.9.11b') + getValue('312.9.12b') +
      getValue('312.9.13b') + getValue('312.9.14b') + getValue('312.9.15b') +
      getValue('312.9.16b') + getValue('312.9.17b') + getValue('312.9.18b') +
      getValue('312.9.19b') + getValue('312.9.20c');
  
    let totalSampleMonth =
      getValue('312.9.1c') + getValue('312.9.2c') + getValue('312.9.3c') +
      getValue('312.9.4c') + getValue('312.9.5c') + getValue('312.9.6c') +
      getValue('312.9.7c') + getValue('312.9.8c') + getValue('312.9.9c') +
      getValue('312.9.10c') + getValue('312.9.11c') + getValue('312.9.12c') +
      getValue('312.9.13c') + getValue('312.9.14c') + getValue('312.9.15c') +
      getValue('312.9.16c') + getValue('312.9.17c') + getValue('312.9.18c') +
      getValue('312.9.19c') + getValue('312.9.20d');
  
    let totalCorrectedLDSS =
      getValue('312.9.1d') + getValue('312.9.2d') + getValue('312.9.3d') +
      getValue('312.9.4d') + getValue('312.9.5d') + getValue('312.9.6d') +
      getValue('312.9.7d') + getValue('312.9.8d') + getValue('312.9.9d') +
      getValue('312.9.10d') + getValue('312.9.11d') + getValue('312.9.12d') +
      getValue('312.9.13d') + getValue('312.9.14d') + getValue('312.9.15d') +
      getValue('312.9.16d') + getValue('312.9.17d') + getValue('312.9.18d') +
      getValue('312.9.19d') + getValue('312.9.20e');
  
    let total1stSR =
      getValue('312.9.1e') + getValue('312.9.2e') + getValue('312.9.3e') +
      getValue('312.9.4e') + getValue('312.9.5e') + getValue('312.9.6e') +
      getValue('312.9.7e') + getValue('312.9.8e') + getValue('312.9.9e') +
      getValue('312.9.10e') + getValue('312.9.11e') + getValue('312.9.12e') +
      getValue('312.9.13e') + getValue('312.9.14e') + getValue('312.9.15e') +
      getValue('312.9.16e') + getValue('312.9.17e') + getValue('312.9.18e') +
      getValue('312.9.19e') + getValue('312.9.20f');
  
    let total2ndSR =
      getValue('312.9.1f') + getValue('312.9.2f') + getValue('312.9.3f') +
      getValue('312.9.4f') + getValue('312.9.5f') + getValue('312.9.6f') +
      getValue('312.9.7f') + getValue('312.9.8f') + getValue('312.9.9f') +
      getValue('312.9.10f') + getValue('312.9.11f') + getValue('312.9.12f') +
      getValue('312.9.13f') + getValue('312.9.14f') + getValue('312.9.15f') +
      getValue('312.9.16f') + getValue('312.9.17f') + getValue('312.9.18f') +
      getValue('312.9.19f') + getValue('312.9.20g');
  
    let total3rdSR =
      getValue('312.9.1g') + getValue('312.9.2g') + getValue('312.9.3g') +
      getValue('312.9.4g') + getValue('312.9.5g') + getValue('312.9.6g') +
      getValue('312.9.7g') + getValue('312.9.8g') + getValue('312.9.9g') +
      getValue('312.9.10g') + getValue('312.9.11g') + getValue('312.9.12g') +
      getValue('312.9.13g') + getValue('312.9.14g') + getValue('312.9.15g') +
      getValue('312.9.16g') + getValue('312.9.17g') + getValue('312.9.18g') +
      getValue('312.9.19g') + getValue('312.9.20h');
  
      arrayControl.controls['312.9.21a'].patchValue(totalLDSS);
      arrayControl.controls['312.9.21b'].patchValue(totalInterview);
      arrayControl.controls['312.9.21c'].patchValue(totalSampleMonth);
      arrayControl.controls['312.9.21d'].patchValue(totalCorrectedLDSS);
      arrayControl.controls['312.9.21e'].patchValue(total1stSR);
      arrayControl.controls['312.9.21f'].patchValue(total2ndSR);
      arrayControl.controls['312.9.21g'].patchValue(total3rdSR);
  }
  

  
}
