import { Component, OnInit } from '@angular/core';
import { ConstantsService } from 'src/app/common/constants.service';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-workers-compensation',
  templateUrl: './workers-compensation.component.html',
  styleUrls: ['./workers-compensation.component.css']
})

export class WorkersCompensationComponent  extends BaseComponent implements OnInit {
isNotEditable: boolean =false
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  // determineAccess() {
  //   const ref = this.constantsService.getSecurityObject();

  //   if ((ref.accessMap['/qualityControl/questionary-335?type=335'] != null && ref.accessMap['/qualityControl/questionary-335?type=335'].accessMode != 'rw')) {
  //       this.isNotEditable = true;
  //   }
  // }
  Is335_1_Y:boolean = false;

  changeQf335_1(event: any){
    if(event.value==='Y'){
      this.group.controls['335.2'].disable();
      this.group.controls['335.3'].disable();
      this.group.controls['335.4'].disable();
      this.group.controls['335.5'].disable();
      this.group.controls['335.6'].disable();
      this.group.controls['335.7'].disable();
      this.group.controls['335.8'].disable();
      this.group.controls['335.9'].disable();
      this.group.controls['335.10'].disable();
      this.group.controls['335.11'].disable();
      this.group.controls['335.12'].disable();
      this.group.controls['335.13'].disable();
      this.Is335_1_Y = true;
      
    }
    else{
      this.group.controls['335.2'].enable();
      this.group.controls['335.3'].enable();
      this.group.controls['335.4'].enable();
      this.group.controls['335.5'].enable();
      this.group.controls['335.6'].enable();
      this.group.controls['335.7'].enable();
      this.group.controls['335.8'].enable();
      this.group.controls['335.9'].enable();
      this.group.controls['335.10'].enable();
      this.group.controls['335.11'].enable();
      this.group.controls['335.12'].enable();
      this.group.controls['335.13'].enable();
      this.Is335_1_Y = false;
    }
  }

  changeQf335_2(event: any){
    if(event.value==='N'){
      this.group.controls['335.3'].disable();
      this.group.controls['335.4'].disable();
      this.group.controls['335.5'].disable();
      this.group.controls['335.6'].disable();
    }
    else{
      this.group.controls['335.3'].enable();
      this.group.controls['335.4'].enable();
      this.group.controls['335.5'].enable();
      this.group.controls['335.6'].enable();
    }
  }

  changeQf335_4(event: any){
    if(event.value==='Y'){
      this.group.controls['335.1'].disable();
      this.group.controls['335.2'].disable();
      this.group.controls['335.3'].disable();
      this.group.controls['335.5'].disable();
      this.group.controls['335.7'].disable();
      this.group.controls['335.8'].disable();
      this.group.controls['335.9'].disable();
      this.group.controls['335.10'].disable();
      this.group.controls['335.11'].disable();
      this.group.controls['335.12'].disable();
      this.group.controls['335.13'].disable();
      this.Is335_1_Y = true;
      this.group.controls['335.15'].disable();
      this.group.controls['335.15a'].disable();
      this.group.controls['335.15b'].disable();
      this.group.controls['335.15c'].disable();
      this.group.controls['335.15d'].disable();
      this.group.controls['335.15e'].disable();
      this.group.controls['335.15f'].disable();
      this.group.controls['335.16'].disable();
      this.group.controls['335.17'].disable();
      this.group.controls['335.18'].disable();
      this.group.controls['335.19.1'].disable();
    this.group.controls['335.19.2'].disable();
    this.group.controls['335.19.3'].disable();
      
    }
    else{
      this.group.controls['335.1'].enable();
      this.group.controls['335.2'].enable();
      this.group.controls['335.3'].enable();
      this.group.controls['335.5'].enable();
      this.group.controls['335.7'].enable();
      this.group.controls['335.8'].enable();
      this.group.controls['335.9'].enable();
      this.group.controls['335.10'].enable();
      this.group.controls['335.11'].enable();
      this.group.controls['335.12'].enable();
      this.group.controls['335.13'].enable();
      this.Is335_1_Y = false;
      this.group.controls['335.15'].enable();
      this.group.controls['335.15a'].enable();
      this.group.controls['335.15b'].enable();
      this.group.controls['335.15c'].enable();
      this.group.controls['335.15d'].enable();
      this.group.controls['335.15e'].enable();
      this.group.controls['335.15f'].enable();
      this.group.controls['335.16'].enable();
      this.group.controls['335.17'].enable();
      this.group.controls['335.18'].enable();
      this.group.controls['335.19.1'].enable();
      this.group.controls['335.19.2'].enable();
      this.group.controls['335.19.3'].enable();
    }
  }

  changeQf335_7(event: any){
    if(event.value==='N'){
      this.group.controls['335.8'].disable();
      this.group.controls['335.9'].disable();
      this.group.controls['335.10'].disable();
      this.group.controls['335.11'].disable();
      this.group.controls['335.12'].disable();
      this.group.controls['335.13'].disable();
      this.disableOf335_15();
      this.group.controls['335.16'].disable();
      this.group.controls['335.17'].disable();
      this.group.controls['335.18'].disable();
      this.disableOf335_19();
    }
    if(event.value==='Y'){
      this.group.controls['335.8'].enable();
      this.group.controls['335.9'].enable();
      this.group.controls['335.10'].enable();
      this.group.controls['335.11'].enable();
      this.group.controls['335.12'].enable();
      this.group.controls['335.13'].enable();
      this.enableOf335_15();
      this.group.controls['335.16'].enable();
      this.group.controls['335.17'].enable();
      this.group.controls['335.18'].enable();
      this.enableOf335_19();
    }
  }
  changeQf335_9(event: any){
    if(event.value==='Y'){
      this.group.controls['335.10'].disable();
      this.group.controls['335.11'].enable();
      this.group.controls['335.12'].enable();
      this.group.controls['335.13'].enable();
      this.Is335_1_Y = false;
      this.group.controls['335.15'].enable();
      this.group.controls['335.15a'].enable();
      this.group.controls['335.15b'].enable();
      this.group.controls['335.15c'].enable();
      this.group.controls['335.15d'].enable();
      this.group.controls['335.15e'].enable();
      this.group.controls['335.15f'].enable();
      this.group.controls['335.16'].enable();
      this.group.controls['335.17'].enable();
      this.group.controls['335.18'].enable();
      this.group.controls['335.19.1'].enable();
      this.group.controls['335.19.2'].enable();
      this.group.controls['335.19.3'].enable();
      
    }
    else{
    
      this.group.controls['335.10'].enable();
      this.group.controls['335.11'].disable();
      this.group.controls['335.12'].disable();
      this.group.controls['335.13'].disable();
      this.Is335_1_Y = true;
      this.group.controls['335.15'].disable();
      this.group.controls['335.15a'].disable();
      this.group.controls['335.15b'].disable();
      this.group.controls['335.15c'].disable();
      this.group.controls['335.15d'].disable();
      this.group.controls['335.15e'].disable();
      this.group.controls['335.15f'].disable();
      this.group.controls['335.16'].disable();
      this.group.controls['335.17'].disable();
      this.group.controls['335.18'].disable();
      this.group.controls['335.19.1'].disable();
    this.group.controls['335.19.2'].disable();
    this.group.controls['335.19.3'].disable();
    }
  }
  handle_335_11(){
    let q3 = this.group.controls['335.11'].value;
    if(q3 === '1') {
        this.group.controls['335.12'].disable();
      this.group.controls['335.13'].disable();
      this.Is335_1_Y = true;
      this.group.controls['335.15'].enable();
      this.group.controls['335.15a'].enable();
      this.group.controls['335.15b'].enable();
      this.group.controls['335.15c'].enable();
      this.group.controls['335.15d'].enable();
      this.group.controls['335.15e'].enable();
      this.group.controls['335.15f'].enable();
      this.group.controls['335.16'].enable();
      this.group.controls['335.17'].enable();
      this.group.controls['335.18'].enable();
      this.group.controls['335.19.1'].enable();
      this.group.controls['335.19.2'].enable();
      this.group.controls['335.19.3'].enable();

    }
    else if (q3 === '2') {
      this.group.controls['335.12'].enable();
      this.group.controls['335.13'].enable();
      this.Is335_1_Y = false;
      this.group.controls['335.15'].disable();
      this.group.controls['335.15a'].disable();
      this.group.controls['335.15b'].disable();
      this.group.controls['335.15c'].disable();
      this.group.controls['335.15d'].disable();
      this.group.controls['335.15e'].disable();
      this.group.controls['335.15f'].disable();
      this.group.controls['335.16'].disable();
      this.group.controls['335.17'].disable();
      this.group.controls['335.18'].disable();
      this.group.controls['335.19.1'].disable();
    this.group.controls['335.19.2'].disable();
    this.group.controls['335.19.3'].disable();
    }
    else if (q3 === '3') {
      this.group.controls['335.12'].disable();
      this.group.controls['335.13'].disable();
      this.Is335_1_Y = true;
      this.group.controls['335.15'].disable();
      this.group.controls['335.15a'].disable();
      this.group.controls['335.15b'].disable();
      this.group.controls['335.15c'].disable();
      this.group.controls['335.15d'].disable();
      this.group.controls['335.15e'].disable();
      this.group.controls['335.15f'].disable();
      this.group.controls['335.16'].disable();
      this.group.controls['335.17'].disable();
      this.group.controls['335.18'].disable();
      this.group.controls['335.19.1'].disable();
    this.group.controls['335.19.2'].disable();
    this.group.controls['335.19.3'].disable();
    }
  }
  disableOf335_15(){
    this.group.controls['335.15'].disable();
    this.group.controls['335.15a'].disable();
    this.group.controls['335.15b'].disable();
    this.group.controls['335.15c'].disable();
    this.group.controls['335.15d'].disable();
    this.group.controls['335.15e'].disable();
    this.group.controls['335.15f'].disable();
  }

  enableOf335_15(){
    this.group.controls['335.15'].enable();
    this.group.controls['335.15a'].enable();
    this.group.controls['335.15b'].enable();
    this.group.controls['335.15c'].enable();
    this.group.controls['335.15d'].enable();
    this.group.controls['335.15e'].enable();
    this.group.controls['335.15f'].enable();

  }

  disableOf335_19(){
    this.group.controls['335.19.1'].disable();
    this.group.controls['335.19.2'].disable();
    this.group.controls['335.19.3'].disable();
    
  }

  enableOf335_19(){
    this.group.controls['335.19.1'].enable();
    this.group.controls['335.19.2'].enable();
    this.group.controls['335.19.3'].enable();
  }

  get Is335_2():boolean{
    return this.group.controls['335.2'].value === 'N';
      }

}