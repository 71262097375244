import { Component, Input, OnInit, HostListener } from '@angular/core';
import { NavigationService } from 'src/app/common/navigation.service';


@Component({
  selector: 'app-body',
  templateUrl: './body.component.html',
  styleUrls: ['./body.component.css',
    './../../../assets/css/interview-module.css']
})

export class BodyComponent implements OnInit {
  @Input() resizeNavStatus: boolean;
  showicon: boolean = false;
resizeNav: any;
sideNav=false;
  constructor(
    public navigationService: NavigationService,
  ) { }
  hideValue: boolean;
navVisible(){
  this.navigationService.getHideValue().subscribe((value) => {
    this.hideValue = value;
    console.log(this.hideValue, "getvalue");
  });

}
  ngOnInit() {
    this.resizeNavStatus = true;
    this.navigationService.getHideValue().subscribe((value) => {
      this.hideValue = value;
      console.log(this.hideValue, "getvalue");
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
      document.getElementById('top-arrow').style.display = 'block';
    } else {
      document.getElementById('top-arrow').style.display = 'none';
    }
  }
  resizeNavBar(val: boolean) {
    this.resizeNav  = val;
  }

}
