import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-field-srmonth',
  templateUrl: './field-srmonth.component.html',
  styleUrls: ['./field-srmonth.component.css']
})
export class FieldSRMonthComponent extends BaseComponent implements OnInit {


  isNotEditable: boolean = false;
  handle68:boolean = false;

  ngOnInit() {
    if ( this.constantsService.in_68_Code) {
      this.group.controls["68.1.9"].patchValue(this.constantsService.in_68_Code);
      this.handle68 = true;
  
    }
    super.ngOnInit();
    this.determineAccess();
  }


  get Is_68_1_3(): boolean {
    return this.group.controls['68.1.3'].value === 'Y';
  }

  get Is_68_1_3_N(): boolean {
    return this.group.controls['68.1.3'].value === 'N';
  }
  get Is_68_1_4(): boolean {
    return this.group.controls['68.1.4'].value === 'Y';
  }
  changeQf68_1_4(val) {
    if ( val === 'Y') {
      this.setControlValue('68.1.9',3);
      this.handle68 = false;
  
    }else {
      this.setControlValue('68.1.9',0);
      this.handle68 = true;
      
    }
  }
  get Is_68_1_5_N(): boolean {
    return this.group.controls['68.1.5'].value === 'N';
  }
  get Is_68_1_6(): boolean {
    return this.group.controls['68.1.6'].value === 'Y';
  }
  changeQf68_1_6(val) {
    if ( val === 'Y') {
      this.setControlValue('68.1.9',3);
      this.handle68 = false;
    
      
    }else {
      this.setControlValue('68.1.9',0);
      this.handle68 = true;
      
    }
  }
  get Is_68_1_7(): boolean {
    return this.group.controls['68.1.7'].value === 'Y';
  }
  get Is_68_1_8(): boolean {
    return this.group.controls['68.1.8'].value === 'Y';
  }
  changeQf68_1_8(val) {
    if ( val === 'Y') {
      this.setControlValue('68.1.9',3);
      this.handle68 = false;
     
      
    }else {
      this.setControlValue('68.1.9',0);
      this.handle68 = true;
      
    }
  }


}
