import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnInit,
  ViewContainerRef,
  HostBinding,
  AfterViewInit,
  OnDestroy
} from "@angular/core";

@Directive({
  selector: '[print-section]',
})
export class PrintSectionDirective implements AfterViewInit, OnDestroy {
  @HostBinding('class.print-section') public printSection = true;
  private style: HTMLStyleElement;

  public ngAfterViewInit() {
    this.style = document.createElement('style');
    this.style.type = 'text/css';
    this.style.innerText = `
    @media print {
      body * {
        visibility: hidden;
      }
      .print-section, .print-section * {
        visibility: visible;
      }
      .print-section {
        width: 100%;
      }
    }`;

    document.head.appendChild(this.style);
  }

  public ngOnDestroy() {
    try {
      document.head.removeChild(this.style);
    } catch (error) {

    }

  }
}
