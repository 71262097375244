import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-reviewer-observations',
  templateUrl: './reviewer-observations.component.html',
  styleUrls: ['./reviewer-observations.component.css']
})

export class ReviewerObservationsComponent extends BaseComponent implements OnInit {
  currentPage: number = 0;
 
  ngOnInit() {
    super.ngOnInit();
  }

}