import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormBuilder } from '@angular/forms';
import * as Highcharts from 'highcharts';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { ConstantsService } from 'src/app/common/constants.service';
import { NavigationService } from 'src/app/common/navigation.service';
import { Router } from '@angular/router';
import { ApiManager } from 'src/app/common/api-manager.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface PeriodicElement {
    position: any;
    totalsampled:any;
    name: any;
    weight: any;
    symbol: any;
    LeadReviewer: any;
    EPEU: any;
    ConfirmationMemo: any;
    ReminderEmail: any;
}

export interface DistrictOffice {
    value: string;
    viewValue: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
    {
        position: 'Allegany',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Annapolis',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Glen Burnie',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Anne Arundel',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Towson',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Catonsville',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Dundalk',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Essex',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Reisterstown',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Baltimore Co.',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Calvert',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Caroline',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Carroll',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Cecil',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Charles',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Dorchester',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Frederick',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Garrett',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Harford',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Howard',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Kent',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Rockville',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Silver Spring',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Germantown',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Montgomery',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Hyattsville',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: '425 RISE',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'South County',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Prince George',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Queen Anne',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'St.Mary',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Somerset',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Talbot',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Washington',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Wicomico',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Worcester',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Harbor View',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Northeast',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Northwest',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Dunbar Orangeville',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Hilton Heights',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Southwest',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Penn North',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'Baltimore City',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
    {
        position: 'TOTAL / STATEWIDE',
        totalsampled:1,
        name: 4,
        weight: 13,
        symbol: 2,
        LeadReviewer: 1,
        EPEU: '0',
        ConfirmationMemo: '33%',
        ReminderEmail: '58%'
    },
];

export interface PeriodicElementtwo {
    LDSSDOName: any; DO: any; TOTAL: any; counts: any; countsifverified: any; ch: any; childonly: any; ctroprb: any; ssihoh: any; ssdihoh: any; otg: any; listedinerror: any; toofewhrs: any; sanction: any; otb: any; otc: any; otd: any; ote: any; otf: any; oth: any; otj: any; otk: any; otl: any; otm: any; otn: any; oto: any; otp: any; otq: any; otr: any; ots: any; ott: any; OTU: any; otv: any; otw: any; otx: any; oty: any; otz: any; noactivity: any;
}

const counts_DATA: PeriodicElementtwo[] = [
    {
        LDSSDOName: 'SAIL', DO: 6, TOTAL: '0', counts: '0', countsifverified: '0', ch: '0', childonly: '0', ctroprb: '0', ssihoh: '0', ssdihoh: '0', otg: '0', listedinerror: '0', toofewhrs: '0', sanction: '0', otb: '0', otc: '0', otd: '0', ote: '0', otf: '0', oth: '0', otj: '0', otk: '0', otl: '0', otm: '0', otn: '0', oto: '0', otp: '0', otq: '0', otr: '0', ots: '0', ott: '0', OTU: '0', otv: '0', otw: '0', otx: '0', oty: '0', otz: '0', noactivity: '0'
    },
    {
        LDSSDOName: 'Towson', DO: 3, TOTAL: 5, counts: 1, countsifverified: '0', ch: 1, childonly: '0', ctroprb: '0', ssihoh: '0', ssdihoh: '0', otg: '0', listedinerror: '0', toofewhrs: '2', sanction: '0', otb: '0', otc: '0', otd: '0', ote: '0', otf: '0', oth: '0', otj: '0', otk: '0', otl: '0', otm: '0', otn: '0', oto: '0', otp: '0', otq: '0', otr: '0', ots: '0', ott: '0', OTU: '0', otv: '0', otw: '0', otx: '0', oty: '0', otz: '0', noactivity: '0'
    },
    {
        LDSSDOName: 'Catonsville', DO: 31, TOTAL: 3, counts: 2, countsifverified: '0', ch: '0', childonly: '0', ctroprb: '0', ssihoh: '0', ssdihoh: '0', otg: '0', listedinerror: '0', toofewhrs: '0', sanction: '0', otb: '0', otc: '0', otd: '0', ote: '0', otf: '0', oth: '0', otj: '0', otk: '0', otl: '0', otm: '0', otn: '0', oto: '0', otp: '0', otq: '0', otr: '0', ots: '0', ott: '0', OTU: '0', otv: '0', otw: '0', otx: '0', oty: '0', otz: '0', noactivity: '0'
    },
    {
        LDSSDOName: 'Dundalk', DO: 32, TOTAL: 1, counts: '0', countsifverified: '0', ch: '0', childonly: '0', ctroprb: '0', ssihoh: '0', ssdihoh: '0', otg: '0', listedinerror: '1', toofewhrs: '0', sanction: '0', otb: '0', otc: '0', otd: '0', ote: '0', otf: '0', oth: '0', otj: '0', otk: '0', otl: '0', otm: '0', otn: '0', oto: '0', otp: '0', otq: '0', otr: '0', ots: '0', ott: '0', OTU: '0', otv: '0', otw: '0', otx: '0', oty: '0', otz: '0', noactivity: '0'
    },
    {
        LDSSDOName: 'Essex', DO: 33, TOTAL: 5, counts: '0', countsifverified: '0', ch: '0', childonly: '0', ctroprb: '0', ssihoh: '0', ssdihoh: '0', otg: '0', listedinerror: '0', toofewhrs: '1', sanction: '2', otb: '0', otc: '0', otd: '0', ote: '0', otf: '0', oth: '0', otj: '0', otk: '0', otl: '0', otm: '0', otn: '0', oto: '0', otp: '0', otq: '0', otr: '0', ots: '0', ott: '0', OTU: '0', otv: '0', otw: '0', otx: '0', oty: '0', otz: '0', noactivity: '0'
    },
    {
        LDSSDOName: 'Reisterstown', DO: 34, TOTAL: 12, counts: 1, countsifverified: '0', ch: '0', childonly: '0', ctroprb: '0', ssihoh: '0', ssdihoh: '0', otg: '0', listedinerror: '0', toofewhrs: '1', sanction: '2', otb: '0', otc: '0', otd: '0', ote: '0', otf: '0', oth: '0', otj: '0', otk: '0', otl: '0', otm: '0', otn: '0', oto: '0', otp: '0', otq: '0', otr: '0', ots: '0', ott: '0', OTU: '0', otv: '0', otw: '0', otx: '0', oty: '0', otz: '0', noactivity: '0'
    },
    {
        LDSSDOName: 'SSI unit', DO: 37, TOTAL: '0', counts: '0', countsifverified: '0', ch: '0', childonly: '0', ctroprb: '0', ssihoh: '0', ssdihoh: '0', otg: '0', listedinerror: '0', toofewhrs: '0', sanction: '0', otb: '0', otc: '0', otd: '0', ote: '0', otf: '0', oth: '0', otj: '0', otk: '0', otl: '0', otm: '0', otn: '0', oto: '0', otp: '0', otq: '0', otr: '0', ots: '0', ott: '0', OTU: '0', otv: '0', otw: '0', otx: '0', oty: '0', otz: '0', noactivity: '0'
    },
    {
        LDSSDOName: 'Baltimore Co', DO: '', TOTAL: 26, counts: 4, countsifverified: '0', ch: '1', childonly: '7', ctroprb: '7', ssihoh: '2', ssdihoh: '0', otg: '0', listedinerror: '1', toofewhrs: '4', sanction: '4', otb: '0', otc: '0', otd: '0', ote: '0', otf: '0', oth: '0', otj: '0', otk: '0', otl: '0', otm: '0', otn: '0', oto: '0', otp: '0', otq: '0', otr: '0', ots: '0', ott: '0', OTU: '0', otv: '0', otw: '0', otx: '0', oty: '0', otz: '0', noactivity: '0'
    }
];

@Component({
    selector: 'app-wpr-dashboard',
    templateUrl: './wpr-dashboard.component.html',
    styleUrls: ['./wpr-dashboard.component.css'],   
})

export class WprDashboardComponent implements OnInit {
  
    DistrictOffices: DistrictOffice[] = [
        { value: "districtOffice1", viewValue: "DistrictOffice1" },
        { value: "districtOffice2", viewValue: "DistrictOffice2" },
        { value: "districtOffice3", viewValue: "DistrictOffice3" }
      ];

    scheduledisplayedColumns: string[] = [
        'position','totalsampled', 'name', 'weight', 'symbol', 'LeadReviewer', 'EPEU', 'ConfirmationMemo', 'ReminderEmail'
    ];

    casedisplayedColumns: string[] = [
        'LDSSDOName', 'DO', 'TOTAL', 'counts', 'countsifverified', 'ch', 'childonly', 'ctroprb', 'ssihoh', 'ssdihoh', 'otg', 'listedinerror', 'toofewhrs', 'sanction', 'otb', 'otc', 'otd', 'ote', 'otf', 'oth', 'otj', 'otk', 'otl', 'otm', 'otn', 'oto', 'otp', 'otq', 'otr', 'ots', 'ott', 'OTU', 'otv', 'otw', 'otx', 'oty', 'otz', 'noactivity'
    ];
    
    scheduledataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
    countsdataSource = new MatTableDataSource<PeriodicElementtwo>(counts_DATA);
    isNotEditable: boolean = false;
    // scheduledataSource = ELEMENT_DATA;
    
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    constructor(
        public readonly router: Router,
    public navigationService: NavigationService,
    public constantsService: ConstantsService
        ) { }

    ngOnInit() {
        this.scheduledataSource.paginator = this.paginator;
        setTimeout(() => {
        }, 200)
    }

    determineAccess() {
        const ref = this.constantsService.getSecurityObject();
  
        if ((ref.accessMap[''] != null && ref.accessMap[''].accessMode != 'rw')) {
            this.isNotEditable = true;
        }
    }
    chosenYearHandler(year){

    }
    chosenMonthHandler(event,month){

    }
    populateData(){}
    print(): void {
        // let printContents, printMember, printStyle, printLink;
        // printContents = document.getElementById('print-section');
        // printMember = document.getElementById('print-member');
        // printStyle = this.printService.getTagsHtml('style');
        // printLink = this.printService.getTagsHtml('link');
        // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
        // this.printService.printTable = printContents ? printContents.innerHTML : null;
        // this.printService.printStyle = printStyle;
        // this.printService.printLink = printLink;
        // this.printService.print();
        window.print();
      }
      back(){
        this.navigationService.navigatePage(this.router.url, 'back');
      }
    
      next(){
        this.navigationService.navigatePage(this.router.url, 'next');
      }
    
}