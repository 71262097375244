import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-information-of-bank-account',
  templateUrl: './information-of-bank-account.component.html',
  styleUrls: ['./information-of-bank-account.component.css']
})
export class InformationOfBankAccountComponent extends BaseComponent implements OnInit {



  ngOnInit() {
    super.ngOnInit();
  }


}