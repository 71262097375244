import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-non-cooperation-wth-fed-qcreq',
  templateUrl: './non-cooperation-wth-fed-qcreq.component.html',
  styleUrls: ['./non-cooperation-wth-fed-qcreq.component.css']
})
export class NonCooperationWthFedQCReqComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
