import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ApiManager } from 'src/app/common/api-manager.service';
import { ConstantsService } from 'src/app/common/constants.service';
import { NavigationService } from 'src/app/common/navigation.service';

export interface PeriodicElement {
  county:string;
  rvwNum: number;
  do: number;
  caseId: number;
  pgmBgnDt:number;
  pgmEndDt:number;
  issncStsDt:number;
  issnTypCd:number;
  grntBnftAmt:number;
  pgmCd: string;
  aidCd:number;
  caseStatusCd:string;
  reviewStatus:number;
  reviewStatus15:string;
  reviewStatusbm:string;
}

export interface PeriodicElementtwo {
  county:any;
  rvwNum: any;
  do: any;
  caseId: any;
  pgmBgnDt:any;
  pgmEndDt:any;
  issncStsDt:any;
  issnTypCd:any;
  grntBnftAmt:any;
  pgmCd: any;
  aidCd:any;
  caseStatusCd:any; 
}

const ELEMENT_DATA: PeriodicElement[] = [
  {county: 'Baltimore County',rvwNum:1036,do:521,caseId:508,pgmBgnDt:7,pgmEndDt:1029,issncStsDt:4159,issnTypCd:2640,grntBnftAmt:110,pgmCd:'10.69%',aidCd:12.53,caseStatusCd:'yes',reviewStatus:19.24,reviewStatus15:'yes',reviewStatusbm:'yes'},
  {county: 'Carroll County',rvwNum:1036,do:521,caseId:508,pgmBgnDt:7,pgmEndDt:1029,issncStsDt:4159,issnTypCd:2640,grntBnftAmt:110,pgmCd:'10.69%',aidCd:12.53,caseStatusCd:'yes',reviewStatus:19.24,reviewStatus15:'yes',reviewStatusbm:'yes'},
  {county: 'Dorchester County',rvwNum:1036,do:521,caseId:508,pgmBgnDt:7,pgmEndDt:1029,issncStsDt:4159,issnTypCd:2640,grntBnftAmt:110,pgmCd:'10.69%',aidCd:12.53,caseStatusCd:'yes',reviewStatus:19.24,reviewStatus15:'yes',reviewStatusbm:'yes'}
];

const ELEMENT_DATATWO: PeriodicElementtwo[] = [
  {county: 31,rvwNum:'Watson',do:'Jennifer',caseId:47482838,pgmBgnDt:460101755,pgmEndDt:'FS',issncStsDt:'G',issnTypCd:'11/4/2019',grntBnftAmt:'11/23/2019',pgmCd:'11/26/2019',aidCd:'12/3/2019',caseStatusCd:0},
  {county: 31,rvwNum:'Mclean',do:'Jennifer',caseId:47482838,pgmBgnDt:460101755,pgmEndDt:'FS',issncStsDt:'G',issnTypCd:'11/4/2019',grntBnftAmt:'11/23/2019',pgmCd:'11/26/2019',aidCd:'12/3/2019',caseStatusCd:0},
  {county: 31,rvwNum:'Hashemi',do:'Jennifer',caseId:47482838,pgmBgnDt:460101755,pgmEndDt:'FS',issncStsDt:'G',issnTypCd:'11/4/2019',grntBnftAmt:'11/23/2019',pgmCd:'11/26/2019',aidCd:'12/3/2019',caseStatusCd:0},
  {county: 31,rvwNum:'Rosen',do:'Jennifer',caseId:47482838,pgmBgnDt:460101755,pgmEndDt:'FS',issncStsDt:'G',issnTypCd:'11/4/2019',grntBnftAmt:'11/23/2019',pgmCd:'11/26/2019',aidCd:'12/3/2019',caseStatusCd:0}
];

@Component({
  selector: 'app-pre-reivew-direct',
  templateUrl: './pre-reivew-direct.component.html',
  styleUrls: ['./pre-reivew-direct.component.css']
})

export class PreReivewDirectComponent implements OnInit {
//   dataSource;
//   data:any = [
   
//     {"rvwNum":220,"rvwMo":null,"rvwSts":200096426,"caseId":"Person One","ldss":null,"csWrkrNm":null,"hohname":null,"aidCd":null,"analystId":null,"pgmTypeDesc10":null,"pgmTypeDesc15":null,pgmTypeDescm15:'',pgmTypeDescbm:''},
    
//   ];
  
//   constructor() { }

//   ngOnInit() {
//    this.dataSource = new MatTableDataSource<any>(this.data);
//   }

// }

@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
@ViewChild(MatSort, {static: true}) sort: MatSort;

snapSampleForm: FormGroup;
isNotEditable:boolean = false;
buildForm() {
  this.snapSampleForm = this.fb.group({ 
   })
   };
displayedColumns: string[] = ['county','rvwNum','do','caseId','pgmBgnDt','pgmEndDt','issncStsDt','issnTypCd','grntBnftAmt','pgmCd','aidCd','caseStatusCd','reviewStatus','reviewStatus15','reviewStatusbm'];
displayedColumnstwo: string[] = ['county','rvwNum','do','caseId','pgmBgnDt','pgmEndDt','issncStsDt','issnTypCd','grntBnftAmt','pgmCd','aidCd','caseStatusCd'];
// dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
dataSource = ELEMENT_DATA;
dataSourcetwo = ELEMENT_DATATWO;

constructor(
  private fb: FormBuilder,
  private apiManager: ApiManager,
  public constantsService: ConstantsService,
  public readonly router: Router,
  public navigationService: NavigationService,
  
  )
{
  // this.dataSource = new MatTableDataSource();
  this.buildForm();
 }

ngOnInit() {
  this.getSelectSample();
}

getSelectSample() {

  // const queryParams: any[] = [];

  // queryParams.push('BPE-PA');
  // this.apiManager.fetchData('getSelectSample', undefined, ApiManager.GET,
  //     undefined, undefined, undefined, queryParams)
  //     .subscribe(res => {
  //       if (res) {
  //         console.log(res);

  //         this.dataSource = new MatTableDataSource(res);
  //         this.dataSource.paginator = this.paginator;
  //         this.dataSource.sort = this.sort;
  //       }
  //     });
  }
  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }
  back(){
    this.navigationService.navigatePage(this.router.url, 'back');
  }

  next(){
    this.navigationService.navigatePage(this.router.url, 'next');
  }
  

}