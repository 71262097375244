import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ApiManager } from 'src/app/common/api-manager.service';
import { NavigationService } from '../../common/navigation.service';
import { ConstantsService } from '../../common/constants.service';
import { Region, DistrictOffice, Analyst } from '../../model/api/region-district-analyst.class';
import { dateFormat } from 'highcharts';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewReviewHistoryComponent  } from '../view-review-history/view-review-history.component';

// export interface Region {
//   value: string;
//   viewValue: string;
// }
// export interface DistrictOffice {
//   ldssDoNa--me: string;
//   doCode: string;
//   userInfo: any[];
// }
// export interface Analyst {
//   value: string;
//   viewValue: string;
// }


@Component({
  selector: 'app-case-assignment',
  templateUrl: './case-assignment.component.html',
  styleUrls: ['./case-assignment.component.css']
})

export class CaseAssignmentComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  regions: any = [];
  regnCd: string;
  selectedTab = 'C';
  selectedRvwMonth;
  isNotEditable: boolean = false;
  // Region[] = [
    // { value: "region1", viewValue: "Region1" },
    // { value: "region2", viewValue: "Region2" },
    // { value: "tacos-3", viewValue: "Region3" }
  // ];
  districtOfficesList: DistrictOffice[] = [
    // { value: "districtOffice1", viewValue: "DistrictOffice1" },
    // { value: "districtOffice2", viewValue: "DistrictOffice2" },
    // { value: "districtOffice3", viewValue: "DistrictOffice3" }
  ];
  Analysts: Analyst[] = [
    // { value: "1", viewValue: "Analyst1" },
    // { value: "2", viewValue: "Analyst2" },
    // { value: "3", viewValue: "Analyst3" }
  ];
  allAnalysts: Analyst[] = [];
  jurisdictionType: string = 'A';
  assignmentType: string = 'All';
  monthYear : string;
  caseAssignForm: FormGroup;

  displayedColumns = [
    "select",
    "ldss",
    "rvwNum",
    "sampleMo",
    "reviewMonth",
    "pgmTypeDesc",
    "caseId",
    "irn",
    "hohNm",
    "assignee",
    // "pgmTypeDesc",
    // "assignment"
    // "region",
    "group",
    "history",
  ];
  casedataSourceDialog = new MatTableDataSource();
  casedataSourceDialogAll = new MatTableDataSource();
  // displayedColumns: string[] = ['select', 'position', 'name', 'weight', 'symbol','reviewMonth','stratum','statusCode','caseid','hohName'];
  //dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  selection = new SelectionModel(true, []);


  constructor(
    private readonly router: Router,
    private apiManager: ApiManager,
    private fb: FormBuilder,
    public navigationService: NavigationService,
    public constantsService: ConstantsService,
    public dialog: MatDialog,
  ) { 

    this.regions = this.constantsService.getDistinctRegions();
    this.allAnalysts = this.constantsService.getDistinctReviewer();
    this.districtOfficesList = this.constantsService.getDistinctDOs();
  }
  
  ngOnInit() {
    this.getData();
    this.buildForm();
    let date:Date = new Date();
    this.monthYear= date.getFullYear()+""+(date.getMonth()+1<10?"0"+(date.getMonth()+1):date.getMonth()+1);
    this.determineAccess();
  }

  determineAccess() {
      const ref = this.constantsService.getSecurityObject();

      if ((ref.accessMap['/qualityControl/qcCaseAssignment'] != null && ref.accessMap['/qualityControl/qcCaseAssignment'].accessMode != 'rw')) {
          this.isNotEditable = true;
      }
  }
  
  ngAfterViewInit(): void {
    this.casedataSourceDialog.paginator = this.paginator;
    this.casedataSourceDialog.sort = this.sort;
  }

  buildForm() {
    this.caseAssignForm = this.fb.group({
      pgmTypeDesc: ['', Validators.required],
      DistrictOffice: ['', Validators.required],
      Analyst: ['', Validators.required],
      benMonth: [''],
      ldssCode: [''],
      reviewer: []
    });
  }

  getData(jurisdiType: string = 'A') {
    const queryParams: any[] = [];
    queryParams.push(this.assignmentType);
    let appCd: string = '';
    appCd = this.constantsService.getAppCd(this.navigationService.appCd);

    queryParams.push(appCd);

    if (jurisdiType) {
      queryParams.push(jurisdiType);
    } 
    else {
      queryParams.push('A');
    }
    
    this.apiManager.fetchData('bpeAssigneUrl', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
    .subscribe(
      result => {
        result.map(item => {
          if(item && item.rvwMo) {
            item['dispRvwMo'] = item.rvwMo.substring(4) + '/' + item.rvwMo.substring(0,4);
          }
        })
       this.casedataSourceDialogAll.data = result;
       if(this.selectedTab =='C') {
        this.casedataSourceDialog.data = this.casedataSourceDialogAll.data.filter(item => item['rvwMo'] === this.monthYear && item['csAsgnmntSts'] === ( this.assignmentType =='Assigned'? 'AS':'UA'));
       }else {
        this.casedataSourceDialog.data = result.filter(item => item.rvwMo !== this.monthYear);
       }
       this.casedataSourceDialog.data = this.casedataSourceDialog.data.sort();
  
      });
  }
   
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.casedataSourceDialog.data.length;
    return numSelected === numRows;
  }
 
 handleCurrentMonth(val){
   this.selection.clear();
    if (val.tab.textLabel==="Current") {
      this.selectedTab = 'C';
      this.displayedColumns = [
        "select",
        "ldss",
        "rvwNum",
        "sampleMo",
        "reviewMonth",
        "pgmTypeDesc",
        "caseId",
        "irn",
        "hohNm",
        "assignee",
        // "pgmTypeDesc",
        // "assignment"
        // "region",
        "group",
        "history",
      ];
      this.casedataSourceDialog.data = this.casedataSourceDialogAll.data.filter(item => item['rvwMo'] === this.monthYear && item['csAsgnmntSts'] ===(this.assignmentType =='Assigned'? 'AS':'UA'));
    } 
    else if (val.tab.textLabel==="Previous") {
      this.selectedTab = 'P';
      this.displayedColumns = [
        "select",
        "ldss",
        "rvwNum",
        "sampleMo",
        "reviewMonth",
        "pgmTypeDesc",
        "caseId",
        "irn",
        "hohNm",
        "assignee",
        // "pgmTypeDesc",
        // "assignment"
        // "region",
        "group",
        "history",
      ];
      this.casedataSourceDialog.data = this.casedataSourceDialogAll.data.filter(item => item['rvwMo'] !== this.monthYear && item['csAsgnmntSts'] === ( this.assignmentType =='Assigned'? 'AS':'UA'));
  }
}

  masterToggle(_ref: any) {
    if (_ref.checked) {
        this.checkPageOnly();
    }
    else {
        this.selection.clear();
    }
  }

  @ViewChild('ref', { static: false }) ref: any;
  checkPageOnly() {
    let i = this.casedataSourceDialog.paginator.pageIndex * this.casedataSourceDialog.paginator.pageSize;
    let end = (this.casedataSourceDialog.paginator.pageIndex + 1) * this.casedataSourceDialog.paginator.pageSize;
    for (i; i < end; i++) {
      this.selection.select(this.casedataSourceDialog.data[i]);
    }
   console.log('selected...',this.selection.selected)
  }

  checkboxLabel(row?: any) {
    // this.selection.selected.push(row);
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
    
  }

  // row ${row.ldss + 1}`;

  /** The label for the checkbox on the passed row */
// checkboxLabel(row?: informationElement): string {
//   if (!row) {
//     return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
//   }
//   return `${this.selection.isSelected(row) ? 'deselect' : 'select'}`;
// }
  isAssignEnabled():boolean{
    if(this.assignmentType !== 'ReAssigned'){
      if(this.selection.selected.length>0 &&  this.caseAssignForm.get('Analyst').value !== '' ){
        return false;
      }
      else {
        return true;
      }
    }else{
     return this.isUnAssignEnabled();
    }
  }

  isUnAssignEnabled():boolean{
    if(this.caseAssignForm.get('Analyst').value !== '' ){
      return false;
    }
    else {
      return true;
    }
  }

  isRegionAssigned():boolean{
    if(this.selection.selected.length>0 &&  this.caseAssignForm.get('region').value !== '' ){
      return false;
    }
    else {
      return true;
    }
  }
 
  moveToRegion() {
    console.log("selected....", this.selection.selected);
    this.selection.selected.forEach(row => {
      let id: string = row.rvwNum;
      let pgmCd: string = row.rvwType;
      let appCd: string = '';
      let region = this.caseAssignForm.get('region').value;

      this.regnCd= this.constantsService.findRegionCdByRegionName(region);
      appCd = this.constantsService.getAppCd(this.navigationService.appCd);
      const queryParams: any[] = [];
      queryParams.push(id);
      queryParams.push(this.regnCd);
      queryParams.push(appCd);
      queryParams.push(pgmCd);  

      this.apiManager.fetchData('moveToRegion', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
        .subscribe(
          res => {
            console.log("----Assigned Region-----");
            const index = this.casedataSourceDialog.data.indexOf(row);
            this.casedataSourceDialog.data.splice(index, 1);
            this.casedataSourceDialog.data = this.casedataSourceDialog.data.sort();
          });
    });
  }
   
  assign() {
    if(this.assignmentType === 'ReAssigned'){
      this.unassign();
    }else{
    console.log("selected....", this.selection.selected);
    this.selection.selected.forEach(row => {
      let id: string = row.rvwNum;
      let pgmCd: string = row.rvwType;
      let analyst = this.caseAssignForm.get('Analyst').value;
      let appCd: string = '';
      appCd = this.constantsService.getAppCd(this.navigationService.appCd);
      const queryParams: any[] = [];
      queryParams.push(id);
      queryParams.push(analyst);
      queryParams.push(appCd);
      queryParams.push(pgmCd);

      this.apiManager.fetchData('saveAssignment', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
        .subscribe(
          res => {
            console.log("----Assigned-----");
            const index = this.casedataSourceDialog.data.indexOf(row);
            this.casedataSourceDialog.data.splice(index, 1);
            this.casedataSourceDialog.data = this.casedataSourceDialog.data.sort();
            /*   this.casedataSourceDialog.data = res;
              this.casedataSourceDialog.data = this.casedataSourceDialog.data.sort(); */
          });
    });
  }
  }

  unassign() {
      let analyst = this.caseAssignForm.get('Analyst').value;
      let currentDate = new Date();
      const queryParams: any[] = [];
      let currentMonth = currentDate.getMonth() + 1;
      queryParams.push(currentDate.getFullYear() +""+ currentMonth.toString().padStart(2, '0'));
      queryParams.push(analyst);

      this.apiManager.fetchData('unAssignment', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
        .subscribe(
          res => {
            console.log("----Assigned-----");
      });
  }

  filterByFormData() {
    let rvwType = this.caseAssignForm.get('pgmTypeDesc').value;
    let ldssDoCd = this.caseAssignForm.get('DistrictOffice').value;
    let reviewer = this.caseAssignForm.get('reviewer').value;
    let filteredData ;
    if(this.selectedTab =='C') {
      filteredData = this.casedataSourceDialogAll.data.filter(item => item['rvwMo'] === this.monthYear && item['csAsgnmntSts'] === ( this.assignmentType =='Assigned'? 'AS':'UA'));
     }else {
      filteredData = this.casedataSourceDialogAll.data.filter(item => item['rvwMo'] !== this.monthYear);
     }
    if(rvwType) {
      filteredData = filteredData.filter(item => item['rvwType'] === rvwType);
    }
    if(ldssDoCd) {
      filteredData = filteredData.filter(item => item['ldss'] === +ldssDoCd);
    }
    if(reviewer) {
      filteredData = filteredData.filter(item => item['analystId'] === reviewer);
    }

    this.casedataSourceDialog.data = filteredData;
  }

  filterPreviousFormData() {
    let benMonth = this.caseAssignForm.get('benMonth').value;
    let ldssDoCd = this.caseAssignForm.get('ldssCode').value;
    let reviewer = this.caseAssignForm.get('reviewer').value;
    let filteredData = this.casedataSourceDialogAll.data;
    if(benMonth) {
      filteredData = filteredData.filter(item => item['rvwMo'] === benMonth);
    }
    if(ldssDoCd) {
      filteredData = filteredData.filter(item => item['ldss'] === +ldssDoCd);
    }
    if(reviewer) {
      filteredData = filteredData.filter(item => item['analystId'] === reviewer);
    }

    this.casedataSourceDialog.data = filteredData;
  }

  filterSample(jurisdiType) {
    this.jurisdictionType = jurisdiType;
    this.getData(jurisdiType);
  }

  filterAssignment(assigType) {
    this.assignmentType = assigType;
    this.getData(this.jurisdictionType);
  }

  getDistinctMonth() {
    let distinctMonth: any = [];
    let distinctMonthSort :any =[]
    this.casedataSourceDialogAll.data.map(filterItem => {
      if (filterItem['rvwMo'] &&
        distinctMonth.indexOf(filterItem['rvwMo']) === -1) {
        distinctMonth.push(filterItem['rvwMo']);
      }
       distinctMonthSort = distinctMonth.sort((a, b) => (b < a) ? -1 : 1)
    });
    return distinctMonthSort;
  }

  getDistinctLDSS(){
    let distinctLDSS: any = [];
    this.casedataSourceDialogAll.data.map(filterItem => {
      if (filterItem['ldss'] &&
        distinctLDSS.indexOf(filterItem['ldss']) === -1) {
        distinctLDSS.push(filterItem['ldss']);
      }
    });
    return distinctLDSS;
  }

  filterData(type, value) {
    switch (type) {
      case 'reviewer':
        this.casedataSourceDialog.data = this.casedataSourceDialogAll.data.filter(item => item['analystId'] === value);
        break;
      case 'benMonth':
        this.selectedRvwMonth = value;
        this.casedataSourceDialog.data = this.casedataSourceDialogAll.data.filter(item => item['rvwMo'] === value);
        break;
      case 'ldssCd':
        this.casedataSourceDialog.data = this.casedataSourceDialogAll.data.filter(item => item['ldssCd'] === value);
        break;
      case 'ldss':
        this.casedataSourceDialog.data = this.casedataSourceDialogAll.data.filter(item => item['ldss'] === +value);
        break;  
    }

    if(this.selectedTab === 'C'){
      this.casedataSourceDialog.data = this.casedataSourceDialog.data.filter(item => item['rvwMo'] === this.monthYear && item['csAsgnmntSts'] === ( this.assignmentType =='Assigned'? 'AS':'UA'));
    } else if(this.selectedTab === 'P' && type === 'ldssCd' ){
      this.casedataSourceDialog.data = this.casedataSourceDialog.data.filter(item => item['rvwMo'] === this.selectedRvwMonth && item['csAsgnmntSts'] === ( this.assignmentType =='Assigned'? 'AS':'UA'));
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.casedataSourceDialog.filter = filterValue.trim().toLowerCase();
  }

  openReviewHistory(rvwInfo) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    if (rvwInfo) {
      dialogConfig.data = {
        rvwId: rvwInfo.rvwNum,
      };
    }

    const dialogRef = this.dialog.open(ViewReviewHistoryComponent , dialogConfig);
    dialogRef.updateSize('60%', '42%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log('afterClose', val);
      }
      this.dialog.closeAll();
    });
  }
  
  next() {
    this.navigationService.navigatePage(this.router.url, 'next');
  }
  
  back() {
    this.navigationService.navigatePage(this.router.url, 'back');
  }
  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }
}