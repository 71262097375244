import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../common/storage.service';
import { AuthService } from '../../common/auth.service';
import { DataShareService } from 'src/app/common/data-share.service';
import { ReportIssueComponent } from 'src/app/shared-module/report-issue/report-issue.component';
import html2canvas from 'html2canvas';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { environment } from 'src/environments/environment';
import { log } from "console";

@Component({
  providers: [ReportIssueComponent],
  selector: "app-top-menu",
  templateUrl: "./top-menu.component.html",
  styleUrls: ["./top-menu.component.css"],
})
export class TopMenuComponent implements OnInit {
  @ViewChild(ReportIssueComponent, { static: false })
  reportIssuePopup: ReportIssueComponent;

  public securityObject = undefined;
  private roleArraySize: number;
  public initials: any;
  public bgColor: any;
  public userDetails: any;
  private showLTCLink: boolean = true;
  private showSupervisor: boolean = false;
  public ecmLink: any = environment.ecmLink;
  private showUser: boolean = false;
  public isProd = environment.envName == "stg" || environment.envName == "prod";
  public entSearch: any = environment.enterpriseSearchURL;
  public currentTheme: any;
  myForm: FormGroup;
  title: string;
  subTitle: string;

  themes = [
    {
      primary: "#673AB7",
      accent: "#FFC107",
      href: "deeppurple-amber.css",
      isDark: false,
    },
    {
      primary: "#3F51B5",
      accent: "#E91E63",
      href: "indigo-pink.css",
      isDark: false,
      isDefault: true,
    },
    {
      primary: "#E91E63",
      accent: "#607D8B",
      href: "pink-bluegrey.css",
      isDark: true,
    },
    {
      primary: "#9C27B0",
      accent: "#4CAF50",
      href: "purple-green.css",
      isDark: true,
    },
  ];

  constructor(
    private router: Router,
    private storage: StorageService,
    public fb: FormBuilder,
    public authService: AuthService,
    public shareService: DataShareService,
    public contactSupportComponent: ReportIssueComponent,

    public dialog: MatDialog
  ) {
    if (router.url.includes("/bpe/fsme")) {
      this.title = "PEC";
      this.subTitle = "FSME";
    } else if (router.url.includes("/bpe")) {
      this.title = "PEC";
      this.subTitle = "Program Evaluation and Compliance";
    } else if (router.url.includes("/bpe/fsme/")) {
      this.title = "PEC";
      this.subTitle = "FSME";
    } else if (router.url.includes("/bpe/payment-accuracy/")) {
      this.title = "PEC";
      this.subTitle = "Payment Accuracy";
    } else if (router.url.includes("/bpe/wpr/")) {
      this.title = "PEC";
      this.subTitle = "Work Participation Report";
    } else if (router.url.includes("/bpe/monitoring/")) {
      this.title = "PEC";
      this.subTitle = "Monitoring";
    } else {
      this.title = "QC";
      this.subTitle = "Quality Control";
    }
  }

  determineRoleAccess(sec: any) {
    this.userDetails = sec;
    console.log(this.userDetails);
    this.showUser = true;
    const userName = this.userDetails.user.name;

    if (userName) {
      this.bgColor = this.generateAvatarBg(userName, 71, 70);
      console.log();

      this.initials = userName
        .match(/\b(\w)/g)
        .join("")
        ?.toUpperCase();
      console.log(this.initials, this.bgColor);
    } else {
      console.log("User's name is empty or null.");
    }
  }

  // determineSupervisorAccess() {
  //   this.showSupervisor = false;
  //   const ref = this.constantsService.getSecurityObject();
  //   let roleArraySize = ref.user.roles.length;
  //   for (let i = 0; i < roleArraySize; i++) {
  //     if (ref.user.roles[i] == 'EE_SUPVRS' ||
  //       ref.user.roles[i] == 'EE_OIG_SUPERVISOR' ||
  //       ref.user.roles[i] == 'EE_FNS_SUPERVISOR' ||
  //       ref.user.roles[i] == 'EE_FIS_SUPERVISOR' ||
  //       ref.user.roles[i] == 'EE_OP_SUPERVISOR') {
  //       this.showSupervisor = true;
  //     }
  //   }
  // }

  // determineLTCAccess() {
  //   this.showLTCLink = false;
  //   const ref = this.constantsService.getSecurityObject();
  //   let roleArraySize = ref.user.roles.length;
  //   for (let i = 0; i < roleArraySize; i++) {
  //     if (ref.user.roles[i] == 'EE_LTC_REPORT') {
  //       this.showLTCLink = true;
  //     }
  //   }
  // }

  ngOnInit() {
    this.buildForm();
    // this.determineSupervisorAccess();
    // this.contactSupportComponent;
    setTimeout(() => {
      let sec = this.storage.getAccessSecurity();
      if (sec && sec.user) {
        this.determineRoleAccess(sec);
      }
    }, 3000);

    // this.determineLTCAccess();
    // this.determineRoleAccess();
    // this.apiManager.fetchData('lookupType', undefined,
    // ApiManager.GET, undefined, undefined,
    // undefined, undefined).subscribe(
    // res => {
    //   this.constantsService.localLookupData = res as any as LookupContainerModel;
    // }
    // );

    // const currTheme = this._themeStorage.getStoredTheme() ? this._themeStorage.getStoredTheme() : {
    //   primary: '#3F51B5',
    //   accent: '#E91E63',
    //   href: 'indigo-pink.css',
    //   isDark: false,
    //   isDefault: true,
    // };
    // this.installTheme(currTheme);
  }

  isSecurityObject() {
    return !this.isEmptyObject(this.securityObject);
  }

  isEmptyObject(object: any) {
    return (
      this.securityObject === undefined ||
      JSON.stringify(this.isSecurityObject) === "{}"
    );
  }

  openEntSearch() {
    window.open(this.entSearch, "_blank");
  }

  homescreen() {
    this.shareService.addDataToMap("hideHeaderMenus", true);
    this.shareService.addDataToMap("hideHeaderBar", true);
    this.shareService.addDataToMap("showRightSidebarMenu", false);
    this.router.navigate(["/dashboard/homeScreen"]);
    // // tslint:disable-next-line:no-unused-expression
    // this.constantsService.getConstant('humanServiceModel').benefitsCase = new BenefitsCase();

    //   if ( this.router.url.includes('/bpe/fsme')) {
    //     this.router.navigate(['./bpe/fsme/']);
    //  } else
    if (this.router.url.includes("/bpe")) {
      this.router.navigate(["./bpe/"]);
    } else {
      this.router.navigate(["./homePage"]);
    }
  }

  getHeight(id: string) {
    const el = document.getElementById(id);
    if (el && el.offsetHeight) {
      return Math.max(
        el.clientHeight,
        el.offsetHeight,
        el.scrollHeight,
        el.getBoundingClientRect().height
      );
    }
    return 0;
  }
  openLtssDocument() {
    window.open(
      "https://docs.google.com/spreadsheets/d/126Glq8uGoe69YKHScWi79gAHAOhYRltS1_DWAKMF4is/edit?ts=5a79ffed#gid=1760562849"
    );
  }

  openBge() {
    window.open("https://www.bge.com/");
  }

  eppic() {
    window.open("https://ebtprod.dhr.state.md.us:65032/mdebtmanage/main.eppic");
  }

  goPotomacEdison() {
    window.open("https://www.firstenergycorp.com/potomac_edison.html");
  }
  goPepco() {
    window.open("https://www.pepco.com/");
  }
  goDelmavaPower() {
    window.open("https://www.delmarva.com/");
  }
  goFnsHanbook() {
    window.open("https://www.fns.usda.gov/snap/QC/fns-handbook-310");
  }

  goDhsTravelExpVoch() {
    window.open(
      "https://kb.dhs.maryland.gov/budget-and-finance/accounting-operations/forms-and-manuals/"
    );
  }
  goDhsFltMgmt() {
    window.open(
      "https://kb.dhs.maryland.gov/division-of-administrative-operations/assistant-chief-of-support-operations/fleet/"
    );
  }
  gosnapMnul() {
    window.open(
      "https://kb.dhs.maryland.gov/family-investment-administration/manuals/"
    );
  }
  goMdDcInmtLctr() {
    window.open("http://www.dpscs.state.md.us/inmate/");
  }
  goBeacn() {
    window.open(
      "https://partner.beacon.labor.maryland.gov/ReBEACONStaff/Account/Login"
    );
  }

  marylandLandRecords() {
    window.open(environment.MarylandLandRecords);
  }

  marylandHealthConnection() {
    window.open(environment.marylandHealthConnection);
  }
  marylandEBT() {
    window.open(environment.MarylandEBT);
  }

  onlineLongTermCareForms() {
    // window.open(environment.OnlineLongTermCareForms);
  }

  zipCodeSearch() {
    window.open(environment.ZipCodeSearch);
  }

  marylandCaseSearch() {
    window.open(environment.MarylandCaseSearch);
  }

  refreshSupport() {
    this.shareService.removeDataFromMap("screenshot");
    let screenshot: Blob;
    html2canvas(document.body).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      // document.body.appendChild(canvas);
      if (imgData) {
        var byteString: string;
        if (imgData.split(",")[0].indexOf("base64") >= 0)
          byteString = atob(imgData.split(",")[1]);
        else byteString = unescape(imgData.split(",")[1]);
        var mimeString = imgData.split(",")[0].split(":")[1].split(";")[0];
        var ia = new Uint8Array(byteString.length);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        screenshot = new Blob([ia], { type: mimeString });
      }
      this.openContactSupport(screenshot);
    });
  }
  openContactSupport(screenshot: BlobPart) {
    if (screenshot) {
      screenshot = new File([screenshot], "screenshot.png", {
        type: "image/png",
      });
      this.shareService.addDataToMap("screenshot", screenshot);
      this.shareService.addDataToMap("openContactsupport", true);

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      const dialogRef = this.dialog.open(ReportIssueComponent, dialogConfig);
      dialogRef.updateSize("75%", "85%");
      dialogRef.afterClosed().subscribe((val) => {
        if (val) {
          console.log("afterClose", val);
        }
      });
    }
  }
  public buildForm() {
    this.myForm = this.fb.group({
      NewEmail: ["", [Validators.required]],
      NewEmailConfirm: ["", [Validators.required]],
      CurrentPassword: [""],
      NewPassword: ["", [Validators.required]],
      NewPasswordConfirm: ["", [Validators.required]],
    });
  }
  dhsSecurityForms() {
    window.open(environment.DHSSecurityForms);
  }

  // openBiBVReport() {
  //   window.open(environment.BiBvReport);
  // }

  // mdJudiciaryCaseSearch() {
  //   window.open(environment.MDJudiciaryCaseSearch);
  // }

  // divStateDocs() {
  //   window.open(environment.DivStateDocs);
  // }

  dhsKnowledgeBaseFIA() {
    window.open(environment.DHSKnowledgeBaseFIA);
  }

  fiaActionTransmittals() {
    window.open(environment.FIAActionTransmittals);
  }

  sdat() {
    window.open(environment.SDAT);
  }

  avs() {
    window.open(environment.AVS);
  }

  // reports() {
  //   if (this.showLTCLink) {
  //     window.open(environment.QlikSense);
  //   }
  // }
  reports() {
    window.open(environment.QlikSense);
  }

  worksNumber() {
    window.open(
      "https://secure1.verifier.theworknumber.com/verifier/Account/LogOn?ReturnUrl=%2fverifier%2fOrder%2fCreate"
    );
  }

  goMVA() {
    window.open("https://egov.maryland.gov/mva/idvr/");
  }

  // dashboard() {
  //   if (this.showLTCLink) {
  //     window.open(environment.QlikView);
  //   }
  // }

  save() {
    window.open(environment.SAVE);
  }
  generateAvatarBg(name: any, s: number, l: number) {
    if (!name) return;
    let hash: number = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 6) - hash);
    }
    let h: number = Math.abs(hash % 360);
    return `hsl(${h}, ${s}%, ${l}%)`;
  }

  // mdMarriageLicenses() {
  //   window.open(environment.MDMarriageLicenses);
  // }
  // // @HostListener('window:scroll', [])
  // // onWindowScroll() {
  // //   if (document.getElementById('header-wrap') && document.getElementById('body-wrapper')) {
  // //     const height = this.getHeight('header-wrap') + this.getHeight('title-wrap');
  // //     if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
  // //       document.getElementById('header-wrap').style.position = 'fixed';
  // //       document.getElementById('body-wrapper').style.marginTop = height + 'px';
  // //     } else {
  // //       document.getElementById('body-wrapper').style.marginTop = '0px';
  // //       document.getElementById('header-wrap').style.position = 'relative';
  // //     }
  // //   }
  // // }

  // account_settings() {

  // }

  // accountManagement() {
  //   this.emitter.broadcast('showAccount', null);
  // }

  // refreshSupport() {
  //   this.contactSupportComponent.ngOnInit();
  // }

  // private _getCurrentThemeFromHref(href: string): SiteThemeInterface {
  //   return this.themes.find(theme => theme.href === href);
  // }

  // installTheme(theme: SiteThemeInterface) {
  //   this.currentTheme = this._getCurrentThemeFromHref(theme.href);
  //   this.styleManager.removeStyle('theme');
  //   this.styleManager.setStyle('theme', `assets/prebuilt-themes/${theme.href}`);

  //   if (this.currentTheme) {
  //     this._themeStorage.storeTheme(this.currentTheme);
  //     this.emitter.broadcast(EventEmitterService.ThemeChange, JSON.stringify(this.currentTheme));
  //   }
  // }
}
