import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-liquid-assets',
  templateUrl: './liquid-assets.component.html',
  styleUrls: ['./liquid-assets.component.css']
})

export class LiquidAssetsComponent extends BaseComponent implements OnInit {
  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    if (!this.group.controls['213.01'].value || this.group.controls['213.01'].value === 'N') {
      this.group.controls['213.02'].enable();
    }
    this.determineAccess();
  }

  handle21302Q(val) {
    if (val === 'Y') {
      this.group.controls['213.02'].enable();
    } else
      if ((val === "") || (val === 'N')) {
        this.group.controls['213.02'].enable();
      }

  }

  calc_213_6() {
    let inNBRPPL: number = parseInt(this.group.controls['213.7'].value, 0);
    const valAmt: number = parseInt(this.group.controls['213.5'].value, 0);
    if (inNBRPPL === 0) {
      if (valAmt) {
        const halfAmt = valAmt / 2;
        this.setValue213_6(halfAmt);
      }
    } else {
      inNBRPPL = inNBRPPL + 1;
      const amtPerPerson = valAmt / inNBRPPL;
      const halfAmt1 = amtPerPerson / 2;
      this.setValue213_6(halfAmt1);

    }
  }

  setValue213_6(val) {
    this.group.controls['213.8'].patchValue(val);
  }
  get Is_213_1(): boolean {
    return this.group.controls['213.1'].value === 'N';
  }

  changeof213_1(event: any) {
    if (event === 'Y') {
      this.group.controls['213.5'].enable();
      this.group.controls['213.7'].enable();
      this.group.controls['213.8'].enable();
    } else {
      this.group.controls['213.5'].disable();
      this.group.controls['213.7'].disable();
      this.group.controls['213.8'].disable();
    }
  }
  changeof213_2(event: any) {
    if (event === 'N') {
      this.group.controls['213.3'].enable();
      this.group.controls['213.4'].enable();
      this.group.controls['213.7'].enable();


    } else {
      this.group.controls['213.3'].disable();
      this.group.controls['213.4'].disable();
      this.group.controls['213.7'].disable();

    }
  }
}