import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-qc56-postal-verification',
  templateUrl: './qc56-postal-verification.component.html',
  styleUrls: ['./qc56-postal-verification.component.css']
})
export class QC56PostalVerificationComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }


}
