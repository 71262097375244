import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../baseComponent.component';

@Component({
  selector: 'app-qc38-contribution-verification-letter',
  templateUrl: './qc38-contribution-verification-letter.component.html',
  styleUrls: ['./qc38-contribution-verification-letter.component.css']
})
export class QC38ContributionVerificationLetterComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }
  convertSpanishPDF() {
    this.callGenerateSpanishPDF.emit(undefined);
  }
}