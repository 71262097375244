import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../shared-module/baseComponent.component';

@Component({
  selector: 'app-reactivations',
  templateUrl: './reactivations.component.html',
  styleUrls: ['./reactivations.component.css']
})

export class ReactivationsComponent extends BaseComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
  }
}