import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-client-discrepancy-name-error',
  templateUrl: './client-discrepancy-name-error.component.html',
  styleUrls: ['./client-discrepancy-name-error.component.css']
})
export class ClientDiscrepancyNameErrorComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
