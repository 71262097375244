import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-max-benefits-recieved-mdprior-stof-rsdnce',
  templateUrl: './max-benefits-recieved-mdprior-stof-rsdnce.component.html',
  styleUrls: ['./max-benefits-recieved-mdprior-stof-rsdnce.component.css']
})
export class MaxBenefitsRecievedMdpriorStofRsdnceComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }
}



