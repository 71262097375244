import { Component, OnInit } from "@angular/core";
import { BaseComponent } from "../../baseComponent.component";
import * as _moment from "moment";
const moment = _moment;
@Component({
  selector: "app-qc13-form",
  templateUrl: "./qc13-form.component.html",
  styleUrls: ["./qc13-form.component.css"],
})
export class Qc13FormComponent extends BaseComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    let dt = new Date(this.getControlValue("500102.2"));
    this.setControlValue("500102.2d", "Landlord");

    //this.setControlValue('500102.16', (dt.getMonth()+1)+"/"+dt.getFullYear())
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if (
      ref.accessMap["/qualityControl/questionary-500102?type=500102"] != null &&
      ref.accessMap["/qualityControl/questionary-500102?type=500102"]
        .accessMode != "rw"
    ) {
      this.isNotEditable = true;
    }
  }
  convertSpanishPDF() {
    this.callGenerateSpanishPDF.emit(undefined);
  }
}
