import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-alcohol-drug-facility-lost-cert',
  templateUrl: './alcohol-drug-facility-lost-cert.component.html',
  styleUrls: ['./alcohol-drug-facility-lost-cert.component.css']
})
export class AlcoholDrugFacilityLostCertComponent  extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
