import { throwError as observableThrowError, Observable, Subject } from "rxjs";
import { share } from "rxjs/operators";
import { map, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Inject } from "@angular/core";
import { environment } from "./../../environments/environment";
import { SlimLoadingBarService } from "ng2-slim-loading-bar";
// import { ConstantsService } from './../common/constants.service';
// import { FormArray } from '@angular/forms/src/model';
// import { StorageService } from "app/common/storage.service";
// import { EventEmitterService } from "./event.emitter.service";
// import { NgxSpinnerService } from 'ngx-spinner';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { DOCUMENT } from "@angular/common";
import { ConstantsService } from "./constants.service";
import { AppUtilService } from "./app.util.service";
import { NgxSpinnerService } from "ngx-spinner";

let wso2Version = "";
let sdrWso2Version = "";
if (environment.isWso2) {
  wso2Version = environment.isWso2Ver;
  sdrWso2Version = environment.sdrWso2Ver;
}

@Injectable()
export class ApiManager {
  private localLookUps: any = {};
  /* tslint:disable */

  public static readonly POST = 1;
  public static readonly GET = 2;
  public static readonly PUT = 3;
  public static readonly DELETE = 10;
  public static readonly DELETE_BODY = 12;
  public static readonly JSONFILE = 4;
  public static readonly FILEUPLOAD = 5;
  public static readonly FILEDOWNLOAD = 6;
  public static readonly FILEBULKDOWNLOAD = 9;
  public static readonly FILEDELETE = 7;
  public static readonly CUSTOMFILEDOWNLOAD = 8;
  public static readonly FILEDOWNLOADGET = 11;
  static readonly fileUpload: string =
    "interview" + wso2Version + "/document/~~/upload";
  static readonly baseJsonUrlDev: String = "./assets/mock-data";
  public static readonly initaiteCase =
    "dashboard" + wso2Version + "/eeqa/getRtDobQ/~~";
  public static readonly workerGetCaseNavs =
    "dashboard" + wso2Version + "/getoptionalnavs";
  public static readonly getDobQ = "dashboard" + wso2Version + "/getRtQ/Dob";
  public static readonly getResetReviewQ =
    "interview" + wso2Version + "/getResetRtQs/~~/~~/~~/~~";
  public static readonly getResetReviewQByIndex =
    "interview" + wso2Version + "/getResetRtQsByIndex/~~/~~/~~/~~/~~";
  public static readonly getAnalystCaseSummary =
    "dashboard" + wso2Version + "/getAnalystInfoById/~~";
  public static readonly getReviewQ =
    "interview" + wso2Version + "/getRtQ/~~/~~/~~/~~";
  public static readonly saveEmail =
    "interview" + wso2Version + "/sendEmail/~~/~~/~~";
  public static readonly saveReviewQ =
    "interview" + wso2Version + "/saveRtQs/~~/~~/~~";
  public static readonly getMemberList =
    "dashboard" + wso2Version + "/getHouseholdIndividuals/~~/~~/~~";
  public static readonly getCaseHomeData =
    "dashboard" + wso2Version + "/getReviewHome/~~/~~";
  public static readonly saveCaseHomeData =
    "dashboard" + wso2Version + "/saveReviewHome";
  public static readonly saveCaseHomeContactData =
    "dashboard" + wso2Version + "/saveReviewHomeContact";
  public static readonly saveQCMember =
    "dashboard" + wso2Version + "/saveQCMember/~~";
  public static readonly getRtDashBoardData =
    "dashboard" + wso2Version + "/getRtDashBoardData/~~/~~";
  public static readonly getRtDashBoardDataAnalyst =
    "dashboard" + wso2Version + "/getRtDashBoardData/analyst/~~/~~ ";
  public static readonly saveAssignment =
    "dashboard" + wso2Version + "/saveAssignment/~~/~~/~~/~~";
  public static readonly unAssignment =
    "dashboard" + wso2Version + "/unAssignment/~~/~~";
  public static readonly assigneeSkipped =
    "dashboard" + wso2Version + "/assigneeSkipped/~~/~~/~~";
  public static readonly assigneUrl =
    "dashboard" + wso2Version + "/getRtModelDashBoardData/~~/~~/~~";
  public static readonly bpeAssigneUrl =
    "dashboard" + wso2Version + "/getRtDashBoardDataByApptype/~~/~~/~~";
  public static readonly documentsForACaseUrl =
    "interview" + wso2Version + "/documents/~~";
  public static readonly syncDocumentsForACase =
    "interview" + wso2Version + "/syncDocuments/~~";
  static readonly fileDownload: string =
    "interview" + wso2Version + "/document/~~/download";
  static readonly viewFile: string =
    "interview" + wso2Version + "/document/~~/view";
  public static readonly accessSecurity: string =
    "dashboard" + wso2Version + "/getUserRolesById";
  public static readonly getSelectSample: string =
    "dashboard" + wso2Version + "/getUnselectedReviews/~~";
  public static readonly saveSelectSample: string =
    "dashboard" + wso2Version + "/saveSelectedReviews/~~";
  // public static readonly getTotalCasesErrorCases: string =
  //   "dashboard" + wso2Version + "/getsupervisorreview/errorData/QC/202308";
  public static readonly getTotalCasesErrorCases: string =
    "dashboard" + wso2Version + "/getsupervisorreview/errorData/~~/~~";

  public static readonly getCaseReviewSearch =
    "dashboard" + wso2Version + "/getRvwInfoByRvwNum/~~";
  public static readonly workItemSearch =
    "dashboard" + wso2Version + "/workitem/searchworkitem/~~/~~";
  public static readonly workUpdateItemSearch =
    "dashboard" + wso2Version + "/workitem/searchworkupdateitem/~~/~~/~~";
  public static readonly getWorkItemCount =
    "dashboard" + wso2Version + "/workitem/getWorkItemCount/~~";
  public static readonly saveWorkItemStatus =
    "dashboard" + wso2Version + "/workitem/saveWorkItemStatus/~~/~~";
  public static readonly deleteWorkItem =
    "dashboard" + wso2Version + "/workitem/deleteWorkItem/~~/~~";
  public static readonly createWorkItem =
    "dashboard" + wso2Version + "/workitem/createworkitem/~~";
  public static readonly getClarify =
    "interview" + wso2Version + "/getScreenNarrative/~~/~~/~~/~~";
  public static readonly saveClarify =
    "interview" + wso2Version + "/saveScreenNarrative";
  public static readonly getcountycases =
    "dashboard" + wso2Version + "/getTotalCorrectIncorrectCasesPerCounty";
  public static readonly getRegionDistricAnalystLookup =
    "dashboard" + wso2Version + "/qccaseassignment/search";
  public static readonly saveReviewStatus =
    "interview" + wso2Version + "/saveReviewStatus/~~/~~/~~";
  public static readonly getFindingsResponses =
    "interview" + wso2Version + "/getFndgsRsp/~~";
  public static readonly saveFindingsResponses =
    "interview" + wso2Version + "/saveFndgsRsp";
  public static readonly savefsmeschedule =
    "dashboard" + wso2Version + "/saveFsmeSchedule";
  public static readonly getfmeschedule =
    "dashboard" + wso2Version + "/getFsmeSchedule/~~";
  public static readonly getfsmeevaluation =
    "dashboard" + wso2Version + "/getFsmeEvaluation";
  public static readonly savefsmeevaluation =
    "dashboard" + wso2Version + "/saveFsmeEvaluation";
  public static readonly pdfDownload =
    "dashboard" + wso2Version + "/manualnotice/~~";
  public static readonly logoutApiUrl =
    "dashboard" + wso2Version + "/loggedout";
  // public static readonly getQCSamplingSchedule = 'application-process' + wso2Version + '/qc/getsamplingselection';
  // public static readonly saveQCSamplingSchedule = 'application-process' + wso2Version + '/qc/samplingselection';
  public static readonly getQCSamplingSchedule =
    "dashboard" + wso2Version + "/qc/getsamplingselection";
  public static readonly saveQCSamplingSchedule =
    "dashboard" + wso2Version + "/qc/samplingselection";
  public static readonly getSamplingReport =
    "dashboard" + wso2Version + "/qc/samplingpreview/~~/~~/~~/~~";
  public static readonly getReviewHistory =
    "dashboard" + wso2Version + "/getAsgnmntHstryByRvwNum/~~";
  public static readonly getCalcSheet =
    "dashboard" + wso2Version + "/calculation/googlesheet/~~/~~";
  public static readonly validateCalcSheet =
    "dashboard" + wso2Version + "/calculation/googlesheet/validate/~~/~~";
  public static readonly saveCalcSheet =
    "dashboard" + wso2Version + "/calculation/sheetresponse/~~";
  public static readonly getCalcSheetTemplate =
    "dashboard" + wso2Version + "/calculation/getCalcSheetTemplate";

  public static readonly getLdssReviewList =
    "dashboard" + wso2Version + "/getLdssReviews/~~/~~";
  public static readonly getMemoSchedule =
    "dashboard" + wso2Version + "/bpe/getSchdlMemoInfo";
  public static readonly saveMemoSchedule =
    "dashboard" + wso2Version + "/bpe/saveSchdlMemoInfo";
  public static readonly saveScannedDocToFNS =
    "interview" + wso2Version + "/document/~~/~~/saveScannedDocToFNS";

  public static readonly getTotalCasesReviewedCases: string =
    "dashboard" + wso2Version + "/getTotalCasesReviewedCases/~~/~~";
  public static readonly getFNSTransmission: string =
    "qc" + wso2Version + "/fns/transmission";

  public static readonly saveFNSTransmission: string =
    "qc" + wso2Version + "/fns/transmission";
  public static readonly updateFNSTransmissionStatus: string =
    "qc" + wso2Version + "/fns/transmission/updateStatus";

  public static readonly getQCLdssInformation =
    "dashboard" + wso2Version + "/qc/getLdssInformation";
  public static readonly saveLdssInformation =
    "dashboard" + wso2Version + "/qc/saveLdssInformation";

  public static readonly getQCDocRepo =
    "dashboard" + wso2Version + "/qc/getQCDocRepo/~~";
  public static readonly getQCDocRepoByRvwNum =
    "dashboard" + wso2Version + "/qc/getQCDocRepoByRvwNum/~~";
  public static readonly saveQcDocRepo =
    "dashboard" + wso2Version + "/qc/saveqcdoc/~~/~~/~~";

  public static readonly getQCDocRepo1 =
    "dashboard" + wso2Version + "/qc/getQCDocRepo1/~~";

  public static readonly generateQcComputationSheet =
    "dashboard" + wso2Version + "/qc/generateQcComputationSheet/~~/~~/~~";
  public static readonly getQcNarrative =
    "dashboard" + wso2Version + "/qc/getCalcSheetNarrative/~~/~~/~~/~~";
  public static readonly getDocumentForDownload =
    "dashboard" + wso2Version + "/qc/getDocumentForDownload/~~";
  public static readonly mergeAllSelectedDoc =
    "dashboard" + wso2Version + "/qc/mergeAllSelectedDoc/~~/~~/~~/~~/~~";
  public static readonly moveToFNSRvwSmry =
    "dashboard" + wso2Version + "/qc/movetofnsrvwsmry/~~";

  public static readonly saveQ16Qs =
    "interview" + wso2Version + "/saveQ16Qs/~~/~~/~~/~~/~~";
  public static readonly saveQ16NegativeQs =
    "interview" + wso2Version + "/saveQ16NegativeQs/~~/~~/~~";

  public static readonly updateQcDocStatus =
    "dashboard" + wso2Version + "/qc/updateQcDocStatus/~~/~~";
  public static readonly updateQcDocStatusByIdList =
    "dashboard" + wso2Version + "/qc/updateQcDocStatus/~~";
  public static readonly contactSupport =
    "public" + wso2Version + "/customer-support";
  public static readonly contactSupportUpload =
    "public" + wso2Version + "/upload-doc/~~/~~";
  static readonly getDocumentsForScreen =
    "application-process" + wso2Version + "/documents";
  public static readonly getCountyForContactsupport =
    "public" + wso2Version + "/get-doNameAndCounty/~~";

  public static readonly saveEmployerInfo =
    "interview" + wso2Version + "/saveEmployer";
  public static readonly deleteEmployerInfo =
    "interview" + wso2Version + "/deleteEmployer";

  public static readonly generate380Workbook =
    "dashboard" + wso2Version + "/qc/generate380Workbook/~~/~~/~~/~~";
  public static readonly updateQcDocName =
    "dashboard" + wso2Version + "/qc/updateQcDocName/~~";
  public static readonly updateQcDocFile =
    "dashboard" + wso2Version + "/qc/updateQcDocFile/~~";
  public static readonly validate380Workbook =
    "dashboard" + wso2Version + "/qc/validate380Workbook/~~";

  public static readonly rvwInfoByRgnDoAnalyst =
    "dashboard" + wso2Version + "/qc/getTotalRvwsByRgnDoAnlyst/~~";

  public static readonly getRvwNumList =
    "dashboard" + wso2Version + "/getRvwNumByRvwMnth/~~";

  public static readonly qcCtrlRvwSrchUrl =
    "dashboard" + wso2Version + "/qc/reviewSearch";
  public static readonly getIncomeValues =
    "dashboard" + wso2Version + "/getFwEligibilityValues/~~";
  public static readonly getFsposproc =
    "dashboard" + wso2Version + "/qc/processLogfsptca/~~";
  public static readonly saveFsposproc =
    "dashboard" + wso2Version + "/qc/saveQcReviewSearch";

  public static readonly getQc10 = "dashboard" + wso2Version + "/qc/getQc10";
  public static readonly saveQc10 = "dashboard" + wso2Version + "/qc/saveQc10";
  public static readonly saveToFNS =
    "interview" + wso2Version + "/document/~~/saveToFNS";
  public static readonly saveGroup =
    "dashboard" + wso2Version + "/saveGroup/~~/~~/~~/~~";
  public static readonly assignRegionGroup =
    "dashboard" + wso2Version + "/assignRegionGroup/~~/~~/~~/~~/~~";
  public static readonly resetRegionGroupAssignee =
    "dashboard" + wso2Version + "/resetRegionGroupAssignee/~~/~~/~~/~~/~~";
  public static readonly moveToRegion =
    "dashboard" + wso2Version + "/moveToRegion/~~/~~/~~/~~";
  public static readonly monthlyRotation =
    "dashboard" + wso2Version + "/qc/getSchedRotation/~~";

  public lookUpModuleName: Map<string, boolean>;
  fileUploadtProgress$;
  fileUploadProgressObserver;
  private loaderStatus = new Subject<any>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private http: HttpClient,
    // private storageService: StorageService,
    private slimLoadingBarService: SlimLoadingBarService,
    private spinnerService: NgxSpinnerService,
    // private alertService: AlertService,
    // private emitter: EventEmitterService,
    private utilService: AppUtilService
  ) {
    this.fileUploadtProgress$ = Observable.create((observer) => {
      this.fileUploadProgressObserver = observer;
    }).pipe(share());
    this.lookUpModuleName = new Map<string, boolean>();
    this.lookUpModuleName.set("screening", false);
    this.lookUpModuleName.set("interview", false);
    this.lookUpModuleName.set("trialbudget", false);
    this.lookUpModuleName.set("inquiry", false);
  }

  private emitTimer() {
    // this.emitter.broadcast('timer', null);
  }

  getLoaderStatus(): Observable<any> {
    return this.loaderStatus.asObservable();
  }

  // public makeFileRequest(url: string, params: any): Observable<HttpResponse<any>> {
  //     return Observable.create(observer => {
  //         let formData: FormData = new FormData(),
  //             xhr: XMLHttpRequest = new XMLHttpRequest();
  //         for (const key in params) {
  //             if (key) {
  //                 formData.append(key, params[key]);
  //             }
  //         }

  //         xhr.onreadystatechange = () => {
  //             if (xhr.readyState === 4) {
  //                 if (xhr.status === 200) {
  //                     observer.next(JSON.parse(xhr.response));
  //                     this.emitTimer();
  //                     observer.complete();
  //                 } else {
  //                     observer.error(xhr.response);
  //                 }
  //             }
  //         };
  //         xhr.upload.onprogress = (event) => {
  //             this.fileUploadProgressObserver.next(Math.round(event.loaded / event.total * 100));
  //             this.emitTimer();
  //         };

  //         xhr.open('POST', url, true);
  //         xhr.setRequestHeader('Accept-Language', 'en-US');
  //         if (environment.addForgerockHeaders) {
  //             xhr.setRequestHeader('uid', 'localUser');
  //             xhr.setRequestHeader('uniqueid', '99999999999');
  //             xhr.setRequestHeader('role', 'cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL,');
  //             xhr.setRequestHeader('caseId', ConstantsService.humanServiceModel.benefitsCase.caseId);

  //         }
  //         xhr.send(formData);
  //         this.emitTimer();
  //     });
  // }

  // public makeSdrRequest(url: string, params: any): Observable<HttpResponse<any>> {
  //     console.log('params sdr', params);
  //     return Observable.create(observer => {
  //         let formData: FormData = new FormData(),
  //             xhr: XMLHttpRequest = new XMLHttpRequest();
  //         for (const key in params) {
  //             if (key && params[key] && params[key] != '' && params[key] != undefined) {
  //                 formData.append(key, params[key]);
  //             }
  //         }

  //         xhr.onreadystatechange = () => {
  //             if (xhr.readyState === 4) {
  //                 if (xhr.status === 200) {
  //                     observer.next(JSON.parse(xhr.response));
  //                     this.emitTimer();
  //                     observer.complete();
  //                 } else {
  //                     observer.error(xhr.response);
  //                 }
  //             }
  //         };

  //         xhr.open('POST', url, true);
  //         xhr.setRequestHeader('Accept-Language', 'en-US');
  //         if (environment.addForgerockHeaders) {
  //             console.log('add sdr heafers');
  //             xhr.setRequestHeader('uid', 'localuser');
  //             xhr.setRequestHeader('uniqueid', '99999999999');
  //             xhr.setRequestHeader('role', 'cn=EE_SUPER,ou=groups,dc=openam,dc=forgerock,dc=org|cn=EE_SCREE,ou=groups,dc=openam,dc=forgerock,dc=org|cn=EE_FINAL,ou=groups,dc=openam,dc=forgerock,dc=org|cn=EE_INTER,ou=groups,dc=openam,dc=forgerock,dc=org|cn=Internal Users,ou=groups,dc=openam,dc=forgerock,dc=org|cn=EE_SUPUSR,ou=groups,dc=openam,dc=forgerock,dc=org');
  //             xhr.setRequestHeader('caseId', ConstantsService.humanServiceModel.benefitsCase.caseId);
  //         }
  //         this.emitTimer();
  //         xhr.send(formData);
  //     });
  // }

  clientLogout() {
    this.document.location.href = ConstantsService.logoutUrl;
  }

  fetchData(
    url: string,
    params: any,
    requestType: number,
    body: any,
    successMethod?: Function,
    failureMethod?: Function,
    queryParams?: string[],
    file?: File
  ) {
    // this.utilService.showLoader();
    let result: Observable<HttpResponse<any>>;
    let api_response: string;
    let finalUrl: string = ApiManager[url];

    this.slimLoadingBarService.start(() => {
      console.log("Loading complete");
    });
    this.spinnerService.show();
    if (
      finalUrl.includes("~~") &&
      queryParams != undefined &&
      queryParams.length > 0
    ) {
      for (let i = 0; i < queryParams.length; i++) {
        finalUrl = finalUrl.replace("~~", queryParams[i]);
      }
    }
    if (url === "lookup") {
      const moduleName = params.moduleName;
      if (!this.localLookUps[moduleName]) {
        result = this.http.get(
          environment.baseUrlDev + finalUrl,
          this.getOptionsWithRequestOptionsJsonResponse(params)
        );
        console.log("lookup call is made");
        this.localLookUps[moduleName] = result;
      } else {
        this.getOptionsWithRequestOptions(params);
        result = this.localLookUps[moduleName];
      }
      // result = this.http.get(environment.baseUrlDev + finalUrl, this.getOptionsWithRequestOptions(params));
    } else {
      if (requestType === ApiManager.GET) {
        result = this.http.get(
          environment.baseUrlDev + finalUrl,
          this.getOptionsWithRequestOptionsJsonResponse(params)
        );
      } else if (requestType === ApiManager.POST && url != "sdrUrl") {
        result = this.http.post(
          environment.baseUrlDev + finalUrl,
          body,
          this.getOptionsWithRequestOptionsJsonResponse(params, true)
        );
        // } else if (requestType === ApiManager.POST && url == 'sdrUrl') {
        //     result = this.makeSdrRequest(environment.sdrUrl + finalUrl, body);
      } else if (requestType === ApiManager.PUT) {
        result = this.http.put(
          environment.baseUrlDev + finalUrl,
          body,
          this.getOptionsWithRequestOptionsJsonResponse(params)
        );
      } else if (requestType === ApiManager.DELETE) {
        result = this.http.delete(
          environment.baseUrlDev + finalUrl,
          this.getOptionsWithRequestOptionsJsonResponse(params)
        );
      } else if (requestType === ApiManager.DELETE_BODY) {
        result = this.http.delete(
          environment.baseUrlDev + finalUrl,
          this.getOptionsWithRequestOptionsJsonResponseWithBody(params, body)
        );
      } else if (requestType === ApiManager.FILEUPLOAD) {
        this.spinnerService.hide();
        result = this.makeFileRequest(
          environment.baseUrlDev + finalUrl,
          params
        );
      } else if (requestType === ApiManager.FILEDOWNLOAD) {
        result = this.http.post(
          environment.baseUrlDev + finalUrl,
          body,
          this.getOptionsWithRequestOptionsBlobResponse(params, true)
        );
      } else if (requestType === ApiManager.FILEDOWNLOADGET) {
        result = this.http.get(
          environment.baseUrlDev + finalUrl,
          this.getOptionsWithRequestOptionsBlobResponse(params, false)
        );
      } else if (requestType === ApiManager.FILEBULKDOWNLOAD) {
        result = this.http.post(
          environment.baseUrlDev + finalUrl,
          body,
          this.getOptionsWithRequestOptionsBlobResponse(params, true)
        );
      } else if (requestType === ApiManager.CUSTOMFILEDOWNLOAD) {
        result = this.http.get(
          environment.baseUrlDev + finalUrl,
          this.getOptionsWithRequestOptionsBlobResponse(params, false)
        );
      } else if (requestType === ApiManager.FILEDELETE) {
        result = this.http.post(
          environment.baseUrlDev + finalUrl,
          body,
          this.getOptionsWithRequestOptionsJsonResponse(params, true)
        );
      }
    }
    return result.pipe(
      map((res: HttpResponse<any>) => {
        // this.utilService.hideLoader();
        // if(requestType == ApiManager.FILEBULKDOWNLOAD || requestType == ApiManager.FILEDOWNLOAD) {
        //     return res;
        // }
        this.spinnerService.hide();
        api_response = JSON.stringify(res);
        if (api_response.includes("DHS Login")) {
          this.clientLogout();
        } else {
          console.log("API Call Success");
          this.slimLoadingBarService.complete();
          this.emitTimer();
          if (successMethod) {
            successMethod();
          }
          if (res.body) {
            return res.body;
          } else {
            return res;
          }
        }
      }),
      catchError((error: HttpErrorResponse | any) => {
        // this.utilService.hideLoader();
        this.spinnerService.hide();
        console.log("API call failure: Catch");
        if (error.error) {
          let errorMessage = error.error;
          let displayErrorMsg = "";
          if (!errorMessage["userMessages"]) {
            displayErrorMsg = "Unknown Issue";
          } else {
            displayErrorMsg = errorMessage.userMessages[0].message;
          }
          // this.alertService.error(displayErrorMsg);
        }
        // this.alertService.error('Error : Unexpected error.');
        this.document.body.scrollTop = 0;
        window.scrollTo(0, 0);
        this.slimLoadingBarService.stop();
        let errMsg = "";
        let errorStatus; // status code for response
        let errorShortMessage; // short message of exception thrown
        let errorJson; // JSON of exception thrown
        if (!(error instanceof ErrorEvent) && !(error instanceof Error)) {
          const errorBody = error.error || "";
          errorJson = errorBody.error || JSON.stringify(errorBody);
          if (!errorJson && error["_body"]) {
            errorJson = error["_body"];
          }
          errorStatus = error.status;
          errorShortMessage = error.statusText || "";
          errMsg = `${errorStatus} - ${errorShortMessage} ${errorJson}`;
        } else {
          errMsg = error.message ? error.message : error.toString();
        }
        // console.error('error ' + errMsg);
        if (failureMethod !== undefined) {
          failureMethod();
        }
        // this._utilService.hideLoader();
        this.loaderStatus.next(false);
        return observableThrowError(errMsg);
      })
    );
  }

  getOptionsWithRequestOptionsJsonResponseWithBody(
    params: any,
    body: any,
    addHeader: boolean = false
  ): {
    headers?: HttpHeaders;
    observe: "response";
    params?: HttpParams;
    reportProgress?: boolean;
    responseType: "json";
    withCredentials?: boolean;
    body?: any;
  } {
    return {
      ...this.getOptionsWithRequestOptions(params, addHeader),
      responseType: "json",
      observe: "response",
      body: body,
    };
  }

  getOptionsWithRequestOptionsJsonResponse(
    params: any,
    addHeader: boolean = false
  ): {
    headers?: HttpHeaders;
    observe: "response";
    params?: HttpParams;
    reportProgress?: boolean;
    responseType: "json";
    withCredentials?: boolean;
  } {
    return {
      ...this.getOptionsWithRequestOptions(params, addHeader),
      responseType: "json",
      observe: "response",
    };
  }

  getOptionsWithRequestOptionsBlobResponse(
    params: any,
    addHeader: boolean = false
  ): {
    headers?: HttpHeaders;
    observe: "response";
    params?: HttpParams;
    reportProgress?: boolean;
    responseType: "blob";
    withCredentials?: boolean;
  } {
    return {
      ...this.getOptionsWithRequestOptions(params, addHeader),
      responseType: "blob",
      observe: "response",
    };
  }

  getOptionsWithRequestOptions(
    params: any,
    addHeader: boolean = false
  ): {
    headers?: HttpHeaders;
    observe?: "body";
    params?: HttpParams;
    reportProgress?: boolean;
    withCredentials?: boolean;
  } {
    let headers = new HttpHeaders();
    if (addHeader) {
      headers = headers.append("Content-Type", "application/json");
    }
    if (environment.addForgerockHeaders) {
      // headers = headers.append('uid', '73463db199f7272c91434166913d82');
      // headers = headers.append('uniqueid', '73463db199f7272c91434166913d82');
      headers = headers.append("uid", "2b1ed9b2d095206b8cc59bcefbe7ed");
      headers = headers.append("uniqueid", "2b1ed9b2d095206b8cc59bcefbe7ed");
      //   headers = headers.append('uid', '23186b77a4a6033654af161931009a');
      //   headers = headers.append('uniqueid', '23186b77a4a6033654af161931009a');
      // headers = headers.append('uid', '317f9ea22e8e82da3aa1eaadada4dd');
      // headers = headers.append('uniqueid', '317f9ea22e8e82da3aa1eaadada4dd');
      // headers = headers.append('fullName', 'Local User');
      // uncomment below code for vendor admin role
      // headers = headers.append('role', 'cn=EE_VEND_ADMN');
      // headers = headers.append('role', 'cn=EE_VEND_ADMN,|cn=EE_SUPUSR,|cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL,|cn=EE_SUPVRS,');
      // headers = headers.append('role', 'cn=EE_SUPUSR,|cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL,|cn=EE_SUPVRS,|cn=EE_FIS_SUPERVISOR');

      //  headers = headers.append('role', 'cn=EE_SUPUSR, |cn=EE_VEND_ADMN');
      headers = headers.append(
        "role",
        "cn=EE_SUPVRS,|cn=EE_VEND_ADMN,|cn=EE_QC_SUPERVISOR,|cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL"
      );
      // headers = headers.append('caseId', ConstantsService.humanServiceModel.benefitsCase.caseId);
    }
    // EE_SUPUSR
    headers = headers.append("Accept-Language", "en-US");
    // let tempCaseId = '';
    // if (ConstantsService.humanServiceModel &&
    //     ConstantsService.humanServiceModel.benefitsCase &&
    //     ConstantsService.humanServiceModel.benefitsCase.caseId &&
    //     ConstantsService.humanServiceModel.benefitsCase.caseId != '') {
    //     tempCaseId = ConstantsService.humanServiceModel.benefitsCase.caseId;
    // }
    // else if (ConstantsService.humanServiceModel &&
    //     ConstantsService.humanServiceModel.benefitsCaseSearches &&
    //     ConstantsService.humanServiceModel.benefitsCaseSearches.search &&
    //     ConstantsService.humanServiceModel.benefitsCaseSearches.search.caseId) {
    //     tempCaseId = ConstantsService.humanServiceModel.benefitsCase.caseId;
    // }

    // headers = headers.append('caseId', tempCaseId);
    // if (isResponseBlob) {
    //     headers = headers.append('responseType','arraybuffer');
    // }

    // if (this.storageService.allowTimeTravel && this.storageService.getTimeTravelDate()) {
    //     let appDate = this.storageService.getTimeTravelDate();
    //     headers = headers.append('X-DateTime', appDate.toISOString());
    // }

    let customParams: HttpParams = new HttpParams();
    for (const key in params) {
      if (key) {
        customParams = customParams.append(key, params[key]);
      }
    }
    // let requestOptions: any = { headers: headers, search: customParams };
    // if (isResponseBlob) {
    //     requestOptions['responseType'] = ResponseContentType.Blob;
    // }
    this.emitTimer();

    return { headers, params: customParams };
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      let file: File = fileList[0];
      let formData: FormData = new FormData();
      formData.append("file", file);
      let headers = new HttpHeaders();
      /** No need to include Content-Type in Angular 4 */
      // headers = headers.append('Content-Type', 'multipart/form-data');
      // headers = headers.append('Accept', 'application/json');
      headers = headers.append("Accept-Language", "en-US");
      this.http
        .post(environment.baseUrlDev + ApiManager.fileUpload, formData, {
          headers,
          observe: "response",
        })
        .pipe(
          map((res: HttpResponse<any>) => res.body),
          catchError((error) => observableThrowError(error))
        )
        .subscribe(
          (data) => console.log("success"),
          (error) => console.log(error)
        );
    }
  }

  ApiWithCaseId(
    url: string,
    params: any,
    requestType: number,
    caseId: string,
    body: any,
    option?: string,
    successMethod?: Function,
    failureMethod?: Function
  ) {
    let result: Observable<HttpResponse<any>>;
    this.slimLoadingBarService.start(() => {
      console.log("Loading complete");
    });
    if (requestType === ApiManager.GET) {
      result = this.http.get(
        environment.baseUrlDev +
          ApiManager[url] +
          "/" +
          caseId +
          ApiManager[option],
        this.getOptionsWithRequestOptionsJsonResponse(params)
      );
    } else if (requestType === ApiManager.POST) {
      result = this.http.post(
        environment.baseUrlDev +
          ApiManager[url] +
          "/" +
          caseId +
          ApiManager[option],
        body,
        this.getOptionsWithRequestOptionsJsonResponse(params, true)
      );
    } else if (requestType === ApiManager.PUT) {
      result = this.http.put(
        environment.baseUrlDev +
          ApiManager[url] +
          "/" +
          caseId +
          ApiManager[option],
        body,
        this.getOptionsWithRequestOptionsJsonResponse(params)
      );
    } else if (requestType === ApiManager.DELETE) {
      result = this.http.delete(
        environment.baseUrlDev +
          ApiManager[url] +
          "/" +
          caseId +
          ApiManager[option],
        this.getOptionsWithRequestOptionsJsonResponse(params)
      );
    } else if (requestType === ApiManager.JSONFILE) {
      result = this.http.get(
        ApiManager.baseJsonUrlDev +
          ApiManager[url] +
          "/" +
          caseId +
          ApiManager[option],
        { observe: "response" }
      );
    }
    return result.pipe(
      map((res: HttpResponse<any>) => {
        console.log("API Call Success");
        this.slimLoadingBarService.complete();
        this.emitTimer();
        if (successMethod !== undefined) {
          successMethod();
        }
        if (res.body) {
          return res.body;
        } else {
          return res;
        }
      }),
      catchError((error: HttpErrorResponse | any) => {
        console.log("API call failure: Catch");
        this.slimLoadingBarService.stop();
        let errMsg = "";
        if (!(error instanceof ErrorEvent) && !(error instanceof Error)) {
          const body = error.error || "";
          const err = body.error || JSON.stringify(body);
          errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
        } else {
          errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        if (failureMethod !== undefined) {
          failureMethod();
        }
        return observableThrowError(errMsg);
      })
    );
  }

  public makeFileRequest(
    url: string,
    params: any
  ): Observable<HttpResponse<any>> {
    return Observable.create((observer) => {
      let formData: FormData = new FormData(),
        xhr: XMLHttpRequest = new XMLHttpRequest();
      for (const key in params) {
        if (key) {
          formData.append(key, params[key]);
        }
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            this.emitTimer();
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };
      xhr.upload.onprogress = (event) => {
        this.fileUploadProgressObserver.next(
          Math.round((event.loaded / event.total) * 100)
        );
        this.emitTimer();
      };

      xhr.open("POST", url, true);
      xhr.setRequestHeader("Accept-Language", "en-US");
      if (environment.addForgerockHeaders) {
        xhr.setRequestHeader("uid", "localUser");
        xhr.setRequestHeader("uniqueid", "99999999999");
        xhr.setRequestHeader("role", "cn=EE_SCREE,|cn=EE_INTER,|cn=EE_FINAL,");
        // xhr.setRequestHeader('caseId', ConstantsService.humanServiceModel.benefitsCase.caseId);
      }
      xhr.send(formData);
      this.emitTimer();
    });
  }
}
