import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-perons-livingin-home-work-book',
  templateUrl: './perons-livingin-home-work-book.component.html',
  styleUrls: ['./perons-livingin-home-work-book.component.css']
})
export class PeronsLivinginHomeWorkBookComponent extends BaseComponent implements OnInit {
  // currentPage: number = 0;
  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
}