import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-standard-deduction',
  templateUrl: './standard-deduction.component.html',
  styleUrls: ['./standard-deduction.component.css']
})
export class StandardDeductionComponent extends BaseComponent implements OnInit {

  isNotEditable: boolean = false;

  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
    const initialValue = this.group.controls['361.3'].value;
    this.changeQf361_3(initialValue);
  }

  handle3611(value){
    this.setControlValue('361.2.1',value);
  }

  calculateStandardDeduction(val: number) {
    this.calculateSDAmount(val);
    const isQF361_3Yes = this.getControlValue('361.3') === 'Y';
    const valueQF361_2_2 = this.getControlValue('361.2.2');
    this.setControlValue('361.2.3a', isQF361_3Yes ? valueQF361_2_2 : '');
  }
  
  changeQf361_3(val: string) {
    const valueQF361_2_2 = this.getControlValue('361.2.2');
    this.setControlValue('361.2.3a', val === 'Y' ? valueQF361_2_2 : '');
  }

}

