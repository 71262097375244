import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup} from '@angular/forms';
import {ConstantsService} from './constants.service';
import {ApiManager} from './api-manager.service';
import {AppUtilService} from './app.util.service';
import * as _moment from 'moment';
import moment from 'moment';

@Injectable()
export class CustomValidatorsService {

  constructor(private apiManager: ApiManager, private utilService: AppUtilService) {
  }

  static validEmail(control) {
    const regex = new RegExp('^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$');
    const val = control.value;

    if (control.valid && control.dirty && val.length > 0 && !(regex.test(val.toString()))) {
      return {'invalidEmail': 'invalid Email'};
    } else {
      return null;
    }
  }

  static validPhone(control: AbstractControl) {
    const regex = new RegExp(/^(\([0-9]{3}\))[ ]{1}[0-9]{3}-[0-9]{4}$/);
    const val = control.value;

    if (control.valid && control.dirty && val.length > 0 && !(regex.test(val.toString()))) {
      return {'invalidPhone': 'invalid Phone'};
    } else {
      return null;
    }
  }

  static validateAmount(control) {
    // const regex = new RegExp('^(?!0.00|11\)\(?!00)\(?!0)\$');
    const val = control.value;

    if (val == '0.00') {
      return {'invalidAmount': 'Invalid Amount'};
    }
    return null;
  }


  static validSsn(event: any) {
    const regex = new RegExp('^(?!666|000|888-88-8888|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$');
    const value = event.target.value;

    if (value !== '' && !(regex.test(value))) {
      return true;
    }
    return false;
  }

  static textOnly(event: any) {
    return (event.charCode == 13 || event.charCode === 32 || event.charCode >= 65 &&
      event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122);
  }

  static nameOnly(event: any) {
    return (event.charCode == 13 || event.charCode === 32 || event.charCode >= 65 && event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122
      || event.charCode === 39 || event.charCode == 45);
  }

  static textHyphenOnly(event: any) {
    return (event.charCode == 13 || event.charCode == 45 || event.charCode === 32 || event.charCode >= 65 &&
      event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122);
  }

  static numberOnly(event: any) {
    return (event.charCode == 13 || (event.charCode >= 48 && event.charCode <= 57));
  }

  static alpaNumberOnly(event: any) {
    return (event.charCode == 13 || (event.charCode >= 48 && event.charCode <= 57)
      || event.charCode == 13 || event.charCode === 32 || (event.charCode >= 65 &&
        event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122));
  }


  static alphaNumericSpecialOnly(event: any) {
    return (event.charCode == 13 || (event.charCode >= 48 && event.charCode <= 57)
      || event.charCode == 13 || event.charCode === 32 || event.charCode == 45
      || event.charCode === 47 || event.charCode === 64 || event.charCode === 38
      || event.charCode === 46 || (event.charCode >= 65 &&
        event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122));
  }


  static textOnlyForApostrophe(event: any) {
    //console.log("charcode ",event.charCode);
    return (event.charCode == 13 || event.charCode == 39 || event.charCode == 45 || event.charCode === 32 || event.charCode >= 65 &&
      event.charCode <= 90 || event.charCode >= 97 && event.charCode <= 122);
  }

  static ssnMasking(event: any) {
    let val = event.target.value;
    const charCode = (event.charCode) ? event.charCode : event.keyCode;      // Allow only backspace and delete
    if (charCode === 46 || charCode === 8 || charCode === 37 || charCode === 45) {// let it happen, don't do anything
    } else {   // Ensure that it is a number and stop the keypress
      if (!(event.charCode == 13 || (event.charCode >= 48 && event.charCode <= 57))) {
        event.preventDefault();
      } else {
        let formatFlag = false;
        if (/^\d{3}$/.test(val)) {
          val = val.substring(0, 3) + '-';
          formatFlag = true;
        } else if (/^\d{3}-\d{2}$/.test(val)) {
          val = val.substring(0, 6) + '-';
          formatFlag = true;
        }
        if (formatFlag) {
          event.target.value = val;
        }
      }
    }
  }

  static patchSSN(value: string) {
    if (value) {
      value = value.split('-').join('');
      if (value.length == 9) {
        let first = value.substring(0, 3) + '';
        let second = value.substring(3, 5) + '';
        let third = value.substring(5, 9) + '';
        return first + '-' + second + '-' + third;
      }
      return '';
    } return '';
  }

  static phoneMasking(event: any) {
    return ['(', /[1-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  }

  static isValidDate(dateString) {
    if (dateString.length !== 10) {
      return false;
    }
    if (dateString.substring(2, 3) !== '/' || dateString.substring(5, 6) !== '/') {
      return false;
    }
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
      return false;

    var parts = dateString.split("/");
    var day = parseInt(parts[1], 10);
    var month = parseInt(parts[0], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month == 0 || month > 12)
      return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  };

  // static validPregnancyDate(control) {
  //     let checkDate: any;
  //     let pregnancyDueDate: Date;
  //     let today: Date;
  //     let no_of_months: number;
  //     if (control.value == '') {
  //         return { 'empty': 'Required Field' }
  //     } else {
  //         checkDate = CustomValidatorsService.validDate(control);
  //     }
  //     if (checkDate) {
  //         return checkDate;
  //     } else {
  //         today = new Date();
  //         pregnancyDueDate = new Date(control.value);
  //         no_of_months = Date.UTC(pregnancyDueDate.getFullYear(), pregnancyDueDate.getMonth(),
  //             pregnancyDueDate.getUTCDay()) - Date.UTC(today.getFullYear(), today.getMonth(), today.getUTCDay());
  //         if (no_of_months > 23673600000) {
  //             return { 'aboveNineMonths': 'Pregnancy due date cannot be greater than 9 months' };
  //         } else if (today > pregnancyDueDate) {
  //             return { 'lessThanPresentDay': 'Pregnancy due date cannot be less than present day' };
  //         } else {
  //             return null;
  //         }
  //     }
  // }
  // static validBirthDate(control: AbstractControl) {
  //     let no_of_years: number;
  //     let today: Date;
  //     let birthDate: Date;
  //     today = new Date();
  //     birthDate = new Date(control.value);
  //     no_of_years = today.getFullYear() - birthDate.getFullYear();
  //     if (no_of_years > 120) {
  //         return { 'birthDate': 'Client age cannot be greater than 120 years' };
  //     }
  //     if (birthDate > today) {
  //         return { 'futureDate': 'Future date is not allowed' };
  //     } else {
  //         return null;
  //     }
  // }
  static Asset500(control: AbstractControl) {
    let inputAmount: any;
    inputAmount = new Number(control.value);
    if (inputAmount > 500) {
      return {'inputAmount499': 'Amount Cannot be greater than $500'};
    } else if (inputAmount == 0) {
      return {'inputAmount499': 'Amount Cannot be  $0'};
    } else {
      return null;
    }
  }

  static Asset20(control: AbstractControl) {
    let inputAmount: any;
    inputAmount = new Number(control.value);
    if (inputAmount > 20) {
      return {'inputAmount19': 'Amount Cannot be greater than $20'};
    } else if (inputAmount == 0) {
      return {'inputAmount19': 'Amount Cannot be  $0'};
    } else {
      return null;
    }
  }

  static Asset38(control: AbstractControl) {
    let inputAmount: any;
    inputAmount = new Number(control.value);
    if (inputAmount > 38) {
      return {'inputAmount38': 'Amount Cannot be greater than $38'};
    } else if (inputAmount == 0) {
      return {'inputAmount38': 'Amount Cannot be  $0'};
    } else {
      return null;
    }
  }

  static Asset25000(control: AbstractControl) {
    let inputAmount: any;
    inputAmount = new Number(control.value);
    if (inputAmount > 25000) {
      return {'inputAmount25000': 'Amount Cannot be greater than $25,000'};
    } else if (inputAmount == 0) {
      return {'inputAmount25000': 'Amount Cannot be  $0'};
    } else {
      return null;
    }
  }

  static Asset999(control: AbstractControl) {
    let inputAmount: any;
    inputAmount = new Number(control.value);
    if (inputAmount < 999) {
      return {'inputAmount999': 'Amount Cannot be less than $999'};
    } else if (inputAmount == 0) {
      return {'inputAmount999': 'Amount Cannot be  $0'};
    } else {
      return null;
    }
  }

  static AssetLessThan999(control: AbstractControl) {
    let inputAmount: any;
    inputAmount = new Number(control.value);
    if (inputAmount > 999) {
      return {'inputAmountLessThan999': 'Amount Cannot be greater than $999'};
    } else if (inputAmount == 0) {
      return {'inputAmountLessThan999': 'Amount Cannot be  $0'};
    } else {
      return null;
    }
  }

  static Asset10(control: AbstractControl) {
    let inputAmount: any;
    inputAmount = new Number(control.value);
    if (inputAmount < 10) {
      return {'inputAmount10': 'Amount Cannot be less than $10'};
    } else if (inputAmount == 0) {
      return {'inputAmount10': 'Amount Cannot be  $0'};
    } else {
      return null;
    }
  }

  static Asset1000(control: AbstractControl) {
    let inputAmount: any;
    inputAmount = new Number(control.value);
    if (inputAmount > 1000) {
      return {'inputAmount1000': 'Amount Cannot be greater than $1000'};
    } else if (inputAmount == 0) {
      return {'inputAmount1000': 'Amount Cannot be  $0'};
    } else {
      return null;
    }
  }

  static Asset4999(control: AbstractControl) {
    let inputAmount: any;
    inputAmount = new Number(control.value);
    if (inputAmount > 4999) {
      return {'inputAmount4999': 'Amount Cannot be greater than $4,999'};
    } else if (inputAmount == 0) {
      return {'inputAmount4999': 'Amount Cannot be  $0'};
    } else {
      return null;
    }
  }

  static Asset199(control: AbstractControl) {
    let inputAmount: any;
    inputAmount = new Number(control.value);
    if (inputAmount < 200) {
      return {'inputAmount199': 'Amount Cannot be less than $200'};
    } else if (inputAmount == 0) {
      return {'inputAmount199': 'Amount Cannot be  $0'};
    } else {
      return null;
    }
  }

  static Asset99(control: AbstractControl) {
    let inputAmount: any;
    inputAmount = Number(control.value);
    if (inputAmount > 100) {
      return {'inputAmount99': 'Amount Cannot be greater than $100'};
    } else if (inputAmount == 0) {
      return {'inputAmount99': 'Amount Cannot be  $0'};
    } else {
      return null;
    }
  }

  static Asset2000(control: AbstractControl) {
    let inputAmount: any;
    inputAmount = Number(control.value);
    if (inputAmount > 2000) {
      return {'inputAmount2000': 'Amount Cannot be less than $200'};
    } else if (inputAmount == 0) {
      return {'inputAmount2000': 'Amount Cannot be  $0'};
    } else {
      return null;
    }
  }

  static validateProviderId(control: AbstractControl) {
    let input: any;
    input = new Number(control.value);
    if (input != '002') {
      return {'invalidProviderId': 'This field not applicable for entered Institution Type'};
    } else {
      return null;
    }
  }

  static validDate(control: AbstractControl) {
    let dateString = control.value;
    // console.log('date string '+ dateString);
    if (dateString) {
      let formatResult = dateString.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
      if (!formatResult) {
        return {'invalidDate': 'Invalid date (Required format: MM/DD/YYYY)'};
      }
      let dateArray: number[] = dateString.split('/');
      let date: Date;
      let dateNumber: number;
      let monthNumber: number;
      let yearNumber: number;
      date = new Date(control.value);
      monthNumber = date.getMonth() + 1;
      dateNumber = date.getDate();
      yearNumber = date.getFullYear();
      // console.log(monthNumber +'/' + dateNumber+ '/' + yearNumber );
      if ((dateArray && dateArray.length == 3)
        && (dateArray[0] != monthNumber || dateArray[1] != dateNumber || dateArray[2] != yearNumber)) {
        return {'invalidDate': 'Invalid Date'};
      }
    }
  }

  static ValidFromToDate(group: AbstractControl) {
    let a = group.get('addressStartDate').value;
    let b = group.get('addressEndDate').value;
    if (!a || !b || isNaN(Date.parse(a)) || isNaN(Date.parse(b))) return null;
    a = Date.parse(a);
    b = Date.parse(b);
    if (a > b) {
      return {'invalidDate': 'Start Date has to be before end Date'};
    }
    return null;
  }

 

  static formatCheck(control) {
    if (control.value) {
      // let tempVal = (control.value).toString();
      let tempVal = _moment(control.value).format('L');
      let isValid = CustomValidatorsService.isValidDate(tempVal);
      if (control.valid && control.dirty && !isValid) {
        return {'formatError': 'Invalid Date'};
      } else {
        return null;
      }
    }
  }

  public validateNumberOnly(event: any) {
    if (!CustomValidatorsService.numberOnly(event)) {
      event.preventDefault();
    }
  }

  public validateText($event) {
    if (!CustomValidatorsService.textOnly(event)) {
      event.preventDefault();
    }
  }

  public validateName($event) {
    if (!CustomValidatorsService.nameOnly(event)) {
      event.preventDefault();
    }
  }

  public validateTextHyphen($event) {
    if (!CustomValidatorsService.textHyphenOnly(event)) {
      event.preventDefault();
    }
  }

  public validateAlphaNumeric($event) {
    if (!CustomValidatorsService.alpaNumberOnly(event)) {
      event.preventDefault();
    }
  }

  public validateAlphaNumericSpecial($event) {
    if (!CustomValidatorsService.alphaNumericSpecialOnly(event)) {
      event.preventDefault();
    }
  }

  public alphaNumericOnly(event: any) {
    return (
      (event.charCode >= 48 && event.charCode <= 57) ||
      (event.charCode >= 65 && event.charCode <= 90) ||
      (event.charCode >= 97 && event.charCode <= 122)
    );
  }


  public validateNameText($event) {
    if (!CustomValidatorsService.textOnlyForApostrophe(event)) {
      event.preventDefault();
    }
  }

  public dateOnly(event: any) {
    return event.charCode >= 47 && event.charCode <= 57;
  }

  public ValidBeginEndDate(group: AbstractControl) {
    let x = group.get('effectiveBeginDate').value;
    let y = group.get('effectiveEndDate').value;
    if (!x || !y || isNaN(Date.parse(x)) || isNaN(Date.parse(y))) return null;
    x = Date.parse(x);
    y = Date.parse(y);
    if (x > y) {
      return {'invalidEffectiveDate': 'Start Date has to be before End Date'};
    }
    return null;
  }

  public ValidEntryExitDate(group: AbstractControl) {
    let x = group.get('entryDate').value;
    let y = group.get('exitDate').value;
    if (!x || !y || isNaN(Date.parse(x)) || isNaN(Date.parse(y))) return null;
    x = Date.parse(x);
    y = Date.parse(y);
    if (x > y) {
      return {'invalidDate': 'entry Date has to be before leave Date'};
    }
    return null;
  }


  validateDateTime(event: any) {
    return ((event.charCode >= 47 && event.charCode <= 58) || event.charCode == 97 ||
      event.charCode == 109 || event.charCode == 112 || event.charCode == 32);
  }

  public ValidCitizenshipBeginEndDate(group: AbstractControl) {
    let x = group.get('beginDt').value;
    let y = group.get('endDt').value;
    if (!x || !y || isNaN(Date.parse(x)) || isNaN(Date.parse(y))) return null;
    x = Date.parse(x);
    y = Date.parse(y);
    if (x >= y) {
      return {'invalidEffectiveDate': 'Effective Begin Date has to be before Effective End Date'};
    }
    return null;
  }

  public BeginDateLessThanDOB(group: AbstractControl) {
    let x = group.get('beginDt').value;
    if (group.get('dob')) {
      let y = group.get('dob').value;
      let final: string[];
      if (y) {
        final = y.toString().split('T');
        y = final[0];
        const date: any = y.split('-');
        y = (date[1] + '/' + date[2] + '/' + date[0]);
      }
      if (!x || isNaN(Date.parse(x))) return null;
      y = Date.parse(y);
      x = Date.parse(x);
      if (x < y) {
        return {'invalidEffectiveBeginDate': 'Effective Begin Date can not be less than DOB'};
      }
    }
    return null;
  }

  public EndDateLessThanDOB(group: AbstractControl) {
    let x = group.get('endDt').value;
    if (group.get('dob')) {
      let y = group.get('dob').value;
      let final: string[];
      if (y) {
        final = y.toString().split('T');
        y = final[0];
        const date: any = y.split('-');
        y = (date[1] + '/' + date[2] + '/' + date[0]);
      }
      if (!x || isNaN(Date.parse(x))) return null;
      y = Date.parse(y);
      x = Date.parse(x);
      if (x < y) {
        return {'invalidEffectiveEndDate': 'Effective End Date can not be less than DOB'};
      }
    }
    return null;
  }

  dateLessThanFutureDate(control: AbstractControl) {
    let current_date: Date;
    let selected_date: Date;
    current_date = this.utilService.getCurrentDate();
    selected_date = new Date(control.value);
    if (selected_date > current_date) {
      return {'futureDate': 'Future date is not allowed'};
    }
    return null;
  }

 showErrorIfAnswerNO(ctr: AbstractControl) {
   if ( ctr.value === 'N' || ctr.value === 'NO' || ctr.value === 'no' ) {
     return {'message': 'Error Case'};
   }

   return null;
 }


  validDate(control: AbstractControl) {
    if(!control.value){
      return;
    }
    let dateString =  moment(control.value).format('YYYY-MM-DD');;
    // console.log('date string '+ dateString);
    if (dateString) {
      let formatResult = dateString.match(/^(\d{2})\/(\d{2})\/(\d{4})$/);
      let formatResultValid = dateString.match(/^(\d{4})-(\d{2})-(\d{2})$/);
      if (!(formatResult||formatResultValid)) {
        return { 'invalidDate': 'Invalid date (Required format: MM/DD/YYYY)' };
      }
      let dateArray: any[] = dateString.split('/');
      let date: Date;
      let dateNumber: number;
      let monthNumber: number;
      let yearNumber: number;
      date = new Date(control.value);
      monthNumber = date.getMonth() + 1;
      dateNumber = date.getDate();
      yearNumber = date.getFullYear();
      // console.log(monthNumber +'/' + dateNumber+ '/' + yearNumber );
      if ((dateArray && dateArray.length == 3)
        && (dateArray[0] != monthNumber || dateArray[1] != dateNumber || dateArray[2] != yearNumber)) {
        return { 'invalidDate': 'Invalid Date' };
      }
    }
  }

 
}
