import { Injectable } from "@angular/core";
import { ApiManager } from "./api-manager.service";
import { Dictionary, List } from "underscore";
import { Observable } from "rxjs/Observable";
import * as _ from 'underscore';

import { ConstantsService } from './constants.service';
import { LookupClass } from '../model/api/lookup.class';


@Injectable()
export class LookupManagerService {
  public static readonly english = 'en_US';
  public static readonly spanish = 'es_US';
  public allLookUps: any[] = [];
  private resultLookUps:any[] = [];

  constructor(private apiManager: ApiManager,
    public constantsService: ConstantsService  ) {
  }

  private extractData(res) {
    let body = res;
    return body || {};
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error);
    return Promise.reject(error.message || error);
  }

  getLookUpValues(code: string[], lang: string):Promise<any>{
    let notCached:LookupClass[] = [];
    let uiLookupTxt =[];
    this.resultLookUps = [];
    
    code.forEach(v =>{
      //removed below code due to dropdown data not populated issue
      // if (!this.allLookUps[v]) {
        let lookup: LookupClass = new LookupClass();
        if (this.constantsService.getText(v)) {
          lookup.lookupTypeCd = this.constantsService.getText(v);
          uiLookupTxt.push({"uiLookup": v, "lookup": this.constantsService.getText(v) })
        } else {
          lookup.lookupTypeCd = v;
        }
        
        lookup.localeCd = lang;
        notCached.push(lookup);
      // }
      // else {
      //   this.resultLookUps[v] = this.allLookUps[v];
      // }
    });
    let obs = Observable.create(observer =>{
      if (notCached.length > 0) {
        this.callDatabaseForLookups(notCached).then(res =>{
          let resLook:Dictionary<LookupClass[]>[] = [];
          resLook = res;
          console.log('call made');
          _.forEach(res, (val, key)=>{
            let lookVal: any;
            if ( uiLookupTxt.filter(v => v.lookup === key ).length > 0 ) {
              let uilookup: any =  uiLookupTxt.filter(v => v.lookup === key )[0];
              this.resultLookUps[uilookup.uiLookup] = val;
              this.allLookUps[uilookup.uiLookup] = val;
            } else {
              this.resultLookUps[key] = val;
              this.allLookUps[key] = val;
            }
            
          });
          console.log(this.resultLookUps);
          console.log(this.allLookUps);
          observer.next(this.resultLookUps);
          console.log('observable created');
          observer.complete();
        }).catch(err =>{
          console.log(err);
        });
      }
      else {
        observer.next(this.resultLookUps);
        console.log('observable created');
        observer.complete();
      }
    });
    return obs.toPromise();
  }

  callDatabaseForLookups(notCached:LookupClass[]){
    return this.apiManager.fetchData('lookupSearchPost', undefined, ApiManager.POST, notCached).toPromise()
      .then(this.extractData).catch(this.handleError);
  }
  // getLookupByCodeAsync(lookupType, code):string {
  //   let sendObj: LookupClass = new LookupClass();
  //   if (!this.allLookUps[lookupType]) {
  //     this.getLookUpValues([lookupType], LookupManagerService.english).then(res => {
  //       return this.getLookupDesc(res[lookupType], code);
  //     }).catch(err => {
  //       console.log(err);
  //       return 'No Lookup'
  //     })
  //   }else{
  //     return this.getLookupDesc(this.allLookUps[lookupType], code);
  //   }
  // }

  getLookupDesc(lookupType:string, code:string):string{
    let retValue;
    let look:LookupClass[] = this.allLookUps[lookupType];
    _.forEach(look, v =>{
      if (v.lkpDesc == code) {
        retValue = v.lookupLongVal;
      }
    });
    return retValue?retValue:'N/A';
  }

  getLookupYNDesc(lookupType:string, code:string):string{
    let retValue;
    let look:LookupClass[] = this.allLookUps[lookupType];
    _.forEach(look, v =>{
    if (v.lkpDesc == code) {
        retValue = v.lookupLongVal;
      }
    });
    return retValue?retValue:'No';
  }
}