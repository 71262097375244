import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-childsupport-two',
  templateUrl: './childsupport-two.component.html',
  styleUrls: ['./childsupport-two.component.css']
})
export class ChildsupportTwoComponent extends BaseComponent implements OnInit {

  currentPage: number = 0;

  ngOnInit() {
    super.ngOnInit();
    if(this.group.get('FormArray')['controls']){
      this.group.get('FormArray')['controls'].map(s=>{
      this.changeQf350_2_1(s);
    });
  }
  }
 
  changeQf350_2_1(val){
    if ( val.controls["350.2.1"].value  === 'N') {
        val.controls['350.2.2'].disable();
        val.controls['350.2.3'].disable();
        val.controls['350.2.4'].disable();
        val.controls['350.2.5a'].disable();
        val.controls['350.2.5b'].disable();
        val.controls['350.2.5c'].disable();
        val.controls['350.2.5d'].disable();
        val.controls['350.2.6'].disable();
        val.controls['350.2.7'].disable();
        val.controls['350.2.8'].disable();
        val.controls['350.2.9'].disable();
    }else {
        val.controls['350.2.2'].enable();
        val.controls['350.2.3'].enable();
        val.controls['350.2.4'].enable();
        val.controls['350.2.5a'].enable();
        val.controls['350.2.5b'].enable();
        val.controls['350.2.5c'].enable();
        val.controls['350.2.5d'].enable();
        val.controls['350.2.6'].enable();
        val.controls['350.2.7'].enable();
        val.controls['350.2.8'].enable();
        val.controls['350.2.9'].enable();
    }
  }



}
