import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-household-group-home',
  templateUrl: './household-group-home.component.html',
  styleUrls: ['./household-group-home.component.css']
})

export class HouseholdGroupHomeComponent extends BaseComponent implements OnInit {

isNotEditable: boolean =false;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }
  changeQf150_3_1(val){
    if (val === 'N'){
      this.group.controls['150.3.2'].disable();
      this.setControlValue('150.3.5','N');
      this.group.controls['150.3.3'].disable();
      this.group.controls['150.3.4.1'].disable();
      this.group.controls['150.3.4.2'].disable();
      this.group.controls['150.3.4.3'].disable();
      this.group.controls['150.3.4.4'].disable();
      this.group.controls['150.3.4.5'].disable();
      this.group.controls['150.3.4.6'].disable();
      this.group.controls['150.3.4.7'].disable();
      this.group.controls['150.3.4.8'].disable();
      this.group.controls['150.3.4.9'].disable();
      this.group.controls['150.3.4.10'].disable();
      this.group.controls['150.3.4.11'].disable();
      this.group.controls['150.3.4.12'].disable();
      this.group.controls['150.3.4.13'].disable();
      this.group.controls['150.3.4.14'].disable();
      this.group.controls['150.3.4.15'].disable();
      this.group.controls['150.3.4.16'].disable();

    }
    else {
      this.group.controls['150.3.2'].enable();
      this.group.controls['150.3.3'].enable();
      this.group.controls['150.3.4.1'].enable();
      this.group.controls['150.3.4.2'].enable();
      this.group.controls['150.3.4.3'].enable();
      this.group.controls['150.3.4.4'].enable();
      this.group.controls['150.3.4.5'].enable();
      this.group.controls['150.3.4.6'].enable();
      this.group.controls['150.3.4.7'].enable();
      this.group.controls['150.3.4.8'].enable();
      this.group.controls['150.3.4.9'].enable();
      this.group.controls['150.3.4.10'].enable();
      this.group.controls['150.3.4.11'].enable();
      this.group.controls['150.3.4.12'].enable();
      this.group.controls['150.3.4.13'].enable();
      this.group.controls['150.3.4.14'].enable();
      this.group.controls['150.3.4.15'].enable();
      this.group.controls['150.3.4.16'].enable();
    } 
  }
  changeQf150_3_2(val){
    if (val === 'N'){
     this.setControlValue('150.3.5','N');
      this.group.controls['150.3.3'].disable();
      this.group.controls['150.3.4.1'].disable();
      this.group.controls['150.3.4.2'].disable();
      this.group.controls['150.3.4.3'].disable();
      this.group.controls['150.3.4.4'].disable();
      this.group.controls['150.3.4.5'].disable();
      this.group.controls['150.3.4.6'].disable();
      this.group.controls['150.3.4.7'].disable();
      this.group.controls['150.3.4.8'].disable();
      this.group.controls['150.3.4.9'].disable();
      this.group.controls['150.3.4.10'].disable();
      this.group.controls['150.3.4.11'].disable();
      this.group.controls['150.3.4.12'].disable();
      this.group.controls['150.3.4.13'].disable();
      this.group.controls['150.3.4.14'].disable();
      this.group.controls['150.3.4.15'].disable();
      this.group.controls['150.3.4.16'].disable();

    }
    else {
  
      this.group.controls['150.3.3'].enable();
      this.group.controls['150.3.4.1'].enable();
      this.group.controls['150.3.4.2'].enable();
      this.group.controls['150.3.4.3'].enable();
      this.group.controls['150.3.4.4'].enable();
      this.group.controls['150.3.4.5'].enable();
      this.group.controls['150.3.4.6'].enable();
      this.group.controls['150.3.4.7'].enable();
      this.group.controls['150.3.4.8'].enable();
      this.group.controls['150.3.4.9'].enable();
      this.group.controls['150.3.4.10'].enable();
      this.group.controls['150.3.4.11'].enable();
      this.group.controls['150.3.4.12'].enable();
      this.group.controls['150.3.4.13'].enable();
      this.group.controls['150.3.4.14'].enable();
      this.group.controls['150.3.4.15'].enable();
      this.group.controls['150.3.4.16'].enable();
    } 
  }
  changeQf150_3_3(val){
    if (val === 'N'){
      this.setControlValue('150.3.5','N');
      this.group.controls['150.3.4.1'].disable();
      this.group.controls['150.3.4.2'].disable();
      this.group.controls['150.3.4.3'].disable();
      this.group.controls['150.3.4.4'].disable();
      this.group.controls['150.3.4.5'].disable();
      this.group.controls['150.3.4.6'].disable();
      this.group.controls['150.3.4.7'].disable();
      this.group.controls['150.3.4.8'].disable();
      this.group.controls['150.3.4.9'].disable();
      this.group.controls['150.3.4.10'].disable();
      this.group.controls['150.3.4.11'].disable();
      this.group.controls['150.3.4.12'].disable();
      this.group.controls['150.3.4.13'].disable();
      this.group.controls['150.3.4.14'].disable();
      this.group.controls['150.3.4.15'].disable();
      this.group.controls['150.3.4.16'].disable();

    }
    else {
  
      
      this.group.controls['150.3.4.1'].enable();
      this.group.controls['150.3.4.2'].enable();
      this.group.controls['150.3.4.3'].enable();
      this.group.controls['150.3.4.4'].enable();
      this.group.controls['150.3.4.5'].enable();
      this.group.controls['150.3.4.6'].enable();
      this.group.controls['150.3.4.7'].enable();
      this.group.controls['150.3.4.8'].enable();
      this.group.controls['150.3.4.9'].enable();
      this.group.controls['150.3.4.10'].enable();
      this.group.controls['150.3.4.11'].enable();
      this.group.controls['150.3.4.12'].enable();
      this.group.controls['150.3.4.13'].enable();
      this.group.controls['150.3.4.14'].enable();
      this.group.controls['150.3.4.15'].enable();
      this.group.controls['150.3.4.16'].enable();
    } 
  }



  other(event: any){
    if (event.checked == true){
      this.setControlValue('150.3.5','Y');
    } else {
      this.setControlValue('150.3.5','');
    }
    
  }
  noneOfAbove(event: any){
    if (event.checked == true){
      this.setControlValue('150.3.5','N');
    } else {
      this.setControlValue('150.3.5','');
    }
    
  }
}