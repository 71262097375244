import { Component, OnInit, ViewChild, Inject, ElementRef } from '@angular/core';
import { ConstantsService } from '../../../../common/constants.service';
import { AppUtilService } from '../../../../common/app.util.service';
import { ApiManager } from '../../../../common/api-manager.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormArray, FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { NavigationService } from '../../../../common/navigation.service';
import { PrintService } from '../../../../common/print.service';
import { CustomValidatorsService } from '../../../../common/custom-validators.service';
import { MonthFormatDirective, DATE_FORMAT, MNTH_FORMAT } from '../../../../shared-module/month-format.directive';
import { MomentDateModule, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { saveAs as importedSaveAs } from 'file-saver';

@Component({
  selector: 'app-monitoring-memos',
  templateUrl: './monitoring-memos.component.html',
  styleUrls: ['./monitoring-memos.component.css']
})

export class MonitoringMemosComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;  
  displayedColumns: string[] = ['memoMonth',
    'batchScheduledDt',
    'batchScheduleTime',
    'memoType',
    // 'batchStatus',
    // 'samplingStartNum',
    // 'interval',
    'actions'];
  isNotEditable: boolean = false;
  dsQCSamplingSchedule;

  constructor(
    public constantsService: ConstantsService,
    private utilService: AppUtilService,
    private apiManager: ApiManager,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public navigationService: NavigationService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private printService: PrintService,
    private customValidatorsService: CustomValidatorsService,
  ) { }

  ngOnInit() {
    this.initTables();
    this.getMemoSchedule();
    this.determineAccess();
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/qualityControl/runSampling'] != null && ref.accessMap['/qualityControl/runSampling'].accessMode != 'rw')) {
        this.isNotEditable = true;
    }
  }

  initTables() {
    this.dsQCSamplingSchedule = [];
    this.dsQCSamplingSchedule = new MatTableDataSource(this.dsQCSamplingSchedule);
    this.dsQCSamplingSchedule.sort = this.sort;
  }

  getMemoSchedule() {
    // const id = this.dsQCSamplingSchedule.memoSchdlrId;
    // const queryParams: any[] = [];
    // queryParams.push(id);
    this.apiManager.fetchData('getMemoSchedule', undefined, ApiManager.GET, undefined, undefined, undefined, undefined)
      .subscribe(res => {
        if (res) {
          this.dsQCSamplingSchedule.data = res;
          setTimeout(() => { this.dsQCSamplingSchedule.paginator = this.paginator; });
        }
      }
    );
  }

 
  openDialog(index) {
    if (this.isNotEditable) {
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    if (index !== undefined && index > -1) {
      dialogConfig.data = {
        samplingdata: this.dsQCSamplingSchedule.data,
        index: index,
      };
    } 
    else {
      dialogConfig.data = {
        samplingdata: this.dsQCSamplingSchedule.data,
        index: -1,
      };
    }

    const dialogRef = this.dialog.open(MonitoringMemoDialog, dialogConfig);
    dialogRef.updateSize('60%', '42%');
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        console.log('afterClose', val);
        // this.dsQCSamplingSchedule = new MatTableDataSource(val);
        
      }
      this.dialog.closeAll();
      this.ngOnInit();
    });
  }

  getDropdownText(val) {
    return this.constantsService.MemoTypes.filter(item => item.value === val )[0].viewValue;
  }
  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }
  back(){
    this.navigationService.navigatePage(this.router.url, 'back');
  }

  next(){
    this.navigationService.navigatePage(this.router.url, 'next');
  }

}


@Component({
  selector: 'monitoring-memos-dialog',
  templateUrl: 'monitoring-memos-dialog.html',
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})

export class MonitoringMemoDialog implements OnInit {

  public memoSchdlrForm: FormGroup;
  dsQCSamplingSchedule: any;
 
  constructor(
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    public appUtilService: AppUtilService,
    private fb: FormBuilder,
    private customValidatorsService: CustomValidatorsService,
  ) {
    this.buildForm();
  }

  displayedColumns: string[] = ['memoType','actions'];

  ngOnInit() {
    let selectedMemos = this.data.samplingdata[this.data.index];
    let memoTypeCd = selectedMemos.memoTypeCd;
    if(selectedMemos.documents) {
    selectedMemos.documents = selectedMemos.documents.filter(item => item.memoTypeCd === memoTypeCd);
    }
    if (this.data.index > -1) {
      if ( this.data.fsmeSch ) {
        this.memoSchdlrForm.get('memoTypeCd').patchValue('FECM');
        this.memoSchdlrForm.get('fsmeScheduleId').patchValue(selectedMemos.bpeFsmeScdlId);
        this.memoSchdlrForm.get('schdlrStrtDt').patchValue(selectedMemos.entranceConfDt);
        this.memoSchdlrForm.get('schdlrStartTime').patchValue('10:00 AM');
        selectedMemos.memoSchdlrMonth = selectedMemos.rvwMnth?selectedMemos.rvwMnth.substring(0,4)+'-'+selectedMemos.rvwMnth.substring(5,7)+'-01':null;
       } else { 
      const tempDate = selectedMemos.memoSchdlrMonth;
      selectedMemos.memoSchdlrMonth = tempDate.substring(0, 4) + '-' + tempDate.substring(4) + '-01';
      this.dsQCSamplingSchedule = new MatTableDataSource(selectedMemos.documents);
    }
    this.memoSchdlrForm.patchValue(selectedMemos);
    // this.memoSchdlrForm.controls.memoSchdlrMonth.value.patchValue(selectedMemos.memoSchdlrMonth);
    }
  }

  buildForm() {
    this.memoSchdlrForm = this.fb.group({
      memoSchdlrId:[''],
      fsmeScheduleId:[''],
      memoSchdlrMonth: ['', [Validators.required]],
      memoTypeCd: ['', [Validators.required]],
      doName:[''],
      appTypeCd : [''],
      schdlrStrtDt: ['', [Validators.required]],
      schdlrStartTime: ['', [Validators.required]],
      memoSchdlrSts:[''],
      interval: ['0', [Validators.required]],
    });
  }

  openWindow(docUrl){
    window.open(docUrl);
  }

  

  save(model) {

    const queryParams: any[] = [];
    const memoData = model.value;
    memoData.memoSchdlrMonth = this.appUtilService.changeDateToMonthFormat(memoData.memoSchdlrMonth);
    this.apiManager.fetchData('saveMemoSchedule', undefined, ApiManager.POST,
    memoData, null, null, queryParams)
          .subscribe(res => {
            this.dsQCSamplingSchedule = res[0].documents;
            // this.dialogRef.close();
          });
  }
}