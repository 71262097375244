import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/shared-module/baseComponent.component';

@Component({
  selector: 'app-af-internationalprogram-violation2',
  templateUrl: './af-internationalprogram-violation2.component.html',
  styleUrls: ['./af-internationalprogram-violation2.component.css']
})
export class AfInternationalprogramViolation2Component extends BaseComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
  }

}
