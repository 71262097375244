import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../shared-module/baseComponent.component';

@Component({
  selector: 'app-child-support-monitor',
  templateUrl: './child-support-monitor.component.html',
  styleUrls: ['./child-support-monitor.component.css']
})
export class ChildSupportMonitorComponent extends BaseComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
  }

}
