import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared-module/baseComponent.component';

@Component({
  selector: 'app-hh-comp-people-in-hh',
  templateUrl: './hh-comp-people-in-hh.component.html',
  styleUrls: ['./hh-comp-people-in-hh.component.css']
})

export class HhCompPeopleInHHComponent extends BaseComponent implements OnInit {
isNotEditable : boolean =false;
currentPage: number = 0;
  ngOnInit() {
    super.ngOnInit();
    this.determineAccess();
  }

}