import { Component, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiManager } from '../common/api-manager.service';
import { AppUtilService } from '../common/app.util.service';
import { ConstantsService } from '../common/constants.service';
import { NavigationService } from '../common/navigation.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort, Sort } from '@angular/material/sort';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DATE_FORMAT } from '../shared-module/month-format.directive';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export interface informationElement {
  caseId: number,
  reviewId: number,
  ldss: string,
  sampleMonth: number,
  firstNm: string,
  lastNm: string,
  rvwr: string,
  sprVsr: string,
  dateAsgn: Date,
  dateDue: Date,
  status: string
}

@Component({
  selector: 'app-quality-control-review',
  templateUrl: './quality-control-review.component.html',
  styleUrls: ['./quality-control-review.component.css']
})
export class QualityControlReviewComponent implements OnInit,OnDestroy {

  
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  qcReviewsForm: FormGroup;
  isNotEditable: boolean =false


  qcSrchRvws;
  filteredQcSrchRvws;
  displayedColumns: string[] = ['caseId','rvwId','ldssCd','sampleMo','firstNm','lastNm',
  'rvwr',
  'supervisor',
  'rvwDtAsgnd',
  'dueDt',
  'status',
  'disp',
  'actions'];
  allAnalysts: any;
  allSupervisors : any;
  allDOs : any;
  
  rvwMnthList = [];
  rvwMnthNumList;
 
  constructor(
    private readonly router: Router,
    private apiManager: ApiManager,
    private fb: FormBuilder,
    public navigationService: NavigationService,
    public constantsService: ConstantsService,
    public appUtilService: AppUtilService,
    public dialog: MatDialog,
  ) {
    this.qcSrchRvws = new MatTableDataSource();
   }

  ngOnDestroy(): void {
    sessionStorage.setItem("reviewForm",JSON.stringify(this.qcReviewsForm.getRawValue()));
  }

   ngOnInit() {
    this.buildForm();
    if(sessionStorage.getItem("reviewForm")){
      let data = JSON.parse(sessionStorage.getItem("reviewForm"));
      this.qcReviewsForm.controls.sampleMo.setValue(data.sampleMo);
      this.qcReviewsForm.controls.rvwMo.setValue(data.rvwMo);
      this.qcReviewsForm.controls.lastNm.setValue(data.lastNm);
      this.qcReviewsForm.controls.caseId.setValue(data.caseId);
      this.qcReviewsForm.controls.noAppRcdFnd.setValue(data.noAppRcdFnd);
      this.qcReviewsForm.controls.qaRvwId.setValue(data.qaRvwId);
      this.qcReviewsForm.controls.status.setValue(data.status);
      this.qcReviewsForm.controls.supervisor.setValue(data.supervisor);
      this.qcReviewsForm.controls.ldssCd.setValue(data.ldssCd);
      this.qcReviewsForm.controls.pgmType.setValue(data.pgmType);
      this.qcReviewsForm.controls.rvwr.setValue(data.rvwr);
      this.qcReviewsForm.controls.nstr.setValue(data.nstr);
    }
    this.loadPageData();
    this.valueChanged();
    this.qcSrchRvws.paginator = this.paginator;
    this.qcSrchRvws.sort = this.sort;
   }

   search() {
    this.loadPageData();
  }
  
  reset() { 
    this.qcReviewsForm.reset();
    sessionStorage.removeItem("reviewForm");
    this.ngOnInit();
  }
  
  buildForm() {
    let rvwMonthDate: Date = new Date();
    // let rvwMonth = 
    // rvwMonthDate.getMonth() + '/' + rvwMonthDate.getFullYear();

    this.qcReviewsForm = this.fb.group({
      rvwMo:[''],
      sampleMo:[''],
      lastNm:[''],
      caseId:[''],
      noAppRcdFnd:[''],
      qaRvwId:[''],
      status:[''],
      supervisor:[''],
      ldssCd:[''],
      pgmType:[''],
      rvwr:[''],
      nstr:['']
    });
  }

  openProcessLogDialog(row) {
    this.constantsService.rvwlog_rvwid = row.rvwId ;
    // this.router.navigate(['/qualityControl/fspReviewlog']);
    if (row.pgmType ===  'FSPPositive' || row.pgmType ===  'ESAPPositive'  ) {
      this.router.navigate(['/qualityControl/fspReviewlog']);
    }
    else{
      this.router.navigate(['/qualityControl/fsNegReviewlog'])
    }
  
  }

  gotoCaseHome(row) {
    const queryParams: any[] = [];
    queryParams.push(row.rvwId);
    this.apiManager.fetchData('getCaseReviewSearch', undefined, ApiManager.GET,
      undefined, undefined, undefined, queryParams).subscribe(
        res => {
          if (res.length > 0) {
            this.review(res[0]);
          }

        },
        error => {
          console.log('look up API call failure: Error');
        }
      );

  }

  review(rvwInfo) {
    this.constantsService.ReviewInfo = rvwInfo;
    if (this.constantsService.ReviewInfo) {
      this.refreshNav();
    }

  }

  refreshNav() {
    this.navigationService.getPrograms(this.navigationService.appCd).then(v => {
      this.afterNavChange();
    });
  }

  afterNavChange() {
    // Do Nothing
    // TODO : if any change after nav change while adding new program
    let initiatePage;
    if (this.constantsService.ReviewInfo.appType === 'BPE-FSME') {
      initiatePage = '/bpe/fsme/case-review-list';
    } else if (this.constantsService.ReviewInfo.appType === 'BPE-PA') {
      initiatePage = '/bpe/payment-accuracy/case-review-list';
    } else if (this.constantsService.ReviewInfo.appType === 'BPE-WPR') {
      initiatePage = '/bpe/wpr/case-review-list';
    } else if (this.constantsService.ReviewInfo.appType === 'BPE-MONITOR') {
      initiatePage = '/bpe/fsme/case-review-list';
    } else {
      initiatePage = '/qualityControl/analystCaseSummary';
    }


    this.navigationService.navigatePage(initiatePage, 'next');
  }

  loadPageData() {
    
    const queryParams: any[] = [];
  
    let sendObj = this.qcReviewsForm.value;
    let caseIdFull = sendObj.qaRvwId;
    queryParams.push(caseIdFull);
  
    this.allAnalysts = this.constantsService.getDistinctReviewer();
    this.allSupervisors = this.constantsService.getDistinctSupervisors();
    this.allDOs = this.constantsService.getDistinctDOsWithOutAppType();

    if(this.constantsService.isAnalystUser()){
      sendObj.rvwr = this.constantsService.getSecurityObject().user.uniqueId

    }

    this.apiManager.fetchData('qcCtrlRvwSrchUrl', undefined, ApiManager.POST, sendObj, undefined, undefined, queryParams)
    .subscribe(
      res => {
      //   this.qcSrchRvws = new MatTableDataSource(res);
      // this.qcSrchRvws = res.qcSearchCriteriaRsltsModel;
      // this.qcSrchRvws.paginator = this.paginator; 
      
  //    this.qcSrchRvws.sort = this.sort;
      if(res){
        this.constantsService.lookUpValues = res.screenLvlLookup;
        this.qcSrchRvws = new MatTableDataSource(res.qcSearchCriteriaRsltsModel);
        // this.qcSrchRvws = res.qcSearchCriteriaRsltsModel;
        this.qcSrchRvws.paginator = this.paginator; 
        this.qcSrchRvws.sort = this.sort;
        
    }
  
          });

      
  }

  sortData(sort: Sort) {
    const data = this.qcSrchRvws;
    if (!sort.active || sort.direction === '') {
      this.qcSrchRvws = data;
      return;
    }

    this.qcSrchRvws = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'reviewId' : return compare(a.reviewId, b.reviewId, isAsc);
        case 'sampleMonth' : return compare(a.sampleMo, b.sampleMo, isAsc);
        case 'dateAsgn': return compare(a.rvwDtAsgnd, b.rvwDtAsgnd, isAsc);
        case 'dateDue': return compareDate(a.dueDt, b.dueDt, isAsc);
        case 'status': return compare(a.status, b.status, isAsc);
        default: return 0;
      }
    });

    this.qcSrchRvws = [... this.qcSrchRvws];
  }

 

  back() {
    this.navigationService.navigatePage(this.router.url, 'back');
  }
  print(): void {
    // let printContents, printMember, printStyle, printLink;
    // printContents = document.getElementById('print-section');
    // printMember = document.getElementById('print-member');
    // printStyle = this.printService.getTagsHtml('style');
    // printLink = this.printService.getTagsHtml('link');
    // // this.printService.printMember = printMember ? printMember.innerHTML : this._utilService.getFullName(this._utilService.getHeadOfHousehold().personName);
    // this.printService.printTable = printContents ? printContents.innerHTML : null;
    // this.printService.printStyle = printStyle;
    // this.printService.printLink = printLink;
    // this.printService.print();
    window.print();
  }
  valueChanged() {
    const queryParams: any[] = [];

    const reviewDoc = this.qcReviewsForm.value;
    console.log(reviewDoc)
    if (reviewDoc.samplingMonth) {
      reviewDoc.samplingMonth = this.appUtilService.changeDateToMonthFormat(reviewDoc.samplingMonth);
      queryParams.push(reviewDoc.samplingMonth);
    } else {
      queryParams.push('All');
    }
    this.apiManager.fetchData('getRvwNumList', undefined, ApiManager.GET, undefined, undefined, undefined, queryParams)
      .subscribe(
        res => {

          this.rvwMnthNumList = res.sort((a, b) => (+a.rvwNum > +b.rvwNum) ? 1 : -1);
          // this.reviewTypeFilter = res;
          const distinctReviewmonth = [...new Set(this.rvwMnthList.map(rG => rG))];
          if (distinctReviewmonth.length === 0) {
            this.rvwMnthList = [];
             res.map(item => {
              if (this.rvwMnthList.filter(fnd => fnd === item.samplemonth).length === 0) {
                this.rvwMnthList.unshift(item.samplemonth);
              }
            })  
          }
          this.rvwMnthList.sort(function(a, b){return b-a});
        }
      )

  }
}

@Component({
  selector: 'app-quality-control-process-review-dialog',
  templateUrl: './quality-control-process-review-dialog.component.html',
  styleUrls: ['./quality-control-review.component.css']
})
export class QCProcessReviewDialogComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  qcRvwProcessLogTcaForm: FormGroup;
  findSource = MatTableDataSource;

  isNotEditable: boolean = false;
  dsQCLdssInformation = new MatTableDataSource();
  @ViewChild(MatSort, {static: true}) sort: MatSort;  

  displayedColumns: string[] = [
    'ldssCd',
    'ldssName',
    'ldssDoCd',
    'ldssDoName',
    'ldssDoCity',
    'ldssDoAddress',
    'ldssDoZipCd',
    'actions'
    ];
  
  
  constructor(
    public constantsService: ConstantsService,
    private apiManager: ApiManager,
    private fb: FormBuilder,
    @Optional() public dialog: MatDialog,

   @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if ( this.data ) {
      this.buildForm();
      
    }
   }

  ngOnInit() {
    this.initTables();
    this.determineAccess();
  }
  buildForm() {
    this.qcRvwProcessLogTcaForm = this.fb.group({
      rvwMo:[''],
      sampleMo:[''],
      firstNm:[''],
      lastNm:[''],
      caseId:[''],
      noAppRcdFnd:[''],
      qaRvwId:[''],
      status:[''],
      supervisor:[''],
      ldssCd:[''],
      pgmType:[''],
      rvwr:[''],
      nstr:[''],
      dueDt:[''],
      asgnDt: ['']
    });
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/qualityControl/ldssInformation'] != null && ref.accessMap['/qualityControl/ldssInformation'].accessMode != 'rw')) {
        this.isNotEditable = true;
    }
}
initTables() {
  this.dsQCLdssInformation.sort = this.sort;
}


}

@Component({
  selector: 'quality-control-process-review-dialog',
  templateUrl: 'quality-control-process-review-dialog.component.html',
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT },
  ],
})

export class QCReviewProcessLogDialog implements OnInit {

  public ldssInformationForm: FormGroup;
  findSource: any;  
  isNotEditable: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<any>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    private fb: FormBuilder,
    public appUtilService: AppUtilService
  ) {
    
  }

  ngOnInit() {
  
  }

  save(model) {
   
  }
}

  function compare(a: number | string | Date , b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  
  function compareDate(a, b, isAsc: boolean) {
    if(a === null || a === undefined) 
      a ='12-31-1899';
    if(b === null || b === undefined) 
      b ='12-31-1899';
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }