import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ApiManager } from 'src/app/common/api-manager.service';
import { ConstantsService } from '../../../../common/constants.service';
import { NavigationService } from '../../../../common/navigation.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DeleteDialogComponent } from 'src/app/shared-module/delete-dialog/delete-dialog.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-ldss-review-list',
  templateUrl: './ldss-review-list.component.html',
  styleUrls: ['./ldss-review-list.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})

export class LdssReviewListComponent implements OnInit {
  findRespSource = MatTableDataSource;
  // tslint:disable-next-line: max-line-length
  displayedColumns: string[] = [
    'evltnNum',
    'evltnSts',
    'ldssDoCd',
    'ldssDoName',
    'evltnMnth',
    'analystId',
   'actions'
  ];
  
  dataSource;
  isNotEditable: boolean = false;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  Countys: County[];
  RvwSts: RvwSts[];  

  constructor(
    public constantsService: ConstantsService,
    // private apiManager: ApiManager,
    // public _utilService: AppUtilService,
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private apiManager: ApiManager,
    public navigationService: NavigationService,
    // private shareService: DataShareService,
    // private alertService: AlertService,
    ) {
      // this.buildForm();
      this.dataSource = new MatTableDataSource();
      this.Countys = this.constantsService.getDistinctCounty();
      // this.dataSource = [{"ldss":220,"csWrkrNm":null,"caseId":200096426,"hohname":"Person One","rvwType":null,"aidCd":null,"analystId":null,"rvwNum":null,"rvwMo":null,"rgnCd":null,"rvwSts":null},{"ldss":100,"csWrkrNm":null,"caseId":200097868,"hohname":"Jason one","rvwType":null,"aidCd":null,"analystId":null,"rvwNum":null,"rvwMo":null,"rgnCd":null,"rvwSts":null}];
    }

    ReviewersList: rvwind[] =
    [
      { value: "1", viewValue: "Kortni" },
      { value: "2", viewValue: "Brandi" },
      { value: "3", viewValue: "Donyearl" },
      { value: "4", viewValue: "Vivian" },
    ];

    evltnStses: RvwSts[] =
    [
      { value: "PE", viewValue: "In Progress" },
      { value: "ST", viewValue: "Submitted" },
      { value: "CP", viewValue: "Completed" },
      { value: "RR", viewValue: "Re-Review" }
    ];
  
  ngOnInit() {
    this.constantsService.ReviewInfo = null;
    this.getPageData();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.determineAccess();
  }

  determineAccess() {
    const ref = this.constantsService.getSecurityObject();

    if ((ref.accessMap['/bpe/fsme/ldss-review-list'] != null && ref.accessMap['/bpe/fsme/ldss-review-list'].accessMode != 'rw')) {
        this.isNotEditable = true;
    }
  }
    
  getTypeValue(cd) {
    let typeValue;
    this.Countys.filter(item => item.value == cd).map(val => {
      typeValue = val.viewValue;
    })
    return typeValue;
  }

  getRvwSts(cd) {
    let typeValue;
    this.evltnStses.filter(item => item.value == cd).map(val => {
      typeValue = val.viewValue;
    })
    return typeValue;
  }

  getReviewer(cd) {
      let typeValue;
      this.ReviewersList.filter(item => item.value == cd).map(val => {
        typeValue = val.viewValue;
      })
      return typeValue;
  }
 
  getPageData() {
    const caseId: any = '';
    const queryParams: any[] = [];

    const objBody: any = this.constantsService.getSecurityObject().user.uniqueId ;
    this.apiManager.fetchData('getfsmeevaluation', undefined, ApiManager.POST, objBody, undefined, undefined, queryParams)
      .subscribe(
        res => {
          this.dataSource.data = res;
          this.findRespSource = res;
          console.log(res)
        }
      );
  }

  review (rvwInfo) {
    this.constantsService.ReviewInfo = rvwInfo;
    this.constantsService.ReviewInfo.rvwNum = rvwInfo.evltnNum;
    this.constantsService.ReviewInfo.rvwMo = rvwInfo.evltnMnth;
    this.constantsService.ReviewInfo.rvwSts = rvwInfo.evltnSts;
    if (this.constantsService.ReviewInfo) {
      this.router.navigateByUrl('/bpe/fsme/questionary-100022?type=100022');
    }
  }

  openDialog(idx) {
    if (this.isNotEditable) {
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
     index: idx,
     data: this.findRespSource && this.findRespSource.length > 0 ? this.findRespSource[idx] : null
    };
    const dialogRef = this.dialog.open(LDssEvaluationDialog, dialogConfig);
    dialogRef.updateSize('75%', '85%');
    dialogRef.afterClosed().subscribe(val => {
      // this.getPageData();
      if (val) {
        console.log('afterClose', val);
      }
      this.ngOnInit();
    });
  }

  openDeleteDialog(index) { 
    if (this.isNotEditable) {
      return;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '350px';
    dialogConfig.height = '180px';
    dialogConfig.data = index;

    const dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(val => {
      if (val == 'yes') {
        const queryParams: any[] = [];
        console.log(this.findRespSource);
        let findRespObj: any = this.findRespSource[index];
        findRespObj.ldssDoCd = [findRespObj.ldssDoCd]
        findRespObj.dirtyStatus =  'DELETE';
        findRespObj.evltnMnth =  new Date(findRespObj.evltnMnth);
        this.apiManager.fetchData('savefsmeevaluation', undefined, ApiManager.POST, findRespObj, undefined, undefined, queryParams)
            .subscribe(res => {
              if (res) {
                this.findRespSource = res;
                this.dataSource.data = this.findRespSource;
                setTimeout(() => { 
                  // this.findRespSource.paginator = this.paginator;
                 });
              }
              this.dialog.closeAll();
            });
      }
      this.ngOnInit();
    });
}

  ldssevaluationForm(ldssevaluationForm: any) {
    throw new Error("Method not implemented.");
  }

  print() {
  }

  edit() {
  }

  override() {
  }

  delete() {
  }

  next() {
  }
}

export interface County {
  value: string;
  viewValue: string;
}

export interface DOOffice {
  ldssDoCd: string;
  ldssDoName: string;
}

export interface RvwSts {
  value: string;
  viewValue: string;
}

export interface EPEU {
  value: string;
  viewValue: string;
}

export interface LeadReviewer {
  value: string;
  viewValue: string;
}

export interface rvwind {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'ldss-evaluation-dialog',
  templateUrl: 'ldssevaluation-dialogview.html',
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],  
})

export class LDssEvaluationDialog implements OnInit {
  ldssevaluationForm: FormGroup; 
  Countys: County[];
  DOs : DOOffice[];
  EvlSts : RvwSts[];
  ReviewersList: rvwind[] =
  [
    { value: "1", viewValue: "Kortni" },
    { value: "2", viewValue: "Brandi" },
    { value: "3", viewValue: "Donyearl" },
    { value: "4", viewValue: "Vivian" },
  ];

  evltnStses: RvwSts[] =
  [
    { value: "PE", viewValue: "In Progress" },
    { value: "CP", viewValue: "Completed" },
    { value: "RR", viewValue: "Re-Review" }
  ];

  findRespSource: any;  
  
  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiManager: ApiManager,
    public constantsService: ConstantsService,
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    public navigationService: NavigationService,
  )
   {
    this.Countys = this.constantsService.getDistinctCounty();
    this.DOs = this.constantsService.getDistinctDOs();
  }

  ngOnInit() {
    this.buildForm();
    if (this.data.index > -1) {
      const selectedFindResp = this.data.data;
      this.ldssevaluationForm.patchValue(selectedFindResp);
      this.ldssevaluationForm.get('ldssCd').patchValue(selectedFindResp.ldss+'');
      this.ldssevaluationForm.get('ldssDoCd').patchValue([selectedFindResp.ldssDoCd]);
      //this.ldssevaluation.get('fndngRspDt').patchValue(this.appUtilService.changeDateYYYYMMDD(selectedFindResp.fndngRspDt));
    }
  }
  
  buildForm() {
    this.ldssevaluationForm = this.fb.group({
      bpeLdssEvltnId: [''],
      evltnNum:[''],
      evltnSts:[''],
      evltnMnth:['',Validators.required],
      ldssCd:[''],
      ldssDoCd:['',Validators.required],
      ldssDoName:[''],
      analystId:['',Validators.required],
      activeInd:[],
      dirtyStatus: ['INSERT'],
    });
  }

  save(model) {
    console.log(model.controls.bpeFsmeScdlId);
    console.log(model);
    const queryParams: any[] = [];
    // queryParams.push('BPE-FSME');
    let appCd: string = '';
    appCd = this.constantsService.getAppCd(this.navigationService.appCd);
    let fsmeschedObj: any = model.value;
    fsmeschedObj.dirtyStatus = model.dirty && model.value.bpeLdssEvltnId !== '' ? 'UPDATE' : 'INSERT';
    fsmeschedObj.bpeLdssEvltnId = model.value.bpeLdssEvltnId === '' ? null : parseInt(model.value.bpeLdssEvltnId);
    fsmeschedObj.evltnMnth =  new Date(fsmeschedObj.evltnMnth);
    
    this.apiManager.fetchData('savefsmeevaluation', undefined, ApiManager.POST, fsmeschedObj, undefined, undefined, queryParams)
      .subscribe(
        res => {
          this.findRespSource = res;
           this.dialogRef.close(res);
        });
        this.buildForm();
  }
}